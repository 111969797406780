import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const AssetHistoryPage = styled.div`
  .button-group {
    float: right;
    clear: both;
  }
  .btn-success{
    background-color: #0B2A1B;
    border-color: #0B2A1B;
    &:hover{
      opacity: 0.8;
    }
  }  
  .ant-table-wrapper {
    min-width: 100%;
  }
  .delete-button{
    margin-left:8px;
  }

  .card_content{
    display:inline;
  }

  .card_value{
    float:right;
  }
`;

export default WithDirection(AssetHistoryPage);