import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const SensorModalWrapper = styled(Modal)`
	.ant-input {
		height: 40px!important;
	}
`;

export default WithDirection(SensorModalWrapper);