import React, { useState, useEffect,useRef,useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber, Checkbox, TimePicker,Radio,Upload,Space } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, HomeFilled, PlusOutlined } from '@ant-design/icons';
import { marketplaceCategoryService, marketplaceProductService } from '@iso/services';
import { activityPolicy } from '@iso/constants/activityPolicy';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import { healthSafetyTypes } from '@iso/constants/healthSafetyTypes';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';
import { assetService,healthsafety,attachment } from '@iso/services';
import moment from 'moment';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import ModalHealthSafetyWrap from './ModalHealthSafety.styles';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

const { Option } = Select;

const ModalHealthSafety = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const [valueLat, setValuelat] = useState();
  const [valueLng, setValueLng] = useState();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState({});
  const currentUser = useSelector((state) => state.user.user);

  const dateFormat = 'DD/MM/YYYY';
  const [attachmentId, setAttachmentId] = useState([]);
  const [tags, setTags] = useState([]);

  const mapRef = useRef();


   useEffect(() => {
      setFields([
        {
          name: ['asset', 'last_checked_date'],
          value: moment(),
        },   
        {
          name: ['asset', 'creation_date'],
          value: moment(),
        },        
        {
          name: ['asset', 'expiry_date'],
          value: moment(),
        },     
        {
          name: ['asset', 'created_by'],
          value: currentUser ? currentUser.name : '',
        },
 
      ]);

  }, [props.propertyId]);


  const uploadProps = {
    name: "Attachment[file]",
    action: apiEndpoint.attachments,
    headers: authHeader(true),
    listType: "picture",
    multiple: true,
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        // response.file.response.attachment.id
        setAttachmentId(attachmentId => [...attachmentId, response.file.response.attachment.id]);
      }
    },
    onRemove: file => {
      attachment.destroy(file.uid);
    }


  };

  const onGalleryFileRemove = (file) => {
     return new Promise((resolve, reject) => {
      window.confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);
        },
        onCancel: () =>{
           reject(true);
        }
      })
     })
   };

  const handleChangeTag = (input_tags) => {
    setTags(input_tags);
  };

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
        setAttachmentId('');
        setValueLng('');
        setValuelat('');
        props.cancelModalHealthSafety();
        props.searchAssetAndPolicy();
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  };

  const getLatLng = (cLat, cLng) => {
    const lat = cLat;
    const lng = cLng;
    return new L.LatLng(
          lat,
          lng
    );
  }

   const onStore = (values) => {
    
    var point = null;

    if(values.asset.type === 'evacuation_point' || values.asset.type === 'fire_extinguisher' || values.asset.type === 'first_aid_kit'){
      if(valueLat !== '' && valueLng !== ''){
        point = getLatLng(valueLat,valueLng);
      }
    }

    const data = {
      facility: {
        ...values.asset,
        date_of_purchase: values.asset.date_of_purchase ? dateHelper.dateForAPI(values.asset.date_of_purchase) : '',
        manufacture_date: values.asset.manufacture_date ? dateHelper.dateForAPI(values.asset.manufacture_date) : '',
        last_checked_date: values.asset.last_checked_date ? dateHelper.dateForAPI(values.asset.last_checked_date) : '',
        creation_date: values.asset.creation_date ? dateHelper.dateForAPI(values.asset.creation_date) : '',
        expiry_date: values.asset.expiry_date ? dateHelper.dateForAPI(values.asset.expiry_date) : '',
        date_of_birth: values.asset.date_of_birth ? dateHelper.dateForAPI(values.asset.date_of_birth) : '',
        chemical_last_used: values.asset.chemical_last_used ? dateHelper.dateForAPI(values.asset.chemical_last_used) : '',
        category:'health_and_safety',
        point,
        tags:tags,
        attachment_id : attachmentId,
        property_id: props.propertyId,
      }
    }


    assetService.storeOrUpdate(data).then(res => {
      if(res.code === '0000'){
        props.onSaved(res.facility);
        form.resetFields();
        setLoading();
        setTags([]);
        props.getDataAsset();
        props.searchAssetAndPolicy();
      } 
      setLoading(false);
    });    
  }
 
  const handleClickMap = (e) => {
     const { lat, lng } = e.latlng;
      form.setFieldsValue({
        latitude: lat,
        longitude: lng,
      });

     setValueLng(lng);
     setValuelat(lat);
  };

  const renderOptionsHealthSafetyTypes = () => {
    let options = [];
    _.forEach(healthSafetyTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a Health Safety Types"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 


  return(
    <ModalHealthSafetyWrap
      visible={props.visibleModalHealthSafety}
      title="Form"
      onCancel={props.cancelModalHealthSafety}
      footer={[
      <Button key="back" onClick={props.cancelModalHealthSafety} className="cancel-button">
        {<IntlMessages id="propertyPage.modal.cancel" />}
      </Button>,
      <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
        {<IntlMessages id="propertyPage.modal.save" />}
      </Button>]}
    >
      <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
          id="#1"
        >
      <div className="form-body">
        <Form.Item
          name={['asset', 'title']}
          className="isoInputWrapper"
          label="Title"
          rules={[
            {
              required: true,
              message: 'This field is required',
              whitespace: true,
            },
            {
              max:255,
              message: 'Title must be maximum 255 characters.'
            },
          ]}
        >
          <Input size="large" placeholder="Input asset title"/>
        </Form.Item>
        <Form.Item
          name={['asset', 'type']}
          className="isoInputWrapper"
          label={<IntlMessages id="propertyPage.modal.asset.healthSafetyTypes" />}
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          {renderOptionsHealthSafetyTypes()}
        </Form.Item>      
 
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
             return (prevValues['asset']);
          }}
        >
        {({ getFieldValue }) => {
          if(getFieldValue('asset') && getFieldValue('asset').type){
          
              return (
                <div>
                    <Row>
                      { getFieldValue('asset').type !== "fire_extinguisher"  && 
                      <Col xs={{span: 24}} md={{span: 11}}>
                        <Form.Item
                          name={['asset', 'last_checked_date']}
                          className="isoInputWrapper"
                          label="Last Checked Date"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <DatePicker size="large" style={{width: '100%'}} format={dateFormat}  />
                        </Form.Item>
                      </Col>
                      }
                      { getFieldValue('asset').type !== "first_aid_kit" &&
                        getFieldValue('asset').type !== "fire_extinguisher" 
                        && 
                        <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                          <Form.Item
                            name={['asset', 'creation_date']}
                            className="isoInputWrapper"
                            label="Creation Date"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                            >
                              <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                            </Form.Item>
                          </Col> 
                          } 
                        </Row>
                        <Row>
                         <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['asset', 'expiry_date']}
                            className="isoInputWrapper"
                            label="Expiry Date"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                          </Form.Item>
                          </Col>
                          {
                            (getFieldValue('asset').type === "fire_extinguisher" || 
                            getFieldValue('asset').type === "first_aid_kit" ) &&
                            <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                              <Form.Item
                                name={['asset', 'manufacture_date']}
                                className="isoInputWrapper"
                                label="Manufacture Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                <DatePicker size="large" style={{width: '100%'}} format={dateFormat}/>
                              </Form.Item>
                            </Col>
                            }
                            {
                              (getFieldValue('asset').type === "policy" ||
                              getFieldValue('asset').type === "induction"  ||
                              getFieldValue('asset').type === "procedure" ) &&
                              
                                <Col xs={{span: 24}} md={{span: 11,offset:2}}>
                                  <Form.Item
                                    className="isoInputWrapper"
                                    label="Tag"
                                  >
                                    <TagsInput name={['asset', 'tags']} value={tags} onChange={handleChangeTag} addOnBlur="true"/>

                                  </Form.Item>
                                </Col>
                             
                            }
                        </Row>  
                        
                        {
                          getFieldValue('asset').type !== "policy" && 
                          getFieldValue('asset').type !== "induction"  &&
                          getFieldValue('asset').type !== "procedure"  &&
                        <Row>
                          <Col xs={{span: 24}} md={{span: 24}} >
                            <Map ref={mapRef} center={props.position[0] !== null ? props.position : false} doubleClickZoom={false} onclick={handleClickMap} zoom={16} zoomControl={true} style={{width:'100%', height:'300px'}}>
                              <ReactLeafletGoogleLayer type={'hybrid'} useGoogMapsLoader={false}/>

                              {valueLat  && <Marker position={[valueLat,valueLng]} draggable={true}>
                                  <Popup position={[valueLat,valueLng]}>
                                  </Popup>
                              </Marker>}
                            </Map>
                          </Col>
                        
                          <Col xs={{span: 24}} md={{span: 11}}>  
                            <Form.Item
                              className="isoInputWrapper"
                              name='latitude'
                              label="Latitude"
                              value={valueLat}
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >                          
                            <Input value={valueLat}/>
                            </Form.Item>
                          </Col>
                          <Col xs={{span: 24}} md={{span: 11, offset: 2}} >
                           <Form.Item
                              className="isoInputWrapper"
                              name='longitude'
                              label="Longitude"
                              value={valueLng}
                              rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                            >
                            <Input value={valueLng}/>
                            </Form.Item>
                          </Col> 
                        </Row>
                        }
                        <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                             <Form.Item
                                name={['asset', 'created_by']}
                                className="isoInputWrapper"
                                label="Created By"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                <Input size="large" placeholder="Created By"/>
                              </Form.Item>
                          </Col>
                      </Row>
                      <Row>
                        <Col xs={{span: 24}} md={{span: 24}} >
                          <Form.Item
                            name={['asset', 'attachment']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="propertyPage.modal.asset.attachment" />}
                          >
                            <Upload {...uploadProps}>
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          
                          </Form.Item>
                        </Col>
                      </Row>
                </div>
              )
          }
        }} 
        </Form.Item>
        <Form.Item
          name={['asset', 'description']}
          className="isoInputWrapper"
          label="Description"
        >
          <Input.TextArea />
        </Form.Item>
        
      </div>
    </Form>
    </ModalHealthSafetyWrap>
   
  )
}
export default ModalHealthSafety;
