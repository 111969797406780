
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
// import { visitorService } from "@iso/services";
import { visitorService } from "../../../services/visitor.service";
import IntlMessages from "@iso/components/utility/intlMessages";
import VisitorModalWrapper from "../Visitor/VisitorModal.styles";
import { Checkbox, Radio } from "antd";
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl, Marker, Popup } from 'react-leaflet';
import { Form, Input, Button, Select, Row, Col, DatePicker, Card, Timeline, Upload, message } from 'antd';
import _ from "lodash";

import moment from "moment";
import VisitorSummaryStyles from "./visitorSummary.styles";



const { Option } = Select;

const VisitorViewSummary = (props) => {
console.log("props",props);
    const { viewSummary } = props;

 
const cancelModal = () => {
    props.setVisibleViewSummaryForm(false);
}



                
  return (

    <VisitorSummaryStyles
    className='visitor-modal'
    forceRender={true}
    open={props.visibleViewSummaryForm}
    title={<IntlMessages id="Visitor Details" />}
    onCancel={cancelModal}
    maskClosable={true}
    footer={[
      <Button key="back" onClick={cancelModal} className="cancel-button">
        {<IntlMessages id="propertyPage.modal.cancel" />}
      </Button>,
      
    ]}
  >
    <div style={{
        paddingLeft:'10px',
        width: "95%", height: "400px"
      }}>
        <br />
        
        <Timeline>
            
         
                <p>{moment(props.viewSummary.created_at).format('ll')}</p>
                <Card title={`Visitor`} bordered={true}>
                <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Visitor Type</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.user_type ? props.viewSummary.user_type :''}</b>
                    </Col>
                  </Row>
                <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Visitor Email</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.email ? props.viewSummary.email : '-'}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Emergency Mobile Number</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.emergency_mobile_number ? props.viewSummary.emergency_mobile_number :'-'}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Mobile Number</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.mobile_number ?props.viewSummary.mobile_number : '-' }</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Birthday</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{moment(props.viewSummary.birthday ? props.viewSummary.birthday :'-').format('ll')}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Updated Date</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{moment(props.viewSummary.updated_at ? props.viewSummary.updated_at :'-').format('ll')}</b>
                    </Col>
                  </Row>
                </Card>
             
        </Timeline>

      </div>
      </VisitorSummaryStyles>
  );
};

export default VisitorViewSummary;
