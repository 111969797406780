import React,{useEffect,useState} from 'react';
import { Col, Row, Slider,Popover,Button } from 'antd';

const WindControl = (props) => {
  const {mapgl,mapController,setVisibleWindSpeed,visibleLayer} = props;

   useEffect(() => {
    if(mapController){
      let v = 'visible';
      if(!visibleLayer){
        v = 'none';
      }
      mapController.setLayoutProperty('wind-particles','visibility', v);
    }
  },[visibleLayer]);
  return (
    <Button 
      className={visibleLayer != 0 ? 
      "mapboxgl-ctrl-active mapboxgl-ctrl-icon mapboxgl-ctrl-wind" :
      "mapboxgl-ctrl-icon mapboxgl-ctrl-wind"
      }
      onClick={() => setVisibleWindSpeed(setVisibleWindSpeed => !setVisibleWindSpeed)}>
    </Button>
  )
}
export default WindControl;