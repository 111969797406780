import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const CategoryFormPage = styled.div`
  width: 100%;
  height: 100%;
  
  .button-group{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .ant-pagination .ant-pagination-item-active{
    border-color: #0B2A1B;
  }
  .ant-pagination a:hover,.ant-pagination-item-link:hover{
    color: #0B2A1B;
  }

  .ant-pagination .ant-pagination-item-active a{
    color:#0B2A1B;
  }
`;

export default WithDirection(CategoryFormPage);