import React, { useState, useEffect } from "react";
import SubscriptionModalWrapper from "./SubscriptionModal.styles";
import { Form, Select, Checkbox, Row, Col, Button, message } from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import Stripe from "@iso/containers/Pages/Stripe/Stripe";
import { subscriptionService } from "@iso/services";
import _ from "lodash";
import stripeActions from "@iso/redux/stripe/actions";
import { useDispatch, useSelector } from "react-redux";
import subscriptionActions from "@iso/redux/subscription/actions";
import logo from "@iso/assets/images/stripe.png";
import CardIcon from "@iso/assets/images/welcomeModals/card-icon.png";
import PaymentMethodIcon from "@iso/assets/images/welcomeModals/payment-method-icon.png";
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const { triggerStripe } = stripeActions;
const { changeActivePropertySubscription } = subscriptionActions;

const SubscriptionModal = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkingError, setCheckingError] = useState(true);
  const activePropertyId = useSelector(
    (state) => state.property?.activePropertyId
  );
  const [fields, setFields] = useState([]);

  useEffect(() => {
    //TODO: load data here
    setShowForm(true);
    setSubscriptions(subscriptionService.getList());
  }, [props]);

  useEffect(() => {
    setFields([
      {
        name: "plan",
        value: process.env.REACT_APP_STRIPE_PLAN_GOLD,
      },
    ]);
  }, [props.visibleSubscriptionModal]);

  const renderOptionsSubscription = () => {
    let options = [];
    _.forEach(subscriptions, (subscription) => {
      if (subscription.key == "REACT_APP_STRIPE_PLAN_GOLD") {
        options.push(
          <Option key={subscription.key} value={subscription.price}>
            <IntlMessages id={"subscription.prices." + subscription.key} />
          </Option>
        );
      }
    });
    return (
      <Select placeholder="Select a plan" allowClear size="large">
        {options}
      </Select>
    );
  };

  const cancelModal = () => {
    props.cancelSubscriptionModal();
    setLoading(false);
    form.resetFields();
    setCheckingError(true);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.property_id = props.propertyId;
        dispatch(triggerStripe());
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };

  const stripeToken = useSelector((state) => state.stripe.token);
  const cardToken = useSelector((state) => state.stripe.cardToken);
  const stripeTrigger = useSelector((state) => state.stripe.stripeTrigger);

  useEffect(() => {
    //Stripe token receive
    if (cardToken) {
      form.validateFields().then((values) => {
        //values.token = stripeToken;
        values.card_token = cardToken;
        values.property_id = props.propertyId;
        onStore(values);
      });
    }
  }, [cardToken]);

  const onStore = (values) => {
    if(!props.propertyId){
      return false;
    }
    const data = {
      subscription: {
        ...values,
        term: 1,
      },
    };
    subscriptionService
      .createTrialGoldSubscription(data)
      .then((res) => {
        if (res.code === "0000") {
          props.onSaved && props.onSaved();
          if (activePropertyId == res.property.id) {
            dispatch(
              changeActivePropertySubscription(res.property?.subscription_label)
            );
          }

          props.cancelModal && props.cancelModal();
          form.resetFields();
          setLoading(false);
          setCheckingError(true);
          history.push('/map')
        } else {
          setLoading(false);
          setCheckingError(true);
          message.error('Invalid Request')
        }
      })
      .catch((info) => {
        setCheckingError(false);
        setLoading(false);
      });
  };

  return (
    <SubscriptionModalWrapper
      title="Subscription"
      visible={props.visibleSubscriptionModal}
      onCancel={cancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      {/* <div className="form-body">
        <p className="instruction">
          Do you want to take the 3 Month Free Gold Subscription?
        </p>
        <p className="price">$<span>39.99</span><span>/month</span></p>
        <Button className="submit-btn" type="primary" size="large">
          Yes, I want
        </Button>
      </div> */}
      <Form
        form={form}
        name="subscriptionForm"
        layout="vertical"
        scrollToFirstError
        fields={fields}
      >
        {showForm && (
          <>
            <div className="form-body">
              <Form.Item
                name="plan"
                label="Plan You Choose"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                {renderOptionsSubscription()}
              </Form.Item>
              <>
                <Form.Item name="payment" label="Payment">
                  <img src={logo} style={{ width: "70%" }} />
                  <br />
                  <br />
                  <div className="card-container">
                    <div>
                      <div className="card-row">
                        <p>Untitled.</p>
                        <img src={CardIcon}></img>
                      </div>
                    </div>
                    <Row style={{ marginTop: "89px" }}>
                      <Col span={12}>
                        <p>OLIVIA RHYE</p>
                      </Col>
                      <Col span={12}>
                        <p>06/24</p>
                      </Col>
                    </Row>
                    <div>
                      <div className="card-row">
                        <p style={{ color: "white", fontSize: "16px" }}>
                          1234 1234 1234 1234
                        </p>
                        <img src={PaymentMethodIcon}></img>
                      </div>
                    </div>
                  </div>
                  <br />
                  <Stripe />
                </Form.Item>
                <br />
                <Form.Item
                  name="term"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: "Please check if you agree with our terms",
                    },
                  ]}
                >
                  <Checkbox>
                    {
                      <IntlMessages id="propertyPage.subscriptionModal.termAndCondition" />
                    }
                    <a
                      href="http://airagri.com.au/index.php/termsconditions/"
                      target="_blank"
                    >
                      Terms & Conditions
                    </a>
                  </Checkbox>
                </Form.Item>
              </>
              {!checkingError ? (
                <div className="ant-form-item-has-error">
                  <div className="ant-form-item-explain">
                    <div>{<IntlMessages id="subscription.modal.error" />}</div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div>
                <p>
                  Payment (Credit card will not be charged if active coupon is
                  entered)
                </p>
                <p>
                  If you would like us to manually assist with the upgrade
                  please email
                </p>
                <a href="mailto:support@airagri.com.au">
                  support@airagri.com.au
                </a>
              </div>
            </div>
          </>
        )}
      </Form>
    </SubscriptionModalWrapper>
  );
};

export default SubscriptionModal;
