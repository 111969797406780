import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const globalFormService = {
  storeOrUpdate,
  getForms,
  viewForm,
  getLivedForms
};

function getForms(){
    return (
        axios
            .get(
                apiEndpoint.globalForms,
                {
                    headers: authHeader(),
                }
            ).then(res => {
            if(res && res.status === 200){
                return res.data;
            }
        })
    );
}

function storeOrUpdate(data, id = null){
    return (id ? (
        axios
          .put(
              `${apiEndpointFunction.globalForms(id)}`,
            data,
            {
              headers: authHeader(),
            }
          )
    ) : (
        axios
          .post(
            apiEndpoint.globalForms,
            data,
            {
              headers: authHeader()
            }
          )
    )).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
    });
}

function viewForm(id){
    return (
        axios
            .get(
                `${apiEndpointFunction.globalForms(id)}`,
                {
                    headers: authHeader(),
                }
            ).then(res => {
            if(res && res.status === 200){
                return res.data;
            }
        })
    );
}

function getLivedForms(){
    return (
        axios
            .get(
                `${apiEndpoint.globalForms}/get-lived-forms`,
                {
                    headers: authHeader(),
                }
            ).then(res => {
            if(res && res.status === 200){
                return res.data;
            }
        })
    );
}
