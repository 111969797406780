import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const AssetPage = styled.div`
  width: 100%;
  height: 100%;
  .button-group{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .btn-success{
    background-color: #0B2A1B !important;
    border-color: #0B2A1B;
    height : 40px ;
    max-width : 155px;
    border-radius : 8px;
    &:hover{
      opacity: 0.8;
    }
  } 
  .search-bar {
    background: rgba(143, 146, 161, 0.05);
    border-radius: 12px;
    border: none;
    height:40px;
    margin-left: 2rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    flex-direction: row-reverse;
    max-width: 228px;
  }
  .site-form-item-icon {
    height: 19px;
    width: 19px;
    color: #8F92A1;
    font-size: 19px;
  }
  .site-form-item-cancel {
    height: 15px;
    width: 15px;
    color: #8F92A1;
    font-size: 15px;
  }
  .search-bar .ant-input {
    background: none!important;
    border: none!important;
    color: #8F92A1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .manage-table .react-collapsible tbody .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
  }
  .manage-property-header {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    margin: auto;
  }

  .manage-table .react-collapsible tbody .name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
   
  .ant-table-wrapper {
    min-width: 100%;
  }
  .fAgWDa .ant-table-content{
    overflow-x: unset;
  }
  .fAgWDa{
    overflow-x: unset;
  }
  .dtr-data{
    white-space: break-spaces;
  }
  .delete-button{
    margin-left:8px;
  }
  .dataTables_filter{
    margin-bottom:10px;
  }
  .dataTables_filter .input-sm{
    margin-left:10px;
  }
  .dataTables_filter .input-sm{
    margin-left:10px;
    text-align:center;
    outline-offset: unset;
    border-radius: 8px;
  }
  .react-collapsible-theme .react-collapsible-search input{
    text-align: center;
    border: 0;
    border: 5px #C9C9CE solid;
    border-radius: 6px;
    width: 100%;
    height: 2rem;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65)
  }
  .search-icon{
    top:5px!important;
    left:15px!important;
    width:20px;
  }

  .clear-icon{
    position: relative; 
    top:5px!important;
    right:2px!important;
  }

  .search-icon svg{
    color: rgba(0, 0, 0, 0.65)
  }

  .action-button.view-history-button, .action-button{
    margin-bottom:5px;
  }

  @media only screen and (max-width : 425px)
  {
    .search-bar{
      margin-left: 1rem;
    }
   .manage-property-header{
    padding : 20px 18px;
   }
  }
  @media only screen and (max-width : 375px)
  {
    .manage-property-header{
      flex-wrap : wrap;
      padding : 20px 18px;
     }
     .search-bar{
      margin-bottom : 10px;
      max-width :100%;
      width : 100%;
      margin-left: 0rem;
    }
    .manage-property-header .button-group .btn-success{
      max-width : 100%;
      min-width : 100%;
    }
    .ant-card-body{
      padding : 24px 24px 0px 24px !important;
    }
  }
  .ant-spin-dot-item{
    background-color: #0B2A1B !important;
  }
`;

export default WithDirection(AssetPage);