import React, { useEffect, useState } from "react";
import { Form, Button, Switch, Radio, InputNumber } from "antd";
import moment from "moment";
import IntlMessages from "@iso/components/utility/intlMessages";
import ConfigureModalSettingWrapper from "./configureModal.style";
import { alarmCategories } from "@iso/constants/alarmCategories";
import { device } from "../../../services/device.service";

const ConfigureModal = (props) => {
  const [form] = Form.useForm();
  const [smsAlertsEnabled, setSMSAlertsEnabled] = useState(false);
  const [sosAlarms, setSOSAlarms] = useState("");
  const [tracking, setTracking] = useState("enable");
  const [deviceTime, setDeviceTime] = useState(0);

  useEffect(() => {
    if (props.assignedDevice) {
      const {
        device_time,
        sms_alerts_enabled_at,
        sos_alarm,
        tracking_enabled_at,
      } = props.assignedDevice;

      setSMSAlertsEnabled(!!sms_alerts_enabled_at);
      setSOSAlarms(sos_alarm || "");
      setTracking(tracking_enabled_at ? "enable" : "disable");
      setDeviceTime(device_time || 0);

      form.setFieldsValue({
        enableSMSAlerts: !!sms_alerts_enabled_at,
        sosAlarm: sos_alarm || "",
        tracking: tracking_enabled_at ? "enable" : "disable",
        deviceTime: device_time || 0,
      });
    }
  }, [props.assignedDevice, form]);
  const cancelModal = () => {
    form.resetFields();
    setSMSAlertsEnabled(false);
    setSOSAlarms("");
    setTracking("enable");
    setDeviceTime(0);
    props?.cancelConfigureModal();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onStore(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onStore = (values) => {
    const smsAlert = smsAlertsEnabled
      ? moment().format("YYYY-MM-DD hh:mm:ss")
      : "";
    const trackingTime =
      tracking === "enable" ? moment().format("YYYY-MM-DD hh:mm:ss") : "";

    const data = {
      sms_alerts_enabled_at: smsAlert,
      tracking_enabled_at: trackingTime,
      device_time: values.deviceTime,
      sos_alarm: sosAlarms,
      user_id: props?.assignedDevice?.user_id,
    };

    device.storeOrUpdate(data, props?.assignedDevice?.id).then((res) => {
      if (res.code === "0000") {
        form.resetFields();
        props.getDeviceByProperty();
        cancelModal();
      }
    });
  };

  const handleRadioChange = (e) => {
    setTracking(e.target.value);
  };

  const handleDeviceTimeChange = (value) => {
    setDeviceTime(value);
  };

  const handleSOSAlarmChange = (e) => {
    setSOSAlarms(e.target.value);
  };

  return (
    <ConfigureModalSettingWrapper
      forceRender={true}
      visible={props.visibleConfigure}
      title={<IntlMessages id="safeFarmPage.configure.modal.title" />}
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          <IntlMessages id="propertyPage.modal.cancel" />
        </Button>,
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          onClick={handleSubmit}
        >
          <IntlMessages id="propertyPage.modal.save" />
        </Button>,
      ]}
    >
      <Form form={form} layout="horizontal">
        <Form.Item
          name="enableSMSAlerts"
          label={<IntlMessages id="configuration.smsAlertPLD.lable" />}
          valuePropName="checked"
          className="alert-cust"
          tooltip={<IntlMessages id="configuration.smsAlertPLD.tooltip" />}
        >
          <Switch
            onChange={(checked) => setSMSAlertsEnabled(checked)}
            checked={smsAlertsEnabled}
          />
        </Form.Item>

        <div className="sos-alarm-settings">
          <p className="normal-label">
            <IntlMessages id="configuration.sosAlarmSetting.title" />
          </p>
          <Form.Item name="sosAlarm">
            <Radio.Group onChange={handleSOSAlarmChange} value={sosAlarms}>
              {Object.keys(alarmCategories).map((category) => (
                <Radio key={category} value={category}>
                  {alarmCategories[category].label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="tracking-settings">
          <p className="normal-label">
            <IntlMessages id="Tracking" />
          </p>
          <Form.Item name="tracking">
            <Radio.Group onChange={handleRadioChange} value={tracking}>
              <Radio value="enable">Enable Tracking</Radio>
              <Radio value="disable">Disable Tracking</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="device_time">
          <p className="normal-label">
            <IntlMessages id="configuration.deviceTime.lable" />:
          </p>
          <Form.Item name="deviceTime" className="isoInputWrapper">
            <InputNumber
              value={deviceTime}
              onChange={handleDeviceTimeChange}
              addonAfter="Minutes"
              style={{ width: "220px" }}
              size="large"
            />
          </Form.Item>
        </div>
      </Form>
    </ConfigureModalSettingWrapper>
  );
};

export default ConfigureModal;
