import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import ImportModalWrapper from './ImportModal.styles';
import { Form, Input, Button, Select, Row, Col, Upload, message } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { importFileService, primaryObjectService, mobService } from '@iso/services';
import _ from 'lodash';
import $ from 'jquery';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import { importTables } from '@iso/constants/importTables';
import { assetCategories } from '@iso/constants/assetCategories';
import { inventoryTypes } from '@iso/constants/inventoryTypes';

const { Option } = Select;
const { Dragger } = Upload;

const ImportModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productCategoryDetail, setProductCategoryDetail] = useState({});
  const [fileId, setFileId] = useState(null);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [mobs, setMobs] = useState([]);
  const [templateUrl, setTemplateUrl] = useState('');

  const updatePrimaryObjectData = (property_id) => {
    if(property_id){
       primaryObjectService.getList(property_id).then(res => {
        if(res.code === '0000'){
          setPrimaryObjects(res.objects);
        }
      });
    }
  }

  useEffect(() => {
    if(props.property){
       mobService.getList(props.property.id).then(res => {
        if(res.code === '0000'){
          setMobs(res.mobs);
        }
      });
    }
  }, [props.property, props.visibleImportModal]);

  useEffect(() => {
    if(props.property)
    {
      updatePrimaryObjectData(props.property.id);
    }
  }, [props.property])

  const cancelModal = () => {
    props.cancelImportModal();
    form.resetFields();
    setLoading(false);
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const data = {
      import_file: {
        ...values.import_file,
        file_id: fileId,
        property_id: props.property.id
      }
    }
    importFileService.importFile(data).then(res => {
      if(res.code === '0000'){
        setTimeout(() => {
          message.success('Imported Successfully!');
        }, 1000);
        
        cancelModal();
        //props.updateDataMobs();
        form.resetFields();
      } else {
        setLoading(false);

        setTimeout(() => {
          message.error('Imported Fail!');
        }, 1000);
      }
    });
  }

  const uploadProps = {
    name: "ImportFile[file]",
    action: apiEndpoint.importFiles,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        message.error(`${file.name} is not a excel file`);
        return false;
      }    

      let $oldOne = $('.anticon-delete')[0];
      setTimeout(()=>{
        if($oldOne)
          $($oldOne).trigger('click');
      },100);
    },
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        setFileId(response.file.response.import_file.id);
      }
    },
    multiple: true    
  };

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsTable = () => {
    let table_options = [];
    _.forEach(importTables, (table, index) => {
      table_options.push(
        <Option key={index} value={table.value}>
            <div>{table.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a table"
        allowClear
        size="large"
        onChange={changeTable}
      >
        {table_options}
      </Select>
    );
  }

  const renderOptionMob = (mob_data) => {
    let options = [];
    _.forEach(mob_data, (mob, index) => {
      options.push(
        <Option key={mob.id} value={mob.id}>
          {mob.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a mob"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsAssetCategory = () => {
    let options = [];
    _.forEach(assetCategories, (cat, index) => {
      options.push(
        <Option key={index} value={cat.value}>
          <div>{cat.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category"
        allowClear
        size="large"
        onChange={changeAssetCategory}
      >
        {options}
      </Select>
    );
  }

  const renderOptionsInventoryType = () => {
    let options = [];
    _.forEach(inventoryTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a inventory type"
        allowClear
        size="large"
        onChange={changeInventoryType}
      >
        {options}
      </Select>
    );
  }

  const changeTable = (value) => {
    switch(value)
    {
      case 'mob':
        setTemplateUrl('mob_import_template_file.xlsx');
        break;
      case 'livestock':
        setTemplateUrl('livestock_import_template_file.xlsx');
        break;
      default:
        setTemplateUrl('');
        break;
    }
  }

  const changeAssetCategory = (value) => {
    switch(value)
    {
      case 'inventory':
        setTemplateUrl('');
        break;
      case 'animal':
        setTemplateUrl('asset_animal_import.xlsx');
        break;
      case 'weather':
        setTemplateUrl('asset_weather_import.xlsx');
        break;
      default:
        setTemplateUrl('asset_import.xlsx');
    }
  }

  const changeInventoryType = (value) => {
    switch(value)
    {
      case 'stock_feed':
        setTemplateUrl('asset_inventory_stockfeed_import.xlsx');
        break;
      case 'fuel':
        setTemplateUrl('asset_inventory_fuel_import.xlsx');
        break;
      case 'aircraft':
        setTemplateUrl('asset_inventory_aircraft_import.xlsx');
        break;
      case 'water':
        setTemplateUrl('asset_inventory_water_import.xlsx');
        break;
      case 'chemical':
        setTemplateUrl('asset_inventory_chemical_import.xlsx');
        break;
      case 'other':
        setTemplateUrl('asset_inventory_other_import.xlsx');
        break;
      default:
        setTemplateUrl('asset_inventory_treatment_import.xlsx');
    }
  }

  const renderDownloadTemplateButton = (template_url) => {
    return (
      <Row>
        <a href={process.env.REACT_APP_API_BASE_URL + "/uploads/template/" + template_url}>
          <Button key="submit" className="btn-success" type="primary">
            {<IntlMessages id="mobPage.importModal.downloadTemplate" />}
          </Button>
        </a>
      </Row>
    )
  }

  return(
    <ImportModalWrapper
      visible={props.visibleImportModal}
      onCancel={cancelModal}
      maskClosable={false}
      title={<IntlMessages id="mobPage.managePage.import"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
      >
        <div className="form-body">
          <Form.Item
            name={['import_file', 'primary_object_id']}
            className="isoInputWrapper"
            label="Location"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            {renderOptionObject(primaryObjects)}
          </Form.Item>
          <Form.Item
            name={['import_file', 'table']}
            className="isoInputWrapper"
            label="Table"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            {renderOptionsTable()}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (prevValues['import_file']);
            }}
          >
            {({ getFieldValue }) => {
                if(getFieldValue('import_file')){
                  if (getFieldValue('import_file').table === "livestock"){
                    return (
                      <Form.Item
                        name={['import_file', 'mob_id']}
                        className="isoInputWrapper"
                        label="Mob"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                          
                        {renderOptionMob(mobs)}
                      </Form.Item>
                    )
                  }else if (getFieldValue('import_file').table === "asset"){
                    return (
                      <>
                      <Form.Item
                        name={['import_file', 'asset_category']}
                        className="isoInputWrapper"
                        label="Category"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        {renderOptionsAssetCategory()}
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => {
                          return (prevValues['import_file']);
                        }}
                      >
                        {({ getFieldValue }) => {
                            if(getFieldValue('import_file')){
                              if (getFieldValue('import_file').asset_category === "inventory"){
                                return (
                                  <>
                                  <Form.Item
                                    name={['import_file', 'type']}
                                    className="isoInputWrapper"
                                    label="Inventory Type"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'This field is required',
                                      },
                                    ]}
                                  >
                                    {renderOptionsInventoryType()}
                                  </Form.Item>
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                      return (prevValues['import_file']);
                                    }}
                                  >
                                    {({ getFieldValue }) => {
                                        if(getFieldValue('import_file')){
                                          if (getFieldValue('import_file').type === "stock_feed" || getFieldValue('import_file').type === "aircraft" 
                                            || getFieldValue('import_file').type === 'water'){
                                            return (
                                              <Form.Item
                                                name={['import_file', 'storage_location']}
                                                className="isoInputWrapper"
                                                label="Storage Location"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'This field is required',
                                                  },
                                                ]}
                                              >
                                                {renderOptionObject(primaryObjects)}
                                              </Form.Item>
                                            )
                                          }
                                        }
                                      }
                                    }
                                  </Form.Item>
                                  </>
                                )
                              }
                            }
                          }
                        }
                      </Form.Item>
                      </>
                    )
                  }
                }
              }
            }
          </Form.Item>
          <br/>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (prevValues['import_file'] && (prevValues['import_file'].table !== currentValues['import_file'].table));
            }}
          >
            {({ getFieldValue }) => {
                if(getFieldValue('import_file')){
                  if (getFieldValue('import_file').table !== 'asset'){
                    return renderDownloadTemplateButton(templateUrl)
                  }else{
                    return <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => {
                        return (prevValues['import_file']);
                      }}
                    >
                      {({ getFieldValue }) => {
                          if(getFieldValue('import_file')){
                            if (getFieldValue('import_file').asset_category != 'inventory'){
                              return renderDownloadTemplateButton(templateUrl)
                            }else{
                              return <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => {
                                  return (prevValues['import_file']);
                                }}
                              >
                                {({ getFieldValue }) => {
                                    if(getFieldValue('import_file')){
                                      if (getFieldValue('import_file').type){
                                        return renderDownloadTemplateButton(templateUrl)
                                      }
                                    }
                                  }
                                }
                              </Form.Item>
                            }
                          }
                        }
                      }
                    </Form.Item>
                  }
                }
              }
            }
          </Form.Item>
          <br/>
          <Form.Item
            name={['import_file', 'file_id']}
            className="isoInputWrapper"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to import</p>
            </Dragger>
          </Form.Item>
        </div>    
      </Form>
    </ImportModalWrapper>
  )
}

export default ImportModal;