import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const MobManagePage = styled.div`
  width: 100%;
  height: 100%;

  .search-bar {
    background: rgba(143, 146, 161, 0.05);
    border-radius: 12px;
    border: none;
    height:40px;
    margin-left: 2rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    flex-direction: row-reverse;
    max-width: 228px;
  }
  .site-form-item-icon {
    height: 19px;
    width: 19px;
    color: #8F92A1;
    font-size: 19px;
  }
  .site-form-item-cancel {
    height: 15px;
    width: 15px;
    color: #8F92A1;
    font-size: 15px;
  }
  .search-bar .ant-input {
    background: none!important;
    border: none!important;
    color: #8F92A1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .button-group{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .btn-success{
      background-color: #0B2A1B !important;
      border-color: #0B2A1B;
      height : 40px ;
      min-width: 100px;
      max-width: 139px;
      border-radius : 8px;
    }
    .btn-download{
      background-color:#5a5d57 !important;
      border-color:#5a5d57;
      height : 40px ;
      min-width: 100px;
    max-width: 139px;
      border-radius : 8px;
    }
    .btn-merge{
      background-color: #717170 !important;
      border-color: #717170;
      height : 40px ;
      min-width: 100px;
    max-width: 139px;
      border-radius : 8px;
    }
  }
  .manage-property-header {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    margin: auto;
  }
  .manage-table .react-collapsible tbody .name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
  .manage-property-table {
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background: white;
    .ant-pagination {
      padding: 24px;
    }
  }
  .fAgWDa .ant-table-content{
    overflow-x: unset;
  }
  .fAgWDa{
    overflow-x: unset;
  }
  .dtr-data{
    white-space: break-spaces;
  }
  .delete-button{
    margin-left:5px;
  }
}
  @media only screen and (max-width : 768px)
  {
    .manage-property-header {
      // flex-wrap: wrap;
    }
    .manage-property-header > .mp-title {
      width: 51% !important;
    }
    .search-bar{
      margin-left: 0rem;
      width : 250px ;
    } 
    .btn-success{
      width :100px !important;
    }
  }

@media only screen and (max-width : 855px){
  .manage-property-header{
    flex-wrap: wrap !important;
  }
  .button-group {
    margin-top: 10px;
    justify-content: flex-start;

  }
}
@media only screen and (max-width : 440px){
  .manage-property-header{
    flex-wrap: wrap !important;
  }
  .search-bar{
    min-width : 100%;
  }
  .button-group {
    margin-top: 10px;
    flex-wrap : wrap;
    justify-content: flex-start;
    .btn-success{
      max-width : 100% !important;
    }
    .btn-download{
      width : 100%;
    }
    .btn-merge{

    }
  }
}
@media only screen and (max-width : 375px){
  .manage-property-header{
    flex-wrap: wrap !important;
  }
  .search-bar{
    min-width : 100%;
  }
  .button-group{
    display : block !important;

    
    .btn-success{
    min-width: 100%;
    max-width: 100%;
    }
    .btn-merge{
      min-width: 100%;
      max-width: 100%;
      }
      .btn-download{
        min-width: 100%;
        max-width: 100%;
        }
  }
}
.ant-spin-dot-item{
    background-color: #0B2A1B;
  }
`;

export default WithDirection(MobManagePage);