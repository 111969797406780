import React, { useEffect, useState } from "react";
import modalActions from "@iso/redux/modal/actions";
import { useDispatch, useSelector } from "react-redux";
import { taskService, propertyService } from "@iso/services";
import _, { isEmpty } from "lodash";
import Box from "@iso/components/utility/box";
import Board from "react-trello";
import "./TaskContent.css";
import TaskModal from "../TaskModal";
import Icon from "./image/Icon.png";
import fileImage from "./image/file_present.png";
import SelectDeleteCard from "./SelectDeleteCard";
import Loader from "@iso/components/utility/loader";
import NewTask from "../NewTask";

const { openModal } = modalActions;
const TaskContent = ({ data, setData }) => {
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const activeProperty = localStorage.getItem("active_property");
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const dispatch = useDispatch();
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [visibleSelectProperty, setVisiblitySelectProperty] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [viewType, setViewType] = useState("board");
  const [kanbanToggle, setKanbanToggle] = useState(-1);
  const [visibleAddCard, setVisibleAddCard] = useState(false);
  const [moveTaskId, setMoveTaskId] = useState(null);
  const [moveLaneId, setMoveLaneId] = useState(null);
  const [viewTaskData, setViewTaskData] = useState({});
  const [cardTitle, setCardTitle] = useState("s");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [position,setPosition] = useState('');
  const [deleteId, setDeleteId] = useState("");
  const [loadingData, setLoadingData] = React.useState(false);
  const [visibleNewTaskModal, setVisibleNewTaskModal] = useState(false);
  const [visibleToAddAssigne, setVisibleToAddAssigne] = useState(false);
  const [assigneeDetail, setAssigneeDetail] = useState();
  const [assigneesDetails, setAssigneesDetails] = useState();
  

  const getPrimaryObject = React.useCallback(
    (activePropertyId) => {
      propertyService.viewProperty(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setPrimaryObjects(res.primary_objects);
        }
      });
    },
    [activePropertyId]
  );

  useEffect(() => {
    if (activePropertyId) {
      getPrimaryObject(activePropertyId);
      propertyService.getProperties().then((res) => {
        if (res.properties.length === 0) {
          setVisiblitySelectProperty(true);
        }
      });
    }
  }, [activePropertyId]);

  useEffect(() => {
    updateDataBoard();
  }, [activePropertyId]);
  const updateDataBoard = (taskType = -1) => {
    if (activePropertyId) {
      setLoadingData(true);
      taskService.getBoard(activePropertyId, taskType).then((res) => {
        if (res.code === "0000") {
          let lanes = res.data;

          lanes = lanes.lanes?.map((lane) => {
            return {
              ...lane,
              title: lane.title,
              cards: !isEmpty(lane.cards)
                ? lane.cards?.map((a) => {
                    return {
                      ...a,
                      description: `${a?.metadata?.images?.length} ${a?.metadata?.images?.length}`,

                      tags: a?.metadata?.tags.split("|").map((item) => {
                        return {
                          color: "#DB2777",
                          title: item,
                        };
                      }),
                    };
                  })
                : [],
            };
          });
          setData({ lanes });
          setLoadingData(false)
        }
      });
    }
  };

  useEffect(() => {
    if (!activeProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);

  const onCardDelete = (cardId, laneId) => {
    taskService.destroy(cardId);
    updateDataBoard(kanbanToggle);
  };

  const handleDragEnd = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    setMoveTaskId(cardId);
    setPosition(position);
    setMoveLaneId(targetLaneId);
  };

  const onDataChange = (newData) => {
    let laneId = ["todo", "underway", "completed"].indexOf(moveLaneId);
    if (moveTaskId && moveLaneId) {
        let tasks = newData?.lanes[laneId]?.cards;
        if (tasks && tasks.length > 0) {
            tasks.forEach((task) => {
                if (task.id === moveTaskId) {
                    let position = calculatePosition(tasks, task);
                    task.position = position;
                    const data = {
                        task: {
                            status: moveLaneId,
                            position: position,
                        },
                    };
                    taskService.changeStatus(data, moveTaskId);
                }
            });
        }
    }
};

const calculatePosition = (tasks, currentTask) => {
    let position = 0;
    const taskIndex = tasks.findIndex((task) => task.id === currentTask.id);
    if (taskIndex > -1) {
        if (tasks.length === 1) {
            position = 65536;
        } else if (tasks.length - 1 === taskIndex) {
            position = tasks[tasks.length - 2].metadata.position + 65536;
        } else if (taskIndex === 0) {
            position = tasks[1].metadata.position / 2;
        } else {
            position =
                (tasks[taskIndex - 1].metadata.position +
                    tasks[taskIndex + 1].metadata.position) /
                2;
        }
    }
    return position;
};


  const viewTask = (id) => {
    taskService.viewTask(id).then((res) => {
      if (res.code === "0000") {
        setViewTaskData(res.task);
      }
    });
  };

  const onCardAdd = (task, laneId) => {

    let card_tags = [];
    const data = {
      task: {
        title: task && task.title,
        description: task && task.description,
        assignee_id: assigneesDetails ? assigneesDetails : "",
        assignee_ids: assigneeDetail ? assigneeDetail : "",
        tags: card_tags,
        status: laneId ? laneId.laneId : "todo",
        property_id: activePropertyId,
        priority: "medium",
      },
    };

    taskService.storeOrUpdateTasks(data).then((res) => {
      if (res.code === "0000") {
        updateDataBoard(kanbanToggle);
        setTask({
          title: "",
          description: "",
          tags: card_tags,
          status: "",
          property_id: activePropertyId,
          priority: "medium",
        });
        laneId.onCancel();
      }
    });
  };

  const onCardClick = (metadata, laneId) => {
    getPrimaryObject(activePropertyId);
    if (viewTaskData) {
      showModal(metadata, laneId);
    }
  };

  const showModal = (task, laneId) => {
    const modalData = {
      task,
      type: "task",
      laneId,
    };
    dispatch(openModal(modalData ? modalData : ""));
  };

  const cancelSelectPropertyModal = (e) => {
    setDeleteId(e);
    setVisibleDeleteModal(true);
  };

  const onChangeViewType = ({ target: { value } }) => {
    setViewType(value);
  };

  const handleChangeKanbanToggle = (value) => {
    setKanbanToggle(value ? 1 : -1);
    updateDataBoard(value ? 1 : -1);
  };
  const handleAddCard = () => {
    setVisibleAddCard(true);
  };
  function Card(cardData) {
    return (
      <>
        <div className="container cust-todo-task-box">
          <img
            className="cust-delete-btn"
            src={Icon}
            onClick={() => cancelSelectPropertyModal(cardData)}
          />
          <div onClick={() => onCardClick(cardData.metadata, cardData.laneId)}>
            <div className="header">
              <span type="lable" className="title1">
                {cardData?.title}
              </span>
            </div>
            <div className="mainDivCard">
              <div className="tagId">#{cardData?.id}</div>
              {cardData?.tags?.reduce((result, current, i) => {
                if (i < 2) {
                  result.push(
                    <div className="tagTitle">
                      {current.title ? current?.title : cardData?.title}{" "}
                    </div>
                  );
                }
                return result;
              }, [])}
              {cardData?.tags?.length > 2
                ? ` +${cardData?.tags?.length - 2} `
                : ""}
            </div>
            <div className="divImgAvtar">
              {cardData?.metadata &&
              cardData?.metadata?.assignees &&
              cardData?.metadata?.assignees?.length ? (
                <>
                  {cardData.metadata.assignees.map((e, index) => {
                    return (
                      <div key={index}>
                        { e.user && e.user.images && e.user.images.length > 0
                          ? e.user.images
                              .slice(-1)
                              .map((path, imgIndex) => (
                                <img
                                  key={imgIndex}
                                  className="avatar"
                                  src={path.full_path}
                                  alt="avatar"
                                />
                              ))
                          : ""}
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}

              {cardData?.metadata?.assignees?.length > 2 ? (
                <span className="avatars-count">
                  +{cardData?.metadata?.assignees?.length}
                </span>
              ) : (
                ""
              )}
              <span className="add-avatar">
                <img
                  src={Icon}
                  onClick={() =>
                    onCardClick(cardData.metadata, cardData.laneId)
                  }
                />
              </span>

              {/* <div className="fileContainer"></div><img src={fileImage} height='20px' width='20px' /><span className="imgLng" >{cardData.metadata.images.length}</span> */}
              {cardData?.metadata && cardData?.metadata?.images?.length ? (
                <>
                  <div className="fileContainer"></div>
                  <img src={fileImage} height="20px" width="20px" />
                  <span className="imgLng">
                    {cardData.metadata.images.length}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  let card_tags = [];
  const [task, setTask] = useState({
    title: "",
    description: "",
    tags: card_tags,
    status: "",
    property_id: activePropertyId,
    priority: "medium",
  });

  function NewCardTemplate(laneId) {
    const handleInputChange = (event) => {
      const { name, value } = event.target;

      setTask((prevTask) => ({
        ...prevTask,
        [name]: value,
      }));
    };

    // ... rest of the code

    const onAdd = () => {
      onCardAdd(task, laneId);
    };
    const onhandleAddMore = () => {
      setVisibleNewTaskModal(true);
    };
    const onHandleAssignee = (laneId) => {
      setVisibleToAddAssigne(true);
    };
    const handleCancel = () => {
      laneId.onCancel();
      setTask({
        title: "",
        description: "",
        tags: card_tags,
        status: "",
        property_id: activePropertyId,
        priority: "medium",
      });
    };
    return (
      <>
        <div className="container cust-todo-task-box cust-add-form">
          <img
            className="cust-delete-btn"
            src={Icon}
            // onClick={() => laneId.onCancel()}
            onClick={(laneId) => handleCancel(laneId)}
          />
          <input
            className="addfiels"
            type="text"
            placeholder="Enter Task Name"
            name="title"
            value={task.title}
            onChange={handleInputChange}
          />
          <input
            className="addfiels"
            type="text"
            placeholder="Description"
            name="description"
            value={task.description}
            onChange={handleInputChange}
          />
          <div>
            <img
              className="assignImg"
              src={process.env.PUBLIC_URL + "./images/icons/user-add.svg"}
              alt="Assign"
            />{" "}
            <span
              className="addassign"
              onClick={() => onHandleAssignee(laneId)}
            >
              Add Assign
            </span>
          </div>
          <div>
            <img
              className="assignImg"
              src={process.env.PUBLIC_URL + "./images/icons/add-circle.svg"}
              alt="Add More"
            />{" "}
            <span
              className="addassign"
              onClick={() => onhandleAddMore(laneId.laneId)}
            >
              Add More
            </span>
          </div>
          <button className="addBtn" onClick={onAdd}>
            Add Task
          </button>
        </div>
      </>
    );
  }

  const components = {
    Card: Card,
    NewCardForm: NewCardTemplate,
  };
  return (
    <>
    
      <Box  className="Task-custom cust-todo-task">
        <Board
          data={data}
          onCardClick={onCardClick}
          customCardLayout
          canAddLanes={false}
          className="task_box custom-task-board"
          style={{ backgroundColor: "transparent" }}
          editable={activePermission == "owner" || activePermission == "admin"}
          editLaneTitle={false}
          editCardTitle
          hideCardDeleteIcon={
            !(activePermission === "owner" || activePermission === "admin")
          }
          disallowAddingCard={true}
          allowRemoveCard={true}
          handleDragEnd={handleDragEnd}
          components={components}
          onDataChange={onDataChange}
          showDeleteButton={true}
          draggable
          cardDraggable
        /> 
      </Box>
             

      <SelectDeleteCard
        activeProperty={activeProperty}
        propertyPage={true}
        deleteId={deleteId}
        onCardDelete={onCardDelete}
        updateDataBoard={updateDataBoard}
        visibleDeleteModal={visibleDeleteModal}
        setVisibleDeleteModal={setVisibleDeleteModal}
        cancelSelectPropertyModal={cancelSelectPropertyModal}
        kanbanToggle={kanbanToggle}
      />
      <NewTask
        setTask={setTask}
        task={task}
        onSaved={updateDataBoard}
        primaryObjects={primaryObjects}
        kanbanToggle={kanbanToggle}
        visibleNewTaskModal={visibleNewTaskModal}
        setVisibleNewTaskModal={setVisibleNewTaskModal}
        propertyId={activePropertyId}
        visibleToAddAssigne={visibleToAddAssigne}
        setVisibleToAddAssigne={setVisibleToAddAssigne}
        setAssigneeDetail={setAssigneeDetail}
        setAssigneesDetails={setAssigneesDetails}
      />
      <TaskModal
        propertyId={activePropertyId}
        onSaved={updateDataBoard}
        updateDataBoard={updateDataBoard}
        primaryObjects={primaryObjects}
        kanbanToggle={kanbanToggle}
      />
    </>
    // </LayoutWrapper>
  );
};

export default TaskContent;