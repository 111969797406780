import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import SharingMapModalWrapper from './SharingMapModal.styles';
import { useSelector } from 'react-redux';
import { Form, Switch, Button, Typography, message } from 'antd';
import { propertyService } from '@iso/services'

const { Paragraph } = Typography

const SharingMapModal = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false)
  const [propertyData, setPropertyData] = useState(false)

  useEffect(() => {
    if(activePropertyId)
    {
      propertyService.viewProperty(activePropertyId).then(res => {
        if(res.code === '0000')
        {
          setPropertyData(res.property)
          setFields([
            {
              name: 'actived',
              value: res.property.sharing_token ? true : false,
            },
          ]);
        }
      })
    }
  }, [props.isShowSharingMapModal, activePropertyId])

  const cancelModal = () => {
    props.cancelSharingMapModal();
  }

  const handleSubmit = (value) => {
    var data = {
      actived: value
    }

    propertyService.activateSharingMap(activePropertyId, data).then(res => {
      if(res.code === '0000')
      {
        setPropertyData(res.property)

        if(res.property.sharing_token)
        {
          message.success('Property Map has been made public')
        }else{
          message.success('Property Map has been made private')
        }
      }else{
        message.error(res.message)
      }
    })
  }

  return (
    <>
    <SharingMapModalWrapper
      forceRender={true}
      visible={props.isShowSharingMapModal}
      title={`Share your ${propertyData.name} map`}
      onCancel={cancelModal}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="horizontal"
        scrollToFirstError
        fields={fields}
        className="form-body"
      >
        <Form.Item
          name={['actived']}
          className="isoInputWrapper"
          valuePropName="checked"
          label="Share your property map"
        >
          <Switch checkedChildren="Public" unCheckedChildren="Private" onChange={handleSubmit}/>
        </Form.Item>
        {propertyData && propertyData.sharing_token && propertyData.public_map_url &&
          <>
            <h3>Copy link to share with everyone</h3>
            <div className="form-qr-area">
              <Paragraph copyable>{propertyData.public_map_url}</Paragraph>
            </div>
          </>
        }
      </Form>
    </SharingMapModalWrapper>
    </>
  );
}

export default SharingMapModal;