import styled from "styled-components";
import { palette } from "styled-theme";
import WithDirection from "@iso/lib/helpers/rtl";
import { handleShowRandomImage } from "@iso/constants/randomBackgrounds";

const SignInStyleWrapper = styled.div`

  .welcomeClass{
    font-family: "Work Sans", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    color:#0B2A1B !important;
    margin-right:10px;
  }

  @media only screen and (max-width: 600px){
    .welcomeClass{
      font-size: 40px !important;
    }

    .signin-heading-hi{
      font-size: 40px !important;
    }

    .signin-heading{
      font-size: 40px !important;
    }
  }

  @media only screen and (max-width: 380px){
    .welcomeClass{
      font-size: 36px !important;
    }

    .signin-heading-hi{
      font-size: 36px !important;
    }

    .signin-heading{
      font-size: 36px !important;
    }
  }

  .isoLoginContent {

    min-height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top:65px;
    ${"" /* padding: 70px 50px 70px 0px; */}
    position: relative;
    background-color: #ffffff;

    .signin-form-main {
      padding: 0px 50px 0px 50px !important;
    }
  }

    @media only screen and (max-width: 768px) {
      .isoLoginContent .signin-form-main{
     ${'' /* padding : 0px 0px 0px 150px !important; */}
    } 
      .signInmain{
        padding: 12px;
    height: 100vh;
    gap: 57px;
    flex-direction: column;
      }
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      padding-bottom: 50px;
      display:flex;
      justify-content :center;
      flex-shrink: 0;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
        color: ${palette("secondary", 2)};
      }
    }

    .isoSignInForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      .ant-form-item-label > label{
        color: rgb(63, 63, 68) ;
      }
      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before,
      .form-field label::after {
        content: unset;
      }
      .ant-form-item-label {
        font-family: "Work Sans", sans-serif;
       
       
        label {
          font-family: "Work Sans", sans-serif;
        }
      }
      .form-field > div {
        display: unset;
      }

      .isoInputWrapper {
        padding-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        input {
          &::-webkit-input-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &:-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }

          &::-moz-placeholder {
            color: ${palette("grayscale", 0)};
          }
          &:-ms-input-placeholder {
            color: ${palette("grayscale", 0)};
          }
        }
      }

      .isoHelperText {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: ${palette("grayscale", 1)};
        padding-left: ${(props) =>
          props["data-rtl"] === "rtl" ? "inherit" : "13px"};
        padding-right: ${(props) =>
          props["data-rtl"] === "rtl" ? "13px" : "inherit"};
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: center;

        &:before {
          content: "*";
          color: ${palette("error", 0)};
          padding-right: 3px;
          font-size: 14px;
          line-height: 1;
          position: absolute;
          top: 2px;
          left: ${(props) => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
          right: ${(props) => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
        }
      }

      .isoHelperWrapper {
        margin-top: 35px;
        flex-direction: column;
      }

      .isoOtherLogin {
        padding-top: 40px;
        margin-top: 35px;
        border-top: 1px dashed ${palette("grayscale", 2)};

        > a {
          display: flex;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        button {
          width: 100%;
          height: 42px;
          border: 0;
          font-weight: 500;

          &.btnFacebook {
            background-color: #3b5998;

            &:hover {
              background-color: darken(#3b5998, 5%);
            }
          }

          &.btnGooglePlus {
            background-color: #dd4b39;
            margin-top: 15px;

            &:hover {
              background-color: darken(#dd4b39, 5%);
            }
          }

          &.btnAuthZero {
            background-color: #e14615;
            margin-top: 15px;

            &:hover {
              background-color: darken(#e14615, 5%);
            }
          }

          &.btnFirebase {
            background-color: ${palette("color", 5)};
            margin-top: 15px;

            &:hover {
              background-color: ${palette("color", 6)};
            }
          }

          &.btnAccountKit {
            ${"" /* background-color: rgb(150, 189, 235); */}
            margin-top: 15px;

            &:hover {
              ${"" /* background-color: ${palette('color', 6)}; */}
            }
          }
        }
      }

      .isoForgotPass {
        font-size: 12px;
        color: ${palette("text", 3)};
        margin-bottom: 10px;
        text-decoration: none;

        &:hover {
          color: ${palette("primary", 0)};
        }
      }

      button {
        width: 100%;
        height: 42px;
        border: 0;
        font-weight: 500;
      }
    }
  }
  .sharing-msg {
    font-size: 13px;
    color: ${palette("text", 3)};
    margin-bottom: 10px;
    text-decoration: none;
    text-align: center;
  }

  .signin-inputlabel {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.42px;
    color: #3f3f44;
  }
  .ant-form-item-label > label{
    color: rgb(63, 63, 68) ;
    font-weight: 600
  }

  .password-placeholder > input {
    // background-color: transparent;
    background: #F7F7F7;
    font-size: 14px;
  }

  .signin-placeholder {
    color: #3F3F44;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.42px;
    height: 58px;
    background: #F7F7F7;
    border-radius: 8px;
    border: none;
  }
  .ant-input {
      &::placeholder {
        color: black !important;
      }
    }

  .signin-btn {
    background: #0B2A1B;
    border-radius: 8px !important;
    font-family: "Work Sans", sans-serif;
    span {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }

  .signin-heading {
    font-family: "Work Sans", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    color: #3f3f44;
  }

  .signin-heading-hi {
    font-family: "Work Sans", sans-serif !important;
    color: #3f3f44;
    font-style: normal;
    font-weight: 100 !important;
    font-size: 48px;
    line-height: 72px;
    display: flex;
    padding: 0px 0px 38px 0px;
  }

  .signin-span {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3f3f44;
  }

  .signup-btn {
    font-family: Work Sans;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
  }

  .forgotpass {
    color: #3F3F44;
display:flex;
justify-content: end;
font-size: 14px;
font-weight: 600;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
margin-bottom: -25px;
    padding-top: 15px;
  }

  .signin-label-div {
    display: flex;
    justify-content: space-between;
  }
.accountDiv{
  gap:8px;
  color: #3F3F44;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  justify-content: center;
    display: flex;

}
}
.subDiv{
  width: 150px;
  height: 19px;
  top: 701px;
  left: 813px;
}
.continuMainDiv{
  display: flex;
  gap: 10px;
  width: 407px;
  flex-direction: column;
  border-left: 2px solid #F7F7F7;
  left: 50px;
}
.continue{
  margin-left : 30px;
}
.continueBtn{
  background: #F7F7F7;
  width: 117px !important;
  height: 56px !important;
  top: 405px;
  left: 1223px;
  border-radius: 16px;
  margin-left: 30px;
}
  @media only screen and (max-width: 768px) {
    .signInmain{
      max-width : 90% !important;
    }
    .fromCol{
      max-width : 100% !important;
    }
    .continuMainDiv{
      left : 80px !important;
      border : none;
    }
    .continue {
      margin : 10px 0px 0px 50px ; 
    }
    .continueBtn{
      height: 56px !important;
      margin-left : 45px;
    }
  }
  @media only screen and (max-width: 668px) {
    .isoLoginContent{
      padding-top : 25px !important;
    } 
    .isoLogoWrapper{
      padding-bottom : 5px !important
    }
    .signInmain{
      max-width : 100Vh;
    }
    .isoLoginContent .signin-form-main{
     padding : 0px 0px 0px 0px !important;
    }
    .signin-heading-hi{
      padding: 0px 0px 0px 0px !important;
   } 
   .isoSignInForm .isoInputWrapper{
    padding-bottom : 0px !important
   } 
   .forgotpass{
    padding-top : 0px !important
   }
  }
  @media only screen and (width: 1024px) {
    .isoLoginContent .signin-form-main{
     padding : 50px !important;
    }
   .signin-heading-hi{
    justify-content: center;
   } 
  }
 
`;

export default WithDirection(SignInStyleWrapper);