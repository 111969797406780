import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

class InfiniteScrollSelect extends React.Component {
  /**
   * When the user scrolls to the end of the popup, call the onLoadMore function
   * to load more data.
   */
  handlePopupScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (this.props.onLoadMore) {
        this.props.onLoadMore();
      }
    }
  };

  render() {
    return (
      <Select
        {...this.props}
        onPopupScroll={this.handlePopupScroll} // Using custom onPopupScroll
      >
        {this.props.children}
      </Select>
    );
  }
}

export default InfiniteScrollSelect;
