import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
import 'mapbox-gl/dist/mapbox-gl.css';
const tagManagerArgs = {
    gtmId: 'GTM-NJKCDB7'
} 
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
