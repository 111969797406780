import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const SubscriptionModalWrapper = styled(Modal)`
padding : 20px;
  &.user-access-modal{
    
    width: 640px !important;
  }  
`;

export default WithDirection(SubscriptionModalWrapper);