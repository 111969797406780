import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, Select, Dropdown, Menu, Popconfirm} from 'antd';
import IntlMessages from '@iso/components/utility/intlMessages';
import PropertyModalWrapper from './PropertyModal.styles';
import SimpleTable from '@iso/components/Tables/SimpleTable';
import { configs } from '@iso/components/Tables/configs';
import fakeData from '@iso/components/Tables/data';
import { CheckCircleFilled, ExclamationCircleFilled, SendOutlined, DownOutlined, CopyOutlined } from '@ant-design/icons';
import { propertyService, userService, permissionService } from '@iso/services';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Loader from '@iso/components/utility/loader';
import PermissionSelect from './PermissionSelect';
import Table from '@iso/components/collapsing-table/table';
import Box from '@iso/components/utility/box';

const { Option } = Select;

const UserAccessModal = (props) => {
 
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [dataList, setDataList] = useState({});
  const [form] = Form.useForm();
  const permissions = useSelector(state => state.permission.permissions);
  const [showForm, setShowForm] = useState(false);
  const [fields, setFields] = useState([]);

  const updateDataTable = useCallback(() => {
    propertyService.getListUsersOfProperty(props.propertyId ? props.propertyId :activePropertyId, true).then(res => {
      if(res.code === '0000'){
        setUsers(res.users);
        setDataList(res.users);
        setModalLoading(false);
      }
    });
  }, [props]);

  useEffect(() => {
    if (props.propertyId ? props.propertyId :activePropertyId){
      updateDataTable();
      permissionService.getUserPermission(props.propertyId ?props.propertyId :activePropertyId).then(res => {
        if(res.code === '0000'){

          setShowForm(res.permission === "owner" || res.permission === "admin");
        }
      });
    }
  }, [props, updateDataTable]);

  useEffect(() => {
    setFields([
      {
        name: "user_name",
        value: props.viewSummary?props.viewSummary.name:'',
      },
      {
        name: "user_email",
        value: props.viewSummary? props.viewSummary.email :'',
      },
      // {
      //   name: "permission",
      //   value: props.editProperty.state,
      // },
      
    ]);

    
  }, [props, props.viewSummary]);
  const cancelModal = () => {
    if (props.cancelModal) {
      props.cancelModal();
    }
  
    form.resetFields();
    setLoading(false);
    if (props.visibleModal) {
      props.setvisibleModal(false)
    }
  }

  const handleOptionClick = (value, object) => {
    object.permission_id = value;
    let usersTmp = _.clone(users);

    const userIndex = _.findIndex(usersTmp, (user) => {
      return user.id === object.id
    });

    usersTmp[userIndex] = object;

    //Owner change
    if(object.permission_id == 1){
      _.forEach(usersTmp,(user) =>{
        if(object.id != user.id && user.permission_id==1){
          user.permission_id = 2;
        }
      });
    }

    setUsers(usersTmp);
    permissionService.update({
      id: object.user_property_id,
      permission_id: object.permission_id
    });    
  }

  const resendInvitation = (user) => {
    onStore({
      permission: user.permission_slug,
      user_email: user.email,
      user_name: user.name,
      is_resend: true
    })
  }
  
  const menu = (object) => (
    <div>
      <Menu>
        <Menu.Item key="resend" icon={<SendOutlined />} onClick={(e)=>{resendInvitation(object)}}>
          <IntlMessages id="propertyPage.managePage.resendInvitation" />
        </Menu.Item>
        <Menu.Item key="copyLink" icon={<CopyOutlined />} onClick={(e)=>{navigator.clipboard.writeText(object.invitation_link)}}>
          <IntlMessages id="propertyPage.managePage.copyLink" />
        </Menu.Item>
    </Menu>
    </div>
  );

  const columns = [
    {
      label: <IntlMessages id="antTable.title.user" />,
      accessor: 'name',
      minWidth: 0,
      sortable: false,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="antTable.title.email" />,
      accessor: 'email',
      minWidth: 0,
      sortable: false,
      position: 2,
      priorityLevel: 2
    },
    {
      label: <IntlMessages id="antTable.title.status" />,
      accessor: 'status_label',
      minWidth: 0,
      sortable: false,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: '',
      accessor: 'action',
      minWidth: 0,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let object = cell.row
        return !object.status ? 
          <Dropdown overlay={menu(object)} trigger={['click']}>
              <Button className="btn-success ant-btn-primary">
              <span><IntlMessages id="antTable.title.actions" /></span> <DownOutlined />
              </Button>
          </Dropdown> : <></>;
      }
    },
    {
      label: <IntlMessages id="antTable.title.permission" />,
      accessor: 'permission',
      minWidth: 10000,
      sortable: false,
      position: 5,
      priorityLevel: 5,
      CustomComponent: cell => {
        let object = cell.row
        return (
          <PermissionSelect 
            object={object}
            updateDataTable={updateDataTable}
            permissions={permissions}
            handleOptionClick={handleOptionClick}
          />
        );
      }
    },
    {
      label: <IntlMessages id="antTable.title.status" />,
      accessor: 'status',
      minWidth: 400,
      sortable: false,
      position: 6,
      priorityLevel: 6,
      CustomComponent: cell => {
        let object = cell.row
        return (
          object.status ?
         ''
          : <ExclamationCircleFilled style={{color: '#ffcc00', fontSize: '18px'}} title="Outgoing"/>
        );
      }
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 400,
      sortable: false,
      position: 7,
      priorityLevel: 7,
      noTitle: true,
      CustomComponent: cell => {
        let object = cell.row
        return (
          (object.permission_id != 1) && <Button key="submit" onClick={handleCancelInvite.bind(this, object)} className="cancel-button">
            {object.status ? <IntlMessages id="propertyPage.modal.removeUser" /> : <IntlMessages id="propertyPage.modal.cancelInvite" />}
          </Button>
        );
      },
    },
  ];
   
  const handleCancelInvite = (object) => {
    setModalLoading(true);
    propertyService.cancelInviteUser(props.propertyId ? props.propertyId  :activePropertyId, object.id).then(res => {
      if(res.code === '0000'){
        updateDataTable();
      }
    });
  }

  const renderOptionsPermission = () => {
    let options = [];
    _.forEach(permissions, (permission) => {
      options.push(
        <Option key={permission.id} value={permission.slug}>{permission.name}</Option>
      );
    })
    return (
      <Select
        placeholder="Select a permission"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        setModalLoading(true);
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    propertyService.inviteUser(props.propertyId ?props.propertyId :activePropertyId, values).then(res => {
      if(res.code === '0000'){
        updateDataTable();
        setFields([]);
        if (props.visibleModal) {
          props.setvisibleModal(false);
        }
        form.resetFields();
        setLoading(false);
      }
    });
  }

  return (
    <PropertyModalWrapper
      className="user-access-modal"
      forceRender={true}
      open= {props.visibleUsermodal ? props.visibleUsermodal :props.visible  || props.visibleModal}
     
      title={props.visibleModal ? 'Invite Visitor To Property' :<IntlMessages id="propertyPage.modal.userAccess" />}
      onCancel={cancelModal}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" loading={loading} onClick={handleSubmit}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      {
       props.visibleModal ? '' :
          dataList.length > 0 &&
          <Table 
            columns={columns} 
            rows={dataList}
            containerId="containerBox"
            showPagination={false}
            rowSize={99999}
          />
      }

      <Form
        form={form}
        fields={fields}
        name="inviteUser"
        layout="vertical"
        scrollToFirstError
      >
        {
          showForm &&
          <div>
            <div className="form-title">
              <h3>Invite User Form</h3>
            </div>
            <div className="form-body">
              <Form.Item
                name="user_name"
                label="User Name"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
              <Input size="large" placeholder="Input user name"/>
              </Form.Item>
              <Form.Item
                name="user_email"
                className="isoInputWrapper"
                label="User Email"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid Email',
                  },
                  () => ({
                    validator(rule, value) {
                      if(value)
                        return userService.checkEmailExistsInProperty(value,props.propertyId ? props.propertyId :activePropertyId);
                      else
                        return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input className="pac-target-input" size="large" placeholder="Input user email"/>
              </Form.Item>
              <Form.Item
                name="permission"
                label="Permission"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                {renderOptionsPermission()}
              </Form.Item>
            </div>
          </div>
        }
      </Form>
    </PropertyModalWrapper>
  );
}

export default UserAccessModal;