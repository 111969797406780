import React, { useState, useEffect } from "react";
import { weatherDurationTomorrowIo } from "@iso/constants/weatherDurationTomorrowIo";
import { weatherCategory } from "@iso/constants/weatherCategory";
import { Select, Switch } from "antd";
import { ReactComponent as PrecipTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/precip-tomorrowio.svg";
import { ReactComponent as TemperatureTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/temperature-tomorrowio.svg";
import { ReactComponent as FeelsLikeTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/feels-like-tomorrowio.svg";
import { ReactComponent as HumidityTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/humidity-tomorrowio.svg";
import { ReactComponent as WindSpeedTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/wind-speed-tomorrowio.svg";
import { ReactComponent as WindGustTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/wind-gust-tomorrowio.svg";
import { ReactComponent as WindDirectionTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/wind-direction-tomorrowio.svg";
import { ReactComponent as PressureTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/pressure-tomorrowio.svg";
import { ReactComponent as RainAccTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/rain-acc-tomorrowio.svg";
import { ReactComponent as VisibilityTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/visibility-tomorrowio.svg";
import { ReactComponent as CloudCoverTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/cloud-cover-tomorrowio.svg";
import { ReactComponent as CloudBaseTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/cloud-base-tomorrowio.svg";
import { ReactComponent as CloudCeilingTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/cloud-ceiling-tomorrowio.svg";
import _ from "lodash";
import { weatherDataService } from "@iso/services";
import WeatherForecastChartWrapper from "./WeatherForecastChart.styles";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { commonHelper } from "@iso/lib/helpers/commonHelper";

const { Option } = Select;

const WeatherForecast = (props) => {
  const [weatherTypes, setWeatherTypes] = useState({
    precipitationIntensity: {
      key: "precipitationIntensity",
      label: "Precip. Intensity",
      value: "",
      unit: "mm/hr",
      icon: PrecipTomorrowio,
      category: weatherCategory.rain.value,
    },
    temperature: {
      key: "temperature",
      label: "Temperature",
      value: "",
      unit: "°C",
      icon: TemperatureTomorrowio,
      category: weatherCategory.temp.value,
    },
    temperatureApparent: {
      key: "temperatureApparent",
      label: "Feels Like",
      value: "",
      unit: "°C",
      icon: FeelsLikeTomorrowio,
      category: weatherCategory.temp.value,
    },
    humidity: {
      key: "humidity",
      label: "Humidity",
      value: "",
      unit: "%",
      icon: HumidityTomorrowio,
      category: weatherCategory.temp.value,
    },
    windSpeed: {
      key: "windSpeed",
      label: "Wind Speed",
      value: "",
      unit: "knots",
      icon: WindSpeedTomorrowio,
      category: weatherCategory.wind.value,
    },
    windGust: {
      key: "windGust",
      label: "Wind Gust",
      value: "",
      unit: "knots",
      icon: WindGustTomorrowio,
      category: weatherCategory.wind.value,
    },
    windDirection: {
      key: "windDirection",
      label: "Wind Direction",
      value: "",
      unit: "",
      icon: WindDirectionTomorrowio,
      category: weatherCategory.wind.value,
    },
    pressureSeaLevel: {
      key: "pressureSeaLevel",
      label: "Pressure",
      value: "",
      unit: "",
      icon: PressureTomorrowio,
      category: weatherCategory.rain.value,
    },
    rainAccumulation: {
      key: "rainAccumulation",
      label: "Rain Acc",
      value: "",
      unit: "mm",
      icon: RainAccTomorrowio,
      category: weatherCategory.rain.value,
    },
    visibility: {
      key: "visibility",
      label: "Visibility",
      value: "",
      unit: "km",
      icon: VisibilityTomorrowio,
      category: weatherCategory.rain.value,
    },
    cloudCover: {
      key: "cloudCover",
      label: "Cloud Cover",
      value: "",
      unit: "%",
      icon: CloudCoverTomorrowio,
      category: weatherCategory.cloud.value,
    },
    cloudBase: {
      key: "cloudBase",
      label: "Cloud Base",
      value: "",
      unit: "ft",
      icon: CloudBaseTomorrowio,
      category: weatherCategory.cloud.value,
    },
    cloudCeiling: {
      key: "cloudCeiling",
      label: "Cloud Ceiling",
      value: "",
      unit: "ft",
      icon: CloudCeilingTomorrowio,
      category: weatherCategory.cloud.value,
    },
  });
  const [activeType, setActiveType] = useState("precipitationIntensity");
  const [dataset, setDataset] = useState([]);
  const [tomorrowioData, setTomorrowioData] = useState({});

  const renderOptionsWeatherDuration = () => {
    let options = [];
    _.forEach(weatherDurationTomorrowIo, (duration, index) => {
      options.push(
        <Option key={index} value={duration.value} className="property-option">
          {duration.label}
        </Option>
      );
    });

    return (
      <Select
        placeholder="Select Duration"
        size="medium"
        style={{ width: 150 }}
        onChange={(value) => props.setSelectedDuration(value)}
        value={props.selectedDuration}
      >
        {options}
      </Select>
    );
  };

  const handleWeatherData = (res) => {
    let data = res?.weather_data?.data?.timelines;
    if (res.code === "0000" && data) {
      data = data[0].intervals;
      const weatherData = [];
      data.forEach((forecastData) => {
        _.map(forecastData.values, (value, key) => {
          let newValue = value;

          if (["cloudBase", "cloudCeiling"].includes(key)) {
            newValue = Math.round(commonHelper.kmToFeet(newValue));
          }

          if (weatherData[key]) {
            weatherData[key].push({
              time: forecastData.startTime,
              value: newValue,
            });
          } else {
            weatherData[key] = [
              {
                time: forecastData.startTime,
                value: newValue,
              },
            ];
          }
        });
      });
      setTomorrowioData(weatherData);
      if (weatherData[activeType]) {
        setDataset(weatherData[activeType]);
      } else {
        setDataset([]);
      }
    } else {
      setTomorrowioData([]);
    }
    props.setTimezone(res?.timezone ? res.timezone : "");
  };

  const handleCurrentWeatherData = (res) => {
    let data = res?.weather_data?.data?.timelines;
    if (res.code === "0000" && data) {
      let newWeatherTypes = { ...weatherTypes };
      const intervals = data[0].intervals;
      let currentWeather = intervals[intervals.length - 1].values;
      _.map(currentWeather, (value, key) => {
        let newValue = value;

        if (["cloudBase", "cloudCeiling"].includes(key)) {
          newValue = Math.round(commonHelper.kmToFeet(newValue));
        }
        
        if (newWeatherTypes[key]) {
          newWeatherTypes[key].value = newValue;
        }
      });
      setWeatherTypes(newWeatherTypes);
    }
    props.setTimezone(res?.timezone ? res.timezone : "");
  };

  useEffect(() => {
    if (props.weatherData) {
      handleWeatherData(props.weatherData);
    }
  }, [props.weatherData]);

  useEffect(() => {
    if (props.currentWeatherData) {
      handleCurrentWeatherData(props.currentWeatherData);
    }
  }, [props.currentWeatherData]);

  useEffect(() => {
    if (tomorrowioData[activeType]) {
      setDataset(tomorrowioData[activeType]);
    } else {
      setDataset([]);
    }
  }, [activeType]);

  useEffect(() => {
    if (props.weatherCategory) {
      for(let key in weatherTypes) {
        if(weatherTypes[key].category === props.weatherCategory) {
          setActiveType(key);
          break;
        }
      }
    }
  }, [props.weatherCategory]);

  return (
    <WeatherForecastChartWrapper>
      <div className="weather-statistic">
        <h1 className="weather-category">
          {weatherCategory[props.weatherCategory]["label"]}
        </h1>
        <div className="statistic-header">
          <div className="timezone-by">
            <p>Timezone {props.timezone ? `(${props.timezone})` : ""} : </p>
            <Switch
              checkedChildren="Property"
              unCheckedChildren="Local"
              checked={props.isPropertyTimezone}
              onChange={(value) => props.setIsPropertyTimezone(value)}
            />
          </div>
          {renderOptionsWeatherDuration()}
        </div>
        <div className="weather-types">
          {_.map(weatherTypes, (type) => {
            if (type.category === props.weatherCategory) {
              return (
                <div
                  className={`weather-type-element ${
                    activeType === type.key ? "weather-type-active" : ""
                  }`}
                  onClick={() => setActiveType(type.key)}
                >
                  <div className="weather-type-icon">
                    <type.icon
                      width={24}
                      height={24}
                      fill={`${activeType === type.key ? "#0B2A1B" : ""}`}
                    ></type.icon>
                  </div>
                  <div>
                    <p>{type.label}</p>
                    <p>
                      {type.value} <span>{type.unit}</span>
                    </p>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={dataset}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis unit={" " + weatherTypes[activeType]["unit"]} />
          <Tooltip />
          <Legend />
          <Line
            name={weatherTypes[activeType]["label"]}
            type="monotone"
            dataKey="value"
            stroke="#0B2A1B"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </WeatherForecastChartWrapper>
  );
};

export default WeatherForecast;
