import axios from "axios";
import { apiEndpoint } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const visitorFormService = {
  getList,
  getVisitorForm,
  createVisitorForm,
  getSurveyQuestion,
  getVisitorFormByProperty,
  uploadImage
};

function getList(formId, params={}){
  return (
    axios
      .get(
        `${apiEndpoint.visitorForm}?form_id=${formId}`,
        {
          headers: authHeader(),
          params: params
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getVisitorForm(token){
  return (
    axios
      .get(
        `${apiEndpoint.visitorForm}/get-visitor-form?token=${token}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function getVisitorFormByProperty(property_id, params= {}){
  return (
    axios
      .get(
        `${apiEndpoint.visitorForm}/get-visitor-form-by-property?property_id=${property_id}`,
        {
          params,
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}


function createVisitorForm(data){
  return (
    axios
    .post(
      `${apiEndpoint.visitorForm}/create-visitor-form`,
      data
    ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function uploadImage(data){
  return (
    axios
    .post(
      `${apiEndpoint.visitorForm}/upload-image`,
      data
    ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getSurveyQuestion(visitorFormToken){
  return (
    axios
      .get(
        `${apiEndpoint.visitorForm}/get-survey-question?visitor_form_token=${visitorFormToken}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}