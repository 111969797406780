export const alarmCategories = {
    global: {
      label: 'Global',
      key: 'Global',
    },
    activeAreas: {
      label: 'Active Areas',
      key: 'ActiveAreas',
    },
    propertyAreas: {
      label: 'Property Areas',
      key: 'PropertyAreas',
    },
  };