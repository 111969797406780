import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
// import Box from '@iso/components/utility/box';
import { useHistory } from 'react-router-dom';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
import { Statistic, Card, Row, Col, Button, Collapse, List } from 'antd';
import MarketplacePage from './Marketplace.styles';
import ProductHome from './ProductHome';
import ProductModal from './ProductModal';
import { ArrowUpOutlined, ArrowDownOutlined, HomeFilled, PlusOutlined } from '@ant-design/icons';
import { marketplaceCategoryService, marketplaceProductService } from '@iso/services';
import moment from 'moment';
import _ from 'lodash';

const { Meta } = Card;
const { Panel } = Collapse;

const ProductList = () => {
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const category_id = urlParams.get('category_id');

  let history = useHistory();
  const dispatch = useDispatch();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activeProperty = localStorage.getItem('active_property');
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [visibleProductModal, setVisibleProductModal] = useState(false);
  const [productCategories, setProductCategories] = useState({});
  const [products, setProducts] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState("");

  const getProductCategories = () => {
    marketplaceCategoryService.getList().then(res => {
      if(res.code === '0000'){
        setProductCategories(res.marketplace_categories);
      }
    });
  }

  const getProducts = () => {
    marketplaceProductService.getList(null, category_id).then(res => {
      if(res.code === '0000'){
        setProducts(res.marketplace_products);
      }
    });
  }

  useEffect(() => {
      getProductCategories();
      getProducts();
  }, []) 

  useEffect(() => {
    if(category_id && productCategories)
    {
      let selected_category = "";
      _.forEach(productCategories, (option, index) => {
        if(option.id == category_id)
        {
          selected_category = option.name;
        }
      })
      setCategoryTitle(selected_category);
    }
  }, [categoryTitle, productCategories])

  const showProductModal = () => {
    setVisibleProductModal(true);
  }

  const cancelProductModal = () => {
    setVisibleProductModal(false);
  }

  const showProductDetail = (product_id) => {
    history.push('/product-detail?product_id=' + product_id);
  }

  return (
    <LayoutWrapper>
      <MarketplacePage>
        <PageHeader>
          {<IntlMessages id="productListPage.managePage.header" />}{categoryTitle != "" ? " > " + categoryTitle : ""}
        </PageHeader>
        <br/>
        <Row  className="product-detail-page">
          <Col xs={{span: 24}} md={{span: 5}}>
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Filter" key="1">
              
              </Panel>
            </Collapse>
            <br/>
          </Col>
          <Col xs={{span: 24}} md={{span: 17, offset: 1}}>
            <div className="site-card-wrapper">
                <List
                  dataSource={products}
                  pagination={{pageSize: 12}}
                  grid={{gutter: 16, column: 4, lg: 2, md:4, xs:1 }}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        className="product-card"
                        hoverable
                        cover={<img alt="example" src={item.images && item.images.length > 0 ? 
                          process.env.REACT_APP_API_BASE_URL + item.images[0].path 
                          : "https://www.usu.edu/involvement/images/robinsimages2020/Profile-Photo-Unavailable.png" } />}
                        onClick={() => showProductDetail(item.id)}
                      >
                      <Meta title={item.title} description={moment(item.created_at, "YYYYMMDD").fromNow()}/>
                      <p className="product-home-price">&#36; {new Intl.NumberFormat().format(item.price)}</p>
                      </Card>
                    </List.Item>                    
                  )}
                />
            </div>
          </Col>
        </Row>
      </MarketplacePage>
    </LayoutWrapper>
  );
}

export default ProductList;
