import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Dropdown, Menu, Popconfirm,Table } from 'antd';
import CategoryFormPage from './CategoryForm.styles';
import { PlusOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import { categoryGlobalFormService } from '@iso/services'
import ModalCategoryForm from './ModalCategoryForm';

const CategoryForm = () => {
  const [dataSource, setDataSource] = useState([]);
  const [visibleCategoryFormModal, setVisibleCategoryFormModal] = useState(false);
  const [categoryId, setCategoryId] = useState('');
  const [dataCategoryForm, setDataCategoryForm] = useState([]);
  useEffect(() => {
     getCategoryForm();
  }, []);

  const getCategoryForm = () => {
    categoryGlobalFormService.get().then(res => {
      if(res.code === "0000"){
        setDataSource(res.category_global_form);
      }
    })
  }

  const columns = [
    {
      title: <IntlMessages id="adminPage.categoryForm.name" />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <IntlMessages id="adminPage.categoryForm.description" />,
      dataIndex: 'description',
      key: 'description',
    },   
    {
      title: <IntlMessages id="adminPage.categoryForm.status" />,
      dataIndex: 'is_live',
      key: 'is_live',
      render: (_, record) => (
        <>
          {record.is_live == 0 ? 'Draft' : 'Live'}
        </>
      )
    },
    {
      title: <IntlMessages id="adminPage.categoryForm.action" />,
      key: 'action',
      render: (_, record) => (
        <Dropdown overlay={menu(record.id)} trigger={['click']}>
          <Button className="btn-success ant-btn-primary">
           <span><IntlMessages id="antTable.title.actions" /></span> <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },   
  ];

  const menu = (id) => (
    <Menu onClick={handleMenuClick.bind(this, id)}>
      <Menu.Item key="editCategoryForm" icon={<EditOutlined />}>
        <IntlMessages id="adminPage.categoryForm.edit" />
      </Menu.Item>
      <Popconfirm
        className="ant-popconfirm"
        placement="bottomRight"
        title="You are about to remove individual record"
        onConfirm={() => {
          confirmRemove(id);
        }}
        okText="Yes"
        cancelText="No"
        className="delete-button"
      >
      <a style={{color:'rgba(0, 0, 0, 0.65)', marginLeft: '11px'}}>
        {<DeleteOutlined />}&nbsp;&nbsp;
        <IntlMessages id="adminPage.categoryForm.remove" />
      </a>
      </Popconfirm>

    </Menu>
  ); 

  const handleMenuClick = (id,e) => {
    if(e.key === "editCategoryForm"){
      setVisibleCategoryFormModal(true);
      setCategoryId(id);
      viewCategoryForm(id);
    }
  }

  const confirmRemove = (id) => {
    categoryGlobalFormService.destroy(id).then(res => {
      if(res.code === "0000"){
        getCategoryForm();
      }
    })
  }

  const viewCategoryForm = (id) => {
    categoryGlobalFormService.view(id).then(res => {
      if(res.code === "0000"){
        setDataCategoryForm(res.category_global_form);
      
      }
    })
  }

  return (
    <LayoutWrapper>
      <CategoryFormPage>
        <PageHeader>
            {<IntlMessages id="sidebar.categoryForm" />}
        </PageHeader>
        <div className="button-group">
          <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={() =>setVisibleCategoryFormModal(true)}>
            <IntlMessages id="adminPage.categoryForm.add" />
          </Button>
        </div>
        <Box id="containerBox">
          <Table dataSource={dataSource} columns={columns} size="small" pagination={{pageSize: 12}} /> 
        </Box> 
      </CategoryFormPage>
      <ModalCategoryForm 
        visibleCategoryFormModal={visibleCategoryFormModal}
        setVisibleCategoryFormModal={setVisibleCategoryFormModal}
        setCategoryId={setCategoryId}
        categoryId={categoryId}
        getCategoryForm={getCategoryForm}
        dataCategoryForm={dataCategoryForm}
      />
    </LayoutWrapper>
  );
}

export default CategoryForm;
