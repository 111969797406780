import React from 'react';

const ManageMob = () => {
  return (
    <div className="sidebar-icon">
      <svg
      className="sidebar-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24" 
        fill = 'none'
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
        d="M22.6369 8.22152C22.1481 8.30066 21.1147 7.86307 19.8764 7.06238C19.1269 6.57358 18.1912 6.44789 17.3766 6.72255C17.1997 6.78307 17.0181 6.85289 16.8319 6.93203C16.8179 6.9041 16.804 6.87151 16.79 6.84358C17.5581 5.45634 17.7955 4.19013 17.4929 3.07754C17.1112 1.68564 16.0219 1.07115 15.9754 1.04788C15.8264 0.968739 15.6448 0.973394 15.5052 1.06184C15.3609 1.15029 15.2771 1.31322 15.2864 1.48081C15.44 4.45082 13.6431 4.69754 13.401 4.71616H10.6498C10.4124 4.69754 8.61551 4.45082 8.76448 1.48081C8.77379 1.31322 8.68999 1.15029 8.54568 1.06184C8.40137 0.973394 8.21982 0.968739 8.07551 1.04788C8.02896 1.07115 6.93499 1.68564 6.55792 3.07754C6.25067 4.20409 6.50205 5.49358 7.29344 6.89945C7.27482 6.93203 7.26085 6.96462 7.24223 6.9972C7.04206 6.90876 6.84188 6.82496 6.6324 6.75048C5.8224 6.47582 4.88671 6.60151 4.12791 7.09031C2.88498 7.89566 1.85618 8.32859 1.36739 8.24945C1.17187 8.21686 0.981005 8.30997 0.887902 8.48221C0.794798 8.65445 0.813419 8.86859 0.943764 9.0129C1.73049 9.91601 2.50325 10.5631 3.31325 11.0053C3.87188 11.3033 4.49567 11.4801 5.16136 11.5314L6.12964 11.6151C6.14826 11.671 6.16223 11.7269 6.18085 11.7827L6.33447 12.2157C6.84188 13.6448 7.32137 14.9948 7.49361 16.1912C7.11188 16.6474 6.89775 17.1734 6.89775 17.7414C6.89775 19.5662 9.1462 21 12.0185 21C14.8907 21 17.1392 19.5662 17.1392 17.7414C17.1392 17.1781 16.925 16.6521 16.5433 16.1912C16.7155 14.9948 17.195 13.6448 17.7024 12.2157L17.8561 11.7827C17.8793 11.7129 17.9026 11.6431 17.9212 11.5733L18.8336 11.4988C19.5226 11.4383 20.1464 11.2614 20.6911 10.9681C21.4964 10.5351 22.2692 9.88808 23.0559 8.98497C23.1862 8.836 23.2095 8.62652 23.1118 8.45428C23.0187 8.28669 22.8278 8.19359 22.6369 8.22152ZM16.1895 2.49564C16.3478 2.71443 16.5014 2.98909 16.5945 3.32426C16.7993 4.06444 16.6736 4.92565 16.236 5.88462C16.0079 5.53082 15.7519 5.19099 15.454 
        4.86978C15.3981 4.80461 15.319 4.76737 15.2398 4.74409C15.6867 4.27392 16.0731 3.57099 16.1895 2.49564ZM7.45171 3.34288C7.54482 2.99375 7.69844 2.71443 7.86137 2.49099C7.97775 3.58961 8.37809 4.30651 8.83896 4.77668C8.79241 4.79996 8.74586 4.82789 8.70861 4.86978C8.39206 5.21427 8.1174 5.58203 7.85671 5.96841C7.38654 4.98151 7.24688 4.10168 7.45171 3.34288ZM6.21809 9.24566C6.04119 9.69256 5.96205 10.186 5.97602 10.6748L5.2405 10.6096C4.69584 10.5677 4.19774 10.4234 3.7555 10.1907C3.25274 9.92066 2.76394 9.54825 2.26584 9.06411C2.91291 8.87325 3.70429 8.47755 4.63533 7.87704C5.15671 7.54186 5.78981 7.45341 6.33447 7.63497C6.50206 7.69083 6.66964 7.76066 6.83257 7.83048C6.61843 8.28204 6.41826 8.74755 6.21809 9.24566ZM12.0231 20.0783C9.75137 20.0783 7.83344 19.0122 7.83344 17.7507C7.83344 16.4891 9.75137 15.4231 12.0231 15.4231C14.2948 15.4231 16.2128 16.4891 16.2128 17.7507C16.2128 19.0122 14.2948 20.0783 12.0231 20.0783ZM16.9855 11.4801L16.8319 11.9131C16.385 13.1653 15.9614 14.3617 15.7286 15.4836C14.8022 14.8691 13.4988 14.4921 12.0231 14.4921C10.5521 14.4921 9.24396 14.8691 8.31758 15.4836C8.08482 14.3617 7.6612 13.1653 7.2143 11.9131L7.06068 11.4801C6.84188 10.8657 6.84654 10.1767 7.08395 9.59014C7.74499 7.94221 8.34551 6.69927 9.25793 5.6472H14.9C15.2631 6.07082 15.561 6.53169 15.831 7.02979C15.845 7.06703 15.8636 7.10893 15.8916 7.14152C16.264 7.85376 16.5805 8.63118 16.9204 9.47376L16.9669 9.59014C17.195 10.1767 17.2043 10.8657 16.9855 11.4801ZM20.2535 10.1534C19.8205 10.3862 19.3178 10.5305 18.7592 10.577L18.0702 10.6329C18.0795 10.1534 18.0004 9.67859 17.8281 9.241L17.7816 9.12462C17.5954 8.65445 17.4138 8.20755 17.2229 7.77928C17.3719 7.7141 17.5209 7.65359 17.6698 7.60703C18.2192 7.42083 18.8523 7.50927 19.369 7.84445C20.3 8.44497 21.0914 8.84531 21.7385 9.03152C21.2404 9.51566 20.7516 9.88342 20.2535 10.1534Z"
       
        style={{strokeWidth:'1', strokeLinecap:'round',strokeLinejoin:'round'}}
        />
      </svg>
    </div>
  );
}
export default ManageMob;