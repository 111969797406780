import React from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from '@iso/components/utility/intlMessages';
import ForgotPassword from './ForgotPassword.styles';
import Alert from '@iso/components/Feedback/Alert';
import { Form, Input, Button, Row, Col } from 'antd';
import { userService } from '@iso/services';
import logo from '@iso/assets/images/logo.png';
import { handleShowRandomImage } from "@iso/constants/randomBackgrounds";

let images = handleShowRandomImage();
export default function () {
  const [msgAlert, setMsgAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("");
  const [form] = Form.useForm();
  const marginBot = {
    marginBottom: '10px',
  };

  const onFinish = (values) => {
    setMsgAlert("");
    userService.forgotPassword(values.email).then(res => {
      if(res.code === '0000'){
        setTypeAlert('success');
        setMsgAlert('Your request has been sent');
      } else {
        setTypeAlert('error');
        setMsgAlert(res.message);
      }
      form.resetFields();
    });
  };

  return (
    <Row className = "signInmain row" style={{padding:"12px",height:'100vh', justifyContent: 'center'}} >
     <Col xl={18} md={24} >
     <img
          src={`${images}`}
          height="100%"
          width="100%"
          style={{ borderRadius: "12px" }}
        />
     </Col>
     <Col xl={6} md={24} sm={24}>
     <ForgotPassword >
    
        <div className="isoFormContent">
        <div className='signin-form-main'>
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <img src={logo} height="54"/>
            </Link>
          </div>
          <div className="isoFormHeadText">
            <h3>
              <IntlMessages id="page.forgotPasswordSubTitle" />
            </h3>
            <p>
              <IntlMessages id="page.forgotPasswordDescription" />
            </p>
          </div>
          {
            msgAlert && 
            <Alert
              message={msgAlert}
              type={typeAlert}
              style={marginBot}
            />
          }
          <div className="isoForgotPassForm">
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                className="isoInputWrapper"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid Email!',
                  },
                  {
                    required: true,
                    message: 'Please input your Email!',
                  },
                ]}
              >
                <Input size="large" placeholder="Email"/>
              </Form.Item>

              <div className="isoInputWrapper">
                <Button type="primary" htmlType="submit" className="btn-success">
                  <IntlMessages id="page.resetPassSubTitle" />
                </Button>
              </div>
            </Form>
            <div className="isoCenterComponent isoHelperWrapper">
  <Link to="/signin" className="isoForgotPass">
    <IntlMessages id="page.resendEmailBackToSignIn" />
  </Link>
</div>

          </div>
        </div>
                </div>
    </ForgotPassword>
     </Col>
    </Row>
  
  );
}
