import moment from 'moment';

export const commonHelper = {
  convertUtcToLocalDateForSar: (date, format) => {
    let utcDate = date + " +00";
    return moment(utcDate).local(true).format(format);
  },

  convertUtcToLocalDate: (date, format) => {
    let utcDate = moment.utc(new Date(date));
    return moment(utcDate).local().format(format);
  },

  generateRandomString: (length = 10) => {
    var characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    var randomString = '';

    for (var i = 0; i < length; i++) {
      randomString = randomString + characters[Math.floor(Math.random() * charactersLength)];
    }
    return randomString;
  },

  generateColorInUse: (daysInUse) => {
    if(daysInUse >= 0 && daysInUse < 10){
      return '#FFE599'
    }else if(daysInUse >= 10 && daysInUse < 20){
      return '#F9CA9C'
    }else if(daysInUse >= 20 && daysInUse < 30){
      return '#F6B26A'
    }else if(daysInUse >= 30 && daysInUse < 40){
      return '#EA9999'
    }else if(daysInUse >= 40 && daysInUse < 50){
      return '#E06666'
    }else{
      return '#CC0000'
    }
  },
  generateRestColor: (restDays) => {
    if(restDays >= 0 && restDays < 10){
      return '#FFF2CC'
    }else if(restDays >= 10 && restDays < 20){
      return '#D9EAD3'
    }else if(restDays >= 20 && restDays < 30){
      return '#B6D7A7'
    }else if(restDays >= 30 && restDays < 40){
      return '#92C47C'
    }else if(restDays >= 40 && restDays < 50){
      return '#69A84F'
    }else{
      return '#33A854'
    }
  },

  dataURLtoFile : (dataUrl, filename) => {
    const extension = dataUrl.split(';')[0].split('/')[1];
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new File([u8arr], `${filename}.${extension}`, { type: mime });
  },

  convertBase64 : (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    })
  },

  kmToFeet: (km) => {
    return km * 3280.84;
  }
}