import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const alertDeviceService = {
  get,
  getAlertDeviceByToken,
  stopDeviceAlarm
};

function get(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.alertDevice}?property_id=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getAlertDeviceByToken(token){
  return (
    axios
      .get(
        `${apiEndpoint.alertDevice}/get-by-token?token=${token}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        return res.data;
      })
  );
}

function stopDeviceAlarm(data){
  return (
    axios
      .post(
        `${apiEndpoint.alertDevice}/stop-alarm-device`,
        data,
        {
          headers: authHeader()
        }
    )
    .then(res => {
      if(res && res.status === 200){
        return res.data;
      }
      return {};
  }));
}

