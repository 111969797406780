import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const PreviewImageModalWrapper = styled(Modal)`
 .ant-modal-body{
    text-align: center
 }
`;

export default WithDirection(PreviewImageModalWrapper);