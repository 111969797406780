import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import { useHistory } from 'react-router-dom';
// import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
// import { configs } from '@iso/components/Tables/configs';
import { Statistic, Card, Row, Col, Button } from 'antd';
import MarketplacePage from './Marketplace.styles';
import ProductHome from './ProductHome';
import ProductModal from './ProductModal';
import { ArrowUpOutlined, ArrowDownOutlined, HomeFilled, PlusOutlined } from '@ant-design/icons';
import { marketplaceCategoryService, marketplaceProductService } from '@iso/services';

const { Meta } = Card;

const Marketplace = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activeProperty = localStorage.getItem('active_property');
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [visibleProductModal, setVisibleProductModal] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const getProductCategories = () => {
    marketplaceCategoryService.getList().then(res => {
      if(res.code === '0000'){
        setProductCategories(res.marketplace_categories);
      }
    });
  }

  const getProducts = () => {
    marketplaceProductService.getList().then(res => {
      if(res.code === '0000'){
        setProducts(res.marketplace_products);
      }
    });
  }

  useEffect(() => {
      getProductCategories();
      getProducts();
  }, []) 

  const showProductModal = () => {
    setVisibleProductModal(true);
  }

  const cancelProductModal = () => {
    setVisibleProductModal(false);
  }

  const showProductList = () => {
    history.push('/product-list');
  }

  const showProductListByCategory = (category_id) => {
    history.push('/product-list?category_id=' + category_id);
  }

  return (
    <LayoutWrapper>
      <MarketplacePage>
        <PageHeader>
          {<IntlMessages id="marketplacePage.managePage.header" />}
        </PageHeader>
        <div className="button-group">
          <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={showProductModal}>
            <IntlMessages id="marketplacePage.managePage.addProduct" />
          </Button>
        </div>
        <h1 className="feature-title">Popular Categories</h1>
        <br/>
        <div className="category-container">
          <div className="category-card-section">
            {(productCategories) ? (productCategories.map((value, index) => {
                    return (
                      <div className="category-card-item">
                        <Card hoverable className="category-card" onClick={() => showProductListByCategory(value.id)}>
                          <Statistic
                            title={<ArrowUpOutlined />}
                            value={value.name}
                            valueStyle={{ color: '#3f8600', fontSize:"20px" }}
                            suffix={"(" + value.product_amount + ")"}
                          />
                        </Card>
                      </div>
                    )
                  }
                )
              ) : ''
            }
          </div>
        </div>
        <br/>
        <h1 className="feature-title">Newest Products</h1>
        <br/>
        <ProductHome products={products}/>
        <Button type="primary" className="btn-success btn-see-all" onClick={showProductList}>See All Products</Button>
        <ProductModal visibleProductModal={visibleProductModal} cancelProductModal={cancelProductModal} 
        productCategories={productCategories} getProducts={getProducts}/>
      </MarketplacePage>
    </LayoutWrapper>
  );
}

export default Marketplace;
