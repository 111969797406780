import React, { useState, useEffect,useRef,useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactFC from "react-froalacharts";
import FroalaCharts from "froalacharts";
import FroalaTheme from "froalacharts/themes/froalacharts.theme.froala";
import { healthSafetyTypes } from '@iso/constants/healthSafetyTypes';
import _ from 'lodash';

ReactFC.fcRoot(FroalaCharts, FroalaTheme);

const AssetChart = (props) => {
  const [chartConfig, setChartConfig] = useState({});
  const dataLabel = [];
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const {data} = props;

  useEffect(() => {
    if(data){
      _.forEach(data.label, function(rlabel){
        healthSafetyTypes.map(function(clabel){
          if(rlabel.label === clabel.value){
            dataLabel.push({'label':clabel.label});
          }
        });
      });
      let width = document.getElementById('health-safety-chart').offsetWidth - 40;
      let height = document.getElementById('health-safety-chart').offsetHeight - 40;;
      setChartConfig({
        //Specify the chart type

        type: 'column',
        
        //Set the container object
        renderAt: 'weather_tab_data',
        
        //Specify chart dimensions
        width: width,
        height: height,
        
        //Set the type of data
        dataSource:
        {
          chart:
          {
            theme: "froala",
            paletteColors: "#0B2A1B",
            xAxisname: "Type",
            yAxisName: "Value",
            plotFillAlpha: "80",
            divLineIsDashed: "1",
            divLineDashLen: "1",
            exportEnabled: "1",
            divLineGapLen: "1",
            
          },
          "categories": [{
            "category": dataLabel
          }],
          "dataset": [{
            "data": data.value
          }],
        }
      }); 
          
    }
  }, [data]);
 
  return(
    <div>
       <ReactFC {...chartConfig} />
    </div>
  )
}
export default AssetChart;