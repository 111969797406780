import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const DashboardWrapper = styled.div`
  h2 {
    font-size: 0.8em;
  }
  .admin-dashboard-title{
  	text-align: center;
  	font-size: 30px;
  }
`;

export default WithDirection(DashboardWrapper);