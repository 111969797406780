
import styled from "styled-components";
import { palette } from "styled-theme";
import { transition, borderRadius } from "@iso/lib/helpers/style_utils";
import WithDirection from "@iso/lib/helpers/rtl";

const SidebarWrapper = styled.div`
.close{
  display:none;
}

  .isomorphicSidebar {
    z-index: 1000;
    background: #ffffff;
    // width: 240px !important;
    flex: 0 0 280px;
max-width: none !important;
min-width : none !important;
    .scrollarea {
      height: calc(100vh - 70px);
    }
  //   a#custom-menu-icon svg path {
  //     stroke:black;
  // }
    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    @media only screen and (max-width: 480px) {
      width: 375px !important;
      min-width: 375px !important;
      max-width: 375px !important;
      flex: 0 0 375px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }
    .triggerBtn {
      cursor: pointer;
      position: absolute;
      top: 88px;
      right: -10px;
      @media only screen and (max-width: 767px) {
        display:none;
      }
      img {
        margin-right: 0 !important;
      }
    }
    .isoLogoWrapper {
      border-bottom: 2px solid rgba(143, 146, 161, 0.05);
      height: 70px;
      background: #fff;
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a {
          font-size: 21px;
          font-weight: 300;
          line-height: 70px;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: ${palette("grayscale", 6)};
          display: block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

  

    .isoDashboardMenu {
      padding-top: 35px;
      padding-bottom: 35px;
      background: transparent;

      a {
        text-decoration: none;
        font-weight: 400;
      }
  

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
        margin: 0;
      }

      .isoMenuHolder {
        display: flex  !important;
        align-items: center !important;

        span.nav-text:hover {
          color: #0B2A1B !important;
        }
        i {
          font-size: 19px;
          color: inherit; 
          margin: ${(props) =>
    props["data-rtl"] === "rtl" ? "0 0 0 30px" : "0 30px 0 0"};
          width: 18px;
          ${transition()};
        }
      }

      
     
      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 14px;
        color: #8f9497;
        font-weight: 400;
        ${transition()};
      }

      .ant-menu-item-selected {
        @media only screen and (max-width: 480px) {
          background-color: transparent !important;
          border-right: none;
        }
        span.ant-menu-title-content {
          @media only screen and (max-width: 480px) {
            background-color: #f7f9f6 !important;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 10px;
          }
        }
        .anticon {
          color: #fff;
        }

        i {
          color: #fff;
        }

        .nav-text {
          color: #ffffff;
          @media only screen and (max-width: 480px) {
            color: #8f9497;
          }
        }
        .sidebar-icon {
          
          svg {
          
            path {
              @media only screen and (max-width: 480px) {
                fill: unset;
                rgb(143, 148, 151) !important;
              }
            }
          }
        }
      }

      > li {
        &:hover {
          i,
          .nav-text {
            color: #0B2A1B;
            @media only screen and (max-width: 480px) {
              color: #0B2A1B !important;
            }
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette("secondary", 5)};
    }

  //   .gHEeuw .isomorphicSidebar .ant-menu-item: a {
  //     color: rgb(143, 148, 151) !important;
  // }
    .ant-menu-item {
      span.ant-menu-title-content {
        @media only screen and (max-width: 480px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      &:hover {
        a {
          color: #0B2A1B !important;
          .isoMenuHolder {
            .nav-text {
              color: #0B2A1B !important;
              @media only screen and (max-width: 480px) {
                color: #0B2A1B !important;
              }
            }
          }
          
        }
      }
    }
    .ant-menu-item.ant-menu-item-selected {
      &:hover {
        a {
          color: #ffffff !important;
          .isoMenuHolder {
            .nav-text {
              color: #0B2A1B !important;
              @media only screen and (max-width: 480px) {
                color: #0B2A1B !important;
              }
            }
          }
        }
      }
    }
    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 24px;

        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${(props) => (props["data-rtl"] === "rtl" ? "25px" : "auto")};
          right: ${(props) => (props["data-rtl"] === "rtl" ? "auto" : "25px")};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }

          ${"" /* &:after {
            content: '\f123';
            font-family: 'Ionicons' !important;
            font-size: 16px;
            color: inherit;
            left: ${props => (props['data-rtl'] === 'rtl' ? '16px' : 'auto')};
            right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '16px')};
            ${transition()};
          } */
  };
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: #ffffff;
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${(props) =>
    props["data-rtl"] === "rtl" ? "0px !important" : "74px !important"};
          padding-right: ${(props) =>
    props["data-rtl"] === "rtl" ? "74px !important" : "0px !important"};
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          &:hover {
            a {
              color: #ffffff !important;
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
  .mailmainBox{
    width:  47.968px;
            height: 45px;
            margin-left: 90px;
            border-radius: 16px;
            background-color: #0B2A1B !important;
            z-index : 1;
            position : relative;
 
  }
  .subDivBox{
    margin-top:-20px;
    margin-left:22px;
    margin-bottom:10px;
    width: 180.865px;
    height: 180px;
    flex-shrink: 0;
    border-radius: 24px;
    // opacity:  0.04999999701976776;
    background-color: rgba(247, 249, 246, 1);

    
  }
  .infoBox{

    

    padding-top: 40px;
    padding-left: 30px;
   
               width: 150.897px;
              color: var(--black-b-100, #171717);
              text-align: center;
              font-family: DM Sans;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 137.5% */
              letter-spacing: -0.4px;

        .pro{
          font-family: DM Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: center;
        }
  }
  .infoBtn{
    margin: 20px;
    width: 140.897px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    border: none;
    color:white;
    background-color: var(--table-title, #0B2A1B );
  }
  .arrowImg{
    margin:10px;
    width: 23.984px;
height: 24px;
flex-shrink: 0;
  }
  @media only screen and (max-width: 480px) {
    .isomorphicSidebar .isoLogoWrapper{
      width:180px !important;
    }
    .isomorphicSidebar.ant-layout-sider-collapsed .isoLogoWrapper{
      width:auto !important;
    }
  .triggerBtn{
    display:block !important;
    right:20px !important;
    top:24px;
  }
}
`;

export default WithDirection(SidebarWrapper);
