import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const propertyService = {
  getProperties,
  viewProperty,
  storeOrUpdateProperties,
  getListUsersOfProperty,
  inviteUser,
  cancelInviteUser,
  getAllProperties,
  active,
  activateSharingMap,
  viewPropertyByToken,
  updateDeviceTime,
  getStripePortalUrl,
  getPropertyMetaByToken,
  requestFreeSetup,
  getGeneralStatistic,
  getPaginatedProperties
};

function getProperties(){
  return (
    axios
    .get(
      apiEndpoint.properties,
      {
        headers: authHeader()
      }
    )
    .then(res => {
      if(res && res.status === 200){
        return res.data;
      }
      return [];
    })
  );
}

function storeOrUpdateProperties(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.properties(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.properties,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function viewProperty(id){
  return (
    axios
      .get(
        apiEndpointFunction.properties(id),
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function getGeneralStatistic(){
  return (
    axios
      .get(
        `${apiEndpoint.properties}/get-general-statistic`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function getListUsersOfProperty(propertyId, isAllInvitedUsers = false){
  return (
    axios
      .get(
        `${apiEndpoint.users}?property_id=${propertyId}${isAllInvitedUsers ? '&is_all_invited_users=1' : ''}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          let users = res.data.users.map(user => {
            return {
              ...user,
              id: user.id,
              name: user.name,
              email: user.email,
              mobile_phone: user.mobile_phone,
              permission: user.more_info.permission,
              permission_id: user.more_info.permission_id,
              user_property_id: user.more_info.user_property_id,
              status: user.more_info.status,
              status_label: user.more_info.status ? 'Accepted' : 'Pending',
              invited_token: user.more_info.invited_token,
              permission_slug: user.more_info.permission_slug,
              invitation_link: user.more_info.invitation_link,
            }
          });

          return {
            users: users,
            code: res.data.code,
          };
        }
        return {};
      })
  );
}

function inviteUser(id, data){
  return (
    axios
      .post(
        `${apiEndpointFunction.properties(id)}/invite`,
        data,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function requestFreeSetup(id){
  return (
    axios
      .post(
        `${apiEndpointFunction.properties(id)}/send-free-setup-request`,
        {},
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function cancelInviteUser(id, userId){
  return (
    axios
      .get(
        `${apiEndpointFunction.properties(id)}/cancel-invite`,
        {
          headers: authHeader(),
          params: {
            user_id: userId
          }
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function getAllProperties(){
  return (
    axios
      .get(
        `${apiEndpoint.getAllProperties}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function active(id){
  return (
    axios
      .post(
        `${apiEndpointFunction.properties(id)}/active`,
        null,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function activateSharingMap(id, data){
  return (
    axios
      .post(
        `${apiEndpointFunction.properties(id)}/activate-sharing-map`,
        data,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function updateDeviceTime(id,data){
  return (
    axios
      .post(
        `${apiEndpointFunction.properties(id)}/update-device-time`,
        data,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function viewPropertyByToken(token = ''){
  return (
    axios
      .get(
        `${apiEndpoint.properties}/view-property-by-token?token=${token}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function getStripePortalUrl(id){
  return (
    axios
      .get(
        `${apiEndpoint.properties}/${id}/get-stripe-portal-url`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function getPropertyMetaByToken(token){
  return (
    axios
    .get(
      `${apiEndpoint.properties}/get-property-meta-by-token?token=${token}`,
      {
        headers: authHeader()
      }
    )
    .then(res => {
      if(res && res.status === 200){
        return res.data;
      }
      return [];
    })
  );
}

function getPaginatedProperties(page = 1, limit = 10, search = '') {
  return (
    axios
      .get(
        `${apiEndpoint.paginatedProperties}?page=${page}&limit=${limit}&search=${search}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}
