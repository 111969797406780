import styled from "styled-components";

export const DiseaseSpecificPracticesWrapper = styled.div`
  ul{
    list-style-type: disc;
    margin: 20px
  }
  .header-table{
    margin-top: 100px;
    p, span{
      font-weight: 500;
    }
    span{
      font-style: italic
    }
  }
`;
