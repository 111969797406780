
import React from "react";

const Dashboard = () => {
  return (
    <div className="sidebar-icon" >
     

<svg width="24" className="sidebar-icon" height="24" viewBox="0 0 24 24" 
fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/element-4">
<g id="element-4">
<path  
// stroke='grey' 
id="Vector" d="M22 10.9V4.1C22 2.6 21.36 2 19.77 2H15.73C14.14 2 13.5 2.6 13.5 4.1V10.9C13.5 12.4 14.14 13 15.73 13H19.77C21.36 13 22 12.4 22 10.9Z" 
style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
/>
<path
// stroke='grey'
id="Vector_2" d="M22 19.9V18.1C22 16.6 21.36 16 19.77 16H15.73C14.14 16 13.5 16.6 13.5 18.1V19.9C13.5 21.4 14.14 22 15.73 22H19.77C21.36 22 22 21.4 22 19.9Z"
style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}/>
<path 
// stroke='grey'
id="Vector_3" d="M10.5 13.1V19.9C10.5 21.4 9.86 22 8.27 22H4.23C2.64 22 2 21.4 2 19.9V13.1C2 11.6 2.64 11 4.23 11H8.27C9.86 11 10.5 11.6 10.5 13.1Z" 
style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}/>
<path 
// stroke='grey'
id="Vector_4" d="M10.5 4.1V5.9C10.5 7.4 9.86 8 8.27 8H4.23C2.64 8 2 7.4 2 5.9V4.1C2 2.6 2.64 2 4.23 2H8.27C9.86 2 10.5 2.6 10.5 4.1Z" 
style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}/>
</g>
</g>
</svg>
    </div>
  );
};
export default Dashboard;
