import React, { useEffect, useState, useRef } from 'react';
import WeatherMapWrapper from './WeatherMap.styles';
// eslint-disable-next-line import/no-webpack-loader-syntax 
// import mapboxgl from '!mapbox-gl';
import mapboxgl from 'mapbox-gl';
import { useSelector, useDispatch } from 'react-redux';
import { propertyService } from '@iso/services';
import PitchToggle from './Controls/PitchToggle';
import StyleDarkControl from './Controls/StyleDarkControl';
import StyleLightControl from './Controls/StyleLightControl';
import StyleSatelliteControl from './Controls/StyleSatelliteControl';
import PrecipitationIntensityControl from './Controls/PrecipitationIntensityControl';
import WindSpeedControl from './Controls/WindSpeedControl';
import WindControl from './Controls/WindControl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as mapsgl from '@aerisweather/mapsgl';
import '@aerisweather/mapsgl/dist/mapsgl.css';
import { Tooltip,Button } from 'antd';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

const timestamp = (new Date()).toISOString();
const WeatherMap=(props) =>
{
  const map=useRef( null );
  const mapContainer = useRef(null);

  const [ zoom, setZoom ]=useState(6);
  const activePropertyId=useSelector( ( state ) => state.property.activePropertyId );
  const [ styleMap, setStyleMap ]=useState(process.env.REACT_APP_MAPBOX_STYLE_SATELLITE);
  const [ pitch, setPitch ]=useState( false );
  const [ mapgl, setMapgl ]=useState();
  const [ mapController, setMapController ]=useState();
  const [lat, setLat] = useState(-37.8151648);
  const [lng, setLng] = useState(144.9743793);
  const [ rasterOpacityPrecipitation, setRasterOpacityPrecipitation ]=useState( 80 );
  const [ rasterOpacityWindSpeed, setRasterOpacityWindSpeed ]=useState( 0 );
  const [ visiblePrecipitation, setVisiblePrecipitation ]=useState( true );
  const [ visibleWindSpeed, setVisibleWindSpeed ]=useState( false );
  const [property, setProperty] = useState();

  const propertyDetail = (id) => {
    
    propertyService.viewProperty(id).then(res => {
      if(res.code === '0000'){
       
        setProperty(res.property);
        if(res.property.lat != null && res.property.lng != null){
          if (props.setCurrentPosition) {
            props.setCurrentPosition({
              lat: res.property.lat,
              lng: res.property.lng,
            })}
          }
          setLng(res.property.lng);
          setLat(res.property.lat);
        }
      })
  }; 

  useEffect(() => {
    if(activePropertyId){
      propertyDetail(activePropertyId);
    }
    if(lat && lng){
      window.addEventListener('load', 
         configMapBox(lat,lng));  
    }
  }, [activePropertyId,lat,lng]);

  const configMapBox = (lat,lng) => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const mapbox = new mapboxgl.Map({
      container: mapContainer.current, // Use the .current property to access the DOM element
      style: 'mapbox://styles/' + process.env.REACT_APP_MAPBOX_USERNAME + '/' + styleMap,
      center: [lng, lat],
      pitch: 0,
      attributionControl: false,
      bearing: 0,
      zoom: zoom
    });
    mapbox.addControl( new mapboxgl.NavigationControl() );
    mapbox.addControl(
      new MapboxGeocoder( {
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      } ), "top-left"
    );
    setMapgl( mapbox );

    mapbox.on( 'load', function ()
    {
      loadPrecipitationIntensity( mapbox );
      loadWindSpeed( mapbox );
    } );
    configMapGL( mapbox );
  // }
  }

  const configMapGL = (mapbox) => {
    const account = new mapsgl.Account(
      process.env.REACT_APP_AERIS_ID,
      process.env.REACT_APP_AERIS_SECRET
    );
    const controller = new mapsgl.MapboxMapController(mapbox, { account });
    setMapController(controller);
    controller.on("load", function () {
      loadDinamicWind(controller);
    });
  };

  const switchLayer=( map, layer ) =>
  {

    map.on( 'style.load', function ()
    {
      loadPrecipitationIntensity( map );
      loadWindSpeed( map );
    } );
    map.setStyle( 'mapbox://styles/'+process.env.REACT_APP_MAPBOX_USERNAME+'/'+layer );

  }

  const loadDinamicWind=( controller ) =>
  {
    if ( controller.hasWeatherLayer( 'wind-particles' ) )
    {
      controller.removeWeatherLayer( 'wind-particles' );
    }
   
  }

  
  

  const loadPrecipitationIntensity=( map ) =>
  {
    if ( map.getLayer( 'precipitation-intensity' )&&map.getSource( 'precipitation-intensity' ) )
    {
      map.removeLayer( 'precipitation-intensity' );
      map.removeSource( 'precipitation-intensity' )
    }
    if ( !map.getLayer( 'precipitation-intensity' )&&!map.getSource( 'precipitation-intensity' ) )
    {
      map.addSource( 'precipitation-intensity', {
        "type": 'raster',
        "tiles": [ `https://api.tomorrow.io/v4/map/tile/{z}/{x}/{y}/precipitationIntensity/${ timestamp }.png?apikey=${ process.env.REACT_APP_TOMORROW_TOKEN }` ],
        "tileSize": 256,
        "attribution": '&copy; <a href="https://www.airagri.com.au/">Powered by Airagri</a>'
      } );
      map.addLayer( {
        "id": "precipitation-intensity",
        "type": "raster",
        "layout": {
          "visibility": visiblePrecipitation? 'visible':'none',
        },
        "source": "precipitation-intensity",
        "minzoom": 1,
        'paint': {
          'raster-opacity': parseInt( rasterOpacityPrecipitation, 10 )/100,
        }
      } );
    }

  }

  const loadWindSpeed=( map ) =>
  {
    if ( map.getLayer( 'wind-speed' )&&map.getSource( 'wind-speed' ) )
    {
      map.removeLayer( 'wind-speed' );
      map.removeSource( 'wind-speed' )
    }
    if ( !map.getLayer( 'wind-speed' )&&!map.getSource( 'wind-speed' ) )
    {

      map.addSource( 'wind-speed', {
        "type": 'raster',
        "tiles": [ `https://api.tomorrow.io/v4/map/tile/{z}/{x}/{y}/windSpeed/${ timestamp }.png?apikey=${ process.env.REACT_APP_TOMORROW_TOKEN }` ],
        "tileSize": 256,
        "attribution": '&copy; <a href="https://www.airagri.com.au/">Powered by Airagri</a>'
      } );
      map.addLayer( {
        "id": "wind-speed",
        "type": "raster",
        "layout": {
          "visibility": visibleWindSpeed? 'visible':'none',
        },
        "source": "wind-speed",
        "minzoom": 1,
        'paint': {
          'raster-opacity': parseInt( rasterOpacityWindSpeed, 10 )/100,
        }
      } );
    }
  }

  const flyToPropertyLocation = () => {
    if(mapgl && property?.lat && property?.lng){
      mapgl.flyTo({center: [property.lng, property.lat], zoom:14});
    }
  }
  return(
    <WeatherMapWrapper>
      <div ref={mapContainer} className="map-container" />
      <div className='mapbox-ctrl-top-right'>
        <Tooltip 
          placement="left"
          title="Property Location" 
        >
          <Button 
            className="mapboxgl-ctrl-icon mapboxgl-position-button"
            onClick={flyToPropertyLocation}
          >
            <span></span>
          </Button>
        </Tooltip>
      </div>
      <div className="mapbox-ctrl-group-bottom-right">
        <StyleLightControl setStyleMap={setStyleMap} styleMap={styleMap} mapgl={mapgl} switchLayer={switchLayer} />
        <StyleDarkControl setStyleMap={setStyleMap} styleMap={styleMap} mapgl={mapgl} switchLayer={switchLayer} />
        <StyleSatelliteControl setStyleMap={setStyleMap} styleMap={styleMap} mapgl={mapgl} switchLayer={switchLayer} />
        <PitchToggle pitch={pitch} setPitch={setPitch} mapgl={mapgl} />
      </div>
      <div className="mapbox-ctrl-group-bottom-left">
        <PrecipitationIntensityControl
          mapgl={mapgl}
          rasterOpacityPrecipitation={rasterOpacityPrecipitation}
          setRasterOpacityPrecipitation={setRasterOpacityPrecipitation}
          visiblePrecipitation={visiblePrecipitation}
          setVisiblePrecipitation={setVisiblePrecipitation}
        />
      
        <WindSpeedControl
          mapgl={mapgl}
          setRasterOpacityWindSpeed={setRasterOpacityWindSpeed}
          rasterOpacityWindSpeed={rasterOpacityWindSpeed}
          setVisibleWindSpeed={setVisibleWindSpeed}
          visibleWindSpeed={visibleWindSpeed} />
      </div>
    </WeatherMapWrapper>
  )
}

export default WeatherMap;