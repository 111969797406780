import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";

export const dynamoSensorService = {
  get,
  getAllSuppliers,
  getUniqueSensorsBySupplier
};

function get(supplier){
  return (
    axios
      .get(
        `${apiEndpoint.dynamoSensors}?supplier=${supplier}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getAllSuppliers(){
  return (
    axios
      .get(
        `${apiEndpoint.dynamoSensors}/get-suppliers`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getUniqueSensorsBySupplier(supplier){
  return (
    axios
      .get(
        `${apiEndpoint.dynamoSensors}/get-unique-sensors-by-supplier?supplier=${supplier}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}