import React,{ useEffect, useState, useRef } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Alert, Checkbox, Row, Col, Form, message } from 'antd';
import FormPage from './Form.styles';
import queryString from 'query-string';
import { userFormService, formService, userService } from '@iso/services';
import _ from 'lodash';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import "survey-react/modern.css";
import SignatureCanvas from 'react-signature-canvas';
import FinalScreen from './FinalScreen';
import FormBuilder from 'antd-form-builder';
import GlobalForm from './GlobalForm';
import { useSelector } from 'react-redux';
import BioForm from '@iso/containers/Pages/AdminPages/FormBuilder/BioForm/BioForm';
import CheckinForm from '@iso/containers/Pages/AdminPages/FormBuilder/CheckinForm/CheckinForm';
import HazardForm from '@iso/containers/Pages/AdminPages/FormBuilder/HazardForm/HazardForm';
import TrainingForm from '@iso/containers/Pages/AdminPages/FormBuilder/TrainingForm/TrainingForm';
import IllnessForm from '@iso/containers/Pages/AdminPages/FormBuilder/IllnessForm/IllnessForm';
import NearMissForm from '@iso/containers/Pages/AdminPages/FormBuilder/NearMissForm/NearMissForm';
import { element } from 'prop-types';

Survey.StylesManager.applyTheme("modern");

const FormSubmission = () => {
	const activePropertyId = useSelector((state) => state.property.activePropertyId);
	const padRef = useRef({});
	const token = queryString.parse(window.location.search).token || '';
	const [form, setForm] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [surveyContent, setSurveyContent] = useState({});
	const [questionList, setQuestionList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [propertyUsers, setPropertyUsers] = useState([]);
	const [pdfForm] = Form.useForm();
	const [showFinalScreen, setShowFinalScreen] = useState(false)
	const formRef = React.createRef()
	const [globalForm] = Form.useForm()

  	const checkFormSubmission = (token) => {
  		userFormService.checkFormSubmission(token).then(res => {
            if(res.code === '0000')
            {
				const preSubmitData = {};

				if (res.user_form?.answer_list.length > 0) {
					res.user_form?.answer_list[res.user_form.answer_list.length - 1].forEach(answer => {
						console.log(answer);
						if (answer.question_name) {
							preSubmitData[answer.question_name] = answer.answer_content;
						}
					});
				}

            	setForm(res.form);
              	let questions = [];

				console.log("res.form.meta_data?.visitor_types.length > 0", res.form.meta_data?.visitor_types?.length);

				if (res.form.meta_data?.visitor_types?.length > 0) {
					questions.push({
						name: "visitor",
						questions: [
							{
								type: "radiogroup",
								choices: res.form.meta_data.visitor_types,
								isRequired: true,
								name: "visitor_type",
								questionId: null,
								title: "Visitor Type"
							}
						]
					})
				}

				if (res.form.meta_data?.is_hosts_enabled) {
					questions.push({
						name: "host",
						questions: [
							{
								type: "dropdown",
								choices: propertyUsers.map(user => user.name),
								name: "host_name",
								title: "Host",
								questionId: null,
								requiredIf: "{visitor_type} anyof [" + res.form.meta_data?.required_visitor_types_for_hosts.join(',') + "]"
							}
						]
					})
				}

	          _.forEach(res.form.surveyQuestions, (question, index) => {
				const visilibity = question.meta_data?.question_visibility.join(',') ?? [];
				const isRequired = question.meta_data?.response_required ?? true;
				const defaultValue = question.meta_data?.prefill_response
					? (preSubmitData[question.id.toString()] ?? null)
					: null
				const isVisible = question.meta_data?.question_visibility.length > 0
					? "{visitor_type} anyof [" + visilibity + "]"
					: true

					console.log("question.question_type", question.question_type);

	            switch(question.question_type){
					case 'yes/no':
						console.log("question", question);

						var data = {
						  name: "page_" + question.id,
						  questions: [
							{
							  type: "radiogroup",
							  choices: ["Yes", "No"],
							  isRequired: isRequired,
							  name: question.id.toString(),
							  title: question.title,
							  defaultValue: defaultValue,
							  visibleIf: isVisible
							}
						  ]
						};
						break;
					case 'text':
						var data = {
						  name: "page_" + question.id,
						  questions: [
							{
							  type: "text",
							  isRequired: isRequired,
							  name: question.id.toString(),
							  title: question.title,
							  defaultValue: defaultValue,
							  visibleIf: isVisible
							}
						  ]
						};
						break;
					case 'multiple_choice':
						var data = {
						  name: "page_" + question.id,
						  questions: [
							{
							  type: "radiogroup",
							  choices: question.meta_data.options,
							  isRequired: isRequired,
							  name: question.id.toString(),
							  title: question.title,
							  defaultValue: defaultValue,
							  visibleIf: isVisible
							}
						  ]
						};
						break;
					case 'signature':
						var data = {
						  name: "page_" + question.id,
						  questions: [
							{
							  type: "signaturepad",
							  isRequired: isRequired,
							  name: question.id.toString(),
							  title: question.title,
							  defaultValue: defaultValue,
							  visibleIf: isVisible
							}
						  ]
						};
						break;
					case 'multi_selection':
						var data = {
						  name: "page_" + question.id,
						  questions: [
							{
							  type: "checkbox",
							  choices: question.meta_data.options,
							  isRequired: isRequired,
							  name: question.id.toString(),
							  title: question.title,
							//   defaultValue: defaultValue,
							  visibleIf: isVisible
							}
						  ]
						};
						break;
					case 'number':
						var data = {
						  name: "page_" + question.id,
						  questions: [
							{
							  type: "text",
							  inputType: "number",
							  isRequired: isRequired,
							  name: question.id.toString(),
							  title: question.title,
							  defaultValue: defaultValue,
							  visibleIf: isVisible
							}
						  ]
						};
						break;
					case 'fixed_text':
						var data = {
							name: "page_" + question.id,
							questions: [
								{
									name: question.id.toString(),
									title: question.title,
									type: "html",
									html: question.meta_data.text
								}
							]
						};
						break;
					case 'fixed_image':
						if (question.meta_data.image_id) {
							var data = {
								name: "page_" + question.id,
								questions: [
									{
										type: "image",
										title: question.title,
										name: question.id.toString(),
										imageLink: question.meta_data.image.full_path
									}
								]
							};
						}
						break;
					case 'scale':
						var data = {
						  name: "page_" + question.id,
						  questions: [
							{
							  type: "radiogroup",
							  choices: ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"],
							  isRequired: isRequired,
							  name: question.id.toString(),
							  title: question.title,
							  defaultValue: defaultValue,
							  visibleIf: isVisible
							}
						  ]
						};
						break;
					default:
						var data = {
						  name: "page_" + question.id,
						  questions: [
							{
							  type: "comment",
							  isRequired: isRequired,
							  name: question.id.toString(),
							  title: question.title
							}
						  ]
						};
						break;
	            }

	            questions.push(data);
	          });
	          	setQuestionList(questions);
            }else{
                setErrorMsg(res.message)
            }
        })
  	}

	useEffect(() => {
		if(activePropertyId){
			userService.getUserForProperty(activePropertyId).then(res => {
				if (res.code === '0000') {
					setPropertyUsers(res.users);
				} else {
					setPropertyUsers([]);
				}
			});
		}
	}, [activePropertyId, token]);

	useEffect(() => {
		checkFormSubmission(token);
	}, [propertyUsers]);

    useEffect(() => {
	    if(questionList && form)
	    {
	      var surveyJSON = {
	        title: form.survey_title,
	        description: form.survey_description,
	        completedHtml: "<h3>Thank you for completing the survey!</h3>",
	        showCompletedPage: false,
			pages: questionList
	    }

		console.log("surveyJSON", surveyJSON);

      	setSurveyContent(surveyJSON);
    } 
  }, [questionList, form]);

	const submitSurveyData = (survey) => {
		const surveyData = survey.data;

		const formattedResults = survey.getAllQuestions().map(question => {
			return {
				question_name: question.name,
				question_content: question.title,
				answer_content: surveyData[question.name] ?? ''
			};
		});

	    const data = {
	      form: {
	        survey: surveyData,
	        type: 'self_create',
	        signature_path: padRef.current && Object.keys(padRef.current).length === 0 && padRef.current.constructor === Object ? '' : padRef.current.toDataURL('image/png'),
			results: formattedResults
	      },
	    }

	    formService.storeOrUpdate(data, form.id).then(res => {
	      if(res.code === '0000'){
	        message.success('Submit success')
	        setShowFinalScreen(true)
	      }else{
	        message.error(res.message)
	      }
	    });
	}

	const handleSubmit = (e) => {
	    setLoading(true);

	    pdfForm
	      .validateFields()
	      .then(values => {
	        setLoading(true);
	        onStore(values);
	      })
	      .catch(info => {
	        console.log('Validate Failed:', info);
	        setLoading(false);
	      });
	  }

	  const onStore = (values) => {
	    const data = {
	      form: {
	      	...values.form,
	        type: 'pdf_upload',
	        signature_path: padRef.current && Object.keys(padRef.current).length === 0 && padRef.current.constructor === Object ? '' : padRef.current.toDataURL('image/png')
	      }
	    }

	    formService.storeOrUpdate(data, form.id).then(res => {
	      if(res.code === '0000'){
	        message.success('Submit success')
	      	checkFormSubmission(token)
	        pdfForm.resetFields();
	        setLoading(false)
	        setShowFinalScreen(true)
	      }else{
	        message.error(res.message)
	        setLoading(false)
	      }
	    });
	  }

	const handleClear = () => {
	    padRef.current.clear();
	}

	const handleSubmitGlobalForm = () => {
        setLoading(true);
        globalForm
          .validateFields()
          .then(values => {
            handleFinish(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
            setLoading(false);
        });
    }

    const handleFinish = (values) => {
      const data = {
	      form: {
	        global_form: values,
	        type: 'system_forms'
	      }
	    }

	    formService.storeOrUpdate(data, form.id).then(res => {
	      if(res.code === '0000'){
	        message.success('Submit success')
	        setShowFinalScreen(true)
	      }else{
	        message.error(res.message)
	      }
	    });
    }
 
  return (
    <LayoutWrapper>
      <FormPage>
        {errorMsg && errorMsg !== '' && !showFinalScreen &&
            <Alert message={errorMsg} type="error" />
        }
        {
        	form && form.type == 'self_create' && !showFinalScreen && <Survey.Survey json={surveyContent} onComplete={submitSurveyData} />
        }
        { showFinalScreen && <FinalScreen /> }
        {
        	form && form.type == 'pdf_upload' && !showFinalScreen &&
        	<Row>
        		<Col xs={{span: 24}} md={{span: 12}}>
        			<iframe src={(form.files && form.files.length) ? process.env.REACT_APP_API_BASE_URL + form.files[form.files.length-1].path : ''} />
        		</Col>
        		<Col xs={{span: 24}} md={{span: 11, offset: 1}}>
        			<Form
				        layout="vertical"
				        scrollToFirstError
				        className="form-body"
				        form={pdfForm}
				    >
				    	<Form.Item
			                name={['form', 'is_agree']}
			                className="isoInputWrapper"
			                valuePropName="checked"
			            >
			                <Checkbox>{<IntlMessages id="formPage.modal.agreeConfirm" />}</Checkbox>
			                
			            </Form.Item>
			            <h3>{<IntlMessages id="formPage.modal.signature" />}</h3>
			        	<div className="signature-area">
			                <SignatureCanvas ref={padRef} />
			                <button onClick={handleClear} className="signature-clear-button">Clear</button>
			            </div>
			            <div className="isoInputWrapper">
				            <Button key="submit" className="btn-success" type="primary" loading={loading} onClick={handleSubmit}>
					          {<IntlMessages id="propertyPage.modal.save" />}
					        </Button>
					    </div>
		           	</Form>
        		</Col>
        	</Row>
        }
        {
        	form && form.type == 'system_forms' && !showFinalScreen &&
        	<GlobalForm formData={form} setShowFinalScreen={setShowFinalScreen}/>
        }
		{
        	form && form.type == 'bio_form' && !showFinalScreen &&
        	<BioForm formData={form} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        }
		{
        	form && form.type == 'checkin_form' && !showFinalScreen &&
        	<CheckinForm formData={form} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        }
		{
        	form && form.type == 'hazard_form' && !showFinalScreen &&
        	<HazardForm formData={form} propertyId={form.property_id} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        }
		{
        	form && form.type == 'training_form' && !showFinalScreen &&
        	<TrainingForm formData={form} propertyId={form.property_id} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        }
		{
        	form && form.type == 'illness_form' && !showFinalScreen &&
        	<IllnessForm formData={form} propertyId={form.property_id} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        }
		{
        	form && form.type == 'near_miss_form' && !showFinalScreen &&
        	<NearMissForm formData={form} propertyId={form.property_id} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        }

      </FormPage>
    </LayoutWrapper>
  );
}

export default FormSubmission;
