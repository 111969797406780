import React, { useEffect, useRef } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Card,
} from "antd";
import _ from "lodash";
import IntlMessages from "@iso/components/utility/intlMessages";

const { Option } = Select;

let autoComplete;

function handleAddressAutoComplete(form, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current.input,
    { types: ["address"] }
  );

  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () => {
    handlePlaceSelect(form);
  });
}

async function handlePlaceSelect(form) {
  const addressObject = autoComplete.getPlace();
  form.setFieldValue("address", addressObject.formatted_address);
}

const WorkerDetail = (props) => {

  const autoCompleteRef = useRef(null);
  const handleAddress = () => {
    handleAddressAutoComplete(props.form, autoCompleteRef);
  };
  useEffect(() => {
    if (autoCompleteRef) {
      handleAddress();
    }
  }, []);

  const renderUsers = () => {
    let options = [];
    if(props.users && props.visitors){
      const userList = props.users.concat(props.visitors);
      _.forEach(userList, (user, index) => {
        options.push(
          <Option key={index} value={user.id + '-' + user.user_type}>
            <div>
              {user.name} -{" "}
              {user.user_type === "visitor" ? "Visitor" : "Register User"}
            </div>
          </Option>
        );
      });
    }
    return (
      <Select placeholder="Select a user" allowClear size="large">
        {options}
      </Select>
    );
  };

  return (
    <Card>
      <h3 className="form-title">
        <IntlMessages id="illnessForm.workerDetail.title" />
      </h3>
      <Form.Item
        name={["worker_detail", "user"]}
        className="isoInputWrapper"
        label="Worker"
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        {renderUsers()}
      </Form.Item>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Form.Item
            name={["worker_detail", "first_name"]}
            className="isoInputWrapper"
            label={<IntlMessages id="illnessForm.workerDetail.firstName" />}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
          <Form.Item
            name={["worker_detail", "last_name"]}
            className="isoInputWrapper"
            label={<IntlMessages id="illnessForm.workerDetail.lastName" />}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Form.Item
            name={["worker_detail", "date_of_birth"]}
            className="isoInputWrapper"
            label={<IntlMessages id="illnessForm.workerDetail.dateOfBirth" />}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <DatePicker size="large" format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
          <Form.Item
            name={["worker_detail", "position"]}
            className="isoInputWrapper"
            label={<IntlMessages id="illnessForm.workerDetail.position" />}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input size="large" placeholder="Position" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={["worker_detail", "address"]}
        className="isoInputWrapper"
        label={<IntlMessages id="illnessForm.workerDetail.workerAddress" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input size="large" placeholder="Address" ref={autoCompleteRef} />
      </Form.Item>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Form.Item
            name={["worker_detail", "department_or_team"]}
            className="isoInputWrapper"
            label={
              <IntlMessages id="illnessForm.workerDetail.departmentOrTeam" />
            }
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input size="large" placeholder="Department Of Team" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
          <Form.Item
            name={["worker_detail", "manager_supervisor_name"]}
            className="isoInputWrapper"
            label={
              <IntlMessages id="illnessForm.workerDetail.managerSupervisorName" />
            }
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input size="large" placeholder="Manager Supervisor Name" />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default WorkerDetail;
