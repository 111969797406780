import React, { useEffect, useRef } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Radio,
  Card,
  TimePicker,
  Space,
} from "antd";
import {
  propertyService,
} from "@iso/services";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const IllnessDetail = (props) => {
  const mapRef = useRef();
  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );
  const handleClickMap = (e) => {
    const { lat, lng } = e.latlng;
    props.form.setFieldValue(["illness_detail", "lat"], lat);
    props.form.setFieldValue(["illness_detail", "lng"], lng);
  };

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then((res) => {
      if (res.code === "0000") {
        props.form.setFieldValue(["illness_detail", "lat"], res.property.lat);
        props.form.setFieldValue(["illness_detail", "lng"], res.property.lng);
      }
    });
  }, []);

  useEffect(() => {
    if (props.propertyId) {
      updatePropertyDetail(props.propertyId);
    }
  }, [props.propertyId]);

  useEffect(() => {
    props.form.setFieldValue(["illness_detail", "witnesses"], [
      {
        name: '',
        contact: ''
      }
    ]);
  }, []);
  return (
    <Card>
      <h3 className="form-title">
        <IntlMessages id="illnessForm.illnessDetail.title" />
      </h3>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Form.Item
            name={["illness_detail", "date_of_illness"]}
            className="isoInputWrapper"
            label={
              <IntlMessages id="illnessForm.illnessDetail.dateOfIllness" />
            }
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <DatePicker size="large" format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
          <Form.Item
            name={["illness_detail", "time_of_illness"]}
            className="isoInputWrapper"
            label={
              <IntlMessages id="illnessForm.illnessDetail.timeOfIllness" />
            }
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <TimePicker size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={["illness_detail", "nature_of_illness"]}
        className="isoInputWrapper"
        label={<IntlMessages id="illnessForm.illnessDetail.natureOfIllness" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name={["illness_detail", "bodily_location"]}
        className="isoInputWrapper"
        label={<IntlMessages id="illnessForm.illnessDetail.bodilyLocation" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return prevValues["illness_detail"];
        }}
      >
        {({ getFieldValue }) => {
          return (
            <Form.Item
              className="isoInputWrapper no-style"
              label={
                <IntlMessages id="illnessForm.illnessDetail.locationOfInjury" />
              }
            >
              <Map
                ref={mapRef}
                center={
                  getFieldValue(["illness_detail", "lat"]) &&
                  getFieldValue(["illness_detail", "lng"])
                    ? [
                        getFieldValue(["illness_detail", "lat"]),
                        getFieldValue(["illness_detail", "lng"]),
                      ]
                    : false
                }
                doubleClickZoom={false}
                onclick={handleClickMap}
                zoom={16}
                zoomControl={true}
                style={{ width: "100%", height: "300px" }}
              >
                {activePropertySubscription ===
                process.env.REACT_APP_STRIPE_PLAN_GOLD ? (
                  <ReactLeafletGoogleLayer
                    type={"hybrid"}
                    useGoogMapsLoader={false}
                  />
                ) : (
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                )}
                {getFieldValue(["illness_detail", "lat"]) &&
                  getFieldValue(["illness_detail", "lng"]) && (
                    <Marker
                      position={[
                        getFieldValue(["illness_detail", "lat"]),
                        getFieldValue(["illness_detail", "lng"]),
                      ]}
                      draggable={true}
                    >
                      <Popup
                        position={[
                          getFieldValue(["illness_detail", "lat"]),
                          getFieldValue(["illness_detail", "lng"]),
                        ]}
                      ></Popup>
                    </Marker>
                  )}
              </Map>
              <Row style={{ marginTop: "10px", marginBottom: "5px" }}>
                <Col xs={{ span: 24 }} md={{ span: 11 }}>
                  <Form.Item
                    name={["illness_detail", "lat"]}
                    className="isoInputWrapper"
                    label="Latitute"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                  <Form.Item
                    name={["illness_detail", "lng"]}
                    label="Longitude"
                    className="isoInputWrapper"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        name={["illness_detail", "how_sustained"]}
        className="isoInputWrapper"
        label={<IntlMessages id="illnessForm.illnessDetail.howSustained" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name={["illness_detail", "thing_involed"]}
        label={<IntlMessages id="illnessForm.illnessDetail.thingInvoled" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes"> Yes </Radio>
          <Radio value="no"> No </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return prevValues["illness_detail"];
        }}
      >
        {({ getFieldValue }) => {
          return getFieldValue(["illness_detail", "thing_involed"]) ===
            "yes" ? (
            <Form.List
              name={["illness_detail", "witnesses"]}
              label="Witnesses"
              rules={[
                {
                  validator: async (_, witnesses) => {
                    if (!witnesses || witnesses.length < 1) {
                      return Promise.reject(new Error("At least 1 witness"));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                      className="witness-space"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[
                          { required: true, message: "Missing Name" },
                        ]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "contact"]}
                        rules={[
                          { required: true, message: "Missing Contact" },
                        ]}
                      >
                        <Input placeholder="Contact" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Witnesses
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          ) : (
            <></>
          );
        }}
      </Form.Item>
    </Card>
  );
};

export default IllnessDetail;
