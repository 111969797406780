import React from "react";
import { Form, Input, Table, Checkbox } from "antd";
import BioCheckbox from "../Components/BioCheckbox";

const { TextArea } = Input;

const PestsAndWeeds = (props) => {
  const columns = [
    {
      title: "4",
      dataIndex: "order",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "PESTS AND WEEDS",
      dataIndex: "pest_and_weeds",
      rowScope: "row",
      colSpan: 7,
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
      width: 500,
    },
    {
      title: "Yes",
      colSpan: 0,
      dataIndex: "yes",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "No",
      colSpan: 0,
      dataIndex: "no",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "N/A",
      colSpan: 0,
      dataIndex: "na",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "Recommended measures ",
      colSpan: 0,
      dataIndex: "recommended_measures",
      rowScope: "row",
      width: 500,
    },
    {
      title: "Actioned ?",
      colSpan: 0,
      dataIndex: "actioned",
      rowScope: "row",
    },
    {
      title: "Comments",
      colSpan: 0,
      dataIndex: "comments",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
      className: "comment-column",
    },
  ];

  const data = [
    {
      key: "1",
      order: "4.1",
      pest_and_weeds: "Pests and weeds",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures ",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "2",
      order: "4.1.1",
      people_vehicles_equipment:
        "Are there documented feral animal, wildlife and weed-control programs in operation and do they include monitoring and management activities?",
      yes: <BioCheckbox name="pest_and_weeds_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="pest_and_weeds_1_no"></BioCheckbox>,
      na: <BioCheckbox name="pest_and_weeds_1_na"></BioCheckbox>,
      recommended_measures:
        "Feral animal, wildlife and weed-control plans documented as required.",
      actioned: <BioCheckbox name="pest_and_weeds_1_1_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="pest_and_weeds_1_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "3",
      order: "",
      people_vehicles_equipment: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "In regular contact with neighbours and regional feral animal, wildlife and weed control groups to maximise the effectiveness of control programs",
      actioned: (
        <Form.Item
          name="pest_and_weeds_1_2_actioned"
          className="isoInputWrapper"
        >
          <BioCheckbox name="pest_and_weeds_1_2_actioned"></BioCheckbox>
        </Form.Item>
      ),
      comments: "",
    },
  ];

  return (
    <Table className="bio-table" columns={columns} dataSource={data} bordered pagination={false} />
  );
};

export default PestsAndWeeds;
