import React, { useState, useEffect, useRef } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import UploadAvatarModalWrapper from './UploadAvatarModal.styles';
import { Form, Select, Upload, Button, Row, Col } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { imageService } from '@iso/services';
import _ from 'lodash';
import moment from 'moment';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import $ from 'jquery';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

const { Option } = Select;

const UploadAvatarModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageId, setImageId] = useState(null);

  const cancelModal = () => {
    props.cancelUploadAvatarModal();
    form.resetFields();
    setLoading(false);
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const data = {
      image: {
        type: 'sample_avatar',
      }
    }

    imageService.storeOrUpdate(data, imageId).then(res => {
      if(res.code === '0000'){
        cancelModal();
        props.getSampleAvatar();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }

  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      let $oldOne = $('.anticon-delete')[0];
      setTimeout(()=>{
        if($oldOne)
          $($oldOne).trigger('click');
      },100);
    },
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        setImageId(response.file.response.image.id);
      }
    },    
    listType: "picture",
    multiple: false
  }

  return(
    <UploadAvatarModalWrapper
      visible={props.visibleUploadAvatarModal}
      onCancel={cancelModal}
      maskClosable={false}
      title={<IntlMessages id="sampleAvatar.managePage.uploadSampleAvatar"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
      >
        <div className="form-body">
          <Form.Item 
            name={['attachment']} 
            label="Attachment" 
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Upload
              {...uploadProps}
            >
              <Button className="action-button" icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
        </div>    
      </Form>
    </UploadAvatarModalWrapper>
  )
}

export default UploadAvatarModal;