import React from 'react';

const ManageTasks = () => {
  return (
	<div className="sidebar-icon">
	<svg
	className="sidebar-icon"
	  width="24"
	  height="24"
	  viewBox="0 0 24 24"
	  fill="none"
	  xmlns="http://www.w3.org/2000/svg"
	>
	  <path
		d="M12.37 8.88H17.62"
		// stroke='rgb(143, 148, 151)'
		style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
	  />
	  <path
		d="M6.38 8.88L7.13 9.63L9.38 7.38"
		style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
	  />
	  <path
		d="M12.37 15.88H17.62"
		style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
	  />
	  <path
		d="M6.38 15.88L7.13 16.63L9.38 14.38"
		style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
	  />
	  <path
		d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
		style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
	  />
	</svg>
  </div>
  );
}
export default ManageTasks;