import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const StopDeviceAlarmRequestModalWrapper = styled(Modal)`
  .ant-modal-body{
    padding: 20;
    overflow: auto
  }
  .ant-modal-content .ant-modal-footer{
    display : none !important;

  }
  .manage-table{
    border-top: 1px solid #EAECF0;
  }
  .btn-success{
    background-color: #0B2A1B;
    border-color: #0B2A1B;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
      color: #0B2A1B;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
  }
  thead{
    text-align : left;
  }
`;

export default WithDirection(StopDeviceAlarmRequestModalWrapper);