import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import styled from "styled-components";
import _, { values } from "lodash";

const Option = styled.p`
  padding: 3px 9px;
  color: white;
  background-color: #5466f1;
  border-radius: 8px;
  margin: 5px 10px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: aliceblue;
  p:not(.option) {
    display: none !important;
  }
  margin-bottom: 40px;
  .term {
    font-weight: bold;
    margin-right: 4.5px;
    font-size: 16px;
    padding: 5px 0px
  }
`;

const DragDropQuestion = ({ value, onChange}) => {
  const [question, setQuestion] = useState(value?.question ? value?.question : '');
  const [optionList, setOptionList] = useState(value?.options ? value?.options : []);
  const [questionInfo, setQuestionInfo] = useState([]);
  const [optionInfo, setOptionInfo] = useState([]);
  const [blankUnderlines, setBlankUnderLines] = useState({});

  useEffect(()=>{
    setQuestion(value?.question ? value?.question : '')
    setOptionList(value?.options ? value?.options : [])
  }, [value])

  const swapChosenOptions = (sourceBlankIndex, destinationBlankIndex) => {
    let newQuestionInfo = _.clone(questionInfo);
    let optionSource = questionInfo[sourceBlankIndex]["option"];
    let optionDestination = questionInfo[destinationBlankIndex]["option"];
    newQuestionInfo[sourceBlankIndex]["option"] = optionDestination;
    newQuestionInfo[destinationBlankIndex]["option"] = optionSource;
    setQuestionInfo(newQuestionInfo);
    return;
  };

  const recallOption = (sourceBlankIndex) => {
    let newQuestionInfo = _.clone(questionInfo);
    let optionSource = questionInfo[sourceBlankIndex]["option"];
    newQuestionInfo[sourceBlankIndex]["option"] = null;
    newQuestionInfo[sourceBlankIndex]["type"] = "blank";
    let newOptionInfo = _.clone(optionInfo);
    newOptionInfo[optionSource["option_index"]]["is_visible"] = true;
    setQuestionInfo(newQuestionInfo);
    setOptionInfo(newOptionInfo);
    return;
  };

  const onDragEnd = ({ source, destination }) => {
    if (
      !source ||
      !destination ||
      source.droppableId === destination.droppableId
    ) {
      return;
    }
    let destinationBlank = destination.droppableId.split("_");
    let destinationBlankIndex = destinationBlank[1];
    let sourceBlank = source.droppableId.split("_");
    let sourceBlankIndex = sourceBlank[1];

    // Swap 2 blank position
    if (
      destinationBlankIndex &&
      sourceBlankIndex &&
      destinationBlank[0] == "blank" &&
      sourceBlank[0] == "blank"
    ) {
      return swapChosenOptions(sourceBlankIndex, destinationBlankIndex);
    }

    // Recall option
    if (destination.droppableId === "answer-section") {
      return recallOption(sourceBlankIndex);
    }

    // Choose option
    let newQuestionInfo = _.map(
      questionInfo,
      ({ index, term, type, option = null }) => {
        const newOption =
          index == destinationBlankIndex
            ? {
                ...optionInfo[source["index"]],
                option_index: source["index"],
              }
            : option;

        return {
          index,
          term,
          type: index == destinationBlankIndex ? "option" : type,
          option: newOption,
        };
      }
    );

    let chosenOptionIndexs = [];
    newQuestionInfo.forEach((question) => {
      if (question.type == "option" && question.option) {
        chosenOptionIndexs.push(question.option.option_index);
      }
    });

    let newOptionInfo = _.map(optionInfo, (option, index) => {
      return {
        ...option,
        is_visible: chosenOptionIndexs.includes(index) ? false : true,
      };
    });
    setOptionInfo(newOptionInfo);
    setQuestionInfo(newQuestionInfo);
  };

  useEffect(() => {
    if(value.questionInfo){
      setQuestionInfo(value.questionInfo);
    }else{
      let temp = [];
      let splittedQuestion = question.split(" ");
      temp = _.map(splittedQuestion, (term, index) => {
        if (term === "___") {
          blankUnderlines[`blank_${index}_underline`] = false;
        }
        setBlankUnderLines(blankUnderlines);
        return {
          index,
          term,
          type: term === "___" ? "blank" : "text",
        };
      });
      setQuestionInfo(temp);
    }
  }, [question]);

  useEffect(() => {
    if(value.optionInfo){
      setOptionInfo(value.optionInfo);
    }else{
      let temp = [];
      temp = _.map(optionList, ({ id, text }, index) => {
        return {
          id,
          text,
          is_visible: true,
        };
      });
      setOptionInfo(temp);
    }
  }, [optionList]);

  useEffect(() => {
    onChange({
      ...value,
      optionInfo: optionInfo,
      questionInfo: questionInfo
    })
  }, [optionInfo, questionInfo]);

  const Blank = ({ id }) => {
    return (
      <Droppable droppableId={`blank_${id}`}>
        {(provided) => (
          <span
            style={{ padding: "7px" }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {" _______  "}
            {provided.placeholder}
          </span>
        )}
      </Droppable>
    );
  };

  const renderQuestion = () => {
    return (
      <QuestionWrapper>
        {questionInfo.map(({ index, term, type, option = null }) => {
          return type === "blank" ? (
            <Blank id={index} />
          ) : type === "option" ? (
            renderOption(option, 0, index)
          ) : (
            <span className="term">{term + " "}</span>
          );
        })}
      </QuestionWrapper>
    );
  };

  const renderOption = (option, index, blankIndex) => {
    return option ? (
      <div>
        <Droppable droppableId={`blank_${blankIndex}`} direction="horizontal">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <Draggable
                key={`${option.id}__choosen`}
                draggableId={`${option.id}__choosen`}
                index={index}
                extra={{ foo: "bar" }}
              >
                {(provided, snapshot) => (
                  <div>
                    <Option
                      className={`${
                        snapshot.isDragging
                          ? "dragging-option option"
                          : "option"
                      }  ${option.is_visible ? "" : "hidden"}`}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      {option.text}
                    </Option>
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <span className="blank_underline">_______</span>
      </div>
    ) : (
      <></>
    );
  };
  const DragDropWrapper = styled.div`
    .hidden {
      display: none !important;
    }
    .blank_underline {
      opacity: 0;
      height: 1px;
      display: block;
    }
    .answer-section{
      flex-wrap: wrap;
      display: flex;
      background-color: #e7e7e7;
      padding: 25px;
      border-radius: 7px;
      justify-content: center
    }
  `;

  const handleDragUpdate = (update) => {};

  return (
    <DragDropWrapper>
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={handleDragUpdate}>
        <div className="DRAGDROP">
          <div>
            {renderQuestion()}
            <Droppable droppableId={"answer-section"}>
              {(provided) => (
                <div
                  className="answer-section"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {optionInfo.map((option, index) => (
                    <Draggable
                      key={option.id}
                      draggableId={option.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Option
                          className={`${
                            snapshot.isDragging ? "dragging-option" : "option"
                          }  ${option.is_visible ? "" : "hidden"}`}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {option.text}
                        </Option>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
    </DragDropWrapper>
  );
};

export default DragDropQuestion;
