import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const ProfileModal = styled.div`
  width: 79.5%;
border-radius: 8px;
  height: 100%;
  margin-left : 2px;
  background-color: white;
//   border-radius : 8px;
  svg.brand-primary-light {
    color: #0B2A1B;
}

.manage-table .react-collapsible tbody th {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color:#0B2A1B;
}
table.react-collapsible .react-collapsible thead > tr > th{
    color: #0B2A1B !important ;
}
.property-area-dot-btn span {
  rotate: 90deg;
}
  .dot-btn {
    span {
      rotate: 90deg;
      cursor: pointer;
    }
  }
  .ant-popover-inner {
    border-radius: 12px !important;
  }
  .btn-success {
    background-color: #0B2A1B;
    border-color: #0B2A1B;
  }
  .manage-property-title {
    color: #101828;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  .manage-property-table {
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background: white;
    .ant-pagination {
      padding: 24px;
    }
    .add-property-btn {
      background: #f80e46;
      border-radius: 8px !important;
      color: #fff;
    }
    .m-p-main {
      padding: 24px;
      display: flex;
      justify-content: space-between;
    }
    .dropdown {
      border: 1px solid #d0d5dd !important;
    }
  }
  .action-btn {
    font-size: 12px;
    line-height: 24px;
    background: #535763;
    border-radius: 10px !important;
    border-color: transparent !important;
    color: white;
  }
  .button-group {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .fAgWDa .ant-table-content {
    overflow-x: unset;
  }
  .fAgWDa {
    overflow-x: unset;
  }
  .dtr-data {
    white-space: break-spaces;
  }
  .delete-button {
    margin-left: 5px;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    height: 100%;
    padding-left : 0px;
    padding-top : 30px;
  }
  
  // Add this CSS to your stylesheet or component's styles
 
`;

export default WithDirection(ProfileModal);
