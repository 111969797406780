import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import modalActions from '@iso/redux/modal/actions';
import ColorModalWrapper from './ColorModal.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Form, Input, Select } from 'antd';
import { layerObjectService } from '@iso/services';
import styled from "styled-components";
import { objectColors } from '@iso/constants/objectColors';
import L from 'leaflet';

const { TextArea } = Input;
const { closeModal } = modalActions;
const { Option } = Select;


const EditTextModal = (props) => {
    const [form] = Form.useForm();
    const [fields, setFields] = useState([]);
    const [loadingLayer, setLoadingLayer] = useState(false);


    const handleChangeColorSubmit = () => {
        form
            .validateFields()
            .then(values => {
                setLoadingLayer(true);
                storeText(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setLoadingLayer(false);
            });
    }
    const storeText = (values) => {
   
      const data = {
        layer_object: {
          type: props.dataText ? props.dataText.type : '',
          color:values.layer.color,
          layer_id:props.dataText ? props.dataText.layer_id : '',
          object:{
            text: values.layer.text,
            centerPoint: [props.dataText ? props.dataText.circle_lat : '', props.dataText ? props.dataText.circle_lng : '' ]
          }
        }
      }
      layerObjectService.storeOrUpdate(data,props.dataText.id).then(res => {
        if(res.code === '0000'){
          props.cancelEditModal();
          form.resetFields();
          props.getDataLayer();
          setLoadingLayer();
        } else {
          setLoadingLayer(false);
        }
      });
    }

    useEffect(() => {
      if(props.dataText){
        setFields([
          {
            name: ['layer', 'text'],
            value: props.dataText.text,
          },
          {
            name: ['layer', 'color'],
            value: props.dataText.color,
          },
        ]);
      }
    
    
    },[props.dataText]);
    const renderOptionsColor = () => {
        let options = [];
        _.forEach(objectColors, (color, index) => {
            options.push(
                <Option key={index} value={color.hexCode}>
                    <ColorItemRow>
                        <ColorItemPreview style={{ backgroundColor: color.color }}></ColorItemPreview>
                        <div>{color.label}</div>
                    </ColorItemRow>
                </Option>
            );
        })
        return (
            <Select
                placeholder="Select a color"
                allowClear
                size="large"
            >
                {options}
            </Select>
        );
    }


    return (
        <ColorModalWrapper
            visible={props.isEditModalVisible}
            onCancel={props.cancelEditModal}
            maskClosable={false}
            centered
            title={"Edit Text Modal"}
            footer={[
                <Button key="back" onClick={props.cancelEditModal} className="cancel-button">
                    {<IntlMessages id="propertyPage.modal.cancel" />}
                </Button>,
                <Button key="submit" onClick={handleChangeColorSubmit} loading={loadingLayer} className="btn-success" type="primary">
                    {<IntlMessages id="propertyPage.modal.save" />}
                </Button>,
            ]}
        >
            <Form
              form={form}
              layout="vertical"
              scrollToFirstError
              fields={fields}
            >
                <div className="form-body">
                  <Form.Item
                        name={['layer', 'text']}
                        label="Text"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                      <TextArea placeholder="Text" autoSize = {{ minRows: 2 }} />

                    </Form.Item>
                    <Form.Item
                        name={['layer', 'color']}
                        label="Color"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        {renderOptionsColor()}
                    </Form.Item>
                    
                </div>
            </Form>
        </ColorModalWrapper>
    )
}

export default EditTextModal;


const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;