export const importTables = [
  {
    label: 'Mob',
    value: 'mob',
  },
  {
  	label: 'Livestock',
  	value: 'livestock'
  },
  {
  	label: 'Asset',
  	value: 'asset'
  }
]