import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector } from 'react-redux';
// import { configs } from '@iso/components/Tables/configs';
import { Row, Col, Button, Dropdown, Menu, Popconfirm } from 'antd';
import ManageProductPage from './ManageProduct.styles';
import Table from '@iso/components/collapsing-table/table';
import { EditOutlined, PlusOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { marketplaceProductService, marketplaceCategoryService } from '@iso/services';
import moment from 'moment';
import ProductModal from '@iso/containers/Pages/Marketplace/ProductModal';

const ManageProduct = () => {
  const currentUser = useSelector((state) => state.user.user);
  const [products, setProducts] = useState([]);
  const [visibleProductModal, setVisibleProductModal] = useState(false);
  const [productData, setProductData] = useState({});
  const [productCategories, setProductCategories] = useState([]);

  const getProductCategories = () => {
    marketplaceCategoryService.getList().then(res => {
      if(res.code === '0000'){
        setProductCategories(res.marketplace_categories);
      }
    });
  }

  const getProducts = () => {
    marketplaceProductService.getList().then(res => {
      if(res.code === '0000'){
        setProducts(res.marketplace_products);
      }
    });
  }

  useEffect(() => {
      getProductCategories();
      getProducts();
  }, []) 

  const menu = (product) => (
    <Menu onClick={handleMenuClick.bind(this, product)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      {(currentUser.is_system_admin == 1) && <p style={{cursor:'pointer'}} className="deleteButton">
        <Popconfirm
                placement="bottomRight"
                title="You are about to delete individual record"
                onConfirm={() => {
                  confirmDelete(product);
                }}
                okText="Yes"
                cancelText="No"
                className="delete-button"
        >
        <a style={{color:'rgba(0, 0, 0, 0.65)', marginLeft: '11px'}}>
          {<DeleteOutlined />}&nbsp;&nbsp;
          <IntlMessages id="propertyPage.managePage.delete" />
        </a>
        </Popconfirm>
      </p>}
    </Menu>
  );

  const handleMenuClick = (product, e) => {
    if(e.key === 'editDetails'){
      setProductData(product);
      showProductModal();
    }
  }

  const confirmDelete = (del_product) => {
    if(del_product.id){
       marketplaceProductService.destroy(del_product.id).then(res => {
        if(res.code === '0000'){
          getProducts();
        }
      });
    }
  }

  const showProductModal = () => {
    setVisibleProductModal(true);
  }

  const cancelProductModal = () => {
    setVisibleProductModal(false);
    setProductData({});
  }

  const columns = [
    {
      label: <IntlMessages id="adminPage.manageProduct.createdBy" />,
      accessor: 'user',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="adminPage.manageProduct.category" />,
      accessor: 'category',
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2
    },
    {
      label: <IntlMessages id="adminPage.manageProduct.title" />,
      accessor: 'title',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3
    },
    {
      label: <IntlMessages id="adminPage.manageProduct.description" />,
      accessor: 'description',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4
    },
    {
      label: <IntlMessages id="adminPage.manageProduct.price" />,
      accessor: 'price',
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      CustomComponent: cell => {
        let product = cell.row
        return new Intl.NumberFormat().format(product.price)
      }
    },
    {
      label: <IntlMessages id="adminPage.manageProduct.externalLink" />,
      accessor: 'product_amount',
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6
    },
    {
      label: <IntlMessages id="marketplacePage.productModal.linkToProduct" />,
      accessor: '',
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7,
      CustomComponent: cell => {
        let product = cell.row
        return (<a target="_blank" href={"/product-detail?product_id=" + product.id}>{window.location.origin + "/product-detail?product_id=" + product.id}</a>)
      }
    },
    {
      label: <IntlMessages id="adminPage.manageProductCategory.createdAt" />,
      accessor: 'created_at',
      minWidth: 100,
      sortable: true,
      position: 8,
      priorityLevel: 8,
      CustomComponent: cell => {
        let product = cell.row
        return moment(product.created_at).format('L');
      }
    },
    {
      label: <IntlMessages id="adminPage.manageProductCategory.updatedAt" />,
      accessor: 'updated_at',
      minWidth: 100,
      sortable: true,
      position: 9,
      priorityLevel: 9,
      CustomComponent: cell => {
        let product = cell.row
        return moment(product.updated_at).format('L');
      }
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 100,
      sortable: true,
      position: 10,
      priorityLevel: 10,
      noTitle: true,
      CustomComponent: cell => {
        let product = cell.row
        return (
            <Dropdown overlay={menu(product)} trigger={['click']}>
              <Button className="action-button">
                <IntlMessages id="antTable.title.actions" /> <DownOutlined />
              </Button>
            </Dropdown>
        );
      },
    },
  ];

  return (
    <LayoutWrapper>
      <ManageProductPage>
        <PageHeader>
          {<IntlMessages id="adminPage.manageProduct.header" />}
        </PageHeader>
        <div className="button-group">
          {(currentUser.is_system_admin == 1) 
          && <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={showProductModal}>
            <IntlMessages id="marketplacePage.managePage.addProduct" />
          </Button>}
        </div>
        <Box id="containerBox">
        {
          products.length > 0 &&
          <Table 
              columns={columns} 
              rows={products}
              containerId="containerBox"
              showPagination={true}
              rowSize={20}
            />
        }
        </Box>
        <ProductModal visibleProductModal={visibleProductModal} cancelProductModal={cancelProductModal} 
        productCategories={productCategories} getProducts={getProducts} productData={productData}/>
      </ManageProductPage>
    </LayoutWrapper>
  );
}

export default ManageProduct;
