import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import EmergencyModalWrapper from './EmergencyModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Upload } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { emergencyService } from '@iso/services';
import moment from 'moment';
import { emergencyTypes } from '@iso/constants/emergencyTypes';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';

const { Option } = Select;
const { closeModal } = modalActions;

const EmergencyModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [dataEmergency, setDataEmergency] = useState({});
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [completion, setCompletion] = useState([]);
 
  useEffect(() => {
    setDataEmergency(props.emergencyData);
  }, [props.emergencyData, props.visibleEmergency]);

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
    setLoading(false);
  }

  const onStore = (values) => {
    const data = {
      "emergency":{
        ...values.emergency,
        expiry_of_tetanus: values.emergency.expiry_of_tetanus ? dateHelper.dateForAPI(values.emergency.expiry_of_tetanus) : '',
        user_id: currentUser.id,
      }
    }

    emergencyService.storeOrUpdate(data, dataEmergency.id).then(res => {
      if(res.code === '0000'){
        props.onSaved();
        props.cancelModal();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }
  const cancelEmergencyModal = () => {
    if (props.viewSummary) {
      props.setViewSummary(false)
    }else{
    props.cancelModal();
    form.resetFields();
    }
    props.setTitle("Add Emergency")
  } 

  useEffect(() => {
      setFields([
        {
          name: ['emergency', 'email'],
          value: dataEmergency.email || '',
        },
        {
          name: ['emergency', 'type'],
          value: dataEmergency.type || '',
        },
        {
          name: ['emergency', 'full_name'],
          value: dataEmergency.full_name || '',
        },
        {
          name: ['emergency', 'relationship'],
          value: dataEmergency.relationship || '',
        },
        {
          name: ['emergency', 'phone_number'],
          value: dataEmergency.phone_number || '',
        },
        {
          name: ['emergency', 'expiry_of_tetanus'],
          value: dataEmergency.expiry_of_tetanus ? moment(dataEmergency.expiry_of_tetanus) : '',
        },
        {
          name: ['emergency', 'other_type'],
          value: dataEmergency.other_type || '',
        },
        {
          name: ['emergency', 'location'],
          value: dataEmergency.location || '',
        },
        {
          name: ['emergency', 'apply_date'],
          value: dataEmergency.apply_date || '',
        },
        {
          name: ['emergency', 'note'],
          value: dataEmergency.note || '',
        },
      ]);
    
  }, [dataEmergency, props.visibleEmergency]);

  const renderOptionsEmergencyTypes = () => {
    let options = [];
    _.forEach(emergencyTypes, (emergency, index) => {
      options.push(
        <Option key={index} value={emergency.value}>
            <div>{emergency.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a emergency type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const disabledDate = (current) => {
    return current && current < completion;
  }

  return(
    <>
    <EmergencyModalWrapper
       open={props.viewSummary?props.viewSummary:props.visibleEmergency}
  
      onCancel={cancelEmergencyModal}
      maskClosable={true}
      centered
      title={props.title}
      footer={[        
        <Button key="back" onClick={cancelEmergencyModal} className="cancel-button"  hidden ={props.viewSummary ? true:false}>
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="save-button"  hidden ={props.viewSummary ? true:false} type="primary" onClick={handleSubmit} loading={loading} disabled={props.isDisableForm ? true : false}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
  
      <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
          disabled={props.isDisableForm ? true : false}
      >
        <div className="form-body">
          <Form.Item
            name={['emergency', 'type']}
            className="isoInputWrapper"
            label="Emergency Type"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            {renderOptionsEmergencyTypes()}
          </Form.Item>
          <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (prevValues['emergency'] && (prevValues['emergency'].type !== currentValues['emergency'].type));
              }}
          >
          {({ getFieldValue }) => {
            if(getFieldValue('emergency')){
              if (getFieldValue('emergency').type === "emergency_contact"){
                return (
                  <div>
                    <Form.Item
                      name={['emergency', 'full_name']}
                      className="isoInputWrapper"
                      label="Full Name"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        },
                        {
                          max:255,
                          message: 'Full Name must be maximum 255 characters.'
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter"/>
                    </Form.Item>
                    <Form.Item
                      name={['emergency', 'relationship']}
                      className="isoInputWrapper"
                      label="Relationship To You"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        },
                        {
                          max:255,
                          message: 'Relationship To You must be maximum 255 characters.'
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter"/>
                    </Form.Item>
                    <Form.Item
                      name={['emergency', 'phone_number']}
                      className="isoInputWrapper"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true
                        },
                        {
                          max:255,
                          message: 'Phone Number must be maximum 255 characters.'
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter"/>
                    </Form.Item>
                    <Form.Item
                      name={['emergency', 'email']}
                      className="isoInputWrapper"
                      label="Email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                        {
                          max:255,
                          message: 'Full Name must be maximum 255 characters.'
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter"/>
                    </Form.Item>
                  </div>
                )
              }else if (getFieldValue('emergency').type === "expiry_of_tetanus"){
                return (
                  <Form.Item
                      name={['emergency', 'expiry_of_tetanus']}
                      className="isoInputWrapper"
                      label="Date"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ]}
                    >
                      <DatePicker size="large" style={{width: '100%'}} />
                  </Form.Item>
                );
              }else if(getFieldValue('emergency').type === "other"){
                return (
                  <div>
                    <Form.Item
                        name={['emergency', 'other_type']}
                        className="isoInputWrapper"
                        label="Type"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          },
                          {
                          max:255,
                          message: 'Type must be maximum 255 characters.'
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter"/>
                    </Form.Item>
                    <Form.Item
                        name={['emergency', 'location']}
                        className="isoInputWrapper"
                        label="Location"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          },
                          {
                          max:255,
                          message: 'Location must be maximum 255 characters.'
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter"/>
                    </Form.Item>
                    <Form.Item
                        name={['emergency', 'apply_date']}
                        className="isoInputWrapper"
                        label="When To Apply"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          },
                          {
                          max:255,
                          message: 'When To Apply must be maximum 255 characters.'
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter"/>
                    </Form.Item>
                  </div>
                );
              }else if (getFieldValue('emergency').type === "notes"){
                return (
                  <Form.Item
                      name={['emergency', 'note']}
                      className="isoInputWrapper"
                      label="Note"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace:true
                        },
                      ]}
                    >
                      <Input.TextArea placeholder='Write...'/>
                  </Form.Item>
                );
              }else{return '';}
            }
          }}
          </Form.Item>
          </div>    
    </Form>
    
    </EmergencyModalWrapper>
    </>
  )
}

export default EmergencyModal;