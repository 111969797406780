import React from "react";
import { Button, l } from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import SelectPropertyModalWrapper from './SelectPropertyModal.styles'
import _ from "lodash";


import { imageService } from "@iso/services"
import { truncate } from "@turf/turf";
const DeleteModal = (props) => {

  const cancelModal = () => { 
    props.closeModal ();
  };


const onDeleteCard = () => {
    imageService.destroy(props.selectAvtarId).then((res) => {
    if (res.code === "0000") {
        props.closeModal();
        props.updateProfileData();
    } else {
     
    }
  });
}

  return (
    <SelectPropertyModalWrapper
    open={props.visible}
      title={<IntlMessages id="Delete Avatar" />}
      onCancel={cancelModal}
      maskClosable={truncate}
      footer={
        <>
              <Button
                key="back"
                onClick={onDeleteCard}
                className="save-button"
              >
                {<IntlMessages id="Yes" />}
              </Button>
              <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id='No' />}
        </Button>
              </>  
      }
    >
      <div className="form-body" >
        <h3 className="dltInfo">
          Are you sure,You want to delete this avatar!
        </h3>
        <br />
       
      </div>
    </SelectPropertyModalWrapper>
  );
};

export default DeleteModal;
