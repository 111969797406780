import Search from "@iso/containers/Pages/Search/Search";
import AdminDashboard from '@iso/containers/Pages/AdminPages/Dashboard/Dashboard';
import ManageProductCategory from '@iso/containers/Pages/AdminPages/ManageProductCategory/ManageProductCategory';
import ManageProduct from '@iso/containers/Pages/AdminPages/ManageProduct/ManageProduct';
import ProductDetail from '@iso/containers/Pages/Marketplace/ProductDetail';
import ManageProperty from '@iso/containers/Pages/AdminPages/ManageProperty/ManageProperty';
import ManageUser from '@iso/containers/Pages/AdminPages/ManageUser/ManageUser';
import ManageSampleAvatar from '@iso/containers/Pages/AdminPages/ManageSampleAvatar/ManageSampleAvatar';
import ManageForm from '@iso/containers/Pages/AdminPages/ManageForm/ManageForm';
import FormBuilder from '@iso/containers/Pages/AdminPages/FormBuilder/FormBuilder';
import CategoryForm from '@iso/containers/Pages/AdminPages/CategoryForm/CategoryForm';
import Agent from '@iso/containers/Pages/AdminPages/Agent/Agent';
import ManageSensor from '@iso/containers/Pages/AdminPages/ManageSensor/ManageSensor';

export default {
	ProductDetail: {
	    component: ProductDetail,
	    path: '/product-detail'
	},
  AdminDashboard: {
    component: AdminDashboard,
    path: '/admin-dashboard'
  },
  ManageProductCategory: {
    component: ManageProductCategory,
    path: '/manage-product-category'
  },
  ManageProduct: {
    component: ManageProduct,
    path: '/manage-product'
  },
  ManageProperty: {
    component: ManageProperty,
    path: '/manage-property'
  },
  ManageUser: {
    component: ManageUser,
    path: '/manage-user'
  },
  ManageSampleAvatar: {
    component: ManageSampleAvatar,
    path: '/manage-sample-avatar'
	},
	Search: {
		component: Search,
		path: '/search'
	},
	ManageForm: {
    component: ManageForm,
    path: '/manage-form'
  },
  FormBuilder: {
    component: FormBuilder,
    path: '/form-builder'
  }, 
  CategoryForm: {
    component: CategoryForm,
    path: '/category-form'
  },
  Agent: {
    component: Agent,
    path: '/manage-agent'
  },
  ManageSensor: {
    component: ManageSensor,
    path: '/manage-sensor'
  },
};
