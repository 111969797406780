
import { useRef,useState,useEffect } from "react";
import "./CalenderContent.css"
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import TaskManagePage from "../Task.styles";
import Box from "@iso/components/utility/box";
// import "./styles.css";
import Calendar from "react-awesome-calendar";

export default function CalenderContent({ data, setData }) {

  const [taskView, setTaskView] = useState([]);
  let calendarRef = useRef();

    useEffect(() => {
    if (data) {
      let arr = [];
      data?.lanes?.map((item) => {
        return item.cards.map((ele) => {
          arr.push({ ...ele, type: item.id });
        });
      });
      listViewTask(arr);
    }
  }, [data]);

  const listViewTask = (lanes) => {
    const tasksArr = [];
    if (lanes.length > 0) {
     lanes.forEach((lane) => {
        return tasksArr.push({
          from: lane.metadata?.start_date,
            to: lane.metadata?.due_date,
          title:lane.title,
          id: lane.id,
          color: 
              lane.type === "todo"
                ? "rgba(24, 119, 242, 0.60)"
                : lane.type === "underway"
                  ? "rgba(250, 196, 101, 0.60)"
                  : lane.type === "completed"
                    ? "rgb(0,100,0, 0.60)"
                    : "#7CFC00",
        });
      });
      setTaskView(tasksArr);
    }
  };

  return (
    <div>
      <Box id="container">
        <div className="mainDiv">
          {taskView.length > 0 && <Calendar ref={calendarRef} events={taskView} />}
        </div>
      </Box>  
      </div>
  );
}