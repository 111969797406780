import React , { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col } from 'antd';
import { propertyService, googleService } from '@iso/services';
import IntlMessages from '@iso/components/utility/intlMessages';
import SelectPropertyModalWrapper from './SelectPropertyModal.styles';
import { Checkbox, Radio } from 'antd';
import _ from 'lodash';
import Alert from '@iso/components/Feedback/Alert';
import PropertyModal from './PropertyModal';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const SelectPropertyModal = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const autoCompleteRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [contactChecked, setContactChecked] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [errMsg, setErrMsg] = useState("");
  const [visible, setVisiblity] = React.useState(false);
  const [editProperty, setEditProperty] = React.useState({});

  const cancelModal = () => {
    props.cancelSelectPropertyModal();
  }


  const showModal = () => {
    setVisiblity(true);
    props.cancelSelectPropertyModal();
  }

  const cancelPropertyModal = () => {
    setVisiblity(false);
  }

  const redirectToPropertyPage = () => {
    history.push('/manage-properties');
  }

  return (
    <SelectPropertyModalWrapper
      visible={props.visibleSelectProperty && currentUser.is_system_admin !== 1}
      title={<IntlMessages id="propertyPage.modal.notify" />}
      onCancel={cancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.ok" />}
        </Button>
      ]}
    >
    <div className="form-body" style={{textAlign:'center'}}>
      <h3>You don't have any properties activated, please create a new propery or activate one of your properties</h3>
      <br/>
      {!props.propertyPage && (<Button icon={<ArrowRightOutlined />} type="primary" className="btn-success" onClick={redirectToPropertyPage} size="large">
        <IntlMessages id="selectProperty.modal.redirectProperty" />
      </Button>)}
    </div>
    </SelectPropertyModalWrapper>
  );
}

export default SelectPropertyModal;