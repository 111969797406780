export const surveyQuestionTypes = [
  {
    label: 'Yes/No',
    value: 'yes/no',
  },
  {
    label: 'Scale',
    value: 'scale',
  },
  {
    label: 'Multiple Choice',
    value: 'multiple_choice',
  },
  {
    label: 'Signature',
    value: 'signature',
  },
  {
    label: 'Fixed Text',
    value: 'fixed_text',
  },
  {
    label: 'Fixed Image',
    value: 'fixed_image',
  },
  {
    label: 'Multi Selection',
    value: 'multi_selection',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Text',
    value: 'text',
  }
]