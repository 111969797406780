import React,{ useEffect, useState, useRef } from 'react';
import { Button, Row, Col, Form, message,Input, Steps, Switch } from 'antd';
import { formService, visitorFormService } from '@iso/services';
import FormBuilder from 'antd-form-builder';
import _ from 'lodash';
import SignaturePad from '@iso/components/SignaturePad/index';
import { isDesktop, isTablet } from "react-device-detect";
import GlobalFormWrapper from './GlobalForm.style'
import DragDropQuestion from "@iso/components/DragDropQuestion/DragDropQuestion";

const isMobile = !isDesktop && !isTablet;
const { Step } = Steps;

const GlobalForm = (props) => {
  const [loading, setLoading] = useState(false);
  const formRef = React.createRef()
  const [globalForm] = Form.useForm()
  const [fields, setFields] = useState([]);
  const [steps, setSteps] = useState({});
  const [newWizardMeta, setNewWizardMeta] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsLength, setStepsLength] = useState(0);
  const [isSimpleForm, setIsSimpleForm] = useState(true);
  const forceUpdate = FormBuilder.useForceUpdate();

  const getStepInfo = (globalForms) => {
    let questionIndex = -1;
    let stepInfo = {};
    if (globalForms) {
      globalForms.forEach((form, formIndex) => {
        form.meta_fields.fields.forEach((field, fieldIndex) => {
          questionIndex++;
          stepInfo[questionIndex] = formIndex;
        });
      });
    }
    stepInfo[questionIndex + 1] = globalForms.length;
    setSteps(stepInfo);
  };

  const getWizardMeta = (globalForms) => {
    let wizardMeta = {
      steps: [],
    };
    let reviewFields = [];
    if (globalForms) {
      globalForms.forEach((form, formIndex) => {
        form.meta_fields.fields.forEach((field, fieldIndex) => {
          if (field["widgetLabel"] === "signature") {
            field["widget"] = SignaturePad;
          }
          if (field["widgetLabel"] === "dragdrop") {
            field["widget"] = DragDropQuestion;
          }
          wizardMeta.steps.push({
            formMeta: {
              columns: 1,
              fields: [field],
            },
          });
        });
        reviewFields.push(
          {
            key: "review" + formIndex,
            colSpan: isMobile ? 1 : 2,
            render() {
              return (
                <fieldset>
                  <legend>{form.description}</legend>
                </fieldset>
              );
            },
          },
          ...form.meta_fields.fields
        );
      });
      wizardMeta.steps.push({
        key: "review",
        title: "Review",
        formMeta: {
          columns: isMobile ? 1 : 2,
          fields: reviewFields,
        },
      });
    }
    setNewWizardMeta(wizardMeta);
    setStepsLength(wizardMeta.steps.length);
  };

  useEffect(() => {
    if (props.formData?.category_global_form_data?.globalForms) {
      getStepInfo(props.formData.category_global_form_data.globalForms);
      getWizardMeta(props.formData.category_global_form_data.globalForms);
    }
  }, [props.formData]);

	const handleSubmitGlobalForm = async () => {
        if(props.visitorForm){
          const visitorFormValidation = await props.visitorForm.validateFields();
          if(visitorFormValidation.errorFields){
            return;
          }
        }
        setLoading(true);
        globalForm
          .validateFields()
          .then(values => {
            handleFinish(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
            setLoading(false);
        });
    }

    const handleFinish = (values) => {
      const data = {
	      form: {
	        global_form: values,
	        type: 'system_forms'
	      }
	    }

      if(props.visitorForm){
        data.visitor = props.visitorForm.getFieldsValue().visitor;
        data.form.visitor_form_token = props.visitorFormToken;
      }
      const handleFn = props.visitorForm ? visitorFormService.createVisitorForm(data) : formService.storeOrUpdate(data, props.formData.id)
	    handleFn.then(res => {
	      if(res.code === '0000'){
	        message.success('Submit success');
          props.setShowFinalScreen(true);
	        setLoading(false);
	      }else{
	        message.error(res.message)
	        setLoading(false);
	      }
	    });
    }

    const getFields = (fields) => {
      for(let i in fields){
        if(fields[i]['widgetLabel'] === 'signature'){
          fields[i]['widget'] = SignaturePad
        }
      }
      return fields;
    }

  const renderFormBuilder = (globalForm) => {
    return (
      <>
      {globalForm.map(value => (
        <>
        <h3>{value.name}</h3>
        <p>{value.description}</p> 
        <FormBuilder meta={getFields(value.meta_fields.fields)} form={formRef} />
        <hr/>
        </>
      ))}
      </>
    )
  }

  const changeIsSimpleForm = () => {
    setIsSimpleForm(isSimpleForm ? false : true);
  };

  const renderStep = () => {
    return (
      <Steps current={steps[currentStep]} className="form-step">
        {props.formData?.category_global_form_data?.globalForms.map((form) => (
          <Step key={form.description} title={form.description} />
        ))}
        <Step key="Review" title="Review" />
      </Steps>
    );
  };

  const handleNext = () => {
    globalForm.validateFields().then(() => {
      setCurrentStep(currentStep + 1);
    });
  };
  const handleBack = () => {
    globalForm.validateFields().then(() => {
      setCurrentStep(currentStep - 1);
    });
  };

  return (
    <GlobalFormWrapper>
      <Row>
        <Col xs={{span: 24}} md={{span: 24}}>
        <Switch
          className="form-type-switch"
          checkedChildren="Simple form"
          unCheckedChildren="Advanced form"
          checked={isSimpleForm}
          onChange={changeIsSimpleForm}
        />
        {
          isSimpleForm ? (
            <Form layout="vertical" form={globalForm} fields={fields}>
              <h1>{props.formData?.category_global_form_data.name}</h1>
              <p>{props.formData?.category_global_form_data.description}</p>
              <hr/>
              {renderFormBuilder(props.formData?.category_global_form_data.globalForms)}
              <Form.Item className="form-footer">
                <Button type="primary" className="btn-success" key="submit" onClick={handleSubmitGlobalForm} loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          ):
          (
            <Form
              form={globalForm}
              onValuesChange={forceUpdate}
              layout="vertical"
              className="preview-form-area advanced-form"
              onFinish={handleFinish}
            >
              <h1>{props.formData?.category_global_form_data.name}</h1>
              <p>{props.formData?.category_global_form_data.description}</p>
              <hr/>
              {renderStep()}
              {newWizardMeta.steps && newWizardMeta.steps.length && (
                <FormBuilder
                  form={globalForm}
                  meta={newWizardMeta.steps[currentStep].formMeta}
                />
              )}
    
              <Form.Item className="form-footer" style={{ textAlign: "right" }}>
                {currentStep > 0 && (
                  <Button className="btn-back" onClick={handleBack}>
                    Back
                  </Button>
                )}
                <Button
                  type="primary"
                  onClick={
                    currentStep === stepsLength - 1
                      ? () => globalForm.submit()
                      : handleNext
                  }
                >
                  {currentStep === stepsLength - 1 ? "Submit" : "Next"}
                </Button>
              </Form.Item>
            </Form>
          )
        }
          
        </Col>
      
      </Row>
  	</GlobalFormWrapper>
  );
}

export default GlobalForm;
