import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, Input, Button, Select, Row, Col, DatePicker, InputNumber } from 'antd';
import _ from 'lodash';
import { livestockService, activityService, applicationHistoryService } from '@iso/services';
import { areaHistoryCategories } from '@iso/constants/areaHistoryCategories';
import AreaSummaryModalWrapper from './AreaSummaryModal.styles';
import { drugUnit } from '@iso/constants/drugUnit';
import { treatmentCategories } from '@iso/constants/treatmentCategories';
import { pregTesting } from '@iso/constants/pregTesting';
import { objectColors } from '@iso/constants/objectColors';
import ApplicationModalWrapper from './ApplicationModal.styles';
import styled from "styled-components";

const { Option } = Select;

const AddAreaHistoryModal = (props) => {
  const [addHistoryForm] = Form.useForm();
  const [applicationForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [assets, setAssets] = useState([]); 
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [liveStockActivities, setLiveStockActivities] = useState([]);
  const [applications, setApplications] = useState({});
  const [visibleApplication, setVisibleApplication] = useState(false);
  const [loadingApplication, setLoadingApplication] = useState(false);

  useEffect(() => {
    if(props.propertyId){
       applicationHistoryService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setApplications(res.application_histories);
          setLoadingApplication(false);
        }
      });
    }
  }, [props.propertyId]);


  useEffect(() => {
    if(props.propertyId){
       livestockService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setAssets(res.assets);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId, props.visibleHistory]);


  const addApplication = (object, e) => {
    setVisibleApplication(true);
  }

  const cancelModalHistory = () => {
    props.cancelModal();
    addHistoryForm.resetFields();
  }

  const cancelModalApplication = () => {
    setVisibleApplication(false);
  }

  const renderOptionsApplication = (application_option) => {
    let options = [];
     _.forEach(application_option, (application, index) => {
      options.push(
        <Option key={index} value={application.name}>
            <div>{application.name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an application"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const handleApplicationSubmit = (e) => {
    applicationForm
      .validateFields()
      .then(application_values => {
        onStoreApplication(application_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingApplication(false);
      });
  }

  const onStoreApplication = (application_values) => {
    const application_data = {
      "application_history":{
        ...application_values.application,
        property_id: props.propertyId
      }
    }

    applicationHistoryService.storeOrUpdate(application_data).then(res => {
      if(res.code === '0000'){
        var tmp_applications = _.clone(applications);
        tmp_applications.push(res.application_history);
        setApplications(tmp_applications);
        cancelModalApplication();
        applicationForm.resetFields();
        setFields([
          {
            name: ['history', 'application'],
            value: res.application_history.name,
          },
        ]);
      } else {
        setLoadingApplication(false);
      }
    });

  }

  const handleHistorySubmit = (e) => {
    addHistoryForm
      .validateFields()
      .then(history_values => {
        onStoreHistory(history_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingHistory(false);
      });
  }

  const onStoreHistory = (history_values) => {
    const history_data = {
      "history":{
        metadata: history_values.history,
        action:'create',
        model: props.model,
        object_id: props.dataId,
        type: props.type,
        property_id: props.propertyId
      }
    }
    
    activityService.storeOrUpdate(history_data).then(res => {
      if(res.code === '0000'){
        cancelModalHistory();
        addHistoryForm.resetFields();
        props.updateAreaHistoryData(props.dataId, 'area');
      } else {
        setLoadingHistory(false);
      }
    });

  }

  const renderOptionsActivityCategories = () => {
    let activity_options = [];
     _.forEach(areaHistoryCategories, (activity, index) => {
      activity_options.push(
        <Option key={index} value={activity.value}>
            <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select type of history"
        allowClear
        size="large"
      >
        {activity_options}
      </Select>
    );
  }

  return (
    <>
    <AreaSummaryModalWrapper
      visible={props.visibleHistory}
      onCancel={cancelModalHistory}
      centered
      maskClosable={false}
      title={ <IntlMessages id="propertyPage.mobModal.historyTab.addHistory"/> }
      footer={[        
        <Button key="back" onClick={cancelModalHistory} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="save-button" type="primary" onClick={handleHistorySubmit} loading={loadingHistory}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
    
          <Form
            form={addHistoryForm}
            layout="vertical"
            scrollToFirstError
            fields={fields}
          >
          <div className="form-body">
            <Form.Item
                  name={['history', 'activity_category']}
                  className="isoInputWrapper"
                  label="Type of History"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                {renderOptionsActivityCategories()}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues['history'];
              }}
            >
            {({ getFieldValue }) => {
              if(getFieldValue('history')){
                if (getFieldValue('history').activity_category === "treatment"){
                  return (<div><Form.Item
                                name={['history', 'name']}
                                className="isoInputWrapper"
                                label="Treatment Name"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input size="large" placeholder="Input treatment name"/>
                          </Form.Item>
                          <Form.Item
                              name={['history', 'quantity']}
                              className="isoInputWrapper"
                              label="Quantity"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                            <InputNumber size="large" min={1} max={1000} />
                          </Form.Item>
                          <Row>
                            <Col xs={{span: 12}} md={{span: 16}}>
                              <Form.Item
                                name={['history', 'application']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="areaHistory.modal.treatment.application" />}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              {renderOptionsApplication(applications)}
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 10, offset: 2}} md={{span: 6, offset: 2}}>
                              <Button key="submit" className="btn-create-feeding-type" type="primary" onClick={addApplication}>
                                {<IntlMessages id="areaHistory.modal.treatment.addApplication.title" />}
                              </Button>
                            </Col>
                          </Row>
                          <Form.Item
                            name={['history', 'description']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="areaHistory.modal.treatment.description" />}
                          >
                            <Input.TextArea />
                          </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "fertilizer"){
                  return (<div><Form.Item
                                name={['history', 'name']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="areaHistory.modal.fertilizer.name" />}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input size="large" placeholder="Input treatment name"/>
                          </Form.Item>
                          <Form.Item
                              name={['history', 'quantity']}
                              className="isoInputWrapper"
                              label="Quantity"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                            <InputNumber size="large" min={1} max={1000} />
                          </Form.Item>
                          <Row>
                            <Col xs={{span: 12}} md={{span: 16}}>
                              <Form.Item
                                name={['history', 'application']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="areaHistory.modal.treatment.application" />}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              {renderOptionsApplication(applications)}
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 10, offset: 2}} md={{span: 6, offset: 2}}>
                              <Button key="submit" className="btn-create-feeding-type" type="primary" onClick={addApplication}>
                                {<IntlMessages id="areaHistory.modal.treatment.addApplication.title" />}
                              </Button>
                            </Col>
                          </Row>
                          <Form.Item
                            name={['history', 'description']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="areaHistory.modal.treatment.description" />}
                          >
                            <Input.TextArea />
                          </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "sow"){
                  return (<div><Form.Item
                                name={['history', 'seed']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="areaHistory.modal.sow.seed" />}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input size="large" placeholder="Input seed"/>
                          </Form.Item>
                          <Form.Item
                              name={['history', 'volume']}
                              className="isoInputWrapper"
                              label={<IntlMessages id="areaHistory.modal.sow.volume" />}
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                            <InputNumber size="large" min={1} max={1000} />
                          </Form.Item>
                          <Row>
                            <Col xs={{span: 12}} md={{span: 16}}>
                              <Form.Item
                                name={['history', 'application']}
                                className="isoInputWrapper"
                                label={<IntlMessages id="areaHistory.modal.treatment.application" />}
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              {renderOptionsApplication(applications)}
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 10, offset: 2}} md={{span: 6, offset: 2}}>
                              <Button key="submit" className="btn-create-feeding-type" type="primary" onClick={addApplication}>
                                {<IntlMessages id="areaHistory.modal.treatment.addApplication.title" />}
                              </Button>
                            </Col>
                          </Row>
                          <Form.Item
                            name={['history', 'description']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="areaHistory.modal.treatment.description" />}
                          >
                            <Input.TextArea />
                          </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "pasture_development"){
                  return (<div>
                            <Form.Item
                              name={['history', 'description']}
                              className="isoInputWrapper"
                              label={<IntlMessages id="areaHistory.modal.treatment.description" />}
                            >
                              <Input.TextArea />
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "earth_works"){
                  return (<div>
                            <Form.Item
                              name={['history', 'description']}
                              className="isoInputWrapper"
                              label={<IntlMessages id="areaHistory.modal.treatment.description" />}
                            >
                              <Input.TextArea />
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "other"){
                  return (<div>
                            <Form.Item
                              name={['history', 'description']}
                              className="isoInputWrapper"
                              label={<IntlMessages id="areaHistory.modal.treatment.description" />}
                            >
                              <Input.TextArea />
                            </Form.Item>
                          </div>)
                }else {}
              }
            }}
            </Form.Item>
          </div>    
      </Form>
    }
    </AreaSummaryModalWrapper>
    <ApplicationModalWrapper
        visible={visibleApplication}
        onCancel={cancelModalApplication}
        centered
        maskClosable={false}
        title={ <IntlMessages id="areaHistory.modal.treatment.addApplication.title"/> }
        footer={[        
          <Button key="back" onClick={cancelModalApplication} className="cancel-button">
            {<IntlMessages id="propertyPage.modal.cancel" />}
          </Button>,
          <Button key="submit" className="btn-success" type="primary" onClick={handleApplicationSubmit} loading={loadingApplication}>
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>,
        ]}
      >
      {
      
      <Form
        form={applicationForm}
        layout="vertical"
        scrollToFirstError
      >
      <div className="form-body">
        <Form.Item
          name={['application', 'name']}
          className="isoInputWrapper"
          label={<IntlMessages id="areaHistory.modal.treatment.application" />}
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input size="large" placeholder="Input application"/>
        </Form.Item>
        </div>    
      </Form>
      }
    </ApplicationModalWrapper>
    </>
  )
}


export default AddAreaHistoryModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;
