import React from 'react';

const HealthSafety = () => {
  return (
    <div className="sidebar-icon">
    
    
    <svg id="Layer_1"
    className="sidebar-icon"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 512 512"
        // fill="rgb(143, 148, 151) !important"
  
  // opacity: 0.5;
  display= 'flex'>

<g className="sidebar-icons">
	<g className="sidebar-icons">
		<path d="M473.9,462.9H22.7c-5.3,0-10.3-2.9-13-7.5c-2.7-4.6-2.6-10.4,0.1-15L240.4,45.2c2.9-5,8.4-8.1,14.2-8.1
			c5.8,0,11.2,3.2,14.1,8.3l221.1,390.3c3.2,5.7,3.2,12.6-0.1,18.3C486.4,459.5,480.5,462.9,473.9,462.9z M31.2,443.1h440
			L254.5,60.5L31.2,443.1z M257.6,55.2C257.6,55.2,257.6,55.2,257.6,55.2L257.6,55.2z"
     
      />
	
		<path d="M372.3,401.6H132.4c-6.8,0-12.8-3.5-16.2-9.4s-3.4-12.9,0.1-18.7l126.1-216.1c2.3-4,6.6-6.2,11.2-6.4
			c4.6,0,8.9,2.5,11.1,6.5l123.1,217.3c3.2,5.6,3.1,12.3-0.1,17.9C384.5,398.3,378.7,401.6,372.3,401.6z M134.5,381.7H369
			L253.4,177.8L134.5,381.7z M259.5,167.4L259.5,167.4C259.5,167.4,259.5,167.4,259.5,167.4L259.5,167.4z"
     
      />
	</g>
</g>
</svg>

    </div>
  );
}
export default HealthSafety;