import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const WeatherTableSection = styled.div`
.tablecust{
  border-radius : 10px !important;
}

  .button-group {
    float: right;
    clear: both;
  }
  .btn-success{
    background-color: #0B2A1B;
    border-color: #0B2A1B;
    &:hover{
      opacity: 0.8;
    }
  }  
  .ant-table-wrapper {
    min-width: 100%;
  }
  .delete-button{
    margin-left:8px;
  }
`;

export default WithDirection(WeatherTableSection);