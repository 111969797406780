import React, { useState, useEffect, useRef } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import SensorsPage from "./Sensors.styles"
import PageHeader from "@iso/components/utility/pageHeader";
import Box from "@iso/components/utility/box";
import IntlMessages from "@iso/components/utility/intlMessages";
import Highlighter from 'react-highlight-words';

import { Popconfirm, Button, Pagination, Dropdown, Menu, message } from 'antd';

import {
  PlusOutlined,
  DownOutlined,
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  PaperClipOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import Table from "@iso/components/collapsing-table/table";
import Loader from "@iso/components/utility/loader";
import SensorModal from "./SensorModal"
import { sensorService,deviceSensorService } from "@iso/services";

const ManageSensor = (props) => {
  const [visibleSensorModal, setVisibleSensorModal] = useState(false);
  const [data, setData] = useState([]);
  const [sensorData, setSensorData] = useState({});
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const [suppliers, setSuppliers] = useState([]);
  
  
  useEffect(() => {
      getSensors();
  }, []);

  const showModal = () => {
    setVisibleSensorModal(true)
  }

  const cancelModal = () => {
    setVisibleSensorModal(false)
    setSensorData({})
  }

  const updateSensorsData = (page = 1) => {
    setCurrentPage(page);
    getSensors({ page: page });
  };

  const getSensors = (params = {}) => {
    params.page = params.page ? params.page : currentPage;
    params.search = params.search ? params.search : "";
    deviceSensorService.getList(params).then((res) => {
      if (res.code === "0000") {
        setData(res.devices);
        setTotalPages(res.total_page);
        // setSearchQuery(res.assets);
      }
    });
  };

  const menu = (sensor) => (
    <Menu onClick={handleMenuClick.bind(this, sensor)}>
      {/* <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item> */}
      { <p style={{cursor:'pointer'}} className="deleteButton">
        <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(sensor);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
        <a style={{color:'rgba(0, 0, 0, 0.65)', marginLeft: '11px'}}>
          {<DeleteOutlined />}&nbsp;&nbsp;
          <IntlMessages id="propertyPage.managePage.delete" />
        </a>
        </Popconfirm>
      </p>}
    </Menu>
  );

  const confirmDelete = (device) => {
    if (device.id) {
      deviceSensorService.destroy(device.id).then((res) => {
        if (res.code === "0000") {
          let tmpData = _.clone(data);
          const Index = _.findIndex(tmpData, (d) => {
            return d.id === res.device.id;
          });
          delete tmpData[Index];
          setData(tmpData);
          message.success("Delete Sensor success!");
        }
      });
    }
  };

  const handleMenuClick = (device, e) => {
    if (e.key === "editDetails") {
      setSensorData(device);
      showModal();
    }
  };

  const columns = [
    {
      label: 'Supplier',
      accessor: "supplier",
      minWidth: 100,
      sortable: true,
    },
    {
      label: 'Device ID',
      accessor: "terminal_id",
      minWidth: 100,
      sortable: true,
    },
    {
      label: 'Property',
      accessor: "property_name",
      minWidth: 100,
      sortable: true,
      CustomComponent: (cell) => {
        const property = cell.row.property;

        if (property) {
          return (
            <div>{property.name}</div>
          )
        } else {
          return (
            <div>N/A</div>
          )
        }
      }
    },
    {
      label: 'Created Date',
      accessor: "created_at",
      minWidth: 100,
      sortable: true,
    },
    {
      label: "",
      accessor: "actions",
      minWidth: 100,
      sortable: false,
      noTitle: true,
      CustomComponent: (cell) => {
        let device = cell.row;
        return (
          <Dropdown overlay={menu(device)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handlePageChange = () => {

  }
  return (
    <LayoutWrapper>
      <SensorsPage>
        <Box id="containerBox">
          <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Sensors</h2>
            </div>
            <>
              
              <div className="button-group">
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  className="btn-success"
                  onClick={showModal}
                >
                  <IntlMessages id="sensorPage.managePage.addSensor" />
                </Button>
              </div>
            </>
          </div>
          <div className="manage-table">
            {data.length > 0 ? (
              <>
                <Table
                  columns={columns}
                  rows={data}
                  containerId="containerBox"
                  showPagination={false}
                  rowSize={pageSize}
                  showSearch={false}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
                <div style={{ padding: "16px" }}>
                  <Pagination
                    current={currentPage}
                    total={data?.length}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50", "100"]}
                  />
                </div>
              </>
            ) : (
              <h1 style={{ padding: "15px 15px",color:'gray' }}>
                <IntlMessages id="dashboardPage.noData" />
              </h1>
            )}
          </div>
        </Box>
      </SensorsPage>
      <SensorModal visibleSensorModal={visibleSensorModal} cancelModal={cancelModal} 
       updateSensorsData={updateSensorsData} sensorData={sensorData} suppliers={suppliers}/>
    </LayoutWrapper>
  );
};

export default ManageSensor;
