import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import { mobService } from '@iso/services';
import {  IconMob  } from '@iso/containers/Pages/PropertyMap/Icons/IconMob';
import _ from 'lodash';

const Mob = (props) => {
  const [mobs, setMobs] = useState({});
  const objectRef = React.useRef([]);

  const [dataMob, setDataMob] = useState({});

  const updateDataMobs = React.useCallback((propertyId) => {
    mobService.getList(propertyId).then(res => {
      if(res.code === '0000'){
        setMobs(res.mobs);
      }
    })
  }, [props.primaryObjects]);

  useEffect(() => {
    if(props.propertyId){
      updateDataMobs(props.propertyId);
    }
  }, [updateDataMobs, props.propertyId]);

  const handleShowMobs = (data) => {
    var html = [];
    if(data.length > 0){
      _.forEach(data, function(mob){
        html.push(
          <Marker 
            key={mob.id} 
            position={[mob.lat, mob.lng]}
            icon={IconMob(mob.tag_colour, mob.tag_number_range, mob.type)}
            ref={el => (objectRef.current[mob.id] = el)}            
          />
        );
      });
    }
    return html;
  }

  return (
    <>
      {handleShowMobs(mobs)}
    </>
  )
}

export default Mob;