export const safetyRadiusOptions = [
    {
      label: '0.5 km',
      value: 0.5,
    },
    {
      label: '1 km',
      value: 1,
    },
    {
      label: '2 km',
      value: 2,
    },
    {
      label: '3 km',
      value: 3,
    },
    {
      label: '4 km',
      value: 4,
    },
    {
      label: '5 km',
      value: 5,
    },
    {
      label: '10 km',
      value: 10,
    },
    {
      label: '20 km',
      value: 20,
    },
]
