import React,{useEffect,useState} from 'react';
import { Col, Row, Slider,Popover,Button } from 'antd';

const PrecipitationIntensityControl = (props) => {
  const {mapgl,setRasterOpacityPrecipitation,rasterOpacityPrecipitation,visiblePrecipitation,setVisiblePrecipitation} = props;

  useEffect(() => {
    if(mapgl){
      mapgl.setLayoutProperty('precipitation-intensity','visibility', visiblePrecipitation ? 'visible' : 'none');
      mapgl.setPaintProperty(
        'precipitation-intensity',
        'raster-opacity',
          parseInt(rasterOpacityPrecipitation, 10) / 100
      );
    }
  },[visiblePrecipitation,rasterOpacityPrecipitation]);

  const content = (
    <Row>
      {visiblePrecipitation &&
      <Col span={21}>
        <Slider
          min={0}
          max={100}
          onChange={ (value) => setRasterOpacityPrecipitation(value)}
          value={ rasterOpacityPrecipitation ? rasterOpacityPrecipitation : 0}
        />
      </Col>
      }
      {visiblePrecipitation &&
        <Col span={3}> {rasterOpacityPrecipitation} </Col>
      }
    </Row>
  );

  return (
     <Popover 
      content={content} 
      title="Precipitation Intensity" 
      className={visiblePrecipitation ? 
      "mapboxgl-ctrl-active" :
      ""}>
      <Button 
        className="mapboxgl-ctrl-icon mapboxgl-ctrl-precipitation-intensity"
        onClick={() => setVisiblePrecipitation(setVisiblePrecipitation => !setVisiblePrecipitation)}>
      </Button>
    </Popover>

  )
}
export default PrecipitationIntensityControl;