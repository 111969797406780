import React, { Fragment, Suspense,useState,useEffect, useMemo } from "react"
import { useSelector, useDispatch } from 'react-redux';
import PrivateLayout from './PrivateLayout/PrivateLayout';
import PublicLayout  from './PublicLayout/PublicLayout';
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import privateRoutes from '../routes/private';
import adminRoutes from '../routes/admin';
import publicRoutes  from '../routes/public';
import _ from 'lodash';
import ErrorBoundary from './ErrorBoundary';
import Loader from '@iso/components/utility/loader';
import Page404 from "@iso/containers/Pages/404/404";
import VerifyPage from "@iso/containers/Pages/VerifyPage/VerifyPage";
import appActions from '@iso/redux/app/actions';
import { propertyService,subscriptionService } from '@iso/services';
import queryString from 'query-string';
import propertyActions from '@iso/redux/property/actions';
import { propertyHelper } from "@iso/lib/helpers/propertyHelper";

const { changeCurrent } = appActions;
const { changeAgent } = propertyActions;

const Main = () => {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const currentUser = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const pathArray = window.location.pathname.split('/');
  const urlVerify = pathArray[1];
  const [stripePlan, setStripePlan] = useState();
  const activeId = useSelector((state) => state.property.activePropertyId);
  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const shareMapToken = queryString.parse(window.location.search).sharing_token || '';
  const formToken = queryString.parse(window.location.search).token || '';
  if(shareMapToken){
    propertyService.getPropertyMetaByToken(shareMapToken).then(res => {
      if(res.code === '0000'){
        if(res.property_metas?.actived?.meta_value && res.property_metas?.is_for_sale?.meta_value){
          dispatch(changeAgent(res.agent));
        }else{
          dispatch(changeAgent(null));
        }
      }
    })
  }

  const handlePrivateLayout = () => {
    if(isLoggedIn){
      if(currentUser.is_system_admin == 1)
      {
        return (
          <PrivateLayout>
            <Switch>
              {_.map(adminRoutes, (route, key) => {
                const { component, path } = route;
                return (
                  <Route exact path={path} key={key} component={component}/>
                )
              })}
              <Route exact path="/" key="admin-dashboard" render={() => { dispatch(changeCurrent(['admin-dashboard'])); return ( <Redirect to="/admin-dashboard"/> ); }} />
              <Route component={Page404} />
            </Switch>
          </PrivateLayout>
        )
      }else{
        if(urlVerify === 'verify'){
          return (<Route path="/verify" component={VerifyPage} />);
        } else {
          return (
            <PrivateLayout>
              <Switch>
                {_.map(privateRoutes, (route, key) => {
                  const { component, path } = route;
                  if(path !=='/health-safety' && path !=='/safe-farm'){
                    if(!currentUser.is_public_view_user || path === '/share-map' || path === '/dashboard'){
                      return (
                        <Route exact path={path} key={key} component={component}/>
                      )
                    }
                  }else{
                    if(propertyHelper.checkIsGoldPackage(activePropertySubscription)){
                      return (
                        <Route exact path={path} key={key} component={component}/>
                      )
                    }
                  }
                })}
                <Route exact path="/" key="dashboard" render={() => { dispatch(changeCurrent(['dashboard'])); return ( <Redirect to="/dashboard"/> ); }} />
                <Route component={Page404} />
              </Switch>
            </PrivateLayout>
          )
        }
      }
      
    }
  }

  const exceptedRoutes = ["/visitor-form", "/stop-device-alarm-request", "/sar-elapsed-time"]

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
          { handlePrivateLayout() }
          { !isLoggedIn && currentUser.is_system_admin != 1  &&
            <Fragment>
              <Switch>
                {_.map(privateRoutes, (route, key) => {
                  const { path } = route;
                  if(path === '/form-submission') {
                    return <Route exact path={path} key={key} render={() => ( <Redirect to={`/signin?form_token=${formToken}`} /> )} />
                  }else if(path === '/share-map') {
                    return <Route exact path={path} key={key} render={() => ( <Redirect to={`/signin?sharing_token=${shareMapToken}`} /> )} />
                  }else if(!exceptedRoutes.includes(path)){
                    return (
                      <Route exact path={path} key={key} render={() => ( <Redirect to="/signup" /> )} />
                    )
                  }
                  
                })}
                {_.map(publicRoutes, (route, key) => {
                  const { component, path } = route;
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={route =>
                        { 
                          return (
                            <PublicLayout component={component} />
                          );
                        }
                      }
                    />
                  )
                })}
              <Route component={Page404} />
              </Switch>
            </Fragment>
          }
          { !isLoggedIn && currentUser.is_system_admin == 1 &&
            <Fragment>
              <Switch>
                {_.map(adminRoutes, (route, key) => {
                  const { path } = route;
                  return (
                    <Route exact path={path} key={key} render={() => ( <Redirect to="/signin"/> )} />
                  )
                })}
                {_.map(publicRoutes, (route, key) => {
                  const { component, path } = route;
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={route =>
                        { 
                          return (
                            <PublicLayout component={component} />
                          );
                        }
                      }
                    />
                  )
                })}
              <Route component={Page404} />
              </Switch>
            </Fragment>
          }
      </Suspense>
    </ErrorBoundary>
  );
}

export default withRouter(Main)