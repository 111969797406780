import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import { observationService } from '@iso/services';
import {  IconObservation  } from '@iso/containers/Pages/PropertyMap/Icons/IconObservation';
import _ from 'lodash';
import modalActions from '@iso/redux/modal/actions';
import PreviewImageModal from '@iso/components/PreviewImageModal/PreviewImageModal';

const Observation = (props) => {
  const [observations, setObservations] = useState({});
  const objectRef = React.useRef([]);
  const [isVisiblePreviewImageModal, setIsVisiblePreviewImageModal] = useState(false)
  const [fileType, setFileType] = useState()
  const [fileUrl, setFileUrl] = useState()

  const updateDataObservations = React.useCallback((propertyId) => {
    observationService.getList(propertyId).then(res => {
      if(res.code === '0000'){
        setObservations(res.observations);
      }
    })
  }, [props.primaryObjects]);

  useEffect(() => {
    if(props.propertyId){
      updateDataObservations(props.propertyId);
    }
  }, [updateDataObservations, props.propertyId]);

  const handleShowObservations = (data) => {
    var html = [];
    if(data.length > 0){
      _.forEach(data, function(observation){
        html.push(
          <Marker 
            key={observation.id} 
            position={[observation.lat, observation.lng]}
            icon={IconObservation()}
            ref={el => (objectRef.current[observation.id] = el)}      
            onClick={handleClickObservation.bind(this, observation)}      
          />
        );
      });
    }
    return html;
  }

  const handleClickObservation = (observation, e) => {
    //Not trigger anything if on deleted state
    if(observation.images && observation.images.length > 0){
      setIsVisiblePreviewImageModal(true)
      let media = observation.images[observation.images.length - 1];
      setFileType(media.file_type === 'video' ? 'video' : 'image')
      setFileUrl(media.full_path)
    }
  }

  return (
    <>
      {handleShowObservations(observations)}
      <PreviewImageModal
        isVisibleModal={isVisiblePreviewImageModal}
        fileType={fileType}
        url={fileUrl}
        cancelModal={()=>{setIsVisiblePreviewImageModal(false)}}
      />
    </>
  )
}

export default Observation;