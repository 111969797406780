import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const AreaSummaryModalWrapper = styled(Modal)`
.manage-table .react-collapsible tbody .title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
.manage-property-header {
  padding: 20px 24px;
  display: flex;
  align-items: center;
  margin: auto;
}
.manage-table .react-collapsible tbody .name {
font-family: "Inter";
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #101828;
  .btn-success{
    background-color: #0B2A1B;
    border-color: #0B2A1B;
    &:hover{
      opacity: 0.8;
    }
  }
  .ant-modal-body{
    padding: 0;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  .btn-create-crop{
    background-color: #46AFCA;
    border-color: #46AFCA;
    &:hover{
      opacity: 0.8;
    }
    margin-top:30px;
    width: 100%;
    padding: 0;
  }

  .btn-create-feeding-type{
    height : 38px !important;
    background-color: #0B2A1B;
    border-color: #0B2A1B;
    &:hover{
      opacity: 0.8;
    }
    margin-bottom:10px;
    margin-top: 30px;
    float:right
  }
  .btn-summary-action{
    margin-bottom: 5px;
  }
`;

export default WithDirection(AreaSummaryModalWrapper);