import React, { useEffect, useLayoutEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import Box from "@iso/components/utility/box";
import PageHeader from "@iso/components/utility/pageHeader";
import ViewSurveyResultModal from "@iso/containers/Pages/Form/ViewSurveyResultModal";
import IntlMessages from "@iso/components/utility/intlMessages";
import { useSelector, useDispatch } from "react-redux";
import { configs } from "@iso/components/Tables/configs";
import { Button, Dropdown, Menu, Select, Pagination, Input } from "antd";
import VisitorPageWrapper from "./Visitor.style";
import {
  SearchOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import propertyActions from "@iso/redux/property/actions";
import _, { property } from "lodash";
import Table from "@iso/components/collapsing-table/table";
import VisitorModal from "./VisitorModal";
import { visitorService } from "../../../services/visitor.service";
import { propertyService } from "../../../services/property.service";
import VisitorViewSummary from "./VisitorViewSummary";
import UserAccessModal from "../Property/UserAccessModal";

const { changeActiveProperty } = propertyActions;

const Visitor = (props) => {
  const dispatch = useDispatch();
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );

  const activePropertySubscription = useSelector(
    (state) => state.property.activePropertySubscription
  );
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const [visibleVisitorModal, setVisibleVisitorModal] = React.useState(false);
  const [visibleVisitorFromResultModal, setVisibleVisitorFromResultModal] =
    React.useState(false);
  // const activeProperty=localStorage.getItem( "active_property" );
  const activeProperty = JSON.parse(localStorage.getItem("active_property"));
  const [property, setProperty] = React.useState({});
  const { Option } = Select;
  const [properties, setProperties] = React.useState([]);
  const [isSaved, setIsSaved] = React.useState(false);
  const [visitors, setVisitors] = React.useState([]);
  const [visitorData, setVisitorData] = React.useState({});
  const [visitorForm, setVisitorForm] = React.useState({});
  const [viewSummary, setViewSummary] = React.useState({});
  const [visibleModal, setvisibleModal] = React.useState(false);
  const [visibleViewSummaryForm, setVisibleViewSummaryForm] =
    React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [searchInput, setsearchInput] = React.useState("");
  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState();

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedVisitor = searchQuery?.slice(startIndex, endIndex);
  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    if (props?.handleVisitorData) {
      props?.handleVisitorData(visitors ? visitors?.length : 0);
    }
  }, [visitors]);

  const updateVisitorData = () => {
    if (activePropertyId) {
      setLoadingData(true);
      visitorService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setVisitors(res.visitors);
          setSearchQuery(res.visitors);

          setLoadingData(false);
        }
      });
    }
  };

  useEffect(() => {
    updateVisitorData();
  }, [activePropertyId]);

  useEffect(() => {
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        setVisiblitySelectProperty(true);
      } else {
        setProperties(res.properties);
        if (res.properties.length === 1) {
          localStorage.setItem(
            "active_property",
            JSON.stringify(res.properties[0])
          );
          dispatch(changeActiveProperty(res.properties[0]));
        }
      }
    });
  }, [activePropertyId, isSaved, activePropertySubscription]);

  const showModal = () => {
    setVisibleVisitorModal(true);
  };

  const cancelModal = () => {
    setVisibleVisitorModal(false);
    setVisitorData({});
  };

  const openEditModal = (visitor) => {
    setVisitorData(visitor);
    setVisibleVisitorModal(true);
  };

 

  const menuList = (property, permission) => (
    <Menu onClick={handleMenuClick.bind(this, property)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      {property.visitor_form &&
        ["self_create", "system_forms", "bio_form", "checkin_form"].includes(
          property?.visitor_form?.form?.type
        ) && (
          <Menu.Item key="viewResult" icon={<EyeOutlined />}>
            <IntlMessages id="visitorPage.table.button.viewFormResult" />
          </Menu.Item>
        )}
      <Menu.Item key="invite" icon={<EyeOutlined />}>
        <IntlMessages id="Invite" />
      </Menu.Item>

      <Menu.Item key="viewSummary" icon={<EyeOutlined />}>
        <IntlMessages id="View Summary" />
      </Menu.Item>
    </Menu>
  );

  const handleMenuClick = (property, e) => {
    if (e.key === "invite") {
      setvisibleModal(true);
      setViewSummary(property);
    }
    if (e.key === "viewSummary") {
      setViewSummary(property);
      setVisibleViewSummaryForm(true);
    }
    if (e.key === "viewResult") {
      openFormResult(property.visitor_form);
    }
    if (e.key === "editDetails") {
      openEditModal(property);
    }
  };
  const openFormResult = (visitorForm) => {
    setVisitorForm(visitorForm);
    setVisibleVisitorFromResultModal(true);
  }
  const cancelViewSurveyResult = () => {
    setVisitorForm({});
    setVisibleVisitorFromResultModal(false);
  };
  const columns = [
    {
      label: "Name",
      accessor: "name",
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: "Email",
      accessor: "email",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: "Mobile",
      accessor: "mobile_number",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: "Emergency Contact",
      accessor: "emergency_mobile_number",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: "Arrival",
      accessor: "created_at",
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
    },
    {
      label: "Departure",
      accessor: "Department",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
    },
    {
      label: "Induction in last 180 days",
      accessor: "Induction",
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7,
    },

    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let row = cell.row;
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(row)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handleOptionClick = (value) => {
    _.forEach(properties, (property) => {
      if (property.id == value) {
        localStorage.setItem("active_property", JSON.stringify(property));
        dispatch(changeActiveProperty(property));
      }
    });
  };

  const renderOptionsProperty = () => {
    let options = [];
    _.forEach(properties, (property, index) => {
      options.push(
        <Option key={index} value={property?.id} className="property-option">
          {property?.name}
        </Option>
      );
    });

    return (
      <Select
        placeholder="Select Active Property"
        value={activeProperty?.name}
        size="large"
        className="property-option"
        onChange={handleOptionClick}
      >
        {options}
      </Select>
    );
  };
  const handleCancelSearch = () => {
    setsearchInput("");
    setSearchQuery(visitors);
  };
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    if (searchTerm === "") {
      setSearchQuery(visitors); // Reset the search query when the search input is empty
    } else {
      const filteredVisitors = visitors.filter((visitor) => {
        return (
          (visitors.name && visitor.name.toLowerCase().includes(searchTerm)) ||
          (visitor.email && visitor.email.toLowerCase().includes(searchTerm)) ||
          (visitor.mobile_number &&
            visitor.mobile_number.toLowerCase().includes(searchTerm)) ||
          (visitor.emergency_mobile_number &&
            visitor.emergency_mobile_number
              .toLowerCase()
              .includes(searchTerm)) ||
          (visitor.created_at &&
            visitor.created_at.toLowerCase().includes(searchTerm)) ||
          (visitor.Department &&
            visitor.Department.toLowerCase().includes(searchTerm)) ||
          (visitor.Induction &&
            visitor.Induction.toLowerCase().includes(searchTerm))
        );
      });
      setSearchQuery(filteredVisitors);
      setCurrentPage(1); // Reset the current page to 1 when the search term changes
    }
    setsearchInput(e.target.value);
  };

  return (
    <LayoutWrapper>
      <VisitorPageWrapper>
        <Box id="containerBox">
          <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Visitors</h2>
            </div>
            <Input
                placeholder="Search…"
                suffix={
                  !searchInput && (
                    <SearchOutlined className="site-form-item-icon" />
                  )
                }
                prefix={
                  searchInput && (
                    <CloseCircleOutlined
                      onClick={handleCancelSearch}
                      className="site-form-item-cancel"
                    />
                  )
                }
                size="large"
                className="search-bar"
                value={searchInput}
                onChange={(e) => handleSearch(e)}
              />
        
          <div className="button-group">
          {renderOptionsProperty()}
              {(activePermission == "owner" || activePermission == "admin") && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  className="btn-addVisitor"
                  onClick={showModal}
                  
                >
                  <IntlMessages id="visitorPage.addVisitor" />
                </Button>
              )}
          </div>
          </div>
            {displayedVisitor && displayedVisitor.length > 0 ? (
                <>
                <div className="manage-table">
                  <Table
                    columns={columns}
                    rows={displayedVisitor}
                    containerId="containerBox"
                    showPagination={false}
                    rowSize={9999}
                  />
                  </div>
                  <div  style={{ padding: "15px" }}>
                    <Pagination
                      current={currentPage}
                      total={searchQuery.length}
                      pageSize={pageSize}
                      onChange={handlePageChange}
                      showSizeChanger
                      pageSizeOptions={["10", "20", "50", "100"]}
                      onShowSizeChange={handlePageSizeChange}
                    />
                  </div>
                </>
            ) : (
              <h1>
              <IntlMessages id="dashboardPage.noData" />
            </h1>
            )}
        
        </Box>
        <VisitorModal
          visibleVisitorModal={visibleVisitorModal}
          cancelModal={cancelModal}
          propertyId={activePropertyId}
          editedVisitor={visitorData}
          updateVisitorData={updateVisitorData}
        />
        <ViewSurveyResultModal
          visibleViewSurveyResult={visibleVisitorFromResultModal}
          cancelViewSurveyResult={cancelViewSurveyResult}
          surveyData={visitorForm}
          type={visitorForm?.form?.type}
        />
        <UserAccessModal
          visibleModal={visibleModal}
          setvisibleModal={setvisibleModal}
          viewSummary={viewSummary}
        />
        {visibleViewSummaryForm ? (
          <VisitorViewSummary
            viewSummary={viewSummary}
            visibleViewSummaryForm={visibleViewSummaryForm}
            setVisibleViewSummaryForm={setVisibleViewSummaryForm}
          />
        ) : (
          ""
        )}
      </VisitorPageWrapper>
    </LayoutWrapper>
  );
};

export default Visitor;
