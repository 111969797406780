import styled from "styled-components";
import bgImage from "@iso/assets/images/sar-bg.jpg";
import WithDirection from "@iso/lib/helpers/rtl";

const VerifyElapsedWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 140px - 1rem);
  height: calc(100vh - 140px - 1rem) ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${bgImage}) no-repeat center center;
  background-size: cover;

  .SAR-contain {
    border-radius: 5px;
    padding: 15px;
    background: aliceblue;
    p {
      font-size: 35px;
      font-weight: 600;
    }
    span {
      font-size: 25px;
      font-weight: 400;
    }
  }

  h1 {
    color: #ffffff;
  }
`;

export default WithDirection(VerifyElapsedWrapper);
