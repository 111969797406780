import React, { useEffect, useState } from "react";
import SAREditModalWrapper from "./SarEditModal.styles";
import PlaceAutocomplete from "./PlaceAutocomplete"
import IntlMessages from "@iso/components/utility/intlMessages";
import {
  Button,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Checkbox,
  Divider,
  Row,
  Col
} from "antd";
import { sarService } from "@iso/services";
import moment from "moment";
import TimezoneSelect from 'react-timezone-select'
import { safetyRadiusOptions } from '@iso/constants/safetyRadiusOptions';
import _ from 'lodash';

const { Option } = Select;

const SAREditModal = (props) => {

  const {
    openSarEditModal,
    setOpeneSarEditModal,
    sarId,
    positionDevice,
    getSARUsers,
    fetchAllSAR,
    methodTransportOptions
  } = props;

  const { TextArea } = Input;
  const CheckboxGroup = Checkbox.Group;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingSubmitForm, setLoadingSubmitForm] = useState(false);
  const [checkedContacts, setCheckedContacts] = useState([]);
  const [fieldSarEditForm,setFieldSarEditForm] = useState([]);
  const [geo, setGeo] = useState({lat: 0, lng: 0});
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  useEffect(() => {
    const fetchData = async () => {
      if (openSarEditModal && sarId) {
        setLoading(true);
        try {
          const res = await sarService.viewSar(sarId);
          if (res.code === "0000") {
            const sar = res.sar;
            const contactIds = sar.contacts.map(contact => contact.user_id);
            setFieldSarEditForm([
              {
                name: "SARName",
                value: sar.name,
              },
              {
                name: "SARDate",
                value: moment(sar.date_time),
              },
              {
                name: "SARTime",
                value: moment(sar.date_time),
              },
              {
                name: "UserName",
                value: sar.assigned_to,
              },
              {
                name: "IotDevice",
                value: sar.iot_device_id,
              },
              {
                name: "trip_comments",
                value: sar.trip_comments,
              },
              {
                name: "destination_location",
                value: sar.destination_location,
              },
              {
                name: "departure_location",
                value: sar.departure_location,
              },
              {
                name: "safety_radius",
                value: sar.safety_radius,
              },
              {
                name: "method_of_transport",
                value: sar.method_of_transport !== null && 
                  (!methodTransportOptions.some(option => option.value === sar.method_of_transport) || sar.method_of_transport === "other")
                  ? "other"
                  : sar.method_of_transport
              },
              {
                name: "method_of_transport_other",
                value: sar.method_of_transport,
              },
              {
                name: "contacts",
                value: contactIds,
              }
            ]);
            setCheckedContacts(contactIds);
            setSelectedTimezone(sar.timezone)
          }
        } catch (error) {
          console.error('Error fetching SAR Edit:', error);
        }finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  },[openSarEditModal,sarId]);

  const cancelModal = () => {
    setOpeneSarEditModal(false);
  }
  const handleSubmitFrom = async (e) => {
  
    try {
      setLoadingSubmitForm(true);
      const values = await form.validateFields();
  
      const dataFormatted = new Date(values.SARDate).toDateString();
      const timeFormatted = new Date(values.SARTime).toLocaleTimeString();
  
      const requestBody = {
        sar: {
          name: values.SARName,
          iot_device_id: values.IotDevice,
          assigned_to: values.UserName,
          contacts: values.contacts,
          departure_location: values.departure_location,
          destination_location: values.departure_location, // Is this correct? You're assigning departure_location to destination_location
          safety_radius: values.safety_radius,
          trip_comments: values.trip_comments,
          date_time: `${dataFormatted} ${timeFormatted}`,
          method_of_transport: values.method_of_transport === 'other' ? values.method_of_transport_other : values.method_of_transport,
          lat: geo.lat,
          lng: geo.lng,
          timezone: selectedTimezone.value || selectedTimezone
        },
      };

      const updateRes = await sarService.updateSAR(sarId, requestBody);
      if (updateRes.code !== "0000") {
        throw new Error('Error updating SAR');
      }
      
    } catch (error) {
      console.error('Error handling form submission Edit Sar:', error);
    } finally {
      await fetchAllSAR();
      setLoadingSubmitForm(false); // Ensure loading state is set to false after submission
    }
  };

  const checkAllContacts = (getSARUsers?.length ?? 0) === checkedContacts.length;

  const onContactsChange = (list) => {
    setCheckedContacts(list);
    form.setFieldsValue({ contacts: list })
  };

  const onCheckAllContacts = (e) => {
    setCheckedContacts(e.target.checked ? getSARUsers.map(user => user.value) : []);
    form.setFieldsValue({ contacts: e.target.checked ? getSARUsers.map(user => user.value) : [] })
  };

  const renderSafetyRadiusOptions = () => {
    let options = [];

    _.forEach(safetyRadiusOptions, (item, index) => {
      options.push(
        <Option key={index} value={item.value}>
            <div>{item.label}</div>
        </Option>
      );
    });

    return (
      <Select
        placeholder="Select a safety radius"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderForm = () => (
    <Form
      form={form}
      fields={fieldSarEditForm}
      name="SAR Edit"
      layout="vertical"
      scrollToFirstError
    >
      <div className="form-body">
        <Form.Item
          name="SARName"
          className="isoInputWrapper"
          label="SAR Name"
          rules={[
            {
              required: true,
              message: "Please input your SAR Name",
            },
            {
              max: 255,
              message: "SAR Name must be maximum 255 characters",
            },
          ]}
        >
          <Input
            className="pac-target-input"
            size="large"
            placeholder="Input your SAR Name"
          />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col xs={{span: 12}} md={{span: 12}}>
            <Form.Item
              name="UserName"
              className="isoInputWrapper"
              label="User Name"
              rules={[
                {
                  required: true,
                  message: "Please input your User Name",
                },
              ]}
            >
              <Select
                className="pac-target-input"
                size="large"
                allowClear
                options={getSARUsers}
              />
            </Form.Item>
          </Col>
          <Col xs={{span: 12}} md={{span: 12}}>
            <Form.Item
              name="IotDevice"
              className="isoInputWrapper"
              label="IoT device"
            >
              <Select
                className="pac-target-input"
                size="large"
                allowClear
                options={positionDevice}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="SARDate"
          className="isoInputWrapper"
          label="SAR Date"
          rules={[
            {
              required: true,
              message: "Please input your SAR Date",
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            className="pac-target-input"
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="SARTime"
          label="SAR Time"
          rules={[
            {
              required: true,
              message: "Please input your SAR Time",
            },
          ]}
        >
          <TimePicker
            className="pac-target-input"
            size="large"
            format="HH:mm"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <div class="ant-col ant-form-item-label">
          <label class="ant-form-item-required" title="SAR Timezone">SAR Timezone</label>
        </div>
        <TimezoneSelect
          value={selectedTimezone}
          onChange={setSelectedTimezone}
        />
        <br/>
        <Form.Item
          name="trip_comments"
          label="Trip Comments"
        >
          <TextArea
            className="isoInputWrapper"
            size="large"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <PlaceAutocomplete form={form} fieldName="destination_location" label="Destination Location" callback={setGeo}/>
        <PlaceAutocomplete form={form} fieldName="departure_location" label="Departure Location" />
        <Form.Item
          name="safety_radius"
          className="isoInputWrapper"
          label="Safety Radius"
        >
        {renderSafetyRadiusOptions()}
        </Form.Item>
        <Form.Item
          name="method_of_transport"
          label="Method of Transport"
        >
        <Select
          allowClear
          options={methodTransportOptions}
        />                
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate
        >
          {({ getFieldValue }) => {
            const selectedValue = getFieldValue('method_of_transport');
            return selectedValue === 'other' ? (
              <Form.Item
                name="method_of_transport_other"
              >
                <Input
                  name="method_of_transport_other"
                  className="isoInputWrapper"
                  size="large"
                  placeholder="Other" 
                />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>
        <Form.Item
          name="contacts"
          label="Contacts"
        > 
          <Checkbox
            style={{
              width: '100%',
            }}
            onChange={onCheckAllContacts} 
            checked={checkAllContacts}> 
            Check all 
          </Checkbox>
          <Divider />
          <CheckboxGroup
            options={getSARUsers}
            value={checkedContacts} 
            onChange={onContactsChange} 
            className="checkboxContacts"
          />
        </Form.Item>
      </div>
      <div className="footer-btn">
        <Button
          key="back"
          onClick={cancelModal}
          className="cancel-button"
        >
          {<IntlMessages id="topbar.cancel" />}
        </Button>

        <Button
          key="submit"
          className="btn-success"
          type="primary"
          loading={loadingSubmitForm}
          onClick={handleSubmitFrom}
        >
          {<IntlMessages id="topbar.save" />}
        </Button>
      </div>
    </Form>
  );

  return (
    <>
      <SAREditModalWrapper
        forceRender={true}
        visible={openSarEditModal}
        title="Sar View"
        onCancel={cancelModal}
        maskClosable={false}
        footer={[]}
      >
       {loading ? "Loading..." : renderForm()}
      </SAREditModalWrapper>
    </>
  )
};
export default SAREditModal;