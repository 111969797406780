import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

const PlaceAutocomplete = ({ form, fieldName, label, callback}) => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY_ADDRESS}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      handlePlaceSelect(fieldName);
    });

    return () => {
      document.body.removeChild(script);
    };
  }, [fieldName]);

  const handlePlaceSelect = (fieldName) => {
    return () => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(`autocomplete-${fieldName}`)
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if(callback){
          callback({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          })
        }
        form.setFieldsValue({ [fieldName]: place.formatted_address });
      });
    };
  };

  return (
    <Form.Item label={label} name={fieldName}>
      <Input
        id={`autocomplete-${fieldName}`}
        placeholder={`Enter your ${label}`}
        onChange={handlePlaceSelect(fieldName)}
        size="large"
      />
    </Form.Item>
  );
};

export default PlaceAutocomplete;