import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import AssetModalWrapper from './AssetModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber, TimePicker } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { assetService, activityService } from '@iso/services';
import moment from 'moment';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';
import { assetCategories } from '@iso/constants/assetCategories';
import AddAssetHistoryModal from './AddAssetHistoryModal';
import AssetHistory from './AssetHistory';
import { weatherTypes } from '@iso/constants/weatherTypes';

const { Option } = Select;
const { closeModal } = modalActions;
const { TabPane } = Tabs;

const EditWeatherModal = (props) => {
  const [form] = Form.useForm();
  const [addHistoryForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [assetData, setAssetData] = useState({});
  const [assetHistory, setAssetHistory] = useState([]);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const activePermission = useSelector((state) => state.permission.activePermission);

  const updateDataAssetHistory = React.useCallback((asset_id, modal_type) => {
      activityService.getList(asset_id, modal_type).then(res => {
        if(res.code === '0000'){
          setAssetHistory(res.activities.reverse());
          //setLoading(false);
        }
      });
  }, [assetHistory]);
 
  useEffect(() => {
    setAssetData(props.dataAsset);
  }, [props.dataAsset]);

  const getRandomLatLng = (coordinates) => {
    var polygon = L.polygon([
      coordinates
    ]);
    var bounds = polygon.getBounds();
    var x_max = bounds.getEast();
    var x_min = bounds.getWest();
    var y_max = bounds.getSouth();
    var y_min = bounds.getNorth();
    var lat=0,lng = 0;
    var count = 0;
    var area = polygon.getLatLngs()[0].map(function(point){
      return [point.lat, point.lng]            
    });

    while(!inside([lat, lng], area)){
      lat = y_min + (Math.random() * (y_max - y_min));
      lng = x_min + (Math.random() * (x_max - x_min));
      count+=1;
      if(count==100){
        break;
      }      
    }            

    return new L.LatLng(
          lat,
          lng
    );
  }
  
  const cancelModal = () => {
    props.cancelEditModal();
    setLoading(false);
    form.resetFields();
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const selectedObject = _.find(props.primaryObjects, (object) => {
      return object.id === values.asset.primary_object_id;
    });
    
    if(!assetData.id || assetData.primary_object_id != selectedObject.id){
      var point = getRandomLatLng(selectedObject.area);
    }
    
    const data = {
      facility: {
        ...values.asset,
        weather_date: values.asset.weather_date ? dateHelper.dateForAPI(values.asset.weather_date) : '',
        weather_time: values.asset.weather_time ? dateHelper.getTime(values.asset.weather_time) : '',
        point,
        property_id: props.propertyId
      }
    }

    assetService.storeOrUpdate(data, assetData.id).then(res => {
      if(res.code === '0000'){
        props.onSaved(res.facility);
        cancelModal();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
      updateDataAssetHistory(assetData.id, 'facility');
      setFields([
        {
          name: ['asset', 'title'],
          value: assetData.title || '',
        },
        {
          name: ['asset', 'category'],
          value: assetData.category,
        },
        {
          name: ['asset', 'weather_type'],
          value: assetData.weather_type,
        },
        {
          name: ['asset', 'weather_date'],
          value: assetData.weather_date ? moment(assetData.weather_date) : '',
        },
        {
          name: ['asset', 'weather_time'],
          value: assetData.weather_time ? moment(assetData.weather_time, 'HH:mm:ss') : '',
        },
        {
          name: ['asset', 'weather_comment'],
          value: assetData.weather_comment
        },
        {
          name: ['asset', 'primary_object_id'],
          value: assetData.primary_object_id || '',
        },
      ]);
    
  }, [props.propertyId, props.visibleAssetWeather, assetData]);

  const renderOptionsAssetCategory = () => {
    let options = [];
    _.forEach(assetCategories, (cat, index) => {
      options.push(
        <Option key={index} value={cat.value}>
          <div>{cat.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category"
        allowClear
        size="large"
        disabled
      >
        {options}
      </Select>
    );
  }

 const renderOptionsWeatherTypes = () => {
    let options = [];
    _.forEach(weatherTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a weather type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const [activeTab,setActiveTab] = useState("1");
  const onChangeTab = (key) => {
    setActiveTab(key);
  }

  const addHistory = (object, e) => {
    setVisibleHistory(true);
  }

  const cancelModalHistory = () => {
    addHistoryForm.resetFields();
    setVisibleHistory(false);
    setLoadingHistory(false);
  }

  return(
    <>
    <AssetModalWrapper
      visible={props.visibleAssetWeather}
      onCancel={cancelModal}
      maskClosable={false}
      title={props.dataAsset.id ? <IntlMessages id="propertyPage.modal.asset.editAsset"/> : <IntlMessages id="propertyPage.modal.asset.addAsset"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
        modalLoading ?
        <Loader /> :
        <Tabs defaultActiveKey="1" className="form-body"
        onChange={onChangeTab}
        >
       
        <TabPane tab={<IntlMessages id="propertyPage.mobModal.detailTab" />} key="1"> 
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            fields={fields}
            id="#1"
          >
          <div>
            <Form.Item
              name={['asset', 'title']}
              className="isoInputWrapper"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
                {
                  max:255,
                  message: 'Title must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input asset title"/>
            </Form.Item>
            <Form.Item
              name={['asset', 'category']}
              className="isoInputWrapper"
              label="Category"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsAssetCategory()}
            </Form.Item>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'weather_type']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.weatherType" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionsWeatherTypes()}
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                  <Form.Item
                    name={['asset', 'primary_object_id']}
                    className="isoInputWrapper"
                    label={<IntlMessages id="propertyPage.modal.asset.paddock" />}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    {renderOptionObject(props.primaryObjects)}
            </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'weather_date']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.date" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DatePicker size="large" style={{width: '100%'}} />
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'weather_time']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="propertyPage.modal.asset.time" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <TimePicker size="large" style={{width: '100%'}} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name={['asset', 'weather_comment']}
              className="isoInputWrapper"
              label={<IntlMessages id="propertyPage.modal.asset.weatherComment" />}
            >
              <Input.TextArea />
            </Form.Item>
          </div>  
      </Form>
      </TabPane>
      <TabPane tab={<IntlMessages id="propertyPage.mobModal.historyTab" />} key="2">
        <Row>
          <Col xs={{span: 7}} md={{span: 7}}>
            {(activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") &&
            <Button key="submit" className="btn-create-breed" type="primary" onClick={addHistory}>
              {<IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />}
            </Button>}
          </Col>
          <Col xs={{span: 15}} md={{span: 17}}>
            
          </Col>
          
        </Row>
        <br/>
        <AssetHistory assetHistory={assetHistory} updateDataAssetHistory={updateDataAssetHistory} dataId={assetData.id}/>
        <AddAssetHistoryModal        
          visibleHistory={visibleHistory} 
          cancelModal={cancelModalHistory}
          primaryObjects={props.primaryObjects}
          model='facilities'
          dataId={assetData.id}
          propertyId={props.propertyId}
          updateDataAssetHistory={updateDataAssetHistory}
        />
      </TabPane>
      </Tabs>
      }     
    </AssetModalWrapper>
    </>
  )
}

export default EditWeatherModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;