import React, { useState, useEffect } from 'react';
import EditLiveStockModalWrapper from './EditLiveStockModal.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, Input, Button, Select, Row, Col, DatePicker, InputNumber, Tabs, Card, Timeline, Popconfirm, Checkbox, Upload } from 'antd';
import { CloseOutlined, UploadOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'lodash';
import modalActions from '@iso/redux/modal/actions';
import { useSelector, useDispatch } from 'react-redux';
import { mobService, breedService, livestockService, activityService, feedingTypeService, assetService,optiWeight } from '@iso/services';
import { livestockTypes } from '@iso/constants/livestockTypes';
import { livestockSex } from '@iso/constants/livestockSex';
import { activityCategories } from '@iso/constants/activityCategories';
import BreedModalWrapper from './BreedModal.styles';
import AddHistoryModalWrapper from './AddHistoryModal.styles';
import Loader from '@iso/components/utility/loader';
import { objectColors } from '@iso/constants/objectColors';
import { drugUnit } from '@iso/constants/drugUnit';
import { treatmentCategories } from '@iso/constants/treatmentCategories';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import styled from "styled-components";
import moment from 'moment';
import FeedingTypeModalWrapper from './FeedingTypeModal.styles';
import { pregTesting } from '@iso/constants/pregTesting';
import $ from 'jquery';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import EditHistoryModal from './EditHistoryModal';
import AddHistoryModal from '@iso/containers/Pages/PropertyMap/components/LiveStock/AddHistoryModal';
import FileUploader from "@iso/components/FileUploader/FileUploader";
import { FileStorage } from "@iso/constants/UploadFile";
import { propertyHelper } from "@iso/lib/helpers/propertyHelper";

const { openModal } = modalActions;
const { closeModal } = modalActions;
const { Option } = Select;
const { TabPane } = Tabs;

const EditLiveStockModal = (props) => {
  const visibleLiveStockModal = props.visible;
  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);
  const [form] = Form.useForm();
  const [breedForm] = Form.useForm();
  const [historyForm] = Form.useForm();
  const [addHistoryForm] = Form.useForm();
  const [feedingTypeForm] = Form.useForm();
  const [amountLiveStock, setAmountLiveStock] = useState(1);
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [mobs, setMobs] = useState([]);
  const [assets, setAssets] = useState([]);
  const [breeds, setBreeds] = useState({});
  const [visibleBreed, setVisibleBreed] = useState(false);
  const [loadingBreed, setLoadingBreed] = useState(false);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [dataLivestock, setDataLivestock] = useState({});
  const [liveStockActivities, setLiveStockActivities] = useState([]);
  const [feedingTypes, setFeedingTypes] = useState({});
  const [visibleFeedingType, setVisibleFeedingType] = useState(false);
  const [loadingFeedingType, setLoadingFeedingType] = useState(false);
  const [checkingUniqueID, setCheckingUniqueID] = useState(true);
  const [checkingTagNumber, setCheckingTagNumber] = useState(true);
  const [checkingNLIS, setCheckingNLIS] = useState(true);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [sireAssets, setSireAssets] = useState([]);
  const [AIChecked, setAIChecked] = useState(false);
  const [manualChecked, setManualChecked] = useState(false);
  const [imageId, setImageId] = useState(null);
  const [visibleEditHistoryModal, setVisibleEditHistoryModal] = useState(false);
  const [historyData, setHistoryData] = useState({});
  const [recordsOpti, setRecordsOpti] = useState({});

  useEffect(() => {
    setDataLivestock(props.liveStockData);

    setAIChecked(props.liveStockData.sire_asset_id ? true : false);
    setManualChecked((props.liveStockData.manual_sire || props.liveStockData.manual_mother) ? true : false);
  }, [props.liveStockData]);

  useEffect(() => {
    if(props.propertyId){
       breedService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setBreeds(res.breeds);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId]);

  useEffect(() => {
    if(props.propertyId){
       feedingTypeService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setFeedingTypes(res.feeding_types);
          setLoadingFeedingType(false);
        }
      });
    }
  }, [props.propertyId]);

  useEffect(() => {
    if(dataLivestock)
    {
      updateHistoryData(dataLivestock.id, 'asset');
    }
    
  }, [dataLivestock]);

  const updateHistoryData = (object_id, data_type) => {

    if(object_id){
       activityService.getList(object_id, data_type).then(res => {
        if(res.code === '0000'){
          setLiveStockActivities(res.activities.reverse());
          setLoading(false);
        }
      });
    }
  }

  useEffect(() => {
    if(props.propertyId){
       livestockService.getAllAssetByProperty(props.propertyId).then(res => {
        if(res.code === '0000'){
          setAssets(res.assets);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId, props.visibleEditLivestock]);

  useEffect(() => {
    if(props.propertyId){
       assetService.getAnimalSire(props.propertyId).then(res => {
        if(res.code === '0000'){
          setSireAssets(res.animal_sires);
          setLoading(false);
        }
      });
      optiWeightRecords(props.propertyId);
    }
  }, [props.propertyId, props.visibleEditLivestock]);
  const onKeyUp = (e) =>{
    let valueRFID = form.getFieldValue(['livestock', 'tag_rfid']);
    setFields([
      {
        name: ['livestock', 'weight'],
        value: '',
      },
    ]);
    if(recordsOpti.length > 0){
      _.forEach(recordsOpti, (value, index) => {
        if(valueRFID === value.eid){
          setFields([
            {
              name: ['livestock', 'weight'],
              value: value.avWt,
            },
          ]);
        }
      })
    }
  }
  const optiWeightRecords = (propertyId) =>{
    optiWeight.getData(propertyId).then(res => {
        if(res.code === '0000'){
          if(res?.opti_weight?.is_active && res?.opti_weight?.is_active === 1 && propertyHelper.checkIsGoldPackage(activePropertySubscription)){
            optiWeight.getDataOptiWeight(res.opti_weight.id).then(res =>{
              setRecordsOpti(res.records[0].records)
            });
          }
         
        }
    });
  }
  const addBreed = (object, e) => {
    setVisibleBreed(true);
  }

  const addHistory = (object, e) => {
    setVisibleHistory(true);
  }

  const addFeedingType = (object, e) => {
    setVisibleFeedingType(true);
  }

  const cancelModalBreed = () => {
    setVisibleBreed(false);
  }

  const cancelModalHistory = () => {
    addHistoryForm.resetFields();
    setVisibleHistory(false);
    setLoadingHistory(false);
  }

  const cancelModalFeedingType = () => {
    setVisibleFeedingType(false);
  }

  const onStoreBreed = (breed_values) => {
    const breed_data = {
      "breed":{
        ...breed_values.breed,
        property_id: props.propertyId
      }
    }

    breedService.storeOrUpdate(breed_data).then(res => {
      if(res.code === '0000'){
        var tmp_breeds = _.clone(breeds);
        tmp_breeds.push(res.breed);
        setBreeds(tmp_breeds);
        cancelModalBreed();
        breedForm.resetFields();
        setFields([
          {
            name: ['livestock', 'breed'],
            value: res.breed.slug,
          },
        ]);
      } else {
        setLoadingBreed(false);
      }
    });

  }

  const renderOptionsBreed = (mob_breed_options) => {
    let breed_options = [];
     _.forEach(mob_breed_options, (breed_opt, index) => {
      breed_options.push(
        <Option key={index} value={breed_opt.slug}>
            <div>{breed_opt.name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a breed"
        allowClear
        size="large"
        className="custom-select"
      >
        {breed_options}
      </Select>
    );
  }

  const renderOptionsFeedingType = (feeding_type_options) => {
    let feeding_options = [];
     _.forEach(feeding_type_options, (feeding_type, index) => {
      feeding_options.push(
        <Option key={index} value={feeding_type.name}>
            <div>{feeding_type.name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a feeding type"
        allowClear
        size="large"
      >
        {feeding_options}
      </Select>
    );
  }

  const handleBreedSubmit = (e) => {
    breedForm
      .validateFields()
      .then(breed_values => {
        onStoreBreed(breed_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingBreed(false);
      });
  }

  const handleFeedingTypeSubmit = (e) => {
    feedingTypeForm
      .validateFields()
      .then(feeding_type_values => {
        onStoreFeedingType(feeding_type_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingFeedingType(false);
      });
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
    setLoading(false);
  }

  const handleHistorySubmit = (e) => {
    addHistoryForm
      .validateFields()
      .then(history_values => {
        onStoreHistory(history_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingHistory(false);
      });
  }

  const onStoreFeedingType = (feeding_type_values) => {
    const feeding_type_data = {
      "feeding_type":{
        ...feeding_type_values.feeding_type,
        property_id: props.propertyId
      }
    }

    feedingTypeService.storeOrUpdate(feeding_type_data).then(res => {
      if(res.code === '0000'){
        var tmp_feeding_types = _.clone(feedingTypes);
        tmp_feeding_types.push(res.feeding_type);
        setFeedingTypes(tmp_feeding_types);
        cancelModalFeedingType();
        feedingTypeForm.resetFields();
        setFields([
          {
            name: ['history', 'feeding_type'],
            value: res.feeding_type.name,
          },
        ]);
      } else {
        setLoadingFeedingType(false);
      }
    });

  }

  const onStore = async (values) => {
    let color = '';
    objectColors.map((value, index) => {
      if(value.value == values.livestock.tag_colour){ color = value.label}
    });
    const unique_id = {
        asset_info: {
          uid: values.livestock.uid,
          asset_id: dataLivestock.id,
          tag_number: values.livestock.tag_number_range,
          NLIS:values.livestock.NLIS,
          tag_colour: values.livestock.tag_colour
        }
    }
    var res = await livestockService.checkUniqueID(unique_id);
    if(res.code !== '0000'){
      if(res.identification === true){
        setCheckingUniqueID(false);
      }else{
        setCheckingUniqueID(true);
      }

      if(res.tag_number === true){
        setCheckingTagNumber(false);
      }else{
        setCheckingTagNumber(true);
      }

      if(res.NLIS === true){
        setCheckingNLIS(false);
      }else{
        setCheckingNLIS(true);
      }
     
      setLoading(false);
      return false;
    }else{
      const data = {
        asset: {
          ...values.livestock,
          date_of_birth: values.livestock.date_of_birth ? dateHelper.dateForAPI(values.livestock.date_of_birth) : '',
          displayed_name: color +  ' | ' + values.livestock.tag_number_range + ' | ' + values.livestock.uid,
          property_id: props.propertyId,
          image_id: imageId,
        }
      }
      livestockService.storeOrUpdate(data, dataLivestock.id).then(res => {
        if(res.code === '0000'){
          props.onSaved(res.asset);
          cancelModal();
          setCheckingUniqueID(true);
          setCheckingTagNumber(true);
          setCheckingNLIS(true);
          props.updateLiveStockData();
        } else {
          setLoading(false);
        }
      });
    }
  }

  const onStoreHistory = (history_values) => {
    const history_data = {
      "history":{
        metadata: history_values.history,
        action:'create',
        model:'assets',
        object_id: dataLivestock.id,
        property_id: props.propertyId
      }
    }
    
    activityService.storeOrUpdate(history_data).then(res => {
      if(res.code === '0000'){
        var tmp_liveStockActivities = _.clone(liveStockActivities);
        tmp_liveStockActivities.unshift(res.history);
        setLiveStockActivities(tmp_liveStockActivities);
        cancelModalHistory();
        addHistoryForm.resetFields();
        if(res.history.metadata.weighing){
          var dataLivestock_clone = _.clone(dataLivestock);
          dataLivestock_clone.weight = res.history.metadata.weighing.weight;
          setDataLivestock(dataLivestock_clone);

        }
      } else {
        setLoadingHistory(false);
      }
    });

  }

  const renderOptionsColor = () => {
    let options = [];
    _.forEach(objectColors, (color, index) => {
      options.push(
        <Option key={index} value={color.value}>
          <ColorItemRow>
            <ColorItemPreview style={{backgroundColor: color.color}}></ColorItemPreview>
            <div>{color.label}</div>
          </ColorItemRow>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a color"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }
  useEffect(() => {
      setFields([
        {
          name: ['livestock', 'mob_id'],
          value: dataLivestock.mob_id,
        },
        {
          name: ['livestock', 'type'],
          value: dataLivestock.type,
        },
        {
          name: ['livestock', 'breed'],
          value: dataLivestock.breed,
        },
        {
          name: ['livestock', 'date_of_birth'],
          value: dataLivestock.date_of_birth ? moment(dataLivestock.date_of_birth) : '',
        },
        {
          name: ['livestock', 'tag_colour'],
          value: dataLivestock.tag_colour,
        },
        {
          name: ['livestock', 'tag_number_range'],
          value: dataLivestock.tag_number_range !== 'N/A' ? dataLivestock.tag_number_range : '',
        },
        {
          name: ['livestock', 'weight'],
          value: dataLivestock.weight,
        },
        {
          name: ['livestock', 'sex'],
          value: dataLivestock.sex,
        },
        {
          name: ['livestock', 'siren_id'],
          value: dataLivestock.siren_id,
        },
        {
          name: ['livestock', 'sire_asset_id'],
          value: dataLivestock.sire_asset_id,
        },
        {
          name: ['livestock', 'mother_id'],
          value: dataLivestock.mother_id,
        },
        {
          name: ['livestock', 'NLIS'],
          value: dataLivestock.NLIS !== 'N/A' ? dataLivestock.NLIS : '',
        },
        {
          name: ['livestock', 'tag_rfid'],
          value: dataLivestock.tag_rfid,
        },
        {
          name: ['livestock', 'uid'],
          value: dataLivestock.uid,
        },
        {
          name: ['livestock', 'unique_features'],
          value: dataLivestock.unique_features,
        },
        {
          name: ['livestock', 'description'],
          value: dataLivestock.description,
        },
        {
          name: ['livestock', 'unique_features'],
          value: dataLivestock.unique_features,
        },
        {
          name: ['livestock', 'ai'],
          value: dataLivestock.sire_asset_id ? true : false
        },
        {
          name: ['livestock', 'manual'],
          value: (dataLivestock.manual_sire || dataLivestock.manual_mother) ? true : false
        },
        {
          name: ['livestock', 'manual_sire'],
          value: dataLivestock.manual_sire,
        },
        {
          name: ['livestock', 'manual_mother'],
          value: dataLivestock.manual_mother,
        },
        {
          name: ['livestock', 'activity_category'],
          value: 'weight',
        },
        {
          name: ['history', 'treatment_unit'],
          value: 'mls',
        },
        {
          name:  ['history', 'activity_category'],
          value: null,
        },
      ]);
    
  }, [dataLivestock, liveStockActivities, props.visibleEditLivestock]);

  const renderLiveStockRows = () => {
    let rows = [];
    _.forEach(_.range(0, amountLiveStock), (key) => {
      rows.push(<Row key={key}>
        <Col xs={{span: 24}} md={{span: 12}} >
          <Form.Item
            name={['task', 'due_date']}
            className="isoInputWrapper"
            label="Name"
          >
            <Input size="large" placeholder="Name"/>
          </Form.Item>
        </Col>
      </Row>)
    })

    return rows;
  }

  useEffect(() => {
    if(props.propertyId){
       mobService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setMobs(res.mobs);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId]);

  const renderOptionMob = (mob_data) => {
    let options = [];
    _.forEach(mob_data, (mob, index) => {
      options.push(
        <Option key={mob.id} value={mob.id}>
          {mob.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a mob"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionMaleAsset = (asset_data) => {
    let options = [];
    
    _.forEach(asset_data, (asset, index) => {
      if(asset.sex && asset.id !== dataLivestock.id && asset.displayed_name){
        options.push(
          <Option key={asset.id} value={asset.id} label={asset.displayed_name}>
           {objectColors.map((value, index) => {
            if(value.value == asset.tag_colour){
              return (
                <ColorItemRow>
                  <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                  <div>{asset.displayed_name}</div>
                </ColorItemRow>
                )
            }
          })}
          </Option>
        );
      }
    })
    return (
      <Select
        placeholder="Select a livestock"
        allowClear
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionFemaleAsset = (asset_data) => {
    let options = [];
    
    _.forEach(asset_data, (asset, index) => {
      if(asset.sex && asset.id !== dataLivestock.id && asset.displayed_name){
        options.push(
          <Option key={asset.id} value={asset.id} label={asset.displayed_name}>
            {objectColors.map((value, index) => {
            if(value.value == asset.tag_colour){
              return (
                <ColorItemRow>
                  <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                  <div>{asset.displayed_name}</div>
                </ColorItemRow>
                )
            }
          })}
          </Option>
        );
      }
    })
    return (
      <Select
        placeholder="Select a livestock"
        allowClear
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionSireAsset = (sire_assets) => {
    let options = [];
    _.forEach(sire_assets, (asset, index) => {
      if(asset.tag_colour && asset.tag_number){
        options.push(
          <Option key={asset.id} value={asset.id}>
            {objectColors.map((value, index) => {
              if(value.value == asset.tag_colour){
                return (
                  <ColorItemRow>
                    <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                    <div>{value.label + ' | ' + asset.tag_number}</div>
                  </ColorItemRow>
                  )
              }
            })}
          </Option>
        );
      }else{
        options.push(
          <Option key={asset.id} value={asset.id}>
            <div>{asset.animal_name}</div>
          </Option>
        );
      }
    })
    return (
      <Select
        placeholder="Select a livestock"
        allowClear
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionAssetForHistory = (asset_data) => {
    let options = [];
    
    _.forEach(asset_data, (asset, index) => {
      if(asset.sex && asset.id !== dataLivestock.id && asset.displayed_name){
        options.push(
          <Option key={asset.id} value={asset.displayed_name} label={asset.displayed_name}>
            {objectColors.map((value, index) => {
            if(value.value == asset.tag_colour){
              return (
                <ColorItemRow>
                  <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                  <div>{asset.displayed_name}</div>
                </ColorItemRow>
                )
            }
          })}
          </Option>
        );
      }
    })
    return (
      <Select
        placeholder="Select a livestock"
        allowClear
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionsLivestockType = () => {
    let livestock_type_options = [];
     _.forEach(livestockTypes, (type, index) => {
      livestock_type_options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a type"
        allowClear
        size="large"
      >
        {livestock_type_options}
      </Select>
    );
  }

  const renderOptionsLivestockSex = () => {
    let livestock_sex_options = [];
     _.forEach(livestockSex, (sex, index) => {
      livestock_sex_options.push(
        <Option key={index} value={sex.value}>
            <div>{sex.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select sex"
        allowClear
        size="large"
      >
        {livestock_sex_options}
      </Select>
    );
  }

  const renderOptionsActivityCategories = () => {
    let activity_options = [];
     _.forEach(activityCategories, (activity, index) => {
      activity_options.push(
        <Option key={index} value={activity.value}>
            <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select type of history"
        allowClear
        size="large"
        listHeight="300"
      >
        {activity_options}
      </Select>
    );
  }

  const renderOptionsDrugUnit = () => {
    let unit_options = [];
     _.forEach(drugUnit, (unit, index) => {
      unit_options.push(
        <Option key={index} value={unit.value}>
            <div>{unit.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select unit"
        allowClear
        size="large"
      >
        {unit_options}
      </Select>
    );
  }

  const renderOptionsTreatmentCategories = () => {
    let treatment_options = [];
     _.forEach(treatmentCategories, (treatment, index) => {
      treatment_options.push(
        <Option key={index} value={treatment.value}>
            <div>{treatment.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select treatment"
        allowClear
        size="large"
      >
        {treatment_options}
      </Select>
    );
  }

  const renderOptionsPregTesting = () => {
    let preg_options = [];
     _.forEach(pregTesting, (preg, index) => {
      preg_options.push(
        <Option key={index} value={preg.value}>
            <div>{preg.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select preg testing"
        allowClear
        size="large"
      >
        {preg_options}
      </Select>
    );
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.name}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderDeleteHistoryButton = (history) => {
    return (
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(history);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
        <a style={{color:'red', marginRight: '20px', float:'right'}}>
          {<CloseOutlined />}
        </a>
        </Popconfirm>
      )
  }

  const renderEditHistoryButton = (history) => {
    return (
      (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") &&
      <a style={{color:'#0B2A1B', marginRight: '20px', float:'right'}} onClick={() => showEditHistoryModal(history)}>
        {<EditOutlined />}
      </a>
    )
  }

  const showEditHistoryModal = (history_data) => {
    setHistoryData(history_data);
    setVisibleEditHistoryModal(true);
  }

  const cancelEditHistoryModal = () => {
    setHistoryData({});
    setVisibleEditHistoryModal(false);
  }

  const confirmDelete = (del_history) => {
    if(del_history.id){
       activityService.destroy(del_history.id).then(res => {
        if(res.code === '0000'){
          updateHistoryData(dataLivestock.id, 'asset');
        }
      });
    }

  }


  const cancelModal = () => {
    props.cancelModal();
    setActiveTab("1");
    setCheckingUniqueID(true);
    setCheckingTagNumber(true);
    setCheckingNLIS(true);
    form.resetFields();
  }

  const [activeTab,setActiveTab] = useState("1");
  const onChangeTab = (key) => {
    setActiveTab(key);
  }

  const onChangeCheckBox = e => {
    setAIChecked(e.target.checked);
  }

  const handleOnUploadSuccess = (image) => {
    setImageId(image.id);
  }

    const updateLiveStockHistory = (history) => {
        var tmp_liveStockActivities = _.clone(liveStockActivities);
        tmp_liveStockActivities.unshift(history);
        setLiveStockActivities(tmp_liveStockActivities);
    }

    const afterLivestockArchived = () => {
        cancelModal();

        if (props.updateLiveStockData) {
          props.updateLiveStockData();
        }
    }

    const onChangeManualCheckBox = e => {
      setManualChecked(e.target.checked);
    }

  return (
    <>
     <EditLiveStockModalWrapper
      visible={props.visibleEditLivestock}
      onCancel={cancelModal}
      centered
      maskClosable={false}
      title={ (!props.viewHistory) ? <IntlMessages id="liveStockPage.managePage.editLiveStock"/> : <IntlMessages id="liveStockPage.table.viewHistory"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (!props.viewHistory) ? (
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>
        ) : '',
      ]}
     >
     <Tabs defaultActiveKey="1" className="form-body"
        onChange={onChangeTab}
        activeKey={(!props.viewHistory) ? activeTab : '2'}

       >
       { (!props.viewHistory) ? (
        <TabPane tab={<IntlMessages id="propertyPage.mobModal.detailTab" />} key="1">
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          <div>
            <Form.Item
                    name={['livestock', 'mob_id']}
                            className="isoInputWrapper"
                            label="Mob"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                    
                  {renderOptionMob(mobs)}
                </Form.Item>
                        <Row>
                          <Col xs={{span: 15}} md={{span: 17}}>
                            <Form.Item
                              name={['livestock', 'breed']}
                              className="isoInputWrapper"
                              label="Breed of animal"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                            {renderOptionsBreed(breeds)}
                            </Form.Item>
                          </Col>
                          <Col xs={{span: 7, offset: 2}} md={{span: 5, offset: 2}}>
                            <Button key="submit" className="btn-create-breed" type="primary" onClick={addBreed}>
                              {<IntlMessages id="propertyPage.modal.breed.addBreed" />}
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['livestock', 'date_of_birth']}
                            className="isoInputWrapper"
                            label="Date Of Birth"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                             <DatePicker size="large" style={{width: '100%'}}/>
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['livestock', 'type']}
                            className="isoInputWrapper"
                            label="Type of animal"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Input type of animal"/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['livestock', 'tag_colour']}
                            className="isoInputWrapper"
                            label="Tag Colour"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderOptionsColor()}
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['livestock', 'tag_number_range']}
                            className="isoInputWrapper"
                            label="Tag Number"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Input tag number"/>
                          </Form.Item>
                        </Col>
                         { (!checkingTagNumber) ? <div className="ant-form-item-has-error">
                          <div className="ant-form-item-explain">
                          <div>Tag Colour and Tag Number are already in use!</div>
                          </div></div> : ''}
                      </Row>
                      <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['livestock', 'weight']}
                            className="isoInputWrapper"
                            label="Weight (kg)"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <InputNumber size="large" min={1} max={1000} />
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['livestock', 'sex']}
                            className="isoInputWrapper"
                            label="Sex"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}

                          >
                            {renderOptionsLivestockSex()}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{span: 2}} md={{span: 2}}>
                          <Form.Item
                            name={['livestock', 'ai']}
                            className="isoInputWrapper"
                            valuePropName="checked"
                            label="AI"
                          >
                            <Checkbox onChange={onChangeCheckBox}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 3}} md={{span: 3}}>
                          <Form.Item
                            name={['livestock', 'manual']}
                            className="isoInputWrapper"
                            valuePropName="checked"
                            label="Manual"
                          >
                            <Checkbox onChange={onChangeManualCheckBox}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 17, offset: 2}} md={{span: 17, offset: 2}}>
                          { manualChecked ? <Form.Item
                                name={['livestock', 'manual_sire']}
                                className="isoInputWrapper"
                                label="Sire"
                              >
                                <Input size="large" placeholder="Input sire"/>
                              </Form.Item> : (AIChecked ? <Form.Item
                              name={['livestock', 'sire_asset_id']}
                              className="isoInputWrapper"
                              label="Sire"
                            >
                             {renderOptionSireAsset(sireAssets)}
                            </Form.Item> : 
                            <Form.Item
                              name={['livestock', 'siren_id']}
                              className="isoInputWrapper"
                              label="Sire"
                            >
                             {renderOptionMaleAsset(assets)}
                            </Form.Item>)
                          }
                        </Col>
                      </Row>
                      { manualChecked ? <Form.Item
                            name={['livestock', 'manual_mother']}
                            className="isoInputWrapper"
                            label="Mother"
                          >
                           <Input size="large" placeholder="Input mother"/>
                          </Form.Item> : <Form.Item
                          name={['livestock', 'mother_id']}
                          className="isoInputWrapper"
                          label="Mother"
                        >
                         {renderOptionFemaleAsset(assets)}
                        </Form.Item>
                      }
                      <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['livestock', 'NLIS']}
                            className="isoInputWrapper"
                            label="NLIS ID NUMBER"
                          >
                           <Input size="large" placeholder="Input NLIS ID"/>
                          </Form.Item>
                          { (!checkingNLIS) ? <div className="ant-form-item-has-error">
                          <div className="ant-form-item-explain">
                          <div>NLIS is already in use!</div>
                          </div></div> : ''}
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['livestock', 'tag_rfid']}
                            className="isoInputWrapper"
                            label="TAG RFID"
                          >
                           <Input size="large" placeholder="Input TAG RFID" onKeyUp={onKeyUp}/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        name={['livestock', 'uid']}
                        className="isoInputWrapper"
                        label="System Gen ID"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Input size="large" placeholder=""/>
                      </Form.Item>
                      { (!checkingUniqueID) ? <div className="ant-form-item-has-error">
                      <div className="ant-form-item-explain">
                      <div>System Gen ID is already in use!</div>
                      </div></div> : ''}
                       <Form.Item
                        name={['livestock', 'unique_features']}
                        className="isoInputWrapper"
                        label="Unique features"
                      >
                        <Input.TextArea />
                      </Form.Item>
                       <Form.Item
                        name={['livestock', 'description']}
                        className="isoInputWrapper"
                        label="Description"
                      >
                        <Input.TextArea />
                      </Form.Item>
                      {propertyHelper.checkIsGoldPackage(activePropertySubscription) && 
                        <><Form.Item name={['attachment']} label="Attachment">
                          <FileUploader
                            type='livestocks'
                            storage={FileStorage.filesystem}
                            isCompressFile={true}
                            handleOnSuccess={handleOnUploadSuccess}
                          />
                        </Form.Item>
                        {
                          dataLivestock.images && dataLivestock.images.length > 0 && <img style={{width:"50%"}}src={dataLivestock.images[dataLivestock.images.length-1].full_path} />
                        }
                        </>
                      }
          </div>  
        </Form>
        </TabPane>
        ) : ''}
        <TabPane tab={<IntlMessages id="propertyPage.mobModal.historyTab" />} key="2">
          <Form
            form={historyForm}
            layout="vertical"
            scrollToFirstError
            fields={fields}
          >
            <div>
              {(!props.viewHistory) ? (
                <Row>
                  <Col xs={{span: 7}} md={{span: 7}}>
                    {(activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") &&<Button key="submit" className="btn-create-breed" type="primary" onClick={addHistory}>
                      {<IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />}
                    </Button>}
                  </Col>
                  <Col xs={{span: 15}} md={{span: 17}}>
                    
                  </Col>
                  
                </Row>
              ) : ''}
              <br/>
              <div style={{ overflow: "scroll", width: "100%", height: "600px" }}>
                <br/>
                <Timeline>
                  {(liveStockActivities) ? (liveStockActivities.map((value, index) => {
                      if(value.metadata.value && value.metadata.value.weight){
                         return  (
                                  // <Timeline.Item color="green" key={index}>
                                  <>
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Weight" bordered={true}>
                                      <p className="card_content">Weight</p> 
                                      <b className="card_value">{value.metadata.value.weight} kg</b>
                                    </Card></>
                                  // </Timeline.Item>
                                  )

                      }

                       if(value.metadata.moving){
                         return (
                        //  <Timeline.Item color="green" key={index}>
                          <>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Moving" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Historical Area</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.moving.source}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">New Area</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.moving.destination}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Duration in Area</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.moving.duration}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  {/* </Timeline.Item> */}
                                  </>
                                  )
                      }

                      if(value.metadata.treatment){
                        return (
                        // <Timeline.Item color="green" key={index}>
                        <>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Treatment" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Treatment name</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.treatment.treatment_name}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Quantity</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.treatment.treatment_quantity}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Unit</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.treatment.treatment_unit}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">WHP</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.treatment.treatment_WHP}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">ESI</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.treatment.treatment_ESI}</b>
                                        </Col>
                                      </Row>
                          
                                    </Card></>
                                  // </Timeline.Item>
                                  )
                      }

                      if(value.metadata.feeding){
                        return (
                          <>
                        {/* <Timeline.Item color="green" key={index}> */}
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Feeding" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Feed Type</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.feeding.feeding_type}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Quantity</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.feeding.feeding_quantity}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  {/* </Timeline.Item> */}
                                  </>
                                  )
                      }

                      if(value.metadata.joining){
                        return (
                          <>
                        {/* <Timeline.Item color="green" key={index}> */}
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Joining" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Sire</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.joining.joining_siren}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Expected Duration</p> 
                                        </Col>
                                          
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                        <b className="card_value">{value.metadata.joining.joining_expected_duration}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  {/* </Timeline.Item> */}
                                  </>
                                  )
                      }

                      if(value.metadata.marking){
                        return (
                          <>
                        {/* <Timeline.Item color="green" key={index}> */}
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Marking" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">System Date</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{moment(value.metadata.marking.marking_system_date).format('ll')}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  {/* </Timeline.Item> */}
                                  </>
                                  )
                      }

                      if(value.metadata.weighing){
                        return (
                          <>
                        {/* <Timeline.Item color="green" key={index}> */}
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Weighing" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Weight</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.weighing.weight} kg</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  {/* </Timeline.Item> */}
                                  </>
                                  )
                      }

                      if(value.metadata.preg_testing){
                        return (
                          <>
                        {/* <Timeline.Item color="green" key={index}> */}
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Preg Testing" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Preg Testing</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.preg_testing.preg_testing}</b>
                                        </Col>   
                                      </Row>
                                      {value.metadata.preg_testing.justation &&
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Gestation</p> 
                                        </Col>
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.preg_testing.justation}</b>
                                        </Col>
                                      </Row>
                                      }
                                    </Card>
                                  {/* </Timeline.Item> */}
                                  </>
                                  )
                      }

                      if(value.metadata.crutching){
                        return (
                          <>
                        {/* <Timeline.Item color="green" key={index}> */}
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Crutching" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">System Date</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{moment(value.metadata.crutching.crutching_system_date).format('ll')}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  {/* </Timeline.Item> */}
                                  </>
                                  )
                      }

                      if(value.metadata.death){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Death" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">{value.metadata.death.cause_of_death ? 'Cause of death' : 'Number Of Animals'}</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.death.cause_of_death ? value.metadata.death.cause_of_death : value.metadata.death.number_of_animals}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">{value.metadata.death.cause_of_death ? 'Location' : 'Description'}</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.death.death_location ? value.metadata.death.death_location : value.metadata.death.description}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.sale){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Sale" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Sale Price</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.sale.sale_price}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Weight</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.sale.weight}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Description</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.sale.description}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }

                      if(value.metadata.other){
                        return (<Timeline.Item color="green" key={index}>
                                    {renderDeleteHistoryButton(value)}
                                    {renderEditHistoryButton(value)}
                                    <p>{moment(value.created_at).format('ll')}</p>
                                    <Card title="Other" bordered={true}>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Title</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.other.other_title}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Comment</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.other.other_comment}</b>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={{span: 11}} md={{span: 11}}>
                                          <p className="card_content">Location</p> 
                                        </Col>
                                         
                                        <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                                         <b className="card_value">{value.metadata.other.other_location}</b>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Timeline.Item>)
                      }


                      return false
                      
                  })) : ''}
                </Timeline>
              </div>
              <EditHistoryModal visibleEditHistoryModal={visibleEditHistoryModal} cancelEditHistoryModal={cancelEditHistoryModal} primaryObjects = {props.primaryObjects}
                historyData={historyData} updateDataHistory={updateHistoryData} propertyId={props.propertyId} dataType="asset" model="assets"
              />
            </div>
          </Form>
        </TabPane>
        </Tabs>  
     </EditLiveStockModalWrapper>
     <BreedModalWrapper
      visible={visibleBreed}
      onCancel={cancelModalBreed}
      centered
      title={ <IntlMessages id="propertyPage.modal.breed.addBreed.title"/> }
      footer={[        
        <Button key="back" onClick={cancelModalBreed} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleBreedSubmit} loading={loadingBreed}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
    
          <Form
            form={breedForm}
            layout="vertical"
            scrollToFirstError
          >
          <div className="form-body">
            <Form.Item
              name={['breed', 'name']}
              className="isoInputWrapper"
              label="Breed"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input size="large" placeholder="Input breed"/>
            </Form.Item>
            </div>    
      </Form>
    }
    </BreedModalWrapper>

    <AddHistoryModal        
        visibleHistory={visibleHistory} 
        liveStockData={dataLivestock} 
        primaryObjects={props.primaryObjects} 
        cancelModal={cancelModalHistory} 
        propertyId={props.propertyId}
        updateHistory={updateLiveStockHistory}
        afterArchived={afterLivestockArchived}
    />

    {/*<AddHistoryModalWrapper
      visible={visibleHistory}
      onCancel={cancelModalHistory}
      centered
      maskClosable={false}
      title={ <IntlMessages id="propertyPage.mobModal.historyTab.addHistory"/> }
      footer={[        
        <Button key="back" onClick={cancelModalHistory} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleHistorySubmit} loading={loadingHistory}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
    
          <Form
            form={addHistoryForm}
            layout="vertical"
            scrollToFirstError
            fields={fields}
          >
          <div className="form-body">
            <Form.Item
                  name={['history', 'activity_category']}
                  className="isoInputWrapper"
                  label="Type of History"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                {renderOptionsActivityCategories()}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues['history'];
              }}
            >
            {({ getFieldValue }) => {
              if(getFieldValue('history')){
                if (getFieldValue('history').activity_category === "treatment"){
                  return (<div><Form.Item
                                name={['history', 'treatment_name']}
                                className="isoInputWrapper"
                                label="Treatment Name"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input size="large" placeholder="Input treatment name"/>
                          </Form.Item>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['history', 'treatment_quantity']}
                                className="isoInputWrapper"
                                label="Quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={1000} />
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['history', 'treatment_unit']}
                                className="isoInputWrapper"
                                label="Unit"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              {renderOptionsDrugUnit()}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['history', 'treatment_WHP']}
                                className="isoInputWrapper"
                                label="WHP (Number in days)"
                              >
                              <InputNumber size="large" min={1} max={1000} />
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['history', 'treatment_ESI']}
                                className="isoInputWrapper"
                                label="ESI (Number in days)"
                              >
                              <InputNumber size="large" min={1} max={1000} />
                              </Form.Item>
                            </Col>
                          </Row>
                          </div>)
                }else if(getFieldValue('history').activity_category === "feeding"){
                  return (<div>
                            <Row>
                              <Col xs={{span: 12}} md={{span: 16}}>
                                <Form.Item
                                  name={['history', 'feeding_type']}
                                  className="isoInputWrapper"
                                  label="Feeding Type"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                {renderOptionsFeedingType(feedingTypes)}
                                </Form.Item>
                              </Col>
                              <Col xs={{span: 10, offset: 2}} md={{span: 6, offset: 2}}>
                                <Button key="submit" className="btn-create-feeding-type" type="primary" onClick={addFeedingType}>
                                  {<IntlMessages id="propertyPage.modal.feedingType.addFeedingType.title" />}
                                </Button>
                              </Col>
                            </Row>
                            <Form.Item
                                name={['history', 'feeding_quantity']}
                                className="isoInputWrapper"
                                label="Quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={1000} />
                            </Form.Item>
                            <FeedingTypeModalWrapper
                              visible={visibleFeedingType}
                              onCancel={cancelModalFeedingType}
                              centered
                              maskClosable={false}
                              title={ <IntlMessages id="propertyPage.modal.feedingType.addFeedingType.title"/> }
                              footer={[        
                                <Button key="back" onClick={cancelModalFeedingType} className="cancel-button">
                                  {<IntlMessages id="propertyPage.modal.cancel" />}
                                </Button>,
                                <Button key="submit" className="btn-success" type="primary" onClick={handleFeedingTypeSubmit} loading={loadingFeedingType}>
                                  {<IntlMessages id="propertyPage.modal.save" />}
                                </Button>,
                              ]}
                            >
                            {
                            
                                  <Form
                                    form={feedingTypeForm}
                                    layout="vertical"
                                    scrollToFirstError
                                  >
                                  <div className="form-body">
                                    <Form.Item
                                      name={['feeding_type', 'name']}
                                      className="isoInputWrapper"
                                      label="Feeding Type"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'This field is required',
                                        },
                                      ]}
                                    >
                                      <Input size="large" placeholder="Input feeding type"/>
                                    </Form.Item>
                                    </div>    
                              </Form>
                            }
                            </FeedingTypeModalWrapper>
                          </div>
                          )
                }else if(getFieldValue('history').activity_category === "joining"){
                  return (<div>   
                            <Form.Item
                              name={['history', 'joining_siren']}
                              className="isoInputWrapper"
                              label="Sire"
                            >
                             {renderOptionAssetForHistory(assets)}
                            </Form.Item>
                        
                            <Form.Item
                              name={['history', 'joining_expected_duration']}
                              className="isoInputWrapper"
                              label="Expected Duration (Number of weeks)"
                              rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                              ]}
                            >
                            <InputNumber size="large" min={1} max={1000} />
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "marking"){
                  return (<div>
                            <Form.Item
                                name={['history', 'marking_system_date']}
                                className="isoInputWrapper"
                                label="System Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                             <DatePicker size="large" style={{width: '100%'}}/>
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "weighing"){
                  return (<div>
                            <Form.Item
                              name={['history', 'weight']}
                              className="isoInputWrapper"
                              label="Weight (kg)"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                            <InputNumber size="large" min={1} max={1000} />
                          </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "preg_testing"){
                  return (<div>
                            <Form.Item
                                name={['history', 'preg_testing']}
                                className="isoInputWrapper"
                                label="Preg Testing"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              {renderOptionsPregTesting()}
                            </Form.Item>
                          </div>)
                }else if (getFieldValue('history').activity_category === "crutching"){
                  return (<div>
                            <Form.Item
                                name={['history', 'crutching_system_date']}
                                className="isoInputWrapper"
                                label="System Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                             <DatePicker size="large" style={{width: '100%'}}/>
                            </Form.Item>
                          </div>)
                }else if (getFieldValue('history').activity_category === "death"){
                  return (<div>
                            <Form.Item
                                name={['history', 'cause_of_death']}
                                className="isoInputWrapper"
                                label="Cause of death"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input.TextArea />
                            </Form.Item>
                            <Form.Item
                                name={['history', 'death_location']}
                                className="isoInputWrapper"
                                label="Location"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                             {renderOptionObject(props.primaryObjects)}
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "other"){
                  return (<div>
                            <Form.Item
                                name={['history', 'other_title']}
                                className="isoInputWrapper"
                                label="Title"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input size="large" placeholder="Input title"/>
                            </Form.Item>
                            <Form.Item
                                name={['history', 'other_comment']}
                                className="isoInputWrapper"
                                label="Comment"
                              >
                              <Input.TextArea />
                            </Form.Item>
                            <Form.Item
                                name={['history', 'other_location']}
                                className="isoInputWrapper"
                                label="Location"
                              >
                             {renderOptionObject(props.primaryObjects)}
                            </Form.Item>
                          </div>)
                }else {}
              }
            }}
            </Form.Item>
          </div>    
      </Form>
    }
    </AddHistoryModalWrapper>*/}
    </>
  )
}


export default EditLiveStockModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;