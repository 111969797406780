import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const WeatherFormWrapper = styled.div`
height : 550px;
  .ant-modal-body{
   
    padding: 0;
  }
 
  .btn-success{
    background-color: #0B2A1B;
    border-color: #0B2A1B;    
  }
  .form-title{
    padding: 16px 0;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 0;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }

  .ant-select-selector {
    border-radius: 8px!important;
  }

  .ant-col {
    padding: 0!important;
  }
`;

export default WithDirection(WeatherFormWrapper);