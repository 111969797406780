import React , { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { propertyService, googleService } from '@iso/services';
import IntlMessages from '@iso/components/utility/intlMessages';
import PropertyModalWrapper from './PropertyModal.styles';
import { Checkbox, Radio } from 'antd';
import _ from 'lodash';
import Alert from '@iso/components/Feedback/Alert';
import { loadScript } from '@iso/lib/helpers/googleApiHelper';
import { acreageUnit } from '@iso/constants/acreageUnit';

let autoComplete;
const { Option } = Select;
function handleAddressAutoComplete(
  setAddress,
  autoCompleteRef,
  setAddressShortName,
  setPoint
) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef?.current?.input,
    { types: ["address"] }
  );

  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () => {
    handlePlaceSelect(setAddress, setAddressShortName, setPoint);
  });
}

async function handlePlaceSelect(setAddress, setAddressShortName, setPoint) {
  const addressObject = autoComplete.getPlace();
  
  const fields = [
    {
      name: 'address',
      value: addressObject.formatted_address,
    },
  ]
  for (var i = 0; i < addressObject.address_components.length; i++) {
    var addressType = addressObject.address_components[i].types[0];
    if (addressType === "country") {
      fields.push({
        name: 'country',
        value: addressObject.address_components[i].long_name
      });
    }
    if (addressType === "administrative_area_level_1"){
      fields.push({
        name: 'state',
        value: addressObject.address_components[i].long_name
      });
    }
  }
  setAddress(fields);
}

const PropertyModal = (props) => {
  const dispatch = useDispatch();
  const autoCompleteRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [contactChecked, setContactChecked] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (autoCompleteRef) {
      if (!document.getElementById("google-api-script")) {
        document.addEventListener("google-script-loaded", handleAddress);
      } else {
        document.getElementById("google-api-script").remove();
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY_ADDRESS}&libraries=places`
        );
        document.addEventListener("google-script-loaded", handleAddress);
      }
    }
  }, []);

  useEffect(() => {
    setFields([
      {
        name: 'address',
        value: props.editProperty.address,
      },
      {
        name: 'country',
        value: props.editProperty.country,
      },
      {
        name: 'state',
        value: props.editProperty.state,
      },
      {
        name: 'name',
        value: props.editProperty.name,
      },
      {
        name: 'contact_name',
        value: props.editProperty.contact_name,
      },
      {
        name: 'contact_email',
        value: props.editProperty.contact_email,
      },
      {
        name: 'contact_phone',
        value: props.editProperty.contact_phone,
      },
      {
        name: 'contact_website',
        value: props.editProperty.contact_website,
      },
      {
        name: 'PIC',
        value: props.editProperty.PIC,
      },
      {
        name: 'acreage_unit',
        value: props.editProperty.acreage_unit,
      },
      {
        name: 'is_public',
        value: props.editProperty.is_public,
      },
      {
        name: 'use_my_account',
        value: currentUser.email === props.editProperty.contact_email,
      },
    ]);

    if(Object.keys(props.editProperty).length > 0){
      if(currentUser.email === props.editProperty.contact_email){
        setContactChecked(true);
      } else {
        setContactChecked(false);
      }
    }
  }, [props, currentUser]);

  const handleAddress = () => {
    handleAddressAutoComplete(setFields, autoCompleteRef)
  }

  const cancelModal = () => {
    props.cancelModal();
    resetForm();
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        const res = await googleService.getLatLng(values.address);
        var point = {};
        var addressShortName = {};
        var addressLongName = {};
        if(res.results.length > 0 && res.results[res.results.length - 1].geometry){
          point = {
            lat: res.results[res.results.length - 1].geometry.location.lat,
            lng: res.results[res.results.length - 1].geometry.location.lng,
          };

          const addressComponents = res.results[res.results.length - 1].address_components;
          const indexCountry = _.findIndex(addressComponents, function(address) { return address.types[0] === 'country'; });
          const indexState = _.findIndex(addressComponents, function(address) { return address.types[0] === 'administrative_area_level_1'; });
          addressShortName = {
            country: addressComponents[indexCountry].short_name || '',
            state: addressComponents[indexState].short_name || '',
          }

          addressLongName = {
            country: addressComponents[indexCountry].long_name || '',
            state: addressComponents[indexState].long_name || '',
          }
        } else {
          setErrMsg("The address invalid");
        }

        const data = {
          property: Object.assign(values, { point }, addressLongName ),
          addressShortName: addressShortName
        }
        data['property'].address = res.results[res.results.length - 1].formatted_address;
        if(Object.keys(addressShortName).length !== 0){
          onStore(data);
        } else {
          setLoading(false);
          setTimeout(() => {
            setErrMsg("");
          }, 3000);
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (data) => {
    propertyService.storeOrUpdateProperties(data, props.editProperty.id).then(res => {
      if(res.code === '0000'){
        props.onSaved();
        props.cancelModal();
        resetForm();
      }
    });
  }

  const resetForm = () => {
    setTimeout(() => {
      form.resetFields();
      handleAddress();
      setLoading(false);
      setContactChecked(false);
      setErrMsg("");
    }, 500);
  }

  const onChangeCheckBox = e => {
    setContactChecked(e.target.checked);
  }

  const renderOptionsAcreageUnit = () => {
    let options = [];
    _.forEach(acreageUnit, (unit, index) => {
      options.push(
        <Option key={index} value={unit.value}>
            <div>{unit.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a unit"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  return (
    <PropertyModalWrapper
      forceRender={true}
      visible={props.visible}
      title={<IntlMessages id="propertyPage.modal.propertyDetail" />}
      onCancel={cancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" loading={loading} onClick={handleSubmit}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="addProperty"
        layout="vertical"
        scrollToFirstError
        initialValues={{
          use_my_account: false,
        }}
        fields={fields}
      >
        {
          errMsg &&
          <Alert
            message={errMsg}
            type="error"
            style={{margin: '15px 15px 0 15px'}}
          />
        }
        <div className="form-body">
          <Form.Item
            name="name"
            className="isoInputWrapper"
            label="Property Name"
            rules={[
              {
                required: true,
                message: 'Please input your property name',

              },
              {
                max:255,
                message: 'Property Name must be maximum 255 characters.'
              },
              {
                whitespace:true,
                message: 'Please input your name',
              },
            ]}
          >
            <Input size="large" placeholder="Input your property name"/>
          </Form.Item>
          <Form.Item
            name="address"
            className="isoInputWrapper"
            label="Address"
            rules={[
              {
                required: true,
                message: 'Please input your address',
              },
              {
                max:255,
                message: 'Address must be maximum 255 characters'
              },
            ]}
          >
            <Input className="pac-target-input" size="large" placeholder="Input your address" ref={autoCompleteRef}/>
          </Form.Item>
          <Row>
            <Col xs={{span: 24}} md={{span: 11}}>
              <Form.Item
                name="country"
                className="isoInputWrapper"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: 'Please input your country',
                  },
                ]}
              >
                <Input size="large" placeholder="Input your country"/>
              </Form.Item>
            </Col>
            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
              <Form.Item
                name="state"
                className="isoInputWrapper"
                label="State"
                rules={[
                  {
                    required: true,
                    message: 'Please input your state',
                  },
                ]}
              >
                <Input size="large" placeholder="Input your state"/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="PIC"
            className="isoInputWrapper"
            label="PIC"
            rules={[
              {
                max:255,
                message: 'PIC must be maximum 255 characters'
              },
            ]}
          >
             <Input size="large" placeholder="Input your PIC"/>
          </Form.Item>
          <Form.Item
            name="acreage_unit"
            className="isoInputWrapper"
            label="Acreage Unit"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
          {renderOptionsAcreageUnit()}
          </Form.Item>
        </div>
        <div className="form-title">
          <h3>{<IntlMessages id="propertyPage.modal.contactInfomation" />}</h3>
        </div>
        <div className="form-body">
          <Form.Item
            name="use_my_account"
            className="isoInputWrapper"
            valuePropName="checked"
          >
            <Checkbox className="checkbox-contact" onChange={onChangeCheckBox}>Use my account</Checkbox>
          </Form.Item>
          {
            !contactChecked && (
              <div>
                <Row>
                  <Col xs={{span: 24}} md={{span: 11}}>
                    <Form.Item
                      name="contact_name"
                      className="isoInputWrapper"
                      label="Contact Name"
                    >
                      <Input size="large" placeholder="Input your contact name" disabled={contactChecked}/>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                    <Form.Item
                      name="contact_email"
                      className="isoInputWrapper"
                      label="Contact Email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Input your contact email" disabled={contactChecked}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 24}} md={{span: 11}}>
                    <Form.Item
                      name="contact_phone"
                      className="isoInputWrapper"
                      label="Contact Phone"
                    >
                      <Input size="large" placeholder="Input your contact phone" disabled={contactChecked}/>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                    <Form.Item
                      name="contact_website"
                      className="isoInputWrapper"
                      label="Contact Website"
                    >
                      <Input size="large" placeholder="Input your contact website" disabled={contactChecked}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="is_public"
                  className="isoInputWrapper"
                  label="Shared on airagri community"
                >
                  <Radio.Group disabled={contactChecked}>
                    <Radio value={1}>Public</Radio>
                    <Radio value={0}>Private</Radio>
                  </Radio.Group>
                </Form.Item>
                
              </div>
            )
          }
        </div>
      </Form>
    </PropertyModalWrapper>
  );
}

export default PropertyModal;