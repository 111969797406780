export const hazardTypes = [
  {
    label: 'Safety',
    value: 'safety_hazards',
  },
  {
    label: 'Biological',
    value: 'biological_hazards',
  },
  {
    label: 'Physical',
    value: 'physical_hazards',
  },
  {
    label: 'Ergonomic',
    value: 'ergonomic_hazards',
  },
  {
    label: 'Chemical',
    value: 'chemical_hazards',
  },
  {
    label: 'Work organization',
    value: 'work_organization_hazards',
  },
  {
    label: 'Environmental',
    value: 'environmental_hazards',
  },
  {
    label: 'Other',
    value: 'other',
  }
]