export const formTypes = [
  {
    label: 'PDF Upload',
    value: 'pdf_upload',
  },
  {
    label: 'Self Create',
    value: 'self_create',
  },
  {
    label: 'System Forms',
    value: 'system_forms',
  },
  {
    label: 'Livestock Biosecurity Form',
    value: 'bio_form',
  },
  {
    label: 'Checkin Form',
    value: 'checkin_form',
  },
  {
    label: 'Hazard Form',
    value: 'hazard_form',
  },
  {
    label: 'Training Form',
    value: 'training_form',
  },
  {
    label: 'Injury / Illness Form',
    value: 'illness_form',
  },
  {
    label: 'Near Miss Form',
    value: 'near_miss_form',
  },
]