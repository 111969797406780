import React, { useState, useEffect } from 'react';
import LiveStockPage from './LiveStock.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import SimpleTable from '@iso/components/Tables/SimpleTable';
import fakeData from '@iso/components/Tables/data';
import { configs } from '@iso/components/Tables/configs';
import { Button, Dropdown, Menu, Popconfirm, Select, Checkbox } from 'antd';
import { PlusOutlined, DownOutlined, EditOutlined, DeleteOutlined,PlusCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import { livestockService, propertyService } from '@iso/services';
import EditLiveStockModal from './EditLiveStockModal';
import _ from 'lodash';
import moment from 'moment';
import { objectColors } from '@iso/constants/objectColors';
import { livestockSex } from '@iso/constants/livestockSex';
import styled from "styled-components";
import AddHistoryModal from '@iso/containers/Pages/PropertyMap/components/Mob/AddHistoryModal';
import NewLiveStockModal from '@iso/containers/Pages/PropertyMap/components/LiveStock/NewLiveStockModal';
import Table from '@iso/components/collapsing-table/table';
import Box from '@iso/components/utility/box';

const { openModal } = modalActions;

const LiveStock = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [livestocks, setLivestocks] = useState({});
  const [liveStockData, setLiveStockData] = useState({});
  const dispatch = useDispatch();
  const mobId = props.mobId;
  const [visibleEditLivestock, setVisibleEditLivestock] = React.useState(false);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [visibleAddLivestock, setVisibleAddLivestock] = React.useState(false);

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then(res => {
      if(res.code === '0000'){
        setPrimaryObjects(res.primary_objects);
      }
    })
  }, []);

  useEffect(() => {
    if(activePropertyId){
      updatePropertyDetail(activePropertyId);
    }    
  }, [activePropertyId, updatePropertyDetail]);

  useEffect(() => {
    updateLiveStockData();
  }, [mobId]);

  const updateLiveStockData = () => {
    if(mobId){
       livestockService.getByMob(mobId).then(res => {
        if(res.code === '0000'){
          setLivestocks(res.assets);
        }
      });
    }
  }

  const updateAssetTotal = () => {
    props.updateMobData();
  }

  const menu = (livestock) => (
    <Menu onClick={handleMenuClick.bind(this, livestock)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      {(activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Menu.Item key="addHistory" icon={<PlusCircleOutlined />}>
        <IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />
      </Menu.Item>}
      {(activePermission == "owner" || activePermission == "admin") && <p style={{cursor:'pointer'}} className="deleteButton">
        <Popconfirm
                placement="bottomRight"
                title="You are about to delete individual record"
                onConfirm={() => {
                  confirmDelete(livestock);
                }}
                okText="Yes"
                cancelText="No"
                className="delete-button"
        >
        <a style={{color:'rgba(0, 0, 0, 0.65)', marginLeft: '11px'}}>
          {<DeleteOutlined />}&nbsp;&nbsp;
          <IntlMessages id="propertyPage.managePage.delete" />
        </a>
        </Popconfirm>
      </p>}
    </Menu>
  );
  const handleMenuClick = (livestock, e) => {
    if(e.key === 'editDetails'){
     setLiveStockData(livestock);
     showModal();
    }

    if(e.key === 'addHistory'){
      setLiveStockData(livestock);
      showAddHistoryModal();
    }
  }

  const showAddHistoryModal = () => {
    setVisibleHistory(true);
  }

  const cancelModalHistory = () => {
    setVisibleHistory(false);
  }

  const showModal = () => {
    setVisibleEditLivestock(true);
  }

  const cancelModal = () => {
    setVisibleEditLivestock(false);
    //setEditProperty({});
  }

  const onSaved = (savedLivestock) => {
    let liveStockTmp = _.clone(livestocks);
    const liveStockIndex = _.findIndex(liveStockTmp, (livestock) => {
      return livestock.id === savedLivestock.id
    });
    if(liveStockIndex === -1){
      liveStockTmp.push(savedLivestock);
    } else {
      liveStockTmp[liveStockIndex] = savedLivestock;
    }
    setLivestocks(liveStockTmp);
  }

  const confirmDelete = (del_livestock) => {
    if(del_livestock.id){
       livestockService.destroy(del_livestock.id).then(res => {
        if(res.code === '0000'){
          updateLiveStockData();
        }
      });
    }

  }

  const showAddLivestockModal = () => {
    setVisibleAddLivestock(true);
  }

  const cancelAddLivestockModal = () => {
    setVisibleAddLivestock(false);
  }

  const columns = [
    
    {
      label: <IntlMessages id="liveStockPage.table.tag_number_range" />,
      accessor: 'tag_number_range',
      minWidth: 0,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="liveStockPage.table.NLIS" />,
      accessor: 'NLIS',
      minWidth: 0,
      sortable: true,
      position: 2,
      priorityLevel: 2
    },
    {
      label: <IntlMessages id="liveStockPage.table.uid" />,
      accessor: 'uid',
      minWidth: 100000000,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    
    {
      label: <IntlMessages id="liveStockPage.table.tag_colour" />,
      accessor: 'tag_colour',
      minWidth: 400,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let livestock = cell.row
        return objectColors.map((value, index) => {
          if(value.value == livestock.tag_colour){
            return (
              <div style={{display:'inline-flex'}}>
                <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                &nbsp;&nbsp;
                <div>{value.label}</div>
              </div>
              )
          }
        });
      }
    },
    {
      label: <IntlMessages id="liveStockPage.table.date_of_birth" />,
      accessor: 'date_of_birth',
      minWidth: 400,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      CustomComponent: cell => {
        let livestock = cell.row
        return (livestock.date_of_birth) ? moment(livestock.date_of_birth).format('DD/MM/YYYY') : (livestock.month_of_birth) && (livestock.month_of_birth+'/'+livestock.year_of_birth);
      }
    },
    {
      label: <IntlMessages id="liveStockPage.table.sex" />,
      accessor: 'sex_name',
      minWidth: 400,
      sortable: true,
      position: 6,
      priorityLevel: 6,
    },
    {
      label: <IntlMessages id="liveStockPage.table.siren" />,
      accessor: 'siren',
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7
    },
    {
      label: <IntlMessages id="liveStockPage.table.mother" />,
      accessor: 'mother',
      minWidth: 100,
      sortable: true,
      position: 8,
      priorityLevel: 8
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 100,
      sortable: true,
      position: 9,
      priorityLevel: 9,
      noTitle: true,
      CustomComponent: cell => {
        let livestock = cell.row
        return (
            <Dropdown overlay={menu(livestock)} trigger={['click']}>
              <Button className="action-button">
                <IntlMessages id="antTable.title.actions" /> <DownOutlined />
              </Button>
            </Dropdown>
        );
      },
    },
    {
      label: 'Checkbox',
      accessor: '',
      minWidth: 0,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let livestock = cell.row
        return (
          <Checkbox 
          value={livestock.id} 
          onChange={onCheckBoxSplit} 
          checked={
            props.valueCheckboxMob?.lastIndexOf(livestock.id) >= 0 ? true : false
          }
          ></Checkbox>
        );
      },
    },
  ];

  const onCheckBoxSplit = (e) => {
    if (e.target.checked === true) {
      props.setValueCheckboxMob([...props.valueCheckboxMob, e.target.value]);
    } else {
      const selected = props.valueCheckboxMob.filter(a => {
        if (a === e.target.value) return false;
        return true;
      });
      props.setValueCheckboxMob([...selected]);
    }
       
  };

  return (
    <LiveStockPage>
      <div className="button-group">
        {(activePermission == "owner" || activePermission == "admin") && <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={showAddLivestockModal}>
          <IntlMessages id="liveStockPage.managePage.addLiveStock" />
        </Button>}
      </div>
          {
            livestocks.length > 0 &&
          //<SimpleTable columns={columns} dataList={livestocks ? new fakeData(livestocks.length, livestocks) : {} } pagination={{pageSize: 50}} />
          <Table 
            columns={columns} 
            rows={livestocks}
            containerId="containerBox"
            showPagination={false}
            rowSize={99999}
          />            
          }
      <EditLiveStockModal 
        onSaved={onSaved} 
        updateLiveStockData={updateLiveStockData} 
        liveStockData={liveStockData} 
        propertyId={activePropertyId} 
        visibleEditLivestock={visibleEditLivestock}
        cancelModal={cancelModal} 
        primaryObjects={primaryObjects}

      />

      <NewLiveStockModal propertyId={activePropertyId} 
      onSaved={onSaved} updateLiveStockData={updateLiveStockData} 
      updatePropertyDetail={updatePropertyDetail}
      visibleAddLivestock={visibleAddLivestock}
      cancelAddLivestockModal={cancelAddLivestockModal}
      from='mob-modal'
      mobId={mobId}
      updateAssetTotal={updateAssetTotal}
      />

      <AddHistoryModal        
          visibleHistory={visibleHistory} 
          updateDataMobHistory={updateLiveStockData} 
          model="asset" type="asset" 
          dataId={liveStockData.id} 
          primaryObjects={primaryObjects} 
          cancelModal={cancelModalHistory} 
          propertyId={activePropertyId} 
      />
    </LiveStockPage>
  )
}

export default LiveStock;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;