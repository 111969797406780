import React from 'react';

const ManageAssets = () => {
  return (
	<div className="sidebar-icon">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" 	className="sidebar-icons" xmlns="http://www.w3.org/2000/svg">
<path d="M2 22H22" stroke="#8F9497" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.94995 22L2.99995 9.96999C2.99995 9.35999 3.28995 8.78004 3.76995 8.40004L10.77 2.95003C11.49 2.39003 12.5 2.39003 13.23 2.95003L20.23 8.39003C20.72 8.77003 21 9.34999 21 9.96999V22" stroke="#8F9497" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
<path d="M15.5 11H8.5C7.67 11 7 11.67 7 12.5V22H17V12.5C17 11.67 16.33 11 15.5 11Z" stroke="#8F9497" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 16.25V17.75" stroke="#8F9497" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 7.5H13.5" stroke="#8F9497" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>
  );
}
export default ManageAssets;