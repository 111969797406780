import React, { useState } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import FormModalWrapper from "./FormModal.styles";
import {Button } from "antd";
import { Form as FormComponent } from "@iso/containers/Pages/Form/Form";

const FormModal = (props) => {

  const cancelModal = () => {
    props.cancelFormModal();
  };

  return (
    <>
      <FormModalWrapper
        width={1200}
        forceRender={true}
        visible={props.isShowFormModal}
        title={`Forms`}
        onCancel={cancelModal}
        footer={[
          <Button key="back" onClick={cancelModal} className="cancel-button">
            {<IntlMessages id="propertyPage.modal.cancel" />}
          </Button>,
        ]}
      >
        <FormComponent isShowFormModal={props.isShowFormModal}></FormComponent>
      </FormModalWrapper>
    </>
  );
};

export default FormModal;
