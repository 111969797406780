import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";

export const deviceSensorService = {
  getList,
  destroy,
  getDetail,
};

function getList(params = {}){
  return (
    axios
      .get(
        apiEndpoint.deviceSensors,
        {
          params: params,
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getDetail(id,params = {}){
  return (
    axios
      .get(
        apiEndpointFunction.deviceSensors(id),
        {
          params: params,
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.deviceSensors(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}
