import React, { useEffect, useState, useCallback } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Input, Row, Col, InputNumber, Form, Checkbox } from 'antd';
import EditSensorNameModalWrapper from './EditSensorNameModal.styles';
import { sensorTypeSettingService } from '../../../services/sensorTypeSetting.service';

const EditSensorNameModal = (props) => {
    const { 
        sensor, 
        visible, 
        onEditSensorNameSaved, 
        onCancel,
        typeModalSensor, 
        sensorType,
        statsSensor,
        usersProperty
    } = props;
    const [form] = Form.useForm();
    const [userSettings, setUserSettings] = useState([]);
    const [typeSetting, setTypeSetting] = useState({});

    useEffect(() => {
        if (visible && sensor) {
            initializeForm();
        }
    }, [visible, sensor, statsSensor]);

    useEffect(() => {
        form.setFieldsValue({ users: userSettings });
    }, [userSettings]);

    const initializeForm = () => {
        form.setFieldsValue({
            name: sensor.name || '',
            sensor_id: sensor.id || '',
            type: sensorType || '',
            max_threshold: statsSensor ? Math.round(statsSensor.max) : 0,
            min_threshold: statsSensor ? Math.round(statsSensor.min) : 0,
        });
        getSensorTypeSetting(sensor.id);
    };

    const getSensorTypeSetting = (sensorId) => {
        sensorTypeSettingService.getList(sensorId).then(res => {
            if (res.code === '0000') {
                const typeSetting = res.sensor_type_settings.find(setting => setting.type === sensorType);
                setTypeSetting(typeSetting);
                const initialUserSettings = typeSetting && typeSetting.user_settings.map(userSetting => ({
                    id: userSetting.user.id,
                    is_send_app: !!userSetting.is_send_app ,
                    is_send_email: !!userSetting.is_send_email,
                    is_send_sms: !!userSetting.is_send_sms,
                }));

                form.setFieldsValue({
                    max_threshold: typeSetting?.max_threshold,
                    min_threshold: typeSetting?.min_threshold,
                });
                setUserSettings(initialUserSettings);
            }
        })
    };

    const handleSave = async () => {
        
        const values = await form.validateFields();
        
        if (typeModalSensor === 'edit') {
            onEditSensorNameSaved(values.name);
        } else if (typeModalSensor === 'alarm') {
            console.log('Submitted values:', values);
            onStoreOrUpdateTypeSetting(values);
        }
      
    };
    const onStoreOrUpdateTypeSetting = (values) => {
        const id = typeSetting?.id || null;
        const data = {
            sensor_type_setting : values
        }
        sensorTypeSettingService.storeOrUpdate(data,id).then(res => {
            if (res.code === '0000') {
                // Close modal after save
                onCancel();
            }
        });
        
    }
    const handleCancel = () => {
        onCancel();
    };
    const options = [
        { label: 'Send App', value: 'is_send_app' },
        { label: 'Send Email', value: 'is_send_email' },
        { label: 'Send SMS', value: 'is_send_sms' },
    ];

    const renderUsers = useCallback( () => {
        return usersProperty.map((user) => {
            const userValues = userSettings?.find(u => u.id === user.id) || {};
            const selectedValues = [
                ...(userValues.is_send_app ? ['is_send_app'] : []),
                ...(userValues.is_send_email ? ['is_send_email'] : []),
                ...(userValues.is_send_sms ? ['is_send_sms'] : []),
            ];

            return (
                <div key={user.id}>
                    <h4>{user.name}</h4>
                    <Checkbox.Group
                        options={options}
                        value={selectedValues}
                        onChange={(selectedValues) => handleChangeCheckbox(user.id, selectedValues)} 
                    />
                </div>
            );
        });
    }, [userSettings,usersProperty]);
        
    const handleChangeCheckbox = (userId, selectedValues) => {
        const updatedUser = {
            id: userId,
            is_send_app: selectedValues.includes('is_send_app'),
            is_send_email: selectedValues.includes('is_send_email'),
            is_send_sms: selectedValues.includes('is_send_sms'),
        };
        let currentUsers = form.getFieldValue('users') || [];

        // If all checkboxes are unchecked, remove the user from the settings
        if (!updatedUser.is_send_app && !updatedUser.is_send_email && !updatedUser.is_send_sms) {
            currentUsers = currentUsers.filter(user => user.id !== userId);
        } else {
            // Otherwise, update or add the user to the settings
            const userIndex = currentUsers.findIndex(user => user.id === userId);

            if (userIndex > -1) {
                currentUsers[userIndex] = updatedUser;
            } else {
                currentUsers = [...currentUsers, updatedUser];
            }
        }
    
        setUserSettings(currentUsers);
    };
    
    const renderContent = useCallback (() => {
        switch (typeModalSensor) {
            case 'edit':
                return (
                    <Form.Item
                        label="Sensor Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input!' }]}
                    >
                        <Input />
                    </Form.Item>
                );
            case 'alarm':
                return (
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="sensor_id"
                                hidden
                            >
                                <Input style={{width:'100%'}}/>
                            </Form.Item>
                            <Form.Item
                                name="type"
                                hidden
                            >
                                <Input  style={{width:'100%'}}/>
                            </Form.Item>
                            <h4>Threshold Settings:</h4>
                                <Form.Item
                                    name="max_threshold"
                                    label="Max Threshold"
                                >
                                <InputNumber addonAfter={statsSensor?.unit}  style={{width:'100%'}}/>
                                </Form.Item>
                                <Form.Item
                                    name="min_threshold"
                                    label="Min Threshold"
                                >
                                <InputNumber addonAfter={statsSensor?.unit} style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <h4>Notify Users</h4>
                            <Form.Item
                                name="users"
                            >
                                {renderUsers()}
                            </Form.Item>
                        </Col>
                    </Row>
                );
            default:
                return <></>;
        }
    },[typeModalSensor,statsSensor,renderUsers]);
       
    const renderTitle = useCallback(() => {
        switch (typeModalSensor) {
            case 'edit':
                return 'Edit Sensor Name';
            case 'alarm':
                return 'Set Alarm';
            default:
                return '';
        }
    },[typeModalSensor]);

    return (
        <EditSensorNameModalWrapper
            title={renderTitle()}
            open={visible}
            onCancel={handleCancel}
            onFinish={handleSave}
            footer={[
                <Button key="cancel" className="cancel-button" onClick={handleCancel}>
                    <IntlMessages id="sensorPage.editSensorNameModal.cancel" />
                </Button>,
                <Button key="save" className="save-button" type="primary" onClick={handleSave}>
                    <IntlMessages id="sensorPage.editSensorNameModal.save" />
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
            >
            {renderContent()}
            </Form>
        </EditSensorNameModalWrapper>
    );
};

export default EditSensorNameModal;