import React, { useEffect, useState, useCallback } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Form, Button, Select, message } from 'antd';
import _ from "lodash";
import SensorModalWrapper from './SensorModal.styles'
import { sensorService } from "@iso/services";
import { dynamoSensorService } from "@iso/services";
import { sensorSuppliers } from '@iso/constants/sensorSuppliers';
import { sensorPartNumbers } from '@iso/constants/sensorPartNumbers';
import InfiniteScrollSelect from "@iso/components/Select/InfiniteScrollSelect";
import { propertyService } from "../../../../services/property.service";
import debounce from 'lodash/debounce';

const { Option } = Select;

const SensorModal = (props) => {
  const cancelModal = () => {
    props.cancelModal();
    form.resetFields();
  }

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [properties, setProperties] = useState([]);
  const [defaultProperties, setDefaultProperties] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    getProperties();
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        setLoading(false);
        console.log('Validate Failed:', info);
      });
  };

  const onStore = (values) => {
    const data = {
      sensor: {
        ...values.sensor,
      }
    }

    sensorService.storeOrUpdate(data, props.sensorData?.id).then(res => {
      if(res.code === '0000'){
          setLoading(false);
          message.success('Add Sensor success!');
          cancelModal();
          props.updateSensorsData();
      }else{
          setLoading(false);
          message.error(res.message);
      }
    });
  }

  const getDevices = (supplier) => {
    dynamoSensorService.getUniqueSensorsBySupplier(supplier).then(res => {
      if (res.code === '0000') {
        setDeviceList(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.message);
      }
    });

    setDeviceList([]);
  }

  const getProperties = (page = 1, pageSize = 10, search = '') => {
    setLoading(true);

    propertyService.getPaginatedProperties(page, pageSize, search).then(res => {
      if (res.code === '0000') {
        setProperties(prevProperties => 
          page === 1 
            ? res.properties 
            : [...prevProperties, ...res.properties]
        );

        if (!search && page === 1) {
          setDefaultProperties(res.properties);
        }

        setPagination({
          ...pagination,
          current: page,
          pageSize,
          total: res.pagination.totalCount,
        });
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.message);
      }
    });
  }

  const onSearchPropertyChange = (value) => {
    setSearchTerm(value);

    if (value) {
      handleSearchProperty(value);
    } else {
      handleSearchProperty.cancel();
      setProperties(defaultProperties);
    }
  }

  const handleSearchProperty = useCallback(
    debounce((value) => {
      getProperties(1, pagination.pageSize, value);
    }, 500)
  , []);

  const handlePageChange = (page, pageSize) => {
    if (page <= Math.ceil(pagination.total / pagination.pageSize)) {
      getProperties(page, pageSize, searchTerm);
    }
  }

  const onChangeSupplier = (value) => {
    getDevices(value);
    setSelectedSupplier(value);
    setFields([]);
  }

  const renderSupplierOptions = () => {
    let options = [];
    _.forEach(sensorSuppliers, (supplier, index) => {
      options.push(
        <Option key={index} value={supplier.value}>
          <div>{supplier.label}</div>
        </Option>
      );
    });
    return (
      <Select onChange={onChangeSupplier} placeholder="Select a vendor" allowClear size="large">
        {options}
      </Select>
    );
  };

  const renderDeviceOptions = (deviceList) => {
    let options = [];
    _.forEach(deviceList, (device, index) => {
      options.push(
        <Option key={index} value={device}>
          <div>{device}</div>
        </Option>
      );
    });
    return (
      <Select placeholder="Select a device" allowClear size="large">
        {options}
      </Select>
    );
  }

  const renderPartNumberOptions = () => {
    let options = [];
    _.forEach(sensorPartNumbers, (partNumber, index) => {
      options.push(
        <Option key={index} value={partNumber.value}>
          <div>{partNumber.label}</div>
        </Option>
      );
    });
    return (
      <Select placeholder="Select a part number" allowClear size="large">
        {options}
      </Select>
    );
  }

  return (
    <SensorModalWrapper
      className='sensor-modal-wrapper'
      forceRender={true}
      open={props.visibleSensorModal}
      title={<IntlMessages id="Add Sensor" />}
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="horizontal"
        fields={fields}
      > 
        <Form.Item
          name={['sensor', 'property_id']}
          label="Property"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <InfiniteScrollSelect
            showSearch
            placeholder={<IntlMessages id="propertyPage.modal.selectAPropertyPlaceholder" />}
            size="large"
            allowClear
            filterOption={false}
            onSearch={onSearchPropertyChange}
            options={(properties || []).map((property) => ({
              value: property.id,
              label: property.name,
            }))}
            onLoadMore={() => handlePageChange(pagination.current + 1, pagination.pageSize)}
          />
        </Form.Item>

        <Form.Item
          name={['sensor', 'supplier']}
          label="Vendor"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          { renderSupplierOptions() }
        </Form.Item>
          
        {  }  
        <Form.Item
          name={['sensor', 'terminal_id']}
          label="Device"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          { renderDeviceOptions(deviceList) }
        </Form.Item>
        
        { selectedSupplier === 'RF Technology' && <>

            <Form.Item>
              {Array.from({ length: 5 }).map((_, index) => (
                <Form.Item
                  key={`sensor${index + 1}`}
                  name={['sensor', 'part_number', index]}
                  label={`Sensor ${index + 1}`}
                  rules={[
                    {
                      required: false,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderPartNumberOptions()}
                </Form.Item>
              ))}
            </Form.Item>
        </> }
        
      </Form>
    </SensorModalWrapper>
  );
};

export default SensorModal;
