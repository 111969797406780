import React, { useState, useEffect } from 'react';
import Box from '@iso/components/utility/box';
import { useSelector } from 'react-redux';
import { propertyService, dashboardService,weatherDataService } from '@iso/services';
import { Button, Row, Col,Select } from 'antd';
import _ from 'lodash';
import * as d3 from "d3";
import WeatherWrapper from './Weather.styles'; 
import UserWeatherChart from './UserWeatherChart';
import WeatherTable from './WeatherTable';
import WeatherForm from './WeatherForm';
import moment from 'moment';
import WeatherForecast from './WeatherForecast'

const { Option } = Select;
const Weather = () => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [property, setProperty] = useState({});
  const [dataTask, setDataTask] = useState({});
  const [dataChart, setDataChart] = useState([]);
  const [dataBubbleChart, setDataBubbleChart] = useState([]);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [firstPrimaryObjects, setFristPrimaryObjects] = useState(0);
  const [weatherDataTable, setWeatherDataTable] = useState({});
  const [weatherApiType, setWeatherApiType] = useState('');
  const [typeChartR, setTypeChartR] = useState('wind_speed');
  const [nameChartR, setNameChartR] = useState('Wind Speed');
  const [dateChart,setDateChart] = useState(moment(new Date(),"YYYY-MM-DD"));;

  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);
  const [loading, setLoading] = useState(false);
  const [numberDays, setNumberDays] = useState(1);
  const [widthClass, setWidthClass] = useState('w-full');
  const [daysWeather, setDaysWeather] = useState(moment(new Date(),"YYYY-MM-DD"));
  const [hoursWeather, setHoursWeather] = useState(3);
  const [nextDay, setNextDay] = useState(0);
  const [weatherData, setWeatherData] = useState([]);

  const [dashboardInfo, setDashboardInfo] = useState({
    weather: {
      rain: [],
      temperature: []
    }
  });

  useEffect(() => {
    if(activePropertyId){
      propertyService.viewProperty(activePropertyId).then(res => {
        if(res.code === '0000'){
          setProperty(res.property);
          setPrimaryObjects(res.primary_objects);
          if(res.property.country === "Australia"){
            setWeatherApiType('janes_weather');
          }else{
            setWeatherApiType('open_weather');
          }
        }
      });
    }
  }, [activePropertyId]);

  const updateWeatherData = () => {
    if(activePropertyId){
       weatherDataService.getList(activePropertyId).then(res => {
        if(res.code === '0000'){
          setWeatherDataTable(res.weather_data);
        }
      });
    }
  }

  const updateWeatherChart = (primary_object_id) => {
    weatherDataService.getDataForWeatherChart(primary_object_id, 'rain').then(res => {
      if(res.code === '0000'){
        setFristPrimaryObjects(primary_object_id);
        setWeatherData(res.weather_data);
      }
    });
  }

  useEffect(() => {
    if(activePropertyId && primaryObjects[0]){
      updateWeatherChart(primaryObjects[0].id);
    }
  }, [activePropertyId, primaryObjects]);

  useEffect(() => {
    updateWeatherData();
  }, [activePropertyId]);

  const onSaved = (weather_data) => {
    setWeatherData(weather_data);
  }

  useEffect(() => {
    if(activePropertyId){
      setLoading(true);
      if(weatherApiType !== ''){
        dashboardService.get(activePropertyId,weatherApiType).then(res => {
          if(res.code === '0000'){
            setDashboardInfo(res.data);
            setDataTask(res.data.task_count);
            setLoading(false);
          }
        });
      }
    }
  }, [activePropertyId,weatherApiType]);

 const handleChange = (value) => {
    setWeatherApiType(value.value);
    setDaysWeather(moment(new Date(),"YYYY-MM-DD"));
    setNameChartR('Wind Speed');
    setNumberDays(1);
    setDateChart(moment(new Date(),"YYYY-MM-DD"));
    setWidthClass('w-full');
  }

  const handleChangeTypeChart = (index,value) => {
    setTypeChartR(value.value);
    setNameChartR(value.label);

  }

  const handleChangeDaysWeather = (index,value) => {
    setDaysWeather( moment(new Date(),"DD-MM-YYYY").add(value.day, 'days'));
    setHoursWeather(value.hour);
    setNextDay(value.next);
    setNumberDays(value.value);
    setWidthClass(value.width);
  }
  return (
    <WeatherWrapper>
      <Row>
        <Col span={24}>
        <Box className="statistic-card">
          <WeatherForecast />
        </Box>
        </Col>
        <Col xl={12} md={24} sm={24} xs={24}>
          <div className="statistic-card">
            <WeatherForm primaryObjects={primaryObjects} propertyId={activePropertyId} 
            updateWeatherChart={updateWeatherChart} updateWeatherData={updateWeatherData}/>
          </div>
        </Col>
        <Col xl={12} md={24} sm={24} xs={24}>
          <div id='weather-tab-chart' className="statistic-card">
            <UserWeatherChart data={weatherData} primaryObjects={primaryObjects} onSaved={onSaved} firstPrimaryObjects={firstPrimaryObjects}/>
          </div>
        </Col>

        {weatherDataTable && 
        <Col xl={24} md={24} sm={24} xs={24}>
           <WeatherTable weatherDataTable={weatherDataTable} updateWeatherData={updateWeatherData} 
           updateWeatherChart={updateWeatherChart}/>
        </Col>
        }
      </Row>        
    </WeatherWrapper>
  );
}

export default Weather;