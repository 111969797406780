import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
import { Statistic, Card, Row, Col, Avatar, Button, Tag } from 'antd';
import MarketplacePage from './Marketplace.styles';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl, Marker, Popup } from 'react-leaflet';
import { UserOutlined, WechatFilled, MailFilled, EnvironmentFilled, PhoneFilled, ClockCircleFilled, TwitterOutlined } from '@ant-design/icons';
import { marketplaceProductService } from '@iso/services';
import _ from 'lodash';
import moment from 'moment';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import EmailContactModal from './EmailContactModal';

const { Meta } = Card;

const ProductDetail = () => {
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const product_id = urlParams.get('product_id');

  const dispatch = useDispatch();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activeProperty = localStorage.getItem('active_property');
  const activePermission = useSelector((state) => state.permission.activePermission);
  const position = [51.505, -0.09]
  const [productDetail, setProductDetail] = useState({});
  const [images, setImages] = useState([]);
  const [visibleEmailContact, setVisibleEmailContact] = useState(false);

  const showAlert = () => {
    alert('kaka');
  }

  useEffect(() => {
    if(product_id)
    {
      marketplaceProductService.viewProduct(product_id).then(res => {
        if(res.code === '0000'){
          setProductDetail(res.marketplace_product);
        }
      });
    }
  }, [])

  useEffect(() => {
    if(productDetail.images && productDetail.images.length > 0)
    {
      console.log(productDetail.images);
      let image_options = [];
      _.forEach(productDetail.images, (option, index) => {
        image_options.push(
          {
            original: process.env.REACT_APP_API_BASE_URL + option.path,
            thumbnail: process.env.REACT_APP_API_BASE_URL + option.path,
          }
        );
      })
      setImages(image_options);
    }else{
      setImages([
        {
          original:'https://www.usu.edu/involvement/images/robinsimages2020/Profile-Photo-Unavailable.png',
          thumbnail: 'https://www.usu.edu/involvement/images/robinsimages2020/Profile-Photo-Unavailable.png'
        }
      ])
    }
  }, [productDetail])

  const showEmailContactModal = () => {
    setVisibleEmailContact(true);
  }

  const cancelEmailContactModal = () => {
    setVisibleEmailContact(false);
  }



  return (
    <LayoutWrapper>
      <MarketplacePage>
        <Row  className="product-detail-page">
          <Col xs={{span: 24}} md={{span: 13}}>
            <ImageGallery items={images} />
            <h1 className="detail-title">{productDetail.title}</h1>
            <br/>
            <Tag icon={<span>&#36; </span>} className="price-tag">
              {new Intl.NumberFormat().format(productDetail.price)}
            </Tag>
            <br/>
            <br/>
            <b>External link:</b>
            <p className="detail-description">
            {productDetail.description}
            </p>
            <hr/>
            <h2>Location</h2>
            <Map center={position} zoom={13} scrollWheelZoom={true} style={{height:"400px"}}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position}>
              </Marker>
            </Map>
          </Col>
          <Col xs={{span: 24}} md={{span: 9, offset: 1}}>
            <div className="site-card-border-less-wrapper">
              <Card title="Seller Information" bordered={true}>
                <Avatar
                  style={{
                    backgroundColor: '#87d068',
                  }}
                  icon={<UserOutlined />}
                >
                </Avatar>
                &nbsp;
                &nbsp;  
                <span style={{fontWeight:"bold", fontSize: "16px"}}>{productDetail.user}</span>
                <br/>
                <br/>
                <Row>
                  <Col xs={{span: 1}} md={{span: 1}}>
                    <EnvironmentFilled />
                  </Col>
                  <Col xs={{span: 21, offset: 1}} md={{span: 21, offset: 1}}>
                    <span>{productDetail.user_address}</span>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col xs={{span: 1}} md={{span: 1}}>
                    <PhoneFilled />
                  </Col>
                  <Col xs={{span: 21, offset: 1}} md={{span: 21, offset: 1}}>
                    <span>{productDetail.user_phone}</span>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col xs={{span: 1}} md={{span: 1}}>
                    <ClockCircleFilled />
                  </Col>
                  <Col xs={{span: 21, offset: 1}} md={{span: 21, offset: 1}}>
                    {moment(productDetail.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                  </Col>
                </Row>
                <Button type="primary" className="btn-success chat-button" icon={<MailFilled />} size="large" onClick={showEmailContactModal}>
                  Contact Seller
                </Button>
              </Card>
            </div>
          </Col>
        </Row>
        <EmailContactModal visibleEmailContact={visibleEmailContact} 
        cancelEmailContactModal={cancelEmailContactModal} productId={productDetail.id}/>
      </MarketplacePage>
    </LayoutWrapper>
  );
}

export default ProductDetail;
