import React, { useState, useEffect } from "react";
import ColorPickerWrapper from "./ColorPicker.styles";
import { SketchPicker } from "react-color";
import { Row, Col } from "antd";

const ColorPicker = ({ defaultColor = "#0B2A1B", handleChangeColor }) => {
  const [color, setColor] = useState(defaultColor);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const changeColor = (color) => {
    setColor(color.hex);
    handleChangeColor(color);
  };
  useEffect(()=>{
    setColor(defaultColor)
  },[defaultColor])
  return (
    <ColorPickerWrapper>
      <Row align="middle">
        <Col>
          <div
            className="color-swatch"
            onClick={() => {
              setDisplayColorPicker(true);
            }}
          >
            <div className="color-color" style={{ background: color }} />
          </div>
          {displayColorPicker ? (
            <div className="color-popover">
              <div
                className="color-cover"
                onClick={() => {
                  setDisplayColorPicker(false);
                }}
              />
              <SketchPicker color={color} onChange={changeColor} />
            </div>
          ) : null}
        </Col>
        <Col>
          <span className="color-label">{color}</span>
        </Col>
      </Row>
    </ColorPickerWrapper>
  );
};

export default ColorPicker;
