import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import modal from '@iso/redux/modal/reducer';
import property from '@iso/redux/property/reducer';
import user from '@iso/redux/user/reducer';
import permission from '@iso/redux/permission/reducer';
import subscription from '@iso/redux/subscription/reducer';
import btnSave from '@iso/redux/btnSave/reducer';
import stripe from '@iso/redux/stripe/reducer';
import property_pic from '@iso/redux/property_pic/reducer';
import property_trial_satellite from '@iso/redux/property_trial_satellite/reducer';
import notification from './notification/reducer';
export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  modal,
  property,
  user,
  permission,
  btnSave,
  stripe,
  subscription,
  property_pic,
  property_trial_satellite,
  notification
});
