import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";
import "gantt-task-react/dist/index.css";

const TaskChartWrapper = styled.div`
  width: 100%;
  // height: 100%;
  .ViewContainer {
    margin-bottom: 15px;
    button {
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
margin-left : 8px;
      width:58px;
      height:40px;
      color : #8F9497;
      padding: 10px, 16px, 10px, 16px;
      border-radius: 8px;
      border: 1px ;
      border-color : #EAECF0;
      background-color : #F9F9FB;
      gap: 8px;
      &.active {
        color: #ffffff;
        background-color: #0B2A1B;
        border-color : #0B2A1B;
      }
    }
  }
`;

export default WithDirection(TaskChartWrapper);
