import React from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import PreviewImageModalWrapper from "./PreviewImageModal.styles";
import { Button } from "antd";
import _ from "lodash";
import modalActions from "@iso/redux/modal/actions";
import "react-tagsinput/react-tagsinput.css";

const { closeModal } = modalActions;

const PreviewImageModal = (props) => {
  const renderImage = () => {
    if (props.fileType === "video") {
      return (
        <video style={{ maxWidth: "100%"}} controls>
          <source src={props.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <img style={{ maxWidth: "100%" }} src={props.url} />;
    }
  };

  return (
    <PreviewImageModalWrapper
      width={600}
      className="user-access-modal"
      forceRender={true}
      visible={props.isVisibleModal}
      title="Media"
      onCancel={props.cancelModal}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          onClick={props.cancelModal}
          className="cancel-button"
        >
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
      ]}
    >
      {renderImage()}
    </PreviewImageModalWrapper>
  );
};

export default PreviewImageModal;
