import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const PropertyManagePage = styled.div`
width: 100%;
height: 100%;
.ant-timeline-item-head.ant-timeline-item-head-green {
  color: red !important;
  border-color: red !important;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}
.dot-btn {
  span {
    rotate: 90deg;
    cursor: pointer;
  }
}
.ant-popover-inner {
  border-radius: 12px !important;
}
.btn-success {
  background-color: #0B2A1B;
  border-color: #0B2A1B;
}
.manage-property-title {
  color: #101828;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.manage-table .react-collapsible tbody .name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
.manage-property-header {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  margin: auto;
}
.manage-property-header > .mp-title {
  min-width: 15%;
  max-width:60%;
}
.manage-property-table {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  background: white;
  .ant-pagination {
    padding: 24px;
  }
  .add-property-btn {
    background: #f80e46;
    border-radius: 8px !important;
    color: #fff;
  }
  .m-p-main {
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }
  .dropdown {
    border: 1px solid #d0d5dd !important;
  }
}
.action-btn {
  font-size: 12px;
  line-height: 24px;
  background: #535763;
  border-radius: 10px !important;
  border-color: transparent !important;
  color: white;
}
.button-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.fAgWDa .ant-table-content {
  overflow-x: unset;
}
.fAgWDa {
  overflow-x: unset;
}
.dtr-data {
  white-space: break-spaces;
}
.delete-button {
  margin-left: 5px;
}
.search-bar {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 12px;
  border: none;
  height:40px;
  margin-left: 2rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  flex-direction: row-reverse;
  min-width : 100px;
  max-width: 228px;
}
.search-bar .ant-input {
  background: none!important;
  border: none!important;
  color: #8F92A1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
  .cstmData{ 
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #000000;
    margin-bottom : 10px;
  }
  .title{
    border-radius : 8px;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }
  .content{
    color: #000000;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
  }
  .cardValue{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    color: #000000;

  }
  .button-group .ant-select-selector{
    height : 40px !important;
  }
  thead{
    text-align : left;
  }
  .btn-action{
    background: #ECDD5D;
    border :  #ECDD5D;
    color : #1E1E1E;
  }
  .lableAction{
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #1E1E1E;

  }
  .manage-table{
    border-top: 1px solid #EAECF0;
  }
  .custSelect{
    width : 150px;
  }

@media only screen and (max-width : 425px)
{
  .manage-property-header{
    flex-wrap: wrap !important;
  }
   
  .manage-property-title{
    margin-bottom : 10px;
  }
.button-group{
  justify-content: flex-start !important;
}
.cutAddButton{
  width : 100% !important;
}
.custSelect{
  width : 100% !important;
}
.search-bar{
 margin-bottom : 10px;
 width : 100% !important;
 max-width :100% !important;
} 
}
@media only screen and (max-width : 1440px)
{
  .manage-property-header > .mp-title {
    min-width: 25%;
  }
  .search-bar{
    margin-left: 0rem;
    width : 250px ;
  } 
}
@media only screen and (max-width : 1024px)
{
  .manage-property-header > .mp-title {
    min-width: 35% !important;
  }
}
@media only screen and (max-width : 768px)
{
  .manage-property-header {
    // flex-wrap: wrap;
  }
  .manage-property-header > .mp-title {
    min-width: 51% !important;
  }
  .search-bar{
    margin-left: 0rem;
    width : 250px ;
  } 
}
@media only screen and (max-width : 375px)
{
  .button-group{
    display : flex;
    flex-wrap : wrap;
    justify-content: flex-start !important;
  }
  .search-bar{
    width : 100% !important;
  } 
  .manage-property-header > .mp-title {
    min-width: 100% !important;  
  }
}
.ant-spin-dot-item{
    background-color: #0B2A1B;
  }
`;

export default WithDirection(PropertyManagePage);