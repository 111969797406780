import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";

export const sensorService = {
  getList,
  getDetail,
  getAllSensors,
  storeOrUpdate,
  destroy,
  getAssetSensors,
  addSensorToAsset,
  removeSensorFromAsset
};

function getList(params = {}){
  return (
    axios
      .get(
        apiEndpoint.sensors,
        {
          params: params,
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getDetail(sensorId, params = {}){
  return (
    axios
      .get(
        apiEndpoint.sensors + '/' + sensorId,
        {
          params: params,
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getAllSensors(params = {}){
  return (
    axios
      .get(
        apiEndpoint.getAllSensors,
        {
          params: params,
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.sensors(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.sensors,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.sensors(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function getAssetSensors(assetId){
  return (
    axios
      .get(
        apiEndpointFunction.getAssetSensors(assetId),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function addSensorToAsset(sensorId, assetId){
  return (
    axios
      .post(
        apiEndpointFunction.addSensorToAsset(),
        {
          sensor_id: sensorId,
          asset_id: assetId
        },
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function removeSensorFromAsset(sensorId, assetId){
  return (
    axios
      .post(
        apiEndpointFunction.removeSensorFromAsset(),
        {
          sensor_id: sensorId,
          asset_id: assetId
        },
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}
