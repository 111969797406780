import styled from "styled-components";

export const BioFormWrapper = styled.div`
  padding: 20px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-table-cell {
    padding: 5px;
  }
  input[type="text"] {
    background-color: #ebebeb;
    border: none !important;
    border-radius: 0px !important;
  }
  .primary-title {
    background-color: #bebebe;
    color: #001786;
    font-weight: bold;
  }
  .ant-table-tbody > tr > td {
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .last-row > td {
    border-bottom: 1px solid;
  }
  .last-cell-column-1 {
    border-bottom: 1px solid;
  }
  .table1_column4 {
    border-right: 1px solid !important;
  }
  .border-right {
    border-right: 1px solid !important;
  }
  .title {
    color: #001786;
    font-weight: bold;
  }
  .sub-title {
    color: #001786;
    font-style: italic;
    font-weight: 400;
  }
  .filled-primary {
    background-color: #bebebe;
    border: none;
  }
  .border-bottom {
    border-bottom: 1px solid;
  }
  .quote {
    font-style: italic;
    font-weight: bold;
  }
  .underline {
    text-decoration: underline !important;
  }
  .table1,
  .table2 {
    margin-bottom: 100px;
  }
  table {
    height: 100%;
  }
  table td {
    height: 100%;
  }
  .comment-column * {
    height: 100%;
  }
  .btn-submit-bio {
    margin-top: 50px;
  }
  .signature-table {
    margin-top: 50px;
    td {
      border-right: 1px solid #f0f0f0 !important;
      border-left: 1px solid #f0f0f0 !important;
      border-top: 1px solid #f0f0f0 !important;
    }
    th {
      border-right: 1px solid #f0f0f0 !important;
      border-left: 1px solid #f0f0f0 !important;
      border-top: 1px solid #f0f0f0 !important;
    }
    .signature-action-table {
      display: flex;
      justify-content: center;
    }
  }
`;
