import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@iso/components/uielements/popover';
import IntlMessages from '@iso/components/utility/intlMessages';
import userpic from '@iso/assets/images/AirAgri-user-144.png';
import authAction from '@iso/redux/auth/actions';
import TopbarDropdownWrapper from './TopbarDropdown.styles';
import TopbarProfileModal from './TopbarProfileModal';
import ShareOptionsModal from './ShareOptionsModal';
import { profileService, userService } from '@iso/services';
import { message } from 'antd';

const { logout } = authAction;

export default function TopbarUser() {
  const [visible, setVisibility] = React.useState(false);
  const [visibleProfile, setVisibilityProfile] = React.useState(false);
  const [editProfile, setEditProfile] = React.useState({});
  const currentUser = useSelector((state) => state.user.user);
  const [profileData, setProfileData] = React.useState({});
  const [visibleShareOption, setVisibleShareOption] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    updateProfileData();
  }, [currentUser])

  const updateProfileData = () => {
    profileService.getProfile().then(res => {
      if(res.code === '0000'){
        setProfileData(res.user);
      }
    });
  }

  function handleVisibleChange() {
    setVisibility(visible => !visible);
  }

  const showModal = () => {
    setVisibilityProfile(true);
    setVisibility(visible => !visible);
  }

  const cancelModal = () => {
    setVisibilityProfile(false);
    //setEditProperty({});
  }

  const showShareOptionModal = () => {
    setVisibleShareOption(true);
    setVisibility(false);
  }

  const cancelShareOptionModal = () => {
    setVisibleShareOption(false);
  }

  const sendVerifyEmail = () => {
    userService.sendVerifyEmail().then(res => {
      if(res.code === '0000'){
        message.success(`We've sent an email to ${currentUser.email}. Click the confirmation link in that email to begin using AirAgri`, 7)
      }
    });
  }

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      { currentUser.is_public_view_user ? 
        <a className="isoDropdownLink" onClick={sendVerifyEmail}>
          <IntlMessages id="topbar.upgradeToFreeAccount" />
        </a> : <></>
      }
      <a className="isoDropdownLink" href="/profile">
        <IntlMessages id="topbar.myprofile" />
      </a>
      <a className="isoDropdownLink" href="/manage-your-product">
        <IntlMessages id="topbar.manageYourProducts" />
      </a>
      <a className="isoDropdownLink" href="https://www.airagri.com.au/contact-us/">
        <IntlMessages id="sidebar.feedback" />
      </a>
      <a className="isoDropdownLink" href="https://www.youtube.com/channel/UCCVpRb5qfSsHWaDzfL5eXGg">
        <IntlMessages id="topbar.help" />
      </a>
      <div className="isoDropdownLink" onClick={showShareOptionModal}>
        <IntlMessages id="topbar.share" />
      </div>
      <div className="isoDropdownLink" onClick={() => dispatch(logout())}>
        <IntlMessages id="topbar.logout" />
      </div>
      <TopbarProfileModal editProfile={editProfile} visibleProfile={visibleProfile} cancelModal={cancelModal}/>
      <ShareOptionsModal visibleShareOption={visibleShareOption} cancelShareOptionModal={cancelShareOptionModal}/>
    </TopbarDropdownWrapper>
    
  );
  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onOpenChange={handleVisibleChange}
      arrowPointAtCenter={true}
      placement="bottomLeft"
    >
      <div className="isoImgWrapper">
        <img alt="user" src={(profileData.images && profileData.images.length > 0) ? process.env.REACT_APP_API_BASE_URL + profileData.images[profileData.images.length-1].path : userpic} className="topbar-avatar"/>
        <span className="userActivity online" />
      </div>
    </Popover>
  );
}
