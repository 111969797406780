import React, { useState, useEffect, useCallback } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import NewTaskWrapper from "./NewTask.styles";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Upload,
  Switch,
  InputNumber,
} from "antd";
import _, { assign, set } from "lodash";
import { taskPriorities } from "@iso/constants/taskPriorities";
import { taskRepeatTypes } from "@iso/constants/taskRepeatTypes";
import { taskStatus } from "@iso/constants/taskStatus";
import Loader from "@iso/components/utility/loader";
import { propertyService, taskService } from "@iso/services";
import { dateHelper } from "@iso/lib/helpers/dateHelper";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { UploadOutlined } from "@ant-design/icons";
import $ from "jquery";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";
import { propertyHelper } from "@iso/lib/helpers/propertyHelper";

const { Option } = Select;
const { closeModal } = modalActions;

const NewTask = (props) => {
  
  const { setVisibleNewTaskModal, visibleNewTaskModal, propertyId,visibleToAddAssigne,setVisibleToAddAssigne ,
    setAssigneeDetail,
        setAssigneesDetails
  } = props;
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );

  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [assigneesUser, setAssigneesUser] = useState([]);
  const [modalLoading, setModalLoading] = useState(true);
  const dispatch = useDispatch();
  const [dataTask, setDataTask] = useState({});
  const [isAdHoch, setIsAdHoch] = useState(true);
  const [imageId, setImageId] = useState(null);
  const [assignUser, setAssignUser] = useState([]);
  const [assigneeDetails, setAssigneeDetails] = useState([]);
  const [updatedState, setUpdatedState] = useState([])
  const [item, setItem] = useState([])


  useEffect(() =>{
    setFields([
      {
        name: ["task", "title"],
        value: props?.task?.title || "",
      }
    ])
  },[propertyId,props.task])
  
  const updateDataAssignee = useCallback((propertyId) => {
    propertyService.getListUsersOfProperty(propertyId).then((res) => {
      
      if (res.code === "0000") {
       
        setAssigneesUser(res.users);
        setModalLoading(false);

      }
    });
  }, [props]);
  useEffect(() => {
    if (propertyId) {
      updateDataAssignee(propertyId);
    }
  }, [propertyId]);

  const handleChangeTag = (input_tags) => {
    setTags(input_tags);
  };

  useEffect(() => {
    handleChangeTag(tags);
  }, [tags]);


  const renderOptionPriority = () => {
    let options = [];
    _.forEach(taskPriorities, (taskPriority, index) => {
      options.push(
        <Option key={index} value={taskPriority.value}>
          {taskPriority.label}
        </Option>
      );
    });
    return (
      <Select allowClear size="large">
        {options}
      </Select>
    );
  };

  const renderOptionRepeatTypes = () => {
    let options = [];
    _.forEach(taskRepeatTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          {type.label}
        </Option>
      );
    });
    return <Select size="large">{options}</Select>;
  };

  const renderOptionStatus = () => {
    let options = [];
    _.forEach(taskStatus, (status, index) => {
      options.push(
        <Option key={index} value={status.value}>
          {status.label}
        </Option>
      );
    });
    return (
      <Select allowClear size="large">
        {options}
      </Select>
    );
  };



const handleAssignee = (value) => {
  setItem(value)
   setAssigneesDetails && setAssigneesDetails(value)
}

  const renderOptionAssignee = (users) => {
    let options = [];
    _.forEach(users, (user, index) => {
      options.push(
        <Option key={user.id} value={user.id}>
          {user.name} | {user.email}
        </Option>
      );
    });
    return (
      <Select allowClear size="large"
      value={users}
      onChange={handleAssignee}
      >
        <Option key={0} value={0} >
          Not assigned
        </Option>
        {options}
      </Select>
    );
  };


  const AssinedUSerSelected = (value) => {

    setAssignUser(value);
    setAssigneeDetail && setAssigneeDetail(value)
   
  }


  useEffect(() => {
    setAssignUser(assignUser);
   
      let item  = assigneesUser?.filter(g => assignUser.includes(g.user_id)).map(g => g);
      setAssigneeDetails(item);
      
  }, [assignUser])
  
  const renderOptionAssignees = (users) => {

    let options = [];
    _.forEach(users, (user, index) => {
      options.push(
        <Option key={user.id} value={user.id}>
          {user.name} | {user.email}
        </Option>
      );
    });
    return (
      <Select allowClear size="large"
        value={users}
        onChange={AssinedUSerSelected}
        mode="multiple"
      >
        <Option key={0} value={0} >
          Not assigned
        </Option>
        {options}
      </Select>
    );
  };



  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    });
    return (
      <Select allowClear size="large">
        <Option key={0} value={0}>
          No paddock
        </Option>
        {options}
      </Select>
    );
  };

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
       
        values.task.tags = tags;
        onStore(values);
      })
      .catch((info) => {

        setLoading(false);
      });
  };

  const onStore = (values) => {
    if (visibleToAddAssigne) {
      props.setAssigneeDetails(values);
      setLoading(false);
      setVisibleToAddAssigne(false);
      form.resetFields();
      setTags([]);
     
    }
    else{
    const data = {
      task: {
        ...values.task,
        start_date:
          values.task.start_date && values.task.start_date !== ""
            ? dateHelper.dateForAPI(values.task.start_date)
            : null,
        due_date:
          values.task.due_date && values.task.due_date !== ""
            ? dateHelper.dateForAPI(values.task.due_date)
            : null,
        point: dataTask.point,
        property_id: props.propertyId,
        is_adhoch: isAdHoch == true ? 1 : 0,
        image_id: updatedState,
        assignee_ids: assignUser,
        assignees: assigneeDetails,
      },
    };


    taskService.storeOrUpdateTasks(data).then((res) => {
     
      if (res.code === "0000") {
        props.onSaved(props.kanbanToggle);
        if(props.setTask){
        props.setTask({
          title: "",
          description: "",
          tags: '',
          status: "",
          property_id: props.propertyId,
          priority: "medium",
        })
      }
        cancelModal();
        form.resetFields();
        setTags([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }
  };



  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      // let $oldOne = $(".anticon-delete")[0];
      // setTimeout(() => {
      //   if ($oldOne) $($oldOne).trigger("click");
      // }, 100);
    // setUpdatedState( [...fileList] )
    },
    onChange: (response,) => {
      if (response.file && response.file.status == "done") {
        // setImageId({ fileList: [...fileList] })
        setImageId(response.file.response.image.id);
        setUpdatedState(prevData => [...prevData, response.file.response.image.id])
      }
    },
    listType: "picture",
    multiple: true,
  };
  

  const handleChangeAdHoch = (value) => {
    setIsAdHoch(value);
  };

  const cancelModal = () => {
    
    setVisibleNewTaskModal(false);
    if (setVisibleToAddAssigne) {
      setVisibleToAddAssigne(false);
    }
    
  };
  return (
    <NewTaskWrapper
      forceRender={true}
      open = {visibleNewTaskModal ? visibleNewTaskModal  : visibleToAddAssigne}
      title={visibleToAddAssigne ? "Add Assignee":<IntlMessages id="propertyMapPage.addTask" />}
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={cancelModal} className={visibleToAddAssigne ? 'btnCancel' :"cancel-button"} >
          {visibleToAddAssigne ? "Add":<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        
        ( activePermission == "owner" ||
          activePermission == "admin" ||
          activePermission == "modify") && (
           
         !visibleToAddAssigne ?  <Button
            key="submit"
            className="btn-success"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>:''
        )
      ]}
    >

      {modalLoading ? (
        <Loader />
      ) :
        <Form
          form={form}
          name="addEditTask"
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          <div className="form-body">
            {visibleToAddAssigne ?'' :
            <>
            <Form.Item
              name={["task", "is_adhoch"]}
              className="isoInputWrapper"
              valuePropName="checked"
            >
              <Switch
                checkedChildren="AdHoch"
                unCheckedChildren="Annual Plan"
                defaultChecked={isAdHoch}
                onChange={handleChangeAdHoch}
              />
            </Form.Item>
           
            <Form.Item
              name={["task", "title"]}
              className="isoInputWrapper"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Input task title" />
            </Form.Item> 
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return currentValues["task"];
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("task")) {
                  if (getFieldValue("task").is_adhoch === false) {
                    return (
                      <>
                        <Row>
                          <Col xs={{ span: 24 }} md={{ span: 11 }}>
                            <Form.Item
                              name={["task", "days_before_task"]}
                              className="isoInputWrapper"
                              label="Reminder - In Days"
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                size="large"
                                min={0}
                                max={100}
                                step={1}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                            <Form.Item
                              name={["task", "repeat_frequency"]}
                              className="isoInputWrapper"
                              label="Repeat Frequency"
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              {renderOptionRepeatTypes()}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          name={["task", "duration"]}
                          className="isoInputWrapper"
                          label="Duration - In Days"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            size="large"
                            min={0}
                            max={100}
                            step={1}
                            addonAfter="days"
                          />
                        </Form.Item>
                      </>
                    );
                  }
                }
              }}
            </Form.Item>
            <Form.Item
              name={["task", "priority"]}
              label="Priority"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              {renderOptionPriority()}
            </Form.Item>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["task", "start_date"]}
                  className="isoInputWrapper"
                  label="Start Date"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <DatePicker size="large" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["task", "due_date"]}
                  className="isoInputWrapper"
                  label="Due Date"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <DatePicker size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  name={["task", "status"]}
                  className="isoInputWrapper"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  {renderOptionStatus()}
                </Form.Item>
              </Col>
            </Row>
            
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["task", "assignee_id"]}
                  className="isoInputWrapper"
                  label="Assignee"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  {renderOptionAssignee(assigneesUser)}
                </Form.Item>
              </Col>



              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Form.Item
                  name={["task", "primary_object_id"]}
                  className="isoInputWrapper"
                  label="Area"
                >
                  {renderOptionObject(props.primaryObjects)}
                </Form.Item>
              </Col>
            </Row>
            </>
}
          {visibleToAddAssigne ? 
          <Form.Item
          name={["task", "assignee_id"]}
          className="isoInputWrapper"
          label="Assignee"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          {renderOptionAssignee(assigneesUser)}
        </Form.Item>
        :''
        }

            <Form.Item
              name={["task", "assignees"]}
              className="isoInputWrapper"
              label="Assignees"

            >
              {renderOptionAssignees(assigneesUser)}
            </Form.Item>
        {visibleToAddAssigne ? '' :
        <>
            <Form.Item className="isoInputWrapper" label="Tags">
              <TagsInput
                name={["task", "tags"]}
                value={tags}
                onChange={handleChangeTag}
                addOnBlur="true"
              />
            </Form.Item>
            <Form.Item name={["task", "details"]} label="Details">
              <Input.TextArea />
            </Form.Item>
            {propertyHelper.checkIsGoldPackage(activePropertySubscription) && (
                <>
                  <Form.Item name={["attachment"]} label="Attachment">
                    <Upload {...uploadProps}>
                      <Button className="action-button" icon={<UploadOutlined />}>
                        Upload
                      </Button>
                    </Upload>
                  </Form.Item>
                  {dataTask.images && dataTask.images.length > 0 && (
                    <img
                      style={{ width: "50%" }}
                      src={
                        process.env.REACT_APP_API_BASE_URL +
                        dataTask.images[dataTask.images.length - 1].path
                      }
                    />
                  )}
                </>
              )}

                </>    }
          </div>
        </Form>}
    </NewTaskWrapper>
  );
};

export default NewTask;