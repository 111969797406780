import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Button, Form, Steps, Switch } from "antd";
import EditFieldModalWrapper from "./EditFieldModal.styles";
import FormBuilder from "antd-form-builder";
import { globalFormService } from "@iso/services";
import queryString from "query-string";
import _ from "lodash";
import SignaturePad from "@iso/components/SignaturePad/index";
import { isDesktop, isTablet } from "react-device-detect";
import DragDropQuestion from "@iso/components/DragDropQuestion/DragDropQuestion";

const { Step } = Steps;
const isMobile = !isDesktop && !isTablet;

const PreviewFormModal = (props) => {
  const formRef = React.createRef();
  const [form] = Form.useForm();
  const [steps, setSteps] = useState({});
  const [newWizardMeta, setNewWizardMeta] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsLength, setStepsLength] = useState(0);
  const [isSimpleForm, setIsSimpleForm] = useState(true);
  const forceUpdate = FormBuilder.useForceUpdate();

  const handleCancel = () => {
    props.cancelPreviewModal();
    form.resetFields();
  };

  const getFields = (fields) => {
    for (let i in fields) {
      switch (fields[i]["widgetLabel"]){
        case "signature":
          fields[i]["widget"] = SignaturePad;
          break;
        case "dragdrop":
          fields[i]["widget"] = DragDropQuestion;
          break;
        default:
          break;
      }
    }
    return fields;
  };

  const renderFormBuilder = (globalForm) => {
    return (
      <>
        {globalForm.map((value) => (
          <>
            <h3>{value.name}</h3>
            <p>{value.description}</p>
            <FormBuilder
              meta={getFields(value.meta_fields.fields)}
              form={formRef}
            />
            <hr />
          </>
        ))}
      </>
    );
  };

  const getStepInfo = () => {
    let questionIndex = -1;
    let stepInfo = {};
    if (props.globalForm) {
      props.globalForm.forEach((form, formIndex) => {
        form.meta_fields.fields.forEach((field, fieldIndex) => {
          questionIndex++;
          stepInfo[questionIndex] = formIndex;
        });
      });
    }
    stepInfo[questionIndex + 1] = props.globalForm.length;
    setSteps(stepInfo);
  };

  const getWizardMeta = () => {
    let wizardMeta = {
      steps: [],
    };
    let reviewFields = [];
    if (props.globalForm) {
      props.globalForm.forEach((form, formIndex) => {
        form.meta_fields.fields.forEach((field, fieldIndex) => {
          if (field["widgetLabel"] === "signature") {
            field["widget"] = SignaturePad;
          }
          wizardMeta.steps.push({
            formMeta: {
              columns: 1,
              fields: [field],
            },
          });
        });
        reviewFields.push(
          {
            key: "review" + formIndex,
            colSpan: isMobile ? 1 : 2,
            render() {
              return (
                <fieldset>
                  <legend>{form.description}</legend>
                </fieldset>
              );
            },
          },
          ...form.meta_fields.fields
        );
      });
      wizardMeta.steps.push({
        key: "review",
        title: "Review",
        formMeta: {
          columns: isMobile ? 1 : 2,
          fields: reviewFields,
        },
      });
    }
    setNewWizardMeta(wizardMeta);
    setStepsLength(wizardMeta.steps.length);
  };

  useEffect(() => {
    if (props.globalForm) {
      getStepInfo();
      getWizardMeta();
    }
  }, [props.globalForm]);

  const handleNext = () => {
    form.validateFields().then(() => {
      setCurrentStep(currentStep + 1);
    });
  };
  const handleBack = () => {
    form.validateFields().then(() => {
      setCurrentStep(currentStep - 1);
    });
  };

  const handleFinish = useCallback(() => {
    console.log("Submit: ", form.getFieldsValue(true));
  }, [form]);

  const handleSubmitGlobalForm = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const renderStep = () => {
    return (
      <Steps current={steps[currentStep]} className="form-step">
        {props.globalForm.map((form) => (
          <Step key={form.description} title={form.description} />
        ))}
        <Step key="Review" title="Review" />
      </Steps>
    );
  };

  const changeIsSimpleForm = () => {
    setIsSimpleForm(isSimpleForm ? false : true);
  };

  return (
    <EditFieldModalWrapper
      className="preview-form-modal"
      title="Preview Form"
      visible={props.isVisiblePreviewModal}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} className="cancel-button">
          Cancel
        </Button>,
      ]}
    >
      <Switch
        className="form-type-switch"
        checkedChildren="Simple form"
        unCheckedChildren="Advanced form"
        checked={isSimpleForm}
        onChange={changeIsSimpleForm}
      />
      {isSimpleForm ? (
        <Form layout="vertical" className="preview-form-area">
          {renderFormBuilder(props.globalForm)}
          <Form.Item className="form-footer">
            <Button type="primary" className="btn-success">
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form
          form={form}
          onValuesChange={forceUpdate}
          layout="vertical"
          className="preview-form-area advanced-form"
          onFinish={handleFinish}
        >
          {renderStep()}
          {newWizardMeta.steps && newWizardMeta.steps.length && (
            <FormBuilder
              form={form}
              meta={newWizardMeta.steps[currentStep].formMeta}
            />
          )}

          <Form.Item className="form-footer" style={{ textAlign: "right" }}>
            {currentStep > 0 && (
              <Button className="btn-back" onClick={handleBack}>
                Back
              </Button>
            )}
            <Button
              type="primary"
              onClick={
                currentStep === stepsLength - 1
                  ? () => form.submit()
                  : handleNext
              }
            >
              {currentStep === stepsLength - 1 ? "Submit" : "Next"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </EditFieldModalWrapper>
  );
};

export default PreviewFormModal;
