export const visitorTypes = [
  {
    label: 'Visitor',
    value: 'visitor',
  },
  {
    label: 'Contractor',
    value: 'contractor',
  },
  {
    label: 'Seed Representative',
    value: 'seed_representative',
  },
  {
    label: 'Courier', 
    value: 'courier',
  },
  {
    label: 'Student',
    value: 'student',
  },
  {
    label: 'Truck Deliveries', 
    value: 'truck_deliveries',
  },
  {
    label: 'Other',
    value: 'other',
  }
];