import React from 'react';

const WeatherArea = () => {
  return (
    <div className="sidebar-icon">
      <svg
       className="sidebar-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.27 18.68C19.28 19.59 17.98 20.09 16.63 20.08H5.54C0.870003 19.74 0.860003 12.94 5.54 12.6H5.59C2.89 5.06 12.12 1.34 16 6.03V6.04C16.7 6.9 17.23 8.04 17.47 9.49C18.8 9.66 19.88 10.31 20.65 11.23C22.4 13.3 22.55 16.68 20.27 18.68Z"

          style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
        />
        <path
          d="M7.26003 13.01C6.74003 12.75 6.17003 12.61 5.59003 12.6"
          style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
        />
        <path
          d="M15.85 9.92C16.37 9.66 16.94 9.52 17.52 9.51"
          style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
        />
        <path
          d="M21.97 8.5C21.97 9.6 21.46 10.59 20.65 11.23C19.88 10.31 18.8 9.66 17.47 9.49C17.23 8.04 16.7 6.9 16 6.04V6.03C16.63 5.39 17.51 5 18.47 5C20.4 5 21.97 6.57 21.97 8.5Z"
          style={{strokeWidth:'1.5', strokeLinecap:'round',strokeLinejoin:'round'}}
        />
      </svg>
    </div>
  );
}
export default WeatherArea;