import React, {useState, useEffect } from 'react';
import DeviceTableWrapper from './DeviceTable.styles'; 
import { Row, Col, Button, Input, Space, Select, Dropdown, Menu, Popconfirm, Form, Alert, Switch } from 'antd';
import {iotDevice,propertyService} from '@iso/services';
import {device} from '../../../services/device.service';
import {deviceFactory} from '../../../services/devicefactory.service';
import _ from 'lodash';
import IntlMessages from '@iso/components/utility/intlMessages';
import { PlusOutlined, EllipsisOutlined,DeleteOutlined, UserOutlined, SettingOutlined, SoundOutlined } from '@ant-design/icons';
import AssignModal from './AssignModal';
import DeviceTimeModal from './DeviceTimeModal';
import StopDeviceAlarmRequestModal from './StopDeviceAlarmRequestModal';
import Table from "@iso/components/collapsing-table/table";
import AddDeviceModal from './AddDeviceModal'
import ConfigureModal from './ConfigureModal';
const { Option } = Select;

const DeviceTable = (props) => {
  const [dataDevice, setDataDevice] = useState([]);
  const [dataDeviceByProperty, setDataDeviceByProperty] = useState([]);
  const [deviceId, setDeviceId] = useState();
  const [loading, setLoading] = useState(false);
  const propertyId = props.propertyId;
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [isSaveAssign, setIsSaveAssign] = React.useState(false);
  const [assignedDevice, setAssignedDevice] = useState();
  const [isShowAssignModal, setIsShowAssignModal] = useState(false);
  const [isShowDeviceTimeModal, setIsShowDeviceTimeModal] = useState(false);
  const [isVisibleStopAlarmRequestModal, setIsVisibleStopAlarmRequestModal] = useState(false);
  const [stopAlarmRequests, setStopAlarmRequests] = useState();
  const [isShowAddDeviceModal, setIsShowAddDeviceModal] = useState(false);
  const [visibleConfigure, setVisibleConfigure] = useState(false);

  useEffect(() => {
    if (propertyId) {
      getDeviceByProperty();
      getDevice();
    }
  }, [propertyId, isSaveAssign]);

  const getDevice = () => {
    iotDevice.getList().then((res) => {
      if (res.code === "0000") {
        setDataDevice(res.devices);
      }
    });
  };

  const getDeviceByProperty = () => {
    device.getList(propertyId).then((res) => {
      if (res.code === "0000") {
        setDataDeviceByProperty(res.devices);
       
      } else {
        setDataDeviceByProperty([]);
      }
    });
  };
  
  const searchDevice = (s) => {
    iotDevice.search(propertyId, s).then((res) => {
      if (res.code === "0000") {
        setDataDeviceByProperty(res.devices);
      }
    });
  };

  const handleChangeDevice = (value) => {
    setDeviceId(value);
  };

  const cancelStopAlarmModal = () => {
    setIsVisibleStopAlarmRequestModal(false)
    setStopAlarmRequests([])
  }
  const onAddDevice = (value) => {
    setLoading(true);
    setShowAlert(false);

    if(value.code === '0000'){
      getDeviceByProperty();
      getDevice();
      setDeviceId();
      props.getPositionDevice();
      setLoading(false);
      setMessage(value.message);
    }else{
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      setMessage(value.message);
      setLoading(false);
    } 
  };

  const onSearchDevice = (e) => {
    if (e.target.value.length > 0) {
      searchDevice(e.target.value);
    } else {
      getDeviceByProperty();
    }
  };

  const renderDeviceSelect = () => {
    let options = [];
    let deviceName = [];
    _.forEach(dataDevice, (device, index) => {
      options.push(
        <Option label={device.name} value={device.device_id}>
          <div className="demo-option-label-item">{device.name}</div>
        </Option>
      );
      deviceName.push(device.name);
    });
    return (
      <Select
        mode="multiple"
        className="ant-select-device"
        placeholder="Select Devices"
        optionLabelProp="label"
        onChange={handleChangeDevice}
        value={deviceId}
        size="large"
        style={{ width: "100%" }}
        allowClear
      >
        {options}
      </Select>
    );
  };

  const menu = (device) => (
    <div>
      <Menu>
        <Menu.Item key="removeDevice">
          <Popconfirm
            // className="ant-popconfirm"
            placement="bottomRight"
            title="You are about to remove individual record"
            onConfirm={() => {
              confirmRemove(device.id, device.type);
            }}
            okText="Yes"
            cancelText="No"
            className="delete-button"
          >
            <a>
              {<DeleteOutlined />}&nbsp;&nbsp;
              <IntlMessages id="safeFarmPage.table.remove" />
            </a>
          </Popconfirm>
        </Menu.Item>

        <Menu.Item
          key="assignUser"
          icon={<UserOutlined />}
          onClick={(e) => handleAssignUser(device)}
        >
          <IntlMessages id="safeFarmPage.table.assign" />
        </Menu.Item>

        {/* <Menu.Item
          key="deviceTime"
          icon={<SettingOutlined />}
          onClick={(e) => handleSetDeviceTime(device)}
          disabled={device.type === 'zoleo_device'}
        >
          <IntlMessages id="safeFarmPage.table.deviceTime" />
        </Menu.Item> */}
        <Menu.Item
          key="showStopAlarmRequest"
          icon={<SoundOutlined />}
          onClick={(e)=>handleShowStopAlarmRequests(device?.stop_device_alarm_requests)}
          disabled={device.type === 'zoleo_device'}
        >
          <IntlMessages id="safeFarmPage.table.stopAlarmRequests" />
        </Menu.Item>

        <Menu.Item
          key="configure "
          icon={<SettingOutlined />}
          onClick={(e)=>handleConfigure(device)}
        >
          <IntlMessages id="safeFarmPage.table.configure" />
        </Menu.Item>
       
    </Menu>
    </div>
  );

  const handleConfigure = (device) => {
    setAssignedDevice(device);
    setVisibleConfigure(true)
  }

  const cancelConfigureModal = () => {
    setAssignedDevice({});
    setVisibleConfigure(false);
  }

  const handleShowStopAlarmRequests = (stopRequests) => {
    setIsVisibleStopAlarmRequestModal(true);
    setStopAlarmRequests(stopRequests);
  }
  const confirmRemove = (id, type) => {
    deviceFactory.resolveService(type).destroy(id).then((res) => {
      if (res.code === "0000") {
        getDeviceByProperty();
        getDevice();
        props.getPositionDevice();
      }
    });
  };
  const onChangeAlarmStatus = (id, status) => {
    iotDevice.updateAlarmStatus(id, status).then(()=>{
      getDeviceByProperty()
    })
  }

  const columnsTable = [
    {
      label: <IntlMessages id="safeFarmPage.table.name" />,
      accessor: "name",
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        const [userName, setUserName] = useState('');
        const asset = cell.row;
  
        useEffect(() => {
          const fetchUserName = async () => {
            try {
              const res = await propertyService.getListUsersOfProperty(props.propertyId);
              if (res.code === '0000') {
                const user = res.users?.find((e) => e.id === asset.user_id);
                if (user) {
                  setUserName(user.name);
                }
              }
            } catch (error) {
              console.error("Error fetching user name:", error);
            }
          };
  
          fetchUserName();
        }, [asset.user_id, props.propertyId]);
  
        return (
          <span>
            {asset.name} <b>{userName && `(${userName})`}</b>
          </span>
        );
      },
    },
    {
      label: <IntlMessages id="Unique_ID" />,
      accessor: "unique_id",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: 'Type',
      accessor: "type_label",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: 'Alarm Status',
      accessor: 'alarm_status',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: (cell) => {
        let asset = cell.row;
        const checked = asset.is_trigger_alarm === 1 ? true : false;
        return (
          <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              disabled={asset.type === 'zoleo_device'}
              onChange={() => onChangeAlarmStatus(asset.id, !asset.is_trigger_alarm)}
              defaultChecked={checked}
            />
        )
      },
    },
    {
      label: "Battery Level",
      accessor: "battery_level",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let record = cell.row;
        return (
        <div className='battery-level'>
          {record.battery_level}%
        </div>
        );
        }
    },
    {
      label: "Actions",
      accessor: "",
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      noTitle: true,
      CustomComponent: (cell) => {
        let record = cell.row;
        
        return (
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon" />
            </div>
          </Dropdown>
        
        );
      },
    },
  ];

  const cancelModal = () => {
    setIsShowAssignModal(false);
    setAssignedDevice({});
  };

  const cancelDeviceTimelModal = () => {
    setIsShowDeviceTimeModal(false);
    setAssignedDevice({});
  };

  const onSaveAssign = () => {
    setIsSaveAssign(!isSaveAssign);
  };

  const handleAssignUser = (device) => {
    setAssignedDevice(device);
    setIsShowAssignModal(true);
  };

  const handleSetDeviceTime = (device) => {
    setAssignedDevice(device)
    setIsShowDeviceTimeModal(true)
  };

  const cancelAddDeviceModal = () => {
    setIsShowAddDeviceModal(false);
  };

  return (
    <DeviceTableWrapper>
      <Row gutter={[10, 15]}>
        <Col>
          {" "}
          <h1 className="title">
            <IntlMessages id="safeFarmPage.device" />
          </h1>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }} className="columnCust">
          <Input
            className="ant-device-search"
            placeholder="Search Name"
            allowClear
            onChange={onSearchDevice}
          />
        </Col>
        <Col xs={{span: 24}} md={{span: 12}}>
          <Button onClick={() => setIsShowAddDeviceModal(true)} loading={loading} className="ant-btn-add-device" icon={<PlusOutlined />}>
            <IntlMessages id="propertyMapPage.add" />
          </Button>
        </Col>
        {/* </div> */}
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <div className="manage-table">
          {dataDeviceByProperty && dataDeviceByProperty?.length > 0 ?
            <Table
              rows={dataDeviceByProperty}
              columns={columnsTable}
              size="small"
              pagination={{ pageSize: 12 }}
              rowSize={99999}
              bordered
              className="manage-table"
              rowClassName="rowClassName1"
            />:
            <h1 style={{ padding: "15px 15px 15px 10px",fontSize:'16px', color: "gray" }}>
              <IntlMessages id="No data Found" />
            </h1>
          }
          </div>
        </Col>
      </Row>

      <AssignModal propertyId={propertyId} assignedIotDevice={assignedDevice} visible={isShowAssignModal} cancelModal={cancelModal} onSaved={onSaveAssign}/>
      <DeviceTimeModal propertyId={propertyId} assignedIotDevice={assignedDevice} visible={isShowDeviceTimeModal} cancelModal={cancelDeviceTimelModal} onSaved={onSaveAssign}/>
      <StopDeviceAlarmRequestModal stopAlarmRequests={stopAlarmRequests} visible={isVisibleStopAlarmRequestModal} cancelModal={cancelStopAlarmModal}/>
      <AddDeviceModal visible={isShowAddDeviceModal} cancelAddDeviceModal={cancelAddDeviceModal} propertyId={propertyId} onSaved={onAddDevice}/>
      <ConfigureModal getDeviceByProperty = {getDeviceByProperty} visibleConfigure = {visibleConfigure} assignedDevice = {assignedDevice} cancelConfigureModal = {cancelConfigureModal}/>
    
    </DeviceTableWrapper>
  );
};

export default DeviceTable;