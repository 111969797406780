import React from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Card,
} from "antd";
import _ from "lodash";
import IntlMessages from "@iso/components/utility/intlMessages";
import SignaturePad from "@iso/components/SignaturePad/index";

const { TextArea } = Input;

const ForManager = (props) => {
  return (
    <Card>
      <h3 className="form-title">
        <IntlMessages id="illnessForm.forManager.title" />
      </h3>
      <Form.Item
        name={["for_manager", "has_reported"]}
        label={<IntlMessages id="illnessForm.forManager.hasAnInvestigation" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input size="large" placeholder="Name" />
      </Form.Item>
      <Form.Item
        name={["for_manager", "controls_implemented"]}
        className="isoInputWrapper"
        label={<IntlMessages id="illnessForm.forManager.controlsImplemented" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name={["for_manager", "employer_confirmation"]}
        className="isoInputWrapper"
        label={<IntlMessages id="illnessForm.forManager.employerConfirmation" />}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return prevValues["for_manager"];
        }}
      >
        {({ getFieldValue }) => {
          return (
            <label>{getFieldValue(['worker_detail', 'first_name'])}</label>
          );
        }}
      </Form.Item>
      <label style={{ marginBottom: '24px', display: 'block' }}><IntlMessages id="illnessForm.forManager.confirmReceipt" /></label>
      <Form.Item
        name={["for_manager", "signature"]}
        label="Signature"
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <SignaturePad tableName={"Signature"}></SignaturePad>
      </Form.Item>
      <Form.Item
        name={["for_manager", "date"]}
        className="isoInputWrapper"
        label={"Date"}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <DatePicker size="large" format="YYYY-MM-DD" />
      </Form.Item>
    </Card>
  );
};

export default ForManager;
