import styled from "styled-components";

export const QueenslandBiosecurityTableWrapper = styled.div`
  .header-table{
    margin: 30px 10px;
    p{
      font-weight: 500;
    }
  }
`;
