import React from "react";
import { Form, Input, Table, Checkbox } from "antd";
import { QueenslandBiosecurityTableWrapper } from "./QueenslandBiosecurityTableWrapper.style";
import BioCheckbox from "../Components/BioCheckbox";
import SignaturePad from '@iso/components/SignaturePad/index';

const QueenslandBiosecurityTable = (props) => {
  const columns = [
    {
      title: "7",
      dataIndex: "title",
      rowScope: "row",
      onCell: (_, index) => {
        return { colSpan: 2 };
      },
      width: 50,
    },
    {
      title: "QUEENSLAND BIOSECURITY MANAGEMENT PLAN REQUIREMENTS",
      dataIndex: "label",
      rowScope: "row",
      colSpan: 2,
      onCell: (_, index) => {
        return { colSpan: 0 };
      },
      width: "90%",
    },
    {
      title: "",
      colSpan: 0,
      dataIndex: "action",
      rowScope: "row",
    },
  ];

  const data = [
    {
      key: "1",
      label: "",
      title: "I DECLARE:",
      action: "",
    },
    {
      key: "2",
      label: "",
      title:
        "This is a biosecurity management plan in accordance with Section 41B of the Queensland Biosecurity Regulation 2016.",
      action: <BioCheckbox name="queensland_biosecurity_1"></BioCheckbox>,
    },
    {
      key: "3",
      label: "",
      title:
        "The purpose of this plan is to state the measures to prevent, control or stop the spread of biosecurity matter into, at, or from the management areas as defined in the biosecurity management plan, pursuant to the Queensland Biosecurity Regulation 2016.",
      action: <BioCheckbox name="queensland_biosecurity_2"></BioCheckbox>,
    },
    {
      key: "4",
      label: "",
      title:
        "Signage is in place at the entry to all areas covered by this biosecurity management plan to instruct visitors to contact the person and sign in either in a visitor logbook or using the Farm Check-In app.",
      action: <BioCheckbox name="queensland_biosecurity_3"></BioCheckbox>,
    },
    {
      key: "5",
      label: "",
      title: "This plan is available to all visitors during business hours",
      action: <BioCheckbox name="queensland_biosecurity_4"></BioCheckbox>,
    },
  ];

  const handleChangeSignature = (signatures) => {
    console.log('YEAJ')
    console.log(signatures)
  }

  const producer_signatures = props.form.getFieldValue('producer_signatures');

  return (
    <QueenslandBiosecurityTableWrapper>
      <div className="header-table">
        <p>QUEENSLAND PRODUCERS, please complete this section.</p>
      </div>
      <Table
        className="bio-table"
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        rowClassName={(record, index) => index === 4 && "last-row"}
      />
      <Form.Item name="producer_signatures">
         <SignaturePad tableName={'Producer Name'}></SignaturePad>
      </Form.Item>
    </QueenslandBiosecurityTableWrapper>
  );
};

export default QueenslandBiosecurityTable;
