import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Row, Col, Alert, Rate, Ratio, Switch, message } from 'antd';
import { FormBuilderWrapper } from './FormBuilder.styles'
import { useSelector } from 'react-redux';
import FormBuilder from 'antd-form-builder';
import Toolbox from './Toolbox';
import EditFieldModal from './EditFieldModal';
import PreviewFormModal from './PreviewFormModal';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { globalFormService } from '@iso/services'
import $ from 'jquery';
import { commonHelper } from '@iso/lib/helpers/commonHelper';
import SignaturePad from '@iso/components/SignaturePad/index';
import _ from 'lodash';
import DragDropQuestion from "@iso/components/DragDropQuestion/DragDropQuestion";

const FormBuilderPage = (props) => {

    const currentUser = useSelector((state) => state.user.user);
    const [formFields, setFormFields] = useState({
        columns: 1,
        formItemLayout: null, 
        fields: []
    });
    const [globalForm, setGlobalForm] = useState({});
    const [isVisibleFieldModal, setIsVisibleFieldModal] = useState(false);
    const [isVisiblePreviewModal, setIsVisiblePreviewModal] = useState(false);
    const [fieldKey, setFieldKey] = useState('');
    const [fieldData, setFieldData] = useState({});
    const formId = queryString.parse(window.location.search).form_id || '';
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    const getFormData = (formId) => {
        globalFormService.viewForm(formId).then(res => {
            if(res.code === '0000' && res.global_form){
                if(res.global_form.meta_fields){
                    let metaFields = res.global_form.meta_fields
                    let fields = _.clone(metaFields.fields)
                    for(let i in fields){
                        if(fields[i]['widgetLabel'] && fields[i]['widgetLabel'] === 'signature'){
                            fields[i]['widget'] = SignaturePad
                            fields[i]['initialValue'] = {
                                signatures: []
                            }
                        }else if(fields[i]['widgetLabel'] && fields[i]['widgetLabel'] === 'dragdrop'){
                            fields[i]['widget'] = DragDropQuestion
                        }
                        else{
                            fields[i]['widgetLabel'] = fields[i]['widget']
                        }
                    }
                    metaFields.fields = fields
                    setFormFields(metaFields);
                }
                setGlobalForm(res.global_form);
            }
        })
    }

    useEffect(() => {
        if(formId && formId !== '')
        {
            getFormData(formId)
        }
    }, [])

    $('.form-builder').on('click', '.ant-form-item-row', function() {
        var fieldItem = formFields.fields.filter((item) => {
            return item.label === $(this).data('label');
        })

        setFieldData(fieldItem[0]);
        showFieldModal();
    })

    const formRef = React.createRef()
  const handleFinish = evt => {
    console.log('submit: ', formRef.current.getFieldsValue())
  }
    const resetFormFields = (fields) => {
        setFormFields(fields);
    }

    const showFieldModal = () => {
        setIsVisibleFieldModal(true);
    }

    const cancelFieldModal = () => {
        setIsVisibleFieldModal(false);
        setFieldKey('');
        setFieldData({});
    }

    const showPreviewModal = () => {
        setIsVisiblePreviewModal(true);
    }

    const cancelPreviewModal = () => {
        setIsVisiblePreviewModal(false);
    }
    
    const changeFormStatus = (value) => {
        const data = {
            global_form: {
                is_live: value ? 1 : 0
            }
        }
        globalFormService.storeOrUpdate(data, formId).then(res => {
            if(res.code === '0000' && res.global_form){
                setGlobalForm(res.global_form);
                if(res.global_form.is_live == 1)
                {
                    message.success('Form lived')
                }else{
                    message.success('Form set draft')
                }
            }
        })
    }

    return (
        <FormBuilderWrapper>
            <div className="homie-container">
                <div className="message">
                    <Row>
                        <Col xs={24} sm={{span: 17}} className="box-shadow">
                            <Row style={{width: '100%'}} className="form-header">
                                <Col xs={{span: 24}} md={{span: 10}}>
                                    <div className="header-content">
                                        <h3>Form Builder</h3>
                                        {formId && formId !== '' && <Switch checkedChildren="Live" unCheckedChildren="Draft" checked={globalForm?.is_live == 1 ? true : false} onChange={changeFormStatus}/>}
                                    </div>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 12, offset: 2}}>
                                    <Button type="primary" className="float-right btn-success" onClick={showPreviewModal}>
                                        Preview Form
                                      </Button>
                                </Col>
                            </Row>
                            <hr/>
                            <Form
                                ref={formRef}
                                layout="vertical"
                                onFinish={handleFinish}
                                className="form-builder"
                              >
                                <FormBuilder meta={formFields} form={formRef}/>
                              </Form>
                        </Col>
                        <Col xs={24} sm={{span: 6, offset: 1}}>
                            <Toolbox showFieldModal={showFieldModal} setFieldKey={setFieldKey}/>
                        </Col>
                    </Row>
                </div>
            </div>
            <EditFieldModal isVisibleFieldModal={isVisibleFieldModal} cancelFieldModal={cancelFieldModal} 
            fieldKey={fieldKey} resetFormFields={resetFormFields} formFields={formFields} fieldData={fieldData}/>
            <PreviewFormModal isVisiblePreviewModal={isVisiblePreviewModal} cancelPreviewModal={cancelPreviewModal} 
            formFields={formFields} globalForm={globalForm} getFormData={getFormData} readOnly={false}/>
        </FormBuilderWrapper>
    )
}

export default FormBuilderPage;
