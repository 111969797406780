import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import ProductCategoryModalWrapper from './ProductCategoryModal.styles';
import { Form, Input, Button, Select, Row, Col } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { marketplaceCategoryService } from '@iso/services';
import { productCategoryTypes } from '@iso/constants/productCategoryTypes';
import _ from 'lodash';

const { Option } = Select;

const ProductCategoryModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productCategoryDetail, setProductCategoryDetail] = useState({});

  const cancelModal = () => {
    props.cancelProductCategoryModal();
    form.resetFields();
    setLoading(false);
  }

  const renderOptionsCategoryType = () => {
    let options = [];
    _.forEach(productCategoryTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  useEffect(() => {
    if(props.productCategoryData)
    {
      setProductCategoryDetail(props.productCategoryData);

      setFields([
          {
            name: ['marketplace_category', 'name'],
            value: productCategoryDetail.name || '',
          },
          {
            name: ['marketplace_category', 'object_type'],
            value: productCategoryDetail.object_type || '',
          }
      ]);
    }
  }, [props.visibleProductCategoryModal, props.productCategoryData, productCategoryDetail])

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const data = {
      marketplace_category: {
        ...values.marketplace_category,
      }
    }

    marketplaceCategoryService.storeOrUpdate(data, productCategoryDetail.id).then(res => {
      if(res.code === '0000'){
        cancelModal();
        props.getProductCategories();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }

  return(
    <ProductCategoryModalWrapper
      visible={props.visibleProductCategoryModal}
      onCancel={cancelModal}
      maskClosable={false}
      title={productCategoryDetail && productCategoryDetail.id ? <IntlMessages id="adminPage.manageProductCategory.editProductCategory"/> : <IntlMessages id="adminPage.manageProductCategory.addProductCategory"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
      >
        <div className="form-body">
          <Form.Item
            name={['marketplace_category', 'name']}
            className="isoInputWrapper"
            label={<IntlMessages id="adminPage.manageProductCategory.name"/>}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input size="large" placeholder="Input title"/>
          </Form.Item>
          <Form.Item
            name={['marketplace_category', 'object_type']}
            className="isoInputWrapper"
            label={<IntlMessages id="adminPage.manageProductCategory.type" />}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
          {renderOptionsCategoryType()}
          </Form.Item>
        </div>    
      </Form>
    </ProductCategoryModalWrapper>
  )
}

export default ProductCategoryModal;