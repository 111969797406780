import React, { useState, useEffect, useCallback } from 'react';

import { PlusOutlined, DownOutlined, EditOutlined, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';

const PropertyTableFirstCell = (props) => {
  const property = props.property;  
  const activePropertyId = props.activePropertyId;  
  return (
    property ? 
    <div id={property.id}>
      <span style={{"margin-right": '5px'}}>{property.name}</span>
      {
        activePropertyId === property.id &&
        <CheckCircleOutlined />
      }
    </div>
    : <></>
  );
}

export default PropertyTableFirstCell;