import React from "react";
import { Form, Input, Table, Checkbox } from "antd";
import BioCheckbox from "../Components/BioCheckbox";
const { TextArea } = Input;

const LivestockAndFeedTable = (props) => {
  const columns = [
    {
      title: "1",
      dataIndex: "order",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 3, 7, 10, 12].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 4, 8, 11, 13].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "INPUTS – LIVESTOCK AND FEED",
      dataIndex: "livestock_and_feed",
      rowScope: "row",
      colSpan: 7,
      onCell: (_, index) => {
        if ([1, 3, 7, 10, 12].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 4, 8, 11, 13].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
      width: 500,
    },
    {
      title: "Yes",
      colSpan: 0,
      dataIndex: "yes",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 3, 7, 10, 12].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 4, 8, 11, 13].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "No",
      colSpan: 0,
      dataIndex: "no",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 3, 7, 10, 12].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 4, 8, 11, 13].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "N/A",
      colSpan: 0,
      dataIndex: "na",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 3, 7, 10, 12].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 4, 8, 11, 13].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "Recommended measures ",
      colSpan: 0,
      dataIndex: "recommended_measures",
      rowScope: "row",
      width: 500,
    },
    {
      title: "Actioned ?",
      colSpan: 0,
      dataIndex: "actioned",
      rowScope: "row",
    },
    {
      title: "Comments",
      colSpan: 0,
      dataIndex: "comments",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 3, 7, 10, 12].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 4, 8, 11, 13].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
      className: "comment-column",
    },
  ];

  const data = [
    {
      key: "1",
      order: "1.1",
      livestock_and_feed: "Livestock",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures ",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "2",
      order: "1.1.1",
      livestock_and_feed:
        "Are all stock that arrive on the property (including livestock travelling back to the property from shows, agistment or contract joining) checked for their health status?",
      yes: <BioCheckbox name="livestock_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="livestock_1_no"></BioCheckbox>,
      na: <BioCheckbox name="livestock_1_na"></BioCheckbox>,
      recommended_measures:
        "Pre-purchase inspection for introduced stock conducted.",
      actioned: <BioCheckbox name="livestock_1_1_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="livestock_1_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "3",
      order: "",
      livestock_and_feed: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Hygiene and quarantine strategies in place to manage livestock returning to the property.",
      actioned: <BioCheckbox name="livestock_1_2_actioned"></BioCheckbox>,
      comments: "",
    },
    {
      key: "4",
      order: "1.1.2",
      livestock_and_feed:
        "Are all introduced livestock accompanied with information on animal treatments and is a health status provided via a National Vendor Declaration (NVD) and Animal Health Declaration (AHD)?",
      yes: <BioCheckbox name="livestock_2_yes"></BioCheckbox>,
      no: <BioCheckbox name="livestock_2_no"></BioCheckbox>,
      na: <BioCheckbox name="livestock_2_na"></BioCheckbox>,
      recommended_measures: "NVDs received for all purchased livestock.",
      actioned: <BioCheckbox name="livestock_2_1_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="livestock_2_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "5",
      order: "",
      livestock_and_feed: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "AHD obtained for further information on livestock health, where required",
      actioned: <BioCheckbox name="livestock_2_2_actioned"></BioCheckbox>,
      comments: "",
    },
    {
      key: "6",
      order: "1.1.3",
      livestock_and_feed:
        "Do all introduced livestock (including livestock travelling back to the property from shows, agistment or contract joining) undergo a period of quarantine where practical?",
      yes: <BioCheckbox name="livestock_3_yes"></BioCheckbox>,
      no: <BioCheckbox name="livestock_3_no"></BioCheckbox>,
      na: <BioCheckbox name="livestock_3_na"></BioCheckbox>,
      recommended_measures:
        "Where practical, newly introduced livestock are segregated, observed and treated.",
      actioned: <BioCheckbox name="livestock_3_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="livestock_3_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "7",
      order: "1.1.4",
      livestock_and_feed:
        "Do all introduced livestock have sufficient time to empty out in the yards prior to their release?",
      yes: <BioCheckbox name="livestock_4_yes"></BioCheckbox>,
      no: <BioCheckbox name="livestock_4_no"></BioCheckbox>,
      na: <BioCheckbox name="livestock_4_na"></BioCheckbox>,
      recommended_measures:
        "Livestock given 24-48 hours holding for empty out (including any time off feed before arrival).",
      actioned: <BioCheckbox name="livestock_4_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="livestock_4_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "8",
      order: "1.1.5",
      livestock_and_feed:
        "Are all incoming livestock identified and recorded in accordance with NLIS requirements?",
      yes: <BioCheckbox name="livestock_5_yes"></BioCheckbox>,
      no: <BioCheckbox name="livestock_5_no"></BioCheckbox>,
      na: <BioCheckbox name="livestock_5_na"></BioCheckbox>,
      recommended_measures:
        "Livestock are checked for identification on arrival.",
      actioned: <BioCheckbox name="livestock_5_1_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="livestock_5_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "9",
      order: "",
      livestock_and_feed: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "When receiving livestock, confirm the NLIS transfer is completed within 48 hours.",
      actioned: <BioCheckbox name="livestock_5_2_actioned"></BioCheckbox>,
      comments: "",
    },
    {
      key: "10",
      order: "1.2",
      livestock_and_feed: "Feed",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "11",
      order: "1.2.1",
      livestock_and_feed:
        "Is stock feed inspected on delivery to ensure it is fit for purpose (e.g. free from pest damage and visual contaminants)? If damaged or contaminated, is there a plan in place for its return or disposal?",
      yes: <BioCheckbox name="feed_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="feed_1_no"></BioCheckbox>,
      na: <BioCheckbox name="feed_1_na"></BioCheckbox>,
      recommended_measures:
        "Stock feed inspected on arrival and checked to ensure it matches what was ordered.",
      actioned: <BioCheckbox name="feed_1_1_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="feed_1_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "12",
      order: "",
      livestock_and_feed: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "If stock feed is spoilt, feed is returned or a disposal plan is implemented.",
      actioned: <BioCheckbox name="feed_1_2_actioned"></BioCheckbox>,
      comments: "",
    },
    {
      key: "13",
      order: "1.2.2",
      livestock_and_feed:
        "Is stock feed stored in a manner that prevents contamination by livestock, vermin, wildlife, feral and domestic animals and other feed types e.g. those containing Restricted Animal Material (RAM)?",
      yes: <BioCheckbox name="feed_2_yes"></BioCheckbox>,
      no: <BioCheckbox name="feed_2_no"></BioCheckbox>,
      na: <BioCheckbox name="feed_2_na"></BioCheckbox>,
      recommended_measures:
        "RAM and non-RAM products are segregated to minimise accidental feeding.",
      actioned: <BioCheckbox name="feed_2_1_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="feed_2_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "14",
      order: "",
      livestock_and_feed: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Livestock feed is stored protected from vermin, wildlife and pests, where practical.",
      actioned: <BioCheckbox name="feed_2_2_actioned"></BioCheckbox>,
      comments: "",
    },
  ];

  return (
    <Table
      className="bio-table"
      columns={columns}
      dataSource={data}
      bordered
      pagination={false}
    />
  );
};

export default LivestockAndFeedTable;
