import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const workingRightService = {
  getList,
  storeOrUpdate,
  getCheckinFormUserWorkingRight,
  destroy
};

function getList(){
  return (
    axios
      .get(
        `${apiEndpoint.workingRights}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getCheckinFormUserWorkingRight(form_id, userId){
  return (
    axios
      .get(
        `${apiEndpoint.workingRights}/get-checkin-form-user-working-right?form_id=${form_id}&user_id=${userId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.workingRights(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.workingRights,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function destroy(id) {
  return (
    axios
      .delete(
        apiEndpointFunction.workingRights(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if (res && res.status === 200) {
          return res.data;
        }
      })
  );
}