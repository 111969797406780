import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import MergeMobModalWrapper from './MergeMobModal.styles';
import { Form, Row, Col, Button, DatePicker, Select, Radio, Popconfirm } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '@iso/components/utility/loader';
import { mobService } from '@iso/services';
import moment from 'moment';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import _ from 'lodash';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const { Option } = Select;

const MergeMobModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [loading, setLoading] = useState(false);
  const [mobs, setMobs] = useState([]);
  const [checkingMobValue, setCheckingMobValue] = useState(false);

  useEffect(() => {
    setMobs(props.mobs)
  }, [props.mobs, props.visibleMergeMobModal])

  const cancelModal = () => {
    props.cancelMergeMobModal();
    setCheckingMobValue(false);
    form.resetFields();
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    if(values.mob.mob_A_id === values.mob.mob_B_id)
    {
      setCheckingMobValue('Mob A and Mob B cannot be the same!');
      setLoading(false);
      return false;

    }

    const data = {
      mob: {
        ...values.mob,
      }
    }

    mobService.mergeMob(data).then(res => {
      if(res.code === '0000'){
        props.updateMobData();
        cancelModal();
        form.resetFields();
        setLoading(false);
      } else {
        setCheckingMobValue(res.message);
        setLoading(false);
      }
    });
  }

  const renderOptionMob = (mobs) => {
    let options = [];
    _.forEach(mobs, (mob, index) => {
      options.push(
        <Option key={mob.id} value={mob.id}>
          {mob.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a mob"
        allowClear
        size="large"
        onChange={changeMobValue}
      >
        {options}
      </Select>
    );
  }

  const changeMobValue = (value) => {
    const selectedMob = _.find(mobs, (mob) => {
      return mob.id === value;
    });

    let new_mobs = [];
    if (selectedMob) {
      if(selectedMob.asset_total == 0){
        _.forEach(mobs, (mob, index) => {
          if(mob.asset_total == 0){
            new_mobs.push(mob);
          }
        })
      }else{
        _.forEach(mobs, (mob, index) => {
          if(mob.asset_total != 0){
            new_mobs.push(mob);
          }
        })
      }

      setMobs(new_mobs);
    } else {
      setMobs(props.mobs);
    }
  }

  // const onChangeRadio = e => {
  //   if(e.target.value == 0){
  //     setPrivateChecked(true);
  //   }else{
  //     setPrivateChecked(false);
  //   }
  // }

  return(
    <MergeMobModalWrapper
      visible={props.visibleMergeMobModal}
      onCancel={cancelModal}
      maskClosable={false}
      title={<IntlMessages id="mobPage.managePage.mergeMob"/>}
      footer={[
        <Button key="back" className="cancel-button" onClick={cancelModal}>
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && 
        <Popconfirm
          placement="bottomRight"
          title="Are you sure, merging mobs will remove history from selected mobs!"
          onConfirm={handleSubmit}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
          <Button key="submit" className="btn-success" type="primary" loading={loading}>
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>
        </Popconfirm>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
        className="form-body"
      >
        <Row>
          <Col xs={{span: 24}} md={{span: 11}}>
            <Form.Item
              name={['mob', 'mob_A_id']}
              className="isoInputWrapper"
              label={<IntlMessages id="mobPage.managePage.mergeMob.modal.mobA" />}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionMob(mobs)}
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
            <Form.Item
              name={['mob', 'mob_B_id']}
              className="isoInputWrapper"
              label={<IntlMessages id="mobPage.managePage.mergeMob.modal.mobB" />}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionMob(mobs)}
            </Form.Item>
          </Col>
        </Row>
        { (checkingMobValue) ? 
          <div className="ant-form-item-has-error">
            <div className="ant-form-item-explain">
                <div>{ checkingMobValue }</div>
            </div>
          </div> : ''}
        <Form.Item
          name={['mob', 'main_mob']}
          className="isoInputWrapper"
          label={<IntlMessages id="mobPage.managePage.mergeMob.modal.primary" />}
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Radio.Group>
            <Radio value={0}>Mob A</Radio>
            <Radio value={1}>Mob B</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </MergeMobModalWrapper>
  )
}

export default MergeMobModal;