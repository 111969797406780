import moment from 'moment';

const checkIsGoldPackage = (activePropertySubscription) => {
  if(activePropertySubscription) {
    if(activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_GOLD || 
      activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_GOLD_ANNUAL ||
      activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD ||
      activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD_YEARLY ||
      activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD ||
      activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD_YEARLY ||
      activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD ||
      activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD_YEARLY
    ){
      return true;
    }
  }

  return false;
};

const checkIsSilverPackage = (activePropertySubscription) => {
  if(activePropertySubscription) {
    if(activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_SILVER || 
      activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_SILVER_ANNUAL
    ){
      return true;
    }
  }

  return false;
};

const checkIsShowSatMap = (property, activePropertySubscription) => {
  if(!property){
    return false;
  }
  if(checkIsSilverPackage(activePropertySubscription) || checkIsGoldPackage(activePropertySubscription)){
    return true;
  }
  if(property.created_at){
    let createdAt = moment.utc(new Date(property.created_at));
    let now = moment.utc(new Date());
    let diff = now.diff(createdAt, 'days');
    if(diff <= 30){
      return true;
    }
  }
  return false;
};

export const propertyHelper = {
  checkIsGoldPackage,
  checkIsSilverPackage,
  checkIsShowSatMap
}