import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import modalActions from '@iso/redux/modal/actions';
import ColorModalWrapper from './ColorModal.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Form, Input, Select } from 'antd';
import { layerObjectService } from '@iso/services';
import styled from "styled-components";
import { objectColors } from '@iso/constants/objectColors';


const { closeModal } = modalActions;
const { Option } = Select;

const ColorModal = (props) => {
    const dispatch = useDispatch();
    const modalVisibility = useSelector((state) => state.modal.modalVisibility);
    const modalData = useSelector((state) => state.modal.modalData);
    const [colorForm] = Form.useForm();
    const [fields, setFields] = useState([]);
    const [loadingLayer, setLoadingLayer] = useState(false);

    const cancelModal = () => {
        colorForm.resetFields();
        dispatch(closeModal());
    }

    const handleChangeColorSubmit = () => {
        colorForm
            .validateFields()
            .then(colorValues => {
                setLoadingLayer(true);
                onUpdateColor(colorValues);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setLoadingLayer(false);
            });
    }

    const onUpdateColor = (values) => {

        layerObjectService.updateColor(values, modalData.layer_object_id).then(res => {
            if (res.code === '0000') {
                cancelModal();
                const layer = modalData.target;
                layer.setStyle({ color: res.layer_object.color });
                colorForm.resetFields();
            }
            setLoadingLayer(false);
        });

    }

    useEffect(() => {
      if(modalData){
        setFields([
          {
            name: ['layer_object', 'color'],
            value: modalData.old_color,
          },
        ]);
      }
    
    
    },[modalData]);

    const renderOptionsColor = () => {
        let options = [];
        _.forEach(objectColors, (color, index) => {
            options.push(
                <Option key={index} value={color.hexCode}>
                    <ColorItemRow>
                        <ColorItemPreview style={{ backgroundColor: color.color }}></ColorItemPreview>
                        <div>{color.label}</div>
                    </ColorItemRow>
                </Option>
            );
        })
        return (
            <Select
                placeholder="Select a color"
                allowClear
                size="large"
            >
                {options}
            </Select>
        );
    }

    useEffect(() => {
        if (modalData.type === 'changeColor') {

        }
    }, [modalData]);

    return (
        <ColorModalWrapper
            visible={modalVisibility && modalData.type === 'changeColor'}
            onCancel={cancelModal}
            maskClosable={false}
            centered
            // title={<IntlMessages id="propertyPage.modal.layer.addlayer.title" />}
            title={"Change Color"}
            footer={[
                <Button key="back" onClick={cancelModal} className="cancel-button">
                    {<IntlMessages id="propertyPage.modal.cancel" />}
                </Button>,
                <Button key="submit" onClick={handleChangeColorSubmit} loading={loadingLayer} className="btn-success" type="primary">
                    {<IntlMessages id="propertyPage.modal.save" />}
                </Button>,
            ]}
        >
            <Form
                form={colorForm}
                layout="vertical"
                scrollToFirstError
                fields={fields}
            >
                <div className="form-body">
                    <Form.Item
                        name={['layer_object', 'color']}
                        label="Color"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        {renderOptionsColor()}
                    </Form.Item>
                </div>
            </Form>
        </ColorModalWrapper>
    )
}

export default ColorModal;


const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;