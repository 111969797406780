import { Row, Col, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { message } from 'antd';
import AddSensorModal from './AddSensorModal';
import { sensorService } from '@iso/services';
import SensorList from './SensorList';

const SensorTab = (props) => {
    const [visibleSensorModal, setVisibleSensorModal] = useState(false);
    const [sensors, setSensors] = useState([]);

    useEffect(() => {
        getSensors();
    }, [props.assetId]);

    const handleOpenSensorModal = () => {
        setVisibleSensorModal(true);
    }

    const handleCloseSensorModal = () => {
        setVisibleSensorModal(false);
    }

    const handleAddSensor = () => {
        message.success(<IntlMessages id="propertyPage.mobModal.sensorTab.addSensorModal.success" />);

        setVisibleSensorModal(false);

        getSensors();
    }

    const getSensors = () => {
        sensorService.getAssetSensors(props.assetId).then(res => {
            if (res.code === '0000') {
                setSensors(res.sensors);
            } else {
                setSensors([]);
            }
        })
    }

    return (
        <>
            { sensors.length === 0 && <Row>
                <Col xs={{ span: 7 }} md={{ span: 7 }}>
                    <Button
                        key="submit"
                        className="btn-create-breed"
                        type="primary"
                        onClick={handleOpenSensorModal}
                    >
                        <IntlMessages id="propertyPage.mobModal.sensorTab.addButton" />
                    </Button>
                </Col>
            </Row> }

            <br />

            <SensorList data={sensors} getSensors={getSensors} />

            <AddSensorModal
                visible={visibleSensorModal}
                onCancel={handleCloseSensorModal}
                onSaved={handleAddSensor}
                assetId={props.assetId}
                propertyId={props.propertyId}
            />
        </>
    )
}

export default SensorTab;