import React, { useState, useEffect, useRef } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import UserModalWrapper from './UserModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Switch } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { profileService, googleService } from '@iso/services';
import { productCategoryTypes } from '@iso/constants/productCategoryTypes';
import _ from 'lodash';
import moment from 'moment';
import { dateHelper } from '@iso/lib/helpers/dateHelper';

const { Option } = Select;

const UserModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const autoCompleteRef = useRef(null);
  const [errMsg, setErrMsg] = useState("");

  const cancelModal = () => {
    props.cancelUserModal();
    form.resetFields();
    setLoading(false);
  }

  const renderOptionsCategoryType = () => {
    let options = [];
    _.forEach(productCategoryTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  useEffect(() => {
    if(props.userData)
    {
      setUserDetail(props.userData);

      setFields([
          {
            name: ['user', 'name'],
            value: userDetail.name || '',
          },
          {
            name: ['user', 'mobile_phone'],
            value: userDetail.mobile_phone || '',
          },
          {
            name: ['user', 'surname'],
            value: userDetail.surname || '',
          },
          {
            name: ['user', 'date_of_birth'],
            value: userDetail.date_of_birth ? moment(userDetail.date_of_birth) : '',
          },
          {
            name: ['user', 'home_phone'],
            value: userDetail.home_phone || '',
          },
          {
            name: ['user', 'address'],
            value: userDetail.address || '',
          },
          {
            name: ['user', 'is_active'],
            value: userDetail.is_active === 1 ? true : false
          },
      ]);
    }
  }, [props.visibleUserModal, props.userData, userDetail])

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = async (values) => {
    const location_res = await googleService.getLatLng(values.user.address);
    var point = {};
    var addressShortName = {};
    var addressLongName = {};
    if(location_res.results.length > 0 && location_res.results[location_res.results.length - 1].geometry){
      point = {
        lat: location_res.results[location_res.results.length - 1].geometry.location.lat,
        lng: location_res.results[location_res.results.length - 1].geometry.location.lng,
      };
    } else {
      setErrMsg("The address invalid");
    }

    const data = {
      user: {
        ...values.user,
        date_of_birth: values.user.date_of_birth ? dateHelper.dateForAPI(values.user.date_of_birth) : '',
        is_active: values.user.is_active && values.user.is_active == true ? 1 : 0,
        address: location_res.results[location_res.results.length - 1].formatted_address,
      }
    }

    profileService.updateProfile(data, userDetail.id).then(res => {
      if(res.code === '0000'){
        cancelModal();
        props.getUsers();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }

  return(
    <UserModalWrapper
      visible={props.visibleUserModal}
      onCancel={cancelModal}
      maskClosable={false}
      title={<IntlMessages id="adminPage.manageUser.editUser"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
      >
        <div className="form-body">
          <Form.Item
            name={['user', 'is_active']}
            className="isoInputWrapper"
            valuePropName="checked"
          >
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>
          <Form.Item
            name={['user', 'name']}
            className="isoInputWrapper"
            label={<IntlMessages id="adminPage.manageUser.name"/>}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input size="large" placeholder="Input name"/>
          </Form.Item>
          <Row>
            <Col xs={{span: 24}} md={{span: 11}}>
              <Form.Item
                name={['user', 'mobile_phone']}
                className="isoInputWrapper"
                label={<IntlMessages id="adminPage.manageUser.mobilePhone"/>}
              >
                <Input size="large" placeholder="Input mobile phone"/>
              </Form.Item>
            </Col>
            <Col xs={{span: 24}} md={{span: 11, offset: 2}} >
              <Form.Item
                name={['user', 'surname']}
                className="isoInputWrapper"
                label={<IntlMessages id="adminPage.manageUser.surname"/>}
              >
                <Input size="large" placeholder="Input surname"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 24}} md={{span: 11}}>
              <Form.Item
                name={['user', 'date_of_birth']}
                className="isoInputWrapper"
                label={<IntlMessages id="adminPage.manageUser.dateOfBirth"/>}
              >
                <DatePicker size="large" style={{width: '100%'}}/>
              </Form.Item>
            </Col>
            <Col xs={{span: 24}} md={{span: 11, offset: 2}} >
              <Form.Item
                name={['user', 'home_phone']}
                className="isoInputWrapper"
                label={<IntlMessages id="adminPage.manageUser.homePhone"/>}
              >
                <Input size="large" placeholder="Input home phone"/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name={['user', 'address']}
            className="isoInputWrapper"
            label={<IntlMessages id="adminPage.manageUser.address"/>}
          >
            <Input size="large" placeholder="Input address"/>
          </Form.Item>
        </div>    
      </Form>
    </UserModalWrapper>
  )
}

export default UserModal;