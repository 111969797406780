
import React, { useState, useEffect, useRef } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Form, Input, Button, Select, Row, Col, DatePicker, Card, Timeline, Upload, message } from 'antd';
import _ from "lodash";
import AssetSummarymodal from './AssetViewModal.styles'
import moment from "moment";


const { Option } = Select;

const AssetViewSummary = (props) => {
    const { editHazard } = props;
  
const cancelModal = () => {
    props.setVisibleViewSummary();
}  

  return (

    <AssetSummarymodal
    className='visitor-modal'
    forceRender={true}
    open={props.visibleViewSummary}
    title={<IntlMessages id="Asset Details" />}
    onCancel={cancelModal}
    maskClosable={true}
    footer={[
      <Button key="back" onClick={cancelModal} className="cancel-button">
        {<IntlMessages id="propertyPage.modal.cancel" />}
      </Button>
      
    ]}
  >
    <div style={{
        padding:'0px 30px 30px 30px',
        width: "100%", height: "400px"
      }}>
        <br />
        
        <Timeline>
                <p>{moment(props.viewSummary.created_at).format('ll')}</p>
                <Card title={`Asset `} bordered={true} style={{borderRadius:'8px'}}>
                <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Hazard Id</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.id ? props.viewSummary.id :'-'}</b>
                    </Col>
                  </Row>
                  {props.viewSummary.type ?
                  <Row>  
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Asset Type</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.type ? props.viewSummary.type :'-'}</b>
                    </Col>
                  </Row>
                  :''}
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Title</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.title ? props.viewSummary.title :'-'}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">category</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.category ?props.viewSummary.category:'-'}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">category_label</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.category_label ? props.viewSummary.category_label :"-"}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">description</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.description  ?props.viewSummary.description :'-'}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">State</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.state ? props.viewSummary.state :'-'}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">remaining_label</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.remaining_label ? props.viewSummary.remaining_label :'-'}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Updated Date</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.updated_at ? props.viewSummary.updated_at :'-'}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 9 }} md={{ span: 9 }}>
                      <p className="card_content">Created By</p>
                    </Col>
                    <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                      <b className="card_value">{props.viewSummary.created_by ? props.viewSummary.created_by :'-'}</b>
                    </Col>
                  </Row>

                </Card>
            
        </Timeline>

      </div>
      </AssetSummarymodal>
  );
};

export default AssetViewSummary;
