import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector } from 'react-redux';
// import { configs } from '@iso/components/Tables/configs';
import { Row, Col, Button, Dropdown, Menu, Popconfirm, Select } from 'antd';
import ManageSampleAvatarPage from './ManageSampleAvatar.styles';
import Table from '@iso/components/collapsing-table/table';
import { EditOutlined, PlusOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { imageService, userService } from '@iso/services';
import moment from 'moment';
import _ from 'lodash';
import UploadAvatarModal from './UploadAvatarModal';

const { Option } = Select;

const ManageSampleAvatar = () => {
  const currentUser = useSelector((state) => state.user.user);
  const [visibleUploadAvatarModal, setVisibleUploadAvatarModal] = useState(false);
  const [sampleAvatar, setSampleAvatar] = useState([]);

  const getSampleAvatar = () => {
    imageService.getList('sample_avatar').then(res => {
      if(res.code === '0000'){
        setSampleAvatar(res.images);
      }
    });
  }

  useEffect(() => {
      getSampleAvatar();
  }, []) 

  const menu = (image) => (
    <Menu onClick={handleMenuClick.bind(this, image)}>
      {(currentUser.is_system_admin == 1) && <p style={{cursor:'pointer'}} className="deleteButton">
        <Popconfirm
                placement="bottomRight"
                title="You are about to delete individual record"
                onConfirm={() => {
                  confirmDelete(image);
                }}
                okText="Yes"
                cancelText="No"
                className="delete-button"
        >
        <a style={{color:'rgba(0, 0, 0, 0.65)', marginLeft: '11px'}}>
          {<DeleteOutlined />}&nbsp;&nbsp;
          <IntlMessages id="propertyPage.managePage.delete" />
        </a>
        </Popconfirm>
      </p>}
    </Menu>
  );
  const handleMenuClick = (image, e) => {
    
  }

  const confirmDelete = (del_image) => {
    if(del_image.id){
       imageService.destroy(del_image.id).then(res => {
        if(res.code === '0000'){
          getSampleAvatar();
        }
      });
    }
  }

  const showUploadAvatarModal = () => {
    setVisibleUploadAvatarModal(true);
  }

  const cancelUploadAvatarModal = () => {
    setVisibleUploadAvatarModal(false);
  }

  const columns = [
    {
      label: <IntlMessages id="adminPage.manageUser.ID" />,
      accessor: 'id',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="adminPage.manageUser.name" />,
      accessor: 'path',
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let image = cell.row
        return (
          <img style={{width:"50px", height:"50px"}} src={process.env.REACT_APP_API_BASE_URL + image.path} />
        )
      }
    },
    {
      label: <IntlMessages id="manageYourProduct.managePage.createdAt" />,
      accessor: 'created_at',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
      CustomComponent: cell => {
        let image = cell.row
        return moment(image.created_at).format('DD/MM/YYYY');
      }
    },
    {
      label: <IntlMessages id="manageYourProduct.managePage.updatedAt" />,
      accessor: 'updated_at',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let image = cell.row
        return moment(image.updated_at).format('DD/MM/YYYY');
      }
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      noTitle: true,
      CustomComponent: cell => {
        let image = cell.row
        return (
            <Dropdown overlay={menu(image)} trigger={['click']}>
              <Button className="action-button">
                <IntlMessages id="antTable.title.actions" /> <DownOutlined />
              </Button>
            </Dropdown>
        );
      },
    },
  ];

  return (
    <LayoutWrapper>
      <ManageSampleAvatarPage>
        <PageHeader>
          {<IntlMessages id="adminPage.manageUser.header" />}
        </PageHeader>
        <div className="button-group">
          {(currentUser.is_system_admin == 1) 
          && <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={showUploadAvatarModal}>
            <IntlMessages id="sampleAvatar.managePage.uploadSampleAvatar" />
          </Button>}
        </div>
        <Box id="containerBox">
          {
            sampleAvatar.length > 0 &&
            <Table 
                columns={columns} 
                rows={sampleAvatar}
                containerId="containerBox"
                showPagination={true}
                rowSize={20}
              />
          }
        </Box>
        <UploadAvatarModal visibleUploadAvatarModal={visibleUploadAvatarModal} 
        cancelUploadAvatarModal={cancelUploadAvatarModal}
        getSampleAvatar={getSampleAvatar}
        />
      </ManageSampleAvatarPage>
    </LayoutWrapper>
  );
}

export default ManageSampleAvatar;
