import React, { useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import { useHistory } from 'react-router-dom';
import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
// import { configs } from '@iso/components/Tables/configs';
import { Row, Col, Button, Dropdown, Menu, Popconfirm, Space, Spin, Pagination } from 'antd';
import ManageUserProductPage from './ManageUserProduct.styles';
import Table from '@iso/components/collapsing-table/table';
import { EditOutlined, PlusOutlined, DeleteOutlined, DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import { marketplaceCategoryService, marketplaceProductService } from '@iso/services';
import moment from 'moment';
import ProductModal from '@iso/containers/Pages/Marketplace/ProductModal';
// import Loader from '../../../components/collapsing-table/components/Loader';

const ManageUserProduct = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const currentUser = useSelector((state) => state.user.user);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [visibleProductModal, setVisibleProductModal] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loadingData, setLoadingData] = React.useState(false);
  const [productData, setProductData] = useState({});

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedVisitor = products.slice(startIndex, endIndex);

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const getProductCategories = () => {
    marketplaceCategoryService.getList().then(res => {
      if (res.code === '0000') {
        setProductCategories(res.marketplace_categories);
      }
    });
  }

  const getProducts = () => {
    marketplaceProductService.getList(currentUser.id, null).then(res => {
      setLoadingData(true)
      if (res.code === '0000') {
        setProducts(res.marketplace_products);
        setLoadingData(false)
      }
    });
  }

  useEffect(() => {
    getProductCategories();
    getProducts();
  }, [])

  const showProductModal = () => {
    setVisibleProductModal(true);
  }

  const cancelProductModal = () => {
    setVisibleProductModal(false);
    setProductData({});
  }

  // const showProductList = () => {
  //   history.push('/product-list');
  // }

  // const showProductListByCategory = (category_id) => {
  //   history.push('/product-list?category_id=' + category_id);
  // }

  const menu = (product) => (
    <Menu onClick={handleMenuClick.bind(this, product)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <p style={{ cursor: 'pointer' }} className="deleteButton">
        <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(product);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
          <a style={{ color: 'rgba(0, 0, 0, 0.65)', marginLeft: '11px' }}>
            {<DeleteOutlined />}&nbsp;&nbsp;
            <IntlMessages id="propertyPage.managePage.delete" />
          </a>
        </Popconfirm>
      </p>
    </Menu>
  );

  const handleMenuClick = (product, e) => {
    if (e.key === 'editDetails') {
      setProductData(product);
      showProductModal();
      //showModal(mob);
    }
  }

  const confirmDelete = (del_product) => {
    if (del_product.id) {
      marketplaceProductService.destroy(del_product.id).then(res => {
        if (res.code === '0000') {
          getProducts();
        }
      });
    }

  }

  const columns = [
    {
      label: <IntlMessages id="marketplacePage.productModal.title" />,
      accessor: 'title',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="marketplacePage.productModal.category" />,
      accessor: 'category',
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2
    },
    {
      label: <IntlMessages id="marketplacePage.productModal.price" />,
      accessor: 'price',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
      CustomComponent: cell => {
        let product = cell.row
        return new Intl.NumberFormat().format(product.price)
      }
    },
    {
      label: <IntlMessages id="marketplacePage.productModal.externalLink" />,
      accessor: 'external_link',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4
    },
    {
      label: <IntlMessages id="marketplacePage.productModal.description" />,
      accessor: 'description',
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      CustomComponent: (cell) => {
        const description = cell.row.description;
        const maxLength = 20;
        return (
          <div title={description.length > maxLength ? description : null}>
            {description.length > maxLength ? `${description.slice(0, maxLength)}...` : description}
          </div>
        );
      }

    },
    {
      label: <IntlMessages id="marketplacePage.productModal.linkToProduct" />,
      accessor: '',
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      CustomComponent: cell => {
        let product = cell.row
        return (<a target="_blank" href={"/product-detail?product_id=" + product.id}>{window.location.origin + "/product-detail?product_id=" + product.id}</a>)
      }
    },
    {
      label: <IntlMessages id="manageYourProduct.managePage.createdAt" />,
      accessor: 'created_at',
      minWidth: 200,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      CustomComponent: cell => {
        let product = cell.row
        return moment(product.created_at).format('L');
      }
    },
    {
      label: <IntlMessages id="manageYourProduct.managePage.updatedAt" />,
      accessor: 'updated_at',
      minWidth: 200,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      CustomComponent: cell => {
        let product = cell.row
        return moment(product.updated_at).format('L');
      }
    },
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let product = cell.row;
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menu(product)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <LayoutWrapper>
      <ManageUserProductPage>
        <div className="visiter_table_main">
        <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Manage Your Product</h2>
            </div>
            <div className="button-group" style={{ gap: "12px" }}>
             
              <Button
                icon={<PlusOutlined />}
                type="primary"
                className="btn-success"
                onClick={showProductModal}
                size="large"
              >
                <IntlMessages id="marketplacePage.managePage.addProduct" />
              </Button>
            </div>
          </div>
         
          <Box className="table_visiter" id="containerBox">
            {products.length > 0 ? (
              <>
               
                    <Table
                      columns={columns}
                      rows={displayedVisitor}
                      containerId="containerBox"
                      showPagination={false}
                      rowSize={99999}
                    />
                  </>
            ) : (
              <h3 style={{ fontSize: '16px', padding: '15px', fontWeight: '400' }}>
                <IntlMessages id="dashboardPage.noData" />
              </h3>
            )}
          </Box>
        </div>
        
        <ProductModal visibleProductModal={visibleProductModal} cancelProductModal={cancelProductModal}
          productCategories={productCategories} getProducts={getProducts} productData={productData} />
      </ManageUserProductPage>
    </LayoutWrapper>
  );
}

export default ManageUserProduct;
