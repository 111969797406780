import React from "react";
import { Form, Checkbox } from "antd";

const BioCheckbox = ({ name }) => {
  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      className="isoInputWrapper"
    >
      <Checkbox></Checkbox>
    </Form.Item>
  );
};

export default BioCheckbox;
