import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  message,
  Checkbox,
} from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import VisitorFormModalWrapper from "./VisitorFormModal.styles";
import {
  formService,
  surveyQuestionService,
  visitorFormService,
} from "@iso/services";
import _ from "lodash";
import { formTypes } from "@iso/constants/formTypes";
import moment from "moment";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import "survey-react/modern.css";
import SignatureCanvas from "react-signature-canvas";
import GlobalForm from "@iso/containers/Pages/Form/GlobalForm";
import FinalScreen from "@iso/containers/Pages/Form/FinalScreen";
import queryString from "query-string";
import BioForm from "@iso/containers/Pages/AdminPages/FormBuilder/BioForm/BioForm";
import CheckinForm from "@iso/containers/Pages/AdminPages/FormBuilder/CheckinForm/CheckinForm";
import HazardForm from "@iso/containers/Pages/AdminPages/FormBuilder/HazardForm/HazardForm";

const { Option } = Select;
Survey.StylesManager.applyTheme("modern");

const VisitorFormModal = (props) => {
  const padRef = useRef({});
  const [loading, setLoading] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [visitorForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [surveyContent, setSurveyContent] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [showFinalScreen, setShowFinalScreen] = useState(false);
  const [pdfForm] = Form.useForm();
  const formToken = queryString.parse(window.location.search).token || "";

  useEffect(() => {
    setFields([
      {
        name: ["visitor", "token"],
        value: formToken,
      },
    ]);
  }, [formToken]);

  useEffect(() => {
    if ((props.propertyId, dataForm.id)) {
      visitorFormService.getSurveyQuestion(formToken).then((res) => {
        if (res.code === "0000") {
          let questions = [];
          _.forEach(res.survey_questions, (question, index) => {
            switch (question.question_type) {
              case "yes/no":
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "radiogroup",
                      choices: ["Yes", "No"],
                      isRequired: true,
                      name: question.id.toString(),
                      title: question.title,
                    },
                  ],
                };
                break;
              case "scale":
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "radiogroup",
                      choices: [
                        "Strongly Disagree",
                        "Disagree",
                        "Neutral",
                        "Agree",
                        "Strongly Agree",
                      ],
                      isRequired: true,
                      name: question.id.toString(),
                      title: question.title,
                    },
                  ],
                };
                break;
              default:
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "comment",
                      isRequired: true,
                      name: question.id.toString(),
                      title: question.title,
                    },
                  ],
                };
                break;
            }

            questions.push(data);
          });
          setQuestionList(questions);
        }
      });
    }
  }, [props.propertyId, dataForm]);

  useEffect(() => {
    if (questionList && dataForm) {
      var surveyJSON = {
        title: dataForm.survey_title,
        description: dataForm.survey_description,
        completedHtml:
          "<h3>Thank you for completing the survey!</h3> <h5>Please click Save to submit your survey!</h5>",
        pages: questionList,
      };
      setSurveyContent(surveyJSON);
    }
  }, [questionList, dataForm]);

  const cancelModal = () => {
    props.cancelModal();
    visitorForm.resetFields();
    setLoading(false);
  };

  useEffect(() => {
    if (props.formData) {
      setDataForm(props.formData);
    }
  }, [props.propertyId, props.visibleFormModal, dataForm]);

  const handleSubmit = async (e) => {
    setLoading(true);
    const visitorFormValidation = await visitorForm.validateFields();
    if (!visitorFormValidation.errorFields) {
      pdfForm
        .validateFields()
        .then((values) => {
          setLoading(true);
          onStore(values);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
          setLoading(false);
        });
    }
  };

  const onStore = (values) => {
    const data = {
      form: {
        ...values.form,
        visitor_form_token: formToken,
        type: "pdf_upload",
        signature_path:
          padRef.current &&
          Object.keys(padRef.current).length === 0 &&
          padRef.current.constructor === Object
            ? ""
            : padRef.current.toDataURL("image/png"),
      },
      visitor: visitorForm.getFieldsValue().visitor,
    };
    visitorFormService.createVisitorForm(data).then((res) => {
      if (res.code === "0000") {
        message.success("Submit success");
        pdfForm.resetFields();
        setLoading(false);
        setShowFinalScreen(true);
      } else {
        message.error(res.message);
        setLoading(false);
      }
    });
  };

  const submitSurveyData = (survey) => {
    const data = {
      form: {
        visitor_form_token: formToken,
        survey: survey.data,
        type: "self_create",
        signature_path:
          padRef.current &&
          Object.keys(padRef.current).length === 0 &&
          padRef.current.constructor === Object
            ? ""
            : padRef.current.toDataURL("image/png"),
      },
      visitor: visitorForm.getFieldsValue().visitor,
    };

    visitorFormService.createVisitorForm(data).then((res) => {
      if (res.code === "0000") {
        message.success("Submit success");
        setShowFinalScreen(true);
      } else {
        message.error(res.message);
      }
    });
  };

  const handleClear = () => {
    padRef.current.clear();
  };

  const renderVisitorInfo = () => {
    return dataForm.is_checkin_form ? (
      <div className="visitor-information">
        <h3 className="visitor-title">Visitor Information</h3>
        <Form
          form={visitorForm}
          layout="vertical"
          scrollToFirstError
          fields={fields}
          className="form-body"
        >
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 11 }}>
              <Form.Item
                name={["visitor", "name"]}
                className="isoInputWrapper"
                label={<IntlMessages id="formPage.modal.vistor.name" />}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input size="large" placeholder="Input your name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
              <Form.Item
                name={["visitor", "email"]}
                label="Email"
                className="isoInputWrapper"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid Email!",
                  },
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input size="large" placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 11 }}>
              <Form.Item
                name={["visitor", "emergency_name"]}
                className="isoInputWrapper"
                label={
                  <IntlMessages id="formPage.modal.vistor.emergencyName" />
                }
              >
                <Input
                  size="large"
                  placeholder="Input your emergency name"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
              <Form.Item
                name={["visitor", "emergency_mobile_number"]}
                className="isoInputWrapper"
                label={
                  <IntlMessages id="formPage.modal.vistor.emergencyContactMobileNumber" />
                }
              >
                <Input
                  size="large"
                  placeholder="Input your emergency mobile number"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <VisitorFormModalWrapper
      className="user-access-modal"
      forceRender={true}
      maskClosable={false}
      visible={props.visibleFormModal}
      title={
        <div>
          <p>{props.property ? props.property.name : 'Checkin Form'}</p>
          <p id="property-address">{props.property ? props.property.address : ''}</p>
        </div>
      }
      onCancel={cancelModal}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
      ]}
    >
      {!showFinalScreen && renderVisitorInfo()}
      {dataForm && dataForm.type == "self_create" && !showFinalScreen && (
        <Survey.Survey json={surveyContent} onComplete={submitSurveyData} />
      )}
      {showFinalScreen && <FinalScreen cancelModal={cancelModal}/>}
      {dataForm && dataForm.type == "pdf_upload" && !showFinalScreen && (
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <iframe
              src={ dataForm.files && dataForm.files.length ?
                process.env.REACT_APP_API_BASE_URL +
                dataForm.files[dataForm.files.length - 1].path : ''
              }
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 1 }}>
            <Form
              layout="vertical"
              scrollToFirstError
              className="form-body"
              form={pdfForm}
            >
              <Form.Item
                name={["form", "is_agree"]}
                className="isoInputWrapper"
                valuePropName="checked"
              >
                <Checkbox>
                  {<IntlMessages id="formPage.modal.agreeConfirm" />}
                </Checkbox>
              </Form.Item>
              <h3>{<IntlMessages id="formPage.modal.signature" />}</h3>
              <div className="signature-area">
                <SignatureCanvas ref={padRef} />
                <button
                  onClick={handleClear}
                  className="signature-clear-button"
                >
                  Clear
                </button>
              </div>
              <div className="isoInputWrapper">
                <Button
                  key="submit"
                  className="btn-success"
                  type="primary"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {<IntlMessages id="propertyPage.modal.save" />}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      )}
      {dataForm && dataForm.type == "system_forms" && !showFinalScreen && (
        <GlobalForm
          formData={dataForm}
          setShowFinalScreen={setShowFinalScreen}
          visitorForm={visitorForm}
          visitorFormToken={formToken}
        />
      )}
      {dataForm && dataForm.type == "bio_form" && !showFinalScreen && (
        <BioForm
          formData={dataForm}
          setShowFinalScreen={setShowFinalScreen}
          isAbleToSubmit={true}
          visitorForm={visitorForm}
          visitorFormToken={formToken}
        />
      )}
      {dataForm && dataForm.type == "checkin_form" && !showFinalScreen && (
        <CheckinForm
          formData={dataForm}
          setShowFinalScreen={setShowFinalScreen}
          isAbleToSubmit={true}
          visitorForm={visitorForm}
          visitorFormToken={formToken}
        />
      )}
      {dataForm && dataForm.type == "hazard_form" && !showFinalScreen && (
        <HazardForm
          formData={dataForm}
          setShowFinalScreen={setShowFinalScreen}
          isAbleToSubmit={true}
          visitorForm={visitorForm}
          visitorFormToken={formToken}
          property={props.property}
        />
      )}
    </VisitorFormModalWrapper>
  );
};

export default VisitorFormModal;
