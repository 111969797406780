import React,{ useState, useEffect, useRef } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dropdown, Menu, Popconfirm, List, Avatar, Radio } from 'antd';
import DirectoryManagePage from './Directory.styles';
import { PlusOutlined, DownOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined, AimOutlined, EnvironmentOutlined, EnvironmentTwoTone } from '@ant-design/icons';
import { userService } from '@iso/services';
import _ from 'lodash';
import $ from 'jquery';

const Directory = () => {
  const dispatch = useDispatch();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);
  const [radius, setRadius] = useState(100000);
  const [userData, setUserData] = useState([]);

  const getUser = (radius) => {
    userService.getUserByRadius(radius).then(res => {
      if(res.code === '0000'){
        let data = res.users.reduce((r, e) => {
          let group = e.name[0].toUpperCase();
          if(!r[group]) r[group] = {group, children: [e]}
          else r[group].children.push(e);
          return r;
        }, {})

        let result = Object.values(data)
        setUserData(result.sort((a, b) => a.group.localeCompare(b.group)));
      }
    });
  }


  useEffect(() => {
    getUser(radius);
  }, [radius])

  const onChange = e => {
    setRadius(e.target.value);

    getUser(e.target.value);
    
  };
  
  useEffect(() => {
    $('.alphabet-scroll-bar > li').click(function(){
      let letter = $(this).data('letter');

      const titleElement = document.getElementById('item-' + letter)
      if(titleElement)
      {
        titleElement.scrollIntoView(true)
      }
    })
  })
  const createAlphabetList = () => {
    let table = []
    //set the default value of i & j to print A to Z
    var i = 65;
    var j = 91;
 
    for (let k = i; k < j; k++) {
      var str =String.fromCharCode(k);
      table.push(<li type="primary" className="btn-success" data-letter={str}>
       {str}
      </li>)
    }
    return table
  }

  return (
    <DirectoryManagePage>
      <PageHeader>
        {<IntlMessages id="directoryPage.managePage.header" />}
      </PageHeader>
      <div className="button-group">
        <Radio.Group onChange={onChange} value={radius}>
          <Radio value={20000}>20km</Radio>
          <Radio value={50000}>50km</Radio>
          <Radio value={100000}>100km</Radio>
          <Radio value={150000}>150km</Radio>
        </Radio.Group>
      </div>
      <ul className="alphabet-scroll-bar">
        {createAlphabetList()}
      </ul>
    
      <div className="directory-container">
        {(userData) ? (userData.map((user, index) => {
        return (
          <div id={'item-' + user.group} className="scroll-div">
          <List
            itemLayout="horizontal"
            dataSource={user.children}
            header={<h2>{user.group}</h2>}
            bordered
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={item.images && item.images.length > 0 ? process.env.REACT_APP_API_BASE_URL + item.images[item.images.length-1].path : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"} />}
                  title={<a href="https://ant.design">{item.name} {item.surname}</a>}
                  description={<><div><AimOutlined/> {item.address}</div><div><EnvironmentTwoTone/> <b>{Math.ceil(item.distance/1000)} km</b></div></>}
                />
                
              </List.Item>
            )}
          />
          <br/>
          </div>
        );
      })): ''}

      </div>
    </DirectoryManagePage>
  );
}

export default Directory;