import React, { useState, useEffect } from 'react';
import AssetModalWrapper from './AssetModal.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Form, Select, Row, Col, message } from 'antd';
import { sensorService } from '@iso/services';

const { Option } = Select;

const AddSensorModal = (props) => {
    const [form] = Form.useForm();
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sensors, setSensors] = useState([]);

    useEffect(() => {
        setFields([]);

        getSensors();
    }, []);

    const getSensors = () => {
        setLoading(true);

        sensorService.getList({ property_id: props.propertyId }).then(res => {
            if (res.code === '0000') {
                setSensors(res.sensors);
            }

            setLoading(false);
        })
    }

    const renderSensorOptions = () => {
        const options = sensors.map((sensor) => {
            return (
                <Option key={sensor.id} value={sensor.id}>
                    {sensor.name} - Device: {sensor.device?.terminal_id}
                </Option>
            );
        });

        return (
            <Select
                placeholder="Select sensor"
                allowClear
                size="large"
            >
                { options }
            </Select>
        )
    }

    const handleSubmit = () => {
        setLoading(true);

        form.validateFields()
            .then(values => {
                onStore(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setLoading(false);
            });
    }

    const onStore = (values) => {
        setLoading(true);

        sensorService.addSensorToAsset(values.sensor_id, props.assetId)
            .then(res => {
                if (res.code === '0000') {
                    form.resetFields();

                    props.onSaved();
                }

                setLoading(false);
            });
    }

    return (
        <AssetModalWrapper
            visible={props.visible}
            onCancel={props.onCancel}
            title={<IntlMessages id="propertyPage.mobModal.sensorTab.addSensorModal.title" />}
            footer={[
                <Button key="back" onClick={props.onCancel} className="cancel-button">
                    {<IntlMessages id="propertyPage.modal.cancel" />}
                </Button>,
                <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
                    {<IntlMessages id="propertyPage.modal.save" />}
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                scrollToFirstError
                fields={fields}
                id="#1"
            >
                <div className="form-body">
                    <Row>
                        <Col xs={{span: 24}} md={{span: 24}}>
                            <Form.Item
                                name="sensor_id"
                                label="Sensor"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                { renderSensorOptions() }
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </AssetModalWrapper>
    )
}

export default AddSensorModal;