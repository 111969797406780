import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Row, Col, message } from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import StopDeviceAlarmRequestModalWrapper from "./StopDeviceAlarmRequestModal.styles";
import { alertDeviceService } from "@iso/services";
import _ from "lodash";
import "survey-react/survey.css";
import "survey-react/modern.css";
import queryString from "query-string";

const { TextArea } = Input;

const StopDeviceAlarmRequestModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [stopDeviceAlarmRequestForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const token = queryString.parse(window.location.search).token || "";
  const email = queryString.parse(window.location.search).email || "";
  const deviceName = queryString.parse(window.location.search).deviceName || "";

  useEffect(() => {
    setFields([
      {
        name: ["token"],
        value: token,
      },
      {
        name: ["email"],
        value: email,
      },
      {
        name: ["deviceName"],
        value: deviceName,
      },
    ]);
  }, [token]);

  const cancelModal = () => {
    props.cancelModal();
    stopDeviceAlarmRequestForm.resetFields();
    setLoading(false);
  };

  useEffect(() => {
    if (props.formData) {
      setDataForm(props.formData);
    }
  }, [props.propertyId, props.visibleFormModal, dataForm]);

  const handleSubmit = async (e) => {
    setLoading(true);
    stopDeviceAlarmRequestForm
      .validateFields()
      .then((values) => {
        setLoading(true);
        onStore(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };

  const onStore = (values) => {
    values = {
      ...values,
      token
    }
    alertDeviceService.stopDeviceAlarm(values).then((res) => {
      if (res.code === "0000") {
        cancelModal()
        message.success('Successful, The alarm has now been terminated')
      } else {
        message.error(res.message);
      }
    });
  };

  const renderStopDeviceAlarmRequestInfo = () => {
    return (
      <div className="stop-device-alarm-request">
        <Form
          form={stopDeviceAlarmRequestForm}
          layout="vertical"
          scrollToFirstError
          fields={fields}
          className="form-body"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name={["deviceName"]}
                className="isoInputWrapper"
                label={<IntlMessages id="stopDeviceAlarmRequest.modal.deviceName" />}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input
                  readOnly={true}
                  size="large"
                  placeholder=""
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name={["email"]}
                className="isoInputWrapper"
                label={<IntlMessages id="stopDeviceAlarmRequest.modal.email" />}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input
                  readOnly={true}
                  size="large"
                  placeholder="Input your email"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name={["reason"]}
                label={
                  <IntlMessages id="stopDeviceAlarmRequest.modal.reason" />
                }
                className="isoInputWrapper"
                rules={[
                  {
                    required: true,
                    message: "Please input your reason!",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name={["comment"]}
                className="isoInputWrapper"
                label={
                  <IntlMessages id="stopDeviceAlarmRequest.modal.comment" />
                }
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <StopDeviceAlarmRequestModalWrapper
      className="user-access-modal"
      forceRender={true}
      maskClosable={false}
      visible={props.visibleStopDeviceAlarmRequestModal}
      title={
        <div>
          <p>{props.property ? props.property.name : "Checkin Form"}</p>
          <p id="property-address">
            {props.property ? props.property.address : ""}
          </p>
        </div>
      }
      onCancel={cancelModal}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      {renderStopDeviceAlarmRequestInfo()}
    </StopDeviceAlarmRequestModalWrapper>
  );
};

export default StopDeviceAlarmRequestModal;
