import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const userFormService = {
  getList,
  checkFormSubmission,
  getUserFormByProperty
};

function getList(formId, params={}){
  return (
    axios
      .get(
        `${apiEndpoint.userForm}?form_id=${formId}`,
        {
          headers: authHeader(),
          params: params
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getUserFormByProperty(property_id, params={}){
  return (
    axios
      .get(
        `${apiEndpoint.userForm}/get-user-form-by-property?property_id=${property_id}`,
        {
          params: params,
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function checkFormSubmission(token){
    return (
        axios
            .get(
                `${apiEndpoint.userForm}/check-form-submission?token=${token}`,
                {
                    headers: authHeader(),
                }
            ).then(res => {
            if(res && res.status === 200){
                return res.data;
            }
        })
    );
}
