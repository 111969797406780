import React , { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, Radio, Checkbox, DatePicker, Menu,Dropdown ,Popconfirm} from 'antd';
import { PlusOutlined,EllipsisOutlined } from '@ant-design/icons';
import { qualificationService } from '@iso/services';
import userActions from '@iso/redux/user/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import QualificationsWrapper from './Qualifications.styles';
import Table from '@iso/components/collapsing-table/table';
import Box from '@iso/components/utility/box';
import QualificationModal from './QualificationModal';
import _ from 'lodash';
import moment from 'moment';

const { store } = userActions;

const Qualifications = (props) => {
  const [qualificationTrade, setQualificationTrade] = useState([]);
  const [qualificationCourse, setQualificationCourse] = useState([]);
  const [qualificationFirstAid, setQualificationFirstAid] = useState([]);
  const [qualificationData, setQualificationData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [visibleQualification, setVisibilityQualification] = React.useState(false);
  const [editQualification, setEditQualification] = useState(false);
  const [editqualifications, setEditQualifications] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);
  const user_id = props.userId || '';
  const form_id = props.formId || '';
  const isDisableForm = user_id && form_id ? true : false
  const [title,setTitle] = useState("Add Qualifications");
  useEffect(() => {
    if(props.tabIndex == props.activeTabIndex){
      updateQualificationData();
    }
  }, [currentUser.id, user_id, form_id, props.activeTabIndex]);

  const updateQualificationData = () => {
    if(user_id && form_id){
      qualificationService.getCheckinFormUserQualification(form_id, user_id, 'trade').then(res => {
        if(res.code === '0000'){
          setQualificationTrade(res.qualifications);
        }
      });
       qualificationService.getCheckinFormUserQualification(form_id, user_id, 'course').then(res => {
        if(res.code === '0000'){
          setQualificationCourse(res.qualifications);
        }
      });
       qualificationService.getCheckinFormUserQualification(form_id, user_id, 'first_aid').then(res => {
        if(res.code === '0000'){
          setQualificationFirstAid(res.qualifications);
        }
      });
    }else if(currentUser){
       qualificationService.getList(currentUser.id, 'trade').then(res => {
        if(res.code === '0000'){
          setQualificationTrade(res.qualifications);
        }
      });
       qualificationService.getList(currentUser.id, 'course').then(res => {
        if(res.code === '0000'){
          setQualificationCourse(res.qualifications);
        }
      });
       qualificationService.getList(currentUser.id, 'first_aid').then(res => {
        if(res.code === '0000'){
          setQualificationFirstAid(res.qualifications);
        }
      });
    }
  }

  const showModal = () => {
    setVisibilityQualification(true);
  }

  const showEditModal = (qualification) => {
    setEditQualification(true);
    setQualificationData(qualification);
    setVisibilityQualification(true);
    setTitle("Edit Qualifications")
  }

  const cancelModal = () => {
    setVisibilityQualification(false);
    setEditQualification(false);
    setQualificationData({});
    setTitle("Add Qualifications")
  }

  const onSaved = () => {
    updateQualificationData();
  }
  const menuList = (qualification, permission) => (
    <Menu onClick={handleMenuClick.bind(this, qualification)}  className='property-area-dot-btn'>
      <Menu.Item key="editDetails" icon={ <img src={process.env.PUBLIC_URL +"./images/icons/edit.png"}/>} className='property-area-dot-btn'>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <Menu.Item key="viewDetails" icon={<img src={process.env.PUBLIC_URL +"./images/icons/eye.png"}/>}>
        <IntlMessages id="View Details" />
      </Menu.Item>
      {/* <Menu.Item key="Remove" icon={<DeleteOutlined />}>
        <IntlMessages id="Remove" />
      </Menu.Item> */}

      <p style={{ cursor: "pointer" }} className="deleteButton">
       <Popconfirm
         placement="bottomRight"
         title="You are about to delete individual record"
         onConfirm={() => {
           confirmDelete(qualification);
         }}
         okText="Yes"
         cancelText="No"
         className="delete-button"
       >
         <a style={{fontFamily: "Sora",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "32px",
  letterSpacing: "0.75px",
 paddingLeft:'11px',
  color: "red",}}>
          <img src={process.env.PUBLIC_URL +"./images/icons/delete.png"}/>&nbsp;&nbsp;
           <IntlMessages id="Remove" />
         </a>
       </Popconfirm>
     </p>
    </Menu>
  );
  const confirmDelete = (qualification) => {
    if (qualification.id) {
      qualificationService.destroy(qualification.id).then((res) => {
        if (res.code === "0000") {
          updateQualificationData();
        }
      });
    }
  };
  const handleMenuClick = (qualification, e) => {


    if (e.key === "viewDetails") {
     
      setViewSummary(true);
      setQualificationData(qualification);
      setTitle('Qualifications')
    }
    if (e.key === "editDetails") {
     
      showEditModal(qualification)

    }
  }
  const columns = [
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.name" />,
      accessor: 'name',
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.completion" />,
      accessor: 'completion',
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let qualification = cell.row
        return (qualification.completion) ? moment(qualification.completion).format('L') : ''
      }
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.institution" />,
      accessor: 'institution',
      minWidth: 100,
      sortable: false,
      position: 3,
      priorityLevel: 3
    },
    {
      label: '',
      accessor: 'edit_detail',
      minWidth: 100,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: cell => {
        let qualification = cell.row
        return (
          <Dropdown overlay={menuList(qualification)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    }, 
  ];
  const columns_first_aid = [
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.name" />,
      accessor: 'name',
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.completion" />,
      accessor: 'completion',
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let qualification = cell.row
        return (qualification.completion) ? moment(qualification.completion).format('L') : ''
      }
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.institution" />,
      accessor: 'institution',
      minWidth: 100,
      sortable: false,
      position: 3,
      priorityLevel: 3
    },
    {
      label: <IntlMessages id="profilePage.qualificationsTab.table.dateExpiry" />,
      accessor: 'date_expiry',
      minWidth: 200,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let qualification = cell.row
        return (qualification.date_expiry) ? moment(qualification.date_expiry).format('L') : ''
      }
    },
    {
      label: '',
      accessor: 'edit_detail',
      minWidth: 100,
      sortable: false,
      position: 5,
      priorityLevel: 5,
      noTitle: true,
      CustomComponent: cell => {
        let qualification = cell.row
        return (
          <Dropdown overlay={menuList(qualification)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    }, 
  ];

  return (
    <>
    <QualificationsWrapper
    style={{ padding: '15px' }}
    >
      <div className="form-title" >
        <h2>{<IntlMessages id="profilePage.managePage.qualificationsTab" />}</h2>
        {!isDisableForm && 
          <Button icon={<PlusOutlined />} type="primary" className='AddQualification'  onClick={showModal}>
            <IntlMessages id="profilePage.licencesTab.addQualification" />
          </Button>
        }
        
       
      </div>
      <div className="form-title">
        <h3>{<IntlMessages id="profilePage.qualificationsTab.type.trade" />}</h3>
      </div>
      <Box id="containerQualificationTrade"  className="tableCust" style={{ border: 'none' }}>
          {qualificationTrade.length > 0 ? 
          <Table 
              columns={columns} 
              rows={qualificationTrade}
              containerId="containerQualificationTrade"
              showPagination={false}
              rowSize={99999}
            /> : <h1><IntlMessages id="dashboardPage.noData" /></h1>
          }
      </Box>
      <div className="form-title" >
        <h3>{<IntlMessages id="profilePage.qualificationsTab.type.courses" />}</h3>
      </div>
      <Box id="containerBoxQualificationCourse" className="tableCust" style={{ border: 'none' }}>
          {qualificationCourse.length > 0 ?
          <Table 
              columns={columns} 
              rows={qualificationCourse}
              containerId="containerBoxQualificationCourse"
              showPagination={false}
              rowSize={99999}
            /> : <h1><IntlMessages id="dashboardPage.noData" /></h1>
          }
      </Box>
      <div className="form-title">
        <h3>{<IntlMessages id="profilePage.qualificationsTab.type.first_aid" />}</h3>
      </div>
      <Box id="containerBoxQualificationFirstAid" className="tableCust" style={{ border: 'none' }}>
          {qualificationFirstAid.length > 0 ?
          <Table 
              columns={columns_first_aid} 
              rows={qualificationFirstAid}
              containerId="containerBoxQualificationFirstAid"
              showPagination={false}
              rowSize={99999}
            /> : <h1><IntlMessages id="dashboardPage.noData" /></h1>
          }
      </Box>
      <QualificationModal        
        visibleQualification={visibleQualification} cancelModal={cancelModal} onSaved={onSaved}
        editQualification={editQualification} qualificationData={qualificationData}
        isDisableForm={isDisableForm} viewSummary={viewSummary} setViewSummary={setViewSummary} title = {title} setTitle = {setTitle}
      />
    </QualificationsWrapper>
      
    </>
  );
}

export default Qualifications;