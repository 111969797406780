import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";

export const sarService = {
  complateSAR,
  addSar,
  getAllSARUser,
  getAllSAR,
  updateSAR,
  terminateSARAlarm,
  viewSar
};

function addSar(data) {
  return axios
    .post(
      `${apiEndpoint.addSAR}`,
      { sar: data },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      if (res && res.status === 200 && res.data.code === "0000") {
        return res.data;
      }
    });
}

function getAllSARUser(id) {
  return axios
    .get(`${apiEndpointFunction.getSARUser(id)}`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res && res.status === 200 && res.data.code === "0000") {
        return res.data;
      }
    });
}

function viewSar(id){
  return axios
    .get(`${apiEndpointFunction.getAllSAR(id)}`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res && res.status === 200 && res.data.code === "0000") {
        return res.data;
      }
    });
}

function getAllSAR() {
  return axios
    .get(`${apiEndpoint.getAllSAR}`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res && res.status === 200 && res.data.code === "0000") {
        return res.data;
      }
    });
}

function updateSAR(id, requestBody) {
  return axios
    .patch(
      `${apiEndpointFunction.terminateSAR(id)}`,
      requestBody,
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      if (res && res.status === 200 && res.data.code === "0000") {
        return res.data;
      }
    });
}

function complateSAR(token) {
  return axios
    .post(`${apiEndpoint.complateElapsed}`, { token: token })
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      }
    });
}

function terminateSARAlarm(payload) {
  return axios
    .post(`${apiEndpoint.terminateAlarm}`,payload )
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      }
    });
}
