import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import { useHistory } from 'react-router-dom';
// import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
// import { configs } from '@iso/components/Tables/configs';
import { Statistic, Card, Row, Col } from 'antd';
import MarketplacePage from './Marketplace.styles';
import { ArrowUpOutlined, ArrowDownOutlined, HomeFilled, ClockCircleFilled } from '@ant-design/icons';
import ProductDetail from './ProductDetail';
// import propertyArea from '@iso/Layout/Sidebar/Icons/property_areas.png';
// import manageLivestock from '@iso/Layout/Sidebar/Icons/manage_livestock.png';
// import manageAssets from '@iso/Layout/Sidebar/Icons/manage_assets.png';


// import { PlusOutlined, DownOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
// import { mobService, propertyService } from '@iso/services';
// import modalActions from '@iso/redux/modal/actions';
// import _ from 'lodash';
// import { objectColors } from '@iso/constants/objectColors';
import moment from 'moment';
// import MobModal from '@iso/containers/Pages/PropertyMap/components/Mob/MobModal';
// import AddHistoryModal from '@iso/containers/Pages/PropertyMap/components/Mob/AddHistoryModal';
// import Table from '@iso/components/collapsing-table/table';
// import styled from "styled-components";
// import SelectPropertyModal from '@iso/containers/Pages/Property/SelectPropertyModal';

//const { openModal } = modalActions;

const { Meta } = Card;

const ProductHome = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activeProperty = localStorage.getItem('active_property');
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
      setProductData(props.products);
  }, [props.products])

  const showProductDetail = (product_id) => {
    history.push('/product-detail?product_id=' + product_id);
  }

  return (
    <>
      <div className="site-card-wrapper">
      <Row gutter={16}>
        {(productData) ? (productData.map((value, index) => {
                if(index <= 11){
                  return (
                    <Col md={6} sm={12} xs={24} className="product-col">
                      <Card
                        hoverable
                        cover={<img alt="example" src={value.images && value.images.length > 0 ? 
                          process.env.REACT_APP_API_BASE_URL + value.images[0].path 
                          : "https://www.usu.edu/involvement/images/robinsimages2020/Profile-Photo-Unavailable.png" } />}
                        onClick={() => showProductDetail(value.id)}
                      >
                      <Meta title={value.title} description={moment(value.created_at, "YYYYMMDD").fromNow()}/>
                      <p className="product-home-price">&#36; {new Intl.NumberFormat().format(value.price)}</p>
                      </Card>
                    </Col>
                  )
                }  
              }
            )
          ) : ''
        }
      </Row>
      </div>
    </>
  );
}

export default ProductHome;
