import React, {useState,useEffect } from 'react';
import { Button, Popover } from 'antd';
import { alertDeviceService } from '@iso/services';
import AlertDeviceWrapper from './AlertDevice.styles'; 
import IntlMessages from '@iso/components/utility/intlMessages';
import { SyncOutlined,EnvironmentOutlined } from '@ant-design/icons';
import DeviceStatusLight from './DeviceStatusLight'
import StopDeviceAlarmRequestModal from './StopDeviceAlarmRequestModal';
import styled from "styled-components";
import Table from '@iso/components/collapsing-table/table';
import Box from '@iso/components/utility/box';

const AlertDevice = (props) => {
  const {propertyId, setCenterPoint, setPositionDevice, setShowMarkerAlert} = props;
  
  const [dataAlert, setDataAlert] = useState([]);
  const [stopAlarmRequests, setStopAlarmRequests] = useState();
  const [isVisibleStopAlarmRequestModal, setIsVisibleStopAlarmRequestModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const popoverDeviceStatus =(
    <div>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#ea5252'}}></ColorItemPreview> &nbsp;&nbsp; Cancelled
      </div> <br/>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#f6cf67'}}></ColorItemPreview> &nbsp;&nbsp; Just cancelled in last 60 min 
      </div><br/>   
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#00965b'}}></ColorItemPreview> &nbsp;&nbsp; Active
      </div>
    </div>
  );  

  const handleShowStopAlarmRequests = (stopRequests) => {
    stopRequests = stopRequests ? stopRequests : []
    setIsVisibleStopAlarmRequestModal(true);
    setStopAlarmRequests(stopRequests);
  }
   useEffect(() => {
    if(propertyId){
     getListAlert(propertyId);
    }
  },[propertyId]);

  const getListAlert = (propertyId) => {
    alertDeviceService.get(propertyId).then(res => {
      if(res.code === "0000"){
        setDataAlert(res.alert_devices);
        setLoading(false);
      }
    });

  }
  

  const columnsTable = [

    {
      label: <IntlMessages id="safeFarmPage.deviceName" />,
      accessor: 'device_name',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: <IntlMessages id="uiElements.badge.status" />,
      accessor: 'status',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
      CustomComponent: (cell) => {
        const stopRequests = cell?.row?.iot_device?.stop_device_alarm_requests;
        let light = 'red' 
        if(stopRequests && stopRequests.length){
          const firstStopRequest = stopRequests[0]

          let currentTime = new Date();
          let firstStopRequestAt = new Date(firstStopRequest.created_at);
          let diffMinutes = Math.floor((currentTime - firstStopRequestAt) / (1000 * 60))
          if(diffMinutes <= 60){
            light= "yellow" 
          }else{
            light = "green"
          }
        }
        return (
          <Popover content={popoverDeviceStatus} title="Status">
            <div>
              <DeviceStatusLight color={light} onClick={()=>handleShowStopAlarmRequests(cell?.row?.iot_device)}>
              </DeviceStatusLight>
            </div>

          </Popover>
        )
      },
    },   
    { 
      label: <IntlMessages id="safeFarmPage.dateTimeAustralia" />,
      accessor: 'date_time',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
    },
    {
      label: <IntlMessages id="Location" />,
      accessor: 'location',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let record  = cell.row
        return (
          <Button className="ant-btn-location" onClick={() => getLocation(record)}>
          <EnvironmentOutlined /> 
          </Button>
        );
      }
    }
  ];
  
  const reloadDataAlert = () => {
    setLoading(true);
    if(propertyId){
      getListAlert(propertyId);
    }
  }
  const getLocation = (data) => {
    setCenterPoint([data.lat,data.lng]);
    setPositionDevice(data);
    setShowMarkerAlert(true);
  }

  const cancelStopAlarmModal = () => {
    setIsVisibleStopAlarmRequestModal(false)
    setStopAlarmRequests([])
  }

  return (
    <AlertDeviceWrapper>
      <div className='manage-table'>
      <Box id="alert-device-box">
      {dataAlert && dataAlert?.length > 0 ?
        <Table rows={dataAlert} columns={columnsTable} size="small" pagination={{pageSize: 12}} containerId="alert-device-box" /> 
        :
        <h1 style={{ padding: "15px 15px 15px 10px",fontSize:'16px', color: "gray" }}>
                      <IntlMessages id="No data found" />
                    </h1>
        }
      </Box>
      </div> 
      <Button className="reload-button-alert" onClick={reloadDataAlert} >
         { loading ? <SyncOutlined spin /> : <SyncOutlined />}
      </Button>
      <StopDeviceAlarmRequestModal stopAlarmRequests={stopAlarmRequests} visible={isVisibleStopAlarmRequestModal} cancelModal={cancelStopAlarmModal}/>
    </AlertDeviceWrapper>
  );
}

export default AlertDevice;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;