import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Checkbox, Space, Popconfirm } from 'antd';
import EditFieldModalWrapper from './EditFieldModal.styles';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { commonHelper } from '@iso/lib/helpers/commonHelper';
import SignaturePad from '@iso/components/SignaturePad/index';
import DragDropQuestion from "@iso/components/DragDropQuestion/DragDropQuestion";

const EditFieldModal = (props) => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
    const [fields, setFields] = useState([]);
	
	const handleCancel = () => {
		props.cancelFieldModal();
		form.resetFields();
	}

	useEffect(() => {
		if(!_.isEmpty(props.fieldData))
		{
			setFields([
				{
					name: 'label',
              		value: props.fieldData?.label
				},
				{
					name: 'required',
              		value: props.fieldData?.required
				},
				{
					name: 'tooltip',
              		value: props.fieldData?.tooltip
				},
				{
					name: 'options',
              		value: props.fieldData?.options
				}
			])
			if(props.fieldData.widgetLabel === "dragdrop"){
				setFields([
					{
						name: 'label',
						  value: props.fieldData?.label
					},
					{
					  name: "question",
					  value: props.fieldData?.question,
					},
					{
					  name: "options",
					  value: props.fieldData?.options,
					},
				  ]);
			}
		}
	}, [props.isVisibleFieldModal, props.fieldData])

	const handleSubmit = () => {
        setLoading(true);
        form
          .validateFields()
          .then(values => {
            onStore(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
            setLoading(false);
        });
    }

	const getFieldData = (key, widget, label, forwardRef = false, values = {}) => {
		var fieldData = {
			key,
			widget: widget,
			widgetLabel: widget,
			name: label ? label.toLowerCase().replace(/ /g, "_") : key,
			forwardRef,
			...values
		}
		
		if(widget === 'signature'){
			fieldData.widget = SignaturePad;
			fieldData.forwardRef = true;
			fieldData.initialValue = {
				signatures: []
			}
		}

		if (widget === "dragdrop") {
			let options = _.map(fieldData.options, ({ option, answer }) => {
			  return {
				id: _.kebabCase(option) + "-" + commonHelper.generateRandomString(3),
				text: option,
			  };
			});
			console.log("option", options);
			const initialValue = {
			  question: fieldData.question,
			  options,
			};
			fieldData.widget = DragDropQuestion;
			fieldData.initialValue = initialValue;
			fieldData.forwardRef = true;
			
			fieldData.name = fieldData.name + "---" + commonHelper.generateRandomString(3)
		  }

		if(widget === 'radio-group'){
			fieldData.forwardRef = true;
		}
		return fieldData
	}
	const onStore = (values) => {
		var formFields = _.clone(props.formFields);
		
		const Index = _.findIndex(formFields.fields, (field) => {
          return field.key == props.fieldData.key;
        });
         if(Index === -1 || _.isEmpty(props.fieldData)){
			var key = props.fieldKey + '-' + commonHelper.generateRandomString(10);
			var fieldData = getFieldData(key, props.fieldKey, values.label, false, values);
          	formFields.fields.push(fieldData);
        } else {
			var fieldData = getFieldData(props.fieldData.key, props.fieldData.widgetLabel, values.label, props.fieldData.forwardRef, values);
          	formFields.fields[Index] = fieldData;
        }
		props.resetFormFields(formFields);
		setLoading(false);
		handleCancel();
	}

	const onRemoveField = () => {
		var formFields = _.clone(props.formFields);

		const Index = _.findIndex(formFields.fields, (field) => {
          return field.key == props.fieldData.key;
        });

        if(Index !== -1)
        {
        	formFields.fields.splice(Index, 1);
        }

        props.resetFormFields(formFields);
		setLoading(false);
		handleCancel();
	}

	const renderDragDropForm = () => {
		return (
			<Form form={form} fields={fields} layout="vertical">
				<Form.Item
					name="label"
					className="isoInputWrapper"
					label="Label"
					rules={[
					{
						required: true,
						message: "Please input Label!",
					},
					]}
				>
					<Input size="large" placeholder="Label" />
				</Form.Item>	
			  <Form.Item
				name="question"
				className="isoInputWrapper"
				label={
				  <div>
					<p>Question ( Use 3 underscores to mark blanks)</p>
					<p style={{ fontStyle: "italic" }}>eg: This largest animal is ___ in the world</p>
				  </div>
				}
				rules={[
				  {
					required: true,
					message: "Please input Label!",
				  },
				]}
			  >
				<Input size="large" placeholder="Question" />
			  </Form.Item>
			  <Form.Item
				name="options"
				className="isoInputWrapper"
				label="Options"
				rules={[
				  {
					required: true,
					message: "Please add option!",
				  },
				]}
			  >
				<Form.List
				  name="options"
				  label="Options"
				  rules={[
					{
					  required: true,
					  message: "Please add option!",
					},
				  ]}
				  initialValue={[{ option: "Option 1", answer: 1 }]}
				>
				  {(fields, { add, remove }) => (
					<>
					  {fields.map(({ key, name, fieldKey, ...restField }) => (
						<div
						  key={key}
						  style={{ display: "flex", marginBottom: 8 }}
						  align="baseline"
						>
						  <Form.Item
							style={{ width: "100%", marginRight: "8px" }}
							{...restField}
							name={[name, "option"]}
							fieldKey={[fieldKey, "option"]}
							rules={[
							  { required: true, message: "This field is required" },
							]}
						  >
							<Input placeholder="Option" size="large" />
						  </Form.Item>
						  {/* <Form.Item
							style={{ marginRight: "8px" }}
							{...restField}
							name={[name, "answer"]}
							fieldKey={[fieldKey, "answer"]}
						  >
							<InputNumber placeholder="Answer" size="large" />
						  </Form.Item> */}
						  <MinusCircleOutlined
							style={{ marginTop: "14px" }}
							onClick={() => remove(name)}
						  />
						</div>
					  ))}
					  <Form.Item>
						<Button
						  type="dashed"
						  onClick={() => add()}
						  block
						  icon={<PlusOutlined />}
						>
						  Add option
						</Button>
					  </Form.Item>
					</>
				  )}
				</Form.List>
			  </Form.Item>
			</Form>
		)
	  }

    return (
        <EditFieldModalWrapper
          title="Field Modal"
          visible={props.isVisibleFieldModal} 
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel} className="cancel-button">
              Cancel
            </Button>,
            (props.fieldData?.key) && 
            <Popconfirm
              title="Are you sure to remove this field?"
              okText="Remove"
              cancelText="Cancel"
              onConfirm={onRemoveField}
            >
                <Button key="remove" type="primary" danger={true}>
	              Remove Field
	            </Button>
            </Popconfirm>,
            <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
              {_.isEmpty(props.fieldData) ? 'Add Field' : 'Update Field'}
            </Button>,
          ]}
        >
			{props.fieldKey === "dragdrop" || props.fieldData?.widgetLabel === "dragdrop" ? (
				renderDragDropForm()
			) : (
					<Form 
						form={form}
						fields={fields}
						layout="vertical"
					>
						<Form.Item
							name="label"
							className="isoInputWrapper"
							label="Label"
							rules={[
								{
									required: true,
									message: 'Please input Label!',
								},
							]}
						>
							<Input size="large" placeholder="Label"/>
						</Form.Item>
						<Form.Item
							name="required"
							className="isoInputWrapper"
							label="Required"
							valuePropName="checked"
						>
							<Checkbox></Checkbox>
						</Form.Item>
						<Form.Item
							name="tooltip"
							className="isoInputWrapper"
							label="Tooltip"
						>
							<Input size="large" placeholder="Tooltip"/>
						</Form.Item>
						{(props.fieldKey === 'select' || props.fieldKey === 'checkbox-group' || props.fieldKey === 'radio-group' 
							|| props.fieldData.widget === 'select' || props.fieldData.widget === 'checkbox-group' 
							|| props.fieldData.widget === 'radio-group' ) && <Form.Item
							name="options"
							className="isoInputWrapper"
							label="Options"
							rules={[
							{
								required: true,
								message: 'Please add option!',
							},
						]} 
						>
							<Form.List name="options" label="Options"
							rules={[
								{
									required: true,
									message: 'Please add option!',
								},
							]} 
							initialValue={[
								{ label: "Option 1", value: 'value_1' }
							]}>
								{(fields, { add, remove }) => (
								<>
									{fields.map(({ key, name, fieldKey, ...restField }) => (
										<Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
											<Form.Item
											{...restField}
											name={[name, 'label']}
											fieldKey={[fieldKey, 'label']}
											rules={[{ required: true, message: 'This field is required' }]}
											>
											<Input placeholder="Label" size="large" />
											</Form.Item>
											<Form.Item
											{...restField}
											name={[name, 'value']}
											fieldKey={[fieldKey, 'value']}
											rules={[{ required: true, message: 'This field is required' }]}
											>
											<Input placeholder="Value" size="large" />
											</Form.Item>
											<MinusCircleOutlined onClick={() => remove(name)} />
										</Space>
									))}
									<Form.Item>
									<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
										Add option
									</Button>
									</Form.Item>
								</>
								)}
							</Form.List>
						</Form.Item>}
					</Form>
			)}
        </EditFieldModalWrapper>
    )
}

export default EditFieldModal;
