import { commonHelper } from './commonHelper';

export const dateHelper = {
  dateForAPI: (date) => {
    return commonHelper.convertUtcToLocalDate(date, 'YYYY-MM-DD');
  },
  getMonth: (date) => {
  	return commonHelper.convertUtcToLocalDate(date, 'MM');
  },
  getYear: (date) => {
  	return commonHelper.convertUtcToLocalDate(date, 'YYYY');
  },
  getTime: (date) => {
  	return commonHelper.convertUtcToLocalDate(date, 'HH:mm:ss');
  },
  dateTimeForAPI: (date) => {
    return commonHelper.convertUtcToLocalDate(date, 'YYYY-MM-DD HH:mm:ss');
  },
  dateTimeForSAR: (date) => {
    return commonHelper.convertUtcToLocalDateForSar(date, 'YYYY-MM-DD HH:mm');
  },

}