import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@iso/components/uielements/menu';
import IntlMessages from '@iso/components/utility/intlMessages';
import { propertyHelper } from "@iso/lib/helpers/propertyHelper";
const SubMenu = Menu.SubMenu;

const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};



export default React.memo(function SidebarMenu({
  singleOption,
  submenuStyle,
  submenuColor,
  stripePlan,
  ...rest
}) {

  let match = useRouteMatch();
  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);

  const { key, label, leftIcon, children } = singleOption;
  const url = stripTrailingSlash(match.url);

  if (children ) {
    return (
      <SubMenu
        key={key}
        title={
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        }
        {...rest}
      >
        {children.map(child => {
          const linkTo = child.withoutDashboard
            ? `/${child.key}`
            : `${url}/${child.key}`;
          return (
            <Menu.Item style={submenuStyle} key={child.key}>
              <Link style={submenuColor} to={linkTo}>
                <IntlMessages id={child.label} />
              </Link>
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  }
  if(key !== 'health-safety' && key !=='safe-farm'){
    return (
      <Menu.Item key={key} {...rest}>
      {key == 'weather' 
        ?
        <a href={`${url}/${key}`} id="custom-menu-icon">
          <span className="isoMenuHolder" style={submenuColor}>    
            {leftIcon}
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        </a>
        :
        <Link to={`${url}/${key}`} id="custom-menu-icon">
          <span className="isoMenuHolder" style={submenuColor}>    
            {leftIcon}
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        </Link>
      }
        
      </Menu.Item>
    );
  }else{
      if(propertyHelper.checkIsGoldPackage(activePropertySubscription)){
        
        return (
          <Menu.Item key={key} {...rest}>

            <Link to={`${url}/${key}`} id="custom-menu-icon">
              <span className="isoMenuHolder" style={submenuColor}>    
                {leftIcon}
                <span className="nav-text">
                  <IntlMessages id={label} />
                </span>
              </span>
            </Link>
          </Menu.Item>
        
        );
      }
    
 
  }
    
  


});
