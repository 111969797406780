import React , { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col,Select, Switch} from 'antd';
import IntlMessages from '@iso/components/utility/intlMessages';
import ModalCategoryFormWrapper from './ModalCategoryForm.styles';
import styled from "styled-components";
import _ from 'lodash';
import { categoryGlobalFormService } from '@iso/services'

const { Option } = Select;

const ModalCategoryForm = (props) => {
  const {setVisibleCategoryFormModal,visibleCategoryFormModal,categoryId,setCategoryId,dataCategoryForm,getCategoryForm} = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [options,setOptions] = useState([]);
  const [valueOptions, setValueOptions] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setOptions([]);
    setValueOptions([]);
    getGlobalForms(categoryId);
  }, [categoryId]);

  useEffect(() => {
    setFields([
      {
        name: 'name',
        value: ''
      },
      {
        name: 'description',
        value: ''
      }
    ]);
    setChecked(false);
  }, [visibleCategoryFormModal]);

  useEffect(() => {
    if(dataCategoryForm){
    setFields([
        {
          name: 'name',
          value: dataCategoryForm?.name
        },
        {
          name: 'description',
          value: dataCategoryForm?.description
        }
      ]);
    }
    if(dataCategoryForm.is_live == 1){
      setChecked(true);
    }
   },[dataCategoryForm]);

  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
    });
  }
  const onStore = (values) => {

    let data = {
      global_form_id: valueOptions,
      category_global_form: {
        ...values,
        is_live: checked ? 1 : 0,
      }
    }
    categoryGlobalFormService.storeOrUpdate(data,categoryId).then(res =>{
      if(res.code === "0000"){
        setLoading(false);
        getCategoryForm();
        setVisibleCategoryFormModal(false);
      }
    });
  }

  const onChangeSwitch = (checked) => {
    setChecked(checked);
  }

  const onChangeSelect = (value) => {
    setValueOptions(value);
  }
  const getValueOptions = () => {

  }
  const getGlobalForms = (id) => {
    categoryGlobalFormService.getGlobalForms(id).then(res=>{
      if(res.code === "0000"){
        _.forEach(res.global_froms, function(value) {
          if(id == value.category_global_form_id && value.category_global_form_id !== ''){
            setValueOptions(oldValue => [...oldValue,value.id]);
          }
          setOptions(oldValue => [...oldValue,{
            label: value.name,
            value: value.id
          }])
        });
      }
    });
  }

  const onCancelModal = () => {
    setVisibleCategoryFormModal(false);
    setCategoryId('');
  }

  return (
    <ModalCategoryFormWrapper
      className="user-access-modal"
      visible={visibleCategoryFormModal}
      title={ <IntlMessages id="sidebar.categoryForm" /> }
      onCancel={onCancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form 
        form={form}
        fields={fields}
        layout="vertical"
      >
        <Form.Item
          name="name"
          className="isoInputWrapper"
          label="Name"
          rules={[
            {
                required: true,
                message: 'Please input Name!',
            },
          ]}
        >
          <Input size="large" placeholder="Name"/>
        </Form.Item>
        <Form.Item
          name="description"
          className="isoInputWrapper"
          label="Description"
          rules={[
            {
                required: true,
                message: 'Please input description!',
            },
          ]}
        >
          <Input.TextArea size="large" placeholder="Description"/>
        </Form.Item>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select question"
          onChange={onChangeSelect}
          value={valueOptions}
          options={options}
        />
        
        <Switch checkedChildren="Live" unCheckedChildren="Draft" checked={checked} onChange={onChangeSwitch}/>
      </Form>
    </ModalCategoryFormWrapper>
  );
}

export default ModalCategoryForm;

