import React, { useState, useEffect } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import WorkingRightModalWrapper from "./WorkingRightModal.style";
import { Form, Input, Button, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { workingRightService } from "@iso/services";
import _ from "lodash";
import $ from "jquery";
import { apiEndpoint } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";
import { workingRights } from "@iso/constants/workingRights";

const { Option } = Select;

const WorkingRightModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [dataWorkingRight, setDataWorkingRight] = useState({});
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [imageId, setImageId] = useState(null);

  useEffect(() => {
    setDataWorkingRight(props.workingRightData);
  }, [props.workingRightData, props.visibleWorkingRightModal]);

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        onStore(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
    setLoading(false);
  };

  const onStore = (values) => {
    const data = {
      working_right: {
        ...values.working_right,
        user_id: currentUser.id,
        image_id: imageId,
      },
    };

    workingRightService.storeOrUpdate(data, dataWorkingRight.id).then((res) => {
      if (res.code === "0000") {
        props.onSaved();
        props.cancelModal();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  };
  const cancelWorkingRightModal = () => {
    if (props.viewSummary) {
      props.setViewSummary(false)
    }else{
    props.cancelModal();
    form.resetFields();
    }
    props.setTitle("Add Attachment")
  };

  useEffect(() => {
    setFields([
      {
        name: ["working_right", "name"],
        value: dataWorkingRight.name || "",
      },
      {
        name: ["working_right", "type"],
        value: dataWorkingRight.type || "",
      },
    ]);
  }, [dataWorkingRight, props.visibleWorkingRightModal]);

  const renderOptionWorkingRights = () => {
    let options = [];
    _.forEach(workingRights, (workingRight, index) => {
      options.push(
        <Option key={index} value={workingRight.value}>
          {workingRight.label}
        </Option>
      );
    });
    return (
      <Select allowClear size="large">
        {options}
      </Select>
    );
  };

  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      let $oldOne = $(".anticon-delete")[0];
      setTimeout(() => {
        if ($oldOne) $($oldOne).trigger("click");
      }, 100);
    },
    onChange: (response) => {
      if (response.file && response.file.status == "done") {
        setImageId(response.file.response.image.id);
      }
    },
    listType: "picture",
    multiple: false,
    data: {
      storage: "s3",
      type: "working_rights",
    },
  };

  return (
    <>
      <WorkingRightModalWrapper
       open={props.viewSummary?props.viewSummary:props.visibleWorkingRightModal}
      
        onCancel={cancelWorkingRightModal}
        maskClosable={false}
        centered
        title={props.title}
        footer={[
          <Button
            key="back"
            onClick={cancelWorkingRightModal}
            className="cancel-button"
            hidden ={props.viewSummary ? true:false}
          >
            {<IntlMessages id="propertyPage.modal.cancel" />}
          </Button>,
          <Button
            key="submit"
            className="save-button"
            hidden ={props.viewSummary ? true:false}
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={props.isDisableForm ? true : false}
          >
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
          disabled={props.isDisableForm ? true : false}
        >
          <div className="form-body">
            <Form.Item
              name={["working_right", "type"]}
              className="isoInputWrapper"
              label="Working Right Type"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              {renderOptionWorkingRights()}
            </Form.Item>
            <Form.Item
              name={["working_right", "name"]}
              className="isoInputWrapper"
              label="Name"
              rules={[
                {
                  max: 255,
                  message: "Name must be maximum 255 characters.",
                },
              ]}
            >
              <Input size="large" placeholder="Input Name" />
            </Form.Item>
            <>
              <Form.Item name={["attachment"]} label="Attachment"  className="isoInputWrapper">
                <Upload {...uploadProps}  className='action-button'>
                  <Button className="action-button"  icon={<img src={process.env.PUBLIC_URL +"./images/icons/export.png"}/>}>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
              {dataWorkingRight.images &&
                dataWorkingRight.images.length > 0 && (
                  <img
                    style={{ width: "50%" }}
                    src={
                      dataWorkingRight.images[
                        dataWorkingRight.images.length - 1
                      ].full_path
                    }
                  />
                )}
            </>
          </div>
        </Form>
      </WorkingRightModalWrapper>
    </>
  );
};

export default WorkingRightModal;
