import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Alert, message, Input } from 'antd';
import EditFieldModalWrapper from './EditFieldModal.styles';
import FormBuilder from 'antd-form-builder';
import { globalFormService } from '@iso/services';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

const PreviewFormModal = (props) => {
	const formRef = React.createRef();
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [formName, setFormName] = useState('');
	const [fields, setFields] = useState([]);
	const [formDescription, setFormDescription] = useState('');
	const [form] = Form.useForm();
	let history = useHistory();
  const formId = queryString.parse(window.location.search).form_id || '';

	useEffect(() => {
		if(props.globalForm)
		{
			setFields(
				[
          {
              name: ['global_form', 'name'],
              value: props.globalForm?.name
          },
          {
              name: ['global_form', 'description'],
              value: props.globalForm?.description
          }
        ]
      )
      setFormName(props.globalForm?.name);
      setFormDescription(props.globalForm?.description);
		}
	}, [props.isVisiblePreviewModal])

	const handleSubmit = () => {
        setLoading(true);
        form
          .validateFields()
          .then(values => {
            handleFinish(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
            setLoading(false);
        });
    }

	const handleFinish = (values) => {
		const data = {
			global_form: {
				...values.global_form,
				meta_fields: props.formFields
			}
		}
    globalFormService.storeOrUpdate(data, props.globalForm?.id).then(res => {
          if(res.code === '0000'){
              setLoading(false);
              message.success('Add Form success!');
              handleCancel();
              props.getFormData(res.global_form.id)
              if(!formId || formId === '')history.push('/manage-form')
          }else{
              setLoading(false);
              setErrorMsg(res.message);
          }
      });
	}
	
	const handleCancel = () => {
		props.cancelPreviewModal();
		form.resetFields();
	}

	const onChangeFormName = (e) => {
		setFormName(e.target.value);
	}

	const onChangeFormDescription = (e) => {
		setFormDescription(e.target.value);
	}

    return (
        <EditFieldModalWrapper
          title="Preview Form"
          visible={props.isVisiblePreviewModal} 
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel} className="cancel-button">
              Cancel
            </Button>,
            (!props.readOnly) && <Button key="submit" onClick={handleSubmit} type="primary" loading={loading} className="btn-success">
              Save Form
            </Button>
          ]}
        >
        	{(!props.readOnly) && <Form
	            form={form}
	            layout="vertical"
	            fields={fields}
	          >
	          	<Form.Item
                  name={['global_form', 'name']}
                  className="isoInputWrapper"
                  label="Form Name"
                  rules={[
                      {
                          required: true,
                          message: 'Please input Form Name!',
                      },
                  ]}
              >
                  <Input size="large" placeholder="Form Name" onChange={onChangeFormName}/>
              </Form.Item>
              <Form.Item
                  name={['global_form', 'description']}
                  className="isoInputWrapper"
                  label="Form Description"
                  rules={[
                      {
                          required: true,
                          message: 'Please input Form Description!',
                      },
                  ]}
              >
                  <Input.TextArea size="large" placeholder="Form Description" onChange={onChangeFormDescription}/>
              </Form.Item>
	            {errorMsg && errorMsg !== '' &&
	              <Alert message={errorMsg} type="error" />
	            }
	          </Form>
            }
	          <Form layout="vertical" className="preview-form-area">
	          	<h3>{formName}</h3>
	          	<hr/>
              <p>{formDescription}</p>
	            <FormBuilder meta={props.formFields} form={formRef}/>
	            <hr/>
	            <Form.Item className="form-footer">
	              <Button type="primary" className="btn-success">
	                Submit
	              </Button>
	            </Form.Item>
	          </Form>
        </EditFieldModalWrapper>
    )
}

export default PreviewFormModal;
