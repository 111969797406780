import React , { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Row, Col, Select } from 'antd';
import { propertyService, googleService } from '@iso/services';
import IntlMessages from '@iso/components/utility/intlMessages';
import PropertyModalWrapper from './PropertyModal.styles';
import _ from 'lodash';
import { propertyHistoryCategories } from '@iso/constants/propertyHistoryCategories';
import UserHistory from './UserHistory';
import MobHistory from './MobHistory';
import LivestockHistory from './LivestockHistory';
import WeatherHistory from './WeatherHistory';
import AssetHistory from './AssetHistory';

const { Option } = Select;

const PropertyHistoryModal = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);

  const cancelModal = () => {
    props.cancelHistory();
  }

  const renderOptionsPropertyHistoryCategory = () => {
    let options = [];
     _.forEach(propertyHistoryCategories, (category, index) => {
      options.push(
        <Option key={index} value={category.value}>
            <div>{category.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  useEffect(() => {
    setFields([
      {
        name: ['property_history', 'type'],
        value: 'user'
      }
    ]);
  }, [props.visibleHistoryModal]);

  return (
    <PropertyModalWrapper
      forceRender={true}
      visible={props.visibleHistoryModal}
      title={<IntlMessages id="liveStockPage.table.viewHistory" />}
      onCancel={cancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button" style={{width:'100%',backgroundColor:'#ABABAB'}}>
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" scrollToFirstError fields={fields} className="form-body">
        <Form.Item
          name={['property_history', 'type']}
          className="isoInputWrapper typelabel"
          label="Type"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          {renderOptionsPropertyHistoryCategory()}
        </Form.Item>
        <Form.Item noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues['property_history'];
          }}
        >
          {({ getFieldValue }) => {
              if(getFieldValue('property_history')){
                if (getFieldValue('property_history').type === "user"){
                  return <UserHistory propertyId={props.propertyId}/>
                }else if(getFieldValue('property_history').type === "mob"){
                  return <MobHistory propertyId={props.propertyId}/>
                }else if(getFieldValue('property_history').type === "livestock"){
                  return <LivestockHistory propertyId={props.propertyId}/>
                }else if(getFieldValue('property_history').type === "asset"){
                  return <AssetHistory propertyId={props.propertyId}/>
                }else{
                  return <WeatherHistory propertyId={props.propertyId}/>
                }
              }
            }
          }
        </Form.Item>
      </Form>
      
      
    </PropertyModalWrapper>
  );
}

export default PropertyHistoryModal;