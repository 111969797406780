import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import SplitModalWrapper from './SplitModal.styles';
import { Form, Button, DatePicker, Input, InputNumber, Select, Row, Col, Radio } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '@iso/components/utility/loader';
import { activityService, feedingTypeService, livestockService } from '@iso/services';
import moment from 'moment';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import _ from 'lodash';
import styled from "styled-components";
import { mobActivityCategories } from '@iso/constants/mobActivityCategories';
import { activityCategories } from '@iso/constants/activityCategories';
import { drugUnit } from '@iso/constants/drugUnit';
import { pregTesting } from '@iso/constants/pregTesting';
import FeedingTypeModalWrapper from './FeedingTypeModal.styles';
import { objectColors } from '@iso/constants/objectColors';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const { Option } = Select;

const EditHistoryModal = (props) => {
  const [form] = Form.useForm();
  const [feedingTypeForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [loading, setLoading] = useState(false);
  const [historySalePrice, setHistorySalePrice] = useState();
  const [historySaleWeight, setHistorySaleWeight] = useState();
  const [historySalePricePerWeight, setHistorySalePricePerWeight] = useState();
  const [feedingTypes, setFeedingTypes] = useState({});
  const [visibleFeedingType, setVisibleFeedingType] = useState(false);
  const [loadingFeedingType, setLoadingFeedingType] = useState(false);
  const [assets, setAssets] = useState([]); 

  useEffect(() => {
    if(props.propertyId){
       livestockService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setAssets(res.assets);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId, props.visibleEditHistoryModal]);

  useEffect(() => {
    if(props.propertyId){
       feedingTypeService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setFeedingTypes(res.feeding_types);
          setLoadingFeedingType(false);
        }
      });
    }
  }, [props.propertyId]);

  const cancelModal = () => {
    props.cancelEditHistoryModal();
    form.resetFields();
  }

  const cancelModalFeedingType = () => {
    setVisibleFeedingType(false);
  }

  const handleFeedingTypeSubmit = (e) => {
    feedingTypeForm
      .validateFields()
      .then(feeding_type_values => {
        onStoreFeedingType(feeding_type_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingFeedingType(false);
      });
  }

  const onStoreFeedingType = (feeding_type_values) => {
    const feeding_type_data = {
      "feeding_type":{
        ...feeding_type_values.feeding_type,
        property_id: props.propertyId
      }
    }

    feedingTypeService.storeOrUpdate(feeding_type_data).then(res => {
      if(res.code === '0000'){
        var tmp_feeding_types = _.clone(feedingTypes);
        tmp_feeding_types.push(res.feeding_type);
        setFeedingTypes(tmp_feeding_types);
        cancelModalFeedingType();
        feedingTypeForm.resetFields();
        setFields([
          {
            name: ['history', 'feeding_type'],
            value: res.feeding_type.name,
          },
        ]);
      } else {
        setLoadingFeedingType(false);
      }
    });

  }

  useEffect(() => {
    if(props.historyData){

        if(props.historyData.metadata)
        {
          let history_metadata = props.historyData.metadata[Object.keys(props.historyData.metadata)[0]];
          let created_at = '';
          if(props.historyData.created_at){
            created_at = moment(props.historyData.created_at);
          };
          setFields([
            {
              name: ['history', 'created_at'],
              value: ( (typeof history_metadata.created_at !== 'undefined') && history_metadata.created_at !== '' ) ? moment(history_metadata.created_at) : created_at ,
            },
            {
              name: ['history', 'activity_category'],
              value: history_metadata.activity_category,
            },
            {
              name: ['history', 'number_of_animals'],
              value: history_metadata.number_of_animals,
            },
            {
                name: ['history', 'price_per_weight'],
                value: history_metadata.price_per_weight,
            },
            {
              name: ['history', 'average_weight'],
              value: history_metadata.average_weight,
            },
            {
              name: ['history', 'sale_price'],
              value: history_metadata.sale_price,
            },
            {
              name: ['history', 'treatment_name'],
              value: history_metadata.treatment_name,
            },
            {
              name: ['history', 'treatment_quantity'],
              value: history_metadata.treatment_quantity,
            },
            {
              name: ['history', 'treatment_unit'],
              value: history_metadata.treatment_unit,
            },
            {
              name: ['history', 'treatment_WHP'],
              value: history_metadata.treatment_WHP,
            },
            {
              name: ['history', 'treatment_ESI'],
              value: history_metadata.treatment_ESI,
            },
            {
              name: ['history', 'feeding_type'],
              value: history_metadata.feeding_type,
            },
            {
              name: ['history', 'feeding_quantity'],
              value: history_metadata.feeding_quantity,
            },
            {
              name: ['history', 'joining_siren'],
              value: history_metadata.joining_siren,
            },
            {
              name: ['history', 'joining_expected_duration'],
              value: history_metadata.joining_expected_duration,
            },
            {
              name: ['history', 'marking_system_date'],
              value: history_metadata.marking_system_date ? moment(history_metadata.marking_system_date) : ''
            },
            {
              name: ['history', 'crutching_system_date'],
              value: history_metadata.crutching_system_date ? moment(history_metadata.crutching_system_date) : ''
            },
            {
              name: ['history', 'shearing_system_date'],
              value: history_metadata.shearing_system_date ? moment(history_metadata.shearing_system_date) : ''
            },
            {
              name: ['history', 'other_title'],
              value: history_metadata.other_title
            },
            {
              name: ['history', 'other_comment'],
              value: history_metadata.other_comment
            },
            {
              name: ['history', 'other_location'],
              value: history_metadata.other_location
            },
            {
              name: ['history', 'weight'],
              value: history_metadata.weight
            },
            {
              name: ['history', 'preg_testing'],
              value: history_metadata.preg_testing
            },    
            {
              name: ['history', 'justation'],
              value: history_metadata.justation
            }
          ]);

          setHistorySalePrice(history_metadata.sale_price);
          setHistorySaleWeight(history_metadata.average_weight);
        }
        
    }
  }, [props.visibleEditHistoryModal]);

   useEffect(() => {
    if(historySalePrice && historySaleWeight){
      setFields([
        {
          name: ['history', 'price_per_weight'],
          value: historySalePrice / historySaleWeight,
        },
      ]);
    }
  }, [historySalePrice, historySaleWeight]);

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const addFeedingType = (object, e) => {
    setVisibleFeedingType(true);
  }

  const onStore = (values) => {
    const data = {
      history: {
        metadata: values.history,
        created_at: values.history.created_at ? dateHelper.dateForAPI(values.history.created_at) : '',
        action:'create',
        model: props.model,
        object_id: props.historyData.object_id,
        type: props.dataType,
        property_id: props.propertyId
      }
    }

    activityService.storeOrUpdate(data, props.historyData.id).then(res => {
      if(res.code === '0000'){
        props.updateDataHistory(res.history.object_id, props.dataType);
        cancelModal();
        form.resetFields();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  const onChangeSalePrice = e => {
    setHistorySalePrice(e);
  }

  const onChangeSaleWeight = e => {
    setHistorySaleWeight(e);
  }

  const renderOptionsMobActivityCategories = () => {
    let activity_options = [];
     _.forEach(mobActivityCategories, (activity, index) => {
      activity_options.push(
        <Option key={index} value={activity.value}>
            <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select type of history"
        allowClear
        size="large"
        disabled={true}
      >
        {activity_options}
      </Select>
    );
  }

  const renderOptionMaleAsset = (asset_data) => {
    let options = [];
    
    _.forEach(asset_data, (asset, index) => {
      if(asset.sex && asset.id !== props.dataId && asset.displayed_name){
        options.push(
          <Option key={asset.id} value={asset.displayed_name} label={asset.displayed_name}> 
            {objectColors.map((value, index) => {
            if(value.value == asset.tag_colour){
              return (
                <ColorItemRow>
                  <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                  <div>{asset.displayed_name}</div>
                </ColorItemRow>
                )
            }
            })}
          </Option>
        );
      }
    })
    return (
      <Select
        placeholder="Select a livestock"
        allowClear
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionsLiveStockActivityCategories = () => {
    let activity_options = [];
     _.forEach(activityCategories, (activity, index) => {
      activity_options.push(
        <Option key={index} value={activity.value}>
            <div>{activity.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select type of history"
        allowClear
        size="large"
        listHeight="300"
        disabled={true}
      >
        {activity_options}
      </Select>
    );
  }

  const renderOptionsDrugUnit = () => {
    let unit_options = [];
     _.forEach(drugUnit, (unit, index) => {
      unit_options.push(
        <Option key={index} value={unit.value}>
            <div>{unit.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select unit"
        allowClear
        size="large"
      >
        {unit_options}
      </Select>
    );
  }

  const renderOptionsPregTesting = () => {
    let preg_options = [];
     _.forEach(pregTesting, (preg, index) => {
      preg_options.push(
        <Option key={index} value={preg.value}>
            <div>{preg.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select preg testing"
        allowClear
        size="large"
      >
        {preg_options}
      </Select>
    );
  }

  const renderOptionsFeedingType = (feeding_type_options) => {
    let feeding_options = [];
     _.forEach(feeding_type_options, (feeding_type, index) => {
      feeding_options.push(
        <Option key={index} value={feeding_type.name}>
            <div>{feeding_type.name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a feeding type"
        allowClear
        size="large"
      >
        {feeding_options}
      </Select>
    );
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.name}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }


  return(
    <SplitModalWrapper
      visible={props.visibleEditHistoryModal}
      onCancel={cancelModal}
      maskClosable={false}
      title={<IntlMessages id="propertyPage.modal.mob.history"/>}
      footer={[
        <Button key="back" className="cancel-button" onClick={cancelModal}>
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Button key="submit" className="save-button" type="primary" onClick={handleSubmit}  loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
        className="form-body"
      >
        <Form.Item
          name={['history', 'activity_category']}
          className="isoInputWrapper"
          label="Type of History"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
        {(props.dataType === 'mob') ? renderOptionsMobActivityCategories() : renderOptionsLiveStockActivityCategories()}
        </Form.Item>
        <Form.Item
          name={['history', 'created_at']}
          className="isoInputWrapper"
          label="Date"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
           <DatePicker size="large" style={{width: '100%'}} format={dateFormatList} />
        </Form.Item>
        <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues['history'];
              }}
            >
            {({ getFieldValue }) => {
              if(getFieldValue('history')){
                if (getFieldValue('history').activity_category === "moving"){
                  return (
                    <div>
                      <Form.Item
                            name={['history', 'time']}
                            className="isoInputWrapper"
                            label="Time"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                          <Radio.Group defaultValue="AM" buttonStyle="solid">
                            <Radio.Button value="AM">AM</Radio.Button>
                            <Radio.Button value="PM">PM</Radio.Button>
                          </Radio.Group>
                      </Form.Item>
                       
                    </div>)
                }
                if (getFieldValue('history').activity_category === "treatment"){
                  return (<div><Form.Item
                                name={['history', 'treatment_name']}
                                className="isoInputWrapper"
                                label="Treatment Name"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input size="large" placeholder="Input treatment name"/>
                          </Form.Item>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['history', 'treatment_quantity']}
                                className="isoInputWrapper"
                                label="Quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={1000} />
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['history', 'treatment_unit']}
                                className="isoInputWrapper"
                                label="Unit"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              {renderOptionsDrugUnit()}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={{span: 24}} md={{span: 11}}>
                              <Form.Item
                                name={['history', 'treatment_WHP']}
                                className="isoInputWrapper"
                                label="WHP (Number in days)"
                              >
                              <InputNumber size="large" min={1} max={1000} />
                              </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                              <Form.Item
                                name={['history', 'treatment_ESI']}
                                className="isoInputWrapper"
                                label="ESI (Number in days)"
                              >
                              <InputNumber size="large" min={1} max={1000} />
                              </Form.Item>
                            </Col>
                          </Row>
                          </div>)
                }else if(getFieldValue('history').activity_category === "feeding"){
                  return (<div>
                            <Row>
                              <Col xs={{span: 12}} md={{span: 16}}>
                                <Form.Item
                                  name={['history', 'feeding_type']}
                                  className="isoInputWrapper"
                                  label="Feeding Type"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                {renderOptionsFeedingType(feedingTypes)}
                                </Form.Item>
                              </Col>
                              <Col xs={{span: 10, offset: 2}} md={{span: 6, offset: 2}}>
                                <Button key="submit" className="btn-create-feeding-type" type="primary" onClick={addFeedingType}>
                                  {<IntlMessages id="propertyPage.modal.feedingType.addFeedingType.title" />}
                                </Button>
                              </Col>
                            </Row>
                            <Form.Item
                                name={['history', 'feeding_quantity']}
                                className="isoInputWrapper"
                                label="Quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={1000} />
                            </Form.Item>
                            <FeedingTypeModalWrapper
                              visible={visibleFeedingType}
                              onCancel={cancelModalFeedingType}
                              centered
                              maskClosable={false}
                              title={ <IntlMessages id="propertyPage.modal.feedingType.addFeedingType.title"/> }
                              footer={[        
                                <Button key="back" onClick={cancelModalFeedingType} className="cancel-button">
                                  {<IntlMessages id="propertyPage.modal.cancel" />}
                                </Button>,
                                <Button key="submit" className="btn-success" type="primary" onClick={handleFeedingTypeSubmit} loading={loadingFeedingType}>
                                  {<IntlMessages id="propertyPage.modal.save" />}
                                </Button>,
                              ]}
                            >
                            {
                            
                                  <Form
                                    form={feedingTypeForm}
                                    layout="vertical"
                                    scrollToFirstError
                                  >
                                  <div className="form-body">
                                    <Form.Item
                                      name={['feeding_type', 'name']}
                                      className="isoInputWrapper"
                                      label="Feeding Type"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'This field is required',
                                        },
                                      ]}
                                    >
                                      <Input size="large" placeholder="Input feeding type"/>
                                    </Form.Item>
                                    </div>    
                              </Form>
                            }
                            </FeedingTypeModalWrapper>
                          </div>
                          )
                }else if(getFieldValue('history').activity_category === "joining"){
                  return (<div>   
                            <Form.Item
                              name={['history', 'joining_siren']}
                              className="isoInputWrapper"
                              label="Sire"
                            >
                             {renderOptionMaleAsset(assets)}
                            </Form.Item>
                        
                            <Form.Item
                              name={['history', 'joining_expected_duration']}
                              className="isoInputWrapper"
                              label="Expected Duration (Number of weeks)"
                              rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                              ]}
                            >
                            <InputNumber size="large" min={1} max={1000} />
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "marking"){
                  return (<div>
                            <Form.Item
                                name={['history', 'marking_system_date']}
                                className="isoInputWrapper"
                                label="Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                             <DatePicker size="large" style={{width: '100%'}}/>
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "weighing"){
                  return (<div>
                            <Form.Item
                              name={['history', 'weight']}
                              className="isoInputWrapper"
                              label="Weight (kg)"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                            <InputNumber size="large" min={1} max={1000} />
                          </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "preg_testing"){
                  return (<div>
                            <Form.Item
                                name={['history', 'preg_testing']}
                                className="isoInputWrapper"
                                label="Preg Testing"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              {renderOptionsPregTesting()}
                            </Form.Item>
                            {
                                (getFieldValue('history').preg_testing === 'pregnant' || 
                                getFieldValue('history').preg_testing === 'multiple')
                                &&
                                <Form.Item
                                  name={['history', 'justation']}
                                  className="isoInputWrapper"
                                  label="Gestation (Number of weeks)"
                                  rules={[
                                        {
                                          required: true,
                                          message: 'This field is required',
                                        },
                                  ]}
                                >
                                <InputNumber size="large" min={1} addonAfter="weeks"/>
                              </Form.Item>
                              }
                          </div>)
                }else if (getFieldValue('history').activity_category === "crutching"){
                  return (<div>
                            <Form.Item
                                name={['history', 'crutching_system_date']}
                                className="isoInputWrapper"
                                label="Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                             <DatePicker size="large" style={{width: '100%'}}/>
                            </Form.Item>
                          </div>)
                }else if (getFieldValue('history').activity_category === "shearing"){
                  return (<div>
                            <Form.Item
                                name={['history', 'shearing_system_date']}
                                className="isoInputWrapper"
                                label="Date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                             <DatePicker size="large" style={{width: '100%'}}/>
                            </Form.Item>
                          </div>)
                }else if (getFieldValue('history').activity_category === "death"){
                  return (<div>
                            <Form.Item
                                name={['history', 'number_of_animals']}
                                className="isoInputWrapper"
                                label="Number of Animals"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={props.numberAnimals} />
                            </Form.Item>
                            <Form.Item
                                name={['history', 'description']}
                                className="isoInputWrapper"
                                label="Description"
                              >
                             <Input.TextArea />
                            </Form.Item>
                          </div>)
                }else if ( getFieldValue('history').activity_category === 'sale'){
                  return (<div>
                            <Form.Item
                                name={['history', 'number_of_animals']}
                                className="isoInputWrapper"
                                label="Number of Animals"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <InputNumber size="large" min={1} max={props.numberAnimals} />
                            </Form.Item>
                            <SalePriceInput>
                              <Form.Item
                                name={['history', 'sale_price']}
                                className="isoInputWrapper"
                                label="Sales Price per Head"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                  {
                                    type: 'number',
                                  }
                                ]}
                                style={{width: '100%'}}
                              >
                                <InputNumber size="large" placeholder="Input sale price" style={{width: '100%'}} onChange={onChangeSalePrice}/>
                              </Form.Item>
                              <SuffixAddon>$</SuffixAddon>
                            </SalePriceInput>
                            <SalePriceInput>
                              <Form.Item
                                name={['history', 'average_weight']}
                                className="isoInputWrapper"
                                label="Average Weight"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                  {
                                    type: 'number',
                                  }
                                ]}
                                style={{width: '100%'}}
                              >
                                <InputNumber size="large" placeholder="Input average weight" style={{width: '100%'}} onChange={onChangeSaleWeight}/>
                              </Form.Item>
                              <SuffixAddon>kg</SuffixAddon>
                            </SalePriceInput>
                            <Form.Item
                                name={['history', 'price_per_weight']}
                                className="isoInputWrapper"
                                label="$ per kg"
                                rules={[
                                  {
                                    type: 'number',
                                  }
                                ]}
                                style={{width: '100%'}}
                              >
                                <Input size="large" style={{width: '100%'}} disabled/>
                              </Form.Item>
                            <Form.Item
                                name={['history', 'description']}
                                className="isoInputWrapper"
                                label="Description"
                              >
                             <Input.TextArea />
                            </Form.Item>
                          </div>)
                }else if(getFieldValue('history').activity_category === "other"){
                  return (<div>
                            <Form.Item
                                name={['history', 'other_title']}
                                className="isoInputWrapper"
                                label="Title"
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                              <Input size="large" placeholder="Input title"/>
                            </Form.Item>
                            <Form.Item
                                name={['history', 'other_comment']}
                                className="isoInputWrapper"
                                label="Comment"
                              >
                              <Input.TextArea />
                            </Form.Item>
                            <Form.Item
                                name={['history', 'other_location']}
                                className="isoInputWrapper"
                                label="Location"
                              >
                            {renderOptionObject(props.primaryObjects)}
                            </Form.Item>
                          </div>)
                }else {}
              }
            }}
            </Form.Item>
      </Form>
    </SplitModalWrapper>
  )
}

export default EditHistoryModal;

const SuffixAddon = styled.span`
  margin-top: 30px;
  margin-bottom: 5px;
  line-height: 38px;
  color: #747784!important;
  flex-shrink: 0;
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
  border-right-width: 1px!important;
  min-width: 36px;
  background-color: #f8f9fa;
  border: solid #d9d9d9;
  border-width: 1px 0;
  white-space: nowrap;
  text-align: center;
`;

const SalePriceInput = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;
