import React, { useState, useEffect } from 'react';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector } from 'react-redux';
import { Row, Col} from 'antd';
import DashboardWrapper from './Dashboard.styles'; 

const Dashboard = () => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
 
  return (
    <DashboardWrapper>
      <PageHeader>
        {<IntlMessages id="propertyPage.dashboard.header"/>}
      </PageHeader>
     <h1 className="admin-dashboard-title">Welcome to Airagri Dashboard</h1>
    </DashboardWrapper>
  );
}

export default Dashboard;
