import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  message,
  Col,
  Row,
  DatePicker,
  Input,
  Checkbox,
  Radio,
} from "antd";
import _ from "lodash";
import { formService, visitorFormService } from "@iso/services";
import IntlMessages from "@iso/components/utility/intlMessages";
import CheckinFormWrapper from "./CheckinForm.styles";
import moment from "moment";
import { dateHelper } from "@iso/lib/helpers/dateHelper";
import { loadScript } from "@iso/lib/helpers/googleApiHelper";

const { TextArea } = Input;

let autoComplete;

function handleAddressAutoComplete(
  setAddress,
  autoCompleteRef,
  setAddressShortName,
  setPoint
) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current.input,
    { types: ["address"] }
  );

  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () => {
    handlePlaceSelect(setAddress, setAddressShortName, setPoint);
  });
}

async function handlePlaceSelect(setAddress, setAddressShortName, setPoint) {
  const addressObject = autoComplete.getPlace();
  const fields = [
    {
      name: ["previous_property_visit"],
      value: addressObject.formatted_address,
    },
  ];
  setAddress(fields);
}

const CheckinForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const autoCompleteRef = useRef(null);

  const arrivalTimeConfig = {
    rules: [{ required: true, message: "Please select time!" }],
  };

  const handleSubmit = async () => {
    if (!props.isAbleToSubmit) {
      return;
    }
    if (props.visitorForm) {
      const visitorFormValidation = await props.visitorForm.validateFields();
      if (visitorFormValidation.errorFields) {
        return;
      }
    }
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        console.log(dateHelper.dateTimeForAPI(values.arrival_time));
        handleFinish(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (props.formData) {
      for (let field in props.formData) {
        if (
          ["arrival_time", "departure_time"].includes(field) &&
          props.formData[field]
        ) {
          form.setFieldValue(field, moment(props.formData[field]));
        } else {
          form.setFieldValue(field, props.formData[field]);
        }
      }
    }
  }, [props.formData]);
  const handleFinish = (values) => {
    const data = {
      form: {
        global_form: {
          ...values,
          arrival_time: values.arrival_time
            ? dateHelper.dateTimeForAPI(values.arrival_time)
            : null,
          departure_time: values.departure_time
            ? dateHelper.dateTimeForAPI(values.departure_time)
            : null,
        },
        type: "checkin_form",
      },
    };
    if (props.visitorForm) {
      data.visitor = props.visitorForm.getFieldsValue().visitor;
      data.form.visitor_form_token = props.visitorFormToken;
    }
    const handleFn = props.visitorForm
      ? visitorFormService.createVisitorForm(data)
      : formService.storeOrUpdate(data, props.formData.id);
    handleFn.then((res) => {
      if (res.code === "0000") {
        message.success("Submit success");
        props.setShowFinalScreen(true);
        setLoading(false);
      } else {
        message.error(res.message);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY_ADDRESS}&libraries=places`
    );

    if (autoCompleteRef) {
      document.addEventListener("google-script-loaded", handleAddress);
    }
  }, []);

  const handleAddress = () => {
    handleAddressAutoComplete(setFields, autoCompleteRef);
  };

  return (
    <CheckinFormWrapper>
      <Form form={form} layout="vertical" fields={fields} forceRender={true}>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <Form.Item
              name="arrival_time"
              label={<IntlMessages id="checkinForm.arrivalTime" />}
              {...arrivalTimeConfig}
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              name="departure_time"
              label={<IntlMessages id="checkinForm.departureTime" />}
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <Form.Item
              name={["mobile_phone"]}
              className="isoInputWrapper"
              label={<IntlMessages id="checkinForm.mobilePhone" />}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Input your number phone" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues;
              }}
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item
                    name={["previous_property_visit"]}
                    className="isoInputWrapper"
                    label={
                      <IntlMessages id="checkinForm.previousPropertyVisit" />
                    }
                  >
                    <Input
                      ref={autoCompleteRef}
                      size="large"
                      placeholder="Property Name / Property Address"
                      disabled={
                        getFieldValue("is_this_property") ? true : false
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              label=" "
              name="is_this_property"
              valuePropName="checked"
            >
              <Checkbox>
                <IntlMessages id="checkinForm.thisProperty" />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="reason"
          className="isoInputWrapper"
          label={<IntlMessages id="checkinForm.reasonForVisit" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="checkinForm.inductionDuration" />}
          name="induction_duration"
        >
          <Radio.Group>
            <Radio value="yes"> Yes </Radio>
            <Radio value="no"> No </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="checkinForm.acknowledgePolicy" />}
          name="acknowledge_policy"
        >
          <Radio.Group>
            <Radio value="yes"> Yes </Radio>
            <Radio value="no"> No </Radio>
            <Radio value="lets_chat"> Lets Chat </Radio>
          </Radio.Group>
        </Form.Item>
        <Button
          key="submit"
          className="btn-success btn-submit-bio"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </Form>
    </CheckinFormWrapper>
  );
};

export default CheckinForm;
