import React, { useState, useEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import Box from "@iso/components/utility/box";
import PageHeader from "@iso/components/utility/pageHeader";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Row, Col, Button, Dropdown, Menu, Popconfirm, Table } from "antd";
import AgentWrapper from "./Agent.style";
import {
  PlusOutlined,
  EditOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { agentService } from "@iso/services";
import AgentModal from "./AgentModal";

const Agent = () => {
  const [dataSource, setDataSource] = useState([]);
  const [visibleAgentModal, setVisibleAgentModal] = useState(false);
  const [dataAgent, setDataAgent] = useState({});
  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = () => {
    agentService.get().then((res) => {
      if (res.code === "0000") {
        setDataSource(res.agents);
      }
    });
  };

  const columns = [
    {
      title: <IntlMessages id="adminPage.agent.name" />,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: <IntlMessages id="adminPage.agent.email" />,
      dataIndex: "email",
      key: "email",
    },
    {
      title: <IntlMessages id="adminPage.agent.status" />,
      dataIndex: "status_label",
      key: "status_label",
    },
    {
      title: <IntlMessages id="adminPage.categoryForm.action" />,
      key: "action",
      render: (_, record) => (
        <Dropdown overlay={menu(record)} trigger={["click"]}>
          <Button className="btn-success ant-btn-primary">
            <span>
              <IntlMessages id="antTable.title.actions" />
            </span>{" "}
            <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const menu = (agent) => (
    <Menu onClick={handleMenuClick.bind(this, agent)}>
      <Menu.Item key="editAgent" icon={<EditOutlined />}>
        <IntlMessages id="adminPage.categoryForm.edit" />
      </Menu.Item>
      <Popconfirm
        className="ant-popconfirm"
        placement="bottomRight"
        title="You are about to remove individual record"
        onConfirm={() => {
          confirmRemove(agent.id);
        }}
        okText="Yes"
        cancelText="No"
        className="delete-button"
      >
        <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
          {<DeleteOutlined />}&nbsp;&nbsp;
          <IntlMessages id="adminPage.categoryForm.remove" />
        </a>
      </Popconfirm>
    </Menu>
  );

  const handleMenuClick = (agent, e) => {
    if (e.key === "editAgent") {
      setVisibleAgentModal(true);
      setDataAgent(agent);
    }
  };

  const confirmRemove = (id) => {
    agentService.destroy(id).then((res) => {
      if (res.code === "0000") {
        getAgents();
      }
    });
  };

  const openAgentModal = () => {
    setDataAgent({})
    setVisibleAgentModal(true)
  }

  return (
    <LayoutWrapper>
      <AgentWrapper>
        <PageHeader>{<IntlMessages id="adminPage.agent.title" />}</PageHeader>
        <div className="button-group">
          <Button
            icon={<PlusOutlined />}
            type="primary"
            className="btn-success"
            onClick={openAgentModal}
          >
            <IntlMessages id="adminPage.agent.add" />
          </Button>
        </div>
        <Box id="containerBox">
          <Table
            dataSource={dataSource}
            columns={columns}
            size="small"
            pagination={{ pageSize: 12 }}
          />
        </Box>
      </AgentWrapper>
      <AgentModal
        visibleAgentModal={visibleAgentModal}
        setVisibleAgentModal={setVisibleAgentModal}
        getAgents={getAgents}
        dataAgent={dataAgent}
        setDataAgent={setDataAgent}
      />
    </LayoutWrapper>
  );
};

export default Agent;
