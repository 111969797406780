import React, { useEffect, useState } from "react";
import VerifyElapsedWrapper from "./VerifyElapsedPage.styles";
import { sarService } from "@iso/services";

const VerifyElapsedPage = () => {
  const [successMsg, setSuccessMsg] = useState("Loading...");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const userId = queryParams.get('userId');
    const sarId = queryParams.get('sarId');
  
    if (!token && !userId) return;
    
    sarService.terminateSARAlarm({ userId, token, sarId })
      .then((res) => {
        setSuccessMsg(res.message);
      })
      .catch((error) => console.error('Error terminating SAR alarm:', error));
    // }else{
    //   // sarService.complateSAR(token)
    //   //   .then((res) => {
    //   //     setSuccessMsg(res.message);
    //   //   })      
    //   //   .catch((error) => console.error('Error completing SAR:', error));
    // }
   
  }, []);

  return (
    <VerifyElapsedWrapper>
      <div className="SAR-contain">
        <p>{successMsg}</p>
      </div>
    </VerifyElapsedWrapper>
  );
};

export default VerifyElapsedPage;
