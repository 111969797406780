import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import ShareOptionsModalWrapper from './ShareOptionsModal.styles';
import { Form, message, Switch, Typography, Checkbox, Col, Row, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import { useSelector } from 'react-redux';
import { propertyService, agentService } from '@iso/services'
import { agentOptions } from '@iso/constants/agent';

const { Paragraph } = Typography
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
};

const ShareOptionsModal = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false)
  const [propertyData, setPropertyData] = useState(false)
  const [propertyMetas, setPropertyMetas] = useState()
  const [agents, setAgents] = useState([])

  const cancelModal = () => {
    props.cancelShareOptionModal();
  }

  const handleSubmit = (isShowMessage = false) => {
    form
      .validateFields()
      .then((values) => {
        activateSharingMap(values, isShowMessage);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  }

  const activateSharingMap = (data, isShowMessage = false) => {
    propertyService.activateSharingMap(activePropertyId, data).then(res => {
      if(res.code === '0000')
      {
        setPropertyData(res.property)
        setPropertyMetas(res.property_metas)
        if(isShowMessage){
          if(res.property.sharing_token)
          {
            message.success('Property Map has been made public')
          }else{
            message.success('Property Map has been made private')
          }
        }
      }else{
        message.error(res.message)
      }
    })
  }
  useEffect(() => {
    getAgents();
    if(activePropertyId)
    {
      propertyService.viewProperty(activePropertyId).then(res => {
        if(res.code === '0000')
        {
          setPropertyData(res.property)
          setPropertyMetas(res.property_metas)
          setFields([
            {
              name: 'actived',
              value: res.property.sharing_token ? true : false,
            },
            {
              name: 'agent',
              value: res.property_metas?.agent?.meta_value,
            },
            {
              name: 'is_for_sale',
              value: res.property_metas?.is_for_sale?.meta_value,
            },
            {
              name: 'privileges',
              value: res.property_metas?.privileges?.meta_value,
            },
          ]);
        }
      })
    }
  }, [props.visibleShareOption, activePropertyId])

  const getAgents = () => {
    agentService.getActiveAgents().then(res => {
      if(res.code === '0000')
      {
        setAgents(res.agents)
      }
    })
  }

  const renderOptionAgent = () => {
    let options = [];
    _.forEach(agents, (agent, index) => {
      options.push(
        <Option key={index} value={agent.id}>
          {agent.name}
        </Option>
      );
    })
    return (
      <Select
        onChange={()=>handleSubmit(false)}
        allowClear
         className='custSelect'
        size="medium"
      >
        {options}
      </Select>
    );
  }

  return (
    <>
    <ShareOptionsModalWrapper
      className="user-access-modal"
      forceRender={true}
      visible={props.visibleShareOption}
      title={<IntlMessages id="topbar.share.title"/>}
      onCancel={cancelModal}
      footer={false}
    >
      <div className="form-body">
          <FacebookShareButton url={window.location.origin} className="share-button">
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={window.location.origin} className="share-button">
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <EmailShareButton url={window.location.origin} className="share-button">
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
          <WhatsappShareButton url={window.location.origin} className="share-button">
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <FacebookMessengerShareButton url={window.location.origin} className="share-button" appId={process.env.REACT_APP_FACEBOOK_APP_ID}>
            <FacebookMessengerIcon size={32} round={true} />
          </FacebookMessengerShareButton>
      </div>
      <div>
       
        <Form
          {...formItemLayout}
          form={form}
          layout="horizontal"
          scrollToFirstError
          fields={fields}
          className="form-body"
        >
          <Form.Item
            name={['actived']}
            className="isoInputWrapper"
            valuePropName="checked"
            label={`Share your ${propertyData.name} map`}
          >
            <Switch checkedChildren="Public" unCheckedChildren="Private" onChange={()=>handleSubmit(true)}/>
          </Form.Item>
          {propertyData && propertyData.sharing_token && propertyData.public_map_url &&
            <>
            <Form.Item
                name={['is_for_sale']}
                className="isoInputWrapper"
                valuePropName="checked"
                label={`Is property for sale`}
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={()=>handleSubmit(false)}/>
              </Form.Item>
              {
              propertyMetas?.is_for_sale?.meta_value && 
                <Form.Item
                  name={['agent']}
                  label="Agent"
                >
                  {renderOptionAgent()}
                </Form.Item>
              }
              <Form.Item name="privileges" label="What to show on map" onChange={()=>handleSubmit(false)}>
                <Checkbox.Group>
                    <Col>
                      <Checkbox value="areas" style={{ lineHeight: '32px' }}>
                        Areas
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value="tasks" style={{ lineHeight: '32px' }}>
                        Tasks
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value="mobs" style={{ lineHeight: '32px' }}>
                        Mobs
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value="observations" style={{ lineHeight: '32px' }}>
                        Observations
                      </Checkbox>
                    </Col>
                </Checkbox.Group>
              </Form.Item>
              <h3>Copy link to share with everyone</h3>
              <div className="form-qr-area">
                <Paragraph copyable>
                  {`${propertyData.public_map_url}`}
                </Paragraph>
              </div>
            </>
          }
        </Form>
      </div>
    </ShareOptionsModalWrapper>
    </>
  );
}

export default ShareOptionsModal;