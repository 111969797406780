import SignUp from "@iso/containers/Pages/SignUp/SignUp";
import SignIn from "@iso/containers/Pages/SignIn/SignIn";
import ResendEmailConfirm from "@iso/containers/Pages/ResendEmailConfirm/ResendEmailConfirm";
import ForgotPassword from "@iso/containers/Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "@iso/containers/Pages/ResetPassword/ResetPassword";
import VerifyPage from "@iso/containers/Pages/VerifyPage/VerifyPage";
import VisitorForm from "@iso/containers/Pages/VisitorForm/VisitorForm";
import StopDeviceAlarmRequest from "@iso/containers/Pages/StopDeviceAlarmRequest/StopDeviceAlarmRequest";
import VerifyElapsedPage from "@iso/containers/Pages/VerifyElapsedPage/VerifyElapsedPage";

export default {
  Landing: {
    component: SignUp,
    path: "/",
  },
  SignUp: {
    component: SignUp,
    path: "/signup",
  },
  SignIn: {
    component: SignIn,
    path: "/signin",
  },
  ResendEmailConfirm: {
    component: ResendEmailConfirm,
    path: "/resendemail",
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/forgotpassword",
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/resetpassword",
  },
  VerifyPage: {
    component: VerifyPage,
    path: '/verify'
  },
  VisitorForm: {
    component: VisitorForm,
    path: '/visitor-form'
  },
  StopDeviceAlarmRequest: {
    component: StopDeviceAlarmRequest,
    path: '/stop-device-alarm-request'
  },
  VerifyElapsedPage: {
    component: VerifyElapsedPage,
    path: "/sar-elapsed-time",
  },
};
