import React, { useState, useEffect, useRef } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import { useHistory } from "react-router-dom";
import PageHeader from "@iso/components/utility/pageHeader";
import Box from "@iso/components/utility/box";
import LiveStockPage from "./LiveStock.styles";
import IntlMessages from "@iso/components/utility/intlMessages";
import { configs } from "@iso/components/Tables/configs";
import { Button, Dropdown, Input, Menu, Pagination, Popconfirm, message } from "antd";
import {
  PlusOutlined,
  DownOutlined,
  EditOutlined,
  PlusCircleOutlined,
  FileTextOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  EyeOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import Loader from "@iso/components/utility/loader";
import modalActions from "@iso/redux/modal/actions";
import NewLiveStockModal from "@iso/containers/Pages/PropertyMap/components/LiveStock/NewLiveStockModal";
import AddHistoryModal from "@iso/containers/Pages/PropertyMap/components/LiveStock/AddHistoryModal";
import EditLiveStockModal from "@iso/containers/Pages/PropertyMap/components/Mob/EditLiveStockModal";
import { livestockService, propertyService, optiWeight } from "@iso/services";
import _ from "lodash";
import { objectColors } from "@iso/constants/objectColors";
import { livestockSex } from "@iso/constants/livestockSex";
import moment from "moment";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import LiveStockSummary from "./LiveStockSummary";
import SelectPropertyModal from "@iso/containers/Pages/Property/SelectPropertyModal";
import { CSVLink } from "react-csv";
import { propertyHelper } from "@iso/lib/helpers/propertyHelper";

const { openModal } = modalActions;

const LiveStock = (props) => {
  let history = useHistory();
  const [livestocks, setLivestocks] = useState([]);
  const dispatch = useDispatch();
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const activeProperty = localStorage.getItem("active_property");
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );

  const [liveStockData, setLiveStockData] = useState({});
  const [visibleEditLivestock, setVisibleEditLivestock] = useState(false);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [visibleViewSummary, setVisibleViewSummary] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);
  const [totalPages, setTotalPages] = useState();
  const [propertyPIC, setPropertyPIC] = useState("");
  const [loadingData, setLoadingData] = React.useState(true);
  const [recordsOpti, setRecordsOpti] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [searchInput, setsearchInput] = React.useState('');

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedLiveStock = searchQuery?.slice(startIndex, endIndex);
     
    const displayData =
    props.search_result &&
    props.search_result.assets?.slice(startIndex, endIndex);

    const handlePageSizeChange = (current, size) => {
      setCurrentPage(1);
      setPageSize(size);
    };
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  const csvOptiWeight = useRef();
  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then((res) => {
      if (res.code === "0000") {
        setPrimaryObjects(res.primary_objects);
        setPropertyPIC(res.property.PIC);
      }
    });
  }, []);
  useEffect(() => {
    if (props?.handleLiveStock) {
      // props.handlOpenmodal(showModal)
      props?.handleLiveStock(livestocks.length);
    }
  }, [livestocks]);
  useEffect(() => {
    if (recordsOpti.length > 0 && csvOptiWeight.current) {
      setTimeout(() => {
        csvOptiWeight.current.link.click();
        setRecordsOpti([]);
      });
    }
  }, [recordsOpti]);

  useEffect(() => {
    if (!activeProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);

  useEffect(() => {
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        setVisiblitySelectProperty(true);
      }
    });
  }, [activePropertyId]);

  const optiWeightRecords = (propertyId) => {
    optiWeight.getData(propertyId).then((res) => {
      if (res.code === "0000") {
        if (res.opti_weight && res?.opti_weight?.is_active === 1) {
          optiWeight.getDataOptiWeight(res.opti_weight.id).then((res) => {
            if (res.records[0].records.length > 0) {
              setRecordsOpti(res.records[0].records);
            } else {
              message.info("No Data");
            }
          });
        } else {
          message.info("Please check the config Opti Weight");
        }
      }
    });
  };

  const handleDownloadOpti = async () => {
    const res = await optiWeightRecords(activePropertyId);
  };

  useEffect(() => {
    if (activePropertyId) {
      updatePropertyDetail(activePropertyId);
    }
  }, [activePropertyId, updatePropertyDetail]);

  useEffect(() => {
    updateLiveStockData(1);
  }, [activePropertyId]);

  const updateLiveStockData = (page = 1) => {
    setCurrentPage(page);

    getLiveStocks({ page: page });
  };

  const getLiveStocks = (params = {}) => {
    params.property_id = params.property_id
      ? params.property_id
      : activePropertyId;
    params.page = params.page ? params.page : currentPage;
    params.search = params.search ? params.search : "";

    livestockService.getAll(params).then((res) => {
      if (res.code === "0000") {
        setLivestocks(res.assets);
        setTotalPages(res.total_page);
        setSearchQuery(res.assets);
        setLoadingData(false);
      }
    });
  };

  const cancelSelectPropertyModal = () => {
    setVisiblitySelectProperty(false);
  };

  const menu = (mob) => (
    <Menu onClick={handleMenuClick.bind(this, mob)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      {(activePermission == "owner" ||
        activePermission == "admin" ||
        activePermission == "modify") && (
        <Menu.Item key="addHistory" icon={<PlusCircleOutlined />}>
          <IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />
        </Menu.Item>
      )}
      <Menu.Item key="history" icon={<FileTextOutlined />}>
        <IntlMessages id="View History" />
      </Menu.Item>
      <Menu.Item key="viewSummary" icon={<EyeOutlined />}>
        <IntlMessages id="View Summary" />
      </Menu.Item>
      {(activePermission == "owner" || activePermission == "admin") && (
        <p style={{ cursor: "pointer" }} className="deleteButton">
          <Popconfirm
            placement="bottomRight"
            title="You are about to delete individual record"
            onConfirm={() => {
              confirmDelete(mob);
            }}
            okText="Yes"
            cancelText="No"
            className="delete-button"
          >
            <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
              {<DeleteOutlined />}&nbsp;&nbsp;
              <IntlMessages id="propertyPage.managePage.delete" />
            </a>
          </Popconfirm>
        </p>
      )}
    </Menu>
  );
  const handleMenuClick = (livestock, e) => {
    if (e.key === "editDetails") {
      setLiveStockData(livestock);
      showEditModal();
    }

    if (e.key === "addHistory") {
      setLiveStockData(livestock);
      showAddHistoryModal();
    }
    if (e.key === "history") {
      showHistory(livestock);
    }
    if (e.key === "viewSummary") {
      setVisibleViewSummary(true);
      setLiveStockData(livestock);
    }
  };

  const confirmDelete = (del_livestock) => {
    if (del_livestock.id) {
      livestockService.destroy(del_livestock.id).then((res) => {
        if (res.code === "0000") {
          let tmpLivestocks = _.clone(livestocks);
          const Index = _.findIndex(tmpLivestocks, (livestock) => {
            return livestock.id === res.asset.id;
          });
          delete tmpLivestocks[Index];
          setLivestocks(tmpLivestocks);
          setSearchQuery(tmpLivestocks)
          updateLiveStockData();
        }
      });
    }
  };

  const showHistory = (livestock) => {
    setLiveStockData(livestock);
    setViewHistory(true);
    showEditModal();
  };

  const columns = [
    {
      label: <IntlMessages id="liveStockPage.table.tag_number_range" />,
      accessor: "tag_number_range",
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: <IntlMessages id="liveStockPage.table.tag_colour" />,
      accessor: "color",
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: (cell) => {
        let livestock = cell.row;
        return objectColors.map((value, index) => {
          const opacity = 0.3;
          const rgbValues = value.color.match(/\d+/g);
          const colorWithOpacity = `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`;
          if (value.value.toLowerCase() == livestock.tag_colour.toLowerCase()) {
            return (
              <div
                style={{
                  display: "flex",
                  backgroundColor: colorWithOpacity,
                  padding: "5px 10px",
                  borderRadius: "10px",
                  gap: "6px",
                  maxWidth: "130px",
                  alignItems: "center",
                }}
              >
                <ColorItemPreview
                  style={{ backgroundColor: value.color }}
                ></ColorItemPreview>
                &nbsp;&nbsp;
                <div>{value.label}</div>
              </div>
            );
          }
        });
      },
    },
    {
      label: <IntlMessages id="liveStockPage.table.NLIS" />,
      accessor: "NLIS",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: <IntlMessages id="liveStockPage.table.date_of_birth" />,
      accessor: "date_of_birth",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: (cell) => {
        let livestock = cell.row;
        return livestock.date_of_birth
          ? moment(livestock.date_of_birth).format("DD/MM/YYYY")
          : livestock.month_of_birth &&
              livestock.month_of_birth + "/" + livestock.year_of_birth;
      },
    },
    {
      label: <IntlMessages id="liveStockPage.table.mother" />,
      accessor: "mother",
      minWidth: 200,
      sortable: false,
      position: 5,
      priorityLevel: 5,
    },
    {
      label: <IntlMessages id="liveStockPage.table.siren" />,
      accessor: "siren",
      minWidth: 200,
      sortable: false,
      position: 6,
      priorityLevel: 6,
    },
    {
      label: <IntlMessages id="liveStockPage.table.uid" />,
      accessor: "uid",
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7,
    },
    {
      label: <IntlMessages id="liveStockPage.table.breed" />,
      accessor: "breed",
      minWidth: 100,
      sortable: true,
      position: 8,
      priorityLevel: 8,
    },
    {
      label: <IntlMessages id="liveStockPage.table.sex" />,
      accessor: "sex_name",
      minWidth: 100,
      sortable: false,
      position: 9,
      priorityLevel: 9,
    },
    {
      label: <IntlMessages id="liveStockPage.table.lastActivity" />,
      accessor: "last_activity",
      minWidth: 100,
      sortable: false,
      position: 10,
      priorityLevel: 10,
    },
    {
      label: <IntlMessages id="liveStockPage.table.location" />,
      accessor: "primary_object",
      minWidth: 100,
      sortable: false,
      position: 11,
      priorityLevel: 11,
    },
    {
      label: "",
      accessor: "actions",
      minWidth: 100,
      sortable: false,
      position: 13,
      priorityLevel: 13,
      noTitle: true,
      CustomComponent: (cell) => {
        let livestock = cell.row;
        return (
          // <Dropdown overlay={menu(livestock)} trigger={['click']}>
          //   <Button className="btn-success ant-btn-primary">
          //     <IntlMessages id="antTable.title.actions" /> <DownOutlined />
          //   </Button>
          // </Dropdown>
          <Dropdown overlay={menu(livestock)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const onSaved = (savedLivestock) => {
    let tmpLivestocks = _.clone(livestocks);
    const Index = _.findIndex(tmpLivestocks, (livestock) => {
      return livestock.id === savedLivestock.id;
    });

    if (Index === -1) {
      tmpLivestocks.push(savedLivestock);
    } else {
      tmpLivestocks[Index] = savedLivestock;
    }
    setLivestocks(tmpLivestocks);
    setSearchQuery(tmpLivestocks);
  };

  const showModal = (livestock) => {
    const modalData = {
      livestock,
      type: "livestock",
      canCreate: true,
    };
    dispatch(openModal(modalData));
  };

  const showEditModal = () => {
    setVisibleEditLivestock(true);
  };

  const showAddHistoryModal = () => {
    setVisibleHistory(true);
  };

  const cancelModalHistory = () => {
    setVisibleHistory(false);
  };

  const cancelEditModal = () => {
    setVisibleEditLivestock(false);
    setViewHistory(false);
  };

  const handleChange = (data = {}) => {
    const page = data.page ? data.page : 1;

    setCurrentPage(page);
    getLiveStocks(data);

  };

  const handleCancelSearch = () => {
    setsearchInput('')
    setSearchQuery(livestocks);
  };
  const handleSearch = (e) => {
    setsearchInput(e.target.value)
    // if (e.key === "Enter") {
      const filteredLiveStock = livestocks.filter((livestocks) => {
        const searchTerm = searchInput.toLowerCase();
        return (
          livestocks.tag_number_range.toLowerCase().includes(searchTerm) ||
          livestocks.color.toLowerCase().includes(searchTerm) ||
          livestocks.NLIS.toLowerCase().includes(searchTerm) ||
          livestocks.siren.toLowerCase().includes(searchTerm) ||
          livestocks.uid.toLowerCase().includes(searchTerm) ||
          livestocks.breed.toLowerCase().includes(searchTerm) ||
          livestocks.sex_name.toLowerCase().includes(searchTerm) ||
          livestocks.last_activity.toLowerCase().includes(searchTerm) ||
          // livestocks.primary_object.toLowerCase().includes(searchTerm) ||
          
          (livestocks.primary_object &&
            livestocks.primary_object.toString().toLowerCase().includes(searchTerm)) ||
          (livestocks.date_of_birth &&
            moment(livestocks.date_of_birth)
              .format("DD/MM/YYYY")
              .includes(searchTerm)) ||
          livestocks.mother.toLowerCase().includes(searchTerm)
        );
      });
      setSearchQuery(filteredLiveStock);
      setCurrentPage(1); // Reset the current page to 1 when the search term changes
    // }
  }
  return (
    <LayoutWrapper>
      <LiveStockPage>
        <Box id="containerBox">
        <div className="manage-property-header">
        <div className="mp-title">
              <h2 className="manage-property-title">LiveStock</h2>
            </div>
            {props.search_result ? (
              ""
            ) : (
              <>
            <Input
                placeholder="Search…"
                suffix={!searchInput && <SearchOutlined className="site-form-item-icon" />}
                prefix={searchInput && <CloseCircleOutlined onClick={handleCancelSearch} className="site-form-item-cancel" />}
                size="large"
                className="search-bar"
                value={searchInput}
                // onChange={(e) => setsearchInput(e.target.value)}
                // onKeyDown={handleSearch}
                onChange={(e) => handleSearch(e)}
              />
          <div className="button-group">
            {(activePermission == "owner" || activePermission == "admin") && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                className="btn-success"
                onClick={showModal}
              >
                <IntlMessages id="liveStockPage.managePage.addLiveStock" />
              </Button>
            )}
            &nbsp;
            {propertyHelper.checkIsGoldPackage(activePropertySubscription) && (
              <Button
                type="primary"
                className="btn-success"
                onClick={handleDownloadOpti}
              >
                Download OptiWeight
              </Button>
            )}
            <CSVLink
              target="_blank"
              ref={csvOptiWeight}
              filename={
                "OptiWeight_list_" +
                moment().format("DD/MM/YYYY HH:mm:ss") +
                ".csv"
              }
              data={recordsOpti}
              className="hidden"
            />
          </div>
          </>)}
          </div>
          {loadingData ? (
            <Loader />
          ) : (
            <>
           {props.search_result &&
            props.search_result.assets &&
            props.search_result.assets.length > 0 ? (
              <>
              <div className="manage-table">
                <Table
                  columns={columns}
                  rows={displayData}
                  containerId="containerBox"
                  showPagination={false}
                  rowSize={99999}
                  showSearch={false}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      updateData={handleChange}
                />
              </div>
              {props.search_result.assets?.length > 10 &&
                <div style={{ padding: "15px" }}>
                  <Pagination
                    current={currentPage}
                    total={props.search_result.assets?.length}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50", "100"]}
                    onShowSizeChange={handlePageSizeChange}
                  />
                </div>}</>
            ) : (
              <div className="manage-table">
             
                  <>
                {!props.search_result && displayedLiveStock && displayedLiveStock?.length > 0 ? (
                  <>
                    <Table
                      columns={columns}
                      rows={displayedLiveStock}
                      containerId="containerBox"
                      showPagination={false}
                      rowSize={15}
                      showSearch={false}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      updateData={handleChange}
                    />
                    <div style={{ padding: "16px" }}>
                      <Pagination
                        current={currentPage}
                        total={searchQuery?.length}
                        pageSize={pageSize}
                        onChange={handlePageChange}
                        showSizeChanger
                        pageSizeOptions={["10", "20", "50", "100"]}
                        onShowSizeChange={handlePageSizeChange}
                      />
                    </div>
                  </>
                ) : (
                  <h1 style={{ padding: "15px 15px",color:'gray' }}>
                    <IntlMessages id="dashboardPage.noData" />
                  </h1>
                )}{" "}</>
              
              </div>
            )}
            </>
          )}
        </Box>
        <NewLiveStockModal
          propertyId={activePropertyId}
          onSaved={onSaved}
          updateLiveStockData={updateLiveStockData}
          updatePropertyDetail={updatePropertyDetail}
          propertyPIC={propertyPIC}
        />
      </LiveStockPage>
      <EditLiveStockModal
        updateLiveStockData={updateLiveStockData}
        onSaved={onSaved}
        liveStockData={liveStockData}
        propertyId={activePropertyId}
        visibleEditLivestock={visibleEditLivestock}
        cancelModal={cancelEditModal}
        primaryObjects={primaryObjects}
        viewHistory={viewHistory}
      />

      <AddHistoryModal
        visibleHistory={visibleHistory}
        liveStockData={liveStockData}
        primaryObjects={primaryObjects}
        cancelModal={cancelModalHistory}
        propertyId={activePropertyId}
        afterArchived={updateLiveStockData}
      />

      <SelectPropertyModal
        visibleSelectProperty={visibleSelectProperty}
        cancelSelectPropertyModal={cancelSelectPropertyModal}
      />
        <LiveStockSummary
      liveStockData = {liveStockData}
      visibleViewSummary = {visibleViewSummary}
      setVisibleViewSummary = {setVisibleViewSummary}
         propertyId={activePropertyId}
      />
    </LayoutWrapper>
  );
};

export default LiveStock;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div {
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
