import React from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Button } from "antd";
import StopDeviceAlarmRequestModalWrapper from "./StopDeviceAlarmRequestModal.styles";
import Table from "@iso/components/collapsing-table/table";
const StopDeviceAlarmRequestModal = (props) => {
  const { stopAlarmRequests, visible, cancelModal } = props;

  const columnsTable = [
    {
      label: "User",
      accessor: "user",
      minWidth: 100,
      sortable: true,
      position: 1,
      itemAlign: 'left',
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let record = cell.row;
        return <span>{record?.user?.email}</span>;
      },
    },
    {
      label: "Reason",
      accessor: "reason",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: "Comment",
      accessor: "comment",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: "Created At",
      accessor: "created_at",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
    },
  ];

  return (
    <StopDeviceAlarmRequestModalWrapper
      width={1000}
      visible={visible}
      onCancel={cancelModal}
      className ='modalcust'
      title="Stop Alarm Requests"
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
      ]}
    >
      <Table
        rows={stopAlarmRequests}
        columns={columnsTable}
        size="small"
        pagination={{ pageSize: 12 }}
        rowSize={99999}
        bordered
        className="manage-table"
        rowClassName="rowClassName1"
      />
    </StopDeviceAlarmRequestModalWrapper>
  );
};

export default StopDeviceAlarmRequestModal;
