import React, { useState, useEffect, useRef } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import IntlMessages from '@iso/components/utility/intlMessages';
import ShareMapPage from './ShareMap.styles';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl } from 'react-leaflet';
import { useSelector, useDispatch } from 'react-redux';
import { propertyService } from '@iso/services';
import { Button, Dropdown, Menu, Checkbox, Alert} from 'antd';
import _ from 'lodash';
import { objectColors } from '@iso/constants/objectColors';
import Task from './components/Task/Task';
import Mob from './components/Mob/Mob';
import Asset from './components/Asset/Asset';
import Observation from './components/Observation/Observation';
import Control from '@skyeer/react-leaflet-custom-control'
import AppLocale from '@iso/config/translation';
import { IntlProvider } from 'react-intl';
import { DownOutlined} from '@ant-design/icons';
import L from 'leaflet';
import { detect } from 'detect-browser';
import queryString from 'query-string';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import propertyActions from '@iso/redux/property/actions';

const { changeAgent } = propertyActions;

const ShareMap = () => {
  const dispatch = useDispatch();
  const mapRef = useRef();
  const [propertyId, setPropertyId] = useState(null);
  const [position, setPosition] = useState([null, null]);
  const objectRef = React.useRef([]);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [editableFG, setEditableFG] = useState(null);
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const currentAppLocale = AppLocale[locale];
  const [visibleShowOption, setVisibleShowOption] = useState(false);
  const [zoomDefault, setZoomDefault] = useState(16);
  const [propertyPIC, setPropertyPIC] = useState("");
  const [propertyAcreageUnit, setPropertyAcreageUnit] = useState("");
  const token = queryString.parse(window.location.search).sharing_token || '';
  const [errorMsg, setErrorMsg] = useState('');
  const [propertyMetas, setPropertyMetas] = useState();

  const updatePropertyDetail = React.useCallback((token) => {
    propertyService.viewPropertyByToken(token).then(res => {
      if(res.code === '0000'){
        setPosition([res.property.lat, res.property.lng]);
        setPrimaryObjects(res.primary_objects);
        setPropertyPIC(res.property.PIC);
        setPropertyAcreageUnit(res.property.acreage_unit);
        setPropertyId(res.property.id)
        setPropertyMetas(res.property_metas)
        if(res?.property_metas?.is_for_sale?.meta_value && res?.property_metas?.actived?.meta_value){
          dispatch(changeAgent(res.agent));
        }else{
          dispatch(changeAgent(null));
        }
      }else{
        setErrorMsg(res.message)
      }
    })
  }, []);

  useEffect(() => {
    updatePropertyDetail(token);
  }, [updatePropertyDetail]);


  const onFeatureGroupReady = reactFGref => {
    // store the featureGroup ref for future access to content
    setEditableFG(reactFGref);
  };

  const handleShowPrimaryObjects = (objects) => {
    var html = [];
    if(objects.length > 0){
      _.forEach(objects, function(object){
        const colorIndex = _.findIndex(objectColors, (color) => {
          return color.value === object.color
        });

        html.push(
          <Polygon key={object.id}
            positions={object.area}
            color={objectColors[colorIndex].color}
            className='primary-objects'
            ref={el => (objectRef.current[object.id] = el)}
          >
            <Tooltip permanent={true} direction="center" className='primary-objects-tooltip'>
              <p>{object.name}</p>
              <p>{parseFloat(object.acreage).toFixed(1)}<span> {object.acreage_unit}</span></p>
            </Tooltip>
          </Polygon>
        );
      });
    }
    return html;
  }

  const [showArea, setShowArea] = useState(true);
  const [showTask, setShowTask] = useState(true);
  const [showMob, setShowMob] = useState(true);
  const [showObservation, setShowObservation] = useState(true);
  const menuShowOptions = () => (
    <Menu>
      <Menu.Item key="showArea">
        <Checkbox 
          checked={showArea && propertyMetas?.privileges?.meta_value && propertyMetas.privileges.meta_value.includes('areas')} 
          onChange={() => setShowArea(!showArea)} 
          id='show-area'
        >
          <IntlMessages id="propertyMapPage.showArea" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="showTask" htmlFor="show-task">
        <Checkbox 
          checked={showTask && propertyMetas?.privileges?.meta_value && propertyMetas.privileges.meta_value.includes('tasks')} 
          onChange={() => setShowTask(!showTask)} 
          id='show-task'
        >
          <IntlMessages id="propertyMapPage.showTask" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="showMob" htmlFor="show-mob">
        <Checkbox 
          checked={showMob && propertyMetas?.privileges?.meta_value && propertyMetas.privileges.meta_value.includes('mobs')}  
          onChange={() => setShowMob(!showMob)}   
          id='show-mob'
        >
          <IntlMessages id="propertyMapPage.showMob" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="showObservation" htmlFor="show-observation">
        <Checkbox 
          checked={showObservation && propertyMetas?.privileges?.meta_value && propertyMetas.privileges.meta_value.includes('observations')}  
          onChange={() => setShowObservation(!showObservation)}   
          id='show-observation'
        >
          <IntlMessages id="propertyMapPage.showObservation" />
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  const handleVisibleChange = (flag) => {
    setVisibleShowOption(flag);
  }

  const browser = detect();

  const changeZoomLevelToDefault = () => {
    setZoomDefault(16);
  }

  return (
    <>
    { (errorMsg && errorMsg !== '') ?
      <Alert message={errorMsg} type="error" /> :
      <LayoutWrapper className={`map-wrapper ${browser.name}`} style={{paddingBottom: '0'}}>
        <ShareMapPage className={`
            ${showArea && propertyMetas?.privileges?.meta_value && propertyMetas.privileges.meta_value.includes('areas') ? "" : "not-show-area"} 
            ${showTask && propertyMetas?.privileges?.meta_value && propertyMetas.privileges.meta_value.includes('tasks') ? "" : "not-show-task"} 
            ${showMob && propertyMetas?.privileges?.meta_value && propertyMetas.privileges.meta_value.includes('mobs') ? "" : "not-show-mob"}
            ${showObservation && propertyMetas?.privileges?.meta_value && propertyMetas.privileges.meta_value.includes('observations') ? "" : "not-show-observation"}`
          }>
          <Map ref={mapRef} center={position[0] != null ? position : false} zoom={zoomDefault} zoomControl={false}>
            <ReactLeafletGoogleLayer type={'hybrid'} useGoogMapsLoader={false}/>
            <ZoomControl position="bottomleft" zoomInText="+" zoomOutText="-" />
            <FeatureGroup
              ref={featureGroupRef => {
                  onFeatureGroupReady(featureGroupRef);
              }}
            >
              <Control position="topleft">
              <div className="leftComponent">
                <IntlProvider
                  locale={currentAppLocale.locale}
                  messages={currentAppLocale.messages}
                >
                  <Dropdown overlay={menuShowOptions} trigger={['click']}  visible={visibleShowOption} onVisibleChange={handleVisibleChange}>
                    <Button className="action-button">
                      <IntlMessages id="propertyMap.title.view" /> <DownOutlined />
                    </Button>
                  </Dropdown>

                </IntlProvider>
                </div>
              </Control>

              { handleShowPrimaryObjects(primaryObjects) }

              <Task
                propertyId={propertyId}
                primaryObjects={primaryObjects}
              />

              <Mob
                propertyId={propertyId}
                primaryObjects={primaryObjects}
              />

              <Asset
                propertyId={propertyId}
                primaryObjects={primaryObjects}
              />

              <Observation
                propertyId={propertyId}
                primaryObjects={primaryObjects}
              />
            </FeatureGroup>
          </Map>
        </ShareMapPage>
      </LayoutWrapper>
    }
    </>
  );
}

export default ShareMap;
