import React from "react";
import { Row, Col } from "antd";
import SubscriptionFlowWrapper from "./SubscriptionFlow.styles";
import Flow1 from "@iso/assets/images/welcomeModals/subscriptionFlow/flow-1.png";
import Flow2 from "@iso/assets/images/welcomeModals/subscriptionFlow/flow-2.png";
import Flow3 from "@iso/assets/images/welcomeModals/subscriptionFlow/flow-3.png";
import Flow4 from "@iso/assets/images/welcomeModals/subscriptionFlow/flow-4.png";

const SubscriptionFlow = (props) => {
  return (
    <SubscriptionFlowWrapper>
      <h1 className="text-title">How your Premium Free trail works</h1>
      <Row>
        <Col className="flow-col" span={6}>
          <div id="flow-1">
            <img src={Flow1}></img>
          </div>
          <div className="flow-container">
            <p className="flow-title">Set your learning goals</p>
            <p className="flow-content">
              You successfully created your profile and set your learning goals
            </p>
          </div>
        </Col>
        <Col className="flow-col" span={6}>
          <div className="icon-outside">
            <div className="icon-inside">
              <img src={Flow2}></img>
            </div>
          </div>
          <div className="flow-container">
            <p className="flow-title">Today: Get Premium access</p>
            <p className="flow-content">
              Full Access to all the features that AIrAgri have.
            </p>
          </div>
        </Col>
        <Col className="flow-col" span={6}>
          <div className="icon-outside">
            <div className="icon-inside">
              <img src={Flow3}></img>
            </div>
          </div>
          <div className="flow-container">
            <p className="flow-title">Day 88 : Trail Reminder</p>
            <p className="flow-content">
              We’ll send you and email reminder about when your trial will end.
            </p>
          </div>
        </Col>
        <Col className="flow-col" span={6}>
          <div className="icon-outside">
            <div className="icon-inside">
              <img src={Flow4}></img>
            </div>
          </div>
          <div className="flow-container">
            <p className="flow-title">Day 90 : Trail Ends</p>
            <p className="flow-content">
              You’ll be charged and your subscription will start on day after
              90. Cancel anytime before.
            </p>
          </div>
        </Col>
      </Row>
      <div className="footer">
        <a className="free-trail-link" href="#">
          Get your free trail with Gold Subscription
        </a>
        <br></br>
        <button className="submit-btn">Start your 3-months free trail</button>
      </div>
    </SubscriptionFlowWrapper>
  );
};

export default SubscriptionFlow;
