import React from "react";
import { Form, Input, Table, Checkbox } from "antd";
import BioCheckbox from "../Components/BioCheckbox";

const { TextArea } = Input;

const OutgoingProducts = (props) => {
  const columns = [
    {
      title: "5",
      dataIndex: "order",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "OUTGOING PRODUCTS",
      dataIndex: "outgoing_products",
      rowScope: "row",
      colSpan: 7,
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
      width: 500,
    },
    {
      title: "Yes",
      colSpan: 0,
      dataIndex: "yes",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "No",
      colSpan: 0,
      dataIndex: "no",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "N/A",
      colSpan: 0,
      dataIndex: "na",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "Recommended measures ",
      colSpan: 0,
      dataIndex: "recommended_measures",
      rowScope: "row",
      width: 500,
    },
    {
      title: "Actioned ?",
      colSpan: 0,
      dataIndex: "actioned",
      rowScope: "row",
    },
    {
      title: "Comments",
      colSpan: 0,
      dataIndex: "comments",
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 1) {
          return { rowSpan: 2 };
        }
        if (index === 2) {
          return { rowSpan: 0 };
        }
        return {};
      },
      className: "comment-column",
    },
  ];

  const data = [
    {
      key: "1",
      order: "5.1",
      outgoing_products: "Outgoing products",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures ",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "2",
      order: "5.1.1",
      outgoing_products:
        "Are all livestock moved off the property accompanied with information on animal treatments, and is a health status provided via an NVD/eNVD and AHD?",
      yes: <BioCheckbox name="outgoing_products_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="outgoing_products_1_no"></BioCheckbox>,
      na: <BioCheckbox name="outgoing_products_1_na"></BioCheckbox>,
      recommended_measures:
        "NVD/Waybills completed for all livestock movements off the property.",
      actioned: (
        <BioCheckbox name="outgoing_products_1_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="outgoing_products_1_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "3",
      order: "",
      outgoing_products: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "AHD completed to provide further information on livestock health where necessary.",
      actioned: (
        <BioCheckbox name="outgoing_products_1_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
  ];

  return (
    <Table className="bio-table" columns={columns} dataSource={data} bordered pagination={false} />
  );
};

export default OutgoingProducts;
