import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Dropdown, Menu, Popconfirm } from 'antd';
import ManageFormPage from './ManageForm.styles';
import { PlusOutlined, EditOutlined, DownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import Table from '@iso/components/collapsing-table/table';
import { globalFormService } from '@iso/services'

const ManageForm = () => {
  let history = useHistory()
  const [forms, setForms] = useState([]);

  const getForms = () => {
    globalFormService.getForms().then(res => {
      if(res.code === '0000'){
        setForms(res.global_forms);
      }
    });
  }

  useEffect(() => {
      getForms();
  }, []) 

  const menu = (form) => (
    <Menu onClick={handleMenuClick.bind(this, form)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
    </Menu>
  );

  const handleMenuClick = (form, e) => {
    if(e.key === 'editDetails'){
      history.push('/form-builder?form_id=' + form.id)
    }
  }
 
 const columns = [
    {
      label: 'Form Name',
      accessor: 'name',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: 'Form Description',
      accessor: 'description',
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2
    },
    {
      label: 'Status',
      accessor: 'is_live',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
      CustomComponent: cell => {
        let form = cell.row
        return (
            <span>{form?.is_live == 1 ? 'Live' : 'Draft'}</span>
        );
      },
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: cell => {
        let form = cell.row
        return (
            <Dropdown overlay={menu(form)} trigger={['click']}>
              <Button className="action-button">
                <IntlMessages id="antTable.title.actions" /> <DownOutlined />
              </Button>
            </Dropdown>
        );
      },
    }
  ];

  return (
    <LayoutWrapper>
      <ManageFormPage>
        <PageHeader>
          {<IntlMessages id="adminPage.manageForm.header" />}
        </PageHeader>
        <div className="button-group">
          <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={() => history.push('/form-builder')}>
            Add New Form
          </Button>
        </div>
        <Box id="containerBox">
        {
          forms.length > 0 &&
          <Table 
              columns={columns} 
              rows={forms}
              containerId="containerBox"
              showPagination={true}
              rowSize={20}
            />
        }
        </Box>
      </ManageFormPage>
    </LayoutWrapper>
  );
}

export default ManageForm;
