export const activityPolicy = [
  {
    label: 'Meeting',
    value: 'meeting',
  },
  {
    label: 'Update',
    value: 'update',
  },
  {
    label: 'Comment',
    value: 'comment',
  }, 
  {
    label: 'Safety Walk',
    value: 'safety_walk',
  }, 
  {
    label: 'Hazard',
    value: 'hazard',
  }, 
  {
    label: 'Induction',
    value: 'induction',
  },  
  {
    label: 'Toolbox meeting',
    value: 'toolbox_meeting',
  },
  {
    label: 'Near Miss',
    value: 'near_miss',
  },
  {
    label: 'Accident',
    value: 'accident',
  },
]