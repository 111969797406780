import React,{useEffect, useState,useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@iso/components/utility/box';
import DashboardWrapper from './Dashboard.styles'; 
import DeviceChart from './DeviceChart'; 
import TrafficLight from './TrafficLight'; 
import ModalActivity from '@iso/containers/Pages/HealthSafety/ModalActivity'; 
import { Row, Col, Button,Modal,Form, Input, Select, DatePicker, message, Popover } from 'antd';
import { healthsafety,assetService } from '@iso/services';
import _ from 'lodash';
import IntlMessages from '@iso/components/utility/intlMessages';
import moment from 'moment';
import { useHistory ,useLocation } from 'react-router-dom';
import appActions from '@iso/redux/app/actions';
import { propertyService } from '@iso/services';
import styled from "styled-components";

const { changeCurrent} = appActions;
const { Option } = Select;

const Dashboard = (props) => {
  const propertyId = props.propertyId;
  const currentUser = useSelector((state) => state.user.user);
  const [historyChartData, setHistoryChartData] = useState([]);
  const [assetChartData, setAssetChartData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [type, setType] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [inductionColor,setInductionColor] = useState();
  const [safetyWalkColor,setSafetyWalkColor] = useState();
  const [toolboxMeetingColor,setToolboxMeetingColor] = useState();
  const [visibleModalActivity,setVisibleModalActivity] = useState(false);
  const [activityActive,setActivityActive] = useState(false);
  const [position,setPosition] = useState([null, null]);
  useEffect(() => {
    if(propertyId){
      getDataAsset();
      getActivityPolicy();
      trafficLight();
      updatePropertyDetail(propertyId)
      setFields([
        {
          name: ['asset', 'date_time'],
          value: moment(),
        },   
    
      ]);
    }
  },[propertyId]);

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then(res => {
      if(res.code === '0000'){
        setPosition([res.property.lat, res.property.lng]);
      }
    })
  }, []);

  const capitalize = (str) => {
   let splitStr = str.toLowerCase().split(' ');
   for (var i = 0; i < splitStr.length; i++) {
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
   }
   return splitStr.join(' '); 
  }

  const trafficLight = () => {
    healthsafety.trafficLight(propertyId).then(res => {
      if(res.code === '0000'){
          setToolboxMeetingColor(res.toolbox_meeting_color);
          setSafetyWalkColor(res.safety_walk_color);
          setInductionColor(res.induction_color);
        }else{
          message.error(res.message)
        }
    });
  }

  const groupByData = (data) => {
      let label = [];
      let value = [];
      const result = _(data)
        .groupBy(x => x.type)
        .map((key, value) => ({label: value, value: key.length}))
        .value();
      _.forEach(result, (v, index) => {
        if(v.label){
          label.push({'label':capitalize( v.label.replace(/_/g, ' ') ) });
          value.push({'value':v.value});
        }  
      });
    return [label,value];
  }

  const getDataAsset = () => {
     if(propertyId){
       healthsafety.getHealthAndSafetyAssets(propertyId).then(res => {
        if(res.code === '0000'){
          setAssetData(res.assets);
          setAssetChartData(groupByData(res.assets));
        }else{
          message.error(res.message)
        }
      });
    }
  }

  const getActivityPolicy = () =>{
    if(propertyId){

       healthsafety.getActivityPolicyByProperty(propertyId).then(res => {
        if(res.code === '0000'){
          setHistoryChartData(groupByData(res.activities));
        }else{
          message.error(res.message)
        }
      });
    } 
  };

  const renderOptionsAssets = (assets) => {
    let options = [];
    _.forEach(assets, (asset, index) => {
      
      options.push(
        <Option key={index} value={asset.id}>
          <div>{asset.title}</div>
        </Option>
      ); 
     
    })
    return (
      <Select
        placeholder="Select Assets"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  } 

  const handleRedirectPage = (e,path) => {
    history.push(e);
    dispatch(changeCurrent([path]));
  }
  const cancelModalActivity = () => {
    setVisibleModalActivity(false);

  }
  const showModalActivity = (value) =>{
    setVisibleModalActivity(true);
    setActivityActive();
    if(value){
      setActivityActive(value);
    }
  }
  const popoverSafetyWalk =(
    <div>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#00965b'}}></ColorItemPreview> &nbsp;&nbsp; The last 30 days
      </div> <br/> 
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#f6cf67'}}></ColorItemPreview> &nbsp;&nbsp; 30 - 90 days 
      </div><br/>   
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#ea5252'}}></ColorItemPreview> &nbsp;&nbsp; Beyond 90 days
      </div>
    </div>
  );  
  const popoverToolBoxMeeting =(
    <div>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#00965b'}}></ColorItemPreview> &nbsp;&nbsp; The last 21 days 
      </div> <br/> 
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#f6cf67'}}></ColorItemPreview> &nbsp;&nbsp; 21 - 50 days 
      </div><br/>   
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#ea5252'}}></ColorItemPreview> &nbsp;&nbsp; Beyond 50 days 
      </div>
    </div>
  );  
  const popoverIndutions = (
    <div>
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#00965b'}}></ColorItemPreview> &nbsp;&nbsp; The last 14 days
      </div> <br/> 
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#f6cf67'}}></ColorItemPreview> &nbsp;&nbsp; 14 - 30 days 
      </div><br/>   
      <div style={{display:'inline-flex'}}>
        <ColorItemPreview style={{backgroundColor: '#ea5252'}}></ColorItemPreview> &nbsp;&nbsp; Beyond 30 days
      </div>
    </div>
  );
  return (
    <DashboardWrapper>
      <Box className="statistic-card">
       <h1>Hi, {currentUser.name ? currentUser.name : currentUser.email} <br/>
        Welcome to SafeFarm.</h1>
        <Row gutter={[10,15]}>
          <Col xs={{span: 12}} md={{span: 12}} lg={{span:8}} onClick={() => showModalActivity('safety_walk')}>
            <Button block className="ant-btn--sf-dashboard">
              <span><IntlMessages id="safeFarmPage.dashboard.safetyWalk" /></span>
            </Button>
          </Col> 
          <Col xs={{span: 12}} md={{span: 12}} lg={{span:8}}>
            <Button className="ant-btn--sf-dashboard" block onClick={() => showModalActivity('hazard')}>
              <span><IntlMessages id="safeFarmPage.dashboard.hazardRisk" /></span>  
            </Button>
          </Col> 
          <Col xs={{span: 12}} md={{span: 12}} lg={{span:8}}>
            <Button className="ant-btn--sf-dashboard" block onClick={() => showModalActivity()}>
              <span><IntlMessages id="dashboard.historyChart.title" /></span>
            </Button>
          </Col>  
          <Col xs={{span: 12}} md={{span: 12}} lg={{span:8}}>
            <Button className="ant-btn--sf-dashboard" block onClick={() => handleRedirectPage('manage-assets','manage-assets')} >
              <span><IntlMessages id="safeFarmPage.dashboard.assetRegister" /></span>
            </Button>
          </Col> 
          <Col xs={{span: 12}} md={{span: 12}} lg={{span:8}}>
            <Button className="ant-btn--sf-dashboard" block onClick={() => handleRedirectPage('manage-forms','')}>
              <span><IntlMessages id="safeFarmPage.dashboard.forms" /></span>
            </Button>
          </Col> 
          <Col xs={{span: 12}} md={{span: 12}} lg={{span:8}}>
            <Button className="ant-btn--sf-dashboard" block onClick={() => handleRedirectPage('map','map')}>
              <span><IntlMessages id="sidebar.propertyMap" /></span>
            </Button>
          </Col>
        </Row>
      </Box>
      <Box className="statistic-card"> 
        <Row>
          <Col xs={{span: 12}} md={{span: 12}} lg={{span: 8}}>
            <Popover content={popoverSafetyWalk} title="Info">
              <span className="traffic-light-label"> <IntlMessages id="safeFarmPage.dashboard.safetyWalk" /></span>
            </Popover>
            <TrafficLight color={safetyWalkColor} />
          </Col> 
          <Col xs={{span: 12}} md={{span: 12}} lg={{span: 8}}>
            <Popover content={popoverToolBoxMeeting} title="Info">
              <span className="traffic-light-label"><IntlMessages id="safeFarmPage.dashboard.toolboxMeeting" /></span>
            </Popover>
            <TrafficLight color={toolboxMeetingColor} />
          </Col>  
          <Col xs={{span: 12}} md={{span: 12}} lg={{span: 8}}>
            <Popover content={popoverIndutions} title="Info">
              <span className="traffic-light-label"><IntlMessages id="safeFarmPage.dashboard.inductions" /></span>
            </Popover>
            <TrafficLight color={inductionColor} />
          </Col> 
        </Row>
      </Box> 
      <Box className="statistic-card">
        <Row>
          <Col xs={{span: 24}} md={{span: 24}} lg={{span: 24}}>
           <DeviceChart assetChartData={assetChartData} historyChartData={historyChartData} />
          </Col>
        </Row>
      </Box>
      <ModalActivity 
        cancelModalActivity={cancelModalActivity} 
        visibleModalActivity={visibleModalActivity} 
        propertyId={propertyId} 
        assets={assetData}
        position={position}
        activityActive={activityActive}
      />
    </DashboardWrapper>
   
  );
}

export default Dashboard;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;