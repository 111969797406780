import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import { assetService } from '@iso/services';
import {  IconAsset  } from '@iso/containers/Pages/PropertyMap/Icons/IconAsset';
import _ from 'lodash';

const Asset = (props) => {
  const [assets, setAssets] = useState({});
  const objectRef = React.useRef([]);

  const updateDataAssets = React.useCallback((propertyId) => {
    assetService.getList(propertyId).then(res => {
      if(res.code === '0000'){
        setAssets(res.facilities);
      }
    })
  }, [props.primaryObjects]);

  useEffect(() => {
    if(props.propertyId){
      updateDataAssets(props.propertyId);
    }
  }, [updateDataAssets, props.propertyId]);

  const handleShowAssets = (data) => {
    var html = [];
    if(data.length > 0){
      _.forEach(data, function(asset){
        if(asset.lat && asset.lng){
          html.push(
            <Marker 
              key={asset.id} 
              position={[asset.lat, asset.lng]}
              icon={IconAsset(asset.category, asset.tag_colour, asset.animal_type, asset.type)}
              ref={el => (objectRef.current[asset.id] = el)}            
            />
          );
        }
      });
    }
    return html;
  }

  return (
    <>
      {handleShowAssets(assets)}
    </>
  )
}

export default Asset;