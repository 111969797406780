import styled from "styled-components";

export const Table2Wrapper = styled.div`
  .table2 .ant-table-thead th {
    background-color: #6ea2ff;
    border: none;
  }
  .table2 .ant-table-tbody tr:first-child td {
    text-align: center;
  }
`;
