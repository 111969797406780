import React, { useState, useEffect } from "react";
import Loader from "@iso/components/utility/loader";
import queryString from "query-string";
import _ from "lodash";
import { searchService } from "../../../services/search.service";
import PropertyBox from "./partials/PropertyBox";
import LiveStockBox from "./partials/LiveStockBox";
import PropertyAreaBox from "./partials/PropertyAreaBox";
import MobBox from "./partials/MobBox";
import AssetBox from "./partials/AssetBox";
import TaskBox from "./partials/TaskBox";
import Property from "../Property/Property";
import Asset from "../Asset/Asset";
import LiveStock from "../LiveStock/LiveStock";
import Mob from "../Mob/Mob";
import PropertyArea from "../PropertyArea/PropertyArea";
const SearchPage = (props) => {
  const [search_result, setSearchResult] = useState([]);
  const search_text = queryString.parse(props.location.search).s || "";
  useEffect(() => {
    searchService.search(search_text).then((res) => {
      setSearchResult(res);
    });
    return () => {};
  }, [search_text]);

  return (
    <div>
      {!search_result ? (
        <Loader />
      ) : (
        <div>
          <Property search_result={search_result} />
          <br />
          <PropertyArea search_result={search_result} />
          <br />
          <Asset search_result={search_result} />
          <br />
          <LiveStock search_result={search_result} />
          <br />
          <Mob search_result={search_result} />
          <br />
          <TaskBox search_result={search_result}></TaskBox>
        </div>
      )}
    </div>
  );
};

export default SearchPage;