import React, { useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import IntlMessages from "@iso/components/utility/intlMessages";
import StopDeviceAlarmRequestWrapper from "./StopDeviceAlarmRequest.styles";
import { Button, message } from "antd";
import logo from "@iso/assets/images/logo.png";
import queryString from "query-string";
import { formService, alertDeviceService } from "@iso/services";
import StopDeviceAlarmRequestModal from "./StopDeviceAlarmRequestModal";

export default function StopDeviceAlarmRequest() {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const token = queryString.parse(window.location.search).token || "";
  const [alertDevice, setAlertDevice] = useState({});
  const [visibleStopDeviceAlarmRequestModal, setVisibleStopDeviceAlarmRequestModal] = useState(true);

  React.useEffect(() => {
    if(token){
      alertDeviceService.getAlertDeviceByToken(token).then((res) => {
        if (res.code === "0000") {
          setVisibleStopDeviceAlarmRequestModal(true)
          setAlertDevice(res.form);
        } else {
          message.error('Token is invalid');
        }
      });
    }
  }, [token]);

  const cancelModal = () => {
    setVisibleStopDeviceAlarmRequestModal(false);
    setAlertDevice({});
  };

  return (
    <StopDeviceAlarmRequestWrapper className="isoSignInPage">
      {!isLoggedIn && 
      <div className="isoLoginContentWrapper">
        <div className="isoLoginContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <img src={logo} height="40" />
            </Link>
          </div>
          <div className="isoSignInForm">
            <Link to="/signin" className="isoForgotPass">
              <Button type="primary" className="btn-success">
                <IntlMessages id="page.signInButton" />
              </Button>
            </Link>

            <div className="isoCenterComponent isoHelperWrapper">
              <Link to={`/signup`}>
                <IntlMessages id="page.signInCreateAccount" />
              </Link>
            </div>
          </div>
        </div>
      </div>}
      <StopDeviceAlarmRequestModal
        visibleStopDeviceAlarmRequestModal={visibleStopDeviceAlarmRequestModal}
        cancelModal={cancelModal}
        alertDevice={alertDevice}
      />
    </StopDeviceAlarmRequestWrapper>
  );
}
