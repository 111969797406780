export const productCategoryTypes = [
  {
    label: 'Asset',
    value: 'asset',
  },
  {
    label: 'Livestock',
    value: 'livestock',
  },
  {
    label: 'Property',
    value: 'property',
  }
]