import styled from "styled-components";
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const ModalActivityWrap = styled(Modal)`
  .btn-success{
    background-color: #538c53;
    border-color: #538c53;
    &:hover{
      opacity: 0.8;
    }
  }
  .addHazards{
    width: 100%;
    background-color: rgb(63, 63, 68);
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: center;
    height: auto;
    border: transparent;
  }

  .attachLocationBtn{
    width: 100%;
    background-color: #8F9497;;
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: center;
    height: auto;
    border: transparent;
  }

  .ant-timeline-item-head.ant-timeline-item-head-green {
    border-color:rgb(248, 14, 70);
    color: rgb(248, 14, 70);
}

`;

export default WithDirection(ModalActivityWrap);
