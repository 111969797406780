import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Input, Button } from "antd";
import appActions from "@iso/redux/app/actions";
import TopbarUser from "./TopbarUser";
import TopbarWrapper from "./Topbar.styles";
import TopbarSearch from "./TopbarSearch";
import IntlMessages from "@iso/components/utility/intlMessages";
import { profileService } from "@iso/services";
import { pushnotification } from "../../services/pushnotification.service";
import { SearchOutlined ,CloseCircleOutlined} from "@ant-design/icons";
import Notification from "./Notification";
import Setting from "./Setting";
import Hamburger from "./Hamburger";
import ListNotification from "./ListNotification";
import { useHistory } from "react-router";
import SharingMapModal from "./SharingMapModal";
import SARModal from "./SARModal";
import SAR from "./SAR";
import FormIcon from "./FormIcon";
import FormModal from "./FormModal";
import queryString from "query-string";
import AgencyStyleWrapper from "@iso/containers/Pages/Agency/Agency.styles";
import ContactAgentModal from "@iso/containers/Pages/Agency/ContactAgentModal";

const { Header } = Layout;
const { toggleCollapsed, toggleChangeText ,changeCurrent} = appActions;
const { Search } = Input;
const URL =
  process.env.REACT_APP_WEB_SOCKET_PROTOCOL +
  process.env.REACT_APP_WEB_SOCKET_URL;

export default function Topbar() {
  const history = useHistory();
  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.topbarTheme
  );
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const notificationData = useSelector(
    (state) => state.notification.notificationData
  );
  const activeAgent = useSelector((state) => state.property.agent);
  //const [isToggle, setIsToggle] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("");
  const textTopbar = useSelector((state) => state.App.textTopBar);
  const { collapsed, openDrawer } = useSelector((state) => state.App);
  const [profileData, setProfileData] = React.useState({});
  const [modalSAROpen, setModalSAROpen] = React.useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [isShowSharingMapModal, setIsShowSharingMapModal] = useState(false);
  const [countNotification, setCountNotification] = useState(0);
  const [dataNotification, setDataNotification] = useState([]);
  const [readyState, setReadySate] = useState();
  const [searchInput,setsearchInput]= React.useState('');
  const [ws, setWs] = useState();
  const [isShowFormModal, setIsShowFormModal] = useState(false);
  const [isShowContactAgentModal, setIsShowContactAgentModal] = useState(false);
  const agentLogo =
    activeAgent && activeAgent.logos
      ? activeAgent.logos[activeAgent.logos.length - 1]
      : null;
  const [toggleNoti, setToggleNoti] = useState(false);
  const isMobileScreen = window.innerWidth < 768;
  const dispatch = useDispatch();

  const handleToggle = React.useCallback(() => {
    dispatch(toggleCollapsed());
    dispatch(toggleChangeText());
  }, [dispatch]);
  
  useEffect(() => {
    setWs(new WebSocket(URL));
  }, []);

  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: "fixed",
    height: 70,
  };


  useEffect(() => {
    if (activePropertyId && currentUser) {
      updateProfileData();
      getDataNotification();
    }
  }, [currentUser, activePropertyId]);

  useEffect(() => {
    if (ws) {
      ws.onopen = function (e) {
        console.log("webSocket connected");
      };

      ws.onmessage = (e) => {
        if (e.data) {
          getDataNotification();
        }
      };

      return () => {
        ws.onclose = () => {
          console.log("WebSocket Disconnected");
          setWs(new WebSocket(URL));
        };
      };
    }
  }, [ws, dataNotification]);
  const updateProfileData = () => {
    profileService.getProfile().then((res) => {
      if (res.code === "0000") {
        setProfileData(res.user);
      }
    });
  };

  const handleSearch = (e) => {
    if (e.key == "Enter") {
      history.push("/search?s=" + e.target.value);
    }
  };

  const isMap = window.location.pathname.split("/").slice(-1)[0] == "map";

  useEffect(() => {
    if (Object.keys(notificationData).length > 0) {
      const data = {
        notification: {
          property_id: activePropertyId,
          user_id: currentUser.id,
          type: notificationData.type,
          data: notificationData.data,
        },
      };
      if (notificationData.id) {
        pushNotification(data, notificationData.id);
      } else {
        pushNotification(data, "");
      }
    }
  }, [notificationData]);

  const getDataNotification = () => {
    
    pushnotification.getData(activePropertyId, currentUser.id).then((res) => {

      if (res.code === "0000") {
        console.log("res",res);
        setDataNotification(res.notification);
        setCountNotification(res.notification.length);
      }
    });
  };

  const pushNotification = (notificationData, id) => {
    pushnotification.storeOrUpdate(notificationData, id).then((res) => {
      if (res.code === "0000") {
        webSocket();
        setDataNotification(res.notification);
      }
    });
  };

  // websocket
  const webSocket = () => {
    ws.send("new notification");
  };

  

  const showFormModal = () => {
    setIsShowFormModal(true);
  };

  const cancelFormModal = () => {
    setIsShowFormModal(false);
  };

  const showContactAgentModal = () => {
    setIsShowContactAgentModal(true);
  };

  const cancelContactAgentModal = () => {
    setIsShowContactAgentModal(false);
  };
  const handleCancelSearch = () => {

    setsearchInput('')
    history.goBack();
    
      dispatch(changeCurrent(['dashboard']));
  }
  return (
    <AgencyStyleWrapper>
      <TopbarWrapper>
        {currentUser.is_public_view_user && agentLogo ? (
          <img className="agent-logo" src={agentLogo.full_path} height="40" />
        ) : (
          <></>
        )}
        <Header
          style={styling}
          className={`menuTop ${
            isCollapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }`}
        >
          <div className="isoLeft">
          {isMobileScreen && (
              <button
                className={
                  isCollapsed
                    ? "triggerBtn menuCollapsed"
                    : "triggerBtn menuOpen"
                }
                style={{ color: customizedTheme.textColor }}
                onClick={handleToggle}
              />
            )}
         <Input
  placeholder="Search…"
  size="large"
  className="search-bar"
  value={searchInput}
  onChange={(e) => setsearchInput(e.target.value)}
  onKeyDown={handleSearch}
  prefix={
    !searchInput ? (
      <>
        <SearchOutlined className="site-form-item-icon" />
        {/* <AnotherIconComponent className="site-form-item-another-icon" /> */}
      </>
    ) : (
      <CloseCircleOutlined
        onClick={handleCancelSearch}
        className="site-form-item-cancel"
      />
    )
  }
/>

          </div>

          <ul className="isoRight">
            {window.location.pathname === "/share-map" && activeAgent ? (
              <li>
                <Button
                  id="btn-contact-agent"
                  type="primary"
                  onClick={showContactAgentModal}
                >
                  <IntlMessages id="topbar.contactAgent" />
                </Button>
              </li>
            ) : (
              <>
                <li onClick={() => setModalSAROpen(true)}>
                <SAR />
              </li>
                <li className="head-bar-notificication">
                  <Notification
                    setToggleNoti={setToggleNoti}
                    toggleNoti={toggleNoti}
                  />
                  <span className="count-notification">
                    {countNotification}
                  </span>
                  {toggleNoti && (
                    <ListNotification
                      getDataNotification={getDataNotification}
                      dataNotification={dataNotification}
                      currentUser={currentUser}
                    />
                  )}
                </li>
                <li onClick={showFormModal}>
                  <FormIcon />
                </li>
              </>
            )}
            <li className="isoUserName">
              {profileData.name ? profileData.name : ""}
            </li>
            <li onClick={() => setSelectedItem("user")} className="isoUser">
              <TopbarUser />
            </li>
            {/* <li>
              <Hamburger />
            </li> */}
          </ul>
        </Header>
        <FormModal
          isShowFormModal={isShowFormModal}
          cancelFormModal={cancelFormModal}
        />
        <SARModal modalSAROpen={modalSAROpen} setModalSAROpen={setModalSAROpen} />
        <ContactAgentModal
          isShowContactAgentModal={isShowContactAgentModal}
          cancelModal={cancelContactAgentModal}
        />
      </TopbarWrapper>
    </AgencyStyleWrapper>
  );
}
