import React from 'react';
import { Link } from 'react-router-dom';
import siteConfig from '@iso/config/site.config';
import logo from '@iso/assets/images/logo.png';
import logo_icon from '@iso/assets/images/favicon1.png';
import queryString from 'query-string';
import { agentSetting } from '@iso/constants/agent';
import { useSelector } from 'react-redux';

export default ({ collapsed }) => {
  const activeAgent = useSelector((state) => state.property.agent);
  let appLogo = activeAgent && activeAgent.logos ? activeAgent.logos[activeAgent.logos.length -1] : null;
  appLogo = appLogo ? appLogo.full_path : logo
  let appLogoIcon = activeAgent && activeAgent.icons ? activeAgent.icons[activeAgent.icons.length -1] : null;
  appLogoIcon = appLogoIcon ? appLogoIcon.full_path : logo_icon

  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <Link to="/dashboard"><img src={appLogoIcon} height="40"/></Link>
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/dashboard"><img src={appLogo} height="40"/></Link>
        </h3>
      )}
    </div>
  );
};
