import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const QualificationsWrapper = styled.div`
.sc-dNsVcS.ddbmvf {
  padding:15px !important;
}
thead{
  text-align : left;
}
.tableCust .react-collapsible-theme .react-collapsible thead > tr > th {
  background-color : white !important;
  color: #0B2A1B !important;
font-size: 12px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left;

}
.react-collapsible-theme .react-collapsible thead > tr > th {
  background-color:white;
}
tr:nth-child(odd) { background-color: #EAECF0; }
tr:nth-child(even) {background-color: white;}

.ant-dropdown-menu-item.property-area-dot-btn{
  color:black !important;
  // border-bottom: 1px solid #E3E3E3 !important;
  //   color: #A8A8A8  !important;
  //   font-family: Sora;
  //   font-size: 16px;
  //   font-weight: 400;
  //   line-height: 32px;
  //   letter-spacing: 0.75px;
  //   text-align: left;
}
table.react-collapsible .react-collapsible thead > tr > th{
  color: red !important ;
}
  &.user-access-modal{
    width: 50%!important;
    // margin: 10px;
   
    .profile-area{
    background-color:white;
    width:50%;
    margin:10px;
    padding:15px !important ;
  }
 
  }
  .ant-modal-body{
    padding: 10px;
  }
  #containerBox {
    padding: 0;
}
.tableCust table.react-collapsible{
  border : none !important;
  box-shadow : none !important;
}
.tableCust{
  border : none;
  padding : 0px !important;
}
.tableCust table.react-collapsible tbody  :hover{
  background-color: #f1f3f6;
}

  .AddQualification{
    display: flex !important;
    width: 183px !important;
    height: 40px !important;
    padding: 8px !important;
    justify-content: center;
    align-items: center;
    gap: 10px !important;
    border-radius: 8px !important;
background: var(--Primary-Color, #0B2A1B );
  }
  .form-title{
    padding: 0px 24px 24px 0px;
    display: flex;
    align-items: center;
    margin: auto;
    // padding: 16px 24px;
    // border-bottom: 1px solid #eaeef1;
    h3{
      color:#0B2A1B;
      width: 100% ;
      font-size: 20px;
    }
    h2{
      color:#1A202C;
      width: 100% ;
      font-size:24px;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  #containerBox h4{
    padding: 15px !important;
  }
@media only screen and (max-width : 375px){
  .form-title{
    display : block;
    padding: 0px 0px 24px 0px;
    .AddQualification{
      margin-top : 10px;
      width : 100% !important;
    }
  }
}
`;

export default WithDirection(QualificationsWrapper);