import React,{useEffect, useLayoutEffect} from 'react';
import { useHistory, useLocation} from 'react-router-dom';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import Box from '@iso/components/utility/box';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dropdown, Input, Menu, Pagination, Popconfirm, Select, message } from 'antd';
import PropertyManagePage from './Property.styles';
import { PlusOutlined,EllipsisOutlined,FileTextOutlined, SearchOutlined,
  CloseCircleOutlined, UpCircleOutlined,HistoryOutlined,EditOutlined, CheckCircleOutlined, UserOutlined, QuestionCircleOutlined, ArrowUpOutlined, EyeOutlined, PlusCircleOutlined ,SettingOutlined, FundViewOutlined} from '@ant-design/icons';
import propertyActions from '@iso/redux/property/actions';
import PropertyModal from './PropertyModal';
import SelectPropertyModal from './SelectPropertyModal';
import UserAccessModal from './UserAccessModal';
import PropertyTableFirstCell from './PropertyTableFirstCell';
import _, { property } from 'lodash';
import Table from '@iso/components/collapsing-table/table';
import { propertyService, permissionService } from '@iso/services';
import SubscriptionModal from './SubscriptionModal';
import { subscriptionPlan } from '@iso/constants/subscriptionPlan';
import FormModal from '@iso/containers/Pages/Form/FormModal';
import PropertyHistoryModal from './PropertyHistoryModal';
import ImportModal from './ImportModal';
import OptiWeightModal from './OptiWeightModal';
import IotDeviceModal from './IotDeviceModal';
import WelcomeModal from '@iso/components/WelcomeModal/WelcomeModal';
import "./ManageProperty.css";
import { propertyHelper } from "@iso/lib/helpers/propertyHelper";
import Loader from "../../../components/utility/loader";

const { changeActiveProperty } = propertyActions;

const Property = (props) => {
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePropertySubscription = useSelector((state) => state.property.activePropertySubscription);
  const activePermission = useSelector((state) => state.permission);
  const [visible, setVisiblity] = React.useState(false);
  const activeProperty = JSON.parse(localStorage.getItem("active_property"));
  const [visibleUserAccess, setVisibleUserAccess] = React.useState(false);
  const [editProperty, setEditProperty] = React.useState({});
  const [property, setProperty] = React.useState({});
  const { Option } = Select;
  const [properties, setProperties] = React.useState([]);
  const [isSaved, setIsSaved] = React.useState(false);
  const [visibleSelectProperty, setVisiblitySelectProperty] = React.useState(false);
  const [visibleFormModal, setVisiblityFormModal] = React.useState(false);
  const [visibleHistoryModal, setVisiblityHistoryModal] = React.useState(false);
  const [propertyHistory, setPropertyHistory] = React.useState({});
  const [visibleImportModal, setVisibleImportModal] = React.useState(false);
  const [visibleOptiWeight, setVisibleOptiWeight] = React.useState(false);
  const [visibleIotDevice, setVisibleIotDevice] = React.useState(false);
  const [visibleWelcomeModal, setVisibleWelcomeModal] = React.useState(false);
  const [visibleUpgradeModal, setVisibleUpgradeModal] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(11);
  const [searchInput, setsearchInput] = React.useState("");
  const [loadingData, setLoadingData] = React.useState(true);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedProperties = searchQuery?.slice(startIndex, endIndex);

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const displayData =
    props.search_result &&
    props.search_result.Property?.slice(startIndex, endIndex);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {    
    getPropertyList();
  }, [activePropertyId, isSaved, activePropertySubscription]);

  const getPropertyList = () => {
    propertyService.getProperties().then(res => {
      if(res.properties.length === 0){
        setVisiblitySelectProperty(true);
        setVisibleWelcomeModal(true);
        setLoadingData(false);
      }else{
        setProperties(res.properties);
        setSearchQuery(res.properties)
        setLoadingData(false);
        if(res.properties.length === 1){
          localStorage.setItem('active_property', JSON.stringify(res.properties[0]));
          dispatch(changeActiveProperty(res.properties[0]));
          setVisiblitySelectProperty(false);
        }else if(!activeProperty){
          setVisiblitySelectProperty(true);
        } 
      }
    }); 
  }

  const handleMenuClick = (property, e) => {
    if (e.key === 'editStatus') {
      localStorage.setItem('active_property', JSON.stringify(property));
      dispatch(changeActiveProperty(property));
      // call api update active status
      propertyService.active(property.id).then(res => {
        if(res.code == "0000") {
          console.log("Success");
        }
      });
    }
    if (e.key === 'userAccess') {
      setVisibleUserAccess(true);
      setProperty(property);
    }

    if(e.key === 'upgrade'){
      setProperty(property);
      setVisibleUpgradeModal(true);
    }

    if(e.key == 'import'){
      showImportModal();
      setProperty(property);
    } 
    if(e.key == 'optiWeight'){
      setProperty(property);
      setVisibleOptiWeight(true);
    }
    if(e.key == 'iotDevice'){
      setProperty(property);
      setVisibleIotDevice(true);
    }
    if(e.key == 'manageSubscription'){
      propertyService.getStripePortalUrl(property.id).then(res => {
        if(res.code === '0000'){
          window.open(res.url, '_blank');
        }else{
          message.error(res.message)
        }
      });
    }
  }

  const showModal = () => {
    setVisiblity(true);
  }

  const cancelModal = () => {
    setVisiblity(false);
    setEditProperty({});
  }

  const cancelSelectPropertyModal = () => {
    setVisiblitySelectProperty(false);

    if (location.state?.visible) {
      setVisiblity(true);
    }
  }

  const cancelWelcomeModal = () => {
    setVisibleWelcomeModal(false);
  }

  const cancelUserAccessModal = () => {
    setVisibleUserAccess(false);
    setProperty({});
  }

  const cancelUpgradeModal = () => {
    setVisibleUpgradeModal(false);
    setProperty({});
  }

  const showHistory = (property) => {
    setPropertyHistory(property);
    setVisiblityHistoryModal(true);
  }

  const cancelHistory = () => {
    setPropertyHistory({});
    setVisiblityHistoryModal(false);
  }
  const menuList = (property, permission) => (
    <Menu onClick={handleMenu.bind(this, property)}>
          {(permission == "owner" || permission == "admin") && <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>}
     
      <Menu.Item key="history" icon={ <FileTextOutlined/>} >
        <IntlMessages id="History" />
      </Menu.Item>
      { (permission == "owner" || permission == "admin" ) && <Menu.Item key="userAccess" icon={<UserOutlined />}>
        <IntlMessages id="propertyPage.managePage.userAccess" />
      </Menu.Item>}
      {(permission == "owner") && <Menu.Item key="addForm" icon={<PlusCircleOutlined />}>
        <IntlMessages id="Add Form" />
      </Menu.Item>}
      <Menu.Item key="viewForm" icon={<EyeOutlined />}>
        <IntlMessages id="View Form" />
      </Menu.Item>
      {permission == "owner" && <Menu.Item key="upgrade" icon={<UpCircleOutlined />}>
        <IntlMessages id="propertyPage.managePage.upgrade" />
      </Menu.Item>}
      <Menu.Item key="viewTiers" icon={<QuestionCircleOutlined />}>
        <IntlMessages id="propertyPage.managePage.viewTiers" />
      </Menu.Item>
     
      {activePropertyId != property.id && <Menu.Item key="editStatus" icon={<CheckCircleOutlined />}>
        <IntlMessages id="propertyPage.managePage.active" />
      </Menu.Item>}
      {propertyHelper.checkIsGoldPackage(property.subscription_label) && 
        <Menu.Item key="import" icon={<PlusOutlined />}>
          <IntlMessages id="mobPage.managePage.import" />
        </Menu.Item>
      }      
      {propertyHelper.checkIsGoldPackage(property.subscription_label) && 
        <Menu.Item key="optiWeight" icon={<SettingOutlined />}>
          <IntlMessages id="antTable.optiWeight" />
        </Menu.Item>
      }
      {propertyHelper.checkIsGoldPackage(property.subscription_label) && 
        <Menu.Item key="iotDevice" icon={<SettingOutlined />}>
          <IntlMessages id="antTable.iotDevice" />
        </Menu.Item>
      }
      {property.stripe_id && 
        <Menu.Item key="manageSubscription" icon={<FundViewOutlined />}>
          <IntlMessages id="antTable.manageSubscription" />
        </Menu.Item>
      }
     
    </Menu>
  );
    const handleMenu = (property, e) => {


    if (e.key === "viewDetails") {
    }
    if (e.key === "history") {
      setPropertyHistory(property);
      setVisiblityHistoryModal(true);
 
    }
    if (e.key === "editDetails") {
      setVisiblity(true);
      setEditProperty(property);
    }
    if (e.key === 'userAccess') {
      setVisibleUserAccess(true);
      setProperty(property);
    }
    if(e.key === 'viewForm') {
      history.push('/manage-forms');
    }

    if(e.key === 'addForm') {
      setProperty(property);
      setVisiblityFormModal(true);
    }
    if(e.key === 'upgrade'){
      setProperty(property);
      setVisibleUpgradeModal(true);
    }
    if (e.key === 'viewTiers') {
      window.open("https://www.airagri.com.au/subscription-pricing-plans/");
    }
    if (e.key === 'editStatus') {
      localStorage.setItem('active_property', JSON.stringify(property));
      dispatch(changeActiveProperty(property));
      // call api update active status
      propertyService.active(property.id).then(res => {
        if(res.code == "0000") {
          console.log("Success");
        }
      });
    }
    if (e.key === 'userAccess') {
      setVisibleUserAccess(true);
      setProperty(property);
    }

    if(e.key === 'upgrade'){
      setProperty(property);
      setVisibleUpgradeModal(true);
    }

    if(e.key == 'import'){
      showImportModal();
      setProperty(property);
    } 
    if(e.key == 'optiWeight'){
      setProperty(property);
      setVisibleOptiWeight(true);
    }
    if(e.key == 'iotDevice'){
      setProperty(property);
      setVisibleIotDevice(true);
    }
    if(e.key == 'manageSubscription'){
      propertyService.getStripePortalUrl(property.id).then(res => {
        if(res.code === '0000'){
          window.open(res.url, '_blank');
        }else{
          message.error(res.message)
        }
      });
    }
  }
  const columns = [
    {
      label: <IntlMessages id="antTable.title.property" />,
      accessor: 'name',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: cell => {
        return (
          <PropertyTableFirstCell 
          activePropertyId={activePropertyId}
          property={cell.row} 
          />
        );
      }
    },
    {
      label: <IntlMessages id="antTable.title.address" />,
      accessor: 'address',
      minWidth: 300,
      sortable: true,
      position: 2,
      priorityLevel: 2
    },
    {
      label: <IntlMessages id="antTable.title.subscription_tier" />,
      accessor: 'subscription_label',
      minWidth: 300,
      sortable: true,
      position: 3,
      priorityLevel: 3,
      CustomComponent: cell => {
        let row = cell.row
        if(row.subscription_label){
          return subscriptionPlan.map((value, index) => {
            if(value.value == row.subscription_label){
              return (
                <div>{value.label}</div>
              )
            }
          });
        }else{
          return (<div>{<IntlMessages id="subscription.prices.REACT_APP_STRIPE_PLAN_BRONZE" />}</div>)
        }
        
      }
    },
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let property = cell.row
        let permission = permissionService.getPermission(property.permission_id);
        return (
          <Dropdown overlay={menuList(property,permission)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handleOptionClick = (value) => {
    _.forEach(properties,(property)=>{
      if(property.id == value){
        localStorage.setItem('active_property', JSON.stringify(property));
        dispatch(changeActiveProperty(property));
      }
    })

  };

  const renderOptionsProperty = () => {
    let options = [];
    _.forEach(properties, (property, index) => {
      options.push(
        <Option key={index} value={property?.id} className="property-option">
          {property?.name}
        </Option>
      );
    });

    return (
      <Select
        placeholder="Select Active Property"
        value={activeProperty?.name}
        size="large"
        className='custSelect'

        onChange={handleOptionClick}
      >
        {options}
      </Select>
    );
  };

  const onSaved = () => {
    setIsSaved(!isSaved);
  }

  const cancelFormModal = () => {
    setVisiblityFormModal(false);
  }

  const showImportModal = () => {
    setVisibleImportModal(true);
  }

  const cancelImportModal = () => {
    setVisibleImportModal(false);
    setProperty({});
  } 
  const cancelOptiWeight = () => {
    setVisibleOptiWeight(false);
    setProperty({});
  }  
  const cancelIotDevice = () => {
    setVisibleIotDevice(false);
    setProperty({});
  }
  const handleCancelSearch = () => {
    setsearchInput("");
    setSearchQuery(properties);
  };
  const handleSearch = (e) => {
    setsearchInput(e.target.value);
    const searchTerm = e.target.value.toLowerCase();
    const filteredAssets = properties.filter((mob) => {
      return (
        mob.name.toLowerCase().includes(searchTerm) 
        ||
        mob.address.toLowerCase().includes(searchTerm)
        // mob.subscription_label.toLowerCase().includes(searchTerm)
      );
    });
    setSearchQuery(filteredAssets);
    setCurrentPage(1); // Reset the current page to 1 when the search term changes
    
  };
  return (
    <LayoutWrapper>
      <PropertyManagePage>
      <Box id="containerBox">
          <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Manage Properties</h2>
            </div>
            {props.search_result ? (
              ""
            ) : (
              <>
              <Input
              placeholder="Search…"
              suffix={
                !searchInput && (
                  <SearchOutlined className="site-form-item-icon" />
                )
              }
              prefix={
                searchInput && (
                  <CloseCircleOutlined
                    onClick={handleCancelSearch}
                    className="site-form-item-cancel"
                  />
                )
              }
              size="large"
              className="search-bar"
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
            <div className="button-group" style={{ gap: "12px" }}>
             
              {renderOptionsProperty()}
              <Button
                icon={<PlusOutlined />}
                type="primary"
                className="cutAddButton"
                onClick={showModal}
                size="large"
                style={{
                  background: "#0B2A1B",
                  borderRadius: "8px !important",
                }}
              >
                <IntlMessages id="propertyPage.managePage.addProperty" />
              </Button>
            </div>
            </>)}
          </div>
          {loadingData ? (
            <Loader />
          ) : (
            <>
              {props.search_result &&
              props.search_result.Property &&
              props.search_result.Property.length > 0 ? (
                <>
                  <div className="manage-table">
                    <Table
                      columns={columns}
                      rows={displayData}
                      containerId="containerBox"
                      showPagination={false}
                      rowSize={99999}
                    />
                  </div>
                  {props.search_result.Property?.length > 10 && (
                    <div style={{ padding: "20px" }}>
                      <Pagination
                        current={currentPage}
                        total={props.search_result.Property?.length}
                        pageSize={pageSize}
                        onChange={handlePageChange}
                        showSizeChanger
                        pageSizeOptions={["10", "20", "50", "100"]}
                        onShowSizeChange={handlePageSizeChange}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="manage-table">
                    <>
                      {!props.search_result &&
                      displayedProperties &&
                      displayedProperties.length > 0 ? (
                        <>
                          <Table
                            columns={columns}
                            rows={displayedProperties}
                            containerId="containerBox"
                            showPagination={false}
                            rowSize="99999"
                          />

                          <div style={{ padding: "20px" }}>
                            <Pagination
                              current={currentPage}
                              total={searchQuery?.length}
                              pageSize={pageSize}
                              onChange={handlePageChange}
                              showSizeChanger
                              pageSizeOptions={["10", "20", "50", "100"]}
                              onShowSizeChange={handlePageSizeChange}
                            />
                          </div>
                        </>
                      ) : (
                        <h1 style={{ padding: "15px 15px", color: "gray" }}>
                          <IntlMessages id="dashboardPage.noData" />
                        </h1>
                      )}
                    </>
                  
                </div>
              )}
            </>
          )}
        </Box>
        <PropertyModal editProperty={editProperty} visible={visible} cancelModal={cancelModal} onSaved={onSaved}/>
        <UserAccessModal visible={visibleUserAccess} propertyId={property.id} cancelModal={cancelUserAccessModal} />
        <SelectPropertyModal activeProperty={activeProperty} propertyPage={true} visibleSelectProperty={visibleSelectProperty} cancelSelectPropertyModal={cancelSelectPropertyModal}/>
        <SubscriptionModal visible={visibleUpgradeModal} property={property} cancelModal={cancelUpgradeModal} onSaved={onSaved}/>
        <FormModal visibleFormModal={visibleFormModal} cancelModal={cancelFormModal} propertyId={property.id}
          fromProperty={true}
        />
        <PropertyHistoryModal visibleHistoryModal={visibleHistoryModal} cancelHistory={cancelHistory} propertyId={propertyHistory.id}/>
        <ImportModal visibleImportModal={visibleImportModal} cancelImportModal={cancelImportModal} property={property} />
        <OptiWeightModal visibleOptiWeight={visibleOptiWeight} cancelOptiWeight={cancelOptiWeight} property={property} />
        <IotDeviceModal visibleIotDevice={visibleIotDevice} cancelIotDevice={cancelIotDevice} onSaved={onSaved} property={property}/>
        <WelcomeModal visibleWelcomeModal={visibleWelcomeModal} cancelWelcomeModal={cancelWelcomeModal} getPropertyList={getPropertyList}></WelcomeModal>
      </PropertyManagePage>
    </LayoutWrapper>
  );
}

export default Property;
