import React,{useEffect, useLayoutEffect} from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector, useDispatch } from 'react-redux';
import { configs } from '@iso/components/Tables/configs';
import { Button, Dropdown, Menu, Select,DatePicker, Pagination ,Input} from 'antd';
import FormPage from './Form.styles';
import { PlusOutlined,
  DownOutlined,
  EditOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  FileOutlined,
  FileDoneOutlined,
  CheckCircleOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  ArrowUpOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  FormOutlined } from '@ant-design/icons';
import propertyActions from '@iso/redux/property/actions';
import _, { property } from 'lodash';
import Table from '@iso/components/collapsing-table/table';
import FormModal from './FormModal';
import InspectionModal from './InspectionModal';
import Loader from '@iso/components/utility/loader';
import { propertyService, permissionService, formService } from '@iso/services';
import { subscriptionPlan } from '@iso/constants/subscriptionPlan';
import { formTypes } from '@iso/constants/formTypes';
const { RangePicker } = DatePicker;
const { changeActiveProperty } = propertyActions;

const Form = (props) => {
  const dispatch = useDispatch();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePropertySubscription = useSelector((state) => state.property.activePropertySubscription);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [visibleFormModal, setVisiblityFormModal] = React.useState(false);
  const [selectedDateRange, setSelectedDateRange] = React.useState([]);
  const activeProperty = localStorage.getItem('active_property');
  const [property, setProperty] = React.useState({});
  const { Option } = Select;
  const [properties, setProperties] = React.useState([]);
  const [isSaved, setIsSaved] = React.useState(false);
  const [forms, setForms] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [visibleSelectProperty, setVisiblitySelectProperty] = React.useState(false);
  const [visibleInspectionModal, setVisibleInspectionModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState();
  const [searchInput, setsearchInput] = React.useState('');
  const [loadingData, setLoadingData] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(5);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedForms =searchQuery?.slice(startIndex, endIndex);

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {   
    if(activePropertyId){
      formService.getList(activePropertyId).then(res => {
        if(res.code === '0000'){
          setForms(res.forms);
          setSearchQuery(res.forms)
        }
      }); 
    } 
  }, [activePropertyId, isSaved, activePropertySubscription, props.isShowFormModal]);

  const updateFormData = () => {
    if(activePropertyId){
      formService.getList(activePropertyId).then(res => {
        if(res.code === '0000'){
          setForms(res.forms);
          setSearchQuery(res.forms);
          setLoadingData(false);
        }
      }); 
    }
  }

  useEffect(() => {
    updateFormData();
  }, [activePropertyId]);

  useEffect(() => {    
    propertyService.getProperties().then(res => {
      if(res.properties.length === 0){
        setVisiblitySelectProperty(true);
      }else{
        setProperties(res.properties);
        if(res.properties.length === 1){
          localStorage.setItem('active_property', JSON.stringify(res.properties[0]));
          dispatch(changeActiveProperty(res.properties[0]));
        }
      }
    }); 
  }, [activePropertyId, isSaved, activePropertySubscription]);

  const showModal = () => {
    setVisiblityFormModal(true);
  }

  const cancelModal = () => {
    setVisiblityFormModal(false);
    setFormData({});
  }

  const openEditModal = (form) => {
    setFormData(form);
    setVisiblityFormModal(true);
  }

  const openInspectionModal = (form) => {
    setFormData(form);
    setVisibleInspectionModal(true);
  }

  const cancelInspectionModal = () => {
    setFormData({});
    setVisibleInspectionModal(false);
  }

  // const menuForm = (property, permission) => (  
  //   <Menu onClick={handleMenuClick.bind(this, property)}>
  //     {(permission == "owner" || permission == "admin") && <Menu.Item key="addForm" icon={<PlusCircleOutlined />}>
  //       <IntlMessages id="propertyPage.managePage.addForm" />
  //     </Menu.Item>}
  //     {permission == "owner" && <Menu.Item key="viewForm" icon={<EyeOutlined />}>
  //       <IntlMessages id="propertyPage.managePage.viewForm" />
  //     </Menu.Item>}
  //   </Menu>
  // );

  const exportSubmissions = (form) => {
    formService.exportSubmissions(form.id).then(res => {
      if(res.code === '0000'){
        window.location.href = res.file_path
      }
    }); 
  }
  const menu = (form) => (
    <Menu onClick={handleMenuClick.bind(this, form)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
      <IntlMessages id="formPage.table.button.edit" />
      </Menu.Item>

      <Menu.Item key="submit" icon={<FormOutlined/>}>
      <IntlMessages id="formPage.table.button.submit" />
      </Menu.Item>

      <Menu.Item key="report" icon={<FileOutlined/>}>
      <IntlMessages id="formPage.table.button.report" />
      </Menu.Item>

      <Menu.Item key="Submissions" icon={<FileDoneOutlined />}>
      <IntlMessages id="Submissions" />
      </Menu.Item>


    </Menu>
  );
  const handleMenuClick = (form, e) => {
    if (e.key === 'editDetails') {
      openEditModal(form)
    }

    if (e.key === 'submit') {
      if (form.is_public && form.public_form_url) {
        window.open(form.public_form_url, '_blank').focus();
      } else {
        openEditModal(form)
      }
    }

    if (e.key === 'report') {
      exportSubmissions(form)
    }

    if (e.key === "Submissions") {
      openInspectionModal(form) 
    }
  }
  const columns = [
    {
      label: <IntlMessages id="formPage.table.title.formName" />,
      accessor: 'name',
      minWidth: 300,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="formPage.table.title.formStatus" />,
      accessor: 'is_public',
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let form = cell.row

        if(form.is_public == 0){
          return 'Private';
        }else{
          return 'Public';
        }       
      }
    },
    {
      label: <IntlMessages id="Created Date" />,
      accessor: 'date',
      minWidth: 200,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: <IntlMessages id="formPage.table.title.lastUpdatedAt" />,
      accessor: 'updated_at',
      minWidth: 200,
      sortable: true,
      position: 4,
      priorityLevel: 4,
    },
    {
      label: <IntlMessages id="formPage.table.title.lastActivityAt" />,
      accessor: 'last_activity',
      minWidth: 200,
      sortable: true,
      position: 5,
      priorityLevel: 5,
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      noTitle: true,
      CustomComponent: cell => {
        let form = cell.row;

        return (
          <Dropdown overlay={menu(form)} trigger={["click"]}>
          <div
            className="property-area-dot-btn"
            style={{ cursor: "pointer" }}
          >
            <EllipsisOutlined className="dotIcon"/>
          </div>
        </Dropdown>
        );
      }
    },
  ];

  const handleOptionClick = (value) => {
    _.forEach(properties,(property)=>{
      if(property.id == value){
        localStorage.setItem('active_property', JSON.stringify(property));
        dispatch(changeActiveProperty(property));
      }
    })

  };



  const renderOptionsProperty = () => {
    let options = [];
    _.forEach(properties,(property,index) => {
      options.push(
        <Option key={index} value={property.id} className="property-option" >
          {property.name}
        </Option>
      );
    })

  return (
      <Select
        placeholder="Select Active Property"
        value={activePropertyId}
        size="large"
        className='selectProperty'
        
        onChange={handleOptionClick}
      >
        {options}
      </Select>
    );
  }

  const onSaved = () => {
    setIsSaved(!isSaved);
  }
  const handleCancel = () => {

    setSelectedDateRange([])
      setForms(forms);
  };
  const handleDateRangeChange = (dates) => {
    
    setSelectedDateRange(dates);
    // Check if dates is not null before calling filterFormsByDateRange
    if (dates) {
      filterFormsByDateRange(dates);
    }else{
      setSearchQuery(forms);
    }
  };

  const filterFormsByDateRange = (dates) => {
    const [startDate, endDate] = dates;

    if (startDate && endDate) {
      const filteredForms = forms.filter((form) => {
        const formDate = new Date(form.updated_at); // Assuming 'updated_at' is a date property in your form object
        return formDate >= startDate && formDate <= endDate;
      });
      
      setSearchQuery(filteredForms);
    } else {
      // If no date range is selected, show all forms
      setSelectedDateRange([])
      setSearchQuery(forms);

    }
  };

  const handleSearch = (e) => {
    setsearchInput(e.target.value)
    const searchTerm = e.target.value.toLowerCase();
       const filteredForms = forms.filter((form) => {

         const formName = form.name.toLowerCase();
         const formStatus = form.is_public === 0 ? "private" : "public";
         const lastUpdatedAt = form.updated_at.toLowerCase(); // You might need to format this date
         const lastActivity = form.last_activity.toLowerCase();
         // Add more columns here as needed
 
         return (
           formName.includes(searchTerm) ||
           formStatus.includes(searchTerm) ||
           lastUpdatedAt.includes(searchTerm) ||
           lastActivity.includes(searchTerm)
           // Add more conditions for other columns
         );
       });
 
       setSearchQuery(filteredForms);
     
   };
 
 
 
   const handleCancelSearch = () => {
     setsearchInput('')
     setSearchQuery(forms);
   };
  return (
    <LayoutWrapper>
    <FormPage>
    <Box id="containerBox">
        <div className='manage-property-header'>
        <div className="mp-title">
              <h2 className="manage-property-title">Forms</h2>
            </div>
          <Input
            placeholder="Search…"
            suffix={!searchInput && <SearchOutlined CloseCircleOutlined className="site-form-item-icon" />}
            prefix={searchInput && <CloseCircleOutlined onClick={handleCancelSearch} className="site-form-item-cancel" />}
            size="large"
            className="search-bar"
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />

<div className="button-group" style={{ gap: "12px" }}>
            {(activePermission == "owner" || activePermission == "admin") && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                className="btn-success"
                onClick={showModal}
                size="large"
              >
                Add Form
              </Button>
            )}
            {renderOptionsProperty()}
            <RangePicker
              value={selectedDateRange}
              onCancel={handleCancel}
            onChange={handleDateRangeChange} renderExtraFooter={() => 'extra footer'} className="startEndDate" />
          </div>

        </div>

        <div className="table_forms">
        {loadingData ? (
                <Loader/>
              ) : (
                <>
          {displayedForms && displayedForms.length > 0 ? (
            <>
              <Table
                columns={columns}
                rows={displayedForms}
                containerId="containerBox"
                showPagination={false}
                rowSize="99999"
              />
               <div style={{padding:'16px'}}>
                  <Pagination
                    current={currentPage}
                    total={searchQuery?.length}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50", "100"]}
                    onShowSizeChange={handlePageSizeChange}
                  /></div>
              </>
          ) : (
            <h1>
              <IntlMessages id="dashboardPage.noData" />
            </h1>
          )}
          </>
              )}
        </div>
      </Box>
      <FormModal visibleFormModal={visibleFormModal} cancelModal={cancelModal} propertyId={activePropertyId}
        updateFormData={updateFormData} formData={formData}
        />
        <InspectionModal 
          visibleInspectionModal={visibleInspectionModal} 
          cancelInspectionModal={cancelInspectionModal} 
          formId={formData?.id} 
          propertyId={formData?.property_id}
        />
    </FormPage>
  </LayoutWrapper>
  );
}

export {Form};
