import React, {useState, useEffect } from 'react';
import DeviceTableWrapper from './DeviceTable.styles'; 
import { Row, Col, Button, Input, Space, Table, Select, Dropdown, Menu, Popconfirm, Form, Alert, Switch } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { deviceFactory } from '@iso/services';
import _ from 'lodash';
import IntlMessages from '@iso/components/utility/intlMessages';
import { PlusOutlined, DownOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined, UserOutlined, SettingOutlined, SoundOutlined } from '@ant-design/icons';
import AssignModal from './AssignModal';
import DeviceTimeModal from './DeviceTimeModal';
import StopDeviceAlarmRequestModal from './StopDeviceAlarmRequestModal';
import AddDeviceModalWrapper from './AddDeviceModal.styles';
import { deviceTypes } from '@iso/constants/deviceTypes';

const { Option } = Select;

const AddDeviceModal = (props) => {
  const {propertyId, onSaved} = props;

  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();

  const renderOptionsDeviceType = () => {
    let options = [];
    _.forEach(deviceTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Form.Item name="device_type" className='mb-2' rules={[{ required: true }]}>
        <Select
          placeholder="Select a device type"
          allowClear
          size="large"
          style={{ width: '100%' }}
        >
          {options}
        </Select>
      </Form.Item>
    );
  }

  const cancelModal = () => {
    props.cancelAddDeviceModal();
  }

  const handleSubmit = (e) => {
    form
      .validateFields()
      .then(async (values) => {
        deviceFactory.resolveService(values.device_type).storeOrUpdate(values).then(res => {
          if (res.code !== '0000') {
            setShowAlert(true);
            setMessage(res.message);
          } else {
            cancelModal();
            onSaved(res);
          }
        })
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  }

  const onAlertClose = () => {
    setShowAlert(false);
  }
  
  return (
    <AddDeviceModalWrapper
      forceRender={true}
      visible={props.visible}
      title={<IntlMessages id="safeFarmPage.device.modal.title" />}
      onCancel={cancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" onClick={handleSubmit} className="btn-success" type="primary">
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>
      ]}
    >
      <Form
        form={form}
        name="addDevice"
        scrollToFirstError
        fields={fields}
        style={{ display: 'block' }}
        initialValues={{ property_id: propertyId }}
      >
        <Row gutter={[10,15]}>
          <Col xs={{span: 24}} md={{span: 24}}>
            {renderOptionsDeviceType()}

            <Form.Item
              name="name"
              className="isoInputWrapper"
              rules={[
                {
                  required: true,
                  message: 'Please input your device name',

                },
                {
                  whitespace:true,
                  message: 'Please input your name',
                },
              ]}
            >
              <Input placeholder="Device Name" />
            </Form.Item>

            <Form.Item name="property_id" style={{ display: 'none' }}>
              <Input type="hidden" />
            </Form.Item>

            {showAlert &&
              <Alert
                message="Error"
                description={message}
                type="error"
                closable
                onClose={onAlertClose}
              />
            }
          </Col>
        </Row>
      </Form>
    </AddDeviceModalWrapper>
  );
}

export default AddDeviceModal;
