import styled from 'styled-components';
import { palette } from 'styled-theme';
import WithDirection from '@iso/lib/helpers/rtl';
import { handleShowRandomImage } from '@iso/constants/randomBackgrounds';

const ResetPasswordStyleWrapper = styled.div`

display:contents;

  .ant-btn-primary.btn-success{
    background-color: #0B2A1B;
    border-color: #0B2A1B;
    border-radius : 8px;
    &:hover{
      opacity: 0.8;
    }
  }

  .isoFormContentWrapper {
    min-height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${"" /* padding: 70px 50px 70px 0px; */}
    position: relative;
    background-color: #ffffff;
  }

  .isoFormContent {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 70px 50px;
    position: relative;
    background-color: #ffffff;

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 70px 20px;
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      margin-bottom: 70px;
      justify-content: center;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
        color: ${palette('secondary', 2)};
      }
    }

    .isoFormHeadText {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      justify-content: center;

      h3 {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        margin: 0 0 7px;
        color: ${palette('text', 0)};
      }

      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2;
        margin: 0;
        color: ${palette('text', 2)};
      }
    }

    .isoResetPassForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      .isoInputWrapper {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        input {
          &::-webkit-input-placeholder {
            color: ${palette('grayscale', 0)};
          }

          &:-moz-placeholder {
            color: ${palette('grayscale', 0)};
          }

          &::-moz-placeholder {
            color: ${palette('grayscale', 0)};
          }
          &:-ms-input-placeholder {
            color: ${palette('grayscale', 0)};
          }
        }
        button {
          height: 42px;
          width: 100%;
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .signInmain{
      width:100%;
        padding: 12px;
    height: 100vh;
    gap: 57px;
    flex-direction: column;
      }
    }
    @media only screen and (max-width: 425px) {
    .isoFormContent .isoFormContent{
    padding: 0px 0px 0px 0px !important;
    }
    .isoFormContent .isoLogoWrapper{
      margin-bottom :0px !important; 
      padding-bottom: 20px !important;
    }
    .signInmain{
      width:100% !important;
      display:block !important;
    }
  }
`;

export default WithDirection(ResetPasswordStyleWrapper);
