import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const ColorPickerWrapper = styled.div`
  .color-color {
    width: 36px;
    height: 14px;
    borderradius: 2px;
  }
  .color-swatch {
    padding: 3px;
    background: #fff;
    borderradius: 1px;
    boxshadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: "pointer";
    border: 1px solid;
    cursor: pointer;
  }
  .color-popover {
    position: absolute;
    z-index: 20;
  }
  .color-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .color-label{
    display: block;
    margin-bottom: 3px;
    margin-left: 5px;
  }
`;

export default WithDirection(ColorPickerWrapper);
