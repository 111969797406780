import styled from "styled-components";

export const NearMissFormWrapper = styled.div`
  padding: 20px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item:not(.no-style){
    margin-bottom: 24px
  }
  .type-of-concern{
    display: block
  }
`;
