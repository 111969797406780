import React, { useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import IntlMessages from "@iso/components/utility/intlMessages";
import VisitorFormWrapper from "./VisitorForm.styles";
import { Button, message } from "antd";
import logo from "@iso/assets/images/logo.png";
import queryString from "query-string";
import { formService, visitorFormService } from "@iso/services";
import VisitorFormModal from "./VisitorFormModal";

export default function VisitorForm() {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  const formToken = queryString.parse(window.location.search).token || "";
  const [formData, setFormData] = useState({});
  const [visibleVisitorFormModal, setVisibleVisitorFormModal] = useState(false);
  const [property, setProperty] = useState({});
  React.useEffect(() => {
    if (isLoggedIn) {
      setRedirectToReferrer(true);
    }
  }, [isLoggedIn]);

  let { from } = location.state || { from: { pathname: "/dashboard" } };

  React.useEffect(() => {
    if(formToken){
      visitorFormService.getVisitorForm(formToken).then((res) => {
        if (res.code === "0000") {
          setVisibleVisitorFormModal(true)
          setFormData(res.form);
          setProperty(res.property);
        } else {
          message.error(res.message);
        }
      });
    }
  }, [formToken]);

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  const cancelModal = () => {
    setVisibleVisitorFormModal(false);
    setFormData({});
  };

  return (
    <VisitorFormWrapper className="isoSignInPage">
      <div className="isoLoginContentWrapper">
        <div className="isoLoginContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <img src={logo} height="40" />
            </Link>
          </div>
          <div className="isoSignInForm">
            <Link to="/signin" className="isoForgotPass">
              <Button type="primary" className="btn-success">
                <IntlMessages id="page.signInButton" />
              </Button>
            </Link>

            <div className="isoCenterComponent isoHelperWrapper">
              <Link to={`/signup`}>
                <IntlMessages id="page.signInCreateAccount" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <VisitorFormModal
        visibleFormModal={visibleVisitorFormModal}
        cancelModal={cancelModal}
        propertyId={formData?.property_id}
        formData={formData}
        property={property}
      />
    </VisitorFormWrapper>
  );
}
