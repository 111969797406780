import { Collapse } from "antd";
import { HazardFormWrapper } from "@iso/containers/Pages/AdminPages/FormBuilder/HazardForm/HazardFormWrapper.style";

const { Panel } = Collapse;

const SurveyFormResult = (props) => {
  const handleString = (string) => {
    return string.replaceAll("_", " ")[0].toUpperCase() + string.replaceAll("_", " ").substring(1).replaceAll(/(---.*)$/g, "")
  };

  const renderSystemFormResult = (resultData) => {
    var newResult = [];
    var resultData = props.generateContent(resultData);

    resultData.map((submission, index) => {
      newResult.push(
        <>
          <h3>
            {handleString(submission[0])  + ': '}
          </h3>
            Answer: {props.renderAnswer(submission[1])}<br/><br/>
        </>
      )
    });

    return newResult;
  };

  return (
    <HazardFormWrapper>
      {Array.isArray(props.formData) ? 
        <Collapse>
          {
            props.formData.map((submission, index) => {
              return (
                <Panel header={submission.submisison_date} key={index}>
                {(props.formType == 'illness') ?
                  <>
                    <h4>Worker Detail</h4>
                    {renderSystemFormResult(submission['worker_detail'])}
                    <hr/>
                    <h4>Illness Detail</h4>
                    {renderSystemFormResult(submission['illness_detail'])}
                    <hr/>
                    <h4>More Detail</h4>
                    {renderSystemFormResult(submission['more_detail'])}
                    <hr/>
                    <h4>For Manager</h4>
                    {renderSystemFormResult(submission['for_manager'])}
                  </>
                  :
                  <>{renderSystemFormResult(submission[props.formType])}</>
                }
                </Panel>
              )
            })
          }
        </Collapse>
        : 
        renderSystemFormResult(props.formData[props.formType])
      }
    </HazardFormWrapper>
  );
};

export default SurveyFormResult;
