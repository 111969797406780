import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';
import Modal from '@iso/components/Feedback/Modal';

const ConfigureModalSettingWrapper = styled(Modal)`

  .btn-success{
    width:50%;
    height:auto;
    background-color: #5e8c53;
    border-color: #5e8c53;
    &:hover{
      opacity: 0.8;
    }
  }
  label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    align-items: center;
}
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
  
 .group-icon{
    display: flex;
    p{
        margin-left: 5px;
    }
 }
 .ant-checkbox{
    bottom: 4px
 }
.weather-type{
    margin-bottom: 24px;
}
.normal-label{
    margin-bottom: 8px;
}
.ant-select-multiple{
    width: 100%;
}
.btn-success{
    background-color: #00B341;
}
.alert-cust{
    font-size : 14px !important;
}
input#deviceTime{
  height:30px !important;
}
.device_time{
  display:flex;

}
.device_time p{
  margin-right : 5px;
  padding-top : 5px;
}
`;

export default WithDirection(ConfigureModalSettingWrapper);