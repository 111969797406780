import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const AssetFilterWrapper = styled(Modal)`
  .ant-btn-primary{
    background-color: #0B2A1B!important;
  }
  .ant-checkbox-group-item{
    display: flex;
  }
  .option-container{
    label.title{
      font-weight: bold;
      margin-bottom: 8px;
      display: block
    }
    margin-bottom: 18px;
  }
  .ant-modal-body{
    padding: 20px ;

  }
  .btn-success{
    background-color: #0B2A1B;
    border-color: #0B2A1B;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
      color: #0B2A1B;
    }
  }
  .form-body{
    padding: 12px 24px 12px 24px;
    .checkbox-contact{
      margin-bottom: 10px;
    }
  }
`;

export default WithDirection(AssetFilterWrapper);