import React from "react";
import { Form, Input, Table } from "antd";
import { Table1Wrapper } from "./Table1Wrapper.style";

const Table1 = (props) => {
  const columns = [
    {
      title: "",
      dataIndex: "column_1",
      render: (text, record) => (
        <>
          <p className="title">{record.column_1.title}</p>
          <p className="sub-title">{record.column_1.sub_title}</p>
        </>
      ),
      rowScope: "row",
      onCell: (_, index) => {
        if (index === 6) {
          return { rowSpan: 2, className: "last-cell-column-1 border-bottom" };
        }
        // These two are merged into above cell
        if (index === 7) {
          return { rowSpan: 0 };
        }
        return {};
      },
      className: "table1_column1 primary-title",
    },
    {
      title: "",
      dataIndex: "column_2",
      onCell: (_, index) => ({
        colSpan: index === 6 || index === 7 ? 3 : 1,
        className:
          index === 6
            ? "border-right "
            : index === 7
            ? "border-right filled-primary border-bottom"
            : "",
      }),
      className: "table1_column2",
    },
    {
      title: "",
      dataIndex: "column_3",
      render: (text, record, index) => {
        if (index === 5) {
          return (
            <div className="emergency_hotline">
              <p>{record.column_3.title}</p>
              <p>{record.column_3.sub_title}</p>
            </div>
          );
        }
        return (
          <>
            <p className="title">{record.column_3.title}</p>
            <p className="sub-title">{record.column_3.sub_title}</p>
          </>
        );
      },
      onCell: (_, index) => {
        if (index === 5) {
          return { colSpan: 2, className: "border-right filled-primary" };
        }
        if (index === 6 || index === 7) {
          return { colSpan: 0 };
        }

        return {};
      },
      className: "table1_column3 primary-title",
      width: 150,
    },
    {
      title: "",
      dataIndex: "column_4",
      onCell: (_, index) => {
        if (index === 5 || index === 6 || index === 7) {
          return { colSpan: 0 };
        }

        return {};
      },
      className: "table1_column4",
    },
  ];

  const data = [
    {
      key: "1",
      column_1: {
        title: "Property name",
        sub_title: "",
      },
      column_2: (
        <Form.Item name="property_name" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
      column_3: {
        title: "Owner",
        sub_title: "",
      },
      column_4: (
        <Form.Item name="owner" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
    },
    {
      key: "2",
      column_1: {
        title: "Property address",
        sub_title: "",
      },
      column_2: (
        <Form.Item name="property_address" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
      column_3: {
        title: "Manager",
        sub_title: "",
      },
      column_4: (
        <Form.Item name="manager" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
    },
    {
      key: "3",
      column_1: {
        title: "PIC",
        sub_title: "",
      },
      column_2: (
        <Form.Item name="pic" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
      column_3: {
        title: "Veterinarian name",
        sub_title: "",
      },
      column_4: (
        <Form.Item name="veterinarian_name" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
    },
    {
      key: "4",
      column_1: {
        title: "Date",
        sub_title: "",
      },
      column_2: (
        <Form.Item name="date_bio" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
      column_3: {
        title: "Veterinarian phone number",
        sub_title: "",
      },
      column_4: (
        <Form.Item name="veterinarian_phone_number" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
    },
    {
      key: "5",
      column_1: {
        title: "Review date ",
        sub_title: "(12 months from date above)",
      },
      column_2: (
        <Form.Item name="review_date" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
      column_3: {
        title: "Local Animal Health Office number",
        sub_title: "(government)",
      },
      column_4: (
        <Form.Item
          name="local_animal_health_office_number"
          className="isoInputWrapper"
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      key: "6",
      column_1: {
        title: "Completed by ",
        sub_title: "(signature)",
      },
      column_2: (
        <Form.Item name="completed_by" className="isoInputWrapper">
          <Input />
        </Form.Item>
      ),
      column_3: {
        title: "Emergency Animal Disease hotline:",
        sub_title: "1800 675 888",
      },
      column_4: "",
    },
    {
      key: "7",
      column_1: {
        title: "Map",
        sub_title: "",
      },
      column_2: (
        <p>
          It is recommended to document any elements relating to biosecurity
          risks or management measures, for example, farm entry points, signage,
          clean down areas, carcase or household waste disposal areas, on a
          property map.{" "}
          <span className="quote">
            *You can complete and save property risk assessments and biosecurity
            plans online in your LPA account.
          </span>
        </p>
      ),
      column_3: {
        title: "",
        sub_title: "",
      },
      column_4: "",
    },
    {
      key: "8",
      column_1: {
        title: "",
        sub_title: "",
      },
      column_2: (
        <p>
          A map example and template are available in the{" "}
          <a href="/map">Property Risk Assessments</a>{" "}
          templates.
        </p>
      ),
      column_3: {
        title: "",
        sub_title: "",
      },
      column_4: "",
    },
  ];

  return (
    <Table1Wrapper>
      <div className="table-header">
        <p>
          KEEP THIS PLAN WITH YOUR OTHER FARM RECORDS AND MAKE IT AVAILABLE IF
          REQUESTED BY AN AUDITOR OR VISITOR.
        </p>
        <p>
          Completing this Farm Biosecurity Management Plan template will support
          producers to meet the biosecurity requirements of the Livestock
          Production Assurance (LPA) Program. If you have implemented a farm
          biosecurity plan for other purposes (e.g. J-BAS, SheepMAP) and it
          covers the elements listed within this template, you do not need to
          complete an additional farm biosecurity plan for LPA.{" "}
          <span>
            *You can complete and save property risk assessments and biosecurity
            plans online in your LPA account.
          </span>
        </p>
        <p>
          You should complete the template to reflect your current farm
          biosecurity practices. Where sections are not relevant for your
          property, you can select ‘Not applicable’. If the section is relevant
          but you do not currently have systems in place, select ‘No’ on the
          template. This will not affect your LPA accreditation but should be
          used to help you identify areas for improvement in order to reduce any
          potential biosecurity risks on your property. This template should be
          reviewed by the owner on an annual basis.
        </p>
        <p>
          Please check with your state or territory for any additional
          requirements that may be applicable to your biosecurity plan.
        </p>
      </div>
      <Table
        className="table1"
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        rowClassName={(record, index) => index === 7 && "last-row"}
      />
    </Table1Wrapper>
  );
};

export default Table1;
