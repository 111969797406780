import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";
import "gantt-task-react/dist/index.css";

const TaskManagePage = styled.div`
  width: 100%;
  height: 100%;
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    position: absolute;
    top: 2%;
    left: 40%;
  }
  .react-trello-lane {
    background: #ffffff;
    /* Primary/Light Grey */

    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    /* Shadow SM */

    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
  }

  header span {
    color: #f80e46;
  }
  .manage-property-title {
    color: #101828;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  .button-group {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .manage-property-header {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    margin: auto;
  }
  
  .manage-property-header > .mp-title {
    width: 100%;
  }
  .react-trello-card {
    background: rgb(242, 246, 246);
    box-shadow: rgb(239 222 92) 3px 0px 0px inset;
    border-radius: 8px;

    header span:first-child:before {
      content: "\u2661 ";
      font-size: 15px;
    }
  }

  .smooth-dnd-draggable-wrapper article {
    /* Light Gray */

    background: #f2f6f6;
    box-shadow: inset 3px 0px 0px #efde5c;
    border-radius: 8px;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
  }

  .margin-top-12 {
    margin-top: 12px;
  }

  .margin-bottom-12 {
    margin-bottom: 12px;
  }

  .ant-radio-group {
    &.btn-success {
      .ant-radio-button-wrapper {
        &:hover {
          color: #0B2A1B;
        }
      }
      .ant-radio-button-wrapper-checked {
        background-color: #0B2A1B;
        border-color: #0B2A1B;

        &:hover {
          color: #fff;
        }

        :not(.ant-radio-button-wrapper-disabled)::before {
          background-color: #0B2A1B;
        }
      }
    }
  }

  .new-task-block {
    margin-top: 10px;
    .ant-collapse-header-text {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 1.17em;
    }
  }

  .toggle-row {
    padding :0px 0px 15px 0px !important;
    // padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-new-task {
      width:142px;
      height: 40px;
      padding: 10px, 16px, 10px, 16px;
      border-radius: 8px;
      border: 1px;
      color :  white;
      background-color: #0B2A1B;
      &:hover {
        color: #0B2A1B;
        background-color: #ffffff;
      }
    }
    .task-title{
      color: rgb(16, 24, 40);
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    }
  }
  .task_box {
    padding: 5px 24px;
  }

  @media only screen and (max-width : 775px){
    .cust-todo-task .custom-task-board{
      overflow: scroll;
    }
    // .toggle-row{
    //   display: block;
    // }
    .btn-new-task{
      margin-top : 8px;
    }
  }
  @media only screen and (max-width : 640px){
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      position: relative;
      align-item : center;
      margin : 0px;
      top: 2%;
      left: 0%;
      .ant-tabs-nav-wrap{
        justify-content: center;
      }
    }
  }
`;

export default WithDirection(TaskManagePage);
