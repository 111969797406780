import styled from "styled-components";

export const Table1Wrapper = styled.div`
  .table1 thead {
    display: none;
  }
  a{
    color: black;
    text-decoration: underline
  }
  .table1 table {
    height: 100%;
  }
  .table1 table td {
    height: 100%;
  }
  .table1 .table1_column2 * {
    height: 100%;
  }
  .table1 .table1_column4 * {
    height: 100%;
  }
  .table1 .emergency_hotline {
    text-align: center;
    font-weight: bold;
  }
  .table1 .emergency_hotline p:last-child {
    font-size: 18px;
  }
  .table-header{
      p:first-child{
        font-weight: 500
      }
      p{
        margin-bottom: 10px
      }
      span{
        font-weight: 500;
        font-style: italic
      }
  }
`;
