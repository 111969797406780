import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector } from 'react-redux';
// import { configs } from '@iso/components/Tables/configs';
import { Row, Col, Button, Dropdown, Menu, Popconfirm, Select } from 'antd';
import ManageUserPage from './ManageUser.styles';
import Table from '@iso/components/collapsing-table/table';
import { EditOutlined, PlusOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { userService, propertyService } from '@iso/services';
import moment from 'moment';
import { subscriptionPlan } from '@iso/constants/subscriptionPlan';
import UserModal from './UserModal';
import _ from 'lodash';

const { Option } = Select;

const ManageUser = () => {
  const currentUser = useSelector((state) => state.user.user);
  const [users, setUsers] = useState([]);
  const [visibleUserModal, setVisibleUserModal] = useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [userData, setUserData] = useState({});
  const [properties, setProperties] = useState({});

  const getUsers = (propertyId) => {
    userService.getAllUsers(propertyId).then(res => {
      if(res.code === '0000'){
        setUsers(res.users);
      }
    });
  }
  useEffect(() => {    
    propertyService.getAllProperties().then(res => {
      if(res.code === '0000'){
        setProperties(res.properties);
      }
    }); 
  }, []);

  useEffect(() => {
      getUsers();
  }, []) 

  const menu = (user) => (
    <Menu onClick={handleMenuClick.bind(this, user)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
    </Menu>
  );

  const handleMenuClick = (user, e) => {
    if(e.key === 'editDetails'){
      setUserData(user);
      showUserModal();
    }
  }

  const confirmDelete = (del_product_category) => {
    // if(del_product_category.id){
    //    marketplaceCategoryService.destroy(del_product_category.id).then(res => {
    //     if(res.code === '0000'){
    //       getProductCategories();
    //     }
    //   });
    // }
  }

  const showUserModal = () => {
    setVisibleUserModal(true);
  }

  const cancelUserModal = () => {
    setVisibleUserModal(false);
    setUserData({});
  }

  const columns = [
    {
      label: <IntlMessages id="adminPage.manageUser.ID" />,
      accessor: 'id',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="adminPage.manageUser.name" />,
      accessor: 'name',
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="adminPage.manageUser.email" />,
      accessor: 'email',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3
    },
    {
      label: <IntlMessages id="adminPage.manageUser.emailVerifiedAt" />,
      accessor: 'email_verified_at',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let user = cell.row
        return moment(user.email_verified_at).format('DD/MM/YYYY HH:mm:ss');
      }
    },
    {
      label: <IntlMessages id="adminPage.manageUser.mobilePhone" />,
      accessor: 'mobile_phone',
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5
    },
    {
      label: <IntlMessages id="adminPage.manageUser.surname" />,
      accessor: 'surname',
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
    },
    {
      label: <IntlMessages id="adminPage.manageUser.dateOfBirth" />,
      accessor: 'date_of_birth',
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7,
      CustomComponent: cell => {
        let user = cell.row
        return user.date_of_birth ? moment(user.date_of_birth).format('DD/MM/YYYY') : 'N/A';
      }
    },
    {
      label: <IntlMessages id="adminPage.manageUser.homePhone" />,
      accessor: 'home_phone',
      minWidth: 100,
      sortable: true,
      position: 8,
      priorityLevel: 8,
    },
    {
      label: <IntlMessages id="adminPage.manageUser.address" />,
      accessor: 'address',
      minWidth: 100,
      sortable: true,
      position: 9,
      priorityLevel: 9,
    },
    {
      label: <IntlMessages id="manageYourProduct.managePage.createdAt" />,
      accessor: 'created_at',
      minWidth: 100,
      sortable: true,
      position: 10,
      priorityLevel: 10,
      CustomComponent: cell => {
        let user = cell.row
        return moment(user.created_at).format('DD/MM/YYYY');
      }
    },
    {
      label: <IntlMessages id="manageYourProduct.managePage.updatedAt" />,
      accessor: 'updated_at',
      minWidth: 100,
      sortable: true,
      position: 11,
      priorityLevel: 11,
      CustomComponent: cell => {
        let user = cell.row
        return moment(user.updated_at).format('DD/MM/YYYY');
      }
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 100,
      sortable: true,
      position: 12,
      priorityLevel: 12,
      noTitle: true,
      CustomComponent: cell => {
        let user = cell.row
        return (
            <Dropdown overlay={menu(user)} trigger={['click']}>
              <Button className="action-button">
                <IntlMessages id="antTable.title.actions" /> <DownOutlined />
              </Button>
            </Dropdown>
        );
      },
    },
  ];

  const onSaved = () => {
    setIsSaved(!isSaved);
  }

  const handleOptionClick = (value) => {
    getUsers(value);
  };

  const renderOptionsProperty = () => {
    let options = [];
    options.push(<Option key='all' value='all' className="property-option" >
          All
        </Option>)
    _.forEach(properties,(property,index) => {
      options.push(
        <Option key={index} value={property.id} className="property-option" >
          {property.name}
        </Option>
      );
    })

  return (
      <Select
        placeholder="Select Property"
        size="large"
        style={{ width: 150 }}
        onChange={handleOptionClick}
        defaultValue="all"
      >
        {options}
      </Select>
    );
  }

  return (
    <LayoutWrapper>
      <ManageUserPage>
        <PageHeader>
          {<IntlMessages id="adminPage.manageUser.header" />}
        </PageHeader>
        <div className="button-group">
          {renderOptionsProperty()}
        </div>
        <Box id="containerBox">
        {
          users.length > 0 &&
          <Table 
              columns={columns} 
              rows={users}
              containerId="containerBox"
              showPagination={true}
              rowSize={20}
            />
        }
        </Box>
        <UserModal visibleUserModal={visibleUserModal} cancelUserModal={cancelUserModal} 
        getUsers={getUsers} userData={userData}/>
      </ManageUserPage>
    </LayoutWrapper>
  );
}

export default ManageUser;
