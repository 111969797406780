import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";

export const agentService = {
  get,
  contactAgent,
  storeOrUpdate,
  destroy,
  getActiveAgents
};

function get() {
  return axios
    .get(apiEndpoint.agents, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      }
    });
}

function getActiveAgents() {
  return axios
    .get(`${apiEndpoint.agents}/get-active-agents`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      }
    });
}

function storeOrUpdate(data, id = null){
  return (id ? (
      axios
        .put(
            `${apiEndpointFunction.agents(id)}`,
          data,
          {
            headers: authHeader(),
          }
        )
  ) : (
      axios
        .post(
          apiEndpoint.agents,
          data,
          {
            headers: authHeader()
          }
        )
  )).then(res => {
      if(res && res.status === 200){
        return res.data;
      }
      return {};
  });
}

function contactAgent(data) {
  return axios
    .post(`${apiEndpoint.agents}/contact-agent`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      }
      return [];
    });
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.agents(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}
