import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import _ from 'lodash';
export const subscriptionService = {
  getList,
  storeOrUpdate,
  destroy,
  getStripeData,
  createTrialGoldSubscription
};

function getList(){
  let prices = {
    REACT_APP_STRIPE_PLAN_BRONZE:  process.env.REACT_APP_STRIPE_PLAN_BRONZE,
    REACT_APP_STRIPE_PLAN_SILVER: process.env.REACT_APP_STRIPE_PLAN_SILVER,
    REACT_APP_STRIPE_PLAN_SILVER_ANNUAL: process.env.REACT_APP_STRIPE_PLAN_SILVER_ANNUAL,
    REACT_APP_STRIPE_PLAN_GOLD: process.env.REACT_APP_STRIPE_PLAN_GOLD,
    REACT_APP_STRIPE_PLAN_GOLD_ANNUAL: process.env.REACT_APP_STRIPE_PLAN_GOLD_ANNUAL,
    REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD,
    REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD_YEARLY: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD_YEARLY,
    REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD,
    REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD_YEARLY: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD_YEARLY,
    REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD,
    REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD_YEARLY: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD_YEARLY,
  }
  return _.map([
    'REACT_APP_STRIPE_PLAN_BRONZE',
    'REACT_APP_STRIPE_PLAN_SILVER','REACT_APP_STRIPE_PLAN_SILVER_ANNUAL',
    'REACT_APP_STRIPE_PLAN_GOLD',
    'REACT_APP_STRIPE_PLAN_GOLD_ANNUAL',
    'REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD',
    'REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD_YEARLY',
    'REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD',
    'REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD_YEARLY',
    'REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD',
    'REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD_YEARLY',
  ], (plan) => {
    return {
      key: plan,
      price: prices[plan],
    }
  });
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.subscriptions(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.subscriptions,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function createTrialGoldSubscription(data){
  return axios.post(
    apiEndpoint.subscriptions + '/create-trial-gold-subscription',
    data,
    {
      headers: authHeader()
    }
  ).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.subscriptions(id),
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}
function getStripeData(id){
  return (
    axios
      .get(
        `${apiEndpointFunction.subscriptions(id)}/get-stripe-data`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}