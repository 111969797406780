import React, { useState } from "react";
import { Button, message, Row, Col } from "antd";
import logo from "@iso/assets/images/logo.png";
import { propertyService } from "@iso/services";
import { Link } from "react-router-dom";

const LeftSection = (props) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    propertyService.requestFreeSetup(props.propertyId).then((res) => {
      if (res.code === "0000") {
        message.success(res.message);
      } else {
        message.success(res.error);
      }
      setLoading(false);
    });
  };

  return (
    <>
      <img className="small-logo" src={logo} />
      <h1 className="text-title">
        Get ready to enter your new property on AirAgri
      </h1>
      <div className="description">
        <p>
          Book a free onboarding call with one of our amazing team (Button just
          sends request) no additional data required
        </p>
        <p>Mobile app - Links / buttons for each app store listing</p>
        <p>Learn more about our PLD’s</p>
        <p>Get Started - Take me to my property map</p>
      </div>
      <Row className="button-group" gutter={10}>
        <Col xs={{span: 24}} md={{span:8}} lg={{span: 8}}>
          <Link to="/map">
            <Button className="submit-btn" type="primary" size="large">
              Go Property Map
            </Button>
          </Link>
        </Col>
        <Col xs={{span: 24}} md={{span:8}} lg={{span: 8}}>
          <Button
            loading={loading}
            className="outline-btn"
            size="large"
            onClick={handleSubmit}
          >
            {loading ? "Request Free..." : "Request Free Setup Service"}
          </Button>
        </Col>
        <Col xs={{span: 24}} md={{span:8}} lg={{span: 8}}>
          <Button
            className="outline-btn"
            id="trial-subscription-btn"
            size="large"
            onClick={() => props.setStep("trial_subscription")}
          >
            Try Gold for free
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default LeftSection;
