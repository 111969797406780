import styled from 'styled-components';
import pencil from '@iso/assets/images/icon/pencil.svg';

export const FormBuilderWrapper = styled.div`
    .message {
        padding: 30px 0;
        min-height: 900px;
    }
    
    .form-builder-toolbox {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        filter: drop-shadow(rgba(0, 0, 0, 0.16) 0px 3px 6px);
        padding: 20px 40px;
        ul {
            list-style: none;
            li {
                margin-bottom: 15px;
            }
        }
        button {
            border-radius: 0;
            width: 100%;
            text-align: left;
        }
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 25px;
            color: #292929;
            margin-bottom: 15px;
        }
    }
    .form-header {
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 25px;
            color: #292929;
            margin-right: 10px;
        }
        .header-content {
            display: flex;
        }
    }
    .form-builder {
        .ant-form-item {
            position: relative;
            cursor: pointer;
            &:after{
                position: absolute;
                right: 0;
                content: url(${pencil});
                font-weight: bold;
                top: 5px;
            }
            &:hover {
                background: #e3e1e1;
            }
        }
    }

    .float-right {
        float: right!important;
    }
`;