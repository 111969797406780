import React, { useState } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Form, Input, Button, message } from "antd";
import "react-tagsinput/react-tagsinput.css";
import ContactAgentModallWrapper from "./ContactAgentModal.styles";
import { agentService } from '@iso/services';
import { useSelector } from 'react-redux';

const ContactAgentModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const activeAgent = useSelector((state) => state.property.agent);
  const agentName = activeAgent ? activeAgent.name : ''
  const agentNumber = activeAgent ? activeAgent.id : ''

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        onStore(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };

  const onStore = (values) => {
    values.agent_code = activeAgent.id
    agentService.contactAgent(values).then((res)=>{
      if(res.code === '0000'){
        message.success(res.message)
      }else{
        message.error(res.message)
      }
      setLoading(false);
      cancelModal()
    })
  };

  const cancelModal = () => {
    props.cancelModal();
    form.resetFields();
  };

  return (
    <>
      <ContactAgentModallWrapper agent={activeAgent} className={`contact-agent-modal`}
        visible={props.isShowContactAgentModal}
        onCancel={cancelModal}
        maskClosable={false}
        title={<p><span className="modal-title"><IntlMessages id="topbar.contactAgent" /></span></p>}
        footer={[
          <Button key="back" onClick={cancelModal} className={`cancel-button`}>
            {<IntlMessages id="propertyPage.modal.cancel" />}
          </Button>,
          <Button
            key="submit"
            className={`btn-success`}
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            {<IntlMessages id="topbar.contactAgent.modal.send" />}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
          id="#1"
        >
          <div className={`form-body`}>
            <Form.Item
              className="isoInputWrapper"
              label="Agent Name"
            >
              <Input size="large" value={agentName} disabled={true} />
            </Form.Item>
            <Form.Item
              className="isoInputWrapper"
              label="Agent Number"
            >
              <Input size="large" value={agentNumber} disabled={true} />
            </Form.Item>
            <Form.Item
              name={["subject"]}
              className="isoInputWrapper"
              label="Subject Line"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                  whitespace: true,
                },
                {
                  max: 255,
                  message: "Subject Line must be maximum 255 characters.",
                },
              ]}
            >
              <Input size="large" placeholder="Input Subject Line" />
            </Form.Item>
            <Form.Item
              name={["message"]}
              className="isoInputWrapper"
              label="Message"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                  whitespace: true,
                },
                {
                  max: 1000,
                  message: "Message must be maximum 1000 characters.",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </div>
        </Form>
      </ContactAgentModallWrapper>
    </>
  );
};

export default ContactAgentModal;
