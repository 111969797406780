import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const SAREditModalWrapper = styled(Modal)`
  @media(min-width:1440px){
    width: 740px !important;
  }
  .ant-modal-body {
    padding: 0;
  }
  .btn-success {
    background-color: #0B2A1B;
    border-color: #0B2A1B;
    margin-left: 10px;
  }

  .form-title {
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3 {
      font-size: 1rem;
      color: #0B2A1B;
    }
  }
  .form-body {
    padding: 12px 24px 12px 24px;
    .checkbox-contact {
      margin-bottom: 10px;
    }
  }
  .footer-btn {
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
  }
  .checkboxContacts .ant-checkbox-wrapper{
    display:flex;
    width: 48%;
  }
  .checkboxContacts{
    height: 150px;
    overflow: auto;
    display: flex;
    gap: 10px;
    flex-wrap:wrap;
  }
  .ant-modal-footer{
    border:0;
  }

`;

export default WithDirection(SAREditModalWrapper);
