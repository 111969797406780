import React, { useState, useEffect, useRef } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Card,
  Timeline,
  Upload,
  message,
} from "antd";
import _ from "lodash";
import AssetSummarymodal from "../Asset/AssetViewModal.styles";
import moment from "moment";

const { Option } = Select;

const LiveStockSummary = (props) => {
  const { liveStockData } = props;

  const cancelModal = () => {
    props.setVisibleViewSummary(false);
  };

  return (
    <AssetSummarymodal
      className="visitor-modal"
      forceRender={true}
      open={props.visibleViewSummary}
      title={<IntlMessages id="LiveStock Details" />}
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
      ]}
    >
      <div
        style={{
          padding: "0px 30px 30px 30px",
          width: "100%",
          height: "auto",
        }}
      >
        <br />

        <Timeline>
          <p>{moment(props.liveStockData.created_at).format("ll")}</p>
          <Card
            title={`Asset `}
            bordered={true}
            style={{ borderRadius: "8px" }}
          >
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">LiveStock Id</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.id ? props.liveStockData.id : "-"}
                </b>
              </Col>
            </Row>
            {props.liveStockData.type ? (
              <Row>
                <Col xs={{ span: 9 }} md={{ span: 9 }}>
                  <p className="card_content">LiveStock Type</p>
                </Col>
                <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                  <b className="card_value">
                    {props.liveStockData.type ? props.liveStockData.type : "-"}
                  </b>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Tag Number Range</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.tag_number_range
                    ? props.liveStockData.tag_number_range
                    : "-"}
                </b>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Breed</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.breed ? props.liveStockData.breed : "-"}
                </b>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Color</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.color ? props.liveStockData.color : "-"}
                </b>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Primary Object</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.primary_object
                    ? props.liveStockData.primary_object
                    : "-"}
                </b>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Sex Name</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.sex_name
                    ? props.liveStockData.sex_name
                    : "-"}
                </b>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Tag Colour</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.tag_colour
                    ? props.liveStockData.tag_colour
                    : "-"}
                </b>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Uid</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.uid ? props.liveStockData.uid : "-"}
                </b>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Year Of Birth</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.year_of_birth
                    ? props.liveStockData.year_of_birth
                    : "-"}
                </b>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Updated Date</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.updated_at
                    ? props.liveStockData.updated_at
                    : "-"}
                </b>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 9 }}>
                <p className="card_content">Last Activity</p>
              </Col>
              <Col xs={{ span: 13, offset: 2 }} md={{ span: 13, offset: 2 }}>
                <b className="card_value">
                  {props.liveStockData.last_activity
                    ? props.liveStockData.last_activity
                    : "-"}
                </b>
              </Col>
            </Row>
          </Card>
        </Timeline>
      </div>
    </AssetSummarymodal>
  );
};

export default LiveStockSummary;