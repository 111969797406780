
import React, { useState, useEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import ProfilePage from "./Profile.styles";
import { Button,  Card } from "antd";
import _ from "lodash";
import Personal from "./Personal";
import Licences from "./Licences";
import Qualifications from "./Qualifications";
import Emergency from "./Emergency";

import ProfileModal from './Modal.styles';
import WorkingRight from "./WorkingRight";
// import MentalHealthChecking from "./MentalHealthChecking";

const Profile = (props) => {
  const [count, setCount] = useState(1);

  const handleInfo = () => {
    setCount(1);
  }
  const handleLicences = () => {
    setCount(2);
  }
  
  const handleQualifications = () => {
    setCount(3)
  }

  const handleEmergency = () => {
    setCount(4)
  }
  const handleWokringRight = () => {
    setCount(5)
  }
const handleMentalHealthChecking = () => {
  setCount(6)
}
  
  return (

    <LayoutWrapper>

     
      <ProfilePage>
        <Card className="cutmCard">
          <div className="MainDivCard">
            <div className="cardImg" >

              <img src={process.env.PUBLIC_URL + "./images/icons/progress.png"} alt="loader"></img>
            </div>
            <div style={{ marginLeft: '5px' }} className="content">
            
              <span className="inforTitle">
                Complete profile
              </span>
              <div className="cardDetails">
                Complete your profile to unlock all features
              </div>
            </div>
          </div>
          <div style={{width:'100%'}} >
            <Button className="verifyBtn">
              Verify Identity
            </Button></div>

        </Card>
        <div className="ButtonGroup" >
          <a className={` ${count === 1 ? 'selected' : 'btn'}`} onClick={handleInfo}>Account Information</a>
          <a className={` ${count === 2 ? 'selected' : 'btn'}`} onClick={handleLicences}>Licences</a>
          <a className={` ${count === 3 ? 'selected' : 'btn'}`} onClick={handleQualifications}>Qualifications</a>
          <a className={` ${count === 4 ? 'selected' : 'btn'}`} onClick={handleEmergency}> Emergency</a>
          <a className={` ${count === 5 ? 'selected' : 'btn'}`} onClick={handleWokringRight}> Working Right</a>
          {/* <a className={` ${count === 6 ? 'selected' : 'btn'}`} onClick={handleMentalHealthChecking}>Mental Health Checking</a> */}
        </div>
      </ProfilePage>

      <ProfileModal>
        {count === 2 ? <Licences formId={props.formId} userId={props.userId} /> : ''}
        {count === 1 ? <Personal formId={props.formId} userId={props.userId}/> : ''}
        {count === 3 ? <Qualifications formId={props.formId} userId={props.userId} /> : ''}
        {count === 4 ? <Emergency formId={props.formId} userId={props.userId}/> : ''}
        {count === 5 ? <WorkingRight formId={props.formId} userId={props.userId} /> : ''}
        {/* {count === 6 ? <MentalHealthChecking formId={props.formId} userId={props.userId} /> : ''} */}
      </ProfileModal>

    </LayoutWrapper>

  );
};

export default Profile;

