import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown,Menu ,Popconfirm} from "antd";
import { PlusOutlined ,EllipsisOutlined} from "@ant-design/icons";
import { workingRightService } from "@iso/services";
import userActions from "@iso/redux/user/actions";
import IntlMessages from "@iso/components/utility/intlMessages";
import WorkingRightWrapper from "./WorkingRight.style";
import Table from "@iso/components/collapsing-table/table";
import Box from "@iso/components/utility/box";
import WorkingRightModal from "./WorkingRightModal";
import _ from "lodash";
import moment from "moment";

const { store } = userActions;

const WorkingRight = (props) => {
  const [workingRights, setWorkingRights] = useState([]);
  const [workingRightData, setWorkingRightData] = useState({});
  const currentUser = useSelector((state) => state.user.user);
  const [viewSummary,setViewSummary]= useState(false);
  const [title,setTitle] = useState("Add Attachment")
  const [visibleWorkingRightModal, setVisibleWorkingRightModal] =
    React.useState(false);
  const user_id = props.userId || "";
  const form_id = props.formId || "";
  const isDisableForm = user_id && form_id ? true : false;

  useEffect(() => {
    if(props.tabIndex == props.activeTabIndex){
      updateWorkingRightData();
    }
  }, [currentUser.id, user_id, form_id, props.activeTabIndex]);

  const updateWorkingRightData = () => {
    if (user_id && form_id) {
      workingRightService
        .getCheckinFormUserWorkingRight(form_id, user_id)
        .then((res) => {
          if (res.code === "0000") {
            setWorkingRights(res.working_rights);
          }
        });
    } else if (currentUser) {
      workingRightService.getList().then((res) => {
        if (res.code === "0000") {
          setWorkingRights(res.working_rights);
        }
      });
    }
  };
  const menuList = (working_right, permission) => (
    <Menu onClick={handleMenuClick.bind(this, working_right)}  className='property-area-dot-btn'>
      <Menu.Item key="editDetails" icon={ <img src={process.env.PUBLIC_URL +"./images/icons/edit.png"}/>} className='property-area-dot-btn'>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <Menu.Item key="viewDetails" icon={<img src={process.env.PUBLIC_URL +"./images/icons/eye.png"}/>}>
        <IntlMessages id="View Details" />
      </Menu.Item>
      {/* <Menu.Item key="Remove" icon={<DeleteOutlined />}>
        <IntlMessages id="Remove" />
      </Menu.Item> */}

      <p style={{ cursor: "pointer" }} className="deleteButton">
       <Popconfirm
         placement="bottomRight"
         title="You are about to delete individual record"
         onConfirm={() => {
           confirmDelete(working_right);
         }}
         okText="Yes"
         cancelText="No"
         className="delete-button"
       >
         <a style={{fontFamily: "Sora",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "32px",
  letterSpacing: "0.75px",
 paddingLeft:'11px',
  color: "red",}}>
          <img src={process.env.PUBLIC_URL +"./images/icons/delete.png"}/>&nbsp;&nbsp;
           <IntlMessages id="Remove" />
         </a>
       </Popconfirm>
     </p>
    </Menu>
  );
  const confirmDelete = (working_right) => {
    if (working_right.id) {
      workingRightService.destroy(working_right.id).then((res) => {
        if (res.code === "0000") {
          updateWorkingRightData();
        }
      });
    }
  };
  const handleMenuClick = (working_right, e) => {


    if (e.key === "viewDetails") {

      setViewSummary(true);
      setWorkingRightData(working_right);
      setTitle('Attachment')
    }
    if (e.key === "editDetails") {
      showEditModal(working_right)

    }
  }

  const showModal = () => {
    setVisibleWorkingRightModal(true);
  };

  const showEditModal = (licence) => {
    setWorkingRightData(licence);
    setVisibleWorkingRightModal(true);
    setTitle("Edit Attachment")
  };

  const cancelModal = () => {
    setVisibleWorkingRightModal(false);
    setWorkingRightData({});
    setTitle("Add Attachment")
  };

  const onSaved = () => {
    updateWorkingRightData();
  };

  const columns = [
    {
      label: <IntlMessages id="profilePage.workingRightTab.table.type" />,
      accessor: "type_label",
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: <IntlMessages id="profilePage.workingRightTab.table.name" />,
      accessor: "name",
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="profilePage.workingRightTab.table.createdAt" />,
      accessor: "created_at",
      minWidth: 200,
      sortable: false,
      position: 3,
      priorityLevel: 3,
      CustomComponent: (cell) => {
        let working_right = cell.row;
        return working_right.created_at
          ? moment(working_right.created_at).format("L")
          : "";
      },
    },
    {
      label: (
        <IntlMessages id="profilePage.workingRightTab.table.lastEditedAt" />
      ),
      accessor: "updated_at",
      minWidth: 200,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      CustomComponent: (cell) => {
        let working_right = cell.row;
        return working_right.updated_at
          ? moment(working_right.updated_at).format("L")
          : "";
      },
    },
    {
      label: "",
      accessor: "edit_detail",
      minWidth: 100,
      sortable: false,
      position: 5,
      priorityLevel: 5,
      noTitle: true,
      CustomComponent: (cell) => {
        let working_right = cell.row;
        return (
          <Dropdown overlay={menuList(working_right)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <WorkingRightWrapper   style={{ padding: '15px' }}>
        <div className="form-title">
          <h2>{<IntlMessages id="profilePage.workingRightTab.title" />}</h2>
          {!isDisableForm && (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              className="AddWorkingRights"
              onClick={showModal}
            >
              <IntlMessages id="profilePage.workingRightTab.addWorkingRight" />
            </Button>
          )}
        </div>
        <Box id="containerBoxWorkingRight" className="tableCust" style={{ border: 'none' }}>
          {workingRights.length > 0 ? (
            <Table
              columns={columns}
              rows={workingRights}
              containerId="containerBoxWorkingRight"
              showPagination={false}
              rowSize={99999}
            />
          ) : (
            <h1>
              <IntlMessages id="dashboardPage.noData" />
            </h1>
          )}
        </Box>
        <WorkingRightModal
          visibleWorkingRightModal={visibleWorkingRightModal}
          cancelModal={cancelModal}
          onSaved={onSaved}
          workingRightData={workingRightData}
          isDisableForm={isDisableForm}
          viewSummary= {viewSummary}
          setViewSummary = {setViewSummary}
          title= {title}
          setTitle = {setTitle}
        />
      </WorkingRightWrapper>
    </>
  );
};

export default WorkingRight;
