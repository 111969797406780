import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Checkbox,
  DatePicker,
  Dropdown,
  Menu,
  Popconfirm
} from "antd";
import { PlusOutlined,EllipsisOutlined } from "@ant-design/icons";
import { emergencyService } from "@iso/services";
import userActions from "@iso/redux/user/actions";
import IntlMessages from "@iso/components/utility/intlMessages";
import EmergencyWrapper from "./Emergency.styles";
import Table from "@iso/components/collapsing-table/table";
import Box from "@iso/components/utility/box";
import EmergencyModal from "./EmergencyModal";
import _ from "lodash";
import moment from "moment";

const { store } = userActions;

const Emergency = (props) => {
  const [emergencyContact, setEmergencyContact] = useState([]);
  const [emergencyExpiryTetanus, setEmergencyExpiryTetanus] = useState([]);
  const [emergencyOther, setEmergencyOther] = useState([]);
  const [emergencyNotes, setEmergencyNotes] = useState([]);
  const [emergencyData, setEmergencyData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [visibleEmergency, setVisibilityEmergency] = React.useState(false);
  const [editEmergency, setEditEmergency] = useState(false);
  const [viewSummary,setViewSummary] = useState(false);
  const [title,setTitle] = useState("Add Emergency")
  const user_id = props.userId || '';
  const form_id = props.formId || '';
  const isDisableForm = user_id && form_id ? true : false

  useEffect(() => {
    if(props.tabIndex == props.activeTabIndex){
      updateEmergencyData();
    }
  }, [currentUser.id, user_id, form_id, props.activeTabIndex]);

  const updateEmergencyData = () => {
    if (form_id && user_id) {
      emergencyService
        .getCheckinFormUserEmergency(form_id, user_id, "emergency_contact")
        .then((res) => {
          if (res.code === "0000") {
            setEmergencyContact(res.emergencies);
          }
        });
      emergencyService
        .getCheckinFormUserEmergency(form_id, user_id, "expiry_of_tetanus")
        .then((res) => {
          if (res.code === "0000") {
            setEmergencyExpiryTetanus(res.emergencies);
          }
        });
      emergencyService
        .getCheckinFormUserEmergency(form_id, user_id, "other")
        .then((res) => {
          if (res.code === "0000") {
            setEmergencyOther(res.emergencies);
          }
        });
      emergencyService
        .getCheckinFormUserEmergency(form_id, user_id, "notes")
        .then((res) => {
          if (res.code === "0000") {
            setEmergencyNotes(res.emergencies);
          }
        });
    } else if (currentUser) {
      emergencyService
        .getList(currentUser.id, "emergency_contact")
        .then((res) => {
          if (res.code === "0000") {
            setEmergencyContact(res.emergencies);
          }
        });
      emergencyService
        .getList(currentUser.id, "expiry_of_tetanus")
        .then((res) => {
          if (res.code === "0000") {
            setEmergencyExpiryTetanus(res.emergencies);
          }
        });
      emergencyService.getList(currentUser.id, "other").then((res) => {
        if (res.code === "0000") {
          setEmergencyOther(res.emergencies);
        }
      });
      emergencyService.getList(currentUser.id, "notes").then((res) => {
        if (res.code === "0000") {
          setEmergencyNotes(res.emergencies);
        }
      });
    }
  };
  const menuList = (emergency, permission) => (
    <Menu onClick={handleMenuClick.bind(this, emergency)}  className='property-area-dot-btn'>
      <Menu.Item key="editDetails" icon={ <img src={process.env.PUBLIC_URL +"./images/icons/edit.png"}/>} className='property-area-dot-btn'>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <Menu.Item key="viewDetails" icon={<img src={process.env.PUBLIC_URL +"./images/icons/eye.png"}/>}>
        <IntlMessages id="View Details" />
      </Menu.Item>
      {/* <Menu.Item key="Remove" icon={<DeleteOutlined />}>
        <IntlMessages id="Remove" />
      </Menu.Item> */}

      <p style={{ cursor: "pointer" }} className="deleteButton">
       <Popconfirm
         placement="bottomRight"
         title="You are about to delete individual record"
         onConfirm={() => {
           confirmDelete(emergency);
         }}
         okText="Yes"
         cancelText="No"
         className="delete-button"
       >
         <a className="dltItem" style={{
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "32px",
  letterSpacing: "0.75px",
 paddingLeft:'11px',
  color: "red",}}>
          <img src={process.env.PUBLIC_URL +"./images/icons/delete.png"}/>&nbsp;&nbsp;
           <IntlMessages id="Remove" />
         </a>
       </Popconfirm>
     </p>
    </Menu>
  );
  const confirmDelete = (emergency) => {
    if (emergency.id) {
      emergencyService.destroy(emergency.id).then((res) => {
        if (res.code === "0000") {
          updateEmergencyData();
        }
      });
    }
  };
  const handleMenuClick = (emergency, e) => {


    if (e.key === "viewDetails") {
     
      setViewSummary(true);
      setEmergencyData(emergency);
      setTitle('Qualifications')
    }
    if (e.key === "editDetails") {
     
      showEditModal(emergency)

    }
  }
  const showModal = () => {
    setVisibilityEmergency(true);
  };

  const showEditModal = (emergency) => {
    setEditEmergency(true);
    setEmergencyData(emergency);
    setVisibilityEmergency(true);
    setTitle("Edit Emergency")
  };

  const cancelModal = () => {
    setVisibilityEmergency(false);
    setEditEmergency(false);
    setEmergencyData({});
    setTitle("Add Emergency")
  };

  const onSaved = () => {
    updateEmergencyData();
  };

  const columns_emergency_contact = [
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.fullName" />,
      accessor: "full_name",
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1,
      isVisible: true,
    },
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.email" />,
      accessor: "email",
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      isVisible: true,
    },
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.relationship" />,
      accessor: "relationship",
      minWidth: 100,
      sortable: false,
      position: 3,
      priorityLevel: 3,
      isVisible: true,
    },
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.phoneNumber" />,
      accessor: "phone_number",
      minWidth: 100,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      isVisible: true,
    },
    {
      label: "",
      accessor: "edit_detail",
      minWidth: 100,
      sortable: false,
      position: 5,
      priorityLevel: 5,
      noTitle: true,
      isVisible: true,
      CustomComponent: (cell) => {
        let emergency = cell.row;
        return (
          <Dropdown overlay={menuList(emergency)} trigger={["click"]}>
          <div
            className="property-area-dot-btn"
            style={{ cursor: "pointer" }}
          >
            <EllipsisOutlined className="dotIcon"/>
          </div>
        </Dropdown>
        );
      },
    },
  ];
  const columns_tetanus_expiry = [
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.tetanusExpiry" />,
      accessor: "expiry_of_tetanus",
      minWidth: 200,
      sortable: false,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let emergency = cell.row;
        return emergency.expiry_of_tetanus
          ? moment(emergency.expiry_of_tetanus).format("L")
          : "";
      },
    },
    {
      label: "",
      accessor: "edit_detail",
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      noTitle: true,
      CustomComponent: (cell) => {
        let emergency = cell.row;
        return (
          <Button
            className="action-button view-history-button"
            onClick={() => {
              showEditModal(emergency);
            }}
          >
            <IntlMessages id="propertyPage.managePage.editDetails" />
          </Button>
        );
      },
    },
  ];

  const columns_other = [
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.type" />,
      accessor: "other_type",
      minWidth: 200,
      sortable: false,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.location" />,
      accessor: "location",
      minWidth: 200,
      sortable: false,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.applyDate" />,
      accessor: "apply_date",
      minWidth: 200,
      sortable: false,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: "",
      accessor: "edit_detail",
      minWidth: 100,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let emergency = cell.row;
        return (
          <Button
            className="action-button view-history-button"
            onClick={() => {
              showEditModal(emergency);
            }}
          >
            <IntlMessages id="propertyPage.managePage.editDetails" />
          </Button>
        );
      },
    },
  ];

  const columns_notes = [
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.notes" />,
      accessor: "note",
      minWidth: 200,
      sortable: false,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: "",
      accessor: "edit_detail",
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      noTitle: true,
      CustomComponent: (cell) => {
        let emergency = cell.row;
        return (
          <Button
            className="action-button view-history-button"
            onClick={() => {
              showEditModal(emergency);
            }}
          >
            <IntlMessages id="propertyPage.managePage.editDetails" />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <EmergencyWrapper  style={{ padding: '15px' }} >
        <div className="form-title">
          <h2>{<IntlMessages id="profilePage.managePage.emergencyTab" />}</h2>
          {!isDisableForm && (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              className="AddEmergency"
              onClick={showModal}
            >
              <IntlMessages id="profilePage.emergencyTab.addEmergency" />
            </Button>
          )}
        </div>
        <div className="form-title">
          <h3>{<IntlMessages id="profilePage.emergencyTab.type.contact" />}</h3>
        </div>
        <Box id="containerBoxEmergencyContact" className="tableCust" style={{ border: 'none' }}>
          {emergencyContact.length > 0 ? (
            <Table 
              columns={columns_emergency_contact} 
              rows={emergencyContact}
              containerId="containerBoxEmergencyContact"
              showPagination={false}
              rowSize={99999}
            />
          ) : (
            <h1>
              <IntlMessages id="dashboardPage.noData" />
            </h1>
          )}
        </Box>
        <div className="form-title">
          <h3>
            {<IntlMessages id="profilePage.emergencyTab.type.tetanusExpiry" />}
          </h3>
        </div>
        <Box id="containerBoxExpiryTetanus" className="tableCust" style={{ border: 'none' }}>
          {emergencyExpiryTetanus.length > 0 ? (
            <Table
              columns={columns_tetanus_expiry}
              rows={emergencyExpiryTetanus}
              containerId="containerBoxExpiryTetanus"
              showPagination={false}
              rowSize={99999}
            />
          ) : (
            <h1>
              <IntlMessages id="dashboardPage.noData" />
            </h1>
          )}
        </Box>
        <div className="form-title">
          <h3>{<IntlMessages id="profilePage.emergencyTab.type.Other" />}</h3>
        </div>
        <Box id="containerBoxEmergencyOther" className="tableCust" style={{ border: 'none' }}>
          {emergencyOther.length > 0 ? (
            <Table
              columns={columns_other}
              rows={emergencyOther}
              containerId="containerBoxEmergencyOther"
              showPagination={false}
              rowSize={99999}
            />
          ) : (
            <h1>
              <IntlMessages id="dashboardPage.noData" />
            </h1>
          )}
        </Box>
        <div className="form-title">
          <h3>{<IntlMessages id="profilePage.emergencyTab.type.Notes" />}</h3>
        </div>
        <Box id="containerBox7" className="tableCust" style={{ border: 'none' }}>
          {emergencyNotes.length > 0 && (
            <Table
              columns={columns_notes}
              rows={emergencyNotes}
              containerId="containerBox7"
              showPagination={false}
              rowSize={99999}
            />
          )}
        </Box>
        <EmergencyModal
          visibleEmergency={visibleEmergency}
          cancelModal={cancelModal}
          onSaved={onSaved}
          editEmergency={editEmergency}
          emergencyData={emergencyData}
          isDisableForm={isDisableForm}
          title= {title}
          setTitle = {setTitle}
          viewSummary = {viewSummary}
          setViewSummary = {setViewSummary}
        />
      </EmergencyWrapper>
    </>
  );
};

export default Emergency;
