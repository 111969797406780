import React from 'react';
import LoaderComponent from './loader.style';
import { Space,Spin  } from 'antd'

export default Loader => (
  <>

  <LoaderComponent>
  <Space size="middle" className='loader'>
       <Spin size="large" />
     </Space>
  </LoaderComponent>
  </>
);