import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  propertyService,
  dashboardService,
} from "@iso/services";

import { Row, Col, Popover, Select, Divider, Card } from "antd";
import moment, { min } from "moment";
import styled from "styled-components";
import WeatherMap from "@iso/containers/Pages/WeatherMap/WeatherMap";
import PropertyMap from "@iso/containers/Pages/PropertyMap/PropertyMap";
import { weatherDataService } from "@iso/services";
import { useHistory } from "react-router";
import appActions from "@iso/redux/app/actions";
import modalActions from "@iso/redux/modal/actions";
import "./Dashboard.style.css";

const { openModal } = modalActions;
const BlankPage = () => {
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const [minTemparature, setMinTemparature] = useState();
  const [maxTemparature, setMaxTemparature] = useState();
  const [precipitationIntensity, setPrecipitationIntensity] = useState();
  const [weatherCode, setWeatherCode] = useState();
  const [visibleVisitorModal, setVisibleVisitorModal] = React.useState(false);
 
  const [statistic, setStatistic] = useState({
    number_of_active_areas:0,
    number_of_inactive_areas:0,

    number_of_active_forms:0,
    number_of_form_responses:0,

    number_of_none_safety_assets:0,
    number_of_safety_assets:0,

    number_of_areas: 0,
    number_of_assets: 0,
    number_of_forms: 0,
    sunrise: "",
    sunset: "",
  });

  const [forms, setForms] = React.useState([]);
  const [visitors, setVisitors] = React.useState(0);
  const [visibleFormModal, setVisiblityFormModal] = React.useState(false);

 
  const dispatch = useDispatch();

  const addForms = () => {
    setVisiblityFormModal(true);
  };
  const showAddAssetModal = () => {
    const modalData = {
      type: "asset",
      canCreate: true,
    };
    dispatch(openModal(modalData));
  };
  
  const addVisitors = () => {
    setVisibleVisitorModal(true);
  };
  const dashboardUpperCard = [
    {
      id: 1,
      name: "Areas",
      // icon : <img src={process.env.PUBLIC_URL + "./images/icons/UpperIcon.svg"} height ='24px' width ='24px' className="arrowImg" alt="sdfsdf"></img>,
      icon: process.env.PUBLIC_URL + "./images/icons/upperCard.svg",
      count:parseInt(statistic.number_of_active_areas) + parseInt(statistic.number_of_inactive_areas),
      render: () => {
        return (
          <div className="sun-info">
              
            <p>Inactive: <span>{statistic.number_of_active_areas}</span></p>
            <p>Active: <span>{statistic.number_of_inactive_areas}</span></p>
          </div>
        );
      }
    },
    {
      id: 2,
      name: "Assets",
      icon: process.env.PUBLIC_URL + "./images/icons/upperCard.svg",
      count: parseInt(statistic.number_of_safety_assets) + parseInt(statistic.number_of_none_safety_assets),
      add: addVisitors,
      render: () => {
        return (
          <div className="sun-info">
            <p>Safety Assets: <span>{statistic.number_of_safety_assets}</span></p>
            <p>Non-Safety Assets: <span>{statistic.number_of_none_safety_assets}</span></p>
          </div>
        );
      }
    },
    {
      id: 4,
      name: "Forms",
      icon: process.env.PUBLIC_URL + "./images/icons/upperCard.svg",
      count:parseInt(statistic.number_of_active_forms) + parseInt(statistic.number_of_form_responses),
      add: addForms,
      render: () => {
        return (
          <div className="sun-info">
            <p>Active: <span>{statistic.number_of_active_forms}</span></p>
            <p>Responses: <span>{statistic.number_of_form_responses}</span></p>
            
          </div>
        );
      }
    },
    {
      id: 3,
      name: "Daylight",
      icon: process.env.PUBLIC_URL + "./images/icons/sunrise-icon.png",
      count: '',
      add: showAddAssetModal,
      render: () => {
        return (
          <div className="sun-info">
            <p>Sunrise: <span>{statistic.sunrise}</span></p>
            <p>Sunset: <span>{statistic.sunset}</span></p>
          </div>
        );
      }
    },
  ];
  useEffect(() => {
    if (activePropertyId) {
      dashboardService.getDashboardData(activePropertyId).then(res => {
        if(res.code === '0000'){
          setStatistic(res.data)
        }
      });
    }
  }, [activePropertyId]);

  const getTomorrowioWeatherData = () => {
    let data = {
      duration_type: "today",
      timezone_type: "property",
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    weatherDataService
      .getTomorrowioWeatherDataV2(data, activePropertyId)
      .then((res) => {
        let data = res?.weather_data?.data?.timelines;
        if (res.code === "0000" && data) {
          data = data[0].intervals;
          if (data) {
            const temperatures = data.map(function (item) {
              return item.values.temperature;
            });
            setMinTemparature(Math.min(...temperatures));
            setMaxTemparature(Math.max(...temperatures));
            const precipitation = data.map(function (item) {
              return item.values.precipitationIntensity;
            });
            if (precipitation.length > 0) {
              const sum = precipitation.reduce((acc, val) => acc + val, 0);
              const average = sum / precipitation.length;
              setPrecipitationIntensity(average.toFixed(2));
            }
          }
        }
      });
  };

  const getWeatherDataCode = () => {
    let data = {
      duration_type: "weather_code_of_today",
      timezone_type: "property",
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    weatherDataService
      .getTomorrowioWeatherDataV2(data, activePropertyId)
      .then((res) => {
        let data = res?.weather_data?.data?.timelines;
        if (res.code === "0000" && data) {
          data = data[0].intervals;
          if (data.length > 0) {
            setWeatherCode(data[data.length - 1].values.weatherCode);
          }
        }
      });
  };

  useEffect(() => {
    if (activePropertyId) {
      getTomorrowioWeatherData();
      getWeatherDataCode();
    }
  }, [activePropertyId]);

  return (
    <div className="manage-property-main">
      <div className="manage-property-cards">
        {dashboardUpperCard?.map((item) => (
          <Card  key={item.id}>
            <div className="catBtn">
              <img
                src={item.icon}
                height="24px"
                width="24px"
                className="arrowImg"
                alt="arrowImg"
              />
              <h4 className="upperCatName">{item.name}</h4>
            </div>
            <div >
            <h2 className="item-count">{item.count}</h2>
              { item.render() }
            </div>
          </Card>
        ))}
      </div>
      <div>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row style={{ borderRadius: "20px" }} className="Weathers">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                className="weather-map-style"
              >
                <WeatherMap/>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} className="propertyMap">
            <PropertyMap className="weather-map-style" isShowOnDashboard={true}/>
          </Col>
        </Row>
      </div>
    </div>
    // </DashboardWrapper>
  );
};

export default BlankPage;
const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;