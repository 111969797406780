import React from 'react';

const Hamburger = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M24 16.2904C24 16.9504 23.4648 17.4844 22.806 17.4844L13.194 17.4844C12.8773 17.4844 12.5736 17.3586 12.3497 17.1347C12.1258 16.9107 12 16.607 12 16.2904C12 15.9737 12.1258 15.67 12.3497 15.4461C12.5736 15.2222 12.8773 15.0964 13.194 15.0964L22.806 15.0964C23.1227 15.0964 23.4264 15.2222 23.6503 15.4461C23.8742 15.67 24 15.9737 24 16.2904Z" fill="#3C4437"/>
		<path d="M24 9.08432C24 9.74432 23.4648 10.2783 22.806 10.2783L1.194 10.2783C0.877331 10.2783 0.573633 10.1525 0.349714 9.9286C0.125795 9.70469 7.66987e-08 9.40099 1.04383e-07 9.08432C1.32067e-07 8.76765 0.125796 8.46395 0.349714 8.24003C0.573633 8.01611 0.877331 7.89032 1.194 7.89032L22.806 7.89032C23.1227 7.89032 23.4264 8.01612 23.6503 8.24003C23.8742 8.46395 24 8.76765 24 9.08432Z" fill="#3C4437"/>
		<path d="M22.806 3.07239C23.1227 3.07239 23.4264 2.94659 23.6503 2.72267C23.8742 2.49876 24 2.19506 24 1.87839C24 1.56172 23.8742 1.25802 23.6503 1.0341C23.4264 0.810185 23.1227 0.684388 22.806 0.684388L8.394 0.684387C8.07733 0.684387 7.77363 0.810184 7.54972 1.0341C7.3258 1.25802 7.2 1.56172 7.2 1.87839C7.2 2.19506 7.3258 2.49875 7.54972 2.72267C7.77363 2.94659 8.07733 3.07239 8.394 3.07239L22.806 3.07239Z" fill="#3C4437"/>
		</svg>
  );
}
export default Hamburger;