export const workingRights = [
  {
    label: "Citizen",
    value: "citizen",
  },
  {
    label: "Working visa",
    value: "working_visa",
  },
];
