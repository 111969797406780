export const deviceTypes  = [
    {
      label: 'AirAgri / PLD',
      value: 'airagri_pld',
    },
    {
      label: 'Zoleo (AirAgri)',
      value: 'zoleo_airagri',
    },
    {
      label: 'Zoleo (BYOD)',
      value: 'zoleo_byod'
    },  
    {
      label: 'Garmin (AirAgri)',
      value: 'garmin_airagri'
    },
    {
      label: 'Garmin (BYOD)',
      value: 'garmin_byod'
    }
]
