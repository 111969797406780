import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Box from "@iso/components/utility/box";
import IntlMessages from "@iso/components/utility/intlMessages";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, Menu, Popconfirm } from "antd";
import {
    DownOutlined,
    EditOutlined,
    PlusCircleOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { mobService, propertyService } from "@iso/services";
import modalActions from "@iso/redux/modal/actions";
import _ from "lodash";
import { objectColors } from "@iso/constants/objectColors";
import moment from "moment";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import MobModal from "@iso/containers/Pages/PropertyMap/components/Mob/MobModal";
import AddHistoryModal from "@iso/containers/Pages/PropertyMap/components/Mob/AddHistoryModal";
import SelectPropertyModal from "@iso/containers/Pages/Property/SelectPropertyModal";

const { openModal } = modalActions;

const MobBox = (props) => {
    const dispatch = useDispatch();
    const [mobs, setMobs] = useState([]);
    const activePropertyId = useSelector(
        (state) => state.property.activePropertyId
    );
    const activeProperty = localStorage.getItem("active_property");
    const activePermission = useSelector(
        (state) => state.permission.activePermission
    );
    const activePropertySubscription = useSelector(
        (state) => state.subscription.activePropertySubscription
    );
    const [primaryObjects, setPrimaryObjects] = useState([]);
    const [visibleHistory, setVisibleHistory] = useState(false);
    const [mobData, setMobData] = useState({});
    const [visibleSelectProperty, setVisiblitySelectProperty] = React.useState(
        false
    );
    const [mobsForExport, setMobsForExport] = useState([]);
    const [visibleMergeMobModal, setVisibleMergeMobModal] = useState(false);

    const updatePropertyDetail = React.useCallback((id) => {
        propertyService.viewProperty(id).then((res) => {
            if (res.code === "0000") {
                setPrimaryObjects(res.primary_objects);
            }
        });
    }, []);

    useEffect(() => {
        if (activePropertyId) {
            updatePropertyDetail(activePropertyId);
        }
    }, [activePropertyId, updatePropertyDetail, activeProperty]);

    const updateMobData = () => {
        if (activePropertyId) {
            mobService.getList(activePropertyId).then((res) => {
                if (res.code === "0000") {
                    setMobs(res.mobs);
                    setMobsForExport(res.mobs_for_export);
                }
            });
        }
    };

    useEffect(() => {
        updateMobData();
    }, [activePropertyId]);

    useEffect(() => {
        propertyService.getProperties().then((res) => {
            if (res.properties.length === 0) {
                setVisiblitySelectProperty(true);
            }
        });
    }, [activePropertyId]);

    useEffect(() => {
        if (!activeProperty) {
            setVisiblitySelectProperty(true);
        }
    }, []);

    const handleMenuClick = (mob, e) => {
        if (e.key === "editDetails") {
            showModal(mob);
        }

        if (e.key === "addHistory") {
            setMobData(mob);
            showAddHistoryModal();
        }
    };

    const showModal = (object) => {
        const modalData = {
            object,
            type: "mob",
            canCreate: true,
        };
        dispatch(openModal(modalData));
    };

    const showAddHistoryModal = () => {
        setVisibleHistory(true);
    };

    const cancelModalHistory = () => {
        setVisibleHistory(false);
    };

    const cancelSelectPropertyModal = () => {
        setVisiblitySelectProperty(false);
    };

    const menu = (mob) => (
        <Menu onClick={handleMenuClick.bind(this, mob)}>
            <Menu.Item key="editDetails" icon={<EditOutlined />}>
                <IntlMessages id="propertyPage.managePage.editDetails" />
            </Menu.Item>
            {(activePermission == "owner" ||
                activePermission == "admin" ||
                activePermission == "modify") && (
                <Menu.Item key="addHistory" icon={<PlusCircleOutlined />}>
                    <IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />
                </Menu.Item>
            )}

            {(activePermission == "owner" || activePermission == "admin") && (
                <p style={{ cursor: "pointer" }} className="deleteButton">
                    <Popconfirm
                        placement="bottomRight"
                        title="You are about to delete individual record"
                        onConfirm={() => {
                            confirmDelete(mob);
                        }}
                        okText="Yes"
                        cancelText="No"
                        className="delete-button"
                    >
                        <a
                            style={{
                                color: "rgba(0, 0, 0, 0.65)",
                                marginLeft: "11px",
                            }}
                        >
                            {<DeleteOutlined />}&nbsp;&nbsp;
                            <IntlMessages id="propertyPage.managePage.delete" />
                        </a>
                    </Popconfirm>
                </p>
            )}
        </Menu>
    );

    const onSaved = (savedMob) => {
        let mobTmp = _.clone(mobs);
        const mobIndex = _.findIndex(mobTmp, (mob) => {
            return mob.id === savedMob.id;
        });
        if (mobIndex === -1) {
            mobTmp.push(savedMob);
        } else {
            mobTmp[mobIndex] = savedMob;
        }
        setMobs(mobTmp);
    };

    const confirmDelete = (del_mob) => {
        if (del_mob.id) {
            mobService.destroy(del_mob.id).then((res) => {
                if (res.code === "0000") {
                    updateMobData();
                }
            });
        }
    };

    const columns = [
        {
            label: <IntlMessages id="antTable.title.name" />,
            accessor: "name",
            minWidth: 100,
            sortable: true,
            position: 1,
            priorityLevel: 1,
        },
        {
            label: <IntlMessages id="antTable.title.animal" />,
            accessor: "type_label",
            minWidth: 100,
            sortable: true,
            position: 2,
            priorityLevel: 2,
        },
        {
            label: <IntlMessages id="antTable.title.area" />,
            accessor: "primary_object",
            minWidth: 100,
            sortable: true,
            position: 3,
            priorityLevel: 3,
        },
        {
            label: <IntlMessages id="antTable.title.tag_colour" />,
            accessor: "tag_colour",
            minWidth: 100,
            sortable: true,
            position: 4,
            priorityLevel: 4,
            CustomComponent: (cell) => {
                let mob = cell.row;
                return objectColors.map((value, index) => {
                    if (value.value == mob.tag_colour) {
                        return (
                            <div style={{ display: "inline-flex" }}>
                                <ColorItemPreview
                                    style={{ backgroundColor: value.color }}
                                ></ColorItemPreview>
                                &nbsp;&nbsp;
                                <div>{value.label}</div>
                            </div>
                        );
                    }
                });
            },
        },
        {
            label: <IntlMessages id="propertyPage.modal.mob.numberAnimal" />,
            accessor: "tag_number_range",
            minWidth: 100,
            sortable: true,
            position: 5,
            priorityLevel: 5,
        },
        {
            label: <IntlMessages id="antTable.title.date_of_birth" />,
            accessor: "date_of_birth",
            minWidth: 100,
            sortable: true,
            position: 6,
            priorityLevel: 6,
            CustomComponent: (cell) => {
                let mob = cell.row;
                return mob.date_of_birth
                    ? moment(mob.date_of_birth).format("DD/MM/YYYY")
                    : "";
            },
        },
        {
            label: <IntlMessages id="antTable.title.breed" />,
            accessor: "breed",
            minWidth: 100,
            sortable: true,
            position: 7,
            priorityLevel: 7,
        },
        {
            label: "",
            accessor: "actions",
            minWidth: 100,
            sortable: true,
            position: 8,
            priorityLevel: 8,
            noTitle: true,
            CustomComponent: (cell) => {
                let mob = cell.row;
                return (
                    <Dropdown overlay={menu(mob)} trigger={["click"]}>
                        <Button className="btn-success ant-btn-primary">
                            <IntlMessages id="antTable.title.actions" />{" "}
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <Fragment>
            <Box id="containerBox">
                <div>
                    <h3>
                        <u>Mob</u>
                    </h3>
                </div>
                {props.search_result &&
                props.search_result.Mob &&
                props.search_result.Mob.length > 0 ? (
                    <Table
                        columns={columns}
                        rows={props.search_result.Mob}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                    />
                ) : (
                    <h1>
                        <IntlMessages id="dashboardPage.noData" />
                    </h1>
                )}
            </Box>
            <MobModal
                onSaved={onSaved}
                updateDataMobs={updateMobData}
                propertyId={activePropertyId}
                primaryObjects={primaryObjects}
                updateMobData={updateMobData}
            />
            <AddHistoryModal
                visibleHistory={visibleHistory}
                updateDataMobHistory={updateMobData}
                model="mobs"
                type="mob"
                dataId={mobData.id}
                primaryObjects={primaryObjects}
                cancelModal={cancelModalHistory}
                propertyId={activePropertyId}
                mobHistory={true}
                numberAnimals={mobData.tag_number_range}
                updateNumberAnimal={updateMobData}
            />
            <SelectPropertyModal
                visibleSelectProperty={visibleSelectProperty}
                cancelSelectPropertyModal={cancelSelectPropertyModal}
            />
        </Fragment>
    );
};

export default MobBox;

const ColorItemRow = styled.div`
    display: flex;
    align-items: center;
    line-height: 30px;
    div {
        margin: 4px;
    }
`;

const ColorItemPreview = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
`;
