import styled from 'styled-components';
import { palette } from 'styled-theme';
import WithDirection from '@iso/lib/helpers/rtl';
import { handleShowRandomImage } from '@iso/constants/randomBackgrounds';
import _ from 'lodash';

const getImageUrls = (images) => {
  const urls = _.map(images, (image)=>{
    return image.full_path;
  })
  return urls;
}

const randomIndex = [];

const AgencyStyleWrapper = styled.div`
    .isoSignInPage{
      background: url(${props => handleShowRandomImage(props.agent?.backgroundImages ? getImageUrls(props.agent?.backgroundImages): null)}) no-repeat center center;
    }
    .isoSignUpContent{
      ${props => props.agent?.color_code ? 
      `.ant-input{
        border: 1px solid `+ props.agent.color_code +` !important;
      }
      .btn-success{
        background-color: `+ props.agent.color_code +`
      }
      #term-and-condition{
        color: `+ props.agent.color_code +` !important
      }
      .ant-checkbox-checked .ant-checkbox-inner{
        background-color: `+ props.agent.color_code +`;
        border-color: `+ props.agent.color_code +`
      }
      .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner{
        border-color: `+ props.agent.color_code +`
      }` : ''}
    }
    .isoLoginContentWrapper{
      ${props => props.agent?.color_code ? 
        `.ant-input{
          border: 1px solid `+ props.agent.color_code +` !important;
        }
        .btn-success{
          background-color: `+ props.agent.color_code + `
        }` : ''}
    }
    .menuTop{
      ${props => props.agent?.color_code ? 
        `#btn-contact-agent{
          background-color: `+ props.agent.color_code + `;
          border: 1px solid `+ props.agent.color_code + `!important
        }` : ''}
      
    }
`;

export default WithDirection(AgencyStyleWrapper);
