import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import {  IconTask  } from '@iso/containers/Pages/PropertyMap/Icons/IconTask';
import _ from 'lodash';
import { taskService } from '@iso/services';

const Task = (props) => {
  const [tasks, setTasks] = useState({});
  const objectRef = React.useRef([]);

  const updateDataTasks = React.useCallback((propertyId) => {
    taskService.getList(propertyId).then(res => {
      if(res.code === '0000'){
        setTasks(res.tasks);
      }
    })
  }, [props.primaryObjects]);

  useEffect(() => {
    if(props.propertyId){
      updateDataTasks(props.propertyId);
    }
  }, [updateDataTasks, props.propertyId]);

  const handleShowTasks = (data) => {
    var html = [];
    if(data.length > 0){
      _.forEach(data, function(task){
        html.push(
          <Marker 
            key={task.id} 
            position={[task.lat, task.lng]} 
            icon={IconTask()}
            ref={el => (objectRef.current[task.id] = el)}
          />
        );
      });
    }
    return html;
  }

  return (
    <>
      {handleShowTasks(tasks)}
    </>
  );
}

export default Task;
