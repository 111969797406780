import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Row, Col } from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import SelectPropertyModalWrapper from "./SelectPropertyModal.styles";
import _ from "lodash";
import { taskService } from "@iso/services"
const SelectDeleteCard = (props) => {
  const currentUser = useSelector((state) => state.user.user);
  const cancelModal = () => {
    props.setVisibleDeleteModal();
  };

const onCancelDelete = () => {
  props.setVisibleDeleteModal(false);
}
const onDeleteCard = () => {
  taskService.destroy(props.deleteId.id).then((res) => {
    if (res.code === "0000") {
      props.setVisibleDeleteModal(false);
      props.updateDataBoard(props.kanbanToggle);
    } else {
     
    }
  });
}
  return (
    <SelectPropertyModalWrapper
    open={props.visibleDeleteModal && currentUser.is_system_admin !== 1}
    title={<IntlMessages id={`Delete ${props.deleteId && _.capitalize(props.deleteId.metadata.title)}`}/>}
      onCancel={cancelModal}
      maskClosable={true}
      footer={
        <>
              <Button
                key="back"
                onClick={onDeleteCard}
                className="cancel-button"
              >
                {<IntlMessages id="Delete" />}
              </Button>
              <Button key="back" onClick={onCancelDelete} className="cancel-button">
          {<IntlMessages id='Cancel' />}
        </Button>
              </>  
      }
    >
      <div className="form-body" style={{ textAlign: "center" }}>
        <h2 className="">
        Are you sure you want to delete {props.deleteId &&  _.capitalize(props.deleteId.metadata.title)} task?
        </h2>
        <br />
       
      </div>
    </SelectPropertyModalWrapper>
  );
};

export default SelectDeleteCard;