export const sensorPartNumbers = [
  {
    label: 'X50-14002',
    value: 'X50-14002',
  },
  {
    label: 'X50-14016',
    value: 'X50-14016',
  },
  {
    label: 'X50-14010',
    value: 'X50-14010',
  },
  {
    label: 'X50-14006',
    value: 'X50-14006',
  }
]