import React, { useState, useEffect } from 'react';
import LiveStockModalWrapper from './NewLiveStockModal.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, Input, Button, Select, Row, Col, DatePicker, InputNumber, Popconfirm, Checkbox, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import modalActions from '@iso/redux/modal/actions';
import { useSelector, useDispatch } from 'react-redux';
import { mobService, breedService, livestockService, assetService,optiWeight } from '@iso/services';
import { livestockTypes } from '@iso/constants/livestockTypes';
import { livestockSex } from '@iso/constants/livestockSex';
import BreedModalWrapper from './BreedModal.styles';
import Loader from '@iso/components/utility/loader';
import { objectColors } from '@iso/constants/objectColors';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import styled from "styled-components";
import $ from 'jquery';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import { commonHelper } from '../../../../../library/helpers/commonHelper';
import { mobTypes } from '@iso/constants/mobTypes';
import FileUploader from "@iso/components/FileUploader/FileUploader";
import { FileStorage } from "@iso/constants/UploadFile";
import { propertyHelper } from "@iso/lib/helpers/propertyHelper";

const { openModal } = modalActions;
const { closeModal } = modalActions;
const { Option } = Select;

const NewLiveStockModal = (props) => {
  const visibleLiveStockModal = props.visible;
  const activePIC = useSelector((state) => state.property_pic.activePropertyPIC);
  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);
  const activeProperty = localStorage.getItem('active_property');
  const [form] = Form.useForm();
  const [breedForm] = Form.useForm();
  const [amountLiveStock, setAmountLiveStock] = useState(1);
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.modal.modalData);
  const [fields, setFields] = useState([]);
  const [mobs, setMobs] = useState([]);
  const [assets, setAssets] = useState([]);
  const [breeds, setBreeds] = useState({});
  const [visibleBreed, setVisibleBreed] = useState(false);
  const [loadingBreed, setLoadingBreed] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [dataLivestock, setDataLivestock] = useState({});
  const [checkingUniqueID, setCheckingUniqueID] = useState(true);
  const [checkingTagNumber, setCheckingTagNumber] = useState(true);
  const [checkingNLIS, setCheckingNLIS] = useState(true);
  const [AIChecked, setAIChecked] = useState(false);
  const [manualChecked, setManualChecked] = useState(false);
  const [sireAssets, setSireAssets] = useState([]);
  const [imageId, setImageId] = useState(null);
  const [recordsOpti, setRecordsOpti] = useState({});

  useEffect(() => {
    
   
    if(props.propertyId){
       breedService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setBreeds(res.breeds);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId]);

  useEffect(() => {
    if(props.propertyId){
      optiWeightRecords(props.propertyId);
       livestockService.getAllAssetByProperty(props.propertyId).then(res => {
        if(res.code === '0000'){
          setAssets(res.assets);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId, modalVisibility]);

  useEffect(() => {
    if(props.propertyId){
       assetService.getAnimalSire(props.propertyId).then(res => {
        if(res.code === '0000'){
          setSireAssets(res.animal_sires);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId, modalVisibility]);
  const optiWeightRecords = (propertyId) =>{
    optiWeight.getData(propertyId).then(res => {
        if(res.code === '0000'){
          if(res.opti_weight?.is_active && res.opti_weight?.is_active === 1 && propertyHelper.checkIsGoldPackage(activePropertySubscription)){
            optiWeight.getDataOptiWeight(res.opti_weight.id).then(res =>{
              setRecordsOpti(res.records[0].records)
            });
          }
         
        }
    });
  }
  const onKeyUp = (e) =>{
    let valueRFID = form.getFieldValue(['livestock', 'tag_rfid']);
    setFields([
      {
        name: ['livestock', 'weight'],
        value: '',
      },
    ]);
    if(recordsOpti.length > 0){
      _.forEach(recordsOpti, (value, index) => {
        if(valueRFID === value.eid){
          setFields([
            {
              name: ['livestock', 'weight'],
              value: value.avWt,
            },
          ]);
        }
      })
    }
  }
  const addBreed = (object, e) => {
    setVisibleBreed(true);
  }

  const cancelModalBreed = () => {
    setVisibleBreed(false);
  }

  const onStoreBreed = (breed_values) => {
    const breed_data = {
      "breed":{
        ...breed_values.breed,
        property_id: props.propertyId
      }
    }

    breedService.storeOrUpdate(breed_data).then(res => {
      if(res.code === '0000'){
        var tmp_breeds = _.clone(breeds);
        tmp_breeds.push(res.breed);
        setBreeds(tmp_breeds);
        cancelModalBreed();
        breedForm.resetFields();
        setFields([
          {
            name: ['livestock', 'breed'],
            value: res.breed.slug,
          },
        ]);
      } else {
        setLoadingBreed(false);
      }
    });

  }
  
  useEffect(() => {
    setFields([
      {
        name: ['livestock', 'NLIS'],
        value: props.propertyPIC || '',
      },
      {
        name: ['livestock', 'uid'],
        value: commonHelper.generateRandomString(16)
      }
    ]);

    if(props.mobId){
      setFields([
        {
          name: ['livestock', 'mob_id'],
          value: props.mobId,
        },
      ]);
    }

  }, [props.visibleAddLivestock, modalVisibility]);

  const renderOptionsBreed = (mob_breed_options) => {
    let breed_options = [];
     _.forEach(mob_breed_options, (breed_opt, index) => {
      breed_options.push(
        <Option key={index} value={breed_opt.slug}>
            <div>{breed_opt.name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a breed"
        allowClear
        size="large"
      >
        {breed_options}
      </Select>
    );
  }

  const handleBreedSubmit = (e) => {
    breedForm
      .validateFields()
      .then(breed_values => {
        onStoreBreed(breed_values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoadingBreed(false);
      });
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = async (values) => {
    let color = '';
    objectColors.map((value, index) => {
      if(value.value == values.livestock.tag_colour){ color = value.label}
    });

    if(values.livestock.uid && values.livestock.tag_number_range){
      const unique_id = {
        asset_info: {
          uid: values.livestock.uid,
          tag_number: values.livestock.tag_number_range,
          NLIS:values.livestock.NLIS,
          tag_colour: values.livestock.tag_colour
        }
      }
      var res = await livestockService.checkUniqueID(unique_id);
    }
    
    if(res && res.code !== '0000'){
      if(res.identification === true){
        setCheckingUniqueID(false);
      }else{
        setCheckingUniqueID(true);
      }

      if(res.tag_number === true){
        setCheckingTagNumber(false);
      }else{
        setCheckingTagNumber(true);
      }

      if(res.NLIS === true){
        setCheckingNLIS(false);
      }else{
        setCheckingNLIS(true);
      }
     
      setLoading(false);
      setTimeout(()=>{
        let NLIS_confirm = $('.delete-button');
        if(NLIS_confirm)
          $(NLIS_confirm).trigger('click');
      },10);

      return false;
    }else{
      const data = {
        asset: {
          ...values.livestock,
          date_of_birth: values.livestock.date_of_birth ? dateHelper.dateForAPI(values.livestock.date_of_birth) : '',
          month_of_birth: values.livestock.month_of_birth ? dateHelper.getMonth(values.livestock.month_of_birth) : '',
          year_of_birth: values.livestock.year_of_birth ? dateHelper.getYear(values.livestock.year_of_birth) : '',
          displayed_name: color +  ' | ' + values.livestock.tag_number_range + ' | ' + values.livestock.uid,
          property_id: props.propertyId,
          image_id: imageId,
        }
      }
      livestockService.storeOrUpdate(data, dataLivestock.id).then(res => {
        if(res.code === '0000'){
          if(props.updateLiveStockData)
            props.updateLiveStockData();
          if(props.updatePropertyDetail){
            props.updatePropertyDetail(props.propertyId);
          }
          cancelModal();
          setCheckingUniqueID(true);
          setCheckingTagNumber(true);
          setCheckingNLIS(true);
          form.resetFields();
          if(props.updateAssetTotal){
            props.updateAssetTotal();
          }
        } else {
          setLoading(false);
        }
      });
    }
  }

  const renderOptionsColor = () => {
    let options = [];
    _.forEach(objectColors, (color, index) => {
      options.push(
        <Option key={index} value={color.value}>
          <ColorItemRow>
            <ColorItemPreview style={{backgroundColor: color.color}}></ColorItemPreview>
            <div>{color.label}</div>
          </ColorItemRow>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a color"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const cancelModal = () => {
    if(props.from === 'mob-modal'){
      props.cancelAddLivestockModal();
    }else{
      dispatch(closeModal());
      //setActiveTab("1");
      if(modalData.layer){
        modalData.featureGroup.leafletElement.removeLayer(modalData.layer);
      }
    }
    setCheckingUniqueID(true);
    setCheckingTagNumber(true);
    setCheckingNLIS(true);
    form.resetFields();
    setLoading(false);
  }

  useEffect(() => {
    if(props.propertyId){
       mobService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setMobs(res.mobs);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId, modalVisibility]);

  const renderOptionMob = (mob_data) => {
    let options = [];
    _.forEach(mob_data, (mob, index) => {
      options.push(
        <Option key={mob.id} value={mob.id}>
          {mob.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a mob"
        allowClear
        size="large"
        disabled = {props.mobId ? true : false}
      >
        {options}
      </Select>
    );
  }

  const renderOptionMaleAsset = (asset_data) => {
    let options = [];
    _.forEach(asset_data, (asset, index) => {
      if(asset.sex && asset.displayed_name){
        options.push(
          <Option key={asset.id} value={asset.id} label={asset.displayed_name}>
          {objectColors.map((value, index) => {
          if(value.value == asset.tag_colour){
              return (
                <ColorItemRow>
                  <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                  <div>{asset.displayed_name}</div>
                </ColorItemRow>
                )
            }

          })}
          </Option>
        );
      }
    })
    return (
      <Select
        placeholder="Select a livestock"
        allowClear
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionFemaleAsset = (asset_data) => {
    let options = [];
    
    _.forEach(asset_data, (asset, index) => {
      if(asset.sex && asset.displayed_name){
        options.push(
          <Option key={asset.id} value={asset.id} label={asset.displayed_name}>
           {objectColors.map((value, index) => {
            if(value.value == asset.tag_colour){
              return (
                <ColorItemRow>
                  <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                  <div>{asset.displayed_name}</div>
                </ColorItemRow>
                )
            }
          })}
          </Option>
        );
      }
    })
    return (
      <Select
        placeholder="Select a livestock"
        allowClear
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionSireAsset = (sire_assets) => {
    let options = [];
    _.forEach(sire_assets, (asset, index) => {
      if(asset.tag_colour && asset.tag_number){
        options.push(
          <Option key={asset.id} value={asset.id}>
            {objectColors.map((value, index) => {
              if(value.value == asset.tag_colour){
                return (
                  <ColorItemRow>
                    <ColorItemPreview style={{backgroundColor: value.color}}></ColorItemPreview>
                    <div>{value.label + ' | ' + asset.tag_number}</div>
                  </ColorItemRow>
                  )
              }
            })}
          </Option>
        );
      }else{
        options.push(
          <Option key={asset.id} value={asset.id}>
            <div>{asset.animal_name}</div>
          </Option>
        );
      }
    })
    return (
      <Select
        placeholder="Select a livestock"
        allowClear
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionsLivestockType = () => {
    let livestock_type_options = [];
     _.forEach(livestockTypes, (type, index) => {
      livestock_type_options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a type"
        allowClear
        size="large"
      >
        {livestock_type_options}
      </Select>
    );
  }

  const renderOptionsLivestockSex = () => {
    let livestock_sex_options = [];
     _.forEach(livestockSex, (sex, index) => {
      livestock_sex_options.push(
        <Option key={index} value={sex.value}>
            <div>{sex.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select sex"
        allowClear
        size="large"
      >
        {livestock_sex_options}
      </Select>
    );
  }

  const renderOptionsMobType = () => {
    let options = [];
    _.forEach(mobTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a type of animal"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const continueWithNullNLIS = () => {
    setFields([
      {
        name: ['livestock', 'NLIS'],
        value: '',
      },
    ]);

    setTimeout(()=>{
      let save = $('.btn-livestock-save');
      if(save)
        $(save).trigger('click');
    },10);
  }

  const onChangeCheckBox = e => {
    setAIChecked(e.target.checked);
  }

  const onChangeManualCheckBox = e => {
    setManualChecked(e.target.checked);
  }

  const handleOnUploadSuccess = (image) => {
    setImageId(image.id);
  }

  return (
    <>
     <LiveStockModalWrapper
      visible={(modalVisibility && modalData.type === 'livestock') || props.visibleAddLivestock}
      onCancel={cancelModal}
      centered
      maskClosable={false}
      title={ <IntlMessages id="liveStockPage.managePage.addLiveStock"/> }
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success btn-livestock-save" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
     >
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          <div className="form-body">
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                    name={['livestock', 'mob_id']}
                            className="isoInputWrapper"
                            label="Mob"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                    
                  {renderOptionMob(mobs)}
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                    name={['livestock', 'livestock_type']}
                            className="isoInputWrapper"
                            label="Type"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                  {renderOptionsLivestockType()}
                </Form.Item>
              </Col>      
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (prevValues['livestock'] && (prevValues['livestock'].livestock_type !== currentValues['livestock'].livestock_type));
              }}
            >
              {({ getFieldValue }) => {
                  if(getFieldValue('livestock')){
                    if (getFieldValue('livestock').livestock_type === "single") { return (
                      <div>
                        <Row>
                          <Col xs={{span: 15}} md={{span: 17}}>
                            <Form.Item
                              name={['livestock', 'breed']}
                              className="isoInputWrapper"
                              label="Breed of animal"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                            {renderOptionsBreed(breeds)}
                            </Form.Item>
                          </Col>
                          <Col xs={{span: 7, offset: 2}} md={{span: 5, offset: 2}}>
                            <Button key="submit" className="btn-create-breed" type="primary" onClick={addBreed}>
                              {<IntlMessages id="propertyPage.modal.breed.addBreed" />}
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['livestock', 'date_of_birth']}
                            className="isoInputWrapper"
                            label="Date Of Birth"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                             <DatePicker size="large" style={{width: '100%'}}/>
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['livestock', 'type']}
                            className="isoInputWrapper"
                            label="Type of animal"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderOptionsMobType()}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['livestock', 'tag_colour']}
                            className="isoInputWrapper"
                            label="Tag Colour"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderOptionsColor()}
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['livestock', 'tag_number_range']}
                            className="isoInputWrapper"
                            label="Tag Number"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Input tag number"/>
                          </Form.Item>
                        </Col>
                         { (!checkingTagNumber) ? <div className="ant-form-item-has-error">
                          <div className="ant-form-item-explain">
                          <div>Tag Colour and Tag Number are already in use!</div>
                          </div></div> : ''}
                      </Row>
                      <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['livestock', 'weight']}
                            className="isoInputWrapper"
                            label="Weight (kg)"
                          >
                            <InputNumber size="large" min={1} max={1000} />
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['livestock', 'sex']}
                            className="isoInputWrapper"
                            label="Sex"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            {renderOptionsLivestockSex()}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{span: 2}} md={{span: 2}}>
                          <Form.Item
                            name={['livestock', 'ai']}
                            className="isoInputWrapper"
                            valuePropName="checked"
                            label="AI"
                          >
                            <Checkbox onChange={onChangeCheckBox}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 3}} md={{span: 3}}>
                          <Form.Item
                            name={['livestock', 'manual']}
                            className="isoInputWrapper"
                            valuePropName="checked"
                            label="Manual"
                          >
                            <Checkbox onChange={onChangeManualCheckBox}></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 17, offset: 2}} md={{span: 17, offset: 2}}>
                            { manualChecked ? <Form.Item
                                name={['livestock', 'manual_sire']}
                                className="isoInputWrapper"
                                label="Sire"
                              >
                                <Input size="large" placeholder="Input sire"/>
                              </Form.Item> : (AIChecked ? <Form.Item
                                name={['livestock', 'sire_asset_id']}
                                className="isoInputWrapper"
                                label="Sire"
                              >
                                {renderOptionSireAsset(sireAssets)}
                              </Form.Item> : 
                              <Form.Item
                                name={['livestock', 'siren_id']}
                                className="isoInputWrapper"
                                label="Sire"
                              >
                               {renderOptionMaleAsset(assets)}
                              </Form.Item>)
                            }
                          </Col>
                        </Row>

                        { manualChecked ? <Form.Item
                            name={['livestock', 'manual_mother']}
                            className="isoInputWrapper"
                            label="Mother"
                          >
                           <Input size="large" placeholder="Input mother"/>
                          </Form.Item> : <Form.Item
                            name={['livestock', 'mother_id']}
                            className="isoInputWrapper"
                            label="Mother"
                          >
                           {renderOptionFemaleAsset(assets)}
                          </Form.Item>
                        }
                      <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['livestock', 'NLIS']}
                            className="isoInputWrapper"
                            label="NLIS ID NUMBER"
                          >
                           <Input size="large" placeholder="Input NLIS ID"/>
                          </Form.Item>
                          { (!checkingNLIS) ? <div className="ant-form-item-has-error">
                          <div className="ant-form-item-explain">
                          <div>
                            <Popconfirm
                              placement="bottomLeft"
                              title="NLIS is already in use! Do you want to edit?"
                              okText="Continue with blank"
                              cancelText="Yes"
                              onConfirm={() => {
                                continueWithNullNLIS();
                              }}
                              className="delete-button"
                            >
                            </Popconfirm>
                          </div>
                          </div></div> : ''}
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['livestock', 'tag_rfid']}
                            className="isoInputWrapper"
                            label="TAG RFID"
                          >
                           <Input size="large" placeholder="Input TAG RFID" onKeyUp={onKeyUp} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        name={['livestock', 'uid']}
                        className="isoInputWrapper"
                        label="System Gen ID"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Input size="large" placeholder=""/>
                      </Form.Item>
                      { (!checkingUniqueID) ? <div className="ant-form-item-has-error">
                      <div className="ant-form-item-explain">
                      <div>System Gen ID is already in use!</div>
                      </div></div> : ''}
                       <Form.Item
                        name={['livestock', 'unique_features']}
                        className="isoInputWrapper"
                        label="Unique features"
                      >
                        <Input.TextArea />
                      </Form.Item>
                       <Form.Item
                        name={['livestock', 'description']}
                        className="isoInputWrapper"
                        label="Description"
                      >
                        <Input.TextArea />
                      </Form.Item>
                      {propertyHelper.checkIsGoldPackage(activePropertySubscription) && 
                        <Form.Item name={['attachment']} label="Attachment">
                          <FileUploader
                            type='livestocks'
                            storage={FileStorage.filesystem}
                            isCompressFile={true}
                            handleOnSuccess={handleOnUploadSuccess}
                          />
                        </Form.Item>
                      }
                    </div>
                    )} else if(getFieldValue('livestock').livestock_type === "group") { return (
                    <div>
                      <Form.Item
                            name={['livestock', 'amount_on_group']}
                            className="isoInputWrapper"
                            label="Amount on group"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                        >
                        <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                      </Form.Item>
                      <Row>
                          <Col xs={{span: 15}} md={{span: 17}}>
                            <Form.Item
                              name={['livestock', 'breed']}
                              className="isoInputWrapper"
                              label="Breed of animal"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                            {renderOptionsBreed(breeds)}
                            </Form.Item>
                          </Col>
                          <Col xs={{span: 7, offset: 2}} md={{span: 5, offset: 2}}>
                            <Button key="submit" className="btn-create-breed" type="primary" onClick={addBreed}>
                              {<IntlMessages id="propertyPage.modal.breed.addBreed" />}
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={{span: 24}} md={{span: 11}}>
                            <Form.Item
                              name={['livestock', 'tag_colour']}
                              className="isoInputWrapper"
                              label="Tag Colour"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              {renderOptionsColor()}
                            </Form.Item>
                          </Col>
                          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                            <Form.Item
                              name={['livestock', 'type']}
                              className="isoInputWrapper"
                              label="Type of animal"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              {renderOptionsMobType()}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                        <Col xs={{span: 24}} md={{span: 11}}>
                          <Form.Item
                            name={['livestock', 'month_of_birth']}
                            className="isoInputWrapper"
                            label="Month Of Birth"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <DatePicker size="large"  picker="month" style={{width: '100%'}} format={'MM'}/>
                          </Form.Item>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                          <Form.Item
                            name={['livestock', 'year_of_birth']}
                            className="isoInputWrapper"
                            label="Year Of Birth"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <DatePicker size="large" picker="year" style={{width: '100%'}}/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    )}else{}
                  }
                }
              }
            </Form.Item>
          </div>  
        </Form>
     </LiveStockModalWrapper>
     <BreedModalWrapper
      visible={visibleBreed}
      onCancel={cancelModalBreed}
      centered
      title={ <IntlMessages id="propertyPage.modal.breed.addBreed.title"/> }
      footer={[        
        <Button key="back" onClick={cancelModalBreed} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleBreedSubmit} loading={loadingBreed}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
    
          <Form
            form={breedForm}
            layout="vertical"
            scrollToFirstError
          >
          <div className="form-body">
            <Form.Item
              name={['breed', 'name']}
              className="isoInputWrapper"
              label="Breed"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input size="large" placeholder="Input breed"/>
            </Form.Item>
            </div>    
      </Form>
    }
    </BreedModalWrapper>
    </>
  )
}


export default NewLiveStockModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;