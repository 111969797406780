import styled from "styled-components";

export const IllnessFormWrapper = styled.div`
  padding: 20px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item:not(.no-style){
    margin-bottom: 24px
  }
  .form-title{
    font-size: 1rem;
    color: #0B2A1B;
    margin-bottom: 10px;
  }
  .witness-space .ant-space-item:nth-child(1), .witness-space .ant-space-item:nth-child(2){
    width: 50%;
  }
  .illness-steps{
    margin-bottom: 30px
  }
  .form-step{
    display: none;
  }
`;
