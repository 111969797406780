import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form, Input, Button, Select, Row, Col, Switch, DatePicker, Upload, message, Checkbox, List, Typography, Space, Dropdown, Menu, Card} from 'antd';
import IntlMessages from '@iso/components/utility/intlMessages';
import FormModalWrapper from './FormModal.styles';
import { configs } from '@iso/components/Tables/configs';
import { CheckCircleFilled, ExclamationCircleFilled, UploadOutlined, MinusCircleOutlined, PlusOutlined, EditOutlined, UserOutlined, DownOutlined, SendOutlined } from '@ant-design/icons';
import { propertyService, userService, permissionService, formService, userFormService, 
  surveyQuestionService, globalFormService, categoryGlobalFormService, visitorFormService, profileService } from '@iso/services';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import $ from 'jquery';
import Loader from '@iso/components/utility/loader';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import { formTypes } from '@iso/constants/formTypes';
import moment from 'moment';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import "survey-react/modern.css";
import ViewSurveyResultModal from './ViewSurveyResultModal';
import Question from './Question';
import SignatureCanvas from 'react-signature-canvas';
import { surveyQuestionTypes } from '@iso/constants/surveyQuestionTypes';
import {visitorTypes} from '@iso/constants/visitorTypes';
import QRCode from "react-qr-code";
import PreviewFormModal from './PreviewFormModal';
import FormBuilder from 'antd-form-builder';
import GlobalForm from './GlobalForm'
import FinalScreen from './FinalScreen'
import BioForm from '@iso/containers/Pages/AdminPages/FormBuilder/BioForm/BioForm';
import PreviewFormTemplate from './PreviewFormTemplate';
import CheckinForm from '@iso/containers/Pages/AdminPages/FormBuilder/CheckinForm/CheckinForm';
import HazardForm from '@iso/containers/Pages/AdminPages/FormBuilder/HazardForm/HazardForm';
import { Link } from 'react-router-dom';
import Table from '@iso/components/collapsing-table/table';
import VisitorModal from '@iso/containers/Pages/Visitor/VisitorModal';
import Box from "@iso/components/utility/box";
import CheckinUserProfileModal from "@iso/containers/Pages/Profile/CheckinUserProfileModal";
import { useForm } from 'antd/lib/form/Form';
import TrainingForm from '@iso/containers/Pages/AdminPages/FormBuilder/TrainingForm/TrainingForm';
import IllnessForm from '@iso/containers/Pages/AdminPages/FormBuilder/IllnessForm/IllnessForm';
import NearMissForm from '@iso/containers/Pages/AdminPages/FormBuilder/NearMissForm/NearMissForm';

const { Option, OptGroup } = Select;
const { Paragraph } = Typography;
Survey.StylesManager.applyTheme("modern");

const FormModal = (props) => {
  const padRef = useRef({});
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const currentUser = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [dataList, setDataList] = useState({});
  const [dataForm, setDataForm] = useState({});
  const [form] = Form.useForm();
  const permissions = useSelector(state => state.permission.permissions);
  const [showForm, setShowForm] = useState(false);
  const [fields, setFields] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [userProperty, setUserProperty] = useState([]);
  const [userChecked, setUserChecked] = useState(false);
  const [userInviteList, setUserInviteList] = useState([]);
  const [userFormList, setUserFormList] = useState([]);
  const [visitorFormList, setVisitorFormList] = useState([]);
  const [surveyDataSubmit, setSurveyDataSubmit] = useState({});
  const [visibleViewSurveyResult, setVisibleViewSurveyResult] = useState(false);
  const [surveyResult, setSurveyResult] = useState({});
  const [signatureUrl, setSignatureUrl] = useState("");
  const [surveyQuestions, setSurveyQuestions] = useState({});
  const [surveyContent, setSurveyContent] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [isShowQr, setIsShowQr] = useState(false);
  const [livedForms, setLivedForms] = useState([]);
  const [isVisiblePreviewModal, setIsVisiblePreviewModal] = useState(false);
  const [showFinalScreen, setShowFinalScreen] = useState(false)
  const [visibleVisitorModal, setVisibleVisitorModal] = React.useState(false);
  const [visitor, setVisitor] = React.useState({});
  const [visibleCheckinUserProfileModal, setVisibleCheckinUserProfileModal] = React.useState(false);
  const [checkinUserId, setCheckinUserId] = React.useState(null);

  const [selectedVisitorTypes, setSelectedVisitorTypes] = useState([]);
  const [visibility, setVisibility] = useState([]);
  const [surveyQuestionType, setSurveyQuestionType] = useState([]);
  const { TextArea } = Input;

  const [formFields, setFormFields] = useState({
      columns: 1,
      formItemLayout: null, 
      fields: []
  });
  const [globalForm, setGlobalForm] = useState([]);
  const [categoryGlobalForm, setCategoryGlobalForm] = useState({});
  const dateFormat = 'DD/MM/YYYY';
  const formRef = React.createRef()
  const [systemForm] = Form.useForm()

  useEffect(() => {
    if(props.propertyId, dataForm.id){
      surveyQuestionService.getList(dataForm.id).then(res => {
        if(res.code === '0000'){
          let questions = [];
          _.forEach(res.survey_questions, (question, index) => {
            switch(question.question_type){
              case 'yes/no':
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "radiogroup",
                      choices: ["Yes", "No"],
                      isRequired: question.meta_data.response_required,
                      name: (question.id).toString(),
                      title: question.title
                    }
                  ]
                };
                break;
              case 'text':
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "text",
                      isRequired: question.meta_data.response_required,
                      name: (question.id).toString(),
                      title: question.title
                    }
                  ]
                };
                break;
              case 'multiple_choice':
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "radiogroup",
                      choices: question.options,
                      isRequired: question.meta_data.response_required,
                      name: (question.id).toString(),
                      title: question.title
                    }
                  ]
                };
                break;
              case 'signature':
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "signaturepad",
                      isRequired: question.meta_data.response_required,
                      name: (question.id).toString(),
                      title: question.title
                    }
                  ]
                };
                break;
              case 'multi_selection':
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "checkbox",
                      choices: question.options,
                      isRequired: question.meta_data.response_required,
                      name: (question.id).toString(),
                      title: question.title
                    }
                  ]
                };
                break;
              case 'number':
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "number",
                      isRequired: question.meta_data.response_required,
                      name: (question.id).toString(),
                      title: question.title
                    }
                  ]
                };
                break;
              case 'scale':
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "radiogroup",
                      choices: ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"],
                      isRequired: question.meta_data.response_required,
                      name: (question.id).toString(),
                      title: question.title
                    }
                  ]
                };
                break;
              default:
                var data = {
                  name: "page_" + question.id,
                  questions: [
                    {
                      type: "comment",
                      isRequired: question.meta_data.response_required,
                      name: (question.id).toString(),
                      title: question.title
                    }
                  ]
                };
                break;
            }

            questions.push(data);
          });
          setQuestionList(questions);
        }
      });
    }    
    
  },[props.propertyId, dataForm])

  useEffect(() => {
    if(questionList && dataForm)
    {
      var surveyJSON = {
        title: dataForm.survey_title,
        description: dataForm.survey_description,
        completedHtml: "<h3>Thank you for completing the survey!</h3> <h5>Please click Save to submit your survey!</h5>",
        pages: questionList
      }

      setSurveyContent(surveyJSON);
    } 
  }, [questionList, dataForm])

  const cancelModal = () => {
    props.cancelModal();
    form.resetFields();
    setUserInviteList([]);
    setSurveyDataSubmit({});
    setLoading(false);
  }

  const renderOptionsFormType = () => {
    let options = [];

    options.push(
      <OptGroup label="DIY (Do it yourself)">
        <Option value="pdf_upload">PDF Upload</Option>
        <Option value="self_create">Self Create</Option>
      </OptGroup>,
      <OptGroup label="DIFM (Do it for me) - Favourites">
        <Option value="bio_form">Livestock Biosecurity</Option>
        <Option value="hazard_form">Hazard Form</Option>
        <Option value="training_form">Training Form</Option>
        <Option value="illness_form">Injury / Illness Form</Option>
        <Option value="near_miss_form">Near Miss Form</Option>
      </OptGroup>,
    )

    if (livedForms.length > 0) {
      let globalForm = [];
      _.forEach(livedForms, (form, index) => {
        globalForm.push(
          <Option value={'system_forms-' + form.id}>{form.name}</Option>
        );
      })

      options.push(
        <OptGroup label="DIFM (Do it for me) - Global">
          {globalForm}
        </OptGroup>
      )
    }
    return (
      <Select
        placeholder="Select a form type"
        allowClear
        size="large"
        disabled={dataForm.id ? true : false}
        onChange={handleChangeLivedForm}
      >
        {options}
      </Select>
    );
  }

  const getLivedForms = () => {
    categoryGlobalFormService.getLivedForms().then(res => {
        if(res.code === '0000'){
          setLivedForms(res.category_global_form);
        }
    })
  }

  const handleChangeLivedForm = (value) => {
    if (value?.includes('system_forms')) {
      getFormData(value.split('-')[1])
    }
  }

  useEffect(() => {
    if(props.propertyId){
       userService.getUserByProperty(props.propertyId, currentUser.id).then(res => {
        if(res.code === '0000'){
          let result = [];
          _.forEach(res.user_property, (user, index) => {
            result.push({
                "label" : user.name + ' (' + user.email + ')',
                "value" : user.id
              }
            )
          });
          setUserProperty(result);
        }
      });
    }
  }, [props.propertyId]);

  useEffect(() => {
    if(dataForm){
      setShowFinalScreen(false);
       userFormService.getList(dataForm.id).then(res => {
        if(res.code === '0000'){
          setUserFormList(res.user_form);
          
          checkShowFinalScreen(res.user_form);
          // console.log('res.user_form',res.user_form);
      
        }
      });
      getVisitorForms()
    }
  }, [props.propertyId, dataForm]);

  const getVisitorForms = () => {
    if(dataForm){
      visitorFormService.getList(dataForm.id).then(res => {
        if(res.code === '0000'){
          setVisitorFormList(res.visitor_forms);
        }
      }); 
    }
  }

  const checkShowFinalScreen = (data) => {
    if(data.length > 0){
       _.forEach(data, (value, index) => {
        if(value.user_id == currentUser.id && value.global_form_submission){
          setShowFinalScreen(true);
        }
      });  
    }
  }

  const getFormData = (formId) => {
    categoryGlobalFormService.view(formId).then(res => {

      if(res.code === '0000'){
        // if(res.global_form.meta_fields) setFormFields(res.global_form.meta_fields);
        setGlobalForm(res.category_global_form.globalForms);
        setCategoryGlobalForm(res.category_global_form);
        // console.log(res.category_global_form.globalForms);
      }
    })
  }

  useEffect(() => {
    if(props.formData){
      setDataForm(props.formData);

      var fieldList = [
        {
          name: ['form', 'name'],
          value: dataForm.name || '',
        },
        {
          name: ['form', 'date'],
          value: dataForm.date ? moment(props.formData.date) : moment(),
        },
        {
          name: ['form', 'is_public'],
          value: dataForm.is_public,
        },
        {
          name: ['form', 'is_online'],
          value: dataForm.is_online ? true : false,
        },
        {
          name: ['form', 'is_share_all'],
          value: dataForm.is_share_all,
        },
        {
          name: ['form', 'survey_title'],
          value: dataForm.survey_title,
        },
        {
          name: ['form', 'survey_description'],
          value: dataForm.survey_description,
        },
        {
          name: ['form', 'category_global_form_id'],
          value: dataForm.category_global_form_id,
        },
        {
          name: ['form', 'is_agree'],
          value: dataForm.is_agree,
        },
        {
          name: ['form', 'is_checkin_form'],
          value: dataForm.is_checkin_form,
        },
        {
          name: ['form', 'meta_data', 'visitor_types'],
          value: dataForm.meta_data?.visitor_types,
        },
        {
          name: ['form', 'meta_data', 'is_hosts_enabled'],
          value: dataForm.meta_data?.is_hosts_enabled,
        },
        {
          name: ['form', 'meta_data', 'required_visitor_types_for_hosts'],
          value: dataForm.meta_data?.required_visitor_types_for_hosts,
        }
      ];

      if (dataForm.category_global_form_id) {
        fieldList.push(
          {
            name: ['form', 'type'],
            value: dataForm.type + '-' + dataForm.category_global_form_id || '',
          }
        )

        getFormData(dataForm.category_global_form_id)
      } else {
        fieldList.push(
          {
            name: ['form', 'type'],
            value: dataForm.type || '',
          }
        )
      }

      setFields(fieldList);
        
      if(dataForm.is_public == 1) setIsShowQr(true)
      if(dataForm.is_public == 0) setIsShowQr(false)

      setUserChecked(dataForm.is_share_all);
      setUserInviteList(dataForm.viewable_users);


    }
    getLivedForms()
  }, [props.propertyId, props.visibleFormModal, dataForm]);

  const handleSubmit = (e) => {
    // setLoading(true);

    form
      .validateFields()
      .then(values => {
        // console.log('values',values);
        setModalLoading(true);
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        // setLoading(false);
      });
  }

  const onStore = (values) => {
    let users_invited = [];

    if (values?.form?.type?.includes('system_forms')) {
      values.form.category_global_form_id = parseInt(values.form.type.split('-')[1]);
      values.form.type = 'system_forms';
    }
    const data = {
      form: {
        ...values.form,
        date: values.form.date ? dateHelper.dateForAPI(values.form.date) : '',
        property_id: props.propertyId,
        file_id: fileId,
        is_public: values.form.is_public && values.form.is_public == true ? 1 : 0,
        is_share_all: values.form.is_share_all ? 1 : 0,
        user_invited: userInviteList,
        survey: surveyDataSubmit,
        signature_path: padRef.current && Object.keys(padRef.current).length === 0 && padRef.current.constructor === Object ? '' : padRef.current.toDataURL('image/png')
      }
    }

    formService.storeOrUpdate(data, dataForm.id).then(res => {
      if(res.code === '0000'){
        form.resetFields();
        cancelModal();

        if(!props.fromProperty){
          props.updateFormData();
        }
        setSurveyDataSubmit({});
        setLoading(false);
      }else{
        setLoading(false);
      }
    });
  }

  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      if (file.type !== 'application/pdf') {
        message.error(`${file.name} is not a pdf file`);
        return false;
      }    

      let $oldOne = $('.anticon-delete')[0];
      
      setTimeout(()=>{
        if($oldOne)
          $($oldOne).trigger('click');
      },100);
    },
    onChange: (response) => {
      if(response.file && response.file.status == "done"){
        setFileId(response.file.response.image.id);
      }
    },    
    listType: "text",
    multiple: false
  }

  const onChangeCheckBox = e => {
    setUserChecked(e.target.checked);

    if(e.target.checked)
    {
      let result = [];

      _.forEach(userProperty, (user, index) => {
        result.push(user.value);
      });

      setUserInviteList(result);
    }
  }

  const onUserListChange = checkedValues => {
    setUserInviteList(checkedValues);
  }

  const submitSurveyData = (survey) => {
    setSurveyDataSubmit(survey.data);
  }

  const showViewSurveyResult = (item) => {
    setSurveyResult(item);
    setVisibleViewSurveyResult(true);
  }

  const cancelViewSurveyResult = () => {
    setSurveyResult({});
    setVisibleViewSurveyResult(false);
  }

  const handleClear = () => {
    padRef.current.clear();
  }

  const renderOptionsSurveyQuestion = (questionIndex) => {
    let options = [];
    _.forEach(surveyQuestionTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a question type"
        allowClear
        size="large"
        onChange={(value) => onChangeSurveyQuestionType(value, questionIndex)}
      >
        {options}
      </Select>
    );
  }

  const onChangeSurveyQuestionType = (value, questionIndex) => {
    const updatedSurveyTypes = [...surveyQuestionType];
    updatedSurveyTypes[questionIndex] = value;
    setSurveyQuestionType(updatedSurveyTypes);
  };

  const changeFormMode = (value) => {
    setIsShowQr(value);
  }

  const showPreviewModal = () => {
    setIsVisiblePreviewModal(true);
  }

  const cancelPreviewModal = () => {
    setIsVisiblePreviewModal(false);
  }
  const getDataSource = () => {
    return userFormList.concat(visitorFormList)
  } 

  const handleMenuClick = (userForm, e) => {
    if (e.key === 'viewResult') {
      showViewSurveyResult(userForm);
    }
    if (e.key === 'viewVisitor') {
      setVisitor(userForm.visitor_info)
      setVisibleVisitorModal(true);
    }
    if (e.key === 'viewProfile') {
      setVisibleCheckinUserProfileModal(true)
      setCheckinUserId(userForm.user_id);
    }
    if (e.key === 'requestShareProfile') {
      profileService.requestShareProfile(userForm.form_id, userForm.user_id).then((res)=>{
        if(res.code === '0000'){
          message.success(res.message)
        }else{
          message.error(res.message)
        }
      })
    }
  }
  const menu = (userForm) => (
    <Menu onClick={handleMenuClick.bind(this, userForm)}>
      {(userForm.is_investigated || userForm.visitor_id) &&
        [
          "self_create", 
          "system_forms", 
          "bio_form", 
          "checkin_form", 
          "hazard_form", 
          "training_form", 
          "illness_form",
          "near_miss_form"
        ]?.includes(userForm.form.type) &&
        (
          <Menu.Item key="viewResult" icon={<EditOutlined />}>
            <IntlMessages id="formPage.modal.table.action.viewResult" />
          </Menu.Item>
        )}
      {userForm.visitor_info && (
        <Menu.Item key="viewVisitor" icon={<UserOutlined />}>
          <IntlMessages id="formPage.modal.table.action.viewVisitor" />
        </Menu.Item>
      )}
      {(userForm.user_info && userForm.user_info.share_with_property_owner) ? (
        <Menu.Item key="viewProfile" icon={<UserOutlined />}>
          <IntlMessages id="formPage.modal.table.action.viewProfile" />
        </Menu.Item>
      ) : <></>}
      {userForm.user_info && !userForm.user_info.share_with_property_owner ? (
        <Menu.Item key="requestShareProfile" icon={<SendOutlined />}>
          <IntlMessages id="formPage.modal.table.action.requestToSeeProfile" />
        </Menu.Item>
      ) : <></>}
    </Menu>
  );

  const columns = [
    {
      label: <IntlMessages id="formPage.modal.table.name" />,
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let row = cell.row;
        return (
          <Col>
            <b>{row.user ? row.user : row.visitor}</b> -
            <i>
              [
              {(row.is_investigated || row.visitor_id) &&
              row.form.type === "self_create"
                ? "Completed the survey"
                : "Agreed"}{" "}
              at {row.user ? row.agree_date : row.created_at}]
            </i>
          </Col>
        );
      },
    },
    {
      label: <IntlMessages id="formPage.modal.table.type" />,
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: (cell) => {
        let row = cell.row;
        return row.visitor ? 'Visitor' : 'Registered User'
      },
    },
    {
      label: <IntlMessages id="formPage.modal.table.signature" />,
      sortable: false,
      position: 3,
      priorityLevel: 3,
      CustomComponent: (cell) => {
        let row = cell.row;
        if (row.signature_path) {
          return (
            <>
              <p>Signed</p>
              <img
                className="signature-image"
                src={process.env.REACT_APP_API_BASE_URL + row.signature_path}
              />
            </>
          );
        }
        return <></>
      },
    },
    {
      label: <IntlMessages id="formPage.modal.table.action" />,
      sortable: false,
      position: 4,
      priorityLevel: 4,
      CustomComponent: (cell) => {
        let row = cell.row;
        return (
          <Dropdown overlay={menu(row)} trigger={["click"]}>
            <Button className="btn-success ant-btn-primary margin-right-0 margin-bottom-5">
              <IntlMessages id="antTable.title.actions" /> <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const canceVisitorlModal = () => {
    setVisibleVisitorModal(false);
    setVisitor({});
  };

  const cancelCheckinUserProfileModal = () => {
    setVisibleCheckinUserProfileModal(false)
    setCheckinUserId(null)
  }

  const renderPreviewForm = (formType) => {
    let template = <></>;
    switch(formType){
      case 'bio_form':
        template = BioForm
        break
      case 'checkin_form':
        template = CheckinForm
        break
      case 'hazard_form':
        template = HazardForm
        break
      case 'training_form':
        template = TrainingForm
        break
      case 'illness_form':
        template = IllnessForm
        break
      case 'near_miss_form':
        template = NearMissForm
        break  
      default:
        template = <></>
        break
    }
    return (
      <Row>
        <Col className="btn-view-form">
          <Button className="action-button btn-view-bio-form" onClick={showPreviewModal}>View Form</Button>
        </Col>
        <PreviewFormTemplate
          isVisiblePreviewModal={isVisiblePreviewModal}
          cancelPreviewModal={cancelPreviewModal}
          type={formType}
          template={template}
          propertyId={activePropertyId}
        />
      </Row>
    )
  }
  const renderOptionVisitorTypes = () => (
    <Select
      mode="multiple"
      placeholder="Select a visitor type"
      allowClear
      onChange={onVisitorTypeChange}
      size="large"
    >
      {visitorTypes.map((type, index) => (
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      ))}
    </Select>
  );

  const onVisitorTypeChange = (selectedValues = []) => {
    const selectedTypes = visitorTypes.filter((type) =>
      selectedValues.includes(type.value)
    );
    setSelectedVisitorTypes(selectedTypes);
  };

  const renderVisibilityOptions = useCallback( (questionIndex) => {
    const selectedVisibility = visibility[questionIndex] || [];
    return (
      <Select
        placeholder="Select Visibility"
        allowClear
        mode="multiple"
        value={selectedVisibility}
        onChange={(value) => onVisibilityChange(value, questionIndex)}
        size="large"
      >
        {selectedVisitorTypes.map((type, index) => (
          <Option key={index} value={type.value}>
            <div>{type.label}</div>
          </Option>
        ))}
      </Select>
    );
  },[visibility, selectedVisitorTypes]);

  const renderOptionVisitorTypesForHosts = useCallback(() => {
    return (
      <Select
        mode="multiple"
        placeholder="Select a visitor type"
        allowClear
        size="large"
      >
        {selectedVisitorTypes.map((type, index) => (
          <Option key={index} value={type.value}>
            <div>{type.label}</div>
          </Option>
        ))}
      </Select>
    )
  }, [selectedVisitorTypes]);

  const onVisibilityChange = (value, questionIndex) => {
    const updatedVisibility = [...visibility];
    updatedVisibility[questionIndex] = value;
    setVisibility(updatedVisibility);
  };

  const handleRemove = (remove, questionIndex) => {
    setSurveyQuestionType((prevTypes) => {
      const updatedTypes = [...prevTypes];
      updatedTypes.splice(questionIndex, 1); 
      return updatedTypes;
    });
    setVisibility((prevVisibilities) => {
      const updatedVisibilities = [...prevVisibilities];
      updatedVisibilities.splice(questionIndex, 1);
      return updatedVisibilities;
    });
    remove(questionIndex);
  };

  const renderRuleYesNo = () => {
    return (
      <Select
        showSearch
        placeholder="Select"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
          { value: 'either', label: 'Either answer is valid' },
          { value: 'no', label: 'Visitor must answer "No"' },
          { value: 'yes', label: 'Visitor must answer "Yes"' },
        ]}
      />
    )
  };

  const renderResponseConditionsFields = (name, fieldKey, restField) => {
    return (
      <>
        <Form.Item
          {...restField}
          name={[name, 'meta_data', 'response_required']}
          fieldKey={[fieldKey, 'meta_data', 'response_required']}
          valuePropName="checked"
        >
          <Checkbox>{<IntlMessages id="formPage.modal.responseRequired" />}</Checkbox>
        </Form.Item>

        <Form.Item
          {...restField}
          name={[name, 'meta_data', 'prefill_response']}
          fieldKey={[fieldKey, 'meta_data', 'prefill_response']}
          valuePropName="checked"
        >
          <Checkbox>{<IntlMessages id="formPage.modal.prefillResponse" />}</Checkbox>
        </Form.Item>
      </>
    )
  };

  const uploadImgProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      //
    },
    onChange: (response,) => {
      //
    },
    listType: "picture",
    multiple: false,
  };

  const renderQuestionFields = (questionType, name, fieldKey, restField) => {
    console.log(questionType, name, fieldKey, restField);

    switch (questionType) {
      case 'yes/no':
        return (
         <>
          <Form.Item
            {...restField}
            name={[name, 'meta_data', 'rule']}
            fieldKey={[fieldKey, 'meta_data', 'rule']}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            {renderRuleYesNo()}
          </Form.Item>
         </>
        );
      case 'multi_selection':
      case 'multiple_choice':
        return (
          <Form.Item
            {...restField}
            name={[name, 'meta_data', 'options']}
            fieldKey={[fieldKey, 'meta_data', 'options']}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Select
              mode="tags"
              style={{
                width: '100%',
              }}
              placeholder="Enter options"
            />
          </Form.Item>
        );
      case 'fixed_text':
        return (
          <Form.Item
            {...restField}
            name={[name, 'meta_data', 'text']}
            fieldKey={[fieldKey, 'meta_data', 'text']}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.TextArea autoSize rows={4} placeholder="Enter text" />           
          </Form.Item>
        )
      case 'fixed_image':
        return (
          <Form.Item
            {...restField}
            label="Image"
            name={[name, 'meta_data', "image_id"]}
            fieldKey={[fieldKey, 'meta_data', "image_id"]}
            getValueFromEvent={(e) => e.file.response?.image.id}
            rules={[
              {
                required: true,
                message:
                  "This field is required",
              },
            ]}
          >
            <Upload {...uploadImgProps} >
              <Button
                className="action-button"
                icon={<UploadOutlined />}
              >
                Upload
              </Button>
            </Upload>
          </Form.Item>
        );
      case 'signature':
      case 'number':
      case 'text':
      default:
        return <></>;
    }
  };

  const renderFormTemplate = (formType) => {
    switch(formType){
      case 'self_create':
        return !dataForm.is_investigated ? (
          <>
            <Form.Item
              name={['form', 'survey']}
              className="isoInputWrapper"
            >
              <Survey.Survey json={surveyContent} onComplete={submitSurveyData}/>
            </Form.Item>
            <hr/>
          </>
        ) : (<></>)
      case 'pdf_upload':
        return (
          <>
            <Form.Item
              name={['form', 'is_agree']}
              className="isoInputWrapper"
              valuePropName="checked"
            >
              <Checkbox>{<IntlMessages id="formPage.modal.agreeConfirm" />}</Checkbox>
              
            </Form.Item>
            <h3>{dataForm.signature_path ? <IntlMessages id="formPage.modal.savedSignature" /> : <IntlMessages id="formPage.modal.signature" />}</h3>
            {
              dataForm.signature_path ? <img src={process.env.REACT_APP_API_BASE_URL + dataForm.signature_path} /> :     
              <div className="signature-area">
                <SignatureCanvas ref={padRef} />
                <button onClick={handleClear} className="signature-clear-button">Clear</button>
              </div>
            }
          </>
        )
      case 'system_forms':
        return !showFinalScreen ? (
          <Form.Item
          name={['form', 'global_form']}
          className="isoInputWrapper"
          >
            <GlobalForm formData={dataForm} setShowFinalScreen={setShowFinalScreen} visibleFormModal={props.visibleFormModal}/>
          </Form.Item>
        ) : <></>
      case 'bio_form':
        return !showFinalScreen ? (
          <BioForm formData={dataForm} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        ) : <></>
      case 'hazard_form':
        return !showFinalScreen ? (
          <HazardForm propertyId={activePropertyId} formData={dataForm} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        ) : <></>
      case 'training_form':
        return !showFinalScreen ? (
          <TrainingForm propertyId={activePropertyId} formData={dataForm} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        ) : <></>
      case 'illness_form':
        return !showFinalScreen ? (
          <IllnessForm propertyId={activePropertyId} formData={dataForm} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        ) : <></>
      case 'near_miss_form':
        return !showFinalScreen ? (
          <NearMissForm propertyId={activePropertyId} formData={dataForm} setShowFinalScreen={setShowFinalScreen} isAbleToSubmit={true}/>
        ) : <></>
      default:
        return <></>
    }
  }

  return (
    <FormModalWrapper
      className="user-access-modal"
      forceRender={true}
      maskClosable={false}
      visible={props.visibleFormModal}
      title={!dataForm.id ? <IntlMessages id="formPage.modal.addForm" /> : <IntlMessages id="formPage.modal.editForm" />}
      onCancel={cancelModal}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (dataForm.is_public == 1 || currentUser.id == dataForm.user_id || !dataForm.id) && <Button key="submit" className="btn-success" type="primary" loading={loading} onClick={handleSubmit}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
        className="form-body"
      >
        <Row>
          <Col xs={{span: 24}} md={{span: currentUser.id == dataForm.user_id || !dataForm.id ? 14 : 24}}>
            <Form.Item
              name={['form', 'is_public']}
              className="isoInputWrapper"
              style={{float: "right"}}
              valuePropName="checked"
            >
              <Switch checkedChildren="Public" unCheckedChildren="Private" onChange={changeFormMode}
              disabled={(dataForm.is_public == 1 || currentUser.id == dataForm.user_id || !dataForm.id) ? false : true}/>
            </Form.Item>
            <br/>
            <br/>
            <hr/>
            <br/>
            <p>{<IntlMessages id="formPage.modal.instruction" />}</p>
            <br/>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['form', 'name']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="formPage.modal.formName" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                 <Input size="large" placeholder="Input form name" 
                 disabled={(dataForm.is_public == 1 || currentUser.id == dataForm.user_id || !dataForm.id) ? false : true}/>
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['form', 'date']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="Creation Date" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                     <DatePicker size="large" style={{ width: '100%' }} format={dateFormat}
                  disabled={(dataForm.is_public == 1 || currentUser.id == dataForm.user_id || !dataForm.id) ? false : true} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['form', 'type']}
                  className="isoInputWrapper"
                  label={<IntlMessages id="formPage.modal.formType" />}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionsFormType()}
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    return prevValues['form'];
                  }}
                >
                  {({ getFieldValue }) => {
                    if(getFieldValue('form')){
                      if (getFieldValue('form').type === "pdf_upload"){
                        return (
                          <><Form.Item name={['attachment']} label="Attachment">
                            <Upload
                              {...uploadProps}
                            >
                              <Button className="action-button" icon={<UploadOutlined />} 
                              disabled={(dataForm.is_public == 1 || currentUser.id == dataForm.user_id || !dataForm.id) ? false : true}>Upload PDF file</Button>
                            </Upload>
                          </Form.Item>
                          {
                            dataForm.files && dataForm.files.length > 0 && <a target="_blank" href={process.env.REACT_APP_API_BASE_URL + dataForm.files[dataForm.files.length-1].path}>{process.env.REACT_APP_API_BASE_URL + dataForm.files[dataForm.files.length-1].path}</a>
                          } 
                          </>  
                        )
                      }else if (getFieldValue('form')?.type?.includes('system_forms')){
                        return (
                          <Row>
                            <Col className="btn-view-form">
                              <Button className="action-button btn-view-bio-form" onClick={showPreviewModal}>View Form</Button>
                            </Col>
                            <PreviewFormModal isVisiblePreviewModal={isVisiblePreviewModal} cancelPreviewModal={cancelPreviewModal} 
                              globalForm={globalForm} readOnly={true} categoryGlobalForm={categoryGlobalForm} />
                          </Row>
                        )
                      }else if ([
                        "bio_form", 
                        "checkin_form", 
                        "hazard_form", 
                        "training_form", 
                        "illness_form", 
                        "near_miss_form"
                      ]?.includes(getFieldValue('form').type)){
                        return renderPreviewForm(getFieldValue('form').type)
                      }
                    }
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['form', 'is_checkin_form']}
                  className="isoInputWrapper"
                  valuePropName="checked"
                >
                  <Checkbox
                      disabled={
                        dataForm.is_public == 1 ||
                        currentUser.id == dataForm.user_id ||
                        !dataForm.id
                        ? false
                        : true}
                    >
                      <IntlMessages id="formPage.modal.isCheckinForm" />
                    </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['form', 'is_online']}
                  className="isoInputWrapper"
                  valuePropName="checked"
                >
                  <Switch checkedChildren="Online" unCheckedChildren="Offline"/>
                </Form.Item>
              </Col>
            </Row>
            {((currentUser.id == dataForm.user_id && dataForm.type === 'self_create' && dataForm) || !dataForm.id) && <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues['form'];
              }}
            >
              {({ getFieldValue }) => {
                if(getFieldValue('form')){
                  if(getFieldValue('form').type === "self_create"){
                    return (
                      <>
                      { getFieldValue('form').is_checkin_form && <Form.Item
                          name={['form', 'meta_data', 'visitor_types']}
                          className="isoInputWrapper"
                          label={<IntlMessages id="formPage.modal.visitorTypes" />}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                            {renderOptionVisitorTypes()}
                        </Form.Item>
                      }
                      {
                        getFieldValue('form').is_checkin_form && <>
                          <Form.Item
                            name={['form', 'meta_data', 'is_hosts_enabled']}
                            className="isoInputWrapper"
                            valuePropName="checked"
                          >
                            <Checkbox>
                                <IntlMessages id="formPage.modal.enableHosts" />
                              </Checkbox>
                          </Form.Item>

                          <Form.Item
                            name={['form', 'meta_data', 'required_visitor_types_for_hosts']}
                            className="isoInputWrapper"
                            label={<IntlMessages id="formPage.modal.hostSelectionRequired" />}
                            rules={[]}
                          >
                              {renderOptionVisitorTypesForHosts()}
                          </Form.Item>
                        </>
                      }
                      <Form.Item 
                        name={['form', 'survey_title']}
                        className="isoInputWrapper" 
                        label={<IntlMessages id="formPage.modal.surveyTitle" />}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Input survey title" />
                      </Form.Item>
                      <Form.Item 
                        name={['form', 'survey_description']} 
                        className="isoInputWrapper"
                        label={<IntlMessages id="formPage.modal.surveyDescription" />}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                         <Input.TextArea />
                      </Form.Item>
                      {!dataForm.id ? <Form.List name={['form', 'survey_question']} label={<IntlMessages id="formPage.modal.surveyQuestions" />}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                              <>
                                <Card 
                                  title={`Question ${name+1}`}
                                  extra={<MinusCircleOutlined onClick={() => handleRemove(remove, name)} />}
                                >
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'title']}
                                      fieldKey={[fieldKey, 'title']}
                                      rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                      <Input.TextArea placeholder="Title" />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'question_type']}
                                      fieldKey={[fieldKey, 'question_type']}
                                      rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                      {renderOptionsSurveyQuestion(name)}
                                    </Form.Item>
                                    {
                                      surveyQuestionType[name] && (
                                        renderQuestionFields(surveyQuestionType[name], name, fieldKey, restField)
                                      )
                                    }

                                    { renderResponseConditionsFields(name, fieldKey, restField) }

                                    <Form.Item
                                      {...restField}
                                      name={[name, 'meta_data', 'question_visibility']}
                                      fieldKey={[fieldKey, 'meta_data', 'meta_data']}
                                      rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                      {renderVisibilityOptions(name)}
                                    </Form.Item>
                                </Card>
                              </>
                            ))}
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Question
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List> : <Question formId={dataForm.id} />}
                      </>
                    )
                  }
                }
              }}
            </Form.Item>}
            <br/>
            {(currentUser.id != dataForm.user_id && dataForm.id) &&
             renderFormTemplate(dataForm.type)
            }
            {(currentUser.id != dataForm.user_id && dataForm.id && !(['self_create', 'pdf_upload']?.includes(dataForm.type)) && showFinalScreen)
              &&
              <FinalScreen />
            }
            {currentUser.id == dataForm.user_id && <>
                <b><IntlMessages id="formPage.modal.userAgreeListTitle" /></b>
                <Box id="containerBoxResultList" style={{ height: "200px", overflow: "auto" }}>
                <Table 
                  className="result-list-table"
                  columns={columns} 
                  rows={getDataSource()}
                  containerId="containerBoxResultList"
                  showPagination={false}
                  rowSize={99999}
                />
                </Box>
                <ViewSurveyResultModal visibleViewSurveyResult={visibleViewSurveyResult} cancelViewSurveyResult={cancelViewSurveyResult} surveyData={surveyResult} type={surveyResult?.form?.type}/>
              <hr/></>
            }
          </Col>
          {(currentUser.id == dataForm.user_id || !dataForm.id) && 
          <Col xs={{span: 24}} md={{span: 9, offset: 1}}>
            <h3>{<IntlMessages id="formPage.modal.userShare" />}</h3>
            <Form.Item
              name={['form', 'is_share_all']}
              className="isoInputWrapper"
              valuePropName="checked"
            >
              <Checkbox onChange={onChangeCheckBox} 
              disabled={(dataForm.is_public == 1 || currentUser.id == dataForm.user_id || !dataForm.id) ? false : true}>{<IntlMessages id="formPage.modal.shareAll" />}</Checkbox>
            </Form.Item>
            <br/>
            <Form.Item
              name={['form', '']}
              className="isoInputWrapper"
            >
              <div style={{ overflow: "scroll", width: "100%", height: "200px" }}>
                <Checkbox.Group options={userProperty} disabled={userChecked} onChange={onUserListChange} value={userInviteList} />
              </div>
            </Form.Item>
            <br/>
            <h3>{<IntlMessages id="formPage.modal.userInvite" />}</h3>
            <br/>
            <Form.Item
              name={['form', 'user_email']}
              className="isoInputWrapper"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid Email',
                },
                () => ({
                  validator(rule, value) {
                    if(value)
                      return userService.checkEmailExistsInProperty(value, props.propertyId);
                    else
                      return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className="pac-target-input" size="large" placeholder="Input user email"/>
            </Form.Item>
            {dataForm.id && dataForm.public_form_url && isShowQr && <>
            <br/>
            <h3>{<IntlMessages id="formPage.modal.qrCodeTitle" />}</h3>
            <br/>
            <div className="form-qr-area">
              <QRCode value={dataForm.public_form_url} />
              <Paragraph copyable>{dataForm.public_form_url}</Paragraph>
            </div>
            </>
            }
          </Col>
        }
        
        </Row>

      </Form>
      <VisitorModal
        visibleVisitorModal={visibleVisitorModal}
        cancelModal={canceVisitorlModal}
        propertyId={activePropertyId}
        editedVisitor={visitor}
        updateVisitorData={getVisitorForms}
      />
      {
        visibleCheckinUserProfileModal && 
          <CheckinUserProfileModal
            formId={props.formData ? props.formData.id : null}
            userId={checkinUserId}
            isVisible={visibleCheckinUserProfileModal}
            cancelModal={cancelCheckinUserProfileModal}
          />
      }
    </FormModalWrapper>
  );
}

export default FormModal;
