import L from 'leaflet';
import { objectColors } from '@iso/constants/objectColors';
import aircraft from './AssetIcons/airagri-aircraft.png';
import machinery from './AssetIcons/airagri-machinery.png';
import shed from './AssetIcons/airagri_shed.png';
import fuelshed from './AssetIcons/fuel_shed.png';
import homestead from './AssetIcons/homestead.png';
import hayshed from './AssetIcons/hay_shed.png';
import storage from './AssetIcons/airagri_storage.png';
import water from './AssetIcons/airagri_water.png';
import damIcon from './AssetIcons/dam_icon.png';
import tankIcon from './AssetIcons/tank_icon.png';
import troughIcon from './AssetIcons/trough_icon.png';
import windmillIcon from './AssetIcons/windmill_icon.png';
import boreIcon from './AssetIcons/bore_icon.png';
import weather from './AssetIcons/airagri_weather.png';
import chicken from './AssetIcons/AnimalIcons/airagri_chicken.png';
import cow from './AssetIcons/AnimalIcons/airagri_cow.png';
import dog from './AssetIcons/AnimalIcons/airagri_dog.png';
import horse from './AssetIcons/AnimalIcons/airagri_horse.png';
import pig from './AssetIcons/AnimalIcons/airagri_pig.png';
import sheep from './AssetIcons/AnimalIcons/airagri_sheep.png';
import bull from './AssetIcons/AnimalIcons/airagri_bull.png';
import other_animal from './AssetIcons/AnimalIcons/airagri_otheranimal.png';
import chemical from './AssetIcons/InventoryIcons/airagri-chemical.png';
import fertiliser from './AssetIcons/InventoryIcons/airagri-fertiliser.png';
import fuel from './AssetIcons/InventoryIcons/airagri-fuel.png';
import hay from './AssetIcons/InventoryIcons/airagri-hay.png';
import vaccine from './AssetIcons/InventoryIcons/airagri-vaccine.png';
import inventory_water from './AssetIcons/InventoryIcons/airagri-water.png';
import inventory_other from './AssetIcons/InventoryIcons/AirAgriFlags_Inventory_Other.png';
import evacuationPoint from './AssetIcons/healthSafetyIcons/evacuationPoint.png';
import fireExtinguisher from './AssetIcons/healthSafetyIcons/fireExtinguisher.png';
import firstAidKit from './AssetIcons/healthSafetyIcons/firstAidKit.png';
import _ from 'lodash';

export const IconAsset = (category, tag_colour = null, animal_type, type) => {
  if(tag_colour){
    var asset_color = '';
    _.forEach(objectColors, (color_obj, index) => {
        if(color_obj.value === tag_colour){
          asset_color = color_obj.color;
        }
    })
  }
  var icon_url = '';

  switch(category){
    case 'inventory':
      switch(type){
        case 'animal_treatment':
          icon_url = other_animal;
          break;
        case 'fertiliser':
          icon_url = fertiliser;
          break;
        case 'stock_feed':
          icon_url = hay;
          break;
        case 'vaccine':
          icon_url = vaccine;
          break;
        case 'fuel':
          icon_url = fuel;
          break;
        case 'aircraft':
          icon_url = aircraft;
          break;
        case 'water':
          icon_url = inventory_water;
          break;
        case 'chemical':
          icon_url = chemical;
          break;
        case 'other':
          icon_url = inventory_other;
          break;
        default:
          icon_url = 'data:image/svg+xml;charset=UTF-8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242%22%20height%3D%2250%22%20viewBox%3D%220%200%2042%2050%22%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%3Cpath%20fill%3D%22%232c303e%22%20fill-opacity%3D%221%22%20d%3D%22M35.7812122%2C6.144%20L36.9372122%2C7.3%20C44.0342122%2C15.546%2043.6762122%2C27.995%2035.8642122%2C35.816%20L21.0001122%2C50.0004%20L6.14121223%2C35.816%20C-1.67278777%2C27.995%20-2.02978777%2C15.544%205.06921223%2C7.298%20L6.22421223%2C6.144%20C10.2652122%2C2.097%2015.5512122%2C0.049%2020.8512122%2C0.001%20L21.1292122%2C0%20C26.4292122%2C0.043%2031.7172122%2C2.085%2035.7632122%2C6.126%20L35.7812122%2C6.144%20Z%22%2F%3E%0A%20%20%3Ccircle%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2218%22%20fill%3D%22%23FFF%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
      }
      break;
    case 'machinery':
      icon_url = machinery;
      break;
    case 'property':
      icon_url = homestead
      break;
    case 'shed':
      switch (type){
        case 'fuel':
          icon_url = fuelshed
          break;
        case 'hay':
          icon_url = hayshed
          break;  
        default:
          icon_url = shed;
      }
      break;
    case 'fencing':
      icon_url = 'data:image/svg+xml;charset=UTF-8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242%22%20height%3D%2250%22%20viewBox%3D%220%200%2042%2050%22%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%3Cpath%20fill%3D%22%232c303e%22%20fill-opacity%3D%221%22%20d%3D%22M35.7812122%2C6.144%20L36.9372122%2C7.3%20C44.0342122%2C15.546%2043.6762122%2C27.995%2035.8642122%2C35.816%20L21.0001122%2C50.0004%20L6.14121223%2C35.816%20C-1.67278777%2C27.995%20-2.02978777%2C15.544%205.06921223%2C7.298%20L6.22421223%2C6.144%20C10.2652122%2C2.097%2015.5512122%2C0.049%2020.8512122%2C0.001%20L21.1292122%2C0%20C26.4292122%2C0.043%2031.7172122%2C2.085%2035.7632122%2C6.126%20L35.7812122%2C6.144%20Z%22%2F%3E%0A%20%20%3Ccircle%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2218%22%20fill%3D%22%23FFF%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22%232C303E%22%20d%3D%22M18%2C16%20L18.6%2C19%20L31%2C19%20L30%2C25%20L12%2C25%20L12%2C18%20L11.5%2C18%20C11.2545401%2C18%2011.0503916%2C17.8231248%2011.0080557%2C17.5898756%20L11%2C17.5%20C11%2C17.2238576%2011.2238576%2C17%2011.5%2C17%20L11.5%2C17%20L12%2C17%20L12%2C16%20L18%2C16%20Z%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
      break;
    case 'storage':
      icon_url = storage;
      break;
    case 'aircraft':
      icon_url = aircraft;
      break;
    case 'water':
      if(type === 'dam'){
        icon_url = damIcon
      }else if(type === 'tank'){
        icon_url = tankIcon
      }else if(type === 'trough'){
        icon_url = troughIcon
      }else if(type === 'bore'){
        icon_url = boreIcon
      }
      else if(type === 'windmill'){
        icon_url = windmillIcon
      }
      else{
        icon_url = water;
      }
      break;
    case 'animal':
      switch(animal_type){
        case 'ai':
          icon_url = other_animal;
          break;
        case 'horse':
          icon_url = horse;
          break;
        case 'dog':
          icon_url = dog;
          break;
        case 'bull':
          icon_url = bull;
          break;
        case 'ram':
          icon_url = sheep;
          break;
        case 'pig':
          icon_url = pig;
          break;
        default:
          icon_url = other_animal;
      }
      break;
    case 'weather':
      icon_url = weather;
      break;
    case 'health_and_safety':
      switch(type){
        case 'evacuation_point':
          icon_url = evacuationPoint;
          break;
        case 'fire_extinguisher':
          icon_url = fireExtinguisher;
          break;
        case 'first_aid_kit':
          icon_url = firstAidKit;
          break;
        default:
          icon_url = 'data:image/svg+xml;charset=UTF-8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242%22%20height%3D%2250%22%20viewBox%3D%220%200%2042%2050%22%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%3Cpath%20fill%3D%22%232c303e%22%20fill-opacity%3D%221%22%20d%3D%22M35.7812122%2C6.144%20L36.9372122%2C7.3%20C44.0342122%2C15.546%2043.6762122%2C27.995%2035.8642122%2C35.816%20L21.0001122%2C50.0004%20L6.14121223%2C35.816%20C-1.67278777%2C27.995%20-2.02978777%2C15.544%205.06921223%2C7.298%20L6.22421223%2C6.144%20C10.2652122%2C2.097%2015.5512122%2C0.049%2020.8512122%2C0.001%20L21.1292122%2C0%20C26.4292122%2C0.043%2031.7172122%2C2.085%2035.7632122%2C6.126%20L35.7812122%2C6.144%20Z%22%2F%3E%0A%20%20%3Ccircle%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2218%22%20fill%3D%22%23FFF%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
      }
      break;
    default:
      icon_url = 'data:image/svg+xml;charset=UTF-8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242%22%20height%3D%2250%22%20viewBox%3D%220%200%2042%2050%22%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%3Cpath%20fill%3D%22%232c303e%22%20fill-opacity%3D%221%22%20d%3D%22M35.7812122%2C6.144%20L36.9372122%2C7.3%20C44.0342122%2C15.546%2043.6762122%2C27.995%2035.8642122%2C35.816%20L21.0001122%2C50.0004%20L6.14121223%2C35.816%20C-1.67278777%2C27.995%20-2.02978777%2C15.544%205.06921223%2C7.298%20L6.22421223%2C6.144%20C10.2652122%2C2.097%2015.5512122%2C0.049%2020.8512122%2C0.001%20L21.1292122%2C0%20C26.4292122%2C0.043%2031.7172122%2C2.085%2035.7632122%2C6.126%20L35.7812122%2C6.144%20Z%22%2F%3E%0A%20%20%3Ccircle%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2218%22%20fill%3D%22%23FFF%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
  }

  var icon = new L.Icon({
    iconUrl: icon_url,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [44, 60],
    iconAnchor: [22, 60],
    className: `custom-icon-marker asset asset_category_${category}`
  });

  return icon;
};