import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const EditFieldModalWrapper = styled(Modal)`
  &.preview-form-modal{
    width: 1000px!important;
  }
  .preview-form-area {
    border: solid 1px gray;
    padding: 20px;
  }
  .btn-success{
      background-color: #0B2A1B!important;
      border-color: #0B2A1B!important;
  }
  .form-step{
    margin-bottom: 60px;
  }
  .form-footer{
    margin-top: 60px
  }
  .btn-back{
    margin-right: 10px
  }
  .form-type-switch{
    margin-bottom: 20px;
  }
  .advanced-form{
    .ant-form-item-label{
      label{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px
      }
    }
  }
`;

export default WithDirection(EditFieldModalWrapper);