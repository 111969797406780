export const hazardStatus = [
  {
    label: 'Open',
    value: 'open',
  },
  {
    label: 'To be confirmed',
    value: 'to_be_confirmed',
  },
  {
    label: 'Confirmed',
    value: 'confirmed',
  },
  {
    label: 'Currently being worked on',
    value: 'currently_being_worked_on',
  },
  {
    label: 'Resolved / Mitigated',
    value: 'resolved_mitigated',
  },
  {
    label: 'Need additional help',
    value: 'need_additional_help',
  },
  {
    label: 'Closed / Raised in error',
    value: 'closed_raised_in_error',
  },
  {
    label: 'Other',
    value: 'other',
  }
]