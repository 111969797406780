import axios from "axios";
import { apiEndpoint ,apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const device = {
  getList,
  storeOrUpdate
};

function getList(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.device}?property_id=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 ){
          return res.data;
        }
      })
  );
}
function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.device(id),
        { device: data },
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.device,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if (res && res.status === 200 && res.data.code === "0000") {
      return res.data;
    }
    return {};
  });
}