import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";

export const sensorTypeSettingService = {
  getList,
  storeOrUpdate,
};

function getList(sensor_id){
  return (
    axios
      .get(
        apiEndpoint.senserTypeSettings,
        {
          params: {
            sensor_id: sensor_id
          },
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}
function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.senserTypeSettings(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.senserTypeSettings,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}