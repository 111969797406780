import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "@iso/components/utility/intlMessages";
import SignUpStyleWrapper from "./SignUp.styles";
import { userService } from "@iso/services";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import queryString from "query-string";
import { SendOutlined } from "@ant-design/icons";
import Alert from "@iso/components/Feedback/Alert";
import logo from "@iso/assets/images/logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import AgencyStyleWrapper from "../Agency/Agency.styles";
import authAction from "@iso/redux/auth/actions";
import appAction from "@iso/redux/app/actions";
import userActions from "@iso/redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { handleShowRandomImage } from "@iso/constants/randomBackgrounds";

let images = handleShowRandomImage();
const { login } = authAction;
const { clearMenu } = appAction;
const { store } = userActions;

const SignUp = (props) => {
  const [form] = Form.useForm();
  const [showMsg, setShowMsg] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("abc11@yopmail.com");
  const [msgAlert, setMsgAlert] = React.useState("");
  const token = queryString.parse(window.location.search).token || "";
  const formToken = queryString.parse(window.location.search).form_token || "";
  const email = queryString.parse(window.location.search).email || "";
  let history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const sharingToken =
    queryString.parse(window.location.search).sharing_token || "";
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.property.agent);
  let appLogo =
    activeAgent && activeAgent.logos
      ? activeAgent.logos[activeAgent.logos.length - 1]
      : null;
  appLogo = appLogo ? appLogo.full_path : logo;

  useEffect(() => {
    setFields([
      {
        name: "email",
        value: decodeURIComponent(email),
      },
      {
        name: "is_read_agree_terms_conditions",
        value: true,
      },
      {
        name: "is_read_agree_terms_conditions",
        value: true,
      },
    ]);
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      user: values,
      token,
      sharing_token: sharingToken,
      form_token: formToken,
    };
    setUserEmail(values.email);
    userService.registers(data).then((res) => {
      if (res.code === "0000") {
        if (token) {
          history.push("/dashboard");
        } else if (sharingToken) {
          dispatch(login(res.access_token));
          dispatch(clearMenu());
          dispatch(store());
          history.push(`/share-map?sharing_token=${sharingToken}`);
        } else {
          setLoading(false);
          setShowMsg(true);
        }
      }
    });
  };

  const resendEmail = () => {
    userService.resendEmailConfirm(userEmail).then((res) => {
      if (res.code === "0000") {
        setMsgAlert("Your request has been sent");
      }
    });
    setTimeout(() => {
      setMsgAlert("");
    }, 3000);
  };

  return (
    <Row
      className="signInmain"
      style={{ padding: "12px", height: "100vh", Width: "100%" }}
    >
      <Col xl={16} className="signIncol">
        <img
          src={`${images}`}
          height="98%"
          width="100%"
          style={{ borderRadius: "12px" }}
        />
      </Col>
      <Col xl={8}>
        <AgencyStyleWrapper agent={activeAgent}>
          <SignUpStyleWrapper className={`isoSignUpPage`}>
            <div
              className={`isoSignUpContentWrapper ${showMsg ? `w-unset` : ``}`}
            >
              <div className={`isoSignUpContent`}>
                {showMsg ? (
                  <div className="showMsg">
                    <div className="icon-content">
                      <Link to="/signin">
                        <SendOutlined style={{ fontSize: "40px" }} />
                      </Link>
                    </div>
                    <div className="text-content">
                      <h2>We've sent an email to {userEmail}</h2>
                      <h2>
                        Click the confirmation link in that email to begin using
                        AirAgri.
                      </h2>
                      <div className="msg-helper">
                        {msgAlert && (
                          <Alert
                            message={msgAlert}
                            type="success"
                            style={{ marginBottom: "10px" }}
                          />
                        )}
                        <p>if you did not receive the email,</p>
                        <p className="link-resend" onClick={resendEmail}>
                          Resend another email
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="isoLogoWrapper">
                      <Link to="/dashboard">
                        <img src={appLogo} height="50" />
                      </Link>
                    </div>
                    <div className="isoSignUpForm">
                      <Row className="mainFromDiv">
                        <Col xl={24} className="fromCol">
                          <Form
                            form={form}
                            name="register"
                            onFinish={onFinish}
                            scrollToFirstError
                            fields={fields}
                          >
                            <Form.Item
                              name="name"
                              className="isoInputWrapper form-field"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Name!",
                                },
                                {
                                  whitespace: true,
                                  message: "No blank space",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                className="signin-placeholder"
                                placeholder="Name"
                              />
                            </Form.Item>

                            <Form.Item
                              name="email"
                              className="isoInputWrapper form-field"
                              style={email ? { pointerEvents: "none" } : {}}
                              rules={[
                                {
                                  type: "email",
                                  message: "The input is not valid Email!",
                                },
                                {
                                  required: true,
                                  message: "Please input your Email!",
                                },
                                () => ({
                                  validator(rule, value) {
                                    if (value)
                                      return userService.checkEmailExists(
                                        value
                                      );
                                    else return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input
                                size="large"
                                className="signin-placeholder"
                                placeholder="Email"
                              />
                            </Form.Item>

                            <Form.Item
                              name="password"
                              className="isoInputWrapper form-field"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Password!",
                                },
                                {
                                  min: 6,
                                  message:
                                    "Password must be minimum 6 characters.",
                                },
                              ]}
                            >
                              <Input
                                type="password"
                                className="signin-placeholder"
                                size="large"
                                placeholder="Password"
                              />
                            </Form.Item>

                            <Form.Item
                              name="mobile_phone"
                              className="isoInputWrapper form-field"
                            >
                              <Input
                                size="large"
                                className="signin-placeholder"
                                placeholder="Mobile Phone"
                              />
                            </Form.Item>
                            <Form.Item
                              name="is_read_agree_terms_conditions"
                              className="isoInputWrapper"
                              valuePropName="checked"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please accept the terms & conditions",
                                },
                                () => ({
                                  validator(rule, value) {
                                    if (value) return Promise.resolve();
                                    else
                                      return Promise.reject(
                                        "Please accept the terms & conditions"
                                      );
                                  },
                                }),
                              ]}
                            >
                              <Checkbox className="termCondition">
                                I have read and agree to the{" "}
                                <a
                                  id="term-and-condition"
                                  href="https://www.airagri.com.au/termsconditions/"
                                  title="terms and condtions"
                                >
                                  terms and condtions
                                </a>
                              </Checkbox>
                            </Form.Item>
                            <Form.Item
                              name="is_partners_specific"
                              className="isoInputWrapper"
                              valuePropName="checked"
                            >
                              <Checkbox className="termCondition">
                                I wish to opt in to receive offers from our
                                partners / specific to farming
                              </Checkbox>
                            </Form.Item>
                            {/* <Form.Item
                              name="recaptcha"
                              className="isoInputWrapper"
                              rules={[
                                {
                                  required: true,
                                  message: "Please verify reCaptcha",
                                },
                              ]}
                            >
                              <ReCAPTCHA
                                sitekey={
                                  process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_ID
                                }
                              />
                            </Form.Item> */}

                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="btn-success"
                                loading={loading}
                              >
                                <IntlMessages id="page.signUpButton" />
                              </Button>
                            </Form.Item>
                          
                            <div className="signInClass">
                            Already have an account? 
                              <Link style={{textDecoration: "underline"}}
                                to={`/signin?sharing_token=${sharingToken}`}
                              >
                              <IntlMessages id="Sign In" />
                              </Link>
                            </div>
                          </Form>
                        </Col>
                        {/* <Col className="continuMainDiv" span={10}>
                          <div className="spaceClass"></div>
                          <div className="butGroup">
                          <div className="continue">Or continue with</div>
                          <button className="continueBtn">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_5_192)">
                                <path
                                  d="M31.2521 16.2994C31.2521 14.9883 31.1457 14.0316 30.9155 13.0395H15.9449V18.9568H24.7323C24.5552 20.4274 23.5985 22.642 21.4725 24.1301L21.4427 24.3282L26.1761 27.9951L26.504 28.0279C29.5158 25.2463 31.2521 21.1537 31.2521 16.2994Z"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M15.9447 31.8902C20.2498 31.8902 23.864 30.4728 26.5039 28.028L21.4723 24.1302C20.1258 25.0692 18.3187 25.7247 15.9447 25.7247C11.7282 25.7247 8.14942 22.9433 6.87369 19.0988L6.6867 19.1146L1.76481 22.9237L1.70044 23.1027C4.32249 28.3113 9.70838 31.8902 15.9447 31.8902Z"
                                  fill="#34A853"
                                />
                                <path
                                  d="M6.87401 19.0987C6.5374 18.1066 6.3426 17.0435 6.3426 15.9451C6.3426 14.8466 6.5374 13.7837 6.85631 12.7915L6.84739 12.5803L1.86382 8.70996L1.70076 8.78752C0.620092 10.949 0 13.3762 0 15.9451C0 18.5141 0.620092 20.9412 1.70076 23.1026L6.87401 19.0987Z"
                                  fill="#FBBC05"
                                />
                                <path
                                  d="M15.9447 6.16537C18.9388 6.16537 20.9585 7.45869 22.1101 8.53948L26.6101 4.14571C23.8464 1.57679 20.2498 0 15.9447 0C9.70838 0 4.32249 3.57875 1.70044 8.78742L6.85598 12.7915C8.14942 8.94693 11.7282 6.16537 15.9447 6.16537Z"
                                  fill="#EB4335"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5_192">
                                  <rect
                                    width="31.2672"
                                    height="32"
                                    fill="white"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>

                          <button className="continueBtn">
                            <svg
                              width="27"
                              height="32"
                              viewBox="0 0 27 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_5_200)">
                                <path
                                  d="M21.8606 17.0009C21.8241 12.9545 25.1824 11.0119 25.3343 10.9172C23.4445 8.16648 20.5035 7.78966 19.4543 7.74532C16.9528 7.49343 14.5709 9.21034 13.3029 9.21034C12.0349 9.21034 10.0743 7.7816 8.00017 7.8219C5.26981 7.86221 2.75617 9.39977 1.35048 11.83C-1.48318 16.7208 0.627387 23.9693 3.3861 27.9392C4.73711 29.8798 6.34534 32.0662 8.45995 31.9856C10.4976 31.905 11.2673 30.6758 13.7262 30.6758C16.1852 30.6758 16.8779 31.9856 19.031 31.9473C21.2205 31.905 22.61 29.9644 23.9509 28.0178C25.4984 25.7648 26.1384 23.5844 26.1749 23.4696C26.1263 23.4494 21.9052 21.8393 21.8606 17.0009Z"
                                  fill="#3F3F44"
                                />
                                <path
                                  d="M17.8155 5.1193C18.9376 3.76712 19.6951 1.88696 19.4865 0.0129356C17.87 0.0774904 15.9135 1.08502 14.7528 2.43719C13.7138 3.63223 12.8023 5.54657 13.0493 7.38244C14.8521 7.52149 16.6932 6.46948 17.8155 5.1193Z"
                                  fill="#3F3F44"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5_200">
                                  <rect
                                    width="26.1818"
                                    height="32"
                                    fill="white"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>

                          <button className="continueBtn">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_5_206)">
                                <path
                                  d="M32 15.9996C32 7.16308 24.8366 -0.000352859 16 -0.000352859C7.16344 -0.000352859 0 7.16308 0 15.9996C0 23.9857 5.85097 30.605 13.5 31.8053V20.6246H9.4375V15.9996H13.5V12.4746C13.5 8.46465 15.8887 6.24965 19.5434 6.24965C21.294 6.24965 23.125 6.56215 23.125 6.56215V10.4996H21.1074C19.1198 10.4996 18.5 11.733 18.5 12.9983V15.9996H22.9375L22.2281 20.6246H18.5V31.8053C26.149 30.605 32 23.9857 32 15.9996Z"
                                  fill="#1877F2"
                                />
                                <path
                                  d="M22.2281 20.625L22.9375 16H18.5V12.9987C18.5 11.7334 19.1198 10.5 21.1074 10.5H23.125V6.56255C23.125 6.56255 21.294 6.25005 19.5434 6.25005C15.8887 6.25005 13.5 8.46505 13.5 12.475V16H9.4375V20.625H13.5V31.8057C14.3146 31.9335 15.1495 32 16 32C16.8505 32 17.6854 31.9335 18.5 31.8057V20.625H22.2281Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5_206">
                                  <rect width="32" height="32" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                          </div>
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </SignUpStyleWrapper>
        </AgencyStyleWrapper>
      </Col>
    </Row>
  );
};

export default SignUp;
