import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const SafeFarmWrapper = styled.div`
  width: 100%;
  height: 100%;
  h1{
    color:#101828;
    margin-left : 12px;
    margin-top : 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    // padding :20px, 24px, 0px, 24px;
  }
  .statistic-card{
    background: #FFFFFF;
    border-top: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
    height: auto;
    padding: 10px 0px 0px 0px;

  }
  .statisticMap-cards{
    background-color: #ffffff !important;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
    height: auto;
    padding: 10px !important;
    min-height: 300px;
    margin : 0px !important;
  }
  .statistic-cards{
    background-color: #ffffff !important;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
    height: auto;
    min-height: 300px;
    margin : 0px ;
    padding-top: 10px !important;
  }
  .tablesCust{
    margin-top : 10px;
    // gap : 0.5rem;
    height : auto;
   
  }
  @media only screen and (width: 1440px) {
    .title{
      width : 100px;
    }
  }
  @media only screen and (width: 1024px) {
    .title{
      width : 60px;
    }
    form#addDevice{
      flex-wrap: nowrap !important;
    }
  }
  @media only screen and (max-width: 1200px) {
    .statistic-cards{
      margin-top : 10px !important;
    }
    .tablesCust{
      margin-top : 0px;
    gap : 0.5rem;
    }
  }
`;
export default WithDirection(SafeFarmWrapper);