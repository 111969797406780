import React, { useMemo } from "react";
import { Form, Input, Table } from "antd";
import _ from "lodash";
import { Table2Wrapper } from "./Table2Wrapper.style";

const Table2 = (props) => {
  const columns = [
    {
      title: "STOCK INVENTORY",
      colSpan: 2,
      dataIndex: "stock_type",
      rowScope: "row",
    },
    {
      title: "",
      colSpan: 0,
      dataIndex: "stock_number",
      rowScope: "row",
    },
  ];

  const data = useMemo(() => {
    const rows = [
      {
        key: "1",
        stock_type: "Stock Type (i.e. cattle, sheep, goats)",
        stock_number: "Stock number (average for the year)",
      },
    ];
    for (let i = 0; i < 35; i++) {
      rows.push({
        key: i + 2,
        stock_type: (
          <Form.Item name={`stock_type_${i}`} className="isoInputWrapper">
            <Input />
          </Form.Item>
        ),
        stock_number: (
          <Form.Item name={`stock_number_${i}`} className="isoInputWrapper">
            <Input />
          </Form.Item>
        ),
      });
    }
    return rows;
  }, []);

  return (
    <Table2Wrapper>
      <Table
          className="table2"
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
          rowClassName={(record, index) => {return index === 0 ? "primary-title" : index === 35 ? 'last-row' : ''}}
        />
    </Table2Wrapper>
  );
};

export default Table2;
