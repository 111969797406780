import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import WeatherForecastWrapper from "./WeatherForecast.styles";
import WeatherMap from "@iso/containers/Pages/WeatherMap/WeatherMap";
import WeatherForecastChart from "./WeatherForecastChart";
import { weatherCategory } from "@iso/constants/weatherCategory";
import { weatherDataService } from "@iso/services";
import WeatherEmailSetting from "./WeatherEmailSetting";

const WeatherForecast = () => {
  const [currentPosition, setCurrentPosition] = useState();
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const [weatherData, setWeatherData] = useState();
  const [currentWeatherData, setCurrentWeatherData] = useState();
  const [defaultTimezoneType] = useState("property");
  const [selectedDuration, setSelectedDuration] = useState("today");
  const [timezone, setTimezone] = useState("");
  const [isPropertyTimezone, setIsPropertyTimezone] = useState(true);
  const [isShowEmailSetting, setIsShowEmailSetting] = useState(false);
  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);

  const updateWeatherData = () => {
    let data = {
      duration_type: selectedDuration,
      timezone_type: isPropertyTimezone ? "property" : "local",
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    weatherDataService
      .getTomorrowioWeatherData(data, activePropertyId)
      .then((res) => {
        setWeatherData(res)
      });
  };

  const getCurrentWeather = () => {
    let data = {
      duration_type: 'current_time',
      timezone_type: defaultTimezoneType,
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    weatherDataService
      .getTomorrowioWeatherData(data, activePropertyId)
      .then((res) => {
        setCurrentWeatherData(res);
      });
  };

  useEffect(() => {
    if (currentPosition) {
      updateWeatherData();
      getCurrentWeather();
    }
  }, [currentPosition]);

  useEffect(() => {
    if (currentPosition) {
      updateWeatherData();
    }
  }, [selectedDuration]);

  useEffect(() => {
    if (currentPosition) {
      updateWeatherData();
    }
  }, [isPropertyTimezone]);


  return (
    <WeatherForecastWrapper>
      <WeatherMap setCurrentPosition={setCurrentPosition} />
      {
        activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_GOLD && 
        <Button
          icon={<SettingOutlined />}
          type="primary"
          className="btn-success weather-email-setting-btn"
          onClick={() => setIsShowEmailSetting(true)}
        >
          Weather Email Setting
        </Button>
      }
      {_.map(weatherCategory, (item, index) => {
        return (
          <WeatherForecastChart
            key={index}
            selectedDuration={selectedDuration}
            setSelectedDuration={setSelectedDuration}
            timezone={timezone}
            setTimezone={setTimezone}
            isPropertyTimezone={isPropertyTimezone}
            setIsPropertyTimezone={setIsPropertyTimezone}
            weatherCategory={item.value}
            currentPosition={currentPosition}
            weatherData={weatherData}
            currentWeatherData={currentWeatherData}
          />
        );
      })}
      <WeatherEmailSetting
        isShowEmailSetting={isShowEmailSetting}
        setIsShowEmailSetting={setIsShowEmailSetting}
      ></WeatherEmailSetting>
    </WeatherForecastWrapper>
  );
};

export default WeatherForecast;