import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'antd';
import { BoldOutlined, CheckSquareOutlined, StarOutlined, 
SwitcherOutlined, CaretDownOutlined, CheckOutlined, AimOutlined, FieldNumberOutlined, CalendarOutlined, EditOutlined, ShakeOutlined } from '@ant-design/icons';
import _ from 'lodash';

const Toolbox = (props) => {
	
	const addField = (key) => {
		props.setFieldKey(key);
		props.showFieldModal();
	}

    return (
        <div className="form-builder-toolbox">
        	<h3>Toolbox</h3>
        	<ul>
        		<li><Button icon={<BoldOutlined />} onClick={() => addField('input')}>Text Input</Button></li>
        		<li><Button icon={<CheckOutlined />} onClick={() => addField('checkbox')}>Checkbox</Button></li>
        		<li><Button icon={<SwitcherOutlined />} onClick={() => addField('switch')}>Switch</Button></li>
        		<li><Button icon={<CaretDownOutlined />} onClick={() => addField('select')}>Select</Button></li>
        		<li><Button icon={<CheckSquareOutlined />} onClick={() => addField('checkbox-group')}>Checkbox Group</Button></li>
        		<li><Button icon={<AimOutlined />} onClick={() => addField('radio-group')}>Radio Group</Button></li>
        		<li><Button icon={<BoldOutlined />} onClick={() => addField('textarea')}>Textarea</Button></li>
        		<li><Button icon={<FieldNumberOutlined />} onClick={() => addField('number')}>Number</Button></li>
        		<li><Button icon={<CalendarOutlined />} onClick={() => addField('date-picker')}>Date Picker</Button></li>
        		<li><Button icon={<EditOutlined />} onClick={() => addField('signature')}>Signature</Button></li>
        		<li><Button icon={<ShakeOutlined />} onClick={() => addField('dragdrop')}>Drag & Drop</Button></li>
        	</ul>
        </div>
    )
}

export default Toolbox;
