import L from 'leaflet';
import _ from 'lodash';
import { objectColors } from '@iso/constants/objectColors';
import cow_black from './MobIcons/AirAgriFlags_Cow_Black.png';
import cow_blue from './MobIcons/AirAgriFlags_Cow_Blue.png';
import cow_darkblue from './MobIcons/AirAgriFlags_Cow_DarkBlue.png';
import cow_darkgrey from './MobIcons/AirAgriFlags_Cow_DarkGrey.png';
import cow_green from './MobIcons/AirAgriFlags_Cow_green.png';
import cow_darkgreen from './MobIcons/AirAgriFlags_Cow_DarkGreen.png';
import cow_lightblue from './MobIcons/AirAgriFlags_Cow_LightBlue.png';
import cow_lightgreen from './MobIcons/AirAgriFlags_Cow_LightGreen.png';
import cow_lightgrey from './MobIcons/AirAgriFlags_Cow_LightGrey.png';
import cow_orange from './MobIcons/AirAgriFlags_Cow_Orange.png';
import cow_pink from './MobIcons/AirAgriFlags_Cow_Pink.png';
import cow_purple from './MobIcons/AirAgriFlags_Cow_Purple.png';
import cow_red from './MobIcons/AirAgriFlags_Cow_Red.png';
import cow_skyblue from './MobIcons/AirAgriFlags_Cow_SkyBlue.png';
import cow_white from './MobIcons/AirAgriFlags_Cow_White.png';
import cow_yellow from './MobIcons/AirAgriFlags_Cow_Yellow.png';

import goat_black from './MobIcons/AirAgriFlags_Goat_Black.png';
import goat_blue from './MobIcons/AirAgriFlags_Goat_Blue.png';
import goat_darkblue from './MobIcons/AirAgriFlags_Goat_DarkBlue.png';
import goat_darkgrey from './MobIcons/AirAgriFlags_Goat_DarkGrey.png';
import goat_green from './MobIcons/AirAgriFlags_Goat_Green.png';
import goat_darkgreen from './MobIcons/AirAgriFlags_Goat_DarkGreen.png';
import goat_lightblue from './MobIcons/AirAgriFlags_Goat_LightBlue.png';
import goat_lightgreen from './MobIcons/AirAgriFlags_Goat_LightGreen.png';
import goat_lightgrey from './MobIcons/AirAgriFlags_Goat_LightGrey.png';
import goat_orange from './MobIcons/AirAgriFlags_Goat_Orange.png';
import goat_pink from './MobIcons/AirAgriFlags_Goat_Pink.png';
import goat_purple from './MobIcons/AirAgriFlags_Goat_Purple.png';
import goat_red from './MobIcons/AirAgriFlags_Goat_Red.png';
import goat_skyblue from './MobIcons/AirAgriFlags_Goat_SkyBlue.png';
import goat_white from './MobIcons/AirAgriFlags_Goat_White.png';
import goat_yellow from './MobIcons/AirAgriFlags_Goat_Yellow.png';

import other_black from './MobIcons/AirAgriFlags_Other_Black.png';
import other_blue from './MobIcons/AirAgriFlags_Other_Blue.png';
import other_darkblue from './MobIcons/AirAgriFlags_Other_DarkBlue.png';
import other_darkgrey from './MobIcons/AirAgriFlags_Other_DarkGrey.png';
import other_green from './MobIcons/AirAgriFlags_Other_Green.png';
import other_darkgreen from './MobIcons/AirAgriFlags_Other_DarkGreen.png';
import other_lightblue from './MobIcons/AirAgriFlags_Other_LightBlue.png';
import other_lightgreen from './MobIcons/AirAgriFlags_Other_LightGreen.png';
import other_lightgrey from './MobIcons/AirAgriFlags_Other_LightGrey.png';
import other_orange from './MobIcons/AirAgriFlags_Other_Orange.png';
import other_pink from './MobIcons/AirAgriFlags_Other_Pink.png';
import other_purple from './MobIcons/AirAgriFlags_Other_Purple.png';
import other_red from './MobIcons/AirAgriFlags_Other_Red.png';
import other_skyblue from './MobIcons/AirAgriFlags_Other_SkyBlue.png';
import other_white from './MobIcons/AirAgriFlags_Other_White.png';
import other_yellow from './MobIcons/AirAgriFlags_Other_Yellow.png';

import sheep_black from './MobIcons/AirAgriFlags_Sheep_Black.png';
import sheep_blue from './MobIcons/AirAgriFlags_Sheep_Blue.png';
import sheep_darkblue from './MobIcons/AirAgriFlags_Sheep_DarkBlue.png';
import sheep_darkgrey from './MobIcons/AirAgriFlags_Sheep_DarkGrey.png';
import sheep_green from './MobIcons/AirAgriFlags_Sheep_Green.png';
import sheep_darkgreen from './MobIcons/AirAgriFlags_Sheep_DarkGreen.png';
import sheep_lightblue from './MobIcons/AirAgriFlags_Sheep_LightBlue.png';
import sheep_lightgreen from './MobIcons/AirAgriFlags_Sheep_LightGreen.png';
import sheep_lightgrey from './MobIcons/AirAgriFlags_Sheep_LightGrey.png';
import sheep_orange from './MobIcons/AirAgriFlags_Sheep_Orange.png';
import sheep_pink from './MobIcons/AirAgriFlags_Sheep_Pink.png';
import sheep_purple from './MobIcons/AirAgriFlags_Sheep_Purple.png';
import sheep_red from './MobIcons/AirAgriFlags_Sheep_Red.png';
import sheep_skyblue from './MobIcons/AirAgriFlags_Sheep_SkyBlue.png';
import sheep_white from './MobIcons/AirAgriFlags_Sheep_White.png';
import sheep_yellow from './MobIcons/AirAgriFlags_Sheep_Yellow.png';

// import sheep from './AssetIcons/AnimalIcons/airagri_sheep.png';
// import goat from './AssetIcons/AnimalIcons/airagri_goat.png';
// import other from './AssetIcons/AnimalIcons/airagri_otheranimal.png';

export const IconMob = (color, asset_total, type) => {
  // var mob_color = '';
  // _.forEach(objectColors, (color_obj, index) => {
  //     if(color_obj.value === color){
  //       mob_color = color_obj.color;
  //     }
  // });

  var icon_url = '';

  switch(type){
    case 'cattle':
      switch(color){
        case 'black':
          icon_url = cow_black;
          break;
        case 'white':
          icon_url = cow_white;
          break;
        case 'orange':
          icon_url = cow_orange;
          break;
        case 'light_green':
          icon_url = cow_lightgreen;
          break;
        case 'purple':
          icon_url = cow_purple;
          break;
        case 'yellow':
          icon_url = cow_yellow;
          break;
        case 'red':
          icon_url = cow_red;
          break;
        case 'sky_blue':
          icon_url = cow_skyblue;
          break;
        case 'dark_blue':
          icon_url = cow_darkblue;
          break;
        case 'blue':
          icon_url = cow_blue;
          break;
        case 'light_blue':
          icon_url = cow_lightblue;
          break;
        case 'light_grey':
          icon_url = cow_lightgrey;
          break;
        case 'dark_grey':
          icon_url = cow_darkgrey;
          break;
        case 'green':
          icon_url = cow_green;
          break;
        case 'dark_green':
          icon_url = cow_darkgreen;
          break;
        case 'pink':
          icon_url = cow_pink;
          break;
        default:
          icon_url = 'data:image/svg+xml;charset=UTF-8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242%22%20height%3D%2250%22%20viewBox%3D%220%200%2042%2050%22%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%3Cpath%20fill%3D%22%232c303e%22%20fill-opacity%3D%221%22%20d%3D%22M35.7812122%2C6.144%20L36.9372122%2C7.3%20C44.0342122%2C15.546%2043.6762122%2C27.995%2035.8642122%2C35.816%20L21.0001122%2C50.0004%20L6.14121223%2C35.816%20C-1.67278777%2C27.995%20-2.02978777%2C15.544%205.06921223%2C7.298%20L6.22421223%2C6.144%20C10.2652122%2C2.097%2015.5512122%2C0.049%2020.8512122%2C0.001%20L21.1292122%2C0%20C26.4292122%2C0.043%2031.7172122%2C2.085%2035.7632122%2C6.126%20L35.7812122%2C6.144%20Z%22%2F%3E%0A%20%20%3Ccircle%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2218%22%20fill%3D%22%23FFF%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
      }
      break;
    case 'sheep':
      switch(color){
        case 'black':
          icon_url = sheep_black;
          break;
        case 'white':
          icon_url = sheep_white;
          break;
        case 'orange':
          icon_url = sheep_orange;
          break;
        case 'light_green':
          icon_url = sheep_lightgreen;
          break;
        case 'purple':
          icon_url = sheep_purple;
          break;
        case 'yellow':
          icon_url = sheep_yellow;
          break;
        case 'red':
          icon_url = sheep_red;
          break;
        case 'sky_blue':
          icon_url = sheep_skyblue;
          break;
        case 'dark_blue':
          icon_url = sheep_darkblue;
          break;
        case 'blue':
          icon_url = sheep_blue;
          break;
        case 'light_blue':
          icon_url = sheep_lightblue;
          break;
        case 'light_grey':
          icon_url = sheep_lightgrey;
          break;
        case 'dark_grey':
          icon_url = sheep_darkgrey;
          break;
        case 'green':
          icon_url = sheep_green;
          break;
        case 'dark_green':
          icon_url = sheep_darkgreen;
          break;
        case 'pink':
          icon_url = sheep_pink;
          break;
        default:
          icon_url = 'data:image/svg+xml;charset=UTF-8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242%22%20height%3D%2250%22%20viewBox%3D%220%200%2042%2050%22%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%3Cpath%20fill%3D%22%232c303e%22%20fill-opacity%3D%221%22%20d%3D%22M35.7812122%2C6.144%20L36.9372122%2C7.3%20C44.0342122%2C15.546%2043.6762122%2C27.995%2035.8642122%2C35.816%20L21.0001122%2C50.0004%20L6.14121223%2C35.816%20C-1.67278777%2C27.995%20-2.02978777%2C15.544%205.06921223%2C7.298%20L6.22421223%2C6.144%20C10.2652122%2C2.097%2015.5512122%2C0.049%2020.8512122%2C0.001%20L21.1292122%2C0%20C26.4292122%2C0.043%2031.7172122%2C2.085%2035.7632122%2C6.126%20L35.7812122%2C6.144%20Z%22%2F%3E%0A%20%20%3Ccircle%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2218%22%20fill%3D%22%23FFF%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
      }
      break;
    case 'goat':
      switch(color){
        case 'black':
          icon_url = goat_black;
          break;
        case 'white':
          icon_url = goat_white;
          break;
        case 'orange':
          icon_url = goat_orange;
          break;
        case 'light_green':
          icon_url = goat_lightgreen;
          break;
        case 'purple':
          icon_url = goat_purple;
          break;
        case 'yellow':
          icon_url = goat_yellow;
          break;
        case 'red':
          icon_url = goat_red;
          break;
        case 'sky_blue':
          icon_url = goat_skyblue;
          break;
        case 'dark_blue':
          icon_url = goat_darkblue;
          break;
        case 'blue':
          icon_url = goat_blue;
          break;
        case 'light_blue':
          icon_url = goat_lightblue;
          break;
        case 'light_grey':
          icon_url = goat_lightgrey;
          break;
        case 'dark_grey':
          icon_url = goat_darkgrey;
          break;
        case 'green':
          icon_url = goat_green;
          break;
        case 'dark_green':
          icon_url = goat_darkgreen;
          break;
        case 'pink':
          icon_url = goat_pink;
          break;
        default:
          icon_url = 'data:image/svg+xml;charset=UTF-8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242%22%20height%3D%2250%22%20viewBox%3D%220%200%2042%2050%22%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%3Cpath%20fill%3D%22%232c303e%22%20fill-opacity%3D%221%22%20d%3D%22M35.7812122%2C6.144%20L36.9372122%2C7.3%20C44.0342122%2C15.546%2043.6762122%2C27.995%2035.8642122%2C35.816%20L21.0001122%2C50.0004%20L6.14121223%2C35.816%20C-1.67278777%2C27.995%20-2.02978777%2C15.544%205.06921223%2C7.298%20L6.22421223%2C6.144%20C10.2652122%2C2.097%2015.5512122%2C0.049%2020.8512122%2C0.001%20L21.1292122%2C0%20C26.4292122%2C0.043%2031.7172122%2C2.085%2035.7632122%2C6.126%20L35.7812122%2C6.144%20Z%22%2F%3E%0A%20%20%3Ccircle%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2218%22%20fill%3D%22%23FFF%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
      }
      break;
    case 'other':
      switch(color){
        case 'black':
          icon_url = other_black;
          break;
        case 'white':
          icon_url = other_white;
          break;
        case 'orange':
          icon_url = other_orange;
          break;
        case 'light_green':
          icon_url = other_lightgreen;
          break;
        case 'purple':
          icon_url = other_purple;
          break;
        case 'yellow':
          icon_url = other_yellow;
          break;
        case 'red':
          icon_url = other_red;
          break;
        case 'sky_blue':
          icon_url = other_skyblue;
          break;
        case 'dark_blue':
          icon_url = other_darkblue;
          break;
        case 'blue':
          icon_url = other_blue;
          break;
        case 'light_blue':
          icon_url = other_lightblue;
          break;
        case 'light_grey':
          icon_url = other_lightgrey;
          break;
        case 'dark_grey':
          icon_url = other_darkgrey;
          break;
        case 'green':
          icon_url = other_green;
          break;
        case 'dark_green':
          icon_url = other_darkgreen;
          break;
        case 'pink':
          icon_url = other_pink;
          break;
        default:
          icon_url = 'data:image/svg+xml;charset=UTF-8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242%22%20height%3D%2250%22%20viewBox%3D%220%200%2042%2050%22%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%3Cpath%20fill%3D%22%232c303e%22%20fill-opacity%3D%221%22%20d%3D%22M35.7812122%2C6.144%20L36.9372122%2C7.3%20C44.0342122%2C15.546%2043.6762122%2C27.995%2035.8642122%2C35.816%20L21.0001122%2C50.0004%20L6.14121223%2C35.816%20C-1.67278777%2C27.995%20-2.02978777%2C15.544%205.06921223%2C7.298%20L6.22421223%2C6.144%20C10.2652122%2C2.097%2015.5512122%2C0.049%2020.8512122%2C0.001%20L21.1292122%2C0%20C26.4292122%2C0.043%2031.7172122%2C2.085%2035.7632122%2C6.126%20L35.7812122%2C6.144%20Z%22%2F%3E%0A%20%20%3Ccircle%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2218%22%20fill%3D%22%23FFF%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
      }
      break;
    default:
      icon_url = 'data:image/svg+xml;charset=UTF-8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242%22%20height%3D%2250%22%20viewBox%3D%220%200%2042%2050%22%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%3Cpath%20fill%3D%22%232c303e%22%20fill-opacity%3D%221%22%20d%3D%22M35.7812122%2C6.144%20L36.9372122%2C7.3%20C44.0342122%2C15.546%2043.6762122%2C27.995%2035.8642122%2C35.816%20L21.0001122%2C50.0004%20L6.14121223%2C35.816%20C-1.67278777%2C27.995%20-2.02978777%2C15.544%205.06921223%2C7.298%20L6.22421223%2C6.144%20C10.2652122%2C2.097%2015.5512122%2C0.049%2020.8512122%2C0.001%20L21.1292122%2C0%20C26.4292122%2C0.043%2031.7172122%2C2.085%2035.7632122%2C6.126%20L35.7812122%2C6.144%20Z%22%2F%3E%0A%20%20%3Ccircle%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2218%22%20fill%3D%22%23FFF%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
  }
  var icon = new L.divIcon({
    iconUrl: icon_url,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [44, 60],
    iconAnchor: [22, 60],
    className: 'custom-icon-marker mob',
    html: '<div><img class="custom-icon-marker mob" src="'+icon_url+'"/><div class="numberCircle">'+asset_total+'</div><div>'
  });

  return icon;
};