import styled from 'styled-components';
import { palette } from 'styled-theme';

const FinalScreenWrapper = styled.div`
  text-align: center;
  padding: 100px 0;

  .final-msg {
    font-size: 30px;
    color: #788195;
    font-weight: 700;
  }

  .final-desc-msg {
    font-size: 13px;
    color: ${palette('text', 3)};
    text-decoration: none;
  }

  .final-button-block {
    margin-top: 30px;
    button{
      margin: 0px 10px
    }
  }
  
`;

export default FinalScreenWrapper;