import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const MobsPage = styled.div`
  width: 100%;
  height: 100%;
  .button-group {
    float: right;
    clear: both;
  }
  .btn-success{
    background-color: #0B2A1B;
    border-color: #0B2A1B;
    &:hover{
      opacity: 0.8;
    }
  }  
  .ant-table-wrapper {
    min-width: 100%;
  }
  .delete-button{
    margin-left:8px;
  }
  .manage-table .react-collapsible tbody .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
  }
  .manage-property-header {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    margin: auto;
  }
  .manage-table .react-collapsible tbody .name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
`;

export default WithDirection(MobsPage);