import React, { useState, useEffect, useRef } from "react";
import InvitationModalWrapper from "./InvitationModal.styles";
import { Button, Input, Form } from "antd";
import { propertyService, userService } from '@iso/services';

const InvitationModal = (props) => {
  const [fields, setFields] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFields([
      {
        name: ["email"],
        value: "",
      },
    ]);
  }, []);

  const cancelModal = () => {
    props.cancelInvitationModal();
  };

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {

        onStore({
          user_email: values.email,
          user_name: values.email.split('@')[0],
          permission: 'view'
        });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    propertyService.inviteUser(props.propertyId, values).then(res => {
      if(res.code === '0000'){
        form.resetFields();
        setLoading(false);
        cancelModal()
        props.setStep('get_ready')
      }
    });
  }

  return (
    <InvitationModalWrapper
      title="Invite"
      visible={props.visibleInvitationModal}
      onCancel={cancelModal}
      maskClosable={false}
      footer={[]}
    >
      <div className="form-body">
        <p className="instruction">
          Enter email address to send invite to your friends & family
        </p>
        <Form form={form} layout="vertical" scrollToFirstError fields={fields}>
          <Form.Item
            name={["email"]}
            className="isoInputWrapper"
            label="Email Address"
            rules={[
              {
                type: "email",
                message: "The input is not valid Email!",
              },
              {
                required: true,
                message: "Please input your Email!",
              },
              () => ({
                validator(rule, value) {
                  if (value)
                    return userService.checkEmailExistsInProperty(
                      value,
                      props.propertyId
                    );
                  else return Promise.resolve();
                },
              }),
            ]}
          >
            <Input size="large" placeholder="Email" />
          </Form.Item>
        </Form>
        <Button className="submit-btn" type="primary" size="large" loading={loading} onClick={handleSubmit}>
          Send Invite
        </Button>
      </div>
    </InvitationModalWrapper>
  );
};

export default InvitationModal;
