import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector } from 'react-redux';
// import { configs } from '@iso/components/Tables/configs';
import { Row, Col, Button, Dropdown, Menu, Popconfirm } from 'antd';
import ManagePropertyPage from './ManageProperty.styles';
import Table from '@iso/components/collapsing-table/table';
import { EditOutlined, PlusOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { propertyService } from '@iso/services';
import moment from 'moment';
import { subscriptionPlan } from '@iso/constants/subscriptionPlan';
import PropertyModal from '@iso/containers/Pages/Property/PropertyModal';

const ManageProperty = () => {
  const currentUser = useSelector((state) => state.user.user);
  const [properties, setProperties] = useState([]);
  const [visiblePropertyModal, setVisiblePropertyModal] = useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [propertyData, setPropertyData] = useState({});

  const getProperties = () => {
    propertyService.getAllProperties().then(res => {
      if(res.code === '0000'){
        setProperties(res.properties);
      }
    });
  }

  useEffect(() => {
      getProperties();
  }, []) 

  const menu = (property) => (
    <Menu onClick={handleMenuClick.bind(this, property)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
    </Menu>
  );

  const handleMenuClick = (property, e) => {
    if(e.key === 'editDetails'){
      setPropertyData(property);
      showPropertyModal();
    }
  }

  const confirmDelete = (del_product_category) => {
    // if(del_product_category.id){
    //    marketplaceCategoryService.destroy(del_product_category.id).then(res => {
    //     if(res.code === '0000'){
    //       getProductCategories();
    //     }
    //   });
    // }
  }

  const showPropertyModal = () => {
    setVisiblePropertyModal(true);
  }

  const cancelPropertyModal = () => {
    setVisiblePropertyModal(false);
    setPropertyData({});
  }

  const columns = [
    {
      label: <IntlMessages id="antTable.title.property" />,
      accessor: 'name',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="adminPage.manageProduct.createdBy" />,
      accessor: 'owner',
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let property = cell.row;
        return property.owner.name;
      }
    },
    {
      label: <IntlMessages id="antTable.title.address" />,
      accessor: 'address',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3
    },
    {
      label: <IntlMessages id="antTable.title.subscription_tier" />,
      accessor: 'subscription_label',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let row = cell.row
        if(row.subscription_label){
          return subscriptionPlan.map((value, index) => {
            if(value.value == row.subscription_label){
              return (
                <div>{value.label}</div>
              )
            }
          });
        }else{
          return (<div>{<IntlMessages id="subscription.prices.REACT_APP_STRIPE_PLAN_BRONZE" />}</div>)
        }
        
      }
    },
    {
      label: <IntlMessages id="manageYourProduct.managePage.createdAt" />,
      accessor: 'created_at',
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      CustomComponent: cell => {
        let property = cell.row
        return moment(property.created_at).format('L');
      }
    },
    {
      label: <IntlMessages id="manageYourProduct.managePage.updatedAt" />,
      accessor: 'updated_at',
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      CustomComponent: cell => {
        let property = cell.row
        return moment(property.updated_at).format('L');
      }
    }
  ];

  const onSaved = () => {
    setIsSaved(!isSaved);
  }

  return (
    <LayoutWrapper>
      <ManagePropertyPage>
        <PageHeader>
          {<IntlMessages id="adminPage.manageProperty.header" />}
        </PageHeader>
        <Box id="containerBox">
        {
          properties.length > 0 &&
          <Table 
              columns={columns} 
              rows={properties}
              containerId="containerBox"
              showPagination={true}
              rowSize={20}
            />
        }
        </Box>
        <PropertyModal editProperty={propertyData} visible={visiblePropertyModal} cancelModal={cancelPropertyModal} onSaved={onSaved}/>
      </ManagePropertyPage>
    </LayoutWrapper>
  );
}

export default ManageProperty;
