import React, { useEffect, useState } from "react";
import SARModalWrapper from "./SARModal.styles";
import IntlMessages from "@iso/components/utility/intlMessages";
import PlaceAutocomplete from "./PlaceAutocomplete"
import Box from "@iso/components/utility/box";
import { dateHelper } from "@iso/lib/helpers/dateHelper";

import {
  Button,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Collapse,
  Table,
  Checkbox,
  Divider,
  Dropdown,
  Menu,
  Col,
  Row
} from "antd";
import moment from "moment";
import { DownOutlined, EditOutlined ,StopOutlined} from '@ant-design/icons';
import { sarService } from "@iso/services";
import { iotDevice } from "../../services/iotdevice.service";
import { useSelector } from "react-redux";
import SAREditModal from "./SarEditModal";
import TimezoneSelect from 'react-timezone-select';
import { safetyRadiusOptions } from '@iso/constants/safetyRadiusOptions';
import _ from 'lodash';

const { Option } = Select;

const SARModal = ({ modalSAROpen, setModalSAROpen }) => {
  const [getSARUsers, setGetSARUsers] = useState([]);
  const [getSAR, setGetSAR] = useState([]);
  const [loading, setLoading] = useState(false);
  const [terminateLoading, setTerminateLoading] = useState(false);
  const [paginationSize, setPaginationSize] = useState(5);
  const [openAccordion, setOpenAccordion] = useState([]);
  const [terminateId, setTerminateId] = useState();
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  const [positionDevice, setPositionDevice] = useState();
  const { TextArea } = Input;
  const CheckboxGroup = Checkbox.Group;
  const [checkedContacts, setCheckedContacts] = useState([]);
  const [openSarEditModal, setOpeneSarEditModal] = useState(false);
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const currentUser = useSelector((state) => state.user.user);
  const [sarId, setSarId] = useState();
  const [geo, setGeo] = useState({lat: 0, lng: 0});

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const handleTerminated = async (id) => {
    setTerminateLoading(true);
    try {
      const verifiedAt = formatDateTimeUTC();
      const userIdVerified = currentUser.id;
  
      // Update SAR
      const requestBody = {
        sar: {
          terminated: 1,
          ...(userIdVerified !== null && { user_id_verified: userIdVerified }),
          ...(verifiedAt !== null && { verified_at: verifiedAt }),
        },
      };

      const updateRes = await sarService.updateSAR(id, requestBody);
      if (updateRes.code !== "0000") {
        throw new Error('Error updating SAR');
      }
  
      // Fetch all SAR entries after update
      await fetchAllSAR(); 

    } catch (error) {
      console.error('Error handling termination:', error);
      // Handle error (e.g., show error message to the user)
    } finally {
      setTerminateLoading(false);
    }
  };

  const formatDateTimeUTC = (date = new Date()) => {
    return moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  };

  const handleMenuClick = (action,id, e) => {
    if(e.key === "terminate"){
      setTerminateId(id);
      handleTerminated(id);
    }
    if(e.key === "viewSar"){
      setSarId(id);
      setOpeneSarEditModal(true);
    }

  }
  const menu = (item,action,id) => ( 
    <Menu onClick={handleMenuClick.bind(this, action, id)}>
      { action && 
        <Menu.Item key="terminate" icon={<StopOutlined/>}>
          Terminate
        </Menu.Item>
      }
      <Menu.Item key="viewSar" icon={<EditOutlined />}>
        View
      </Menu.Item>
    </Menu>
  );
  
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Assigned to",
      dataIndex: "assigned",
      key: "assigned",
    },
    {
      title: "Terminated by",
      dataIndex: "terminatedBy",
      key: "terminatedBy",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (item, { action, id }) => (
        <Dropdown overlay={menu(item,action,id)} trigger={['click']}>
          <Button className="ant-btn ant-btn-default ant-dropdown-trigger action-button margin-bottom-5">
            <IntlMessages id="antTable.title.actions" /> <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const resetForm = () => {
    setTimeout(() => {
      form.resetFields();
    }, 500);
  };

  const cancelModal = () => {
    setModalSAROpen(false);
    resetForm();
  };

  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const dataFormated = new Date(values.SARDate).toDateString();
      const timeFormated = new Date(values.SARTime).toLocaleTimeString();

      const payload = {
        name: values.SARName,
        assigned_to: values.UserName,
        date_time: `${dataFormated} ${timeFormated}`,
        property_id: activePropertyId,
        iot_device_id: values?.IotDevice,
        departure_location: values?.departure_location,
        destination_location: values?.destination_location,
        safety_radius: values?.safety_radius,
        trip_comments: values?.trip_comments,
        contacts: values?.contacts,
        method_of_transport: values.method_of_transport === 'other' ? values.method_of_transport_other : values.method_of_transport,
        lat: geo.lat,
        lng: geo.lng,
        timezone: selectedTimezone.value || selectedTimezone
      };

      if (activePropertyId) {
        const res = await sarService.addSar(payload);
        if (res.code === "0000") {
          // Reset form fields
          form.resetFields();

          // Fetch updated SAR list
          await fetchAllSAR();
          // Reset accordion state
          setOpenAccordion([]);
        }
      }
    } catch (error) {
      console.error('Error handling form submission:', error);
    } finally {
      setLoading(false);
    };
  };

  const fetchAllSAR = async () => {
    try {
      const allSARRes = await sarService.getAllSAR();
      if (allSARRes.code === "0000") {
        setGetSAR(allSARRes.sar);
      }
    } catch (error) {
      console.error("Error fetching all SAR data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!activePropertyId) return;
      try {

        const [positionDeviceRes, sarUserRes] = await Promise.all([
          iotDevice.positionDevice(activePropertyId),
          sarService.getAllSARUser(activePropertyId),
        ]);
      
        if (positionDeviceRes.code === "0000") {
          const positionDeviceData = positionDeviceRes.positions.map(item => ({
              value: item?.device?.id,
              label: item?.device?.name
          }));
          setPositionDevice(positionDeviceData);
        }

        if (sarUserRes.code === "0000") {
          const sarUserData = sarUserRes.users.map(item => ({
            value: item.id,
            label: item.name
          }));
          setGetSARUsers(sarUserData);
        }

        await fetchAllSAR();

      } catch (error) {
          console.error("Error fetching Sar data:", error);
      }
    };
    fetchData();
  }, [activePropertyId]);

  const checkAllContacts = (getSARUsers?.length ?? 0) === checkedContacts.length;

  const onContactsChange = (list) => {
    setCheckedContacts(list);
    form.setFieldsValue({ contacts: list })
  };

  const onCheckAllContacts = (e) => {
    setCheckedContacts(e.target.checked ? getSARUsers.map(user => user.value) : []);
    form.setFieldsValue({ contacts: e.target.checked ? getSARUsers.map(user => user.value) : [] })
  };
  const [showMethodTransportText,setShowMethodTransportText] = useState(false);
  
  const methodTransportOptions = [
    { value: 'car', label: 'Car' },
    { value: 'quadBike', label: 'Quad Bike' },
    { value: 'buggy', label: 'Buggy' },
    { value: 'aeroplane', label: 'Aeroplane' },
    { value: 'helicopter', label: 'Helicopter'},
    { value: 'other', label:'Other'}
  ];

  const renderSafetyRadiusOptions = () => {
    let options = [];

    _.forEach(safetyRadiusOptions, (item, index) => {
      options.push(
        <Option key={index} value={item.value}>
            <div>{item.label}</div>
        </Option>
      );
    });

    return (
      <Select
        placeholder="Select a safety radius"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }
  
  return (
    <>
      <SARModalWrapper
        forceRender={true}
        visible={modalSAROpen}
        title={<IntlMessages id="topbar.SAR" />}
        onCancel={cancelModal}
        maskClosable={false}
        footer={[]}
      >
        <Collapse
          activeKey={openAccordion}
          onChange={(e) => setOpenAccordion(e)}
        >
          <Panel header="Add SAR" key="Add SAR">
            <Form
              initialValues={{ SARDate: moment(), SARTime: moment() }}
              form={form}
              name="SAR"
              layout="vertical"
              scrollToFirstError
            >
              <div className="form-body">
                <Form.Item
                  name="SARName"
                  className="isoInputWrapper"
                  label="SAR Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your SAR Name",
                    },
                    {
                      max: 255,
                      message: "SAR Name must be maximum 255 characters",
                    },
                  ]}
                >
                  <Input
                    className="pac-target-input"
                    size="large"
                    placeholder="Input your SAR Name"
                  />
                </Form.Item>
                <Row gutter={[16, 16]}>
                  <Col xs={{span: 12}} md={{span: 12}}>
                    <Form.Item
                      name="UserName"
                      className="isoInputWrapper"
                      label="User Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your User Name",
                        },
                      ]}
                    >
                      <Select
                        className="pac-target-input"
                        size="large"
                        allowClear
                        options={getSARUsers}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 12}} md={{span: 12}}>
                    <Form.Item
                      name="IotDevice"
                      className="isoInputWrapper"
                      label="IoT device"
                    >
                      <Select
                        className="pac-target-input"
                        size="large"
                        allowClear
                        options={positionDevice}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="SARDate"
                  className="isoInputWrapper"
                  label="SAR Date"
                  rules={[
                    {
                      required: true,
                      message: "Please input your SAR Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    className="pac-target-input"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="SARTime"
                  label="SAR Time"
                  rules={[
                    {
                      required: true,
                      message: "Please input your SAR Time",
                    },
                  ]}
                >
                  <TimePicker
                    className="pac-target-input"
                    size="large"
                    style={{ width: "100%" }}
                    format="HH:mm"
                  />
                </Form.Item>
                <div class="ant-col ant-form-item-label">
                  <label class="ant-form-item-required" title="SAR Timezone">SAR Timezone</label>
                </div>
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                />
                <br/>
                <Form.Item
                  name="trip_comments"
                  label="Trip Comments"
                >
                  <TextArea
                    className="isoInputWrapper"
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <PlaceAutocomplete form={form} fieldName="destination_location" label="Destination Location" callback={setGeo} />
                <PlaceAutocomplete form={form} fieldName="departure_location" label="Departure Location" />
                <Form.Item
                  name="safety_radius"
                  className="isoInputWrapper"
                  label="Safety Radius"
                >
                {renderSafetyRadiusOptions()}
                </Form.Item>
                <Form.Item
                  name="method_of_transport"
                  label="Method of Transport"
                >
                <Select
                  allowClear
                  options={methodTransportOptions}
                />                
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate
                >
                  {({ getFieldValue }) => {
                    const selectedValue = getFieldValue('method_of_transport');
                    return selectedValue === 'other' ? (
                      <Form.Item
                        name="method_of_transport_other"
                      >
                        <Input
                          name="method_of_transport_other"
                          className="isoInputWrapper"
                          size="large"
                          placeholder="Other" 
                        />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                
                <Form.Item
                  name="contacts"
                  label="Contacts"
                > 
                  <Checkbox 
                    style={{
                      width: '100%',
                    }}
                    onChange={onCheckAllContacts} 
                    checked={checkAllContacts}> 
                    Check all 
                  </Checkbox>
                  <Divider />
                  <CheckboxGroup
                    options={getSARUsers}
                    value={checkedContacts} 
                    onChange={onContactsChange} 
                    className="checkboxContacts"
                  />
                </Form.Item>
              </div>
              <div className="footer-btn">
                <Button
                  key="back"
                  onClick={cancelModal}
                  className="cancel-button"
                >
                  {<IntlMessages id="topbar.cancel" />}
                </Button>

                <Button
                  key="submit"
                  className="btn-success"
                  type="primary"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {<IntlMessages id="topbar.save" />}
                </Button>
              </div>
            </Form>
          </Panel>
        </Collapse>
        <Table
          className="SarTable__Wrapper"
          dataSource={getSAR.map((item) => {
            return {
              id: item.id,
              name: item.name,
              date: item.date_time,
              assigned: item.user_assigned?.name,
              terminatedBy: item.terminated === 1 && item.user_id_verified ? item.user_verified.name : '',
              action: item.terminated === 0 ? true : false,
            };
          })}
          columns={columns}
          pagination={{
            pageSize: paginationSize,
            pageSizeOptions: [5, 10, 15],
            showSizeChanger: "true",
          }}
          onChange={(e) => setPaginationSize(e.pageSize)}
        />
       
      </SARModalWrapper>
      <SAREditModal 
        openSarEditModal={openSarEditModal} 
        setOpeneSarEditModal={setOpeneSarEditModal} 
        sarId={sarId} 
        positionDevice={positionDevice} 
        getSARUsers={getSARUsers}
        fetchAllSAR={fetchAllSAR} 
        methodTransportOptions={methodTransportOptions}
      />
    </>
  );
};

export default SARModal;
