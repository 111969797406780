import React, { useState, useEffect, useMemo } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Switch,
  message,
  Upload,
  Modal,
} from "antd";
import IntlMessages from "@iso/components/utility/intlMessages";
import AgentModalWrapper from "./AgentModal.style";
import ColorPicker from "@iso/components/ColorPicker/ColorPicker";
import { agentService } from "@iso/services";
import { apiEndpoint } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";
import $ from "jquery";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import _ from 'lodash';

const AgentModal = (props) => {
  const { visibleAgentModal, setVisibleAgentModal, dataAgent, getAgents, setDataAgent } =
    props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [checked, setChecked] = useState(false);
  const [color, setColor] = useState("#658ac1");
  const [logoImageId, setLogoImageId] = useState(null);
  const [iconImageId, setIconImageId] = useState(null);
  const [backgroundImageIds, setBackgroundImageIds] = useState([]);
  const [logoList, setLogoList] = useState([]);
  const [iconList, setIconList] = useState([]);
  const [backgroundImageList, setBackgroundImageList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const closePreviewModal = () => setPreviewOpen(false);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadLogoProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    fileList: logoList,
    beforeUpload: (file, fileList) => {
      if (!validateFile(file)) {
        return false;
      }
      let $oldOne = $(".anticon-delete")[0];
      setTimeout(() => {
        if ($oldOne) $($oldOne).trigger("click");
      }, 100);
    },
    onChange: (response) => {
      if (response.fileList && response.fileList.length) {
        setLogoList(response.fileList);
      }
      if (response.file && response.file.status == "done") {
        let res = response.file.response;
        if (res.code === "0000") {
          setLogoImageId(res.image.id);
        } else {
          message.error(res.message);
        }
      }
    },
    listType: "picture",
    multiple: false,
    data: {
      type: "agent_logos",
      storage: "s3",
    },
  };

  const uploadIconProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    fileList: iconList,
    beforeUpload: (file, fileList) => {
      if (!validateFile(file)) {
        return false;
      }
      let $oldOne = $(".anticon-delete")[0];
      setTimeout(() => {
        if ($oldOne) $($oldOne).trigger("click");
      }, 100);
    },
    onChange: (response) => {
      if (response.fileList && response.fileList.length) {
        setIconList(response.fileList);
      }
      if (response.file && response.file.status == "done") {
        let res = response.file.response;
        if (res.code === "0000") {
          setIconImageId(res.image.id);
        } else {
          message.error(res.message);
        }
      }
    },
    listType: "picture",
    multiple: true,
    data: {
      type: "agent_icons",
      storage: "s3",
    },
  };

  const uploadBackgroundImageProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    fileList: backgroundImageList,
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
    },
    beforeUpload: (file, fileList) => {
      if (!validateFile(file)) {
        return false;
      }
    },
    onChange: (response) => {
      if (response.file && response.file.status == "done") {
        let res = response.file.response;
        if (res.code === "0000") {
          backgroundImageIds.push(res.image.id)
          setBackgroundImageIds(backgroundImageIds)
          response.file.image_id = res.image.id
        } else {
          message.error(res.message);
        }
      }
      setBackgroundImageList(response.fileList);
    },
    onRemove: (file) => {
      const imageId = file?.image_id;
      const newBackgroundImageIds = backgroundImageIds.filter((item)=>{
        return item != imageId
      })
      setBackgroundImageIds(newBackgroundImageIds)
    },
    listType: "picture-card",
    data: {
      type: "agent_background_image",
      storage: "s3",
    },
  };

  const validateFile = (file) => {
    let fileType = file.type.split("/");
    let fileSize = Math.round(file.size / 1000000); // MB
    if (fileType[0] && fileType[0] === "video") {
      if (fileSize > 50) {
        message.error("Video size must not exceed 50MB");
        return false;
      }
    } else {
      if (fileSize > 10) {
        message.error("Image size must not exceed 10MB");
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setFields([
      {
        name: "name",
        value: "",
      },
      {
        name: "email",
        value: "",
      },
      {
        name: "color_code",
        value: "",
      },
    ]);
    setChecked(false);
  }, [visibleAgentModal]);

  useEffect(() => {
    setFields([
      {
        name: "name",
        value: dataAgent?.name,
      },
      {
        name: "email",
        value: dataAgent?.email,
      },
      {
        name: "color_code",
        value: dataAgent?.color_code,
      },
    ]);
    if (dataAgent.status == 1) {
      setChecked(true);
    }
    setColor(dataAgent.color_code ? dataAgent.color_code : "#0B2A1B");
    if(dataAgent.backgroundImages){
      let backgroundImagesTemp = _.map(dataAgent.backgroundImages, (image) => {
        return {
          uid: image.id,
          image_id: image.id,
          name: "image.png",
          status: 'done', 
          url: image.full_path, 
        }
      })
      let backgroundImageIds = _.map(dataAgent.backgroundImages, (image) => {
        return image.id
      })
      setBackgroundImageList(backgroundImagesTemp)
      setBackgroundImageIds(backgroundImageIds)
    }
  }, [dataAgent, visibleAgentModal]);

  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        onStore(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };
  const onStore = (values) => {
    let data = {
      ...values,
      status: checked ? 1 : 0,
      color_code: color,
      logo_id: logoImageId,
      icon_id: iconImageId,
      background_image_ids: backgroundImageIds
    };
    agentService.storeOrUpdate(data, dataAgent.id ?? null).then((res) => {
      if (res.code === "0000") {
        setLoading(false);
        getAgents();
        onCancelModal();
      }
    });
  };

  const onChangeSwitch = (checked) => {
    setChecked(checked);
  };

  const onCancelModal = () => {
    setDataAgent({});
    setIconList([]);
    setLogoList([]);
    setBackgroundImageList([]);
    setVisibleAgentModal(false);
  };

  const handleChangeColor = (color) => {
    setColor(color.hex);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <AgentModalWrapper
      className="user-access-modal"
      visible={visibleAgentModal}
      title={<IntlMessages id="adminPage.agent.title" />}
      onCancel={onCancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form form={form} fields={fields} layout="vertical">
        <Form.Item
          name="name"
          className="isoInputWrapper"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input Name!",
            },
          ]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          className="isoInputWrapper"
          rules={[
            {
              type: "email",
              message: "The input is not valid Email!",
            },
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input size="large" placeholder="Email" />
        </Form.Item>
        <Form.Item label="Hex color code" className="isoInputWrapper">
          <ColorPicker
            defaultColor={color}
            handleChangeColor={handleChangeColor}
          />
        </Form.Item>
        <Form.Item label="Logo">
          <Upload {...uploadLogoProps}>
            <Button className="action-button" icon={<UploadOutlined />}>
              Upload
            </Button>
          </Upload>
        </Form.Item>
        {dataAgent.logos && dataAgent.logos.length > 0 && (
          <img
            style={{ width: "50%" }}
            src={dataAgent.logos[dataAgent.logos.length - 1].full_path}
          />
        )}
        <Form.Item label="Icon">
          <Upload {...uploadIconProps}>
            <Button className="action-button" icon={<UploadOutlined />}>
              Upload
            </Button>
          </Upload>
        </Form.Item>
        {dataAgent.icons && dataAgent.icons.length > 0 && (
          <img
            style={{ width: "50%" }}
            src={dataAgent.icons[dataAgent.icons.length - 1].full_path}
          />
        )}
        <Form.Item label="Background images" name="background_images">
          <Upload {...uploadBackgroundImageProps}>
            {backgroundImageList.length >= 8 ? null : uploadButton}
          </Upload>
          <Modal
            open={previewOpen}
            footer={null}
            onCancel={closePreviewModal}
            title="Preview"
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </Form.Item>
        <Form.Item>
          <Switch
            checkedChildren="Live"
            unCheckedChildren="Draft"
            checked={checked}
            onChange={onChangeSwitch}
          />
        </Form.Item>
      </Form>
    </AgentModalWrapper>
  );
};

export default AgentModal;
