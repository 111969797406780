import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import AssetModalWrapper from './AssetModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tabs, Card, Timeline, Popconfirm, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { assetService, activityService } from '@iso/services';
import moment from 'moment';
import _ from 'lodash';
import styled from "styled-components";
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import L from 'leaflet';
import inside from 'point-in-polygon';
import { assetCategories } from '@iso/constants/assetCategories';
import { inventoryTypes } from '@iso/constants/inventoryTypes';
import { assetStates } from '@iso/constants/assetStates';
import { aircraftTypes } from '@iso/constants/aircraftTypes';
import { aircraftUnits } from '@iso/constants/aircraftUnits';
import AddAssetHistoryModal from './AddAssetHistoryModal';
import AssetHistory from './AssetHistory';

const { Option } = Select;
const { closeModal } = modalActions;
const { TabPane } = Tabs;

const EditAircraftModal = (props) => {
  const [form] = Form.useForm();
  const [addHistoryForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [assetData, setAssetData] = useState({});
  const [assetHistory, setAssetHistory] = useState([]);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const activePermission = useSelector((state) => state.permission.activePermission);

  const updateDataAssetHistory = React.useCallback((asset_id, modal_type) => {
      activityService.getList(asset_id, modal_type).then(res => {
        if(res.code === '0000'){
          setAssetHistory(res.activities.reverse());
          //setLoading(false);
        }
      });
  }, [assetHistory]);
 
  useEffect(() => {
    setAssetData(props.dataAsset);
  }, [props.dataAsset]);

  const getRandomLatLng = (coordinates) => {
    var polygon = L.polygon([
      coordinates
    ]);
    var bounds = polygon.getBounds();
    var x_max = bounds.getEast();
    var x_min = bounds.getWest();
    var y_max = bounds.getSouth();
    var y_min = bounds.getNorth();
    var lat=0,lng = 0;
    var count = 0;
    var area = polygon.getLatLngs()[0].map(function(point){
      return [point.lat, point.lng]            
    });

    while(!inside([lat, lng], area)){
      lat = y_min + (Math.random() * (y_max - y_min));
      lng = x_min + (Math.random() * (x_max - x_min));
      count+=1;
      if(count==100){
        break;
      }      
    }            

    return new L.LatLng(
          lat,
          lng
    );
  }
 
  const cancelModal = () => {
    props.cancelEditModal();
    setLoading(false);
    form.resetFields();
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const selectedObject = _.find(props.primaryObjects, (object) => {
      return object.id === values.asset.primary_object_id;
    });
    
    if(!assetData.id || assetData.primary_object_id != selectedObject.id){
      var point = getRandomLatLng(selectedObject.area);
    }
    
    const data = {
      facility: {
        ...values.asset,
        date_of_purchase: values.asset.date_of_purchase ? dateHelper.dateForAPI(values.asset.date_of_purchase) : '',
        manufacture_date: values.asset.manufacture_date ? dateHelper.dateForAPI(values.asset.manufacture_date) : '',
        expiry_date: values.asset.expiry_date ? dateHelper.dateForAPI(values.asset.expiry_date) : '',
        point,
        property_id: props.propertyId
      }
    }

    assetService.storeOrUpdate(data, assetData.id).then(res => {
      if(res.code === '0000'){
        props.onSaved(res.facility);
        cancelModal();
        form.resetFields();
      } else {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
      setFields([
        {
          name: ['asset', 'title'],
          value: assetData.title || '',
        },
        {
          name: ['asset', 'category'],
          value: assetData.category,
        },
        {
          name: ['asset', 'other_category'],
          value: assetData.other_category,
        },
        {
          name: ['asset', 'type'],
          value: assetData.type,
        },
        {
          name: ['asset', 'other_inventory_type'],
          value: assetData.other_inventory_type,
        },
        {
          name: ['asset', 'state'],
          value: assetData.state,
        },
        {
          name: ['asset', 'other_state'],
          value: assetData.other_state,
        },
        {
          name: ['asset', 'date_of_purchase'],
          value: assetData.date_of_purchase ? moment(assetData.date_of_purchase) : '',
        },
        {
          name: ['asset', 'manufacture_date'],
          value: assetData.manufacture_date ? moment(assetData.manufacture_date) : '',
        },
        {
          name: ['asset', 'description'],
          value: assetData.description || '',
        },
        {
          name: ['asset', 'aircraft_type'],
          value: assetData.aircraft_type || '',
        },
        {
          name: ['asset', 'other_aircraft_type'],
          value: assetData.other_aircraft_type || '',
        },
        {
          name: ['asset', 'storage_location'],
          value: assetData.storage_location || '',
        },
        {
          name: ['asset', 'other_storage_location'],
          value: assetData.other_storage_location || '',
        },
        {
          name: ['asset', 'aircraft_model'],
          value: assetData.aircraft_model || '',
        },
        {
          name: ['asset', 'container_unit'],
          value: assetData.container_unit,
        },
        {
          name: ['asset', 'other_container_unit'],
          value: assetData.other_container_unit || '',
        },
        {
          name: ['asset', 'current_fuel_level'],
          value: assetData.current_fuel_level || '',
        },
        {
          name: ['asset', 'volume'],
          value: assetData.volume || '',
        },
        {
          name: ['asset', 'primary_object_id'],
          value: assetData.primary_object_id || '',
        },
      ]);
    
  }, [props.propertyId, props.visibleAssetAircraft, assetData]);

  const renderOptionsAssetCategory = () => {
    let options = [];
    _.forEach(assetCategories, (cat, index) => {
      options.push(
        <Option key={index} value={cat.value}>
          <div>{cat.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a category"
        allowClear
        size="large"
        disabled
      >
        {options}
      </Select>
    );
  }

  const renderOptionsInventoryType = () => {
    let options = [];
    _.forEach(inventoryTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a inventory type"
        allowClear
        size="large"
        disabled
      >
        {options}
      </Select>
    );
  }

  const renderOptionsAssetState = () => {
    let options = [];
    _.forEach(assetStates, (state, index) => {
      options.push(
        <Option key={index} value={state.value}>
          <ColorItemRow>
            <ColorItemPreview style={{backgroundColor: state.color}}></ColorItemPreview>
            <div>{state.label}</div>
          </ColorItemRow>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a state"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionsAircraftType = () => {
    let options = [];
    _.forEach(aircraftTypes, (aircraft_type, index) => {
      options.push(
        <Option key={index} value={aircraft_type.value}>
          <div>{aircraft_type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a aircraft type"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }
  const renderOptionsAircraftUnit = () => {
    let options = [];
    _.forEach(aircraftUnits, (aircraft_unit, index) => {
      options.push(
        <Option key={index} value={aircraft_unit.value}>
          <div>{aircraft_unit.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a aircraft unit"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const [activeTab,setActiveTab] = useState("1");
  const onChangeTab = (key) => {
    setActiveTab(key);
  }

  const addHistory = (object, e) => {
    setVisibleHistory(true);
  }

  const cancelModalHistory = () => {
    addHistoryForm.resetFields();
    setVisibleHistory(false);
    setLoadingHistory(false);
  }

  return(
    <>
    <AssetModalWrapper
      visible={props.visibleAssetAircraft}
      onCancel={cancelModal}
      maskClosable={false}
      title={props.dataAsset.id ? <IntlMessages id="propertyPage.modal.asset.editAsset"/> : <IntlMessages id="propertyPage.modal.asset.addAsset"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
    {
        modalLoading ?
        <Loader /> : 
        <Tabs defaultActiveKey="1" className="form-body"
        onChange={onChangeTab}
        >
       
        <TabPane tab={<IntlMessages id="propertyPage.mobModal.detailTab" />} key="1">
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            fields={fields}
            id="#1"
          >
          <div>
            <Form.Item
              name={['asset', 'title']}
              className="isoInputWrapper"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
                {
                  max:255,
                  message: 'Title must be maximum 255 characters.'
                },
              ]}
            >
              <Input size="large" placeholder="Input asset title"/>
            </Form.Item>
            <Form.Item
              name={['asset', 'category']}
              className="isoInputWrapper"
              label="Category"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsAssetCategory()}
            </Form.Item>
            <Form.Item
              name={['asset', 'type']}
              className="isoInputWrapper"
              label="Inventory Type"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderOptionsInventoryType()}
            </Form.Item>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'aircraft_type']}
                  className="isoInputWrapper"
                  label="Aircraft Type"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionsAircraftType()}
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'storage_location']}
                  className="isoInputWrapper"
                  label="Storage Location"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionObject(props.primaryObjects)}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (prevValues['asset'] && (prevValues['asset'].aircraft_type !== currentValues['asset'].aircraft_type));
                }}
              >
                {({ getFieldValue }) => {
                    if(getFieldValue('asset')){
                      if (getFieldValue('asset').aircraft_type === "other"){
                        return (
                          <Form.Item
                            name={['asset', 'other_aircraft_type']}
                            className="isoInputWrapper"
                            label="Other Aircraft Type"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                                whitespace: true
                              },
                              {
                                max:255,
                                message: 'Other Aircraft Type must be maximum 255 characters.'
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Input other aircraft type"/>
                          </Form.Item>
                        );
                      }
                    }else{
                      if (assetData.aircraft_type === "other"){
                        return (
                          <Form.Item
                            name={['asset', 'other_aircraft_type']}
                            className="isoInputWrapper"
                            label="Other Aircraft Type"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                                whitespace: true
                              },
                              {
                                max:255,
                                message: 'Other Aircraft Type must be maximum 255 characters.'
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Input other aircraft type"/>
                          </Form.Item>
                        );
                      }
                    }
                  }
                }
            </Form.Item>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'aircraft_model']}
                  className="isoInputWrapper"
                  label="Aircraft Model"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'container_unit']}
                  className="isoInputWrapper"
                  label="Unit of measurement"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionsAircraftUnit()}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (prevValues['asset'] && (prevValues['asset'].container_unit !== currentValues['asset'].container_unit));
                }}
              >
                {({ getFieldValue }) => {
                    if(getFieldValue('asset')){
                      if (getFieldValue('asset').container_unit === "other"){
                        return (
                          <Form.Item
                            name={['asset', 'other_container_unit']}
                            className="isoInputWrapper"
                            label="Other Unit Of Measurement"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                                whitespace: true
                              },
                              {
                                max:255,
                                message: 'Other Unit Of Measurement must be maximum 255 characters.'
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Input other unit of measurement"/>
                          </Form.Item>
                        );
                      }
                    }else{
                      if (assetData.container_unit === "other"){
                        return (
                          <Form.Item
                            name={['asset', 'other_container_unit']}
                            className="isoInputWrapper"
                            label="Other Unit Of Measurement"
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                                whitespace: true
                              },
                              {
                                max:255,
                                message: 'Other Unit Of Measurement must be maximum 255 characters.'
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Input other unit of measurement"/>
                          </Form.Item>
                        );
                      }
                    }
                  }
                }
            </Form.Item>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'current_fuel_level']}
                  className="isoInputWrapper"
                  label="Current Fuel Level"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'volume']}
                  className="isoInputWrapper"
                  label="Fuel Capacity"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'state']}
                  className="isoInputWrapper"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionsAssetState()}
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'date_of_purchase']}
                  className="isoInputWrapper"
                  label="Date Of Purchase"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DatePicker size="large" style={{width: '100%'}} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (prevValues['asset'] && (prevValues['asset'].state !== currentValues['asset'].state));
              }}
            >
              {({ getFieldValue }) => {
                  if(getFieldValue('asset')){
                    if (getFieldValue('asset').state === "other"){
                      return (
                          <Form.Item
                          name={['asset', 'other_state']}
                          className="isoInputWrapper"
                          label="Other State"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Input other state"/>
                        </Form.Item>
                      );
                    }
                  }else{
                    if (assetData.state === "other"){
                      return (
                          <Form.Item
                          name={['asset', 'other_state']}
                          className="isoInputWrapper"
                          label="Other State"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Input other state"/>
                        </Form.Item>
                      );
                    }
                  }
                }
              }
            </Form.Item>
            <Row>
              <Col xs={{span: 24}} md={{span: 11}}>
                <Form.Item
                  name={['asset', 'manufacture_date']}
                  className="isoInputWrapper"
                  label="Manufacture Date"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DatePicker size="large" style={{width: '100%'}} />
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
                <Form.Item
                  name={['asset', 'primary_object_id']}
                  className="isoInputWrapper"
                  label="Location"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionObject(props.primaryObjects)}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name={['asset', 'description']}
              className="isoInputWrapper"
              label="Description"
            >
              <Input.TextArea />
            </Form.Item>
          </div>  
      </Form>
      </TabPane>
      <TabPane tab={<IntlMessages id="propertyPage.mobModal.historyTab" />} key="2">
        <Row>
          <Col xs={{span: 7}} md={{span: 7}}>
            {(activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") &&
            <Button key="submit" className="btn-create-breed" type="primary" onClick={addHistory}>
              {<IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />}
            </Button>}
          </Col>
          <Col xs={{span: 15}} md={{span: 17}}>
            
          </Col>
          
        </Row>
        <br/>
        <AssetHistory assetHistory={assetHistory} updateDataAssetHistory={updateDataAssetHistory} dataId={assetData.id}/>
        <AddAssetHistoryModal        
          visibleHistory={visibleHistory} 
          cancelModal={cancelModalHistory}
          primaryObjects={props.primaryObjects}
          model='facilities'
          dataId={assetData.id}
          propertyId={props.propertyId}
          updateDataAssetHistory={updateDataAssetHistory}
        />
      </TabPane>
      </Tabs>
      }     
    </AssetModalWrapper>
    </>
  )
}

export default EditAircraftModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;