import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const PurposeWrapper = styled.div`
  .text-title {
    line-height: normal;
    margin-bottom: 32px;
  }
  .image-section{
    img{
      position: absolute;
      top: 35%;
      left: 20%;
      right: 20%;
      @media only screen and (max-width: 767px) {
        top: 35%;
        left: 10%;
        right: 10%;
      }
    }
  }
`;

export default WithDirection(PurposeWrapper);
