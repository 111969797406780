import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Form, InputNumber, Button, Row, Col} from 'antd';
import { propertyService } from '@iso/services';
import IotDeviceModalWrapper from './IotDeviceModal.styles';

const IotDeviceModal = (props) => {
  const {property,onSaved,cancelIotDevice} = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if(property){
     setFields([
        {
          name: 'device_time',
          value: property.device_time ? property.device_time : ''
        }, 
      ]);
    }
    
  }, [property]);


  const handleSubmit = () =>{
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });

  }
  const onStore = (data) =>{
    propertyService.updateDeviceTime(property.id,data).then(res => {
      if(res.code === '0000'){
        onSaved();
        setLoading(false);
        cancelIotDevice();
      }
    });
  }

  return (
    <IotDeviceModalWrapper
      visible={props.visibleIotDevice}
      onCancel={cancelIotDevice}
      title="IOT Device"
      footer={[
        <Button key="back" onClick={cancelIotDevice} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        scrollToFirstError
        layout="vertical"
        fields={fields}
        >
         <div className="form-body">
            <Form.Item
              name="device_time"
              className="isoInputWrapper"
              label="Times"
            >
              <InputNumber addonAfter="Minutes"  size="large"/>
            </Form.Item>
         </div>
      </Form>
    </IotDeviceModalWrapper>
  )

};

export default IotDeviceModal;