import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const PropertyAreaPage = styled.div`
  width: 100%;
  height: 100%;
  .search-bar {
    background: rgba(143, 146, 161, 0.05);
    border-radius: 12px;
    border: none;
    height:40px;
    margin-left: 2rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    flex-direction: row-reverse;
    max-width: 228px;
  }
  .site-form-item-icon {
    height: 19px;
    width: 19px;
    color: #8F92A1;
    font-size: 19px;
  }
  .site-form-item-cancel {
    height: 15px;
    width: 15px;
    color: #8F92A1;
    font-size: 15px;
  }
  .search-bar .ant-input {
    background: none!important;
    border: none!important;
    color: #8F92A1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .button-group {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .btn-success{
      background-color: #0B2A1B !important;
      border-color: #0B2A1B;
      height : 40px ;
      max-width : 150px;
      border-radius : 8px;
      &:hover{
        opacity: 0.8;
      }
    } 
    .btn-import{
      max-width: 150px;
      height: 40px ;
      border-radius: 8px;
      border: 1px;
      background :#797a77;
      &:hover{
        opacity: 0.8;
      }
    }  
    .btn-export{
      max-width: 150px;
      height: 40px ;
      border-radius: 8px;
      border: 1px;
      background : #3D4337;
      &:hover{
        opacity: 0.8;
      }
    }   
    .btn-download{
      max-width: 150px;
      height: 40px ;
      border-radius: 8px;
      border: 1px;
      background :#306623;
      &:hover{
        opacity: 0.8;
      }
    }  
  }

    @media ( max-width: 767px){
      flex-wrap: wrap;
      button{
        margin-bottom: 10px;
      }
    }
  .manage-property-header {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    margin: auto;
  }
  .manage-table .react-collapsible tbody .name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  }
  .manage-property-header > .mp-title {
    width: 15%;
  }
  .manage-property-table {
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background: white;
    .ant-pagination {
      padding: 24px;
    }
  }
  .ant-table-wrapper {
    min-width: 100%;
  }
  .fAgWDa .ant-table-content{
    overflow-x: unset;
  }
  .fAgWDa{
    overflow-x: unset;
  }
  .dtr-data{
    white-space: break-spaces;
  }
  .delete-button{
    margin-left:8px;
  }
  .dataTables_filter{
    margin-bottom:10px;
  }
  .dataTables_filter .input-sm{
    margin-left:10px;
  }
  .dataTables_filter .input-sm{
    margin-left:10px;
    text-align:center;
    outline-offset: unset;
    border-radius: 8px;
  }
  .ant-pagination-item-active{
    border-color:#0B2A1B;
    a{
      color:#0B2A1B;
    }
  }
  .ant-pagination-item:focus, .ant-pagination-item:hover{
    border-color:#0B2A1B;
    a{
      color:#0B2A1B;
    }
  }
  .ant-spin-dot-item{
    background-color: #0B2A1B;
  }
  .ant-pagination-item-link:hover{
    border-color: #0B2A1B;
    color:#0B2A1B;
  }
  .react-collapsible-theme .react-collapsible-search input{
    text-align: center;
    border: 0;
    border: 5px #C9C9CE solid;
    border-radius: 6px;
    width: 100%;
    height: 2rem;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65)
  }
  .search-icon{
    top:5px!important;
    left:15px!important;
    width:20px;
  }

  .clear-icon{
    position: relative;
    top:5px!important;
    right:2px!important;
  }

  .search-icon svg{
    color: rgba(0, 0, 0, 0.65)
  }

  .action-button.view-history-button, .action-button{
    margin-bottom:5px;
    margin-top:5px;
  }
.colorclass{
  display: flex;
  padding: 5px 10px;
  border-radius: 10px;
  gap: 6px;
  max-width: 130px;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .manage-property-header  {
    display : block !important;
  }
  .mp-title{
    width : 22% ! important;
  }

   .button-group{
    flex-wrap: wrap;
    display: flex;
    gap : 1rem;
  }
}

@media only screen and (max-width: 425px) {
  .manage-property-header  {
    display : block !important;
  }
  .mp-title{
    width : 40% ! important;
  }

   .button-group{
    flex-wrap: wrap;
    display: flex;
    gap : 1rem;
  }
  .search-bar{
    margin-left: 0rem !important;
    max-width : 300px;
  }
  .btn-success{
    min-width : 300px !important;
   }
   .btn-export{
    min-width : 300px !important;
  }
  .btn-download{
    min-width : 300px !important;
  }
  .btn-import{
    min-width : 300px !important;
    
  }
}

@media only screen and (max-width: 320px) {
  .manage-property-header  {
    display : block !important;
  }
  .mp-title{
    width : 40% ! important;
  }

   .button-group{
    flex-wrap: wrap;
    display: flex;
    gap : 1rem;
  }
  .search-bar{
    margin-left: 0rem !important;
    max-width : 300px;
  }
  .btn-success{
    min-width : 200px !important;
   }
   .btn-export{
    min-width : 200px !important;
  }
  .btn-download{
    min-width : 200px !important;
  }
  .btn-import{
    min-width : 200px !important;
    
  }
}

@media only screen and (max-width :1024px)
{
  .manage-property-header  {
    flex-wrap : wrap !important;
  }
  .button-group{
    margin-top : 20px;
    justify-content: flex-start;
  }
  .btn-success{
   min-width : 210px;
  }

  .btn-export{
    min-width : 210px ;
  
  }
  .btn-download{
    min-width : 210px ;
  }
  .btn-import{
    min-width : 210px ;
  }
}

`;

export default WithDirection(PropertyAreaPage);
