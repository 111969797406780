import React from "react";
import CheckinUserProfileWrapper from "@iso/containers/Pages/Profile/CheckinUserProfileModal.style";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Button } from "antd";
import Profile from "@iso/containers/Pages/Profile/Profile";

const CheckinUserProfileModal = ({
  isVisible,
  cancelModal,
  formId,
  userId,
}) => {
  return (
    <CheckinUserProfileWrapper
      className="user-access-modal"
      forceRender={true}
      maskClosable={false}
      visible={isVisible}
      title={<IntlMessages id="profileModal.title" />}
      onCancel={cancelModal}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
      ]}
    >
        <Profile formId={formId} userId={userId}></Profile>
    </CheckinUserProfileWrapper>
  );
};

export default CheckinUserProfileModal;
