import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const profileService = {
  getProfile,
  updateProfile,
  checkCurrentPassword,
  updateCurrentProfile,
  getCheckinFormUserProfile,
  requestShareProfile
};

function getProfile(){
  return (
    axios
      .get(
        apiEndpoint.profiles,
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getCheckinFormUserProfile(form_id, user_id){
  return (
    axios
      .get(
        `${apiEndpoint.profiles}/get-checkin-form-user-profile?form_id=${form_id}&user_id=${user_id}`,
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function updateProfile(data, id = null){
  return (
    axios
      .put(
        apiEndpointFunction.profiles(id),
        data,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function checkCurrentPassword(current_password, id = null){
  return (
    axios
      .post(
        `${apiEndpointFunction.profiles(id)}/check-current-password`,
        current_password,
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }        
      })
  );
}

function updateCurrentProfile(data){
  return (
    axios
      .put(
        `${apiEndpoint.profiles}/update-profile`,
        data,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function requestShareProfile(form_id, user_id){
  return (
    axios
      .post(
        `${apiEndpoint.profiles}/request-share-profile`,
        {
          form_id,
          user_id
        },
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}