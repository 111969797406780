import React, { useState, useEffect } from 'react';
import { Collapse, Form, Input, Button, Select, Row, Col, DatePicker, Tag, Tabs, message, Table, Space } from 'antd';
// import Table from "@iso/components/collapsing-table/table";
import IntlMessages from '@iso/components/utility/intlMessages';
import moment from 'moment'
import {
  PaperClipOutlined
} from "@ant-design/icons";
const { Panel } = Collapse;
const tableStyle = {
  overflow: 'auto',
  width: '100%', // Ensure the table takes the full available width
  whiteSpace: 'nowrap', // Prevent text from wrapping
  overflowX: 'auto', // Add horizontal scrolling if content overflows
};
const CollapseHealthSafety = (props) => {
  const { healthAndSafetyPolicies, healthAndSafetyAssets } = props;
  const replaceText = (text) => {
    return (
      <div key={text}>
        <a
          key='1'
          href={process.env.REACT_APP_API_BASE_URL + text}
          download={process.env.REACT_APP_API_BASE_URL + text}
          target="_blank"
        >
          {text.toString().replace("/uploads/attachments/", "")}
        </a>
        <br />
      </div>
    );
  };

  //Asset History columns
  const policyColumns = [
    {
      title: 'Title',
      dataIndex: 'assetName',
      key: 'assetName',
      fixed: 'left',
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div key={text}>
          <span>
            {text.length > 40 ? (
              <span title={text}>
                {`${text.slice(0, 40)}...`}
              </span>
            ) : text}
            {record.attachment && record.attachment.length > 0 && (
              <PaperClipOutlined style={{ marginLeft: 5, color: '#0B2A1B' }} />
            )}
          </span>
        </div>
      ),
    },
    {
      title: <IntlMessages id="Type    " />,
      dataIndex: 'type',
      key: 'type',
      render: (type) => type ? type.replace(/_/g, '') : null,
   
    },
    {
      title: <IntlMessages id="healthSafety.history.dateTime" />,
      dataIndex: 'date_time',
      key: 'date_time',
      render: (date_time) => moment(date_time).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      title: <IntlMessages id="Attachment" />,
      dataIndex: 'attachment',
      key: 'attachment',

      render: (attachment) => attachment.map((value) => replaceText(value.url))
    },
    {
      title: <IntlMessages id="healthSafety.history.meetingName" />,
      dataIndex: 'metadata',
      key: 'metadata',

      render: (metadata) => metadata.meeting_name,
    },
    {
      title: <IntlMessages id="healthSafety.history.attendees" />,
      dataIndex: 'metadata',
      key: 'metadata',
      render: (metadata) => metadata.attendees ? metadata.attendees.map((value, key) => {
        return (
          <Tag key={value} color="#0B2A1B">{value}</Tag>
        );
      }) : "",
    },
    {
      title: <IntlMessages id="healthSafety.history.notes" />,
      dataIndex: 'metadata',
      key: 'metadata',
      render: (metadata) => metadata.notes,
    },
    {
      title: <IntlMessages id="healthSafety.history.actions" />,
      dataIndex: 'metadata',
      key: 'metadata',
      render: (metadata) => metadata.actions,
    },
    {
      title: <IntlMessages id="healthSafety.history.lat" />,
      dataIndex: 'metadata',
      key: 'metadata',
      render: (metadata) => metadata.point ? metadata.point.lat : '',
    },
    {
      title: <IntlMessages id="healthSafety.history.lng" />,
      dataIndex: 'metadata',
      key: 'metadata',
      render: (metadata) => metadata.point ? metadata.point.lng : '',
    },
    {
      title: <IntlMessages id="healthSafety.history.comment" />,
      dataIndex: 'metadata',
      key: 'metadata',
      render: (metadata) => metadata.comments,
    }
  ];

  //Health And Safety Assets columns
  const assetsColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div key={text}>
          <span>
            {text.length > 40 ? (
              <span title={text}>
                {`${text.slice(0, 40)}...`}
              </span>
            ) : text}
            {record.attachment && record.attachment.length > 0 && (
              <PaperClipOutlined style={{ marginLeft: 5, color: '#0B2A1B' }} />
            )}
          </span>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: 'type_label',
      key: 'type_label',
      render: (text, record) => (
        <span>
          {text && text.length > 40 ? (
            <span title={text}>
              {`${text.slice(0, 40)}...`}
            </span>
          ) : text}
        </span>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.length - b.description.length,
      render: (text, record) => (
        <span>
          {text && text.length > 40 ? (
            <span title={text}>
              {`${text.slice(0, 40)}...`}
            </span>
          ) : text}
        </span>
      ),
    },
    {
      title: <IntlMessages id="Attachment" />,
      dataIndex: 'attachment',
      key: 'attachment',
      width: 'auto',
      render: (attachment) => attachment.map((value) => replaceText(value.url))
    },
    {
      title: 'Last Checked Date',
      dataIndex: 'last_checked_date',
      key: 'last_checked_date',
      render: (date_time) => date_time ? moment(date_time).format('DD/MM/YYYY') : ''
    },
    {
      title: 'Creation Date',
      dataIndex: 'creation_date',
      key: 'creation_date',
      render: (date_time) => date_time ? moment(date_time).format('DD/MM/YYYY') : ''
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (date_time) => date_time ? moment(date_time).format('DD/MM/YYYY') : ''
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Manufacture Date',
      dataIndex: 'manufacture_date',
      key: 'manufacture_date',
      render: (date_time) => date_time ? moment(date_time).format('DD/MM/YYYY') : ''
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags) => tags ?  <Tag key={tags} color="#0B2A1B">{tags}</Tag> : ''
    },
    {
      title: <IntlMessages id="healthSafety.history.lat" />,
      dataIndex: 'lat',
      key: 'lat',
    },
    {
      title: <IntlMessages id="healthSafety.history.lng" />,
      dataIndex: 'lng',
      key: 'lng',
    },
  ];

  const onChange = (key) => {

  };

  return (

    <Collapse defaultActiveKey={['1']} onChange={onChange}>
      <Panel header="Assets" key="1">
        <Table dataSource={healthAndSafetyAssets} columns={assetsColumns} rowKey={assetsColumns.key} key={assetsColumns.key} pagination={{ pageSize: 10 }} style={tableStyle} />
      </Panel>

      <Panel header="Acitivities" key="2">
        <Table dataSource={healthAndSafetyPolicies} columns={policyColumns} rowKey={policyColumns.key} key={policyColumns.key} pagination={{ pageSize: 10 }} style={tableStyle} />
      </Panel>
      
    </Collapse>
  )
}
export default CollapseHealthSafety;
