import React, {useState, useEffect} from "react";
import { Upload, message, Button } from "antd";
import { apiEndpoint } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";
import axios from "axios";
import imageCompression from "browser-image-compression";
import $ from "jquery";
import { FileSizeLimit, FileStorage } from "@iso/constants/UploadFile";
import { UploadOutlined } from "@ant-design/icons";
import { commonHelper } from '@iso/lib/helpers/commonHelper';

const compressOptions = {
  maxSizeMB: FileSizeLimit.image,
  maxWidthOrHeight: 1920,
};

const defaultStorage = process.env.REACT_APP_DEFAULT_FILE_STORAGE

const FileUploader = ({
  name = "Image[file]",
  action = apiEndpoint.images,
  storage = defaultStorage ? defaultStorage : FileStorage.azure_blob,
  type = null,
  data = {},
  multiple = false,
  isCompressFile = false,
  handleOnChange = null,
  handleOnSuccess = null,
  handleOnFail = null,
  handleOnRemove = null,
  handleOnPreview = null,
  handleValidateFile = null,
  defaultFileList =[],
  listType= "picture",
  innerElement = null,
  fileList,
  showUploadList = true,
  maxCount = null
}) => {
  const [className, setClassName] = useState('custom-file-uploader')
  useEffect(()=>{
    setClassName('custom-file-uploader-' + commonHelper.generateRandomString()) 
  }, [])
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    let newFile = file;
    try {
      const compressedFile = await imageCompression(file, compressOptions);
      newFile = new File([compressedFile], compressedFile.name);
    } catch (error) {
      console.log(error);
    }

    const fmData = new FormData();
    const headerConfig = authHeader(true);
    headerConfig["content-type"] = "multipart/form-data";
    const config = {
      headers: headerConfig,
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append(name, newFile);
    fmData.append("type", type);
    fmData.append("storage", storage);
    if (data) {
      for (let key in data) {
        fmData.append(key, data[key]);
      }
    }
    try {
      const res = await axios.post(action, fmData, config);

      onSuccess(res.data);
    } catch (err) {
      console.log("Eroor: ", err);
      onError({ err });
    }
  };

  const uploadProps = {
    name: name,
    action: action,
    headers: authHeader(true),
    customRequest: isCompressFile ? uploadImage : null,
    beforeUpload: async (file, fileList) => {
      console.log("VALIDATE");
      const isValidFile = handleValidateFile ? handleValidateFile(file) : await validateFile(file);
      if (!isValidFile) {
        return false;
      }
      if(!multiple){
        let $oldOne = $(`.${className} .anticon-delete`)[0];
        setTimeout(() => {
          if ($oldOne) $($oldOne).trigger("click");
        }, 100);
      }
    },
    onChange: (response) => {
      handleOnChange && handleOnChange(response);
      if (response.file && response.file.status == "done") {
        let res = response.file.response;
        if (res.code === "0000") {
          switch(name){
            case 'Image[file]':
              handleOnSuccess && handleOnSuccess(res.image);
              break;
            case 'Attachment[file]':
              handleOnSuccess && handleOnSuccess(res.attachment);
              break;
            case 'Kmz[file]':
              handleOnSuccess && handleOnSuccess(res.kmz);
              break;
              default:
                break;
          }
        } else {
          handleOnFail ? handleOnFail(res.message) : message.error(res.message);
        }
      }
    },
    onRemove: file => {
      handleOnRemove && handleOnRemove(file)
    },
    onPreview: async (file) => {
      handleOnPreview && handleOnPreview(file)
    },
    listType: listType,
    multiple: multiple,
    data: {
      type: type,
      storage: storage,
    },
    showUploadList: showUploadList,
    maxCount: maxCount,
    defaultFileList: defaultFileList,
    fileList: fileList
  };

  const validateFile = async (file) => {
    let newFile = file;
    if (isCompressFile) {
      try {
        const compressedFile = await imageCompression(file, compressOptions);
        newFile = new File([compressedFile], compressedFile.name);
      } catch (error) {
        console.log(error);
      }
    }
    let fileType = file.type.split("/");
    let fileSize = Math.round(newFile.size / 1000000); // MB
    if (fileType[0] && fileType[0] === "video") {
      if (fileSize > FileSizeLimit.video) {
        message.error(`Video size must not exceed ${FileSizeLimit.video}MB`);
        return false;
      }
    } else {
      if (fileSize > FileSizeLimit.image) {
        message.error(`Image size must not exceed ${FileSizeLimit.image}MB`);
        return false;
      }
    }
    return true;
  };

  return (
    <Upload {...uploadProps} className={className}>
      {
        innerElement ? innerElement : 
        <Button className="action-button" icon={<UploadOutlined />}>
          Upload
        </Button>
      }
    </Upload>
  );
};

export default FileUploader;