import React,{useEffect, useState,useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@iso/components/utility/box';
import ButtonGroupWrapper from './ButtonGroup.styles'; 
import ModalActivity from './ModalActivity'; 
import { Row, Col, Button,Modal,message } from 'antd';
import { healthsafety,assetService } from '@iso/services';
import _ from 'lodash';
import IntlMessages from '@iso/components/utility/intlMessages';
import moment from 'moment';
import { useHistory ,useLocation } from 'react-router-dom';
import appActions from '@iso/redux/app/actions';
import { propertyService } from '@iso/services';
import styled from "styled-components";
import FormModal from '../Form/FormModal';

const { changeCurrent} = appActions;

const ButtonGroup = (props) => {
  const {propertyId,showModalHealthSafety} = props;
  const currentUser = useSelector((state) => state.user.user);
  const [type, setType] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ visibleFormModal, setVisiblityFormModal ]=React.useState( false );
  const [fields, setFields] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [visibleModalActivity,setVisibleModalActivity] = useState(false);
  const [activityActive,setActivityActive] = useState(false);

  const handleRedirectPage = (e,path) => {
    history.push(e);
    dispatch(changeCurrent([path]));
  }
  const cancelModalActivity = () => {
    setVisibleModalActivity(false);

  }
  const showModalActivity = (value) =>{
    setVisibleModalActivity(true);
    setActivityActive();
    if(value){
      setActivityActive(value);
    }
  }
  const handleOpenModal = () => {
    setVisiblityFormModal(true);
  };
  const cancelModal = () => {
    setVisiblityFormModal(false);
    // setFormData({});
  };
  return (
    <ButtonGroupWrapper>
      <div className="statistic-card">
        <Row gutter={[10,15]}>
          <Col xs={{span: 24}} md={{span: 12}} lg={{span:4}} sx = {{span:24}} onClick={() => showModalActivity('safety_walk')}>
            <Button block className="ant-btn--sf-dashboard">
              <span><IntlMessages id="safeFarmPage.dashboard.safetyWalk" /></span>
            </Button>
          </Col> 
          <Col xs={{span: 24}} md={{span: 12}} lg={{span:4}} sx = {{span:24}}>
            <Button className="ant-btn--sf-dashboard" block onClick={() => showModalActivity('hazard')}>
              <span><IntlMessages id="safeFarmPage.dashboard.hazardRisk" /></span>  
            </Button>
          </Col> 
          <Col xs={{span: 24}} md={{span: 12}} lg={{span:4}} sx = {{span:24}}>
            <Button className="ant-btn--sf-dashboard" block onClick={() => showModalActivity()}>
              <span><IntlMessages id="dashboard.historyChart.title" /></span>
            </Button>
          </Col>  
          <Col xs={{span: 24}} md={{span: 12}} lg={{span:4}} sx = {{span:24}}>
            <Button className="ant-btn--sf-dashboard" block onClick={() => showModalHealthSafety()} >
              <span><IntlMessages id="healthSafety.addHealthSafety" /> </span>
            </Button>
          </Col> 
          <Col xs={{span: 24}} md={{span: 12}} lg={{span:4}} sx = {{span:24}}>
            <Button className="ant-btn--sf-dashboard" block 
          onClick={handleOpenModal}
            // onClick={() => handleRedirectPage('manage-forms','')}
            >
              <span><IntlMessages id="safeFarmPage.dashboard.forms" /></span>
            </Button>
          </Col> 
          <Col xs={{span: 24}} md={{span: 12}} lg={{span:4}} sx = {{span:24}}>
            <Button className="ant-btn--sf-dashboard" block onClick={() => handleRedirectPage('map','map')}>
              <span><IntlMessages id="sidebar.propertyMap" /></span>
            </Button>
          </Col>
        </Row>
      </div>

      <ModalActivity 
        cancelModalActivity={cancelModalActivity} 
        visibleModalActivity={visibleModalActivity} 
        propertyId={propertyId} 
        getDataPolicy = {props.getDataPolicy}
        activityActive={activityActive}
        searchAssetAndPolicy={props.searchAssetAndPolicy}
      />
      <FormModal
     cancelModal ={cancelModal}
     visibleFormModal ={visibleFormModal}
     propertyId  ={propertyId}
     fromProperty={true}
     />
    </ButtonGroupWrapper>
   
  );
}

export default ButtonGroup;