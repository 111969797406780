import React, { useState, useEffect } from 'react';
import WeatherTableSection from './WeatherTable.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { PlusOutlined, DownOutlined, EditOutlined, DeleteOutlined,PlusCircleOutlined ,EllipsisOutlined} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import {  propertyService, weatherDataService } from '@iso/services';
import _ from 'lodash';
import styled from "styled-components";
import Table from '@iso/components/collapsing-table/table';
import Box from '@iso/components/utility/box';
import EditWeatherDataModal from './EditWeatherDataModal';

const { openModal } = modalActions;

const WeatherTable = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [weatherData, setWeatherData] = useState({});
  const [particularWeatherData, setParticularWeatherData] = useState({});
  const dispatch = useDispatch();
  const [visibleEditWeatherData, setVisibleEditWeatherData] = useState(false);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const currentUser = useSelector((state) => state.user.user);

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then(res => {
      if(res.code === '0000'){
        setPrimaryObjects(res.primary_objects);
      }
    })
  }, []);

  useEffect(() => {
    if(activePropertyId){
      updatePropertyDetail(activePropertyId);
    }    
  }, [activePropertyId, updatePropertyDetail]);

  useEffect(() => {
    if(props.weatherDataTable){
      setWeatherData(props.weatherDataTable); 
    }
  }, [props.weatherDataTable]);

  const updateWeatherData = () => {
    props.updateWeatherData();
  }

  const menu = (weather_data) => (
    <Menu onClick={handleMenuClick.bind(this, weather_data)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      {(activePermission == "owner" || activePermission == "admin") && <p style={{cursor:'pointer'}} className="deleteButton">
        <Popconfirm
                placement="bottomRight"
                title="You are about to delete individual record"
                onConfirm={() => {
                  confirmDelete(weather_data);
                }}
                okText="Yes"
                cancelText="No"
                className="delete-button"
        >
        <a style={{color:'rgba(0, 0, 0, 0.65)', marginLeft: '11px'}}>
          {<DeleteOutlined />}&nbsp;&nbsp;
          <IntlMessages id="propertyPage.managePage.delete" />
        </a>
        </Popconfirm>
      </p>}
    </Menu>
  );
  const handleMenuClick = (weather_data, e) => {
    if(e.key === 'editDetails'){
      setParticularWeatherData(weather_data);
      setVisibleEditWeatherData(true);
    }
  }

  const cancelModal = () => {
    setVisibleEditWeatherData(false);
    //setEditProperty({});
  }

  const confirmDelete = (del_weather_data) => {
    if(del_weather_data.id){
       weatherDataService.destroy(del_weather_data.id).then(res => {
        if(res.code === '0000'){
          props.updateWeatherData();
          props.updateWeatherChart(primaryObjects[0].id);
        }
      });
    }

  }

  const columns = [
    {
      label: <IntlMessages id="weatherPage.table.timelog" />,
      accessor: 'timelog',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
    },
    {
      label: <IntlMessages id="weatherPage.table.temperature" />,
      accessor: 'temperature',
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2
    },
    {
      label: <IntlMessages id="weatherPage.table.rain" />,
      accessor: 'rain',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: <IntlMessages id="weatherPage.table.duration" />,
      accessor: 'duration',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
    },
    
    {
      label: <IntlMessages id="weatherPage.table.location" />,
      accessor: 'primary_object_name',
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
    },
    {
      label: <IntlMessages id="weatherPage.table.propertyArea" />,
      accessor: 'property_name',
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
    },
    {
      label: <IntlMessages id="weatherPage.table.createdBy" />,
      accessor: 'username',
      minWidth: 100,
      sortable: true,
      position: 7,
      priorityLevel: 7,
    },
    {
      label: <IntlMessages id="weatherPage.table.comment" />,
      accessor: 'comment',
      minWidth: 100,
      sortable: true,
      position: 8,
      priorityLevel: 8
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 100,
      sortable: true,
      position: 9,
      priorityLevel: 9,
      noTitle: true,
      CustomComponent: cell => {
        let weather_data = cell.row
        return (
          (currentUser.id === weather_data.user_id) ? 
          (
          <Dropdown overlay={menu(weather_data)} trigger={["click"]}>
          <div
            className="property-area-dot-btn"
            style={{ cursor: "pointer" }}
          >
            <EllipsisOutlined className="dotIcon" />
          </div>
        </Dropdown>):''
        );
      },
    },
  ];
  return (
    <WeatherTableSection>
      <Box id="containerBox">
        {
          weatherData.length > 0 &&
        //<SimpleTable columns={columns} dataList={livestocks ? new fakeData(livestocks.length, livestocks) : {} } pagination={{pageSize: 50}} />
        <Table 
          columns={columns} 
          rows={weatherData}
          containerId="containerBox"
          showPagination={false}
          rowSize={99999}
          className="tablecust"
  
        />            
        }
      </Box>
      <EditWeatherDataModal visibleEditWeatherData={visibleEditWeatherData} 
      particularWeatherData={particularWeatherData} cancelModal={cancelModal} primaryObjects={primaryObjects} 
      updateWeatherData={updateWeatherData} propertyId={activePropertyId}/>
    </WeatherTableSection>
  )
}

export default WeatherTable;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;