import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import LicenceModalWrapper from './LicenceModal.styles';
import { Form, Input, Button, Select, Row, Col, DatePicker, Upload, Radio, List,message } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { imageService, profileService } from '@iso/services';
import userActions from '@iso/redux/user/actions';

const { Option } = Select;
const { store } = userActions;

const UploadAvatarModal = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user.user);
  const [sampleAvatar, setSampleAvatar] = useState([]);
  const [avatarValue, setAvatarValue] = useState();

  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then(values => {
        onStore(values);
        setLoading(false);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
    
  }

  const onStore = (values) => {
    const data = {
        "user":{
          image_id: avatarValue,
          sample_avatar: 1
        }
      }
      profileService.updateCurrentProfile(data).then(res => {
        if(res.code === '0000'){
          dispatch(store());
          props.updateProfileData();
          form.resetFields();
          setTimeout(() => {
            message.success('Save Successfully!');
          }, 1000);
          cancelModal();
        } else {
          setLoading(false);
          setTimeout(() => {
            message.error('Save Fail!');
          }, 1000);
        }
      });
  }

  const getSampleAvatar = () => {
    imageService.getList('sample_avatar').then(res => {
      if(res.code === '0000'){
        setSampleAvatar(res.images);
      }
    });
  }

  useEffect(() => {
      getSampleAvatar();
  }, []) 

  const cancelModal = () => {
    props.cancelUploadAvatarModal();
    setAvatarValue();
  }


  const onChange = e => {
    console.log('radio checked', e.target.value);
    setAvatarValue(e.target.value);
  };

  return(
    <>
    <LicenceModalWrapper
      visible={props.visibleUploadAvatar}
      onCancel={cancelModal}
      maskClosable={false}
      centered
      title={<IntlMessages id="profilePage.licencesTab.uploadAvatar"/>}
      footer={[        
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" loading={loading} onClick={handleSubmit}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
      >
        <div className="form-body">
          <Row>
            {sampleAvatar && <><div style={{ overflow: "scroll", width: "100%", height: "200px" }}>
                  <Radio.Group onChange={onChange} value={avatarValue}>
                  {(sampleAvatar) ? (sampleAvatar.map((value, index) => {
                          return (
                           <Radio value={value.id}><img className="image-gallery" style={{width:"50px", height:"50px"}} src={process.env.REACT_APP_API_BASE_URL + value.path} /></Radio>
                          )
                        }  
                      )
                    ) : ''
                  }
                  </Radio.Group>

                </div></>
            }
          </Row>
        </div>    
      </Form>
    </LicenceModalWrapper>
    </>
  )
}

export default UploadAvatarModal;