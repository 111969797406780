import React,{ useState, useEffect } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import Box from '@iso/components/utility/box';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import { useSelector } from 'react-redux';
// import { configs } from '@iso/components/Tables/configs';
import { Row, Col, Button, Dropdown, Menu, Popconfirm } from 'antd';
import ManageProductCategoryPage from './ManageProductCategory.styles';
import Table from '@iso/components/collapsing-table/table';
import { EditOutlined, PlusOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { marketplaceCategoryService } from '@iso/services';
import moment from 'moment';
import ProductCategoryModal from './ProductCategoryModal';

const ManageProductCategory = () => {
  const currentUser = useSelector((state) => state.user.user);
  const [productCategories, setProductCategories] = useState([]);
  const [visibleProductCategoryModal, setVisibleProductCategoryModal] = useState(false);
  const [productCategoryData, setProductCategoryData] = useState({});

  const getProductCategories = () => {
    marketplaceCategoryService.getList().then(res => {
      if(res.code === '0000'){
        setProductCategories(res.marketplace_categories);
      }
    });
  }

  useEffect(() => {
      getProductCategories();
  }, []) 

  const menu = (product_category) => (
    <Menu onClick={handleMenuClick.bind(this, product_category)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      {(currentUser.is_system_admin == 1) && <p style={{cursor:'pointer'}} className="deleteButton">
        <Popconfirm
                placement="bottomRight"
                title="You are about to delete individual record"
                onConfirm={() => {
                  confirmDelete(product_category);
                }}
                okText="Yes"
                cancelText="No"
                className="delete-button"
        >
        <a style={{color:'rgba(0, 0, 0, 0.65)', marginLeft: '11px'}}>
          {<DeleteOutlined />}&nbsp;&nbsp;
          <IntlMessages id="propertyPage.managePage.delete" />
        </a>
        </Popconfirm>
      </p>}
    </Menu>
  );

  const handleMenuClick = (product_category, e) => {
    if(e.key === 'editDetails'){
      setProductCategoryData(product_category);
      showProductCategoryModal();
    }
  }

  const confirmDelete = (del_product_category) => {
    if(del_product_category.id){
       marketplaceCategoryService.destroy(del_product_category.id).then(res => {
        if(res.code === '0000'){
          getProductCategories();
        }
      });
    }
  }

  const showProductCategoryModal = () => {
    setVisibleProductCategoryModal(true);
  }

  const cancelProductCategoryModal = () => {
    setVisibleProductCategoryModal(false);
    setProductCategoryData({});
  }

  const columns = [
    {
      label: <IntlMessages id="adminPage.manageProductCategory.name" />,
      accessor: 'name',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="adminPage.manageProductCategory.type" />,
      accessor: 'object_type',
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2
    },
    {
      label: <IntlMessages id="adminPage.manageProductCategory.productAmount" />,
      accessor: 'product_amount',
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3
    },
    {
      label: <IntlMessages id="adminPage.manageProductCategory.createdAt" />,
      accessor: 'created_at',
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let product_category = cell.row
        return moment(product_category.created_at).format('L');
      }
    },
    {
      label: <IntlMessages id="adminPage.manageProductCategory.updatedAt" />,
      accessor: 'updated_at',
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      CustomComponent: cell => {
        let product_category = cell.row
        return moment(product_category.updated_at).format('L');
      }
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      noTitle: true,
      CustomComponent: cell => {
        let product_category = cell.row
        return (
            <Dropdown overlay={menu(product_category)} trigger={['click']}>
              <Button className="action-button">
                <IntlMessages id="antTable.title.actions" /> <DownOutlined />
              </Button>
            </Dropdown>
        );
      },
    },
  ];

  return (
    <LayoutWrapper>
      <ManageProductCategoryPage>
        <PageHeader>
          {<IntlMessages id="adminPage.manageProductCategory.header" />}
        </PageHeader>
        <div className="button-group">
          {(currentUser.is_system_admin == 1) 
          && <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={showProductCategoryModal}>
            <IntlMessages id="adminPage.manageProductCategory.addProductCategory" />
          </Button>}
        </div>
        <Box id="containerBox">
        {
          productCategories.length > 0 &&
          <Table 
              columns={columns} 
              rows={productCategories}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            />
        }
        </Box>
        <ProductCategoryModal visibleProductCategoryModal={visibleProductCategoryModal} 
        cancelProductCategoryModal={cancelProductCategoryModal} productCategoryData={productCategoryData} 
        getProductCategories={getProductCategories}/>

      </ManageProductCategoryPage>
    </LayoutWrapper>
  );
}

export default ManageProductCategory;
