import React from "react";
import { Form, Input, Table, Checkbox } from "antd";
import BioCheckbox from "../Components/BioCheckbox";
const { TextArea } = Input;

const ProductionPracticesTable = (props) => {
  const columns = [
    {
      title: "3",
      dataIndex: "order",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 5, 13].includes(index)) {
          return { rowSpan: 3 };
        }
        if ([8, 10, 16, 19].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 3, 6, 7, 9, 11, 14, 15, 17, 20].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "PRODUCTION PRACTICES",
      dataIndex: "production_practices",
      rowScope: "row",
      colSpan: 7,
      onCell: (_, index) => {
        if ([1, 5, 13].includes(index)) {
          return { rowSpan: 3 };
        }
        if ([8, 10, 16, 19].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 3, 6, 7, 9, 11, 14, 15, 17, 20].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
      width: 500,
    },
    {
      title: "Yes",
      colSpan: 0,
      dataIndex: "yes",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 5, 13].includes(index)) {
          return { rowSpan: 3 };
        }
        if ([8, 10, 16, 19].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 3, 6, 7, 9, 11, 14, 15, 17, 20].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "No",
      colSpan: 0,
      dataIndex: "no",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 5, 13].includes(index)) {
          return { rowSpan: 3 };
        }
        if ([8, 10, 16, 19].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 3, 6, 7, 9, 11, 14, 15, 17, 20].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "N/A",
      colSpan: 0,
      dataIndex: "na",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 5, 13].includes(index)) {
          return { rowSpan: 3 };
        }
        if ([8, 10, 16, 19].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 3, 6, 7, 9, 11, 14, 15, 17, 20].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "Recommended measures ",
      colSpan: 0,
      dataIndex: "recommended_measures",
      rowScope: "row",
      width: 500,
    },
    {
      title: "Actioned ?",
      colSpan: 0,
      dataIndex: "actioned",
      rowScope: "row",
    },
    {
      title: "Comments",
      colSpan: 0,
      dataIndex: "comments",
      rowScope: "row",
      onCell: (_, index) => {
        if ([1, 5, 13].includes(index)) {
          return { rowSpan: 3 };
        }
        if ([8, 10, 16, 19].includes(index)) {
          return { rowSpan: 2 };
        }
        if ([2, 3, 6, 7, 9, 11, 14, 15, 17, 20].includes(index)) {
          return { rowSpan: 0 };
        }
        return {};
      },
      className: "comment-column",
    },
  ];

  const data = [
    {
      key: "1",
      order: "3.1",
      production_practices: "Livestock monitoring",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures ",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "2",
      order: "3.1.1",
      production_practices:
        "Are livestock inspected regularly to ensure the early detection of sick animals?",
      yes: <BioCheckbox name="production_practices_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="livestock_monitoring_1_no"></BioCheckbox>,
      na: <BioCheckbox name="production_practices_1_na"></BioCheckbox>,
      recommended_measures: "Routine stock inspections conducted. ",
      actioned: (
        <BioCheckbox name="livestock_monitoring_1_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="livestock_monitoring_1_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "3",
      order: "",
      production_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Frequency of livestock inspections increased during periods of higher risk (e.g. increased insect and wildlife activity or growing periods for weeds).",
      actioned: (
        <BioCheckbox name="livestock_monitoring_1_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "4",
      order: "",
      production_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Aware of the signs and symptoms of infectious diseases",
      actioned: (
        <BioCheckbox name="livestock_monitoring_1_3_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "5",
      order: "3.2",
      production_practices: "Animal health management",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "6",
      order: "3.2.1",
      production_practices:
        "Have you implemented practices that help protect your livestock from diseases endemic to your region?",
      yes: <BioCheckbox name="animal_health_management_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="animal_health_management_1_no"></BioCheckbox>,
      na: <BioCheckbox name="animal_health_management_1_na"></BioCheckbox>,
      recommended_measures:
        "Best practice management practices for livestock health and welfare documented and updates reviewed as they arise.",
      actioned: (
        <BioCheckbox name="animal_health_management_1_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="animal_health_management_1_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "7",
      order: "",
      production_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Subscribed to local bulletins and in regular communication with local vet about disease risks.",
      actioned: (
        <BioCheckbox name="animal_health_management_1_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "8",
      order: "",
      production_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "In regular contact with neighbours to discuss any issues on their farms.",
      actioned: (
        <BioCheckbox name="animal_health_management_1_3_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "9",
      order: "3.2.2",
      production_practices:
        "Do you seek advice from a veterinarian or government officer in relation to any unusual sickness or death event?",
      yes: <BioCheckbox name="animal_health_management_2_yes"></BioCheckbox>,
      no: <BioCheckbox name="animal_health_management_2_no"></BioCheckbox>,
      na: <BioCheckbox name="animal_health_management_2_na"></BioCheckbox>,
      recommended_measures:
        "Unusual signs of disease reported as soon as possible to vet or local animal health authority.",
      actioned: (
        <BioCheckbox name="animal_health_management_2_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="animal_health_management_2_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "10",
      order: "",
      production_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "In the event of a disease outbreak, affected and suspected animals isolated and treated where necessary.",
      actioned: (
        <BioCheckbox name="animal_health_management_2_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "11",
      order: "3.2.3",
      production_practices:
        "Have you implemented any strategies for managing livestock diseases onfarm (e.g. Johne’s disease)?",
      yes: <BioCheckbox name="animal_health_management_3_yes"></BioCheckbox>,
      no: <BioCheckbox name="animal_health_management_3_no"></BioCheckbox>,
      na: <BioCheckbox name="animal_health_management_3_na"></BioCheckbox>,
      recommended_measures:
        "Where applicable, relevant disease management strategies documented and reviewed on a regular basis.",
      actioned: (
        <BioCheckbox name="animal_health_management_3_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="animal_health_management_3_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "12",
      order: "",
      production_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Veterinary advice on disease risks sought where relevant.",
      actioned: (
        <BioCheckbox name="animal_health_management_3_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "13",
      order: "3.3",
      production_practices: "Carcase, manure, and waste management",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "14",
      order: "3.3.1",
      production_practices:
        "Are carcase disposal and household garbage areas contained and secure to prevent access by livestock, feral animals and wildlife?",
      yes: (
        <BioCheckbox name="carcase_manure_waste_management_1_yes"></BioCheckbox>
      ),
      no: (
        <BioCheckbox name="carcase_manure_waste_management_1_no"></BioCheckbox>
      ),
      na: (
        <BioCheckbox name="carcase_manure_waste_management_1_na"></BioCheckbox>
      ),
      recommended_measures:
        "Dead animal pits and garbage tips fenced off to prevent livestock and feral animals accessing carcases and food waste.",
      actioned: (
        <BioCheckbox name="carcase_manure_waste_management_1_1_actioned"></BioCheckbox>
      ),
      comments: (
        <Form.Item
          name="carcase_manure_waste_management_1_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "15",
      order: "",
      production_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "Where practical, carcases disposed of immediately in a way that takes into account environmental and public considerations (e.g. burning, burial or composting).",
      actioned: (
        <BioCheckbox name="carcase_manure_waste_management_1_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "16",
      order: "",
      production_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures: "Disposal sites marked on property map.",
      actioned: (
        <BioCheckbox name="carcase_manure_waste_management_1_3_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "17",
      order: "3.3.2",
      production_practices:
        "Are you minimising the risk of salmonella or botulism when applying chicken litter?",
      yes: (
        <BioCheckbox name="carcase_manure_waste_management_2_yes"></BioCheckbox>
      ),
      no: (
        <BioCheckbox name="carcase_manure_waste_management_2_no"></BioCheckbox>
      ),
      na: (
        <BioCheckbox name="carcase_manure_waste_management_2_na"></BioCheckbox>
      ),
      recommended_measures: "All litter is stored in fenced off areas.",
      actioned: (
        <Form.Item
          name="carcase_manure_waste_management_2_1_actioned"
          className="isoInputWrapper"
        >
          <BioCheckbox name="carcase_manure_waste_management_2_1_actioned"></BioCheckbox>
        </Form.Item>
      ),
      comments: (
        <Form.Item
          name="carcase_manure_waste_management_2_comments"
          className="isoInputWrapper"
        >
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "18",
      order: "",
      production_practices: "",
      yes: "",
      no: "",
      na: "",
      recommended_measures:
        "A period of at least three weeks allowed between application of litter and grazing.",
      actioned: (
        <BioCheckbox name="carcase_manure_waste_management_2_2_actioned"></BioCheckbox>
      ),
      comments: "",
    },
    {
      key: "19",
      order: "3.4",
      production_practices: "Fences",
      yes: "Yes",
      no: "No",
      na: "N/A",
      recommended_measures: "Recommended measures",
      actioned: "Actioned?",
      comments: "Comments",
    },
    {
      key: "20",
      order: "3.4.1",
      production_practices:
        "Are fences, especially boundary fences, regularly inspected and adequately maintained?",
      yes: <BioCheckbox name="fences_1_yes"></BioCheckbox>,
      no: <BioCheckbox name="fences_1_no"></BioCheckbox>,
      na: <BioCheckbox name="fences_1_na"></BioCheckbox>,
      recommended_measures:
        "Existing fences regularly inspected and maintained.",
      actioned: <BioCheckbox name="fences_1_1_actioned"></BioCheckbox>,
      comments: (
        <Form.Item name="fences_1_comments" className="isoInputWrapper">
          <TextArea />
        </Form.Item>
      ),
    },
    {
      key: "21",
      order: "",
      production_practices: "",
      yes: "",
      no: <BioCheckbox name="fences_1_no"></BioCheckbox>,
      na: "",
      recommended_measures: "Fencing replaced where required.",
      actioned: <BioCheckbox name="fences_1_2_actioned"></BioCheckbox>,
      comments: "",
    },
  ];

  return (
    <Table className="bio-table" columns={columns} dataSource={data} bordered pagination={false} />
  );
};

export default ProductionPracticesTable;
