import React, { useState, useEffect } from "react";
import { Button, Select, Switch } from "antd";
import { primaryObjectTypeService } from "@iso/services";
import IntlMessages from "@iso/components/utility/intlMessages";
import PrimaryObjectFilterWrapper from "./PrimaryObjectFilterModal.styles";
import { Checkbox, Radio } from "antd";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const PrimaryObjectFilterModal = (props) => {
  const [primaryObjectTypes, setPrimaryObjectTypes] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(props.selectedPrimaryObjectTypeIds ? props.selectedPrimaryObjectTypeIds : []);
  const [notSelectedOptions, setNotSelectedOptions] = useState([]);

  const cancelModal = () => {
    props.cancelPrimaryObjectFilterModal();
  };

  useEffect(() => {
    if (props.propertyId) {
      primaryObjectTypeService.getListNomal(props.propertyId).then((res) => {
        if (res.code === "0000") {
          setPrimaryObjectTypes(res.primary_object_types);
        }
      });
    }
  }, [props.propertyId, props.visiblePrimaryObjectFilterModal]);

  useEffect(() => {
    const options = [];
    const defaultValue = [];
    if (primaryObjectTypes) {
      primaryObjectTypes.forEach((type) => {
        defaultValue.push(type.id)
        options.push({
          value: type.id,
          label: type.name,
        });
      });
    }
    if(!props.selectedPrimaryObjectTypeIds){
      setSelectedOptions(defaultValue)
    }
    setTypeOptions(options);
  }, [primaryObjectTypes]);

  useEffect(()=>{
    const notSelected = []
    typeOptions.forEach(option => {
      if(!selectedOptions.includes(option.value)){
        notSelected.push(option.value)
      }
    })
    setNotSelectedOptions(notSelected)
  }, [selectedOptions])

  const handleChangeType = (list) => {
    setSelectedOptions(list)
  };

  const handleSubmit = () => {
    props.handleFilterPrimaryObjectType && props.handleFilterPrimaryObjectType({
      selectedTypeIds: selectedOptions,
      notSelectedTypeIds: notSelectedOptions,
    })
    cancelModal()
  }

  return (
    <PrimaryObjectFilterWrapper
      className="primary-object-filter-modal"
      forceRender={true}
      visible={props.visiblePrimaryObjectFilterModal}
      title={
        <IntlMessages id="primaryObjectFilter.modal.primaryObjectFilter" />
      }
      onCancel={cancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <div className="option-container">
        <label className="title">Select type </label>
        <CheckboxGroup options={typeOptions} value={selectedOptions} onChange={handleChangeType} />
      </div>

      <div className="option-container">
        <label className="title">Show area label</label>
        <Switch checkedChildren="Show" unCheckedChildren="Hide" checked={props.isShowAreaLabel} onChange={(value) => props.setIsShowAreaLabel(value)}/>
      </div>
      
    </PrimaryObjectFilterWrapper>
  );
};

export default PrimaryObjectFilterModal;
