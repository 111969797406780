import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import SplitModalWrapper from './SplitModal.styles';
import { Form, Input, Button, Select, Row, Col, InputNumber, DatePicker,Radio,Divider } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { objectColors } from '@iso/constants/objectColors';
import { mobService } from '@iso/services';
import moment from 'moment';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import _ from 'lodash';
import { mobTypes } from '@iso/constants/mobTypes';
import styled from "styled-components";
import L from 'leaflet';
import inside from 'point-in-polygon';
const { RangePicker } = DatePicker;

const { Option } = Select;

const SplitModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [checkingTotal, setCheckingTotal] = useState(true);
  const [loading, setLoading] = useState(false);
  const {getLatLngMarkerArea,messageWarning} = props;

  const cancelModal = () => {
    props.cancelSplitModal();
    setCheckingTotal(true);
    form.resetFields();
  }

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select an object"
        allowClear
        size="large"
        disabled={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionsBreed = (mob_breed_options) => {
    let breed_options = [];
     _.forEach(mob_breed_options, (breed_opt, index) => {
      breed_options.push(
        <Option key={index} value={breed_opt.slug}>
            <div>{breed_opt.name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a breed"
        allowClear
        size="large"
        disabled={true}
      >
        {breed_options}
      </Select>
    );
  }

  const renderOptionsMobType = () => {
    let options = [];
    _.forEach(mobTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
            <div>{type.label}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a type"
        allowClear
        size="large"
        disabled={true}
      >
        {options}
      </Select>
    );
  }

  const renderOptionsColor = () => {
    let options = [];
    _.forEach(objectColors, (color, index) => {
      options.push(
        <Option key={index} value={color.value}>
          <ColorItemRow>
            <ColorItemPreview style={{backgroundColor: color.color}}></ColorItemPreview>
            <div>{color.label}</div>
          </ColorItemRow>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a color"
        allowClear
        size="large"
        disabled={true}
      >
        {options}
      </Select>
    );
  }

  const getRandomLatLng = (coordinates) => {
    var polygon = L.polygon([
      coordinates
    ]);
    var bounds = polygon.getBounds();
    var x_max = bounds.getEast();
    var x_min = bounds.getWest();
    var y_max = bounds.getSouth();
    var y_min = bounds.getNorth();
    var lat=0,lng = 0;
    var count = 0;
    var area = polygon.getLatLngs()[0].map(function(point){
      return [point.lat, point.lng]            
    });

    while(!inside([lat, lng], area)){
      lat = y_min + (Math.random() * (y_max - y_min));
      lng = x_min + (Math.random() * (x_max - x_min));
      count+=1;
      if(count==100){
        break;
      }      
    }            

    return new L.LatLng(
          lat,
          lng
    );
  }

  useEffect(() => {

    if(props.dataMob){
        setFields([
          {
            name: ['mob', 'name'],
            value: props.dataMob.name || '',
          },
          {
            name: ['mob', 'type'],
            value: props.dataMob.type || '',
          },
          {
            name: ['mob', 'breed'],
            value: props.dataMob.breed || '',
          },
          {
            name: ['mob', 'tag_colour'],
            value: props.dataMob.tag_colour || '',
          },
          {
            name: ['mob', 'description'],
            value: props.dataMob.description || '',
          },
          {
            name: ['mob', 'date_of_birth'],
            value: props.dataMob.date_of_birth && props.dataMob.date_of_birth_end ? [moment(props.dataMob.date_of_birth), moment(props.dataMob.date_of_birth_end)] : '',
          },
          {
            name: ['mob', 'primary_object_id'],
            value: props.dataMob.primary_object_id || '',
          },
          {
            name: ['mob', 'meta_data'],
            value: props.dataMob.meta_data || '',
          },
        ]);
    }
  }, [props.visibleSplitModal]);

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = async (values) => {
    if( ( values.mob.sub_mob_1 + values.mob.sub_mob_2 != props.numberAnimals) && props.valueCheckboxMob.length == 0){
      setCheckingTotal(false);
      setLoading(false);
      return false;
    }
   
    const selectedObject = _.find(props.primaryObjects, (object) => {
      return object.id === values.mob.primary_object_id;
    });

    let arrayPoints = [];
    await mobService.getByObject(selectedObject.id).then(res => {
      if(res.code === "0000"){
        _.forEach(res.mobs, (mob) => {
          if(mob.latitude && mob.longitude){
            arrayPoints.push(
              {
                "lat": mob.latitude,
                "lng": mob.longitude
              }
            );
          }  
        });
      }
    });
  
    let point_mob_1 = null;
    let point_mob_2 = null;

    if(props.dataMob.latitude && props.dataMob.longitude){
      point_mob_1 = {
        'lat': props.dataMob.latitude,
        'lng': props.dataMob.longitude,
      };
      point_mob_2 = getLatLngMarkerArea(1,selectedObject,arrayPoints)[0];
    }else{
      point_mob_1 = getLatLngMarkerArea(2,selectedObject,arrayPoints)[0];
      point_mob_2 = getLatLngMarkerArea(2,selectedObject,arrayPoints)[1];
    }
     
   if(point_mob_2 == null || point_mob_1 == null){
    messageWarning();
    props.updateDataMobs();
    cancelModal();
    props.cancelModal();
    form.resetFields();
    setLoading(false);
    return
   }
    var meta_data_1 = [];
    var meta_data_2 = [];
    
    if(values.mob.meta_data && values.mob.meta_data.length > 0){
      values.mob.meta_data?.map((item) => {
        meta_data_1.push({name: item.name, value: item.sub_mob_1})
      });
      values.mob.meta_data?.map((item) => {
        meta_data_2.push({name: item.name, value: item.sub_mob_2})
      });
    }
 
    let data = {
      mob: {
        original_mob: props.dataMob.id,
        main_mob : values.mob.main_mob,
        choose_mobs: props.valueCheckboxMob,
        sub_mobs : [
          {
            ...values.mob,
            name: values.mob.name + ' 1',
            date_of_birth: values.mob.date_of_birth ? dateHelper.dateForAPI(values.mob.date_of_birth[0]) : '',
            date_of_birth_end: values.mob.date_of_birth ? dateHelper.dateForAPI(values.mob.date_of_birth[1]) : '',
            point: point_mob_1,
            property_id: props.propertyId,
            tag_number_range: values.mob.sub_mob_1 ? values.mob.sub_mob_1 : '',
            meta_data: meta_data_1
          },
          {
            ...values.mob,
            name: values.mob.name + ' 2',
            date_of_birth: values.mob.date_of_birth ? dateHelper.dateForAPI(values.mob.date_of_birth[0]) : '',
            date_of_birth_end: values.mob.date_of_birth ? dateHelper.dateForAPI(values.mob.date_of_birth[1]) : '',
            point: point_mob_2,
            property_id: props.propertyId,
            tag_number_range: values.mob.sub_mob_2 ? values.mob.sub_mob_2 : '',
            meta_data: meta_data_2
          }
        ]
      }
    }

    if(props.valueCheckboxMob.length > 0){
 
      mobService.chooseSplitMob(data).then(res => {
        if(res.code === '0000'){
          props.updateDataMobs();
          cancelModal();
          props.cancelModal();
          form.resetFields();
          setLoading(false);
          //setDisableNumberAnimal(false);
        } else {
          setLoading(false);
        }
      });
    }else{
    
      mobService.splitMob(data).then(res => {
        if(res.code === '0000'){
          props.updateDataMobs();
          cancelModal();
          props.cancelModal();
          form.resetFields();
          setLoading(false);
          //setDisableNumberAnimal(false);
        } else {
          setLoading(false);
        }
      });
    }

  }


  return(
    <SplitModalWrapper
      visible={props.visibleSplitModal}
      onCancel={cancelModal}
      maskClosable={false}
      title={<IntlMessages id="propertyPage.modal.mob.split"/>}
      footer={[
        <Button key="back" className="cancel-button" onClick={cancelModal}>
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit}  loading={loading}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        fields={fields}
        className="form-body"
      >
        <Row>
          <Col xs={{span: 24}} md={{span: 11}}>
            <Form.Item
              name={['mob', 'name']}
              className="isoInputWrapper"
              label="Name"
            >
              <Input size="large" placeholder="Input mob name" disabled={true}/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
            <Form.Item
              name={['mob', 'primary_object_id']}
              className="isoInputWrapper"
              label={<IntlMessages id="propertyPage.modal.mob.paddock" />}
            >
              {renderOptionObject(props.primaryObjects)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{span: 24}} md={{span: 11}}>
            <Form.Item
              name={['mob', 'type']}
              className="isoInputWrapper"
              label="Animal"
            >
              {renderOptionsMobType()}
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
            <Form.Item
              name={['mob', 'breed']}
              className="isoInputWrapper"
              label="Breed"
            >
            {renderOptionsBreed(props.breeds)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{span: 24}} md={{span: 24}}>
            <Form.Item
              name={['mob', 'date_of_birth']}
              className="isoInputWrapper"
              label="Date Of Birth"
            >
              <RangePicker size="large" style={{width: '100%'}} disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}}>
            <Form.Item
              name={['mob', 'tag_colour']}
              className="isoInputWrapper"
              label="Tag Colour"
            >
            {renderOptionsColor()}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={['mob', 'description']}
          className="isoInputWrapper"
          label="Description"
        >
          <Input.TextArea disabled={true}/>
        </Form.Item>
        {props.valueCheckboxMob?.length > 0 ?
        <>
        <br/>
        <p>Choose Mob</p> 
          <Row>
            <Form.Item
              name={['mob', 'main_mob']}
              className="isoInputWrapper"
              label={<IntlMessages id="mobPage.managePage.mergeMob.modal.primary" />}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
            <Radio.Group>
              <Radio value={0}>Mob A</Radio>
              <Radio value={1}>Mob B</Radio>
            </Radio.Group>
          </Form.Item>
          </Row>
          </>
        :
        <>
        <br/>
        <p>Number of animals in new mob</p>
        <Row>
          <Col xs={{span: 24}} md={{span: 11}}>
            <Form.Item
              name={['mob', 'sub_mob_1']}
              className="isoInputWrapper"
              label="A"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <InputNumber size="large" min={1} max={9999} style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 11, offset: 2}}>
            <Form.Item
              name={['mob', 'sub_mob_2']}
              className="isoInputWrapper"
              label="B"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
            <InputNumber size="large" min={1} max={9999} style={{width: '100%'}} />
            </Form.Item>
          </Col>
        </Row>
        </>
        }
        { (!checkingTotal) ? <div className="ant-form-item-has-error">
        <div className="ant-form-item-explain"><div><IntlMessages id="propertyMap.mobModal.splitModal.totalError" /></div></div></div> : ''}
       {props.valueCheckboxMob?.length == 0 &&
       <>
        <Divider dashed orientation="left" orientationMargin="left">Attributes</Divider>
        <Form.List name={['mob', 'meta_data']} label="Attributes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <>
                    <Row gutter={16}>
                      <Col xs={{span: 6}} md={{span: 6}}>
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          fieldKey={[fieldKey, 'name']}
                          label="Name"
                          rules={[{ required: true, message: 'This field is required' }]}
                        >
                          <Input size="large" placeholder="Name" disabled/>
                        </Form.Item>
                      </Col>
                      <Col xs={{span: 6}} md={{span: 6}}>
                        <Form.Item
                          {...restField}
                          name={[name, 'value']}
                          label="Value"
                          fieldKey={[fieldKey, 'value']}
                          rules={[{ required: true, message: 'This field is required' }]}
                        >
                          <InputNumber placeholder="Value" size="large" min={1} style={{width: '100%'}} disabled/>
                        </Form.Item>
                      </Col>
                      <Col xs={{span: 6}} md={{span: 6}}>
                        <Form.Item
                          {...restField}
                          name={[name, 'sub_mob_1']}
                          label="Mob A"
                          fieldKey={[fieldKey, 'value']}
                          rules={[{ required: true, message: 'This field is required' }]}
                        >
                          <InputNumber placeholder="Value" size="large" min={1} style={{width: '100%'}} />
                        </Form.Item>
                      </Col>
                      <Col xs={{span: 6}} md={{span: 6}}>
                        <Form.Item
                          {...restField}
                          name={[name, 'sub_mob_2']}
                          label="Mob B"
                          fieldKey={[fieldKey, 'value']}
                          rules={[{ required: true, message: 'This field is required' }]}
                        >
                          <InputNumber placeholder="Value" size="large" min={1} style={{width: '100%'}} />
                        </Form.Item>
                      </Col>
                      
                    </Row>
                  </>
                ))}
              </>
            )}
          </Form.List>
          </>
        }
      </Form>
    </SplitModalWrapper>
  )
}

export default SplitModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div{
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;