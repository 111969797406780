import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import ProductModalWrapper from './ProductModal.styles';
import { Form, Input, Button  } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { marketplaceCustomerService } from '@iso/services';

const EmailContactModal = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const activePermission = useSelector((state) => state.permission.activePermission);

  const cancelModal = () => {
    props.cancelEmailContactModal();
    setLoading(false);
    form.resetFields();
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(values => {
        onStore(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
  }

  const onStore = (values) => {
    const data = {
      marketplace_customer: {
        ...values.marketplace_customer,
        product_id: props.productId
      }
    }

    marketplaceCustomerService.storeOrUpdate(data).then(res => {
      if(res.code === '0000'){
        cancelModal();
      } else {
        setLoading(false);
      }
    });
  }

  return(
    <ProductModalWrapper
      visible={props.visibleEmailContact}
      onCancel={cancelModal}
      maskClosable={false}
      title={<IntlMessages id="marketplacePage.emailContactModal.header"/>}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") 
        && <Button key="submit" className="btn-success" type="primary" onClick={handleSubmit} loading={loading}>
          {<IntlMessages id="marketplacePage.emailContactModal.submit" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
      >
        <div className="form-body">
          <Form.Item
            name={['marketplace_customer', 'name']}
            className="isoInputWrapper"
            label={<IntlMessages id="marketplacePage.emailContactModal.name"/>}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input size="large" placeholder="Input your name"/>
          </Form.Item>
          <Form.Item
            name={['marketplace_customer', 'email']}
            className="isoInputWrapper"
            label={<IntlMessages id="marketplacePage.emailContactModal.email"/>}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input size="large" placeholder="Input your email"/>
          </Form.Item>
          <Form.Item
            name={['marketplace_customer', 'phone_number']}
            className="isoInputWrapper"
            label={<IntlMessages id="marketplacePage.emailContactModal.phoneNumber"/>}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input size="large" placeholder="Input your phone number"/>
          </Form.Item>
          <Form.Item
            name={['marketplace_customer', 'message']}
            className="isoInputWrapper"
            label={<IntlMessages id="marketplacePage.emailContactModal.message"/>}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </div>    
      </Form>
    </ProductModalWrapper>
  )
}

export default EmailContactModal;