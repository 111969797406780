import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const DashboardWrapper = styled.div`

  h1 {
    color: #0B2A1B;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .ant-col {
    padding: 0.25rem;
  }
  span.traffic-light-label {
    width: 100%;
    border: 2px solid #f1e154;
    padding: 15px 5px;
    border-radius: 5px;
    display: block;
    text-align: center;
    line-height: 1;
  }

  .ant-btn.ant-btn--sf-dashboard{
    border-radius:5px !important;
    padding: 20px 15px;
    line-height: 0px;
  }
   .ant-btn.ant-btn--sf-dashboard:focus{
    color:#0B2A1B;
   }
  .ant-btn.ant-btn--sf-dashboard:hover{
    background-color: #0B2A1B;
    color: white; 
  }
  .statistic-card {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
    margin-bottom: 15px;
  }
`;

export default WithDirection(DashboardWrapper);