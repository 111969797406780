import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const emergencyService = {
  getList,
  storeOrUpdate,
  getCheckinFormUserEmergency,
  destroy
};

function getList(userId, type){
  return (
    axios
      .get(
        `${apiEndpoint.emergencies}?user_id=${userId}&type=${type}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getCheckinFormUserEmergency(formId, userId, type){
  return (
    axios
      .get(
        `${apiEndpoint.emergencies}/get-checkin-form-user-emergency?form_id=${formId}&user_id=${userId}&type=${type}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.emergencies(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.emergencies,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function destroy ( id )
{
  return (
    axios
      .delete(
        apiEndpointFunction.emergencies(id),
        {
          headers: authHeader()
        }
      ).then( res =>
      {
        if ( res && res.status===200 )
        {
          return res.data;
        }
      } )
  );
}